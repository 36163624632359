<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on HTML to YAML Converter</h3>
                    </div>
                    <!-- FAQ 1 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the HTML to YAML Converter?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to YAML Converter is a tool that transforms HTML data into YAML (YAML Ain’t Markup Language) format. YAML is a human-readable data serialization format commonly used for configuration files and data exchange between programming languages.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 2 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the HTML to YAML Converter?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To use the HTML to YAML Converter:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Paste the HTML content into the input field.</li>
                                    <li>Click the <strong>Convert</strong> button to transform the HTML into YAML format.</li>
                                    <li>The YAML result will be displayed in the output field, ready for use in configuration files or further processing.</li>
                                    <li>You can also download the generated YAML file by clicking the <strong>Download</strong> button.</li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This process allows you to quickly convert structured HTML data into YAML, useful for configuration or data storage.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 3 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What is YAML?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    YAML (YAML Ain't Markup Language) is a human-readable format used for data serialization. It’s commonly employed for configuration files, data exchange between programming languages, and structured data storage. Unlike HTML or XML, YAML is much simpler and focuses on readability.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Here’s an example of a simple YAML structure:
                                </p>
                                <pre>
person:
  name: "Alice"
  age: 30
  city: "New York"
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    YAML uses indentation to represent nested structures, making it easy to read and write.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 4 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>What is the difference between HTML and YAML?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    HTML (HyperText Markup Language) and YAML (YAML Ain't Markup Language) serve different purposes:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>HTML</strong> is used to structure and display content on web pages. It includes elements like paragraphs, links, images, and forms.</li>
                                    <li><strong>YAML</strong> is used for data representation, such as in configuration files. It’s designed to be human-readable, with an emphasis on simplicity and minimal syntax.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    While HTML is focused on web development, YAML is focused on configuration and structured data exchange, making them complementary tools in different contexts.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 5 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>What kind of HTML can be converted to YAML?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to YAML Converter works best with structured HTML elements like tables, lists, and forms. For example, this HTML table:
                                </p>
                                <pre>
&lt;table&gt;
  &lt;tr&gt;&lt;th&gt;Name&lt;/th&gt;&lt;th&gt;Age&lt;/th&gt;&lt;/tr&gt;
  &lt;tr&gt;&lt;td&gt;Alice&lt;/td&gt;&lt;td&gt;30&lt;/td&gt;&lt;/tr&gt;
&lt;/table&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Will be converted into YAML as:
                                </p>
                                <pre>
table:
  - name: "Alice"
    age: 30
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The converter reads the structured HTML and reformats it into YAML, making it easier to work with the data in other applications.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 6 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>How does the conversion process work?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to YAML Converter reads the HTML structure, identifies elements such as tables, lists, or divs, and converts them into a YAML representation. It ensures the output YAML follows the indentation and syntax rules of the format.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Here’s how the process works:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>HTML tags like &lt;table&gt;, &lt;tr&gt;, and &lt;td&gt; are mapped to YAML lists and key-value pairs.</li>
                                    <li>Text content inside the tags is preserved and placed as values in the YAML structure.</li>
                                    <li>Attributes from the HTML tags (like class, id) are ignored in YAML conversion, focusing only on the content.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This conversion helps ensure the data is formatted correctly and can be used in YAML-compatible applications.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 7 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>What are the limitations of the HTML to YAML Converter?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to YAML Converter works best with simple, well-structured HTML content. However, it has some limitations:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>It may not handle complex or deeply nested HTML elements accurately.</li>
                                    <li>HTML attributes (like <code>class</code>, <code>id</code>, etc.) are not converted to YAML as they are often unnecessary in YAML data structures.</li>
                                    <li>Advanced HTML formatting or styling is ignored since YAML is focused on data, not presentation.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The tool is most effective for simple HTML structures like tables, lists, and basic content sections.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 8 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEight" aria-expanded="true" aria-controls="faqCollapseEight">
                            <h5>Why would I need to convert HTML to YAML?</h5>
                        </button>
                        <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Converting HTML to YAML can be useful in scenarios where structured data is needed in a simple and human-readable format. For example:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>YAML is often used in configuration files for applications. Converting HTML forms or tables to YAML can streamline setting up data-driven configurations.</li>
                                    <li>Data extracted from websites or HTML reports can be transformed into YAML for further processing in programming languages or configuration management systems.</li>
                                    <li>YAML’s focus on simplicity and readability makes it a preferred choice for developers needing to store or transfer structured data.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The conversion helps ensure that HTML content can be used effectively in environments where YAML is required.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Additional FAQs as needed -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HTMLToYAMLFAQ',
};
</script>

<style scoped>
  /* Add any specific styles for FAQ layout */
</style>
