<template>
  <BreadCrumb PageTitle="Starfield Name Generator" />
  <div class="custom-container mt-5 mb-50">
    <h1 class="text-center">Starfield Name Generator</h1>
    <p class="fs-6 text-center mb-4">
      Create unique cosmic names instantly with our Starfield Name Generator - perfect for characters, planets, and galaxies in your space-themed stories or games!
    </p>
    <div class="input-group">
      <span class="input-group-text pe-4" for="selectOptions">Options:</span>
      <select id="selectOptions" v-model="selectedOption" class="form-select" @change="updateHeaderText">
        <option value="random">Random Names</option>
        <option value="male">Male Names</option>
        <option value="female">Female Names</option>
      </select>
    </div>
    <div class="row mt-4 mb-4">
      <div>
        <div class="input-group">
          <span class="input-group-text" for="numNames">Numbers:</span>
          <input id="numNames" v-model.number="numNames" class="form-control" type="number" max="10" min="1" />
        </div>
      </div>
    </div>
    <div class="col">
      <button @click="generateAndDisplayNames" class="btn btn-primary mb-4 btn-hover w-100" data-bs-toggle="tooltip" title="Generate Names">
        Generate Names
      </button>
    </div>
    <div class="col-md-12">
      <div>
        <h4 class="genNames text-center">{{ headerText }}</h4>
        <div v-if="errorMessage" class="alert alert-warning mt-2" role="alert">
          {{ errorMessage }}
        </div>
        <div class="generatedNames fs-5">
          <p v-if="generatedNames.length === 0">No names generated yet.</p>
          <div v-else class="row row-cols-1 row-cols-md-2 g-4">
            <div v-for="(name, index) in generatedNames" :key="index" class="col">
              <div class="card h-100">
                <div class="card-body d-flex justify-content-between align-items-center">
                  <h5 class="card-title mb-0">{{ name }}</h5>
                  <button @click="copyToClipboard(name, index)" class="btn btn-outline-secondary btn-sm" title="Copy to clipboard">
                    <i :class="copyIcons[index]"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- FAQ Section -->
    <div class="mt-5">
      <StarfieldNameGeneratorFAQ />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import StarfieldNameGeneratorFAQ from '../FAQs/starfieldNameGeneratorFAQ.vue';

export default {
  name: 'StarfieldNameGenerator',
  components: {
    BreadCrumb,
    StarfieldNameGeneratorFAQ
  },
  setup() {
    const male_name = ['Aleron','Borex','Caelum','Draxen','Elix','Faros','Galex','Helion','Icarix','Jaxon','Kaelo','Leonis','Maxar','Novan','Orion','Pyxel','Qasar','Rigel','Solaris','Tycho','Ulxis','Voxen','Wyrmis','Xylo','Zephon','Arcturus','Braxon','Cygnus','Deneb','Elara','Fenris','Graviton','Halcyon','Io','Jericho','Kronos','Lyra','Morpheus','Nexus','Oberon','Pulsar','Quaser','Rexor','Sirius','Triton','Ulyss','Vega','Warp','Xeon','Zenith','Axiom','Blaze','Cosmo','Deimos','Eos','Flux','Ganymede','Helix','Indigo','Jove','Krypton','Lazarus','Matrix','Nebulo','Onyx','Phoenix','Quillon','Renegade','Stark','Taranis','Universe','Vortex','Wonder','Xenon','Ymir','Zephyrus','Altair','Bionic','Crater','Draco','Echo','Fornax','Grid','Horizon','Inferno','Jett','Kelvin','Lyric','Mirage','Nitro','Oort','Proteus','Quantum','Rogue','Specter','Tesseract','Umbra','Vector','Wraith','Xander','Yarden','Zane','Aurelius','Buzz','Creed','Deviant','Exodus','Fable','Grim','Halo','Ithaca','Journey','Kael','Lancer','Metro','Nucleus','Orbit','Pulse','Quest','Racer','Sentinel','Talon','Utopia','Vandal','Warp','Xero','York','Zeta','Astro','Barron','Cosmo','Dash','Equinox','Forge','Grit','Hunter','Icon','Jinx','Kinetic','Lux','Maverick','Nighthawk','Odyssey','Prestige','Quake','Revolt','Solstice','Titan','Umbriel','Virgo','Widget','Xenos','Yorick','Zorro','Ajax','Bolt','Cipher','Drifter','Elite','Fringe','Grail','Harbinger','Iron','Justice','Kohr','Legend','Mako','Nova','Omega','Phantom','Quantum','Rune','Striker','Tempest','Unit','Vault','Wilder','Xypher','Yankee','Zulu','Archon','Blaze','Cipher','Dynamo','Epoch','Frame','Gideon','Helios','Iconic','Jet','Kairos','Lumen','Mystic','Nemo','Orion','Prime','Quixote','Rebel','Surge','Tidal'];
    const female_name = ['Aria','Bella','Cora','Diana','Eva','Fiona','Gemma','Hana','Isla','Jade','Kaya','Luna','Mia','Nora','Olivia','Piper','Quinn','Rhea','Sara','Tessa','Uma','Vera','Willa','Xena','Yara','Zoe','Alyssa','Beatrix','Celia','Dahlia','Elara','Freya','Grace','Helena','Ivy','Julia','Kiara','Leia','Maya','Nova','Ophelia','Paige','Qiana','Ruby','Stella','Trinity','Una','Vienna','Wren','Xia','Yvette','Zara','Amara','Britta','Calla','Daphne','Elise','Farah','Gianna','Harper','Iris','Jasmine','Kara','Layla','Marina','Nadia','Opal','Pearl','Quincy','Rosalie','Skye','Talia','Ursula','Violet','Winona','Xanthe','Yasmin','Zadie','Avery','Bridget','Camila','Daniela','Esme','Faye','Giselle','Hazel','India','Julianna','Kenna','Lilah','Melody','Nyla','Octavia','Penelope','Queen','Riley','Savannah','Thea','Ulyana','Valentina','Whitney','Xiomara','Yael','Zinnia','Annika','Blair','Celeste','Delilah','Eleanor','Felicity','Genesis','Heidi','Indigo','Janelle','Kirsten','Lyra','Mckenzie','Noelle','Odessa','Priscilla','Qadira','Reagan','Selena','Taryn','Ulani','Vanessa','Waverly','Xena','Yasmeen','Zaina','Audrey','Bryn','Cassidy','Darcy','Emery','Fern','Gwyneth','Hattie','Ingrid','Joelle','Kaitlyn','Lorelei','Morgan','Nina','Oriana','Phoebe','Quinlan','Reese','Summer','Tia','Uriela','Veda','Winter','Xyla','Yolanda','Zephyr','Anastasia','Bernadette','Catalina','Deja','Emilia','Faith','Gabriella','Holly','Isadora','Jocelyn','Kyra','Lucinda','Magnolia','Neve','Orla','Petra','Quinta','Raven','Seraphina','Tabitha','Unity','Veronica','Winnie','Xandra','Yuliana','Zola'];
    const last_name = ['Nova','Orion','Sirius','Vega','Rigel','Phoenix','Lyra','Aquila','Draco','Polaris','Altair','Carina','Electra','Gemini','Helix','Io','Leo','Mira','Nebula','Omega','Pulsar','Quasar','Rhea','Solaris','Triton','Ursa','Vulcan','Terra','Xena','Yildun','Zephyr','Andromeda','Betelgeuse','Cosmos','Dorado','Eclipse','Fornax','Galaxy','Hydra','Indus','Janus','Kuiper','Lynx','Meteor','Nashira','Oberon','Pavo','Quantum','Rocket','Sagitta','Torus','Universe','Vortex','Wormhole','Xerxes','Yokai','Zenith','Asteroid','Blazar','Celestial','Deneb','Equinox','Fomalhaut','Ganymede','Helios','Interstellar','Jupiter','Kosmos','Lunar','Milky','Nadir','Orbit','Pleiades','Quantar','Radiant','Satellite','Terra','Umbra','Vector','Warp','Xanadu','Ymir','Zodiac','Arcturus','Borealis','Chroma','Deimos','Eridanus','Fenix','Graviton','Horizon','Infinity','Jovian','Kepler','Lunarix','Meridian','Nexus','Omicron','Pictor','Quark'];

    const selectedOption = ref('random');
    const numNames = ref(1);
    const generatedNames = ref([]);
    const errorMessage = ref('');
    const copyIcons = ref([]);

    const headerText = computed(() => {
      switch (selectedOption.value) {
        case 'male':
          return 'Generated Male Names';
        case 'female':
          return 'Generated Female Names';
        default:
          return 'Generated Random Names';
      }
    });

    function generateRandomName(gender) {
      let firstName;
      if (gender === 'male') {
        firstName = male_name[Math.floor(Math.random() * male_name.length)];
      } else if (gender === 'female') {
        firstName = female_name[Math.floor(Math.random() * female_name.length)];
      } else {
        firstName = Math.random() < 0.5
          ? male_name[Math.floor(Math.random() * male_name.length)]
          : female_name[Math.floor(Math.random() * female_name.length)];
      }
      const lastName = last_name[Math.floor(Math.random() * last_name.length)];
      return `${firstName} ${lastName}`;
    }

    function generateUniqueNames(count, gender) {
      const uniqueNames = new Set();
      const maxAttempts = count * 10; // Prevent infinite loop
      let attempts = 0;

      while (uniqueNames.size < count && attempts < maxAttempts) {
        uniqueNames.add(generateRandomName(gender));
        attempts++;
      }

      if (uniqueNames.size < count) {
        errorMessage.value = `Could only generate ${uniqueNames.size} unique names. Try again or reduce the number of names.`;
      } else {
        errorMessage.value = '';
      }

      return Array.from(uniqueNames);
    }

    function generateAndDisplayNames() {
      errorMessage.value = '';
      if (numNames.value < 1) {
        errorMessage.value = 'Minimum number of names is 1!';
        return;
      }
      if (numNames.value > 10) {
        errorMessage.value = 'Maximum number of names is 10!';
        return;
      }

      generatedNames.value = generateUniqueNames(numNames.value, selectedOption.value);
      copyIcons.value = Array(generatedNames.value.length).fill('bi bi-clipboard');
    }

    function copyToClipboard(name, index) {
      navigator.clipboard.writeText(name).then(() => {
        copyIcons.value[index] = 'bi bi-clipboard-check';
        setTimeout(() => {
          copyIcons.value[index] = 'bi bi-clipboard';
        }, 2000);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }

    return {
      selectedOption,
      numNames,
      generatedNames,
      errorMessage,
      headerText,
      generateAndDisplayNames,
      copyToClipboard,
      copyIcons
    };
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}

.card {
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
</style>