<template>
  <BreadCrumb PageTitle="IPv4 To IPv6 Converter" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">IPv4 To IPv6 Converter</h1>
    <p class="fs-6 text-center mb-4">Quickly convert IPv4 addresses to IPv6 format for network compatibility and modernization.</p>
    <div class="container">
      <div class="mb-4">
        <label for="ipv4Input">Enter IPv4 Address:</label>
        <input type="text" class="form-control" id="ipv4Input" v-model="ipv4Input" placeholder="IPv4 Address">
      </div>
      <div class="alert alert-success" role="alert" v-if="ipv6Addresses">
        <p><strong>IPv6 Compressed:</strong> {{ ipv6Addresses.ipv6Compressed }}</p>
        <p><strong>IPv6 Expanded (Shortened):</strong> {{ ipv6Addresses.ipv6ExpandedShortened }}</p>
        <p><strong>IPv6 Expanded:</strong> {{ ipv6Addresses.ipv6Expanded }}</p>
        <button class="copy-all-btn btn btn-secondary btn-sm mt-2 w-100" @click="copyAllResults">
          <i :class="copyIconClass"></i> Copy All
        </button>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <button class="btn btn-primary btn-block w-100 mb-3" @click="convertIPv4" data-bs-toggle="tooltip" title="Convert IPv4 to IPv6">Convert</button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-danger btn-block w-100" @click="clear" data-bs-toggle="tooltip" title="Clear screen">Clear</button>
        </div>
      </div>
      <div class="result" id="result">{{ resultMessage }}</div>
    </div>
    <div>
      <IPv4ToIPv6ConverterFAQ />
    </div>
  </div>
</template>

<script>
import ipaddr from 'ipaddr.js';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import IPv4ToIPv6ConverterFAQ from '../FAQs/ipv4ToIpv6ConverterFAQ.vue';

export default {
  components: {
    BreadCrumb,
    IPv4ToIPv6ConverterFAQ
  },
  data() {
    return {
      ipv4Input: '',
      ipv6Addresses: null,
      resultMessage: '',
      copyIconClass: 'bi bi-clipboard'
    };
  },
  methods: {
    convertIPv4() {
      try {
        this.ipv6Addresses = this.IPv4toIPv6(this.ipv4Input);
        this.resultMessage = '';
      } catch (error) {
        this.resultMessage = 'Invalid IPv4 address.';
        this.ipv6Addresses = null;
      }
    },
    clear() {
      this.ipv4Input = '';
      this.ipv6Addresses = null;
      this.resultMessage = '';
    },
    IPv4toIPv6(ipv4) {
      try {
        const ipv4Parsed = ipaddr.parse(ipv4);
        if (ipv4Parsed.kind() === 'ipv4') {
          const ipv6Compressed = ipv4Parsed.toIPv4MappedAddress().toString();
          const ipv6ExpandedShortened = ipv4Parsed.toIPv4MappedAddress().toNormalizedString();

          // Manually construct the IPv6 Expanded format
          const segments = ipv6ExpandedShortened.split(":");
          const expandedSegments = segments.map(segment => segment.padStart(4, '0'));
          const ipv6Expanded = expandedSegments.join(":");

          return {
            ipv6Compressed,
            ipv6ExpandedShortened,
            ipv6Expanded,
          };
        } else {
          throw new Error("Invalid IPv4 address format.");
        }
      } catch (error) {
        throw new Error("Invalid IPv4 address format.");
      }
    },
    copyAllResults() {
      const results = [
        `IPv6 Compressed: ${this.ipv6Addresses.ipv6Compressed}`,
        `IPv6 Expanded (Shortened): ${this.ipv6Addresses.ipv6ExpandedShortened}`,
        `IPv6 Expanded: ${this.ipv6Addresses.ipv6Expanded}`
      ].join('\n');

      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = results;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand('copy');
      document.body.removeChild(tempTextArea);

      // Provide a temporary visual feedback
      this.copyIconClass = 'bi bi-clipboard-check';
      setTimeout(() => {
        this.copyIconClass = 'bi bi-clipboard';
      }, 2000);
    }
  }
};
</script>

<style scoped>
.custom-container {
  max-width: 80vh;
  margin: auto;
}

label {
  font-size: 16px;
}
</style>