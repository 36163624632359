<template>
  <BreadCrumb PageTitle="Random IP Generator with Barcode" />
  <div class="custom-container mt-5 mb-50">
    <h1 class="text-center">Random IP Generator with Barcode</h1>
    <p class="fs-6 text-center">Utilize Random IP Generator for instant, reliable IP addresses. Ideal for network analysis, educational purposes, or software testing scenarios. Experience effortless generation today!</p>
    <div class="randomIPbg mt-3">
      <div class="form-control form-control-lg" id="randomIPs">
        <div v-if="generatedIPs.length === 0">No IPs generated yet</div>
        <div v-for="ip in generatedIPs" :key="ip" class="ip-item text-center mb-10 fs-6">
          <span>{{ ip }}</span>
          <span> | </span>
          <i class="bi bi-clipboard" @click="copyToClipboard(ip, $event)" title="Copy to clipboard"></i>
          <span> | </span>
          <i class="bi bi-qr-code" @click="showQrcode(ip)" title="Show QR code"></i>
        </div>
      </div>
    </div>
    <div id="errorAlert" class="alert alert-danger mt-4" v-if="error">{{ error }}</div>
    <div class="input-group mt-4 mb-4 d-flex justify-content-center">
      <span class="input-group-text">How Many IPs?</span>
      <input type="number" v-model="quantity" class="form-control w-25 text-center" placeholder="1" min="1" max="10">
    </div>
    <div>
      <p style="margin-bottom:5px;">Optional (Enter IP range)</p>
      <div class="input-group mb-3">
        <span class="input-group-text">Start</span>
        <input v-model="ipStart" class="form-control" type="text" :placeholder="ipVersion === 'IPv4' ? '0.0.0.0' : '::'" />
        <input v-model="ipEnd" class="form-control" type="text" :placeholder="ipVersion === 'IPv4' ? '255.255.255.255' : 'ffff:ffff:ffff:ffff:ffff:ffff:ffff:ffff'" />
        <span class="input-group-text">End</span>
      </div>
    </div>
    <div class="form-check mb-4">
      <input class="form-check-input" type="checkbox" v-model="excludePrivateIPs">
      <label class="form-check-label">
        Exclude Private IP Ranges
      </label>
    </div>
    <div class="mb-4">
      <select class="form-select" v-model="ipVersion">
        <option value="IPv4">IPv4</option>
        <option value="IPv6">IPv6</option>
      </select>
    </div>
    <div class="mt-4">
      <button class="btn btn-primary w-100" @click="generateIPs">Generate Random IPs</button>
    </div>
    <div class="modal fade" id="qrCodeModal" tabindex="-1" aria-labelledby="qrCodeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered justify-content-center modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="qrCodeModalLabel">QR Code for IP Address</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body d-flex justify-content-center" id="qrCodeContainer">
            <!-- QR code will be placed here -->
          </div>
        </div>
      </div>
    </div>
    <div class="randomIPGeneratorFAQ mt-40">
      <RandomIPGeneratorFAQ />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import QRCode from 'qrcode';
import { Modal } from 'bootstrap';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import RandomIPGeneratorFAQ from '../FAQs/randomIPGeneratorFAQ.vue';

export default defineComponent({
  components: {
    BreadCrumb,
    RandomIPGeneratorFAQ
  },
  data() {
    return {
      quantity: 1,
      ipStart: '',
      ipEnd: '',
      excludePrivateIPs: false,
      ipVersion: 'IPv4',
      generatedIPs: [] as string[],
      error: ''
    };
  },
  methods: {
    generateIPs() {
      this.error = '';
      this.generatedIPs = [];

      if (!this.validateInputRanges()) {
        return;
      }

      if (this.quantity < 1 || this.quantity > 10 || isNaN(this.quantity)) {
        this.showError('Please enter a number between 1 and 10.');
        return;
      }

      if ((this.ipStart && !this.ipEnd) || (!this.ipStart && this.ipEnd)) {
        this.showError('Please enter both a start and an end IP.');
        return;
      }

      if (this.ipStart && this.ipEnd && (!this.validateIP(this.ipStart) || !this.validateIP(this.ipEnd))) {
        this.showError('Please enter a valid IP range.');
        return;
      }

      try {
        const ipList = this.generateRandomIPs(this.quantity, this.ipStart, this.ipEnd, this.excludePrivateIPs);
        this.generatedIPs = ipList;
        this.generateQRCode(this.generatedIPs[0]);
      } catch (e: any) {
        this.showError('An error occurred: ' + e.message);
      }
    },
    showError(message: string) {
      this.error = message;
    },
    hideError() {
      this.error = '';
    },
    generateRandomIPs(count: number, ipStart: string, ipEnd: string, excludePrivate: boolean) {
      let ips: string[] = [];
      if (!ipStart || !ipEnd) {
        ipStart = this.ipVersion === 'IPv4' ? '0.0.0.0' : '::';
        ipEnd = this.ipVersion === 'IPv4' ? '255.255.255.255' : 'ffff:ffff:ffff:ffff:ffff:ffff:ffff:ffff';
      }

      for (let i = 0; i < count; i++) {
        let randomIP: string;
        do {
          randomIP = this.ipVersion === 'IPv4' ? this.generateRandomIPv4(ipStart, ipEnd) : this.generateRandomIPv6();
        } while (excludePrivate && this.isPrivateIP(randomIP));

        ips.push(randomIP);
      }

      return ips;
    },
    generateRandomIPv4(ipStart: string, ipEnd: string) {
      const startLong = this.ip2long(ipStart);
      const endLong = this.ip2long(ipEnd);
      const randomLong = startLong + (Math.floor(Math.random() * (endLong - startLong + 1)));
      return this.long2ip(randomLong);
    },
    generateRandomIPv6() {
      const segments: string[] = [];
      for (let i = 0; i < 8; i++) {
        segments.push(Math.floor(Math.random() * 0x10000).toString(16));
      }
      return segments.join(':');
    },
    ip2long(ip: string) {
      const components = ip.split('.').map(item => parseInt(item, 10));
      return (components[0] * 16777216) + (components[1] * 65536) + (components[2] * 256) + components[3];
    },
    long2ip(long: number) {
      return ((long >>> 24) + '.' +
        ((long >> 16) & 255) + '.' +
        ((long >> 8) & 255) + '.' +
        (long & 255));
    },
    validateIP(ip: string) {
      if (this.ipVersion === 'IPv4') {
        const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipRegex.test(ip);
      } else {
        const ipRegex = /^([\da-f]{1,4}:){7}[\da-f]{1,4}$/i;
        return ipRegex.test(ip);
      }
    },
    isPrivateIP(ip: string) {
      if (this.ipVersion === 'IPv4') {
        const parts = ip.split('.').map(item => parseInt(item, 10));
        return parts[0] === 10 ||
          (parts[0] === 172 && parts[1] >= 16 && parts[1] <= 31) ||
          (parts[0] === 192 && parts[1] === 168);
      } else {
        // Define logic for IPv6 private IPs if necessary
        return false;
      }
    },
    copyToClipboard(text: string, event: Event) {
      const copyIcon = event.target as HTMLElement;
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      document.body.removeChild(textArea);

      copyIcon.classList.remove('bi-clipboard');
      copyIcon.classList.add('bi-clipboard-check');

      setTimeout(() => {
        copyIcon.classList.remove('bi-clipboard-check');
        copyIcon.classList.add('bi-clipboard');
      }, 2000);
    },
    showQrcode(ip: string) {
      this.generateQRCode(ip);
      const qrCodeModal = new Modal(document.getElementById('qrCodeModal') as HTMLElement);
      qrCodeModal.show();
    },
    generateQRCode(ip: string) {
      QRCode.toDataURL(ip, (err: Error | null, url: string) => {
        if (err) {
          this.showError('Failed to generate QR code: ' + err.message);
          return;
        }
        const qrCodeContainer = document.getElementById('qrCodeContainer') as HTMLElement;
        qrCodeContainer.innerHTML = `<img src="${url}" alt="QR Code">`;
      });
    },
    validateInputRanges() {
      const isStartPrivate = this.isPrivateIP(this.ipStart);
      const isEndPrivate = this.isPrivateIP(this.ipEnd);

      if (this.excludePrivateIPs && isStartPrivate && isEndPrivate) {
        this.showError('Conflict in selection: The specified range is private, and you\'ve chosen to exclude private IPs. Please modify your selection criteria.');
        return false;
      }

      return true;
    }
  },
  watch: {
    excludePrivateIPs() {
      this.hideError();
    },
    ipStart() {
      this.hideError();
    },
    ipEnd() {
      this.hideError();
    },
    quantity() {
      this.hideError();
    }
  }
});
</script>

<style scoped>
#randomIPs {
  padding: 10px;
}

.custom-container {
  max-width: 85vh;
  margin: 0 auto;
}
  
.modal-content {
  width: 50%;
}

.bi {
  cursor: pointer;
}
</style>