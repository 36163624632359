<template>
  <BreadCrumb PageTitle="Color Picker Generator" />
  <div class="letter-spacing mb-50">
    <h1 class="text-center">Color Picker Generator</h1>
    <p class="fs-5 text-center">Load image and click on desired color to get the RGB, Hex, HSL and CMYK values of the selected color.</p>
    <div class="row mt-50">
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-6">
            <div class="input-group mb-3">
              <input
                ref="fileInput"
                class="form-control"
                type="file"
                @change="handleFileUpload"
                aria-describedby="file-addon"
                aria-label="Upload"
                accept="image/jpeg, image/png, image/gif"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <input
                v-model="imageURL"
                class="form-control form-control-sm"
                type="text"
                aria-label="Image URL"
                placeholder="Enter Image URL"
              />
              <button @click="loadImageFromURL" class="btn btn-primary btn-sm">
                Image URL
              </button>
            </div>
          </div>
        </div>
        <div class="canvas-container mt-10 mb-50 w-100 text-center">
          <canvas id="canvas" ref="canvas"></canvas>
        </div>
        <div id="related-colors-section mt-4">
          <h3 class="text-center">Random Colors</h3>
          <div id="related-colors" class="mb-4" style="display: flex; flex-wrap: nowrap;">
            <div
              v-for="color in randomColors"
              :key="color.rgb"
              class="related-color-block"
              @click="updateCurrentColorDisplay(color.r, color.g, color.b)"
            >
              <div
                class="content"
                :style="{ backgroundColor: color.rgb }"
              >
                <span class="hex-value">{{ rgbToHex(color.r, color.g, color.b) }}</span>
              </div>
            </div>
          </div>
          <button type="button" @click="populateRandomColors" class="btn btn-primary w-100">
            Generate Random Color
          </button>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="sticky-parent">
          <div class="border-box">
            <h5 class="text-center">
              Active Color
            </h5>
            <div ref="currentColor" id="current-color" class="h-50 card w-100"></div>
          </div>

          <div id="color-info" class="row mt-4 mb-4">
            <div class="input-group mb-15 mb-md-20">
              <span class="input-group-text">RBG</span>
              <input type="text" class="form-control shadow-none fs-md-15 text-black" id="rgb-value" v-model="colorInfo.rgb" placeholder="---" readonly>
              <span class="input-group-text" @click="copyToClipboard(colorInfo.rgb, $event.target)">
                <i class="flaticon-copy cursor-pointer"></i>
              </span>
            </div>
            <div class="input-group mb-15 mb-md-20">
              <span class="input-group-text">HEX</span>
              <input type="text" class="form-control shadow-none fs-md-15 text-black" id="hex-value" v-model="colorInfo.hex" placeholder="---" readonly>
              <span class="input-group-text" @click="copyToClipboard(colorInfo.hex, $event.target)">
                <i class="flaticon-copy cursor-pointer"></i>
              </span>
            </div>
            <div class="input-group mb-15 mb-md-20">
              <span class="input-group-text">HSL</span>
              <input type="text" class="form-control shadow-none fs-md-15 text-black" id="hsl-value" v-model="colorInfo.hsl" placeholder="---" readonly>
              <span class="input-group-text" @click="copyToClipboard(colorInfo.hsl, $event.target)">
                <i class="flaticon-copy cursor-pointer"></i>
              </span>
            </div>
            <div class="input-group mb-15 mb-md-20">
              <span class="input-group-text">CMYK</span>
              <input type="text" class="form-control shadow-none fs-md-15 text-black" id="cmyk-value" v-model="colorInfo.cmyk" placeholder="---" readonly>
              <span class="input-group-text" @click="copyToClipboard(colorInfo.cmyk, $event.target)">
                <i class="flaticon-copy cursor-pointer"></i>
              </span>
            </div>
          </div>
          <div id="color-history-section" class="mt-4 mb-4">
            <h5 class="text-center">
              Saved Colors
            </h5>
            <div id="color-history-boxes">
              <div
                v-for="(color, index) in colorHistory"
                :key="index"
                class="color-placeholder"
                :style="{ backgroundColor: color.rgb }"
                @click="updateCurrentColorDisplay(color.r, color.g, color.b)"
              >
                <span class="delete-btn" @click.stop="removeColor(index)">
                  &times;
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div><ColorPickerGeneratorFAQ /></div>
    </div>
    <canvas
      id="zoomPopup"
      ref="zoomPopup"
      width="100"
      height="100"
      style="position: fixed; visibility: hidden; border-radius: 50%; border: 2px solid #fff; z-index: 1100;"
    ></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, onUnmounted } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ColorPickerGeneratorFAQ from '../FAQs/colorPickerGeneratorFAQ.vue';

interface Color {
  r: number;
  g: number;
  b: number;
  rgb: string;
}

interface ColorInfo {
  rgb: string;
  hex: string;
  hsl: string;
  cmyk: string;
  name: string;
}

export default defineComponent({
  components: {
    BreadCrumb,
    ColorPickerGeneratorFAQ
  },
  setup() {
    const fileInput = ref<HTMLInputElement | null>(null);
    const canvas = ref<HTMLCanvasElement | null>(null);
    const currentColor = ref<HTMLDivElement | null>(null);
    const zoomPopup = ref<HTMLCanvasElement | null>(null);
    const imageURL = ref<string>('');
    
    // Initialize colorHistory with 6 empty placeholders
    const colorHistory = reactive<Color[]>(
      Array(6).fill({ r: 0, g: 0, b: 0, rgb: '' })
    );
    
    const randomColors = reactive<Color[]>([]);
    const colorInfo = reactive<ColorInfo>({
      rgb: '-',
      hex: '-',
      hsl: '-',
      cmyk: '-',
      name: '-'
    });

    onMounted(() => {
      loadSampleImage();
      populateRandomColors();
      setupCanvasEvents();
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
      removeCanvasEvents();
    });

    function handleFileUpload(event: Event) {
      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];
      if (file && file.type.match('image.*')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          loadImage(e.target?.result as string);
          imageURL.value = '';
        };
        reader.readAsDataURL(file);
      } else {
        alert('Invalid file format. Please upload an image.');
      }
      target.value = '';
    }

    function loadImageFromURL() {
      if (imageURL.value) {
        loadImage(imageURL.value);
        if (fileInput.value) {
          fileInput.value.value = '';
        }
      } else {
        alert('Please enter a valid URL.');
      }
    }

    function loadImage(src: string) {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        if (!canvas.value) return;
        const ctx = canvas.value.getContext('2d');
        const parentContainer = canvas.value.parentElement as HTMLElement;
        const parentWidth = parentContainer.offsetWidth;
        const parentHeight = parentContainer.offsetHeight;

        const aspectRatio = img.width / img.height;
        let newWidth = parentWidth;
        let newHeight = newWidth / aspectRatio;

        if (newHeight > parentHeight) {
          newHeight = parentHeight;
          newWidth = newHeight * aspectRatio;
        }

        if (newWidth > parentWidth) {
          newWidth = parentWidth;
          newHeight = newWidth / aspectRatio;
        }

        canvas.value.width = newWidth;
        canvas.value.height = newHeight;
        ctx?.clearRect(0, 0, canvas.value.width, canvas.value.height);
        ctx?.drawImage(img, 0, 0, newWidth, newHeight);
      };
      img.src = src;
    }

    function loadSampleImage() {
      const img = new Image();
      img.src = '/sample-color-palette.jpg';
      img.onload = () => {
        if (!canvas.value) return;
        const ctx = canvas.value.getContext('2d');
        const parentContainer = canvas.value.parentElement as HTMLElement;
        const parentWidth = parentContainer.offsetWidth;
        const parentHeight = parentContainer.offsetHeight;

        const canvasWidth = Math.max(parentWidth, img.width);
        const canvasHeight = Math.max(parentHeight, img.height);

        canvas.value.width = canvasWidth;
        canvas.value.height = canvasHeight;

        const offsetX = (canvasWidth - img.width) / 2;
        const offsetY = (canvasHeight - img.height) / 2;

        ctx?.clearRect(0, 0, canvas.value.width, canvas.value.height);
        ctx?.drawImage(img, offsetX, offsetY, img.width, img.height);
      };
      img.onerror = (error) => {
        console.error("Error loading image:", error);
      };
    }

    function setupCanvasEvents() {
      if (!canvas.value) return;
      canvas.value.addEventListener('click', handleCanvasClick);
      canvas.value.addEventListener('mousemove', handleEvent);
      canvas.value.addEventListener('touchmove', handleEvent);
      canvas.value.addEventListener('touchend', handleTouchEnd);
      canvas.value.addEventListener('mouseleave', hideZoomPopup);
    }

    function removeCanvasEvents() {
      if (!canvas.value) return;
      canvas.value.removeEventListener('click', handleCanvasClick);
      canvas.value.removeEventListener('mousemove', handleEvent);
      canvas.value.removeEventListener('touchmove', handleEvent);
      canvas.value.removeEventListener('touchend', handleTouchEnd);
      canvas.value.removeEventListener('mouseleave', hideZoomPopup);
    }

    function handleCanvasClick(event: MouseEvent) {
      if (!canvas.value) return;
      const ctx = canvas.value.getContext('2d');
      const rect = canvas.value.getBoundingClientRect();
      const scaleX = canvas.value.width / rect.width;
      const scaleY = canvas.value.height / rect.height;
      const x = (event.clientX - rect.left) * scaleX;
      const y = (event.clientY - rect.top) * scaleY;
      const pixel = ctx?.getImageData(Math.floor(x), Math.floor(y), 1, 1).data;

      if (pixel) {
        updateCurrentColorDisplay(pixel[0], pixel[1], pixel[2]);
        saveColorToHistory(pixel[0], pixel[1], pixel[2]);
      }
    }

    function handleEvent(event: MouseEvent | TouchEvent) {
      if (!canvas.value || !zoomPopup.value) return;
      const ctx = canvas.value.getContext('2d');
      let x, y;

      event.preventDefault();

      const rect = canvas.value.getBoundingClientRect();
      const scaleX = canvas.value.width / rect.width;
      const scaleY = canvas.value.height / rect.height;

      if (event.type === 'touchmove') {
        const touch = (event as TouchEvent).touches[0];
        x = (touch.clientX - rect.left) * scaleX;
        y = (touch.clientY - rect.top) * scaleY;
      } else {
        x = ((event as MouseEvent).clientX - rect.left) * scaleX;
        y = ((event as MouseEvent).clientY - rect.top) * scaleY;
      }

      if (x >= 0 && y >= 0 && x <= canvas.value.width && y <= canvas.value.height) {
        const pixel = ctx?.getImageData(Math.floor(x), Math.floor(y), 1, 1).data;
        if (pixel) {
          updateCurrentColorDisplay(pixel[0], pixel[1], pixel[2]);

          const popupCtx = zoomPopup.value.getContext('2d');
          zoomPopup.value.width = 100;
          zoomPopup.value.height = 100;
          popupCtx?.clearRect(0, 0, zoomPopup.value.width, zoomPopup.value.height);
          popupCtx?.drawImage(
            canvas.value,
            Math.max(0, Math.min(canvas.value.width - 50, x - 25)),
            Math.max(0, Math.min(canvas.value.height - 50, y - 25)),
            50,
            50,
            0,
            0,
            100,
            100
          );
          popupCtx?.beginPath();
          popupCtx?.arc(50, 50, 4, 0, 2 * Math.PI);
          popupCtx!.strokeStyle = '#fff';
          popupCtx!.lineWidth = 2;
          popupCtx?.stroke();

          let popupX, popupY;
          if (event.type === 'touchmove') {
            popupX = (event as TouchEvent).touches[0].clientX - 60;
            popupY = (event as TouchEvent).touches[0].clientY - 110;
          } else {
            popupX = (event as MouseEvent).clientX - 60;
            popupY = (event as MouseEvent).clientY - 110;
          }

          zoomPopup.value.style.left = `${Math.max(0, Math.min(window.innerWidth - 100, popupX))}px`;
          zoomPopup.value.style.top = `${Math.max(0, Math.min(window.innerHeight - 100, popupY))}px`;
          zoomPopup.value.style.visibility = 'visible';
        }
      } else {
        hideZoomPopup();
      }
    }

    function handleTouchEnd(event: TouchEvent) {
      event.preventDefault();
      hideZoomPopup();

      if (!canvas.value) return;
      
      const rect = canvas.value.getBoundingClientRect();
      const scaleX = canvas.value.width / rect.width;
      const scaleY = canvas.value.height / rect.height;

      const touch = event.changedTouches[0];
      const x = (touch.clientX - rect.left) * scaleX;
      const y = (touch.clientY - rect.top) * scaleY;

      const ctx = canvas.value.getContext('2d');
      const pixel = ctx?.getImageData(Math.floor(x), Math.floor(y), 1, 1).data;

      if (pixel) {
        updateCurrentColorDisplay(pixel[0], pixel[1], pixel[2]);
        saveColorToHistory(pixel[0], pixel[1], pixel[2]);
      }
    }

    function hideZoomPopup() {
      if (zoomPopup.value) {
        zoomPopup.value.style.visibility = 'hidden';
      }
    }

    function updateCurrentColorDisplay(r: number, g: number, b: number) {
      const rgb = `rgb(${r}, ${g}, ${b})`;
      const hex = rgbToHex(r, g, b);
      const hsl = rgbToHsl(r, g, b);
      const cmyk = rgbToCmyk(r, g, b);

      colorInfo.rgb = rgb;
      colorInfo.hex = hex;
      colorInfo.hsl = hsl;
      colorInfo.cmyk = cmyk;

      if (currentColor.value) {
        currentColor.value.style.backgroundColor = rgb;
        currentColor.value.style.border = `1px solid ${rgb}`;
      }
    }

    function saveColorToHistory(r: number, g: number, b: number) {
  const rgb = `rgb(${r}, ${g}, ${b})`;

  // Check if the color already exists in the history
  const existingIndex = colorHistory.findIndex(color => color.rgb === rgb);
  
  if (existingIndex !== -1) {
    // If the color exists, remove it from its current position
    colorHistory.splice(existingIndex, 1);
  }

  // Add the new or moved color to the beginning of the array
  colorHistory.unshift({ r, g, b, rgb });

  // If we have more than 6 colors, remove the excess
  if (colorHistory.length > 6) {
    colorHistory.splice(6);
  }

  // Ensure we always have exactly 6 slots
  while (colorHistory.length < 6) {
    colorHistory.push({ r: 0, g: 0, b: 0, rgb: '' });
  }
}

// Update the removeColor function as well
function removeColor(index: number) {
  // Remove the color at the specified index
  colorHistory.splice(index, 1);
  
  // Add an empty placeholder to the end
  colorHistory.push({ r: 0, g: 0, b: 0, rgb: '' });
}

    function populateRandomColors() {
      randomColors.length = 0;
      for (let i = 0; i < 5; i++) {
        const { r, g, b } = generateRandomColor();
        const rgb = `rgb(${r}, ${g}, ${b})`;
        randomColors.push({ r, g, b, rgb });
      }
    }

    function generateRandomColor() {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      return { r, g, b };
    }

    function rgbToHex(r: number, g: number, b: number): string {
      return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    }

    function copyToClipboard(text: string, iconElement: EventTarget | null) {
      if (!iconElement) return;

      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      const originalClass = (iconElement as HTMLElement).className;
      (iconElement as HTMLElement).className = 'flaticon-check-1';

      setTimeout(() => {
        (iconElement as HTMLElement).className = originalClass;
      }, 2000);
    }

    function rgbToHsl(r: number, g: number, b: number) {
      r /= 255;
      g /= 255;
      b /= 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0;
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
      }
      s = Math.round(s * 100);
      l = Math.round(l * 100);
      h = Math.round(360 * h);
      return `hsl(${h}, ${s}%, ${l}%)`;
    }

    function rgbToCmyk(r: number, g: number, b: number) {
      let c = 1 - (r / 255);
      let m = 1 - (g / 255);
      let y = 1 - (b / 255);
      let k = Math.min(c, Math.min(m, y));

      if (k === 1) {
        c = m = y = 0;
      } else {
        c = (c - k) / (1 - k);
        m = (m - k) / (1 - k);
        y = (y - k) / (1 - k);
      }
      c = Math.round(c * 100);
      m = Math.round(m * 100);
      y = Math.round(y * 100);
      k = Math.round(k * 100);
      return `cmyk(${c}%, ${m}%, ${y}%, ${k}%)`;
    }

    function handleResize() {
      if (canvas.value) {
        const img = new Image();
        img.src = canvas.value.toDataURL();
        img.onload = () => {
          loadImage(img.src);
        };
      }
    }

    return {
      fileInput,
      canvas,
      currentColor,
      zoomPopup,
      imageURL,
      colorHistory,
      randomColors,
      colorInfo,
      handleFileUpload,
      loadImageFromURL,
      loadImage,
      loadSampleImage,
      setupCanvasEvents,
      handleCanvasClick,
      handleEvent,
      handleTouchEnd,
      hideZoomPopup,
      updateCurrentColorDisplay,
      saveColorToHistory,
      removeColor,
      populateRandomColors,
      generateRandomColor,
      copyToClipboard,
      rgbToHsl,
      rgbToCmyk,
      rgbToHex
    };
  }
});
</script>

<style scoped>
.sticky-parent {
  position: -webkit-sticky;
  position: sticky;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .sticky-parent {
    position: fixed;
    width: 25%;
    margin-top: 0;
  }
}

.related-color-block {
  flex: 1 1 calc(20% - 10px);
  margin: 5px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

#zoomPopup {
  border: 1px solid black;
  pointer-events: none;
  z-index: 9999;
}

.related-color-block::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.related-color-block .content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.hex-value {
  position: absolute;
  font-size: 18px;
}

#color-history-boxes {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.color-placeholder {
  position: relative;
  width: 100%;
  height: 50%;
  padding-bottom: 100%;
  border: 1px dashed #cccccc;
}

.color-box {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.delete-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  color: #000;
  padding: 2px;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  width: 16px;
  height: 16px;
  text-align: center;
}

.flaticon-copy {
  display: contents;
}

.canvas-container {
  max-width: 100%;
  overflow-x: hidden;
}

canvas {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .col-lg-8 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .hex-value {
    font-size: 12px;
  }
}
</style>