<template>
  <BreadCrumb PageTitle="Find and Replace Text Tool" />
  <div class="container custom-container mt-4 mb-50">
    <h1 class="text-center">Find and Replace Text Tool</h1>
    <p class="fs-6 text-center mb-20">
      This simple tool helps you find and replace text from the input you
      provide. Just paste your text, input your desired changes, and see the
      magic happen!
    </p>

    <!-- Alert for validation message -->
    <div v-if="alertMessage" class="alert alert-danger" role="alert">
      {{ alertMessage }}
    </div>

    <div class="form-group mb-4">
      <label for="inputText">Your Text:</label>
      <textarea
        class="form-control"
        id="inputText"
        rows="4"
        placeholder="Paste your text here..."
        v-model="inputText"
      ></textarea>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="input-group mb-4">
          <span class="input-group-text">Replace this:</span>
          <input
            type="text"
            class="form-control"
            id="findText"
            placeholder="Text to find"
            v-model="findText"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="input-group mb-4">
          <span class="input-group-text">With this:</span>
          <input
            type="text"
            class="form-control"
            id="replaceText"
            placeholder="Replacement text"
            v-model="replaceText"
          />
        </div>
      </div>
    </div>
    <div class="form-group form-check mb-2">
      <input
        type="checkbox"
        class="form-check-input"
        id="wholeWord"
        v-model="wholeWord"
      />
      <label class="form-check-label" for="wholeWord">Match Whole Words</label>
    </div>
    <div class="form-check mb-4">
      <input
        type="checkbox"
        class="form-check-input"
        id="caseSensitive"
        v-model="caseSensitive"
      />
      <label class="form-check-label" for="caseSensitive">Case Sensitive</label>
    </div>

    <div class="form-group mb-3">
      <label for="outputText">Result:</label>
      <textarea
        class="form-control"
        id="outputText"
        rows="4"
        readonly
        :value="outputText"
      ></textarea>
    </div>

    <div v-if="occurrences !== null" class="mb-3">
      <p>Occurrences affected: {{ occurrences }}</p>
    </div>

    <div class="row">
      <div class="col-md-6 mb-2">
        <button
          class="btn btn-primary w-100"
          @click="replaceTextFunction"
          data-bs-toggle="tooltip"
          title="Replace Text"
        >
          Replace Text
        </button>
      </div>
      <div class="col-md-6 mb-2">
        <button
          class="btn btn-danger w-100"
          @click="copyText"
          data-bs-toggle="tooltip"
          title="Copy Text"
        >
          Copy Text
        </button>
      </div>
    </div>
    <div>
      <FindAndReplaceTextFAQ />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import FindAndReplaceTextFAQ from '../FAQs/findAndReplaceTextFAQ.vue';

export default defineComponent({
  components: {
    BreadCrumb,
    FindAndReplaceTextFAQ
  },
  data() {
    return {
      inputText: "",
      findText: "",
      replaceText: "",
      wholeWord: false,
      caseSensitive: false,
      outputText: "",
      alertMessage: "",
      occurrences: null as number | null,
    };
  },
  methods: {
    replaceTextFunction() {
      if (!this.findText) {
        this.showAlert("Please enter the text to find.");
        return;
      }

      let flags = "g";
      if (!this.caseSensitive) {
        flags += "i";
      }

      let findPattern = this.wholeWord ? `\\b${this.findText}\\b` : this.findText;

      try {
        const regex = new RegExp(findPattern, flags);
        const matches = this.inputText.match(regex);
        this.occurrences = matches ? matches.length : 0;
        this.outputText = this.inputText.replace(regex, this.replaceText);
        this.alertMessage = "";
      } catch (error) {
        this.showAlert("Invalid find pattern.");
      }
    },
    copyText() {
      navigator.clipboard.writeText(this.outputText).then(
        () => {
          this.showAlert("Text copied to clipboard!");
        },
        () => {
          this.showAlert("Failed to copy text.");
        }
      );
    },
    showAlert(message: string) {
      this.alertMessage = message;
      setTimeout(() => {
        this.alertMessage = "";
      }, 2000);
    },
  },
});
</script>

<style scoped>
.custom-container {
  max-width: 90vh;
  margin: auto;
}
</style>