<template>
  <BreadCrumb PageTitle="Arcsin Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Arcsin Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter a value between -1 and 1 to calculate its arcsine (inverse sine). The result will be shown in both radians and degrees.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input Value for Arcsin -->
        <div class="mb-3">
          <label for="value" class="form-label">Value (x):</label>
          <input
            type="text"
            id="value"
            v-model="inputValue"
            class="form-control"
            placeholder="e.g., 0.5"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateArcsin" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <p v-if="result !== null" class="fs-5">
          sin<sup>-1</sup>({{ inputValue }}) = <strong>{{ result.radians }}</strong> radians ({{ result.degrees }}&deg;)
        </p>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter a value between -1 and 1 in the "Value" field.</li>
        <li>Click "Calculate" to get the arcsine of the entered value in both radians and degrees.</li>
        <li>Remember that arcsine is the same as the inverse sine, represented as sin<sup>-1</sup>(θ).</li>
      </ul>
      <h5 class="alert-heading">Formula for Calculating Arcsin:</h5>
      <p class="fs-6">
        The formula for calculating the arcsine of a value is:
      </p>
      <code class="fs-5">
        arcsin(x) = sin<sup>-1</sup>(x)
      </code>
      <p class="fs-6">
        where <strong>x</strong> is the value and the result is given in radians.
      </p>
      <p class="fs-6">
        To convert radians to degrees:
        <br />
        Degrees = Radians × (180/π)
      </p>
      <p class="fs-6">
        Example: If <strong>x = 0.5</strong>, then:
        <br />
        sin<sup>-1</sup>(0.5) = 0.5236 radians = 30&deg;
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const inputValue = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate the arcsine
    const calculateArcsin = () => {
      error.value = "";
      result.value = null;

      // Validate input
      if (!inputValue.value.trim()) {
        error.value = "Please enter a value between -1 and 1.";
        return;
      }

      let value = parseFloat(inputValue.value.trim());

      if (isNaN(value) || value < -1 || value > 1) {
        error.value = "Please enter a valid number between -1 and 1.";
        return;
      }

      // Calculate arcsin in radians
      let radians = Math.asin(value).toFixed(4);
      // Convert radians to degrees
      let degrees = (radians * (180 / Math.PI)).toFixed(2);

      result.value = { radians, degrees };
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      inputValue.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      inputValue,
      result,
      error,
      calculateArcsin,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
