<template>
  <div class="row">
    <div class="col-xxl-7 col-xxxl-8 px-3">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing cover-image-card"
      >
        <div class="card-body p-10 p-md-15">
          <h5 class="text-black fw-bold mb-10 mb-12">Our Mission</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            At <strong>Pixel Royals</strong>, we are committed to automating your tasks and making your life easier. Our mission is to provide you with efficient, user-friendly automation tools that streamline your workflow and increase productivity. 🛠️
          </p>
        </div>
      </div>
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing cover-image-card"
      >
        <div class="card-body p-10 p-md-15">
          <h5 class="text-black fw-bold mb-10 mb-12">Our Story</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            <strong>Pixel Royals</strong> was born out of a desire to simplify complex tasks through automation. We realized the need for reliable, easy-to-use tools that could handle a variety of automation needs. 💡
            <br />
            From a humble start-up to a growing platform, we're committed to delivering high-quality automation tools that make a real difference in your day-to-day activities.
          </p>
        </div>
      </div>

      <!-- New Section: Our Values -->
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing cover-image-card"
      >
        <div class="card-body p-10 p-md-15">
          <h5 class="text-black fw-bold mb-10 mb-12">Our Values</h5>
          <ul class="info-list ps-0 mb-0 list-unstyled">
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative mb-3">
              <strong>Innovation:</strong> We believe in constant innovation to bring the best tools to our users.
            </li>
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative mb-3">
              <strong>Quality:</strong> Our focus is on delivering tools that are both reliable and high-quality.
            </li>
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative mb-3">
              <strong>User-Centric:</strong> Our users are at the heart of everything we do. We value your feedback and strive to create tools that truly meet your needs.
            </li>
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative mb-3">
              <strong>Integrity:</strong> We are committed to transparency, honesty, and maintaining the highest ethical standards.
            </li>
          </ul>
        </div>
      </div>

      <!-- New Section: Achievements -->
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing cover-image-card"
      >
        <div class="card-body p-10 p-md-15">
          <h5 class="text-black fw-bold mb-10 mb-12">Our Achievements</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Since our inception, <strong>Pixel Royals</strong> has been trusted by thousands of users worldwide. Our tools have been featured in leading tech platforms and have helped countless individuals and businesses save time and effort.
          </p>
          <ul class="info-list ps-0 mb-0 list-unstyled">
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative mb-3">
              🏆 Over 1 million tasks automated using our tools.
            </li>
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative mb-3">
              🌟 Rated 4.8/5 by our users across various platforms.
            </li>
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative mb-3">
              📈 Continuous growth in our user base and tool offerings.
            </li>
          </ul>
        </div>
      </div>

      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing cover-image-card"
      >
        <div class="card-body p-10 p-md-15">
          <h5 class="text-black fw-bold mb-10 mb-12">Our Future</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            We're just getting started! Our goal is to expand our range of tools and continue to innovate, making automation accessible to everyone. Stay tuned for new features, tools, and updates as we continue to grow and evolve. 🚀
          </p>
        </div>
      </div>
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing contact-card"
      >
        <div class="card-body p-20 p-md-25 p-lg-30">
          <h5 class="text-black fw-bold mb-10 mb-12">Contact Us</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Got questions? Suggestions? We'd love to hear from you! Reach out to us at:
            <a href="mailto:admin@pixelroyals.com" class="text-decoration-none">admin@pixelroyals.com</a>
          </p>
        </div>
      </div>
    </div>

    <!-- Sidebar -->
    <div class="col-lg-3">
      <BlogSidebar />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BlogSidebar from '../../../pages/Dashboard/BlogSidebar.vue';

export default defineComponent({
  name: "AboutContent",
  components: {
    BlogSidebar,
  },
});
</script>

<style scoped>
.card {
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  color: #44505b;
  font-weight: bold;
}

a {
  transition: color 0.2s;
}

a:hover {
  color: #ffffff !important;
  text-decoration: none;
}

/* New padding for better layout alignment */
.row {
  padding-left: 15px;
  padding-right: 15px;
}

.card-body {
  padding: 10px 15px;
}

@media (max-width: 768px) {
  .card-body {
    padding: 10px;
  }
}

@media (max-width: 576px) {
  .row {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
