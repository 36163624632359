<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on HTML to BBCode Converter</h3>
                    </div>
                    <!-- FAQ 1 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the HTML to BBCode Converter?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to BBCode Converter is a tool that converts HTML markup into BBCode, a simplified markup language commonly used in forums and message boards. BBCode offers a more restricted way to format text while ensuring compatibility across various platforms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 2 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the HTML to BBCode Converter?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To use the HTML to BBCode Converter:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Paste your HTML content into the input field.</li>
                                    <li>Click the <strong>Convert</strong> button to transform the HTML into BBCode.</li>
                                    <li>The result will be displayed as BBCode in the output field.</li>
                                    <li>Copy the BBCode and use it in your desired forum or message board.</li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This process simplifies converting complex HTML into forum-friendly BBCode.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 3 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What is BBCode?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    BBCode stands for Bulletin Board Code, and it is a lightweight markup language used to format posts in forums and message boards. It uses square brackets <strong>[]</strong> to wrap text with formatting options, like bold, italics, or adding links.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 4 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>What types of HTML can be converted to BBCode?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The converter supports many common HTML tags that are often needed in forums, including:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>Text formatting:</strong> &lt;b&gt;, &lt;i&gt;, &lt;u&gt; to BBCode equivalents like [b], [i], [u]</li>
                                    <li><strong>Links:</strong> &lt;a&gt; tag for hyperlinks to [url] in BBCode</li>
                                    <li><strong>Images:</strong> &lt;img&gt; to [img] in BBCode</li>
                                    <li><strong>Lists:</strong> Ordered and unordered lists in HTML to [list] tags in BBCode</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    However, not all HTML elements are supported. Complex HTML like tables or CSS-based styles cannot be accurately converted to BBCode.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 5 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>Can I convert back from BBCode to HTML?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This tool is specifically designed to convert HTML into BBCode. To convert BBCode back into HTML, you would need a different tool, often provided by forum platforms or online converters that work in reverse.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 6 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Why would I need to convert HTML to BBCode?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Many online forums and message boards do not support HTML for security and compatibility reasons. BBCode is a safer and simpler markup language that is widely used for text formatting in forum posts. Converting your HTML content to BBCode allows you to retain basic formatting without using complex HTML.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 7 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>What should I do if the BBCode doesn't render correctly?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    If the BBCode doesn't render correctly in your forum or message board, make sure that:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>The forum or platform supports BBCode.</li>
                                    <li>You copied the BBCode correctly from the converter.</li>
                                    <li>The BBCode tags are complete and properly nested.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Some forums might use custom BBCode variations, so consult the forum's help section for the correct formatting rules.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Additional FAQs as needed -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HTMLtoBBCodeFAQ',
};
</script>