<template>
  <BreadCrumb PageTitle="HTML To Markdown" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">HTML To Markdown</h1>
    <p class="fs-6 text-center mb-4">Transform your HTML into clean, readable Markdown instantly. Enjoy features like HTML beautification and live preview to ensure seamless conversion and effortless editing.</p>
    <div class="row mb-4">
      <div class="col-md-6 mb-4 mb-md-0">
        <div class="markdown mb-2">
          <label for="inputHTML">Input (HTML):</label>
          <div class="ms-3 form-check">
            <input type="checkbox" class="form-check-input" id="beautifyHTMLCheckbox" v-model="beautifyHTML" @change="handleInput">
            <label for="beautifyHTMLCheckbox" class="form-check-label">Beautify HTML</label>
          </div>
        </div>
        <textarea id="inputHTML" class="form-control" rows="25" v-model="inputHTML" @input="handleInput"></textarea>
      </div>
      <div class="col-md-6">
        <div class="markdown mb-2 d-flex justify-content-between align-items-center">
          <label>{{ livePreview ? 'Live Preview' : 'Output (Markdown):' }}</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="livePreviewCheckbox" v-model="livePreview">
            <label class="form-check-label" for="livePreviewCheckbox">Live Preview</label>
          </div>
        </div>
        <div class="position-relative output-wrapper">
          <textarea v-if="!livePreview" id="outputMD" class="form-control" rows="25" readonly :value="outputMarkdown"></textarea>
          <div v-else id="markdownPreview" class="form-control markdown-preview" v-html="markdownHTML"></div>
          <i 
            :class="['bi', copied ? 'bi-clipboard-check' : 'bi-clipboard', 'copy-icon']" 
            @click="copyToClipboard"
            :title="copied ? 'Copied!' : 'Copy to clipboard'"
          ></i>
        </div>
      </div>
    </div>

    <div class="alert alert-danger mt-3" v-if="error">Error converting HTML to Markdown!</div>
    <div>
      <HtmlToMarkdownFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import HtmlToMarkdownFAQ from '../FAQs/htmlToMarkdownFAQ.vue';
import { html as beautifyHTML } from 'js-beautify';
import TurndownService from 'turndown';
import { marked } from 'marked';

export default {
  components: {
    BreadCrumb,
    HtmlToMarkdownFAQ
  },
  data() {
    return {
      inputHTML: '',
      beautifyHTML: false,
      livePreview: false,
      outputMarkdown: '',
      error: false,
      copied: false,
    };
  },
  computed: {
    markdownHTML() {
      return marked(this.outputMarkdown);
    }
  },
  methods: {
    handleInput() {
      if (this.beautifyHTML) {
        this.inputHTML = beautifyHTML(this.inputHTML, { indent_size: 2, space_in_empty_paren: true });
      }
      this.convertToMarkdown();
    },
    convertToMarkdown() {
      try {
        const turndownService = new TurndownService();
        this.outputMarkdown = turndownService.turndown(this.inputHTML);
        this.error = false;
      } catch (e) {
        this.error = true;
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.outputMarkdown).then(() => {
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      });
    }
  }
};
</script>

<style scoped>
.markdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-container {
  width: 90vw;
  max-width: 1200px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    padding: 0 0.5rem;
  }
}
label, .form-control {
  font-size: 16px;
}
@media (max-width: 767px) {
  .row {
    flex-direction: column;
  }
}
.output-wrapper {
  position: relative;
}
.copy-icon {
  position: absolute;
  bottom: 10px;
  right: 25px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #6c757d;
  z-index: 1;
}
.copy-icon:hover {
  color: #495057;
}
.markdown-preview {
  height: calc(18 * 1.5em + 0.20rem);
  overflow-y: auto;
  padding-right: 40px;
}
textarea {
  resize: vertical;
  padding-right: 40px;
}
</style>