<template>
  <BreadCrumb PageTitle="Multiplication Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Multiplication Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Multiply two or more numbers quickly and easily. Enter the numbers you want to multiply, and the calculator will provide the product along with the calculation steps.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Numbers -->
        <div class="mb-3" v-for="(number, index) in numbers" :key="index">
          <label :for="'number' + index" class="form-label">Number {{ index + 1 }}:</label>
          <input
            type="text"
            :id="'number' + index"
            v-model="number.value"
            class="form-control"
            placeholder="e.g., 2, 3.5"
          />
        </div>

        <!-- Button to Add More Numbers -->
        <div class="mb-3">
          <button @click="addNumber" class="btn btn-success">Add Another Number</button>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateProduct" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Product: {{ result.product }}</p>
          <p class="fs-5">Calculation Steps: {{ result.steps }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter two or more numbers you wish to multiply.</li>
        <li>Click "Calculate" to find the product of the numbers.</li>
        <li>The result will display the product and the calculation steps.</li>
        <li>Click "Add Another Number" to include more numbers in the calculation.</li>
        <li>Click "Clear" to reset the input fields and results.</li>
      </ul>

      <h5 class="alert-heading">Multiplication Formula:</h5>
      <div class="fs-6">
        The product of multiple numbers \( a_1, a_2, a_3, \ldots, a_n \) is calculated as:
      </div>
      <div>
        \[
        \text{Product} = a_1 \times a_2 \times a_3 \times \ldots \times a_n
        \]
      </div>

      <h5 class="alert-heading">Example Calculation:</h5>
      <ul class="fs-6">
        <li>
          For \( 2 \times 3 \times 4 \):<br />
          Multiply the numbers: \( 2 \times 3 = 6 \), then \( 6 \times 4 = 24 \).<br />
          **Product**: 24
        </li>
        <li>
          For \( 1.5 \times 2 \times 5 \):<br />
          Multiply the numbers: \( 1.5 \times 2 = 3 \), then \( 3 \times 5 = 15 \).<br />
          **Product**: 15
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const numbers = ref([{ value: "" }, { value: "" }]);
    const result = ref(null);
    const error = ref("");

    // Function to add more numbers
    const addNumber = () => {
      numbers.value.push({ value: "" });
    };

    // Function to calculate the product of multiple numbers
    const calculateProduct = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse and validate each number
        const parsedNumbers = numbers.value.map((number) => parseFloat(number.value.trim()));

        if (parsedNumbers.some((num) => isNaN(num))) {
          throw new Error("Please enter valid numbers.");
        }

        // Calculate the product of all numbers
        let product = 1;
        let steps = "Multiplying the numbers:\n";

        parsedNumbers.forEach((num, index) => {
          product *= num;
          steps += `${index > 0 ? "× " : ""}${num} `;
        });

        steps += `= ${product}`;

        result.value = {
          product: product,
          steps: steps,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      numbers.value = [{ value: "" }, { value: "" }];
      result.value = null;
      error.value = "";
    };

    return {
      numbers,
      result,
      error,
      addNumber,
      calculateProduct,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
