<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
      <div class="card-body p-15 p-sm-20">
        <div class="accordion" id="faqAccordion">
          <div class="row">
            <div class="text-center mb-3">
              <h3>FAQs on Resize Image To Online Tool</h3>
            </div>
            
            <div class="accordion-item rounded-0 border-0">
              <button
                class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faqCollapseOne"
                aria-expanded="true"
                aria-controls="faqCollapseOne"
              >
                <h5>What is "Resize Image To" Online Tool?</h5>
              </button>
              <div
                id="faqCollapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#faqAccordion"
              >
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    The "Resize Image To" online tool allows users to easily resize their images to a specified file size. Whether you need an image for a website, social media, or any other platform, this tool helps you compress and adjust the image size while maintaining the best possible quality.
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item rounded-0 border-0">
              <button
                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faqCollapseTwo"
                aria-expanded="false"
                aria-controls="faqCollapseTwo"
              >
                <h5>How to Resize An Image To Any Size</h5>
              </button>
              <div
                id="faqCollapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    To resize an image using the "Resize Image To" tool, follow these steps:
                    <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      <li>Upload your image by dragging and dropping it into the upload area or by clicking to select the file from your device.</li>
                      <li>Once the image is uploaded, you will see a preview of the original image along with its size.</li>
                      <li>Specify the target size in kilobytes (KB) in the input field provided.</li>
                      <li>Click the "Resize" button to start the resizing process.</li>
                      <li>After resizing, you will see a preview of the resized image along with its new size.</li>
                      <li>If satisfied with the result, click the "Download" button to save the resized image to your device.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item rounded-0 border-0">
              <button
                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faqCollapseThree"
                aria-expanded="false"
                aria-controls="faqCollapseThree"
              >
                <h5>Key Features of the Tool</h5>
              </button>
              <div
                id="faqCollapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    The "Resize Image To" online tool offers several key features:
                    <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      <li><b>Easy Image Upload</b>: Drag and drop your image or click to upload.</li>
                      <li><b>Dynamic Resizing</b>: Specify any target size in KB for your image.</li>
                      <li><b>Quality Maintenance</b>: The tool optimizes the image to maintain the best possible quality during resizing.</li>
                      <li><b>Real-time Preview</b>: View both the original and resized images side by side for easy comparison.</li>
                      <li><b>Download Option</b>: Quickly download the resized image to your device.</li>
                      <li><b>User-friendly Interface</b>: Simple and intuitive design for easy use.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'ResizeImageToFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  