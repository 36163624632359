<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
    <div class="card-body p-15 p-sm-20">
        <div class="accordion" id="faqAccordion">
            <div class="row">
                <div class="text-center mb-3">
                    <h3>FAQs on Annular Velocity Calculator</h3>
                </div>

                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                        <h5>Why is annular velocity important in drilling?</h5>
                    </button>
                    <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                Annular velocity is essential for efficiently carrying drilling cuttings to the surface, which helps maintain wellbore stability and prevent blowouts.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="false" aria-controls="faqCollapseTwo">
                        <h5>What is a typical annular velocity range?</h5>
                    </button>
                    <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                The optimal annular velocity depends on several factors including the size of the cuttings and the viscosity of the fluid. Typical values range from 80 to 150 feet per minute.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="false" aria-controls="faqCollapseThree">
                        <h5>Can this calculator be used for any fluid type?</h5>
                    </button>
                    <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                Yes, the calculator can be used for any type of fluid, as long as the flow rate is known in gallons per minute.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="false" aria-controls="faqCollapseFour">
                        <h5>How to use the Annular Velocity Calculator</h5>
                    </button>
                    <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                To use this calculator effectively, you typically need to:
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Enter the diameter of the hole or casing in inches into the 'Hole Diameter' field.</li>
                                    <li>Input the diameter of the pipe or drill string in inches into the 'Pipe Diameter' field.</li>
                                    <li>Type the flow rate of the fluid in gallons per minute into the 'Flow Rate' field.</li>
                                    <li>Click the "Calculate" button to compute the annular velocity.</li>
                                </ol>
                                <em class="fs-md-15 fs-lg-16 text-paragraph lh-base">If the hole diameter is 9 inches, the pipe diameter is 5 inches, and the flow rate is 350 gallons per minute, the calculator will determine the annular velocity needed for optimal drilling mud performance.</em>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="false" aria-controls="faqCollapseFive">
                        <h5>Annular Velocity Formula</h5>
                    </button>
                    <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                The annular velocity (AV) is calculated using the formula:
                                <br>
                                AV = Q / A
                                <br>
                                where:
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Q is the flow rate of the fluid (gallons per minute).</li>
                                    <li>A is the cross-sectional area of the annulus (square feet), calculated as π/4 * (Dh² - Dp²) / 144.</li>
                                    <li>Dh is the diameter of the hole or casing (inches).</li>
                                    <li>Dp is the diameter of the pipe or drill string (inches).</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'AnnularVelocityCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
