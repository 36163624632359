<template>
  <div class="row">
    <!-- Main Content Area -->
    <div class="col-xxl-8 col-xl-9">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing terms-conditions-card">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <h5 class="text-black fw-bold mb-10 mb-12">Introduction</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Welcome to <strong>Pixel Royals</strong>! These Terms of Service ("Terms") govern your use of our website located at <a href="https://pixelroyals.com" class="text-decoration-none">PixelRoyals.com</a> and any related services provided by Pixel Royals.
          </p>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            By accessing or using the website, you agree to abide by these Terms. If you disagree with any part of the Terms, then you may not access the website.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">Privacy Policy</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            For information about how we collect and use your personal data, please refer to our <a href="https://pixelroyals.com/privacy-policy" class="text-decoration-none">Privacy Policy</a>.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">User Obligations</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            As a user of Pixel Royals:
          </p>
          <ul class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            <li>You agree not to use the website for any unlawful purpose or to violate any international, federal, or local laws/regulations.</li>
            <li>You agree not to distribute malicious software or spam through our platform.</li>
            <li>You are responsible for any content you post or submit to the website.</li>
          </ul>
          <h5 class="text-black fw-bold mb-10 mb-12">Intellectual Property</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            All content available on Pixel Royals, including but not limited to text, graphics, logos, and software, is the property of Pixel Royals and is protected by copyright laws.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">Limitation of Liability</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Pixel Royals, its employees, and its affiliates will not be liable for any indirect, consequential, or incidental damages that result from the use or inability to use our services.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">Termination</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Pixel Royals reserves the right to terminate or suspend access to our website without prior notice or liability for any reason, including but not limited to a breach of the Terms.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">Changes to Terms</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Pixel Royals reserves the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">How you can contact us</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            If you have any questions about these Terms, please contact us at:
            <a href="mailto:admin@pixelroyals.com" class="text-decoration-none">
              admin@pixelroyals.com
            </a>.
          </p>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Last updated on September 4, 2023.
          </p>
        </div>
      </div>
    </div>

    <!-- Sidebar Area -->
    <div class="col-xxl-4 col-xl-3">
      <BlogSidebar />
    </div>
  </div>
</template>

<script>
import BlogSidebar from "../../../pages/Dashboard/BlogSidebar.vue";

export default {
  name: "TermsAndConditions",
  components: {
    BlogSidebar,
  },
};
</script>
