<template>
	<BreadCrumb PageTitle="Farm Name Generator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Farm Name Generator 🌾</h1>
	  <p class="fs-6 text-center mb-4">Grow your brand with our Farm Name Generator! Find the perfect name that embodies your agricultural vision. 🌾 Start naming today!</p>
	  <div class="col-md-12">
		<div class="mb-3">
		  <div class="row mt-4">
			<div class="col-md-12">
			  <div class="input-group">
				<span class="input-group-text">No. of Farm names:</span>
				<input v-model="numberOfNames" class="form-control" type="number" max="50" min="1" placeholder="1" />
			  </div>
			</div>
		  </div>
		  <div class="row mt-4">
			<div class="col-12">
			  <div class="input-group mb-2">
				<span class="input-group-text">Starts</span>
				<input v-model="startsWith" class="form-control" type="text" placeholder="Starts with" />
				<input v-model="endsWith" class="form-control" type="text" placeholder="Ends with" />
				<span class="input-group-text">Ends</span>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	  <div class="col">
		<button @click="generateNames" class="btn btn-primary d-flex justify-content-center align-items-center mb-4 btn-hover w-100" data-bs-toggle="tooltip" title="Generate Random Farm Names">
		  Get Names
		</button>
	  </div>
	  <div class="col-md-12">
		  <h4 class="text-center">Generated Farm Names</h4>
		  <div v-if="showError" class="alert alert-warning mt-2" role="alert">
			<span>{{ errorMessage }}</span>
		  </div>
		  <div class="generated-names fs-5">
			<span @click="copyNames" class="copy-icon">
			  <i :class="copyIconClass"></i>
			</span>
			<p>{{ generatedNames }}</p>
		  </div>
	  </div>
	  
	  <!-- FAQ Section -->
	  <div class="mt-5">
		<FarmNameGeneratorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import FarmNames from '../../components/JSON/FarmNames.json';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import FarmNameGeneratorFAQ from '../FAQs/farmNameGeneratorFAQ.vue';
  
  export default {
	name: 'FarmNameGenerator',
	components: {
	  BreadCrumb,
	  FarmNameGeneratorFAQ
	},
	setup() {
	  const numberOfNames = ref(1);
	  const startsWith = ref('');
	  const endsWith = ref('');
	  const generatedNames = ref('No names generated yet.');
	  const showError = ref(false);
	  const errorMessage = ref('');
	  const copyIconClass = ref('bi bi-clipboard');
  
	  const filteredFirstNames = computed(() => {
		return FarmNames.firstNames.filter(name => 
		  name.toLowerCase().startsWith(startsWith.value.toLowerCase())
		);
	  });
  
	  const filteredLastNames = computed(() => {
		return FarmNames.lastNames.filter(name => 
		  name.toLowerCase().endsWith(endsWith.value.toLowerCase())
		);
	  });
  
	  const generateNames = () => {
		if (numberOfNames.value < 1) {
		  showError.value = true;
		  errorMessage.value = "Please enter a number of Farm names to generate.";
		  return;
		}
  
		const maxPossibleCombinations = filteredFirstNames.value.length * filteredLastNames.value.length;
		if (numberOfNames.value > maxPossibleCombinations) {
		  showError.value = true;
		  errorMessage.value = `Cannot generate ${numberOfNames.value} unique names. Maximum possible unique combinations: ${maxPossibleCombinations}`;
		  return;
		}
  
		showError.value = false;
		const names = new Set();
		while (names.size < numberOfNames.value) {
		  const firstName = filteredFirstNames.value[Math.floor(Math.random() * filteredFirstNames.value.length)];
		  const lastName = filteredLastNames.value[Math.floor(Math.random() * filteredLastNames.value.length)];
		  names.add(`${firstName} ${lastName}`);
		}
		generatedNames.value = Array.from(names).join(', ');
	  };
  
	  const copyNames = () => {
		navigator.clipboard.writeText(generatedNames.value);
		copyIconClass.value = 'bi bi-clipboard-check';
		setTimeout(() => {
		  copyIconClass.value = 'bi bi-clipboard';
		}, 2000);
	  };
  
	  return {
		numberOfNames,
		startsWith,
		endsWith,
		generatedNames,
		showError,
		errorMessage,
		copyIconClass,
		generateNames,
		copyNames
	  };
	}
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  .copy-icon {
	cursor: pointer;
	float: right;
  }
  .generated-names {
	margin-top: 20px;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
  }
  </style>