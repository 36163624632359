<template>
	<BreadCrumb PageTitle="Five Number Summary Calculator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Five Number Summary Calculator</h1>
	  <p class="fs-6 text-center mb-4">Online 5 number summary calculator for quick statistical analysis. Calculate mean, median, quartiles, and more.</p>
  
	  <div class="col-md-12">
		<div>
		  <label class="form-label" for="numInput">Enter numbers separated by comma, space, or new lines:</label>
		  <textarea id="numInput" v-model="input" class="w-100 form-control" placeholder="1,2,4,5,8 or 1 3 4 7 11" rows="3"></textarea>
		</div>
	  </div>
	  <div>
		<div v-if="error" class="alert alert-warning mt-2" role="alert">
		  {{ error }}
		</div>
		<div class="row mt-4">
		  <div class="col-md-6 mb-2">
			<button @click="calculate" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Calculate Numbers">Calculate</button>
		  </div>
		  <div class="col-md-6 mb-2">
			<button @click="reset" class="btn btn-danger w-100" data-bs-toggle="tooltip" title="Reset Screen">Reset</button>
		  </div>
		</div>
	  </div>
	  <div class="col-md-12">
		<div class="mt-4">
		  <h4 class="text-center">Calculation:</h4>
		  <div class="card mt-4">
			<div class="card-body">
			  <p v-if="!result">No calculations generated yet.</p>
			  <template v-else>
				<p>Count: {{ result.count }}</p>
				<p>Sum: {{ result.sum }}</p>
				<p>Mean: {{ result.mean }}</p>
				<p>Min: {{ result.min }}</p>
				<p>Q1 (25th Percentile): {{ result.q1 }}</p>
				<p>Median (50th Percentile): {{ result.median }}</p>
				<p>Q3 (75th Percentile): {{ result.q3 }}</p>
				<p>Max: {{ result.max }}</p>
				<p>Range: {{ result.range }}</p>
				<p>Variance: {{ result.variance }}</p>
				<p>Standard Deviation: {{ result.stdDev }}</p>
			  </template>
			</div>
		  </div>
		</div>
	  </div>
	  <div class="mt-4">
		<FiveNumberSummaryCalculatorFAQ />
	  </div>
	</div>
</template>

<script>
import { ref, computed } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import FiveNumberSummaryCalculatorFAQ from '../FAQs/fiveNumberSummaryCalculatorFAQ.vue';

export default {
	name: 'FiveNumberSummaryCalculator',
	components: {
	  BreadCrumb,
	  FiveNumberSummaryCalculatorFAQ
	},
	setup() {
	  const input = ref('');
	  const error = ref('');
	  const result = ref(null);

	  const numbers = computed(() => {
		return input.value
		  .split(/[,\s\n]+/)
		  .map(Number)
		  .filter(n => !isNaN(n));
	  });

	  function calculate() {
		if (numbers.value.length === 0) {
		  error.value = 'Please enter numbers to calculate!';
		  result.value = null;
		  return;
		}

		error.value = '';
		const sortedNumbers = [...numbers.value].sort((a, b) => a - b);
		const count = sortedNumbers.length;
		const sum = sortedNumbers.reduce((a, b) => a + b, 0);
		const mean = sum / count;
		const min = sortedNumbers[0];
		const max = sortedNumbers[count - 1];
		const median = getMedian(sortedNumbers);
		const q1 = getMedian(sortedNumbers.slice(0, Math.floor(count / 2)));
		const q3 = getMedian(sortedNumbers.slice(Math.ceil(count / 2)));
		const range = max - min;
		const variance = sortedNumbers.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / count;
		const stdDev = Math.sqrt(variance);

		result.value = {
		  count,
		  sum: sum.toFixed(2),
		  mean: mean.toFixed(2),
		  min,
		  q1: q1.toFixed(2),
		  median: median.toFixed(2),
		  q3: q3.toFixed(2),
		  max,
		  range: range.toFixed(2),
		  variance: variance.toFixed(2),
		  stdDev: stdDev.toFixed(2)
		};
	  }

	  function getMedian(arr) {
		const mid = Math.floor(arr.length / 2);
		return arr.length % 2 === 0 ? (arr[mid - 1] + arr[mid]) / 2 : arr[mid];
	  }

	  function reset() {
		input.value = '';
		error.value = '';
		result.value = null;
	  }

	  return {
		input,
		error,
		result,
		calculate,
		reset
	  };
	}
}
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

label,
.form-control,
.card-body {
  font-size: 16px;
}

textarea {
  resize: none;
  overflow: hidden;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
