<template>
  <BreadCrumb PageTitle="Random Number Generator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Random Number Generator</h1>
    <p class="fs-6 text-center mb-4">
      Generate random numbers within a specified range. You can generate single or multiple random numbers, and choose whether to allow duplicates.
    </p>

    <!-- Input and Generation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Minimum Value -->
        <div class="mb-3">
          <label for="minValue" class="form-label">Minimum Value:</label>
          <input
            type="number"
            id="minValue"
            v-model="minValue"
            class="form-control"
            placeholder="e.g., 1"
          />
        </div>

        <!-- Input for Maximum Value -->
        <div class="mb-3">
          <label for="maxValue" class="form-label">Maximum Value:</label>
          <input
            type="number"
            id="maxValue"
            v-model="maxValue"
            class="form-control"
            placeholder="e.g., 100"
          />
        </div>

        <!-- Input for Quantity -->
        <div class="mb-3">
          <label for="quantity" class="form-label">Quantity of Numbers to Generate:</label>
          <input
            type="number"
            id="quantity"
            v-model="quantity"
            class="form-control"
            placeholder="e.g., 5"
          />
        </div>

        <!-- Option to Allow Duplicates -->
        <div class="mb-3 form-check">
          <input
            type="checkbox"
            id="allowDuplicates"
            v-model="allowDuplicates"
            class="form-check-input"
          />
          <label for="allowDuplicates" class="form-check-label">Allow Duplicates</label>
        </div>

        <!-- Generation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="generateNumbers" class="btn btn-primary w-100">Generate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Generated Numbers:</h4>
        <div v-if="result.length > 0">
          <p class="fs-5">{{ result.join(', ') }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the minimum and maximum values for the range of random numbers.</li>
        <li>Specify the quantity of random numbers you want to generate.</li>
        <li>Check "Allow Duplicates" if you want the numbers to potentially repeat.</li>
        <li>Click "Generate" to create the random numbers. The generated numbers will be displayed below.</li>
        <li>Click "Clear" to reset the input fields and results.</li>
      </ul>

      <h5 class="alert-heading">About Random Number Generation:</h5>
      <p class="fs-6">
        Random number generation is a process by which a sequence of numbers or symbols is generated in a way that cannot be reasonably predicted better than by random chance. This calculator uses JavaScript's built-in <code>Math.random()</code> function to generate random numbers.
      </p>

      <h5 class="alert-heading">Example Usage:</h5>
      <ul class="fs-6">
        <li>
          Generate 5 random numbers between 1 and 100 without duplicates:
          <br />
          Possible output: 23, 67, 89, 2, 45
        </li>
        <li>
          Generate 3 random numbers between 10 and 20 with duplicates allowed:
          <br />
          Possible output: 15, 12, 15
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const minValue = ref("");
    const maxValue = ref("");
    const quantity = ref("");
    const allowDuplicates = ref(false);
    const result = ref([]);
    const error = ref("");

    // Function to generate random numbers
    const generateNumbers = () => {
      error.value = "";
      result.value = [];

      try {
        // Parse input values
        const min = parseInt(minValue.value);
        const max = parseInt(maxValue.value);
        const qty = parseInt(quantity.value);

        if (isNaN(min) || isNaN(max) || isNaN(qty)) {
          throw new Error("Please enter valid numeric values.");
        }

        if (min > max) {
          throw new Error("Minimum value cannot be greater than maximum value.");
        }

        if (qty <= 0) {
          throw new Error("Quantity must be a positive integer.");
        }

        const range = max - min + 1;

        if (!allowDuplicates.value && qty > range) {
          throw new Error(
            "Quantity cannot be greater than the range of numbers when duplicates are not allowed."
          );
        }

        const numbers = [];

        if (allowDuplicates.value) {
          // Generate numbers with duplicates allowed
          for (let i = 0; i < qty; i++) {
            const randNum = Math.floor(Math.random() * range) + min;
            numbers.push(randNum);
          }
        } else {
          // Generate numbers without duplicates
          const possibleNumbers = [];
          for (let i = min; i <= max; i++) {
            possibleNumbers.push(i);
          }
          for (let i = 0; i < qty; i++) {
            const randomIndex = Math.floor(Math.random() * possibleNumbers.length);
            numbers.push(possibleNumbers[randomIndex]);
            possibleNumbers.splice(randomIndex, 1);
          }
        }

        result.value = numbers;
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      minValue.value = "";
      maxValue.value = "";
      quantity.value = "";
      allowDuplicates.value = false;
      result.value = [];
      error.value = "";
    };

    return {
      minValue,
      maxValue,
      quantity,
      allowDuplicates,
      result,
      error,
      generateNumbers,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
