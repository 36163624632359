<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
		<div class="card-body p-15 p-sm-20">
			<div class="accordion" id="faqAccordion">
				<div class="row">
					<div class="text-center mb-3">
						<h3>FAQs on Antoine Equation Calculator</h3>
					</div>
					<div class="accordion-item rounded-0 border-0">
						<button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
							<h5>What is the Antoine Equation?</h5>
						</button>
						<div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
							<div class="accordion-body pb-0">
								<p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
									The Antoine Equation is a mathematical expression used to calculate the vapor pressure of a substance at a specific temperature. It is widely used in thermodynamics and is given by the formula:
									<br>
									P = 10^(A - B / (C + T)),
									<br>
									where P is the vapor pressure, T is the temperature in degrees Celsius, and A, B, C are empirical coefficients specific to each substance.
								</p>
							</div>
						</div>
					</div>

					<div class="accordion-item rounded-0 border-0">
						<button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="false" aria-controls="faqCollapseTwo">
							<h5>What is the range of temperatures I can use with this calculator?</h5>
						</button>
						<div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
							<div class="accordion-body pb-0">
								<p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
									The calculator is accurate within the temperature range specific to the provided coefficients. Typically, these coefficients are valid for the liquid phase near the normal boiling point of the substance.
								</p>
							</div>
						</div>
					</div>

					<div class="accordion-item rounded-0 border-0">
						<button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="false" aria-controls="faqCollapseThree">
							<h5>Where can I find Antoine coefficients for different substances?</h5>
						</button>
						<div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
							<div class="accordion-body pb-0">
								<p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
									Antoine coefficients can be found in chemical engineering databases and textbooks, such as the CRC Handbook of Chemistry and Physics, or various online resources.
								</p>
							</div>
						</div>
					</div>

					<div class="accordion-item rounded-0 border-0">
						<button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="false" aria-controls="faqCollapseFour">
							<h5>Can this calculator be used for mixtures?</h5>
						</button>
						<div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
							<div class="accordion-body pb-0">
								<p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
									No, the Antoine Equation Calculator is intended for pure substances. The behavior of mixtures can be significantly more complex and may require different models or equations.
								</p>
							</div>
						</div>
					</div>

					<div class="accordion-item rounded-0 border-0">
						<button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="false" aria-controls="faqCollapseFive">
							<h5>How to use the Antoine Equation Calculator</h5>
						</button>
						<div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
							<div class="accordion-body pb-0">
								<p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
									Using the Antoine Equation Calculator is straightforward:
									<ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
										<li>Input the coefficients A, B, and C for the substance into their respective fields.</li>
										<li>Enter the temperature in degrees Celsius in the 'Temperature' field.</li>
										<li>Click the "Calculate" button to get the vapor pressure of the substance.</li>
									</ol>
									<em class="fs-md-15 fs-lg-16 text-paragraph lh-base">Example: To find the vapor pressure of water at 85°C, enter A = 8.07131, B = 1730.63, and C = 233.426 into the calculator and press calculate.</em>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'AntoineEquationCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
