<template>
  <BreadCrumb PageTitle="Random Bible Verse Generator" />
  <div class="custom-container mt-5 mb-50">
    <h1 class="text-center">Random Bible Verse Generator</h1>
    <p class="fs-6 text-center mb-4">Generate random Bible verses and share with friends and family.</p>
    <div class="col-md-12">
      <div class="row mt-4 mb-3">
        <div class="input-group mb-2">
          <span class="input-group-text">No. of verses:</span>
          <input v-model="verseNum" class="form-control" type="number" max="12" min="1" />
        </div>
      </div>
      <button @click="generateVerses" class="btn btn-primary d-flex justify-content-center align-items-center mb-4 w-100" data-bs-toggle="tooltip" title="Generate Random Verses">
        Generate Verses
      </button>
      <div>
        <h4 class="text-center">Generated Bible Verses</h4>
        <div v-if="errorMessage" class="alert alert-warning mt-2" role="alert">
          {{ errorMessage }}
        </div>
        <div class="generatedVerses">
          <div id="verseContainer" class="row">
            <div v-for="(verse, index) in generatedVerses" :key="index" class="col-md-6 mb-3">
              <div class="card h-100">
                <div class="card-body position-relative">
                  <h5 class="card-subtitle mb-2 text-muted">
                    Topic: <a :href="'https://www.dictionary.com/browse/' + verse.topic.toLowerCase()" target="_blank">{{ verse.topic }}</a>
                  </h5>
                  <button @click="copyVerse(verse, index)" class="btn btn-link position-absolute top-0 end-0 p-2">
                    <i :class="['bi', copyIconClass[index]]"></i>
                  </button>
                  <h5 class="card-title mt-3">{{ verse.verse }}</h5>
                  <p class="card-text fs-6">{{ verse.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- FAQ Section -->
    <div class="mt-5">
      <RandomBibleVerseGeneratorFAQ />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted } from 'vue';
import bibleVersesImport from '../../components/JSON/bibleVerseByTopic.json';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import RandomBibleVerseGeneratorFAQ from '../FAQs/randomBibleVerseGeneratorFAQ.vue';

interface BibleVerse {
  verse: string;
  topic: string;
  text: string;
}

// Explicitly type the imported data
const bibleVerses = bibleVersesImport as BibleVerse[];

export default {
  name: 'RandomBibleVerseGenerator',
  components: {
    BreadCrumb,
    RandomBibleVerseGeneratorFAQ
  },
  setup() {
    const verseNum = ref<number>(1);
    const generatedVerses = ref<BibleVerse[]>([]);
    const errorMessage = ref<string>('');
    const copyIconClass = ref<string[]>([]);

    const generateVerses = () => {
      if (verseNum.value < 1 || verseNum.value > 12) {
        errorMessage.value = 'Please select between 1 and 12 verses.';
        return;
      }

      errorMessage.value = '';
      generatedVerses.value = [];
      copyIconClass.value = [];

      const shuffled = [...bibleVerses].sort(() => 0.5 - Math.random());
      generatedVerses.value = shuffled.slice(0, verseNum.value);
      copyIconClass.value = Array(verseNum.value).fill('bi-clipboard');
    };

    const copyVerse = (verse: BibleVerse, index: number) => {
      const textToCopy = `${verse.verse} - ${verse.text} (Topic: ${verse.topic})`;
      navigator.clipboard.writeText(textToCopy).then(() => {
        copyIconClass.value[index] = 'bi-clipboard-check';
        setTimeout(() => {
          copyIconClass.value[index] = 'bi-clipboard';
        }, 2000);
      });
    };

    onMounted(() => {
      generateVerses();
    });

    return {
      verseNum,
      generatedVerses,
      errorMessage,
      copyIconClass,
      generateVerses,
      copyVerse,
    };
  },
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}

.card {
  overflow: hidden;
}

.btn-link {
  text-decoration: none;
  color: inherit;
}

.btn-link:hover {
  color: #0056b3;
}
</style>