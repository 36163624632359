<template>
  <BreadCrumb :PageTitle="pageTitle" />

  <div class="custom-container mt-5">
    <h1 class="text-center">{{ pageTitle }}</h1>
    <p class="fs-6 text-center mb-4">
      Calculate and simplify ratios, find equivalent ratios, or solve for a missing term in a proportional ratio. Enter the values and select the calculation type.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Select Calculation Type -->
        <div class="mb-3">
          <label for="calcType" class="form-label">Select Calculation Type:</label>
          <select id="calcType" v-model="calcType" class="form-select">
            <option value="simplify">Simplify a Ratio</option>
            <option value="equivalent">Find Equivalent Ratios</option>
            <option value="proportion">Solve Proportional Ratio</option>
          </select>
        </div>

        <!-- Input Fields for Simplify Ratio -->
        <div v-if="calcType === 'simplify'" class="mb-3">
          <label for="num1" class="form-label">First Number:</label>
          <input
            type="number"
            id="num1"
            v-model="num1"
            class="form-control"
            placeholder="e.g., 8"
          />
          <label for="num2" class="form-label mt-3">Second Number:</label>
          <input
            type="number"
            id="num2"
            v-model="num2"
            class="form-control"
            placeholder="e.g., 12"
          />
        </div>

        <!-- Input Fields for Equivalent Ratios -->
        <div v-if="calcType === 'equivalent'" class="mb-3">
          <label for="num1" class="form-label">First Number:</label>
          <input
            type="number"
            id="num1"
            v-model="num1"
            class="form-control"
            placeholder="e.g., 2"
          />
          <label for="num2" class="form-label mt-3">Second Number:</label>
          <input
            type="number"
            id="num2"
            v-model="num2"
            class="form-control"
            placeholder="e.g., 3"
          />
          <label for="multiplier" class="form-label mt-3">Multiplier:</label>
          <input
            type="number"
            id="multiplier"
            v-model="multiplier"
            class="form-control"
            placeholder="e.g., 4"
          />
        </div>

        <!-- Input Fields for Proportional Ratio -->
        <div v-if="calcType === 'proportion'" class="mb-3">
          <label for="num1" class="form-label">First Number (a):</label>
          <input
            type="number"
            id="num1"
            v-model="num1"
            class="form-control"
            placeholder="e.g., 3"
          />
          <label for="num2" class="form-label mt-3">Second Number (b):</label>
          <input
            type="number"
            id="num2"
            v-model="num2"
            class="form-control"
            placeholder="e.g., 4"
          />
          <label for="num3" class="form-label mt-3">Third Number (c):</label>
          <input
            type="number"
            id="num3"
            v-model="num3"
            class="form-control"
            placeholder="e.g., 6"
          />
          <p class="fs-6 mt-3">
            Solving for \( \frac{a}{b} = \frac{c}{d} \). Enter the values for \( a \), \( b \), and \( c \), and the calculator will solve for \( d \).
          </p>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateRatio" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">{{ result.calculationType }}</p>
          <p class="fs-5">{{ result.calculation }}</p>
          <p class="fs-5">Final Result: {{ result.finalResult }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Select the type of ratio calculation you want to perform.</li>
        <li>Enter the required values based on the selected calculation type.</li>
        <li>Click "Calculate" to get the result. The result will display the calculation process and the final value.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Ratio Calculation Formulas:</h5>
      <ul class="fs-6">
        <li>
          <strong>Simplifying a Ratio:</strong><br />
          Divide both numbers by their Greatest Common Divisor (GCD).
        </li>
        <li>
          <strong>Finding Equivalent Ratios:</strong><br />
          Multiply or divide both terms of the ratio by the same non-zero number.
        </li>
        <li>
          <strong>Solving Proportional Ratios:</strong><br />
          Use the proportion \( \frac{a}{b} = \frac{c}{d} \) to solve for the missing term:
          \[
          d = \frac{b \times c}{a}
          \]
        </li>
      </ul>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          <strong>Simplifying a Ratio:</strong><br />
          Simplify 8:12:<br />
          GCD of 8 and 12 is 4.<br />
          Simplified Ratio: \( \frac{8 \div 4}{12 \div 4} = 2:3 \).
        </li>
        <li>
          <strong>Finding Equivalent Ratios:</strong><br />
          Find equivalent ratio of 2:3 with a multiplier of 4:<br />
          Equivalent Ratio: \( 2 \times 4 : 3 \times 4 = 8:12 \).
        </li>
        <li>
          <strong>Solving Proportional Ratios:</strong><br />
          Solve \( \frac{3}{4} = \frac{6}{d} \):<br />
          \( d = \frac{4 \times 6}{3} = 8 \).
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BreadCrumb from '../../components/Common/BreadCrumb.vue';

export default {
  components: {
    BreadCrumb,
  },
  props: {
    calculationType: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const calcType = ref('simplify');
    const num1 = ref('');
    const num2 = ref('');
    const num3 = ref('');
    const multiplier = ref('');
    const result = ref(null);
    const error = ref('');

    const pageTitle = computed(() => {
      const titles = {
        simplify: 'Simplify a Ratio',
        equivalent: 'Find Equivalent Ratios',
        proportion: 'Solve Proportional Ratio',
      };
      return titles[calcType.value] || 'Ratio Calculator';
    });

    // Mapping between calcType and URL-friendly strings
    const calcTypeToSlug = {
      simplify: 'simplify-a-ratio',
      equivalent: 'equivalent-ratios',
      proportion: 'solve-proportional-ratio',
    };

    const slugToCalcType = {
      'simplify': 'simplify',
      'equivalent-ratios': 'equivalent',
      'solve-proportional-ratio': 'proportion',
    };

    // Initialize calcType based on route parameter
    if (route.params.calculationType) {
      const slug = route.params.calculationType;
      if (slugToCalcType[slug]) {
        calcType.value = slugToCalcType[slug];
      }
    }

    // Watch for changes in calcType to update the route
    watch(
      () => calcType.value,
      (newCalcType) => {
        const newSlug = calcTypeToSlug[newCalcType];
        router.replace({
          name: 'RatioCalculator',
          params: { calculationType: newSlug },
        });
      }
    );

    // Watch for changes in the route parameter to update calcType
    watch(
      () => route.params.calculationType,
      (newCalculationType) => {
        if (newCalculationType && slugToCalcType[newCalculationType]) {
          calcType.value = slugToCalcType[newCalculationType];
        } else {
          // Default to 'simplify' if the route parameter is invalid or missing
          calcType.value = 'simplify';
        }
      }
    );

    // Function to calculate ratios based on selected type
    const calculateRatio = () => {
      error.value = '';
      result.value = null;

      try {
        let finalResult, calculationType, calculation;

        switch (calcType.value) {
          case 'simplify': {
            // Simplify a ratio
            if (num1.value === '' || num2.value === '') {
              throw new Error('Please enter both numbers.');
            }
            const number1 = parseFloat(num1.value);
            const number2 = parseFloat(num2.value);
            if (isNaN(number1) || isNaN(number2) || number1 <= 0 || number2 <= 0) {
              throw new Error('Please enter valid positive numbers.');
            }
            const gcdValue = gcd(number1, number2);
            const simplifiedNum1 = number1 / gcdValue;
            const simplifiedNum2 = number2 / gcdValue;
            finalResult = `${simplifiedNum1}:${simplifiedNum2}`;
            calculationType = 'Simplified Ratio';
            calculation = `GCD of ${number1} and ${number2} is ${gcdValue}. Simplified Ratio: ${simplifiedNum1}:${simplifiedNum2}`;
            break;
          }

          case 'equivalent': {
            // Find equivalent ratios
            if (num1.value === '' || num2.value === '' || multiplier.value === '') {
              throw new Error('Please enter both numbers and the multiplier.');
            }
            const number1 = parseFloat(num1.value);
            const number2 = parseFloat(num2.value);
            const multi = parseFloat(multiplier.value);
            if (
              isNaN(number1) ||
              isNaN(number2) ||
              isNaN(multi) ||
              number1 <= 0 ||
              number2 <= 0 ||
              multi <= 0
            ) {
              throw new Error('Please enter valid positive numbers.');
            }
            const equivalentNum1 = number1 * multi;
            const equivalentNum2 = number2 * multi;
            finalResult = `${equivalentNum1}:${equivalentNum2}`;
            calculationType = 'Equivalent Ratio';
            calculation = `${number1} × ${multi} : ${number2} × ${multi} = ${equivalentNum1}:${equivalentNum2}`;
            break;
          }

          case 'proportion': {
            // Solve proportional ratio
            if (num1.value === '' || num2.value === '' || num3.value === '') {
              throw new Error('Please enter values for a, b, and c.');
            }
            const a = parseFloat(num1.value);
            const b = parseFloat(num2.value);
            const c = parseFloat(num3.value);
            if (isNaN(a) || isNaN(b) || isNaN(c) || a === 0) {
              throw new Error("Please enter valid numbers and 'a' should not be zero.");
            }
            const d = (b * c) / a;
            finalResult = `d = ${d.toFixed(6)}`;
            calculationType = 'Proportional Ratio';
            calculation = `Using proportion a/b = c/d, d = (b × c) / a = (${b} × ${c}) / ${a} = ${d.toFixed(6)}`;
            break;
          }

          default:
            throw new Error('Invalid calculation type.');
        }

        result.value = {
          calculationType: calculationType,
          calculation: calculation,
          finalResult: finalResult,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to find GCD of two numbers
    const gcd = (a, b) => {
      return b === 0 ? a : gcd(b, a % b);
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      num1.value = '';
      num2.value = '';
      num3.value = '';
      multiplier.value = '';
      result.value = null;
      error.value = '';
    };

    return {
      calcType,
      num1,
      num2,
      num3,
      multiplier,
      result,
      error,
      calculateRatio,
      clearValues,
      pageTitle,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
