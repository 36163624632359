<template>
  <div class="container-fluid mt-5 mb-50">
    <div class="row">
      <!-- Main content -->
      <div class="col-lg-9">
        <!-- Loop over categories -->
        <div v-for="(category, categoryIndex) in allTools" :key="categoryIndex">
          <!-- Category Heading -->
          <h3 class="text-center mt-4 mb-3">{{ category.categoryName }}</h3>
          <div class="cards-container">
            <!-- Loop over tools within the category -->
            <div
              v-for="tool in category.tools"
              :key="tool.name"
              class="card h-100"
            >
              <div class="card-body d-flex flex-column">
                <div class="text-center mb-3">
                  <!-- Image or Icon -->
                  <img
                    v-if="!imageErrors[tool.name]"
                    :src="`/images/${tool.route.slice(1)}.png`"
                    alt="Icon"
                    class="img-fluid"
                    @error="onImageError(tool.name)"
                    style="width: 2rem; height: 2rem;"
                  />
                  <i
                    v-else
                    :class="['bi', tool.icon, 'fs-1']"
                  ></i>
                </div>
                <h5 class="card-title text-center">{{ tool.name }}</h5>
                <p class="card-text text-center text-muted mt-2">{{ tool.subtitle }}</p>
                <router-link :to="tool.route" class="btn btn-outline-primary mt-auto">
                  Use Tool
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sidebar -->
      <div class="col-lg-3">
        <BlogSidebar />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BlogSidebar from './BlogSidebar.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    BlogSidebar
  },
  data() {
    return {
      allTools: [
        {
          categoryName: 'Widely Used Automated Tools',
          tools: [
            { name: "Pixel Circle Generator", route: "/pixel-circle-generator", icon: "bi-circle", subtitle: "Create perfect pixel circles" },
            { name: "Midjourney Prompt Generator", route: "/midjourney-prompt-generator", icon: "bi-magic", subtitle: "Generate creative prompts" },
            { name: "Color Picker Generator", route: "/color-picker-generator", icon: "bi-palette", subtitle: "Find the perfect color" },
            { name: "cRiNgE tExT gEnErAtOr", route: "/cringe-text-generator", icon: "bi-type", subtitle: "Make your text stand out" },
            { name: "Advanced Image Cropper", route: "/advanced-image-cropper", icon: "bi-crop", subtitle: "Precisely crop your images" },
            { name: "Secret Santa Generator", route: "/secret-santa-generator", icon: "bi-gift", subtitle: "Organize gift exchanges" },
            { name: "Christmas Charades Generator", route: "/christmas-charades-generator", icon: "bi-chat-square-quote", subtitle: "Generate holiday game ideas" },
            { name: "Secret Santa Poem Generator", route: "/secret-santa-poem-generator", icon: "bi-journal-text", subtitle: "Create festive poems" },
          ],
        },
        {
          categoryName: 'Tech Tools',
          tools: [
            { name: "HTML Validator", route: "/html-validator", icon: "bi-code-square", subtitle: "Check your HTML code" },
            { name: "Random IP Generator with Barcode", route: "/random-ip-generator", icon: "bi-upc-scan", subtitle: "Generate IP addresses" },
            { name: "Find and Replace Text", route: "/find-and-replace-text", icon: "bi-search", subtitle: "Edit text efficiently" },
            { name: "Multiple Word Replacer", route: "/multiple-word-replacer", icon: "bi-arrow-left-right", subtitle: "Batch replace words" },
            { name: "Whitespace Remover", route: "/whitespace-remover", icon: "bi-arrows-collapse", subtitle: "Clean up text spacing" },
            { name: "HTML To Markdown", route: "/html-to-markdown", icon: "bi-file-code", subtitle: "Convert HTML to Markdown" },
            { name: "Word Flipper Generator", route: "/word-flipper", icon: "bi-arrow-repeat", subtitle: "Flip words in text" },
            { name: "Sentence Counter", route: "/sentence-counter", icon: "bi-chat-dots", subtitle: "Count sentences in text" },
          ],
        },
        {
          categoryName: 'Calculators',
          tools: [
            { name: "Annular Velocity Calculator", route: "/annular-velocity-calculator", icon: "bi-speedometer", subtitle: "Compute fluid velocities" },
            { name: "Antoine Equation Calculator", route: "/antoine-equation-calculator", icon: "bi-thermometer", subtitle: "Calculate vapor pressures" },
            { name: "Annualized Loss Expectancy Calculator", route: "/annualized-loss-expectancy-calculator", icon: "bi-graph-down", subtitle: "Estimate financial risks" },
            { name: "Pace Running Calculator", route: "/pace-running-calculator", icon: "bi-stopwatch", subtitle: "Calculate running paces" },
            { name: "Empirical Rule Calculator", route: "/empirical-rule-calculator", icon: "bi-bar-chart", subtitle: "Understand data distributions" },
            { name: "What Divided By Calculator", route: "/what-divided-by-calculator", icon: "bi-slash", subtitle: "Find dividend or divisor" },
            { name: "OpenAI Pricing Calculator", route: "/openai-pricing-calculator", icon: "bi-currency-dollar", subtitle: "Estimate OpenAI API costs" },
            { name: "Aspect Ratio Calculator", route: "/aspect-ratio-calculator", icon: "bi-aspect-ratio", subtitle: "Calculate aspect ratios" },
          ],
        },
        {
          categoryName: 'Generators',
          tools: [
            { name: "Roman Name Generator", route: "/roman-name-generator", icon: "bi-person", subtitle: "Generate ancient Roman names" },
            { name: "Random Paragraph Generator", route: "/random-paragraph-generator", icon: "bi-paragraph", subtitle: "Create random text" },
            { name: "Word Ladder Generator", route: "/word-ladder-generator", icon: "bi-ladder", subtitle: "Generate word puzzles" },
            { name: "Blank Space Generator", route: "/blank-space-generator", icon: "bi-square", subtitle: "Create custom spaces" },
            { name: "Bubble Text Generator", route: "/bubble-text-generator", icon: "bi-chat-dots", subtitle: "Generate bubble-styled text" },
            { name: "Random Username Generator", route: "/random-username-generator", icon: "bi-person-circle", subtitle: "Create random usernames" },
            { name: "Random Pin Generator", route: "/random-pin-generator", icon: "bi-keypad", subtitle: "Generate secure PINs" },
            { name: "Random NBA Team Generator", route: "/random-nba-team-generator", icon: "bi-basket", subtitle: "Get random NBA teams" },
          ],
        },
        {
          categoryName: 'Encoders / Decoders',
          tools: [
            { name: "NTLM Hash Generator", route: "/ntlm-hash-generator", icon: "bi-shield-lock", subtitle: "Generate NTLM hashes" },
            { name: "MD2 Hash Generator", route: "/md2-hash-generator", icon: "bi-lock", subtitle: "Create MD2 hashes" },
            { name: "MD4 Hash Generator", route: "/md4-hash-generator", icon: "bi-lock-fill", subtitle: "Generate MD4 hashes" },
            { name: "MD5 Hash Generator", route: "/md5-hash-generator", icon: "bi-shield", subtitle: "Create MD5 hashes" },
            { name: "MD6 Hash Generator", route: "/md6-hash-generator", icon: "bi-shield-fill", subtitle: "Generate MD6 hashes" },
            { name: "SHA-1 Hash Generator", route: "/sha1-hash-generator", icon: "bi-shield-shaded", subtitle: "Generate SHA-1 hashes" },
            { name: "CRC16 Hash Generator", route: "/crc16-hash-generator", icon: "bi-check-circle", subtitle: "Compute CRC16 checksums" },
            { name: "SHA-256 Hash Generator", route: "/sha-256-hash-generator", icon: "bi-shield-lock-fill", subtitle: "Generate SHA-256 hashes" },
          ],
        },
        {
          categoryName: 'Maths',
          tools: [
            { name: "Square Root Calculator", route: "/square-root-calculator", icon: "bi-check2", subtitle: "Calculate square roots" },
            { name: "Nth Root Calculator", route: "/nth-root-calculator", icon: "bi-braces", subtitle: "Compute any root" },
            { name: "How Many Days Calculator", route: "/how-many-days-until", icon: "bi-calendar2-date", subtitle: "Calculate date differences" },
            { name: "What Divided By What Equals Calculator", route: "/what-divided-by-what-equals-1", icon: "bi-percent", subtitle: "Find division factors" },
            { name: "Percentage Change Calculator", route: "/percentage-change-calculator", icon: "bi-graph-up-arrow", subtitle: "Calculate percent changes" },
            { name: "Fractions Calculator", route: "/fractions-calculator", icon: "bi-slash-circle", subtitle: "Perform fraction operations" },
            { name: "Algebra Solver", route: "/algebra-solver", icon: "bi-x-circle", subtitle: "Solve algebraic equations" },
            { name: "Scientific Calculator", route: "/online-math-calculator", icon: "bi-calculator", subtitle: "Advanced Scientific Calculator" },
          ],
        },
        {
          categoryName: 'English',
          tools: [
            { name: "Readability Calculator", route: "/readability-calculator", icon: "bi-journal-check", subtitle: "Calculate the readability score" },
            { name: "Syllable Counter", route: "/syllable-counter", icon: "bi-123", subtitle: "Count word syllables" },
            { name: "List All Nouns In Sentence", route: "/list-all-nouns-in-sentence", icon: "bi-card-text", subtitle: "Identify nouns" },
            { name: "List All Verbs In Sentence", route: "/list-all-verbs-in-sentence", icon: "bi-arrow-right", subtitle: "Identify verbs" },
            { name: "List All Pronouns In Sentence", route: "/list-all-pronouns-in-sentence", icon: "bi-person-fill", subtitle: "Identify pronouns" },
            { name: "List All Adjectives In Sentence", route: "/list-all-adjectives-in-sentence", icon: "bi-list-stars", subtitle: "Identify adjectives" },
            { name: "List All Adverbs In Sentence", route: "/list-all-adverbs-in-sentence", icon: "bi-chat-right-quote", subtitle: "Identify adverbs" },
            { name: "List All Prepositions In Sentence", route: "/list-all-prepositions-in-sentence", icon: "bi-box-arrow-in-down", subtitle: "Identify prepositions" },
          ],
        },
        {
          categoryName: 'Word Scramble Games',
          tools: [
            { name: "Christmas Word Scramble", route: "/christmas-word-scramble", icon: "bi-snow2", subtitle: "Holiday word puzzle fun" },
            { name: "Bible Word Scramble", route: "/bible-word-scramble", icon: "bi-book", subtitle: "Scripture-based word games" },
            { name: "Thanksgiving Word Scramble", route: "/thanksgiving-word-scramble", icon: "bi-clipboard-check", subtitle: "Festive word challenges" },
            { name: "Baby Word Scramble", route: "/baby-word-scramble", icon: "bi-emoji-smile", subtitle: "Cute baby-themed puzzles" },
            { name: "Animal Word Scramble", route: "/animal-word-scramble", icon: "bi-bug", subtitle: "Animal kingdom word games" },
            { name: "Wedding Word Scramble", route: "/wedding-word-scramble", icon: "bi-heart", subtitle: "Matrimonial word fun" },
            { name: "Easter Word Scramble", route: "/easter-word-scramble", icon: "bi-egg", subtitle: "Spring-themed word puzzles" },
            { name: "Holiday Word Scramble", route: "/holiday-word-scramble", icon: "bi-calendar-event", subtitle: "Festive word challenges" },
          ],
        },
      ],
      imageErrors: {} as Record<string, boolean>, // Tracks image loading errors
    };
  },
  methods: {
    // Handles image loading errors
    onImageError(toolName: string) {
      this.imageErrors[toolName] = true;
    },
  },
});
</script>

<style scoped>
.card {
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  color: #44505b;
  font-weight: bold;
}

a {
  transition: color 0.2s;
}

a:hover {
  color: #ffffff !important;
  text-decoration: none;
}

/* Container for the cards */
.cards-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1600px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  }
}

@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }
}

@media (max-width: 576px) {
  .cards-container {
    grid-template-columns: 1fr; /* 1 card per row */
  }
}
</style>
