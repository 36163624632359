<template>
  <BreadCrumb PageTitle="Addition Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Addition Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter multiple numbers separated by commas or spaces, and this tool will calculate their sum. You can use decimal values as well.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <div class="mb-3">
          <input
            type="text"
            v-model="numberInput"
            class="form-control"
            placeholder="e.g., 1.5, 2.3, 4 or 1.5 2.3 4"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateSum" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <p v-if="sum !== null" class="fs-5">
          Sum: <strong>{{ sum }}</strong>
        </p>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter multiple numbers separated by commas or spaces (e.g., 1.5, 2.3, 4 or 1.5 2.3 4).</li>
        <li>You can include decimal values as well (e.g., 2.5, 3.75).</li>
        <li>The calculator will display the sum of all entered numbers.</li>
      </ul>
      <h5 class="alert-heading">Formula for Addition:</h5>
      <p class="fs-6">
        To find the sum of multiple numbers, simply add them together:
      </p>
      <ul class="fs-6">
        <li>For numbers <strong>a</strong>, <strong>b</strong>, and <strong>c</strong>, the sum is calculated as:</li>
        <code>
          Sum = a + b + c
        </code>
        <li>
          Example: For the numbers 1.5, 2.3, and 4:
          <br />
          Sum = 1.5 + 2.3 + 4 = 7.8
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input field and result state
    const numberInput = ref("");
    const sum = ref(null);
    const error = ref("");

    // Function to calculate the sum of numbers
    const calculateSum = () => {
      error.value = "";
      sum.value = null;

      // Check if the input is empty
      if (!numberInput.value.trim()) {
        error.value = "Please enter some numbers to calculate the sum.";
        return;
      }

      try {
        // Split the input into an array of numbers
        const numbers = numberInput.value
          .split(/[\s,]+/) // Split by comma or space
          .map((num) => parseFloat(num.trim())) // Convert each part to a float
          .filter((num) => !isNaN(num)); // Filter out any non-numeric values

        // Check if there are any valid numbers
        if (numbers.length === 0) {
          throw new Error("Invalid input. Please enter valid numbers.");
        }

        // Calculate the sum of the numbers
        sum.value = numbers.reduce((acc, curr) => acc + curr, 0).toFixed(2);
      } catch (e) {
        error.value = "Please enter valid numbers separated by commas or spaces.";
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      numberInput.value = "";
      sum.value = null;
      error.value = "";
    };

    return {
      numberInput,
      sum,
      error,
      calculateSum,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

strong {
  font-weight: bold;
}

.dark h5 {
    color: #2b3035 !important;
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
