<!-- src/App.vue -->
<template>
    <BreadCrumb PageTitle="sPoNgEbOb MoCkINg TeXt GeNeRaToR" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">sPoNgEbOb MoCkINg TeXt GeNeRaToR</h1>
      <p class="text-center fs-6">Convert any input text to mocking SpongeBob style text</p>
  
      <!-- Input Section -->
      <div class="form-group">
        <label for="textInput" class="fs-6">Your Text</label>
        <textarea
          id="textInput"
          class="form-control fs-6"
          rows="5"
          v-model="textInput"
          placeholder="Enter your text here..."
        ></textarea>
        <small class="text-muted fs-6">Character Count: {{ textInput.length }}</small>
      </div>
  
      <!-- Convert and Reset Buttons -->
      <div class="row mt-3">
        <div class="col-md-6">
          <button class="btn btn-primary w-100" @click="convertText">Convert to Mocking Text</button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-warning w-100" @click="resetText">Reset Text</button>
        </div>
      </div>
  
      <!-- Error Message -->
      <div v-if="error" class="alert alert-danger mt-3">
        {{ error }}
      </div>
  
      <!-- Mocked Text Output Section -->
      <div v-if="mockedText" class="mt-4">
        <h3>Mocking Text Output</h3>
        <textarea class="form-control fs-6" rows="5" readonly :value="mockedText"></textarea>
  
        <!-- Action Buttons -->
        <div class="mt-2 mb-2">
            <button class="btn btn-danger w-100" @click="copyToClipboard">
                <i :class="clipboardIcon"></i> {{ clipboardText }}
            </button>
        </div>
      </div>
  
      <!-- FAQ Section -->
      <div class="mt-50 mb-50">
        <h2>sPoNgEbOb MoCkINg TeXt GeNeRaToR FAQ</h2>
        <div v-for="(item, index) in faqList" :key="index" class="mb-4">
          <h5>{{ item.question }}</h5>
          <p v-html="item.answer" class="fs-6"></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  
  export default {
    components: {
      BreadCrumb
    },
    data() {
      return {
        textInput: '',
        mockedText: '',
        error: '',
        clipboardIcon: 'bi bi-clipboard',
        clipboardText: 'Copy to Clipboard',
        faqList: [
          {
            question: '1. What is the SpongeBob Mocking Text Generator?',
            answer: 'This tool converts any input text to a mocking text format, where letters alternate between uppercase and lowercase.'
          },
          {
            question: '2. How does the mocking text conversion work?',
            answer: 'The conversion alternates between uppercase and lowercase letters for each character in the input text using JavaScript’s <code>split()</code>, <code>map()</code>, and <code>join()</code> methods.'
          },
          {
            question: '3. What can I do with the output text?',
            answer: 'You can copy the converted text to your clipboard using the "Copy to Clipboard" button. You can then paste it anywhere you like, such as social media, messages, or documents.'
          },
          {
            question: '4. How do I reset the text?',
            answer: 'Simply click the "Reset Text" button to clear both the input and output text areas.'
          },
          {
            question: '5. What is the character limit?',
            answer: 'There is no enforced character limit, but it’s best to keep your input text within a few hundred characters for optimal performance.'
          },
          {
            question: '6. Can I see how many characters I’ve entered?',
            answer: 'Yes, the character count is displayed below the input box. It shows how many characters you have typed in the input field.'
          },
          {
            question: '7. How does the clipboard functionality work?',
            answer: 'When you click the "Copy to Clipboard" button, the mocked text is copied to your system clipboard. You will see a checkmark icon and a confirmation message indicating success.'
          },
          {
            question: '8. Are there any limitations to the conversion?',
            answer: 'The conversion works on plain text only. Special characters, emojis, or other non-alphabetical characters will not be affected by the case alternation.'
          }
        ]
      };
    },
    methods: {
      convertText() {
        this.error = '';
        if (!this.textInput.trim()) {
          this.error = 'Please enter some text to convert.';
          return;
        }
        this.mockedText = this.textInput.split('')
          .map((char, index) => (index % 2 === 0 ? char.toLowerCase() : char.toUpperCase()))
          .join('');
      },
      resetText() {
        this.textInput = '';
        this.mockedText = '';
        this.error = '';
      },
      copyToClipboard() {
        navigator.clipboard.writeText(this.mockedText).then(() => {
          this.clipboardIcon = 'bi bi-clipboard-check';
          this.clipboardText = 'Copied!';
          setTimeout(() => {
            this.clipboardIcon = 'bi bi-clipboard';
            this.clipboardText = 'Copy to Clipboard';
          }, 2000);
        });
      }
    }
  };
  </script>
  
  <style>
  .custom-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  </style>
  