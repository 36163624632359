// src/generatePoem.js

import { wordLists } from './templates';

const getRandomWord = (list) => {
  return list[Math.floor(Math.random() * list.length)];
};

export const generatePoem = (template) => {
  if (typeof template !== 'string') {
    throw new Error('Template must be a string');
  }

  try {
    let poem = template;
    const placeholders = template.match(/{\w+}/g) || [];

    placeholders.forEach((placeholder) => {
      const key = placeholder.slice(1, -1);
      const wordList = wordLists[key];
      if (wordList) {
        const randomWord = getRandomWord(wordList);
        poem = poem.replace(placeholder, randomWord);
      } else {
        throw new Error(`No word list found for placeholder: ${placeholder}`);
      }
    });

    return poem;
  } catch (error) {
    console.error('Error generating poem:', error);
    throw error;
  }
};
