<template>
  <div
    :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
    id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
        to="/"
        class="d-flex align-items-center text-white text-decoration-none"
      >
        <img src="../../assets/images/favicon.png" alt="logo-icon" />
        <span class="fw-bold ms-10">Pixelroyals</span>
      </router-link>
      <div class="border-bottom"></div>
      <button
        class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
        class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion"
        id="sidebarNavAccordion"
        @click="handleMenuItemClick"
      >
      <li class="sub-title sidebar-nav-item">
          <span class="d-block text-uppercase fw-medium">Apps</span>
      </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseOne"
            aria-expanded="true"
            aria-controls="sidebarCollapseOne"
          >
            <i class="bi-star"></i>
            <span class="title">Editor's Choice</span>
          </a>
          <div
            id="sidebarCollapseOne"
            class="accordion-collapse collapse show"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/pixel-circle-generator" class="sidebar-sub-menu-link">
                    Pixel Circle Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/midjourney-prompt-generator" class="sidebar-sub-menu-link">
                    Midjourney Prompt Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/color-picker-generator" class="sidebar-sub-menu-link">
                    Color Picker Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/cringe-text-generator" class="sidebar-sub-menu-link">
                    cRiNgE tExT gEnErAtOr
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/advanced-image-cropper" class="sidebar-sub-menu-link">
                    Advanced Image Cropper
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/secret-santa-generator" class="sidebar-sub-menu-link">
                    Secret Santa Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/christmas-charades-generator" class="sidebar-sub-menu-link">
                    Christmas Charades Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/secret-santa-poem-generator" class="sidebar-sub-menu-link">
                    Secret Santa Poem Generator
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseTwo"
            aria-expanded="false"
            aria-controls="sidebarCollapseTwo"
          >
            <i class="ph ph-puzzle-piece"></i>
            <span class="title">Word Scramble Games</span>
          </a>
          <div
            id="sidebarCollapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/christmas-word-scramble" class="sidebar-sub-menu-link">
                    Christmas Word Scramble
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/bible-word-scramble" class="sidebar-sub-menu-link">
                    Bible Word Scramble
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/thanksgiving-word-scramble" class="sidebar-sub-menu-link">
                    Thanksgiving Word Scramble
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/baby-word-scramble" class="sidebar-sub-menu-link">
                    Baby Word Scramble
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/animal-word-scramble" class="sidebar-sub-menu-link">
                    Animal Word Scramble
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/wedding-word-scramble" class="sidebar-sub-menu-link">
                    Wedding Word Scramble
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/easter-word-scramble" class="sidebar-sub-menu-link">
                    Easter Word Scramble
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/holiday-word-scramble" class="sidebar-sub-menu-link">
                    Holiday Word Scramble
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/guess-word" class="sidebar-sub-menu-link">
                    Guess Word
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/whats-the-word" class="sidebar-sub-menu-link">
                    Whats The Word
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseThree"
            aria-expanded="false"
            aria-controls="sidebarCollapseThree"
          >
            <i class="bi-tools"></i>
            <span class="title">Tech Tools</span>
          </a>
          <div
            id="sidebarCollapseThree"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/html-validator" class="sidebar-sub-menu-link">
                    HTML Validator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/random-ip-generator" class="sidebar-sub-menu-link">
                    Random IP Generator with Barcode
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/find-and-replace-text" class="sidebar-sub-menu-link">
                    Find and Replace Text
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/multiple-word-replacer" class="sidebar-sub-menu-link">
                    Multiple Word Replacer
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/whitespace-remover" class="sidebar-sub-menu-link">
                    Whitespace Remover
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/html-to-markdown" class="sidebar-sub-menu-link">
                    HTML To Markdown
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/html-to-bbcode" class="sidebar-sub-menu-link">
                    HTML To BBCode
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/html-to-csv" class="sidebar-sub-menu-link">
                    HTML To CSV
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/html-to-json" class="sidebar-sub-menu-link">
                    HTML To JSON
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/html-to-text" class="sidebar-sub-menu-link">
                    HTML To Text
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/html-to-xml" class="sidebar-sub-menu-link">
                    HTML To XML
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/html-to-yaml" class="sidebar-sub-menu-link">
                    HTML To YAML
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/json-to-csv" class="sidebar-sub-menu-link">
                    JSON To CSV
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/millisecond-to-human-readable" class="sidebar-sub-menu-link">
                    Millisecond To Human Readable
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/word-sorter" class="sidebar-sub-menu-link">
                    Word Sorter
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/ipv6-to-ipv4-converter" class="sidebar-sub-menu-link">
                    IPv6 to IPv4 Converter
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/ipv4-to-ipv6-converter" class="sidebar-sub-menu-link">
                    IPv4 to IPv6 Converter
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/bcrypt-password-generator" class="sidebar-sub-menu-link">
                    Bcrypt Password Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/letter-frequency-counter" class="sidebar-sub-menu-link">
                    Letter Frequency Counter
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/word-flipper" class="sidebar-sub-menu-link">
                    Word Flipper Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/yaml-validator" class="sidebar-sub-menu-link">
                    YAML Validator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/json-escape-unescape" class="sidebar-sub-menu-link">
                    JSON Escape / Unescape
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sentence-counter" class="sidebar-sub-menu-link">
                    Sentence Counter
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/remove-unwanted-characters" class="sidebar-sub-menu-link">
                    Remove Unwanted Characters
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/resize-image-to-20-kb" class="sidebar-sub-menu-link">
                    Resize Image To
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/svg-to-css-converter" class="sidebar-sub-menu-link">
                    SVG To CSS Converter
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/cron-converter" class="sidebar-sub-menu-link">
                    CRON Converter
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link :to="{ path: '/color-converter' }" class="sidebar-sub-menu-link" :class="{ 'active': isColorConverterActive }">
                    Color Converter
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/numbers-to-words-converter" class="sidebar-sub-menu-link">
                    Numbers To Words Converter
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseFour"
            aria-expanded="false"
            aria-controls="sidebarCollapseFour"
          >
            <i class="ph ph-calculator"></i>
            <span class="title">Calculators</span>
          </a>
          <div
            id="sidebarCollapseFour"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/antenna-beamwidth-calculator" class="sidebar-sub-menu-link">
                    Antenna Beamwidth Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/annular-velocity-calculator" class="sidebar-sub-menu-link">
                    Annular Velocity Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/antoine-equation-calculator" class="sidebar-sub-menu-link">
                    Antoine Equation Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/annualized-loss-expectancy-calculator" class="sidebar-sub-menu-link">
                    Annualized Loss Expectancy Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/pace-running-calculator" class="sidebar-sub-menu-link">
                    Pace Running Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/paint-ratio-calculator" class="sidebar-sub-menu-link">
                    Paint Ratio Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/collatz-conjecture-calculator" class="sidebar-sub-menu-link">
                    Collatz Conjecture Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/rice-to-water-ratio-calculator" class="sidebar-sub-menu-link">
                    Rice To Water Ratio Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/wall-art-size-calculator" class="sidebar-sub-menu-link">
                    Wall Art Size Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/empirical-rule-calculator" class="sidebar-sub-menu-link">
                    Empirical Rule Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link 
                    :to="{ path: '/what-divided-by-calculator' }" 
                    class="sidebar-sub-menu-link" 
                    :class="{ 'active': isWhatDividedByCalculatorActive }">
                    What Divided By Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/gravitational-force-calculator" class="sidebar-sub-menu-link">
                    Gravitational Force Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/openai-pricing-calculator" class="sidebar-sub-menu-link">
                    OpenAI Pricing Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/five-number-summary-calculator" class="sidebar-sub-menu-link">
                    Five Number Summary Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/aspect-ratio-calculator" class="sidebar-sub-menu-link">
                    Aspect Ratio Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/nursery-space-calculator" class="sidebar-sub-menu-link">
                    Nursery Space Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/nursery-ratio-calculator" class="sidebar-sub-menu-link">
                    Nursery Ratio Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/reverse-percentage-calculator" class="sidebar-sub-menu-link">
                    Reverse Percentage Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/slugging-percentage-calculator" class="sidebar-sub-menu-link">
                    Slugging Percentage Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/algebra-solver" class="sidebar-sub-menu-link">
                    Algebra Solver
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseFive"
            aria-expanded="false"
            aria-controls="sidebarCollapseFive"
          >
          <i class="ph ph-gear"></i>
            <span class="title">Generators</span>
          </a>
          <div
            id="sidebarCollapseFive"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/starfield-name-generator" class="sidebar-sub-menu-link">
                    Starfield Name Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/roman-name-generator" class="sidebar-sub-menu-link">
                    Roman Name Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/random-paragraph-generator" class="sidebar-sub-menu-link">
                    Random Paragraph Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/word-ladder-generator" class="sidebar-sub-menu-link">
                    Word Ladder Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/blank-space-generator" class="sidebar-sub-menu-link">
                    Blank Space Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/subscript-generator" class="sidebar-sub-menu-link">
                    Subscript Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/superscript-generator" class="sidebar-sub-menu-link">
                    Superscript Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/bubble-text-generator" class="sidebar-sub-menu-link">
                    Bubble Text Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/christmas-elf-name-generator" class="sidebar-sub-menu-link">
                    Christmas Elf Name Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/farm-name-generator" class="sidebar-sub-menu-link">
                    Farm Name Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/writing-prompt-generator" class="sidebar-sub-menu-link">
                    Writing Prompt Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/random-facts-generator" class="sidebar-sub-menu-link">
                    Random Facts Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/random-question-generator" class="sidebar-sub-menu-link">
                    Random Question Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/random-bible-verse-generator" class="sidebar-sub-menu-link">
                    Random Bible Verse Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/random-username-generator" class="sidebar-sub-menu-link">
                    Random Username Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/online-text-repeater" class="sidebar-sub-menu-link">
                    Online Text Repeater
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/art-prompt-generator" class="sidebar-sub-menu-link">
                    Art Prompt Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/random-word-generator" class="sidebar-sub-menu-link">
                    Random Word Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link :to="{ path: '/random-pin-generator' }" class="sidebar-sub-menu-link" :class="{ 'active': isRandomPinGeneratorActive }">
                    Random Pin Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/random-nba-team-generator" class="sidebar-sub-menu-link">
                    Random NBA Team Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/spongebob-mocking-text-generator" class="sidebar-sub-menu-link">
                    Spongebobs Mocking Text Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/text-to-ascii-art-generator" class="sidebar-sub-menu-link">
                    Text To Ascii Art Generator
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseSix"
            aria-expanded="false"
            aria-controls="sidebarCollapseSix"
          >
            <i class="bi-shield-lock"></i>
            <span class="title">Encoders / Decoders</span>
          </a>
          <div
            id="sidebarCollapseSix"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/ntlm-hash-generator" class="sidebar-sub-menu-link">
                    NTLM Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/md2-hash-generator" class="sidebar-sub-menu-link">
                    MD2 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/md4-hash-generator" class="sidebar-sub-menu-link">
                    MD4 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/md5-hash-generator" class="sidebar-sub-menu-link">
                    MD5 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/md6-hash-generator" class="sidebar-sub-menu-link">
                    MD6 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/ripemd-128-hash-generator" class="sidebar-sub-menu-link">
                    RIPEMD-128 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/ripemd-160-hash-generator" class="sidebar-sub-menu-link">
                    RIPEMD-160 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/ripemd-256-hash-generator" class="sidebar-sub-menu-link">
                    RIPEMD-256 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/ripemd-320-hash-generator" class="sidebar-sub-menu-link">
                    RIPEMD-320 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sha1-hash-generator" class="sidebar-sub-menu-link">
                    SHA-1 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sha3-224-hash-generator" class="sidebar-sub-menu-link">
                    SHA3-224 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sha3-256-hash-generator" class="sidebar-sub-menu-link">
                    SHA3-256 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sha3-384-hash-generator" class="sidebar-sub-menu-link">
                    SHA3-384 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sha3-512-hash-generator" class="sidebar-sub-menu-link">
                    SHA3-512 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sha-224-hash-generator" class="sidebar-sub-menu-link">
                    SHA-224 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sha-256-hash-generator" class="sidebar-sub-menu-link">
                    SHA-256 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sha-384-hash-generator" class="sidebar-sub-menu-link">
                    SHA-384 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sha-512-hash-generator" class="sidebar-sub-menu-link">
                    SHA-512 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/crc8-hash-generator" class="sidebar-sub-menu-link">
                    CRC8 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/crc16-hash-generator" class="sidebar-sub-menu-link">
                    CRC16 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/crc24-hash-generator" class="sidebar-sub-menu-link">
                    CRC24 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/crc32-hash-generator" class="sidebar-sub-menu-link">
                    CRC32 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/adler32-hash-generator" class="sidebar-sub-menu-link">
                    Adler-32 Hash Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/whirlpool-hash-generator" class="sidebar-sub-menu-link">
                    Whirlpool Hash Generator
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseSeven"
            aria-expanded="false"
            aria-controls="sidebarCollapseSeven"
          >
            <i class="ph ph-math-operations"></i>
            <span class="title">Maths</span>
          </a>
          <div
            id="sidebarCollapseSeven"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/square-root-calculator" class="sidebar-sub-menu-link">
                    Square Root Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/nth-root-calculator" class="sidebar-sub-menu-link">
                    Nth Root Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/how-many-days-until" class="sidebar-sub-menu-link">
                    How Many Days Until?
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link 
                    :to="{ path: '/what-divided-by-what-equals-1' }" 
                    custom
                    v-slot="{ navigate, isActive }"
                  >
                    <span 
                      @click="navigate" 
                      class="sidebar-sub-menu-link"
                      :class="{ 'active': isActive || $route.path.startsWith('/what-divided-by-what-equals-') }"
                      style="cursor: pointer;"
                    >
                      What Divided By What Calculator
                    </span>
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/percentage-change-calculator" class="sidebar-sub-menu-link">
                    Percentage Change Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/gcd-calculator" class="sidebar-sub-menu-link">
                    GCD Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sine-calculator" class="sidebar-sub-menu-link">
                    Sine Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/absolute-value-calculator" class="sidebar-sub-menu-link">
                    Absolute Value Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/online-math-calculator" class="sidebar-sub-menu-link">
                    Online Math Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/adding-fractions-calculator" class="sidebar-sub-menu-link">
                    Adding Fractions Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/addition-calculator" class="sidebar-sub-menu-link">
                    Addition Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/antilog-calculator" class="sidebar-sub-menu-link">
                    Antilog Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/arccos-calculator" class="sidebar-sub-menu-link">
                    Arccos Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/arcsin-calculator" class="sidebar-sub-menu-link">
                    Arcsin Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/arctan-calculator" class="sidebar-sub-menu-link">
                    Arctan Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/average-calculator" class="sidebar-sub-menu-link">
                    Average Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/complex-numbers-calculator" class="sidebar-sub-menu-link">
                    Complex Numbers Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/convolution-calculator" class="sidebar-sub-menu-link">
                    Convolution Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/cosine-calculator" class="sidebar-sub-menu-link">
                    Cosine Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/dividing-fractions-calculator" class="sidebar-sub-menu-link">
                    Dividing Fractions Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/division-calculator" class="sidebar-sub-menu-link">
                    Division Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/exponential-growth-calculator" class="sidebar-sub-menu-link">
                    Exponential Growth Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/exponents-calculator" class="sidebar-sub-menu-link">
                    Exponents Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/factorial-calculator" class="sidebar-sub-menu-link">
                    Factorial Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/fractions-calculator" class="sidebar-sub-menu-link">
                    Fractions Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/gcf-calculator" class="sidebar-sub-menu-link">
                    GCF Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/lcm-calculator" class="sidebar-sub-menu-link">
                    LCM Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/ln-calculator" class="sidebar-sub-menu-link">
                    Ln Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/log-calculator" class="sidebar-sub-menu-link">
                    Log Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/multiplication-calculator" class="sidebar-sub-menu-link">
                    Multiplication Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/multiplying-fractions-calculator" class="sidebar-sub-menu-link">
                    Multiplying Fractions Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/percentage-calculator" class="sidebar-sub-menu-link">
                    Percentage Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/percent-error-calculator" class="sidebar-sub-menu-link">
                    Percent Error Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/percentage-increase-calculator" class="sidebar-sub-menu-link">
                    Percentage Increase Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/pythagorean-theorem-calculator" class="sidebar-sub-menu-link">
                    Pythagorean Theorem Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/quadratic-equation-solver" class="sidebar-sub-menu-link">
                    Quadratic Equation Solver
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/random-number-generator" class="sidebar-sub-menu-link">
                    Random Number Generator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                    :to="{ path: '/ratio-calculator' }"
                    class="sidebar-sub-menu-link"
                    :class="{ 'active': $route.path.startsWith('/ratio-calculator') }"
                  >
                    Ratio Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/remainder-calculator" class="sidebar-sub-menu-link">
                    Remainder Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/scientific-notation-calculator" class="sidebar-sub-menu-link">
                    Scientific Notation Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/prime-factor-calculator" class="sidebar-sub-menu-link">
                    Prime Factor Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/simplifying-fractions-calculator" class="sidebar-sub-menu-link">
                    Simplifying Fractions Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/standard-deviation-calculator" class="sidebar-sub-menu-link">
                    Standard Deviation Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/subtracting-fractions-calculator" class="sidebar-sub-menu-link">
                    Subtracting Fractions Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/subtraction-calculator" class="sidebar-sub-menu-link">
                    Subtraction Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/tangent-calculator" class="sidebar-sub-menu-link">
                    Tangent Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/trigonometry-calculator" class="sidebar-sub-menu-link">
                    Trigonometry Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/weighted-average-calculator" class="sidebar-sub-menu-link">
                    Weighted Average Calculator
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/variance-calculator" class="sidebar-sub-menu-link">
                    Variance Calculator
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseEight"
            aria-expanded="false"
            aria-controls="sidebarCollapseEight"
          >
            <i class="ph ph-book-open-text"></i>
            <span class="title">English</span>
          </a>
          <div
            id="sidebarCollapseEight"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/syllable-counter" class="sidebar-sub-menu-link">
                    Syllable Counter
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/list-all-nouns-in-sentence" class="sidebar-sub-menu-link">
                    List All Nouns In Sentence
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/list-all-verbs-in-sentence" class="sidebar-sub-menu-link">
                    List All Verbs In Sentence
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/list-all-pronouns-in-sentence" class="sidebar-sub-menu-link">
                    List All Pronouns In Sentence
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/list-all-adjectives-in-sentence" class="sidebar-sub-menu-link">
                    List All Adjectives In Sentence
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/list-all-adverbs-in-sentence" class="sidebar-sub-menu-link">
                    List All Adverbs In Sentence
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/list-all-prepositions-in-sentence" class="sidebar-sub-menu-link">
                    List All Prepositions In Sentence
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/readability-calculator" class="sidebar-sub-menu-link">
                    Readability Calculator
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sub-title sidebar-nav-item">
          <span class="d-block text-uppercase fw-medium">Pages</span>
        </li>
        <li class="sidebar-nav-item">
          <router-link 
            to="/blog" 
            class="sidebar-nav-link d-block"
            :class="{ 'active': isBlogActive }"
          >
            <i class="flaticon-web"></i>
            <span class="title">Blog</span>
          </router-link>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseTwelve"
            aria-expanded="false"
            aria-controls="sidebarCollapseTwelve"
          >
            <i class="flaticon-settings"></i>
            <span class="title">Miscellaneous</span>
          </a>
          <div
            id="sidebarCollapseTwelve"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link
                    to="/privacy-policy"
                    class="sidebar-sub-menu-link"
                  >
                    Privacy Policy
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                    to="/terms-conditions"
                    class="sidebar-sub-menu-link"
                  >
                    Terms & Conditions
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                    to="/about-us"
                    class="sidebar-sub-menu-link"
                  >
                    About Us
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import stateStore from "../../utils/store";

export default defineComponent({
  name: "MainSidebar",
  setup() {
    const stateStoreInstance = stateStore;
    const route = useRoute();

    const isWhatDividedByCalculatorActive = computed(() => {
      return route.path.startsWith('/what-divided-by-calculator');
    });

    const isColorConverterActive = computed(() => {
      return route.path.startsWith('/color-converter');
    });

    const isRandomPinGeneratorActive = computed(() => {
      return route.path.startsWith('/random-') && route.path.endsWith('-pin-generator');
    });

    const isBlogActive = computed(() => {
      return route.path.startsWith('/blog') || route.name === 'BlogPost';
    });

    const handleMenuItemClick = (event: MouseEvent) => {
      if (window.innerWidth <= 768) { // Assuming 768px is your mobile breakpoint
        const target = event.target as HTMLElement;
        if (target.classList.contains('sidebar-sub-menu-link') || target.classList.contains('sidebar-nav-link')) {
          stateStoreInstance.onChange();
        }
      }
    };

    return {
      stateStoreInstance,
      isRandomPinGeneratorActive,
      isColorConverterActive,
      isWhatDividedByCalculatorActive,
      isBlogActive,
      handleMenuItemClick,
    };
  },
});
</script>

<style scoped>
  .sidebar-area .sidebar-menu .sidebar-navbar-nav .sidebar-nav-item .accordion-collapse .accordion-body .sidebar-sub-menu .sidebar-sub-menu-item .sidebar-sub-menu-link {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
  }
  .sidebar-nav-link.active {
  background-color: #7a76ff;
  color: #fff;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px;
}
</style>