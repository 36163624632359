<template>
  <BreadCrumb PageTitle="Wall Art Size Calculator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Wall Art Size Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Easily calculate the perfect art size for your wall with our Wall Art Size Calculator. Add multiple artworks and customize gaps.
    </p>
    <form @submit.prevent="calculate">
      <h5>Wall Dimensions</h5>
      <div class="input-group mb-3">
        <label class="input-group-text pe-3" for="wallWidth">Wall Width</label>
        <input
          type="number"
          class="form-control"
          id="wallWidth"
          v-model.number="wallWidth"
          placeholder="Enter wall width in cm"
          min="1"
          required
          aria-describedby="wallWidthHelp"
        >
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text pe-2" for="wallHeight">Wall Height</label>
        <input
          type="number"
          class="form-control"
          id="wallHeight"
          v-model.number="wallHeight"
          placeholder="Enter wall height in cm"
          min="1"
          required
          aria-describedby="wallHeightHelp"
        >
      </div>
      <h5>Gap Between Artworks</h5>
      <div class="input-group mb-3">
        <span class="input-group-text" id="artGapLabel">Gap (cm)</span>
        <input
          type="number"
          class="form-control"
          id="artGap"
          v-model.number="artGap"
          placeholder="Enter gap between artworks in cm"
          min="0"
          required
          aria-describedby="artGapHelp"
        >
      </div>
      <h5>Artworks</h5>
      <div id="artworkFields">
        <div
          v-for="(artwork, index) in artworks"
          :key="artwork.id"
          class="artwork mb-4 p-3 border rounded"
        >
          <h6>Artwork {{ index + 1 }}</h6>
          <div class="input-group mb-3">
            <span class="input-group-text" :for="'artWidth-' + artwork.id">Width (cm)</span>
            <input
              :id="'artWidth-' + artwork.id"
              class="form-control"
              type="number"
              v-model.number="artwork.width"
              placeholder="Enter artwork width in cm"
              min="1"
              required
              aria-describedby="artWidthHelp"
            >
            <span class="input-group-text" :for="'artHeight-' + artwork.id">Height (cm)</span>
            <input
              :id="'artHeight-' + artwork.id"
              class="form-control"
              type="number"
              v-model.number="artwork.height"
              placeholder="Enter artwork height in cm"
              min="1"
              required
              aria-describedby="artHeightHelp"
            >
          </div>
          <button
            type="button"
            class="btn btn-danger"
            @click="removeArtwork(index)"
            v-if="artworks.length > 1"
            aria-label="Remove Artwork"
          >
            Remove Artwork
          </button>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row gap-3 mb-4">
        <button type="button" class="btn btn-secondary w-100" @click="addArtworkField">
          Add Another Artwork
        </button>
        <button type="submit" class="btn btn-primary w-100">
          Calculate
        </button>
      </div>
    </form>
    <div v-if="alert" class="alert alert-danger" role="alert">
      {{ alert }}
    </div>
    <div v-if="result" class="mt-4">
      <h5>Result:</h5>
      <p class="fs-5">{{ result }}</p>
    </div>
    <div class="mt-4">
      <WallArtSizeCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import WallArtSizeCalculatorFAQ from '../FAQs/wallArtSizeCalculatorFAQ.vue';

export default {
  name: 'WallArtCalculator',
  components: {
    BreadCrumb,
    WallArtSizeCalculatorFAQ
  },
  setup() {
    const wallWidth = ref('');
    const wallHeight = ref('');
    const artGap = ref('');
    const alert = ref('');
    const result = ref('');

    const artworks = reactive([
      { id: Date.now(), width: '', height: '' }
    ]);

    const addArtworkField = () => {
      artworks.push({ id: Date.now() + Math.random(), width: '', height: '' });
    };

    const removeArtwork = (index) => {
      artworks.splice(index, 1);
    };

    const calculate = () => {
      alert.value = '';
      result.value = '';

      // Validate wall dimensions and art gap
      if (wallWidth.value <= 0 || wallHeight.value <= 0 || artGap.value < 0) {
        alert.value = 'Please enter valid wall dimensions and gap.';
        return;
      }

      // Validate each artwork
      for (let i = 0; i < artworks.length; i++) {
        const art = artworks[i];
        if (art.width <= 0 || art.height <= 0) {
          alert.value = `Please enter valid dimensions for Artwork ${i + 1}.`;
          return;
        }
      }

      // Calculate total width required
      const totalArtWidth = artworks.reduce((sum, art) => sum + Number(art.width), 0);
      const totalGapsWidth = artGap.value * (artworks.length - 1);
      const totalWidthRequired = totalArtWidth + totalGapsWidth;

      // Calculate maximum height required
      const maxArtHeight = Math.max(...artworks.map(art => Number(art.height)));

      if (totalWidthRequired > wallWidth.value || maxArtHeight > wallHeight.value) {
        result.value = "The artworks are too large for the wall. Please adjust the sizes or the number of artworks.";
      } else {
        const remainingWidth = wallWidth.value - totalWidthRequired;
        const remainingHeight = wallHeight.value - maxArtHeight;
        result.value = `The artworks fit on the wall. There is ${remainingWidth} cm of space left horizontally and ${remainingHeight} cm vertically.`;
      }
    };

    const reset = () => {
      wallWidth.value = '';
      wallHeight.value = '';
      artGap.value = '';
      alert.value = '';
      result.value = '';
      // Reset artworks to one empty artwork
      artworks.splice(0, artworks.length, { id: Date.now(), width: '', height: '' });
    };

    return {
      wallWidth,
      wallHeight,
      artGap,
      artworks,
      alert,
      result,
      addArtworkField,
      removeArtwork,
      calculate,
      reset
    };
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.form-label {
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.form-control {
  font-size: 16px;
}

.alert {
  font-size: 14px;
}

.artwork {
  background-color: #f8f9fa;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
