<template>
    <BreadCrumb PageTitle="HTML To Text Converter" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">HTML To Text Converter</h1>
      <p class="fs-6 text-center mb-4">Easily convert your HTML content into plain text format. Use beautify HTML and copy the plain text output.</p>
      <div class="row mb-4">
        <div class="col-md-6 mb-4 mb-md-0">
          <div class="mb-2">
            <label for="inputHTML">Input (HTML):</label>
          </div>
          <textarea id="inputHTML" class="form-control" rows="25" v-model="inputHTML"></textarea>
        </div>
        <div class="col-md-6">
          <div class="markdown mb-2 d-flex justify-content-between align-items-center">
            <label>Output (Text):</label>
          </div>
          <div class="position-relative output-wrapper">
            <textarea id="outputText" class="form-control" rows="25" readonly :value="outputText"></textarea>
            <i 
              :class="['bi', copied ? 'bi-clipboard-check' : 'bi-clipboard', 'copy-icon']" 
              @click="copyToClipboard"
              :title="copied ? 'Copied!' : 'Copy to clipboard'"
            ></i>
          </div>
        </div>
      </div>
  
      <div class="text-center mb-4">
        <button class="btn btn-primary w-100" @click="convertToText">Convert</button>
      </div>
  
      <div class="alert alert-danger mt-3" v-if="error">Error converting HTML to Text!</div>

      <div>
        <HTMLToTextFAQ />
      </div>
    </div>
  </template>
  
  <script>
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import { html as beautifyHTML } from 'js-beautify';
  import { htmlToText } from 'html-to-text';  // Import the html-to-text library
  import HTMLToTextFAQ from "../FAQs/htmlToTextFAQ.vue"
  
  export default {
    components: {
      BreadCrumb,
      HTMLToTextFAQ
    },
    data() {
      return {
        inputHTML: '',
        beautifyHTML: false,
        outputText: '',
        error: false,
        copied: false,
      };
    },
    methods: {
      handleInput() {
        if (this.beautifyHTML) {
          this.inputHTML = beautifyHTML(this.inputHTML, { indent_size: 2, space_in_empty_paren: true });
        }
      },
      convertToText() {
        try {
          // Use html-to-text to convert HTML to plain text, with custom selectors for blockquote and cite
          this.outputText = htmlToText(this.inputHTML, {
            wordwrap: 130,
            selectors: [
              {
                selector: 'blockquote',
                format: 'block',  // Treat blockquote as a block element
                options: {
                  leadingLineBreaks: 1,
                  trailingLineBreaks: 1,
                },
                convert: (node, options) => {
                  return `\n"${node.children.map(child => child.data.trim()).join(' ')}"\n`;
                }
              },
              {
                selector: 'cite',
                format: 'inline',  // Treat cite as inline text
                convert: (node, options) => {
                  return ` — ${node.children.map(child => child.data.trim()).join(' ')}`;
                }
              }
            ]
          });
          this.error = false;
        } catch (e) {
          this.outputText = ''; // Set output to empty if an error occurs
          this.error = true;
        }
      },
      copyToClipboard() {
        navigator.clipboard.writeText(this.outputText).then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-container {
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .custom-container {
      padding: 0 0.5rem;
    }
  }
  
  label, .form-control {
    font-size: 16px;
  }
  
  @media (max-width: 767px) {
    .row {
      flex-direction: column;
    }
  }
  
  .output-wrapper {
    position: relative;
  }
  
  .copy-icon {
    position: absolute;
    bottom: 10px;
    right: 25px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #6c757d;
    z-index: 1;
  }
  
  .copy-icon:hover {
    color: #495057;
  }
  
  textarea {
    resize: vertical;
    padding-right: 40px;
  }
  
  .btn {
    padding: 10px 20px;
  }
  </style>
