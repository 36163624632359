<template>
  <BreadCrumb PageTitle="Advanced Scientific Calculator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Scientific Calculator</h1>
    <div class="calculator card">
      <div class="card-body">
        <div class="display mb-3">
          <div class="equation text-end text-muted small">{{ equationDisplay }}</div>
          <input type="text" class="form-control form-control-lg text-end" v-model="display" readonly>
          <span v-if="transparentBracket" class="transparent-bracket">)</span>
        </div>
        <div class="buttons">
          <div class="row g-2 mb-2" v-for="row in buttonRows" :key="row.join()">
            <div class="col" v-for="button in row" :key="button">
              <button 
                class="btn btn-sm w-100" 
                :class="getButtonClass(button)"
                @click="handleButtonClick(button)"
              >
                {{ button === 'Rad/Deg' ? (isRadianMode ? 'Rad' : 'Deg') : button }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <h3>Operand Descriptions</h3>
      <ul class="list-group">
        <li class="list-group-item" v-for="(desc, op) in operandDescriptions" :key="op">
          <strong>{{ op }}:</strong> {{ desc }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as math from 'mathjs';
import { sqrt, cbrt, pow, exp } from 'mathjs';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import { calculateSin, calculateCos, calculateTan, formatResult } from '@/components/Calculator/TrigonometricOperations';
import { calculateLog, calculateLog10, calculateLog2, calculateExp, formatResult as formatLogResult } from '@/components/Calculator/LogarithmicOperations';

export default {
  components: {
    BreadCrumb,
  },
  data() {
    return {
      display: '0',
      memory: 0,
      equation: '',
      lastResult: null,
      lastLogFunc: '',
      isRadianMode: true,
      currentInput: '', // Tracks current input
      previousInput: '', // Tracks previous input for operations like power (x^y)
      calculationPerformed: false,
      isError: false,
      transparentBracket: false,
      reciprocalMode: false,
      reciprocalInput: '',
      powerMode: false,
      powerBase: '',
      expMode: false,
      mantissa: '',
      buttonRows: [
      ['(', ')', 'mc', 'm+', 'm-', 'mr'],
        ['1/x', '√', '∛', '%', 'Rand'],
        ['eˣ', 'log₂', 'sin', 'cos', 'tan', 'Rad/Deg'],
        ['π', 'log', 'ln', 'e', 'x²', 'xʸ', ],
        ['C', 'AC', '±', '÷'],
        ['7', '8', '9', '×'],
        ['4', '5', '6', '-'],
        ['1', '2', '3', '+'],
        ['0', '.', 'EXP', '=']
      ],
      operandDescriptions: {
        '+': 'Addition',
        '-': 'Subtraction',
        '×': 'Multiplication',
        '÷': 'Division',
        '%': 'Percentage',
        '±': 'Toggle sign',
        'sin': 'Sine function',
        'cos': 'Cosine function',
        'tan': 'Tangent function',
        'log': 'Logarithm (base 10)',
        'ln': 'Natural logarithm',
        '√': 'Square root',
        'x²': 'Square',
        'x^y': 'Exponentiation',
        'π': 'Pi (3.14159...)',
        'e': 'Euler\'s number (2.71828...)',
        '(': 'Open parenthesis',
        ')': 'Close parenthesis',
        '1/x': 'Reciprocal',
        'Rand': 'Random number',
        '∛': 'Cube root',
        '2ˣ': 'Power of 2',
        'eˣ': 'Exponential function',
        '10ˣ': 'Power of 10',
        'log₂': 'Logarithm (base 2)',
        'mc': 'Memory clear',
        'm+': 'Memory add',
        'm-': 'Memory subtract',
        'mr': 'Memory recall',
      }
    };
  },
  computed: {
    equationDisplay() {
      if (this.isError) {
        return this.equation; // Display the erroneous equation when there's an error
      } else if (this.equation) {
        // Handle EXP display in equation
        if (this.equation.includes('EXP')) {
          return this.equation;
        }
        const openParenCount = (this.equation.match(/\(/g) || []).length;
        const closeParenCount = (this.equation.match(/\)/g) || []).length;
        if (openParenCount > closeParenCount) {
          return this.equation + ')'.repeat(openParenCount - closeParenCount);
        }
        return this.equation;
      } else if (this.lastResult) {
        return `Ans = ${this.lastResult}`;
      } else {
        if (this.display.includes('EXP')) {
          return this.display;
        }
        const openParenCount = (this.display.match(/\(/g) || []).length;
        const closeParenCount = (this.display.match(/\)/g) || []).length;
        if (openParenCount > closeParenCount) {
          return this.display + ')'.repeat(openParenCount - closeParenCount);
        }
        return this.display;
      }
    }
  },
  methods: {
    handleButtonClick(button) {
    if (this.isError && button !== 'AC') {
      this.clearError();
    }

    switch(button) {
      case 'Rad/Deg':
        this.toggleAngleMode();
        break;
      case 'C':
        this.clear();
        break;
      case 'AC':
        this.allClear();
        break;
      case '=':
        this.calculate();
        break;
      case 'π':
        this.addToDisplay('π');
        break;
      case 'sin':
      case 'cos':
      case 'tan':
        this.addTrigFunction(button);
        break;
      case 'log':
      case 'ln':
      case 'log₂':
      case 'eˣ':
        this.addLogFunction(button);
        break;
      case '%':
        this.addPercentage();
        break;
      case '±':
        this.addToggleSign();
        break;
      case '+':
      case '-':
      case '×':
      case '÷':
        this.addOperator(button);
        break;
      case '1/x':
        this.addReciprocal();
        break;
      case 'x²':
        this.addSquare();
        break;
      case '√':
        this.addSquareRoot();
        break;
      case 'x³':
        this.addCube();
        break;
      case '∛':
        this.addCubeRoot();
        break;
      case 'xʸ':
        this.addPower();
        break;
      case 'mc':
        this.memoryClear();
        break;
      case 'm+':
        this.memoryAdd();
        break;
      case 'm-':
        this.memorySubtract();
        break;
      case 'mr':
        this.memoryRecall();
        break;
      case 'EXP':
        this.addEXP();
        break;
        default:
          if (this.expMode) {
            this.addToExponent(button);
          } else {
            this.addToDisplay(button);
          }
      }
  },

  addEXP() {
      // Convert this.display to a string if it's not already
      const displayStr = String(this.display);

      if (displayStr === '0' || displayStr === '' || parseFloat(displayStr) <= 0) {
        // If there's no mantissa or it's not greater than 0, just display 0
        this.display = '0';
        return;
      }

      if (!displayStr.includes('EXP')) {
        this.display = displayStr + 'EXP';
      }
    },

    addToExponent(value) {
      if (!isNaN(value)) {
        this.display += value;
      }
    },

    // Square (x²)
    addSquare() {
      this.display = pow(this.display, 2).toString();
    },

    // Cube (x³)
    addCube() {
      this.display = pow(this.display, 3).toString();
    },

    // Square Root (√x)
    addSquareRoot() {
      if (this.display) {
        this.display = `√(${this.display})`;
      }
    },

    // Cube Root (∛x)
    addCubeRoot() {
      this.display = cbrt(this.display).toString();
    },

    // Exponential (e^x)
    exponential() {
      this.display = exp(this.display).toString(); // Euler's number (e) to the power of x
    },
    toggleAngleMode() {
      this.isRadianMode = !this.isRadianMode;
    },
    clearError() {
      this.isError = false;
      this.display = '0';
      this.equation = '';
      this.transparentBracket = false;
    },
    addTrigFunction(func) {
      if (this.calculationPerformed || this.display === '0') {
        this.display = `${func}(`;
      } else {
        this.display += `${func}(`;
      }
      this.calculationPerformed = false;
      this.equation = '';
      this.transparentBracket = true;
    },
    addLogFunction(func) {
      let displayFunc = func;
      let calcFunc = func;
      
      if (func === 'ln') {
        displayFunc = 'ln';
        calcFunc = 'log'; // For natural log, we use 'log' in mathjs
      } else if (func === 'log₂') {
        displayFunc = 'log₂';
        calcFunc = 'log2';
      } else if (func === 'eˣ') {
        displayFunc = 'e^';
        calcFunc = 'exp';
      } else if (func === 'log') {
        displayFunc = 'log';
        calcFunc = 'log10'; // For base 10 log
      }

      if (this.calculationPerformed || this.display === '0') {
        this.display = `${displayFunc}(`;
      } else {
        this.display += `${displayFunc}(`;
      }
      
      this.calculationPerformed = false;
      this.equation = '';
      this.transparentBracket = true;
      
      // Store the calculation function name for later use
      this.lastLogFunc = calcFunc;
    },
    addPercentage() {
      let currentInput = this.display; // Use 'display' as the current input
      
      // Ensure that percentage cannot be added first or after a result (i.e., when input is empty or just '0')
      if (!currentInput || currentInput === '0' || typeof currentInput !== 'string') {
        return; // Do nothing if no valid number has been entered or after a result
      }

      // Proceed with adding percentage if input is valid
      const parts = currentInput.split(/([+\-×÷])/); // Split by operators
      const lastPart = parts[parts.length - 1];
      
      if (lastPart !== '' && !isNaN(parseFloat(lastPart)) && !lastPart.includes('%')) {
        parts[parts.length - 1] = lastPart + '%'; // Append '%' to the last number
        this.display = parts.join(''); // Join the parts back together and update the display
      }
    },

    addOperator(operator) {
      this.calculationPerformed = false;
      if (this.display === '0' || this.display === '') {
        this.display = '0' + operator;
      } else if (['+', '-', '×', '÷'].includes(this.display.slice(-1))) {
        this.display = this.display.slice(0, -1) + operator;
      } else {
        // Add the operator without closing the parenthesis
        this.display += operator;
      }
      this.equation = '';
      // Don't change transparentBracket here
    },

    clear() {
      if (this.expMode) {
        if (this.display.endsWith('EXP')) {
          this.display = this.mantissa;
          this.expMode = false;
        } else {
          this.display = this.display.slice(0, -1);
          if (this.display.endsWith('EXP')) {
            this.expMode = false;
          }
        }
      } else if (this.display.length > 1) {
        this.display = this.display.slice(0, -1);
      } else {
        this.display = '0';
      }
      this.isError = false;
      this.transparentBracket = false;
    },

    allClear() {
      this.display = '0';
      this.equation = '';
      this.lastResult = '';
      this.calculationPerformed = false;
      this.isError = false;
      this.transparentBracket = false;
      this.expMode = false;
      this.mantissa = '';
    },

    addToggleSign() {
      // Ensure display is treated as a string
      this.display = this.display.toString();

      // If the display is '0', do nothing
      if (this.display === '0') return;

      // Find the last opening parenthesis '('
      const lastOpeningParenthesis = this.display.lastIndexOf('(');

      // If there's a function (e.g., 'sin(') with a number inside the brackets
      if (lastOpeningParenthesis !== -1 && this.display.match(/(sin|cos|tan|log|ln)\(/)) {
        // Extract the inner value inside the function's parentheses
        const innerExpression = this.display.slice(lastOpeningParenthesis + 1);
        
        // Toggle the sign of the inner value
        if (innerExpression.startsWith('-')) {
          this.display = this.display.slice(0, lastOpeningParenthesis + 1) + innerExpression.slice(1);
        } else {
          this.display = this.display.slice(0, lastOpeningParenthesis + 1) + '-' + innerExpression;
        }
      } else {
        // If no parentheses or functions, toggle the sign of the whole number as usual
        if (this.display.startsWith('-')) {
          this.display = this.display.slice(1);  // Remove the negative sign to make it positive
        } else {
          this.display = '-' + this.display;  // Add a negative sign to make it negative
        }
      }
    },

    addPower() {
      if (this.display === '0' || this.calculationPerformed) {
        return; // Do nothing if there's no base number
      }
      this.powerMode = true;
      this.powerBase = this.display;
      this.display += '^(';
      this.transparentBracket = true;
      this.calculationPerformed = false;
    },

    preprocessExpression(expression) {
      // Handle cases like '4tan()', '4sin()', '3cos()', 'πtan()', 'πsin()', 'πcos()'
      expression = expression.replace(/(\d+(?:\.\d+)?|π)([a-zA-Z]+\()/g, (match, p1, p2) => {
        return `${p2}${p1}`;
      });

      // Add a leading zero for decimal inputs like .2 (transform it into 0.2)
      expression = expression.replace(/(^|[^0-9])(\.\d+)/g, (match, p1, p2) => {
        return `${p1}0${p2}`;
      });

      // Handle percentage calculations
      expression = expression.replace(/(-?\d+\.?\d*)\s*%/g, (match, p1) => {
        return `(${p1}/100)`;
      });

      return expression;
    },

    memoryClear() {
      this.memory = 0;
    },

    memoryAdd() {
      const currentValue = parseFloat(this.display);
      if (!isNaN(currentValue)) {
        this.memory += currentValue;
      }
    },

    memorySubtract() {
      const currentValue = parseFloat(this.display);
      if (!isNaN(currentValue)) {
        this.memory -= currentValue;
      }
    },

    memoryRecall() {
      this.display = this.memory.toString();
      this.calculationPerformed = true;
    },
    
    calculate() {
      if (this.calculationPerformed && this.display === this.lastResult) {
        return;
      }

      if (this.reciprocalMode) {
        // Don't add an extra closing parenthesis if it's already there
        if (!this.display.endsWith(')')) {
          this.display += ')';
        }
        this.reciprocalMode = false;
      }

      try {

        // Handle EXP calculation
        if (String(this.display).includes('EXP')) {
          const [mantissa, exponent] = String(this.display).split('EXP');
          const result = parseFloat(mantissa) * Math.pow(10, parseInt(exponent || '0'));
          this.lastResult = formatResult(result);
          this.equation = String(this.display); // Keep the EXP format in the equation
          this.display = this.lastResult;
          this.calculationPerformed = true;
          return;
        }

      // Ensure this.display is always a string after calculation
      this.display = String(this.display);

        // If nth root operation is in progress, process it
        if (this.nthRootMode) {
          const nthRootValue = this.display.replace(/[^0-9.]/g, ''); // Capture the nth root number (remove any non-numeric chars)
          
          // If the nth number is invalid, display an error
          if (!nthRootValue || isNaN(nthRootValue)) {
            this.display = 'ERROR';
            return;
          }

          // Use the stored first number for the nth root
          const firstNumber = parseFloat(this.lastInputForNthRoot);
          const nthRootResult = Math.pow(firstNumber, 1 / parseFloat(nthRootValue));

          // Update the display with the result
          this.lastResult = nthRootResult.toFixed(10); // Adjust decimal places as needed
          this.display = this.lastResult;
          this.equation = `${nthRootValue}√${firstNumber}`; // Display as nth root operation

          // Reset nth root mode
          this.nthRootMode = false;
          this.calculationPerformed = true;
          return;
        }

        // Preprocess the expression
        let evalExpression = this.preprocessExpression(this.display);
        let displayExpression = evalExpression; // For updating the equation display

        console.log('Preprocessed expression:', evalExpression); // Debugging log

        // Replace multiplication and division symbols
        evalExpression = evalExpression.replace(/×/g, '*').replace(/÷/g, '/');

        // Close any open parentheses before evaluation
        const openParenCount = (evalExpression.match(/\(/g) || []).length;
        const closeParenCount = (evalExpression.match(/\)/g) || []).length;
        evalExpression += ')'.repeat(openParenCount - closeParenCount);

        // Handle e^x function
        evalExpression = evalExpression.replace(/e\^(\(.*?\)|\d+|π)/g, (match, p1) => {
          return `exp(${p1})`;
        });

        // Handle reciprocal (1/x) without parentheses (e.g., 1/5)
        evalExpression = evalExpression.replace(/1\/(\d+(\.\d+)?)/g, (match, p1) => {
          return `(1/${p1})`;
        });

        // Handle reciprocal with parentheses (e.g., 1/(5 + 3))
        evalExpression = evalExpression.replace(/1\/\(([^)]+)\)/g, (match, p1) => {
          return `(1/(${p1}))`;
        });

        // Handle square, cube, and power functions
        evalExpression = evalExpression.replace(/(\d+(\.\d+)?)\^2/g, (match, p1) => {
          return `pow(${p1}, 2)`; // Square
        });
        evalExpression = evalExpression.replace(/(\d+(\.\d+)?)\^3/g, (match, p1) => {
          return `pow(${p1}, 3)`; // Cube
        });

        // Handle square root, cube root, and nth root
        evalExpression = evalExpression.replace(/√(\d+(\.\d+)?)/g, (match, p1) => {
          return `sqrt(${p1})`;
        });
        evalExpression = evalExpression.replace(/√\(([^)]+)\)/g, (match, p1) => {
          return `sqrt(${p1})`;
        });
        evalExpression = evalExpression.replace(/∛(\d+(\.\d+)?)/g, (match, p1) => {
          return `cbrt(${p1})`;
        });
        evalExpression = evalExpression.replace(/∛\(([^)]+)\)/g, (match, p1) => {
          return `cbrt(${p1})`; // Cube root
        });

        // Handle trigonometric functions with radian/degree toggle
        evalExpression = evalExpression.replace(/sin\(([^)]+)\)/g, (match, p1) => {
          return calculateSin(p1, this.isRadianMode);
        });
        evalExpression = evalExpression.replace(/cos\(([^)]+)\)/g, (match, p1) => {
          return calculateCos(p1, this.isRadianMode);
        });
        evalExpression = evalExpression.replace(/tan\(([^)]+)\)/g, (match, p1) => {
          return calculateTan(p1, this.isRadianMode);
        });

        // Handle power operation
        evalExpression = evalExpression.replace(/(\d+(?:\.\d+)?)\^(\(.*?\)|\d+(?:\.\d+)?)/g, (match, base, exponent) => {
          return `pow(${base}, ${exponent})`;
        });

        // Handle logarithmic functions
        evalExpression = evalExpression.replace(/ln\(([^)]+)\)/g, (match, p1) => calculateLog(parseFloat(p1)));
        evalExpression = evalExpression.replace(/log\(([^)]+)\)/g, (match, p1) => calculateLog10(parseFloat(p1)));
        evalExpression = evalExpression.replace(/log₂\(([^)]+)\)/g, (match, p1) => calculateLog2(parseFloat(p1)));

        // Replace π with its numeric value
        evalExpression = evalExpression.replace(/π/g, Math.PI);

        console.log('Final expression to evaluate:', evalExpression); // Debugging log

        // Evaluate the expression
        const calculationResult = math.evaluate(evalExpression);

        // Update both the equation display and the result display
        this.equation = displayExpression; // Update the equation display at the top
        this.lastResult = formatResult(calculationResult);
        this.display = this.lastResult;
        this.calculationPerformed = true;
        this.isError = false;
        this.transparentBracket = false;
        this.reciprocalMode = false;
        this.reciprocalInput = '';
        this.powerMode = false;
        this.powerBase = '';
      } catch (error) {
        console.error('Calculation error:', error); // For debugging
        console.log('Wrong equation:', this.display); // Log the wrong equation
        this.errorEquation = this.display; // Store the erroneous equation
        this.display = 'ERROR';
        this.equation = this.errorEquation; // Set equation to the erroneous input
        this.lastResult = '';
        this.isError = true;
        this.transparentBracket = false;
      }
    },

    addReciprocal() {
      if (this.display === '0' || this.calculationPerformed) {
        this.display = '1/(';
        this.reciprocalInput = '';
      } else {
        this.reciprocalInput = this.display;
        this.display = `1/(${this.display}`;
      }
      this.transparentBracket = true;
      this.reciprocalMode = true;
      this.calculationPerformed = false;
    },

    addToDisplay(value) {
      // Convert this.display to a string if it's not already
      let displayStr = String(this.display);

      if (this.calculationPerformed) {
        this.display = value;
        this.calculationPerformed = false;
      } else if (displayStr.includes('EXP')) {
        // If we're in EXP mode, only allow adding digits after EXP
        const [mantissa, exponent] = displayStr.split('EXP');
        if (exponent === undefined || (!isNaN(value) && value !== '.')) {
          this.display = displayStr + value;
        }
      } else if (this.powerMode) {
        if (displayStr.endsWith('^(')) {
          this.display = displayStr + value;
        } else {
          this.display = displayStr.slice(0, -1) + value + ')';
        }
      } else if (displayStr === '0' && value !== '.') {
        this.display = value;
      } else {
        this.display = displayStr + value;
      }
      
      // Update displayStr after modifications
      displayStr = String(this.display);

      // Check for open parenthesis of trig functions or reciprocal
      const functionsWithParenthesis = ['sin(', 'cos(', 'tan(', '1/('];
      if (functionsWithParenthesis.some(func => displayStr.endsWith(func))) {
        this.transparentBracket = true;
      } else if (value === ')') {
        this.transparentBracket = false;
        if (this.reciprocalMode) {
          this.reciprocalMode = false;
        }
      }
      
      this.equation = '';
    },

    getButtonClass(button) {
      if (button === 'Rad/Deg') {
        return this.isRadianMode ? 'btn-info' : 'btn-secondary';
      } else if (['÷', '×', '-', '+', '='].includes(button)) {
        return 'btn-warning';
      } else if (['C', 'AC'].includes(button)) {
        return 'btn-danger';
      } else if (!isNaN(button) || button === '.') {
        return 'btn-secondary';
      } else {
        return 'btn-info';
      }
    },
  }
};
</script>

<style scoped>
.custom-container {
  max-width: 450px;
  margin: auto;
}
.calculator {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
}
.display {
  position: relative;
  height: 80px;
}
.display input {
  font-size: 1.5rem;
  height: 100%;
}
.equation {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 0.8rem;
}
.buttons .btn {
  font-size: 0.8rem;
  height: 35px;
  padding: 0.25rem 0.5rem;
}
.buttons .btn-secondary {
  background-color: #dadce0;
  border-color: #dadce0;
  color: #202124;
}
.buttons .btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
  color: #f8f9fa;
}
.input-wrapper {
  position: relative;
}
.transparent-bracket {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  font-size: 1.5rem;
}
</style>