<template>
  <BreadCrumb PageTitle="Nth Root Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Nth Root Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the nth root of a given number. Enter the number and the root value (n) to find the result along with the calculation steps.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Number -->
        <div class="mb-3">
          <label for="number" class="form-label">Number:</label>
          <input
            type="text"
            id="number"
            v-model="numberInput"
            class="form-control"
            placeholder="e.g., 16, 27, 64"
          />
        </div>

        <!-- Input for Root Value -->
        <div class="mb-3">
          <label for="root" class="form-label">Root Value (n):</label>
          <input
            type="text"
            id="root"
            v-model="rootValue"
            class="form-control"
            placeholder="e.g., 2 for square root, 3 for cube root"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateRoot" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Number: {{ result.number }}</p>
          <p class="fs-5">Root (n): {{ result.root }}</p>
          <p class="fs-5">Calculation: {{ result.calculation }}</p>
          <p class="fs-5">Final Result: {{ result.rootResult }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter a number in the first input field (e.g., 16, 27, 64).</li>
        <li>Enter the root value (n) in the second input field (e.g., 2 for square root, 3 for cube root).</li>
        <li>Click "Calculate" to find the nth root of the entered number.</li>
        <li>The result will show the input number, the root value, the calculation process, and the final result.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Formula for Nth Root:</h5>
      <div class="fs-6">
        The nth root of a number \( x \) is given by the formula:
      </div>
      <div>
        \[
        \sqrt[n]{x} = x^{1/n}
        \]
      </div>
      <p class="fs-6">
        This means that \( \sqrt[n]{x} \) is the number that, when raised to the power of \( n \), gives \( x \):
      </p>
      <div>
        \[
        \left( \sqrt[n]{x} \right)^n = x
        \]
      </div>

      <h5 class="alert-heading">Properties of Nth Root:</h5>
      <ul class="fs-6">
        <li>Square root: \( \sqrt{16} = 4 \) because \( 4 \times 4 = 16 \).</li>
        <li>Cube root: \( \sqrt[3]{27} = 3 \) because \( 3 \times 3 \times 3 = 27 \).</li>
        <li>Fourth root: \( \sqrt[4]{16} = 2 \) because \( 2 \times 2 \times 2 \times 2 = 16 \).</li>
        <li>For negative numbers, the nth root is real only for odd values of \( n \).</li>
      </ul>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For \( \sqrt[2]{16} \):<br />
          Calculation: \( 16^{1/2} = 4 \).
        </li>
        <li>
          For \( \sqrt[3]{27} \):<br />
          Calculation: \( 27^{1/3} = 3 \).
        </li>
        <li>
          For \( \sqrt[4]{81} \):<br />
          Calculation: \( 81^{1/4} = 3 \).
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const numberInput = ref("");
    const rootValue = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate nth root
    const calculateRoot = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the input values
        const number = parseFloat(numberInput.value.trim());
        const root = parseFloat(rootValue.value.trim());

        if (isNaN(number) || isNaN(root) || root <= 0) {
          throw new Error("Please enter valid positive values for both the number and root.");
        }

        // Calculate the nth root
        const rootResult = Math.pow(number, 1 / root);
        const calculation = `${number} ^ (1/${root}) = ${rootResult.toFixed(6)}`;

        result.value = {
          number: number,
          root: root,
          calculation: calculation,
          rootResult: rootResult.toFixed(6),
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      numberInput.value = "";
      rootValue.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      numberInput,
      rootValue,
      result,
      error,
      calculateRoot,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
