<template>
  <div class="leaderboard-container mt-4 mb-5">
    <h2 class="text-center mb-20">{{ title }}</h2>
    <div v-if="loading" class="loading-container">
      <div class="loading-text">Loading...</div>
    </div>
    <div v-else class="card w-75 mx-auto mb-20">
      <div class="card-body">
        <ul class="list-group">
          <li
            v-for="(entry, index) in displayedLeaderboard"
            :key="entry.id"
            class="list-group-item d-flex justify-content-between align-items-center"
            :class="{ 'list-group-item-warning': index === 0 }"
          >
            <span>
              <i v-if="index === 0" class="bi bi-trophy-fill text-warning"></i>
              {{ index + 1 }}. {{ entry.name }}
            </span>
            <span>{{ entry.score }}</span>
          </li>
          <li v-if="playerBeyondTop10" class="list-group-item d-flex justify-content-between align-items-center">
            <span>{{ playerPosition }}. {{ playerName }}</span>
            <span>{{ score }}</span>
          </li>
        </ul>
      </div>
      <div class="card-footer text-center">
        <button class="btn btn-primary w-100" @click="goBack">Back</button>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from '@/supabase'; // Adjust the path as needed

export default {
  name: 'LeaderboardTemplate',
  props: {
    tableName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Global Leaderboard'
    },
    playerName: {
      type: String,
      default: ''
    },
    score: {
      type: Number,
      default: 0
    },
    from: { // Prop to handle the route to navigate back
      type: String,
      required: true
    }
  },
  data() {
    return {
      leaderboard: [],
      playerBeyondTop10: false,
      playerPosition: null,
      loading: true // Add loading state
    };
  },
  computed: {
    displayedLeaderboard() {
      return this.leaderboard.slice(0, 10);
    }
  },
  methods: {
    async fetchLeaderboard() {
      try {
        const { data, error } = await supabase
          .from(this.tableName)
          .select('*')
          .order('score', { ascending: false })
          .limit(10);

        if (error) throw error;

        this.leaderboard = data;

        // Determine if the player's score is beyond the top 10
        const { data: allData } = await supabase
          .from(this.tableName)
          .select('*')
          .order('score', { ascending: false });

        const playerIndex = allData.findIndex(entry => entry.name === this.playerName && entry.score === this.score);
        this.playerBeyondTop10 = playerIndex >= 10;
        this.playerPosition = playerIndex + 1;
      } catch (error) {
        console.error('Error loading leaderboard', error);
      } finally {
        this.loading = false; // Set loading to false after data is fetched
      }
    },
    goBack() {
      this.$router.push(this.from); // Use the `from` prop for navigation
    }
  },
  mounted() {
    this.fetchLeaderboard();
  }
};
</script>

<style scoped>
.leaderboard-container {
  max-width: 120vh;
  margin: 0 auto;
  padding: 20px;
}

.list-group-item {
  border: none;
  border-bottom: 1px dashed #e5e5e5;
}

.list-group-item-warning {
  background-color: #fff3cd;
}

/* Add styles for loading text */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
}

.loading-text {
  font-size: 1.5em;
  animation: blinkingText 1.5s infinite;
}

@keyframes blinkingText {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
</style>
