<template>
    <div>
      <div class="rule-inputs">
        <input
          type="text"
          v-model="newRule.original"
          placeholder="Enter word/phrase to replace"
          class="form-control"
        />
        <input
          type="text"
          v-model="newRule.replacement"
          placeholder="Enter replacement word/phrase"
          class="form-control"
        />
        <button @click="addRule" class="btn btn-success">Add</button>
      </div>
      <table class="table mt-3">
        <thead>
          <tr>
            <th>Original</th>
            <th>Replacement</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="rule in rules" :key="rule.id">
            <td>{{ rule.original }}</td>
            <td>{{ rule.replacement }}</td>
            <td>
                <div class="btn-group btn-group-sm w-100" role="group" aria-label="Small button group">
                    <button type="button" @click="editRule(rule)" class="btn btn-secondary">Edit</button>
                    <button type="button" @click="deleteRule(rule.id)" class="btn btn-danger">Delete</button>
                </div>
            </td>
          </tr>
        </tbody>
      </table>
      <button @click="clearAllRules" class="btn btn-secondary mt-3 w-100">Clear All</button>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  interface Rule {
    id: number;
    original: string;
    replacement: string;
  }
  
  export default defineComponent({
    props: {
      rules: {
        type: Array as () => Rule[],
        required: true
      }
    },
    data() {
      return {
        newRule: {
          original: '',
          replacement: ''
        } as Rule,
        editMode: false,
        ruleToEdit: null as Rule | null
      };
    },
    methods: {
      addRule() {
        if (this.newRule.original && this.newRule.replacement) {
          if (this.editMode && this.ruleToEdit) {
            this.ruleToEdit.original = this.newRule.original;
            this.ruleToEdit.replacement = this.newRule.replacement;
            this.editMode = false;
            this.ruleToEdit = null;
          } else {
            const newRule: Rule = { ...this.newRule, id: Date.now() };
            this.$emit('addRule', newRule);
          }
          this.resetForm();
        }
      },
      editRule(rule: Rule) {
        this.editMode = true;
        this.ruleToEdit = rule;
        this.newRule.original = rule.original;
        this.newRule.replacement = rule.replacement;
      },
      deleteRule(ruleId: number) {
        this.$emit('deleteRule', ruleId);
      },
      clearAllRules() {
        this.$emit('clearAllRules');
      },
      resetForm() {
        this.newRule.original = '';
        this.newRule.replacement = '';
      }
    }
  });
  </script>
  
  <style scoped>
  .replacement-rule-container {
    margin-top: 20px;
  }
  .rule-inputs {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .rule-inputs input {
    flex: 1;
  }
  </style>
  