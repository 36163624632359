<template>
    <BreadCrumb PageTitle="Random Paragraph Generator" />
    <div class="custom-container mt-5 mb-50">
      <h1 class="text-center">Random Paragraph Generator</h1>
      <p class="fs-6 text-center mb-4">Explore our Random Paragraph Generator for diverse topics like Inspiration, Technology, Nature, and more. Perfect for sparking ideas and creativity!</p>
      <div class="row mt-4 mb-4">
        <div class="col-md-6">
          <div class="input-group mb-3">
            <span class="input-group-text" for="categorySelect">Category:</span>
            <select v-model="category" id="questionCategory" class="form-select">
              <option value="" disabled selected>Choose...</option>
              <option v-for="cat in categories" :key="cat" :value="cat">{{ cat }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group">
            <span class="input-group-text" for="numbers">No. of paragraphs:</span>
            <input v-model="numberOfParagraphs" id="numbers" class="form-control" type="number" max="5" min="1" />
          </div>
        </div>
      </div>
      <div class="col">
        <button @click="generateParagraphs" id="generateBtn" class="btn btn-primary mb-4 btn-hover w-100" data-bs-toggle="tooltip" title="Generate Paragraphs">
          Generate Paragraphs
        </button>
      </div>
      <div>
        <h4 class="text-center">Generated Paragraphs</h4>
        <div v-if="showError" id="error-alert" class="alert alert-warning mt-2" role="alert">
          <span>Please select a category and ensure the number of paragraphs is between 1 and 5.</span>
        </div>
        <div class="generatedParagraphs">
          <div v-for="(paragraph, index) in generatedParagraphs" :key="index" class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">{{ category }} - Paragraph {{ index + 1 }}</h5>
              <p class="card-text fs-6">{{ paragraph }}</p>
            </div>
          </div>
        </div>
      </div>
      
      <!-- FAQ Section -->
      <div class="mt-5">
        <RandomParagraphGeneratorFAQ />
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, computed, reactive, defineComponent } from 'vue';
  import randomParagraphData from '../../components/RandomParagraphData';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import RandomParagraphGeneratorFAQ from '../FAQs/randomParagraphGeneratorFAQ.vue';
  
  export default defineComponent({
    name: 'RandomParagraphGenerator',
    components: {
      BreadCrumb,
      RandomParagraphGeneratorFAQ
    },
    setup() {
      const data = reactive(randomParagraphData);
  
      const category = ref<string>('');
      const numberOfParagraphs = ref<number>(1);
      const generatedParagraphs = ref<string[]>([]);
      const showError = ref<boolean>(false);
  
      const generateParagraphs = () => {
        if (!category.value || numberOfParagraphs.value < 1 || numberOfParagraphs.value > 5) {
          showError.value = true;
          return;
        }
        
        showError.value = false;
        const selectedParagraphs = data.paragraphs[category.value];
        const result: string[] = [];
        
        for (let i = 0; i < numberOfParagraphs.value; i++) {
          const randomIndex = Math.floor(Math.random() * selectedParagraphs.length);
          result.push(selectedParagraphs[randomIndex]);
        }
        
        generatedParagraphs.value = result;
      };
  
      return {
        category,
        numberOfParagraphs,
        generatedParagraphs,
        showError,
        categories: computed(() => data.categories),
        generateParagraphs
      };
    }
  });
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  .btn-hover:hover {
    opacity: 0.9;
  }
  
  .generatedParagraphs {
    white-space: pre-wrap;
  }
  </style>