<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Bubble Text Generator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is Bubble Text Generator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Bubble Text Generator is a free online tool that transforms your standard text into bubble font style which you can copy and paste on social media, messages, or anywhere online.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the Bubble Text Generator?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Simply type your text into the input box, press the 'Generate Bubble' button, and your text will be converted into bubble font style in the display area below, ready to be copied.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>Is it free to use?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, the Bubble Text Generator is completely free to use for personal and commercial purposes. No sign-up or registration is required.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>Can I use the generated bubble text on social media?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, the generated bubble text can be used on social media platforms like Facebook, Twitter, Instagram, etc. Just click the 'Copy' button next to the generated text and paste it anywhere you like.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>Why doesn't the copy button work?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    If the copy button isn't working, make sure you are using the tool on a compatible browser and that you have allowed clipboard access when prompted.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Can I customize the bubble text color?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    While the Bubble Text Generator does not directly change text color, you can copy the text and apply color styles on the platform where you paste it.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>What should I do if I encounter an issue?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    If you run into any issues or have questions, please contact our support team. We're here to help you with any problems or feedback you might have.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'bubbleTextGeneratorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
