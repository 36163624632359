<template>
  <BreadCrumb PageTitle="LCM Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">LCM Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two or more integers to calculate their Least Common Multiple (LCM). The calculator will show the LCM along with the calculation steps and optionally the prime factorization of each number.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Integers -->
        <div class="mb-3">
          <label for="integers" class="form-label">Enter Integers (comma separated):</label>
          <input
            type="text"
            id="integers"
            v-model="integersInput"
            class="form-control"
            placeholder="e.g., 12, 15, 20"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateLCM" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Integers: {{ result.integers }}</p>
          <p class="fs-5">LCM: {{ result.lcm }}</p>
          <p class="fs-5">Calculation Steps: {{ result.steps }}</p>
          <p class="fs-5" v-if="result.primeFactors">
            Prime Factorization of Each Number:
            <ul>
              <li v-for="(factors, number) in result.primeFactors" :key="number">
                {{ number }}: {{ factors.join(" × ") }}
              </li>
            </ul>
          </p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter two or more integers separated by commas (e.g., 12, 15, 20).</li>
        <li>Click "Calculate" to compute the LCM of the entered integers.</li>
        <li>The result will show the entered integers, the LCM, and the calculation steps.</li>
        <li>Click "Clear" to reset the input field and result.</li>
      </ul>

      <h5 class="alert-heading">What is LCM?</h5>
      <p class="fs-6">
        The Least Common Multiple (LCM) of two or more integers is the smallest positive integer that is divisible by all the given numbers without leaving a remainder.
      </p>

      <h5 class="alert-heading">Formula for LCM using GCD:</h5>
      <div class="fs-6">
        For two integers \( a \) and \( b \), the LCM is calculated as:
      </div>
      <div>
        \[
        \text{LCM}(a, b) = \frac{|a \times b|}{\text{GCD}(a, b)}
        \]
      </div>
      <p class="fs-6">
        The formula can be extended to more than two integers by iteratively applying the LCM formula.
      </p>

      <h5 class="alert-heading">Formula for LCM using Prime Factorization:</h5>
      <div class="fs-6">
        The LCM of two or more numbers is the product of the highest power of all prime factors.
      </div>
      <div>
        <p class="fs-6">
          For example, the LCM of 12 and 15 is calculated as follows:
        </p>
        <ul class="fs-6">
          <li>12 = 2 × 2 × 3</li>
          <li>15 = 3 × 5</li>
          <li>Prime factors: 2, 3, 5</li>
          <li>Highest power of 2 = 2 × 2 = 4</li>
          <li>Highest power of 3 = 3</li>
          <li>Highest power of 5 = 5</li>
          <li>LCM = 4 × 3 × 5 = 60</li>
        </ul>
      </div>

      <h5 class="alert-heading">Example Calculation:</h5>
      <ul class="fs-6">
        <li>
          For \( \text{LCM}(12, 15) \):
          <ul>
            <li>Using GCD formula: LCM = |12 × 15| / GCD(12, 15) = 180 / 3 = 60</li>
          </ul>
        </li>
        <li>
          For \( \text{LCM}(12, 15, 20) \):
          <ul>
            <li>Using GCD formula iteratively: LCM(12, 15) = 60</li>
            <li>LCM(60, 20) = 60</li>
            <li>LCM = 60</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const integersInput = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate LCM of multiple integers
    const calculateLCM = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the integers from the input
        const integers = parseIntegers(integersInput.value);

        if (integers.length < 2) throw new Error("Please enter at least two integers.");

        // Calculate LCM of the integers
        const { lcm, steps, primeFactors } = lcmCalculation(integers);

        result.value = {
          integers: integers.join(", "),
          lcm: lcm,
          steps: steps,
          primeFactors: primeFactors,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to parse integers from a comma-separated string
    const parseIntegers = (input) => {
      return input
        .split(",")
        .map((num) => parseInt(num.trim()))
        .filter((num) => !isNaN(num));
    };

    // Function to calculate LCM using the GCD and prime factorization
    const lcmCalculation = (nums) => {
      let steps = "";
      let lcm = nums[0];
      let primeFactors = {};

      steps += `LCM(${nums.join(", ")})\n`;

      for (let i = 1; i < nums.length; i++) {
        lcm = findLCM(lcm, nums[i], steps);
      }

      nums.forEach((num) => {
        primeFactors[num] = primeFactorize(num);
      });

      return { lcm, steps: steps.trim(), primeFactors };
    };

    // Function to find LCM of two numbers using the GCD formula
    const findLCM = (a, b, steps) => {
      const gcdValue = findGCD(a, b);
      const lcm = Math.abs(a * b) / gcdValue;
      steps += `LCM(${a}, ${b}) = |${a} × ${b}| / GCD(${a}, ${b}) = ${lcm}\n`;
      return lcm;
    };

    // Function to find GCD of two numbers using the Euclidean algorithm
    const findGCD = (a, b) => {
      while (b !== 0) {
        let remainder = a % b;
        a = b;
        b = remainder;
      }
      return a;
    };

    // Function to perform prime factorization of a number
    const primeFactorize = (num) => {
      const factors = [];
      let divisor = 2;

      while (num >= 2) {
        if (num % divisor === 0) {
          factors.push(divisor);
          num = num / divisor;
        } else {
          divisor++;
        }
      }

      return factors;
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      integersInput.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      integersInput,
      result,
      error,
      calculateLCM,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
