<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Absolute Value Calculator</h3>
                    </div>
                    <!-- FAQ 1 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the Absolute Value Calculator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Absolute Value Calculator is a tool that calculates the absolute value of any number. The absolute value of a number is the distance between that number and 0 on a number line, without considering the direction. It is always a non-negative value.
                                </p>
                                <!-- Diagram: Number line showing absolute value -->
                                <div class="text-center my-3">
                                    <img src="images/avc_faq_1.jpg" alt="Absolute Value Diagram" class="img-fluid">
                                    <p class="text-muted fs-md-14">Figure: Absolute value of a number on a number line.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 2 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the Absolute Value Calculator?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To use the Absolute Value Calculator:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Enter a number in the input field.</li>
                                    <li>Click the <strong>Calculate</strong> button.</li>
                                    <li>The result will display the absolute value of the entered number.</li>
                                    <li>You can reset the input by clicking the <strong>Clear</strong> button.</li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The calculator works for both positive and negative numbers, as well as for decimal values.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 3 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What is absolute value?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Absolute value refers to the magnitude of a number without considering its sign. In mathematical terms, the absolute value of a number "x" is denoted as |x|, and is always non-negative. For example:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>|5| = 5</li>
                                    <li>|-5| = 5</li>
                                    <li>|0| = 0</li>
                                </ul>
                                <!-- Diagram: Visualizing absolute value -->
                                <div class="text-center my-3">
                                    <img src="images/avc_faq_2.jpg" alt="Absolute Value Explanation" class="img-fluid">
                                    <p class="text-muted fs-md-14">Figure: The absolute value of a number is its distance from zero, without direction.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 4 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>Does the Absolute Value Calculator work for negative numbers?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, the calculator works for both negative and positive numbers. The absolute value of a negative number is simply the positive version of that number. For example, the absolute value of -8 is 8.
                                </p>
                                <div class="text-center my-3">
                                    <img src="images/avc_faq_3.jpg" alt="Negative Number Absolute Value" class="img-fluid">
                                    <p class="text-muted fs-md-14">Figure: Absolute value of -8 is 8.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 5 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>Can the calculator handle decimal numbers?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, the Absolute Value Calculator can handle decimal numbers. You can enter decimal values, and the calculator will return the absolute value of the decimal number. For example:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>|3.5| = 3.5</li>
                                    <li>|-7.25| = 7.25</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 6 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>What should I do if I get an unexpected result?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    If you get an unexpected result, double-check the input value to ensure it was entered correctly. Also, make sure that the number you entered is a valid numerical value. The calculator only processes numerical input and returns the absolute value based on the number you provide.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Additional FAQs as needed -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AbsoluteValueCalculatorFAQ',
};
</script>

<style scoped>
  /* Add any specific styles for FAQ layout or images */
</style>
