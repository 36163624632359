<template>
  <BreadCrumb PageTitle="Pixel Circle Generator" />
  <div class="custom-generator mt-5 mb-50">
    <h1 class="text-center">Pixel Circle Generator</h1>
    <p class="fs-5 text-center">Create precise pixelated circles with customizable styles and grid options. Perfect for pixel art and design projects.</p>
    <div class="row mt-50">
      <div class="d-flex justify-content-between">
        <label class="form-label" for="width">Grid Size:</label>
        <label class="form-label" for="style">Style:</label>
        <label class="form-label" for="bCount">Block Count:</label>
      </div>
      <div class="input-group d-flex justify-content-between">
        <input v-model="gridSize" class="form-control" type="number" max="200" min="3" @input="drawGrid" />
        <select v-model="selectedStyle" class="form-select" @change="drawGrid">
          <option value="thin">Thin</option>
          <option value="fill">Fill</option>
        </select>
        <input v-model="filledBlockCount" class="form-control text-center" type="number" readonly />
      </div>
    </div>
    <div class="toggleSelect">
      <div class="d-flex justify-content-between">
        <label class="form-label" for="colorPicker">Pixel Color:</label>
        <label class="form-label" for="toggleGrid">Toggle Grid:</label>
        <label class="form-label" for="toggleCircle">Toggle Outline:</label>
      </div>
      <div class="d-flex justify-content-between">
        <input v-model="pixelColor" class="square-color-picker" type="color" @input="drawGrid" />
        <div class="form-check form-switch">
          <input v-model="showGrid" class="form-check-input" type="checkbox" role="switch" @change="drawGrid" />
        </div>
        <div class="form-check form-switch">
          <input v-model="showCircle" class="form-check-input" type="checkbox" role="switch" @change="drawGrid" />
        </div>
      </div>
    </div>
    <div class="scrollable-container mb-3">
      <div id="grid-container" ref="gridContainer"></div>
    </div>
    <div class="row download-btn">
      <div class="col-md-4 mb-2">
        <button @click="takeSnapshot('png')" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Download as PNG">
          <i class="bi bi-download"></i> PNG
        </button>
      </div>
      <div class="col-md-4 mb-2">
        <button @click="takeSnapshot('jpeg')" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Download as JPG">
          <i class="bi bi-download"></i> JPG
        </button>
      </div>
      <div class="col-md-4 mb-2">
        <button @click="takeSnapshot('svg')" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Download as SVG">
          <i class="bi bi-download"></i> SVG
        </button>
      </div>
      <div>
        <PixelCircleGeneratorFAQ />
      </div>
    </div>
</div>

</template>

  
<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import PixelCircleGeneratorFAQ from '../FAQs/pixelCircleGeneratorFAQ.vue';

export default defineComponent({
  components: {
    BreadCrumb,
    PixelCircleGeneratorFAQ
  },
  setup() {
    const gridSize = ref(10);
    const pixelColor = ref("#ea868f");
    const showGrid = ref(true);
    const showCircle = ref(false);
    const selectedStyle = ref("thin");
    const filledBlockCount = ref(0);
    const gridContainer = ref<HTMLDivElement | null>(null);

    const hexToRgb = (hex: string) => {
      hex = hex.replace(/^#/, '');
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b, a: 255 };
    };

    const drawPixel = (x: number, y: number, img: ImageData, rgba: { r: number; g: number; b: number; a: number }, filledBlockCountRef: { value: number }) => {
      const dix = (y * gridSize.value + x) * 4;
      img.data[dix] = rgba.r;
      img.data[dix + 1] = rgba.g;
      img.data[dix + 2] = rgba.b;
      img.data[dix + 3] = 255; // Set alpha to 255 (fully opaque)
      if (rgba.a !== 0) {
        filledBlockCountRef.value++;
      }
    };

    const drawCircle = (gridSize: number, cPix1: CanvasRenderingContext2D, rgba: { r: number; g: number; b: number; a: number }, selectedStyle: string, filledBlockCountRef: { value: number }) => {
      filledBlockCountRef.value = 0;
      const r = (gridSize - 1) / 2;
      const center = gridSize / 2;

      function nValue(x: number, y: number, r: number) {
        return x * x + y * y - r * r;
      }

      let x = -r;
      let y = r % 1 === 0 ? 0 : 0.5;
      let img = cPix1.createImageData(gridSize, gridSize);

      if (selectedStyle === 'thin') {
        do {
          const cx = r;
          const cy = r;
          drawPixel(cx + x, cy + y, img, rgba, filledBlockCountRef); // quadrant 3
          drawPixel(cx - x, cy - y, img, rgba, filledBlockCountRef); // quadrant 1
          drawPixel(cx - y, cy + x, img, rgba, filledBlockCountRef); // quadrant 2
          drawPixel(cx + y, cy - x, img, rgba, filledBlockCountRef); // quadrant 4
          const e = nValue(x, y, r);
          const ex = nValue(x + 1, y, r);
          const ey = nValue(x, y + 1, r);
          const exy = nValue(x + 1, y + 1, r);
          if (Math.abs(exy) < Math.abs(ex) && Math.abs(exy) < Math.abs(ey)) x++, y++;
          else if (Math.abs(ex) < Math.abs(exy) && Math.abs(ex) < Math.abs(ey)) x++;
          else if (Math.abs(ey) < Math.abs(exy) && Math.abs(ey) < Math.abs(ex)) {
            y++;
            if (Math.abs(nValue(x + 1, y + 1, r)) > Math.abs(nValue(x + 1, y, r)) && Math.abs(nValue(x + 1, y, r)) < Math.abs(nValue(x, y + 1, r))) x++;
          }
        } while (x < 0);
      } else if (selectedStyle === 'fill') {
        do {
          const cx = r;
          const cy = r;
          drawPixel(cx + x, cy + y, img, rgba, filledBlockCountRef); // quadrant 3
          drawPixel(cx - x, cy - y, img, rgba, filledBlockCountRef); // quadrant 1
          drawPixel(cx - y, cy + x, img, rgba, filledBlockCountRef); // quadrant 2
          drawPixel(cx + y, cy - x, img, rgba, filledBlockCountRef); // quadrant 4
          const e = nValue(x, y, r);
          const ex = nValue(x + 1, y, r);
          const ey = nValue(x, y + 1, r);
          const exy = nValue(x + 1, y + 1, r);
          if (Math.abs(exy) < Math.abs(ex) && Math.abs(exy) < Math.abs(ey)) x++, y++;
          else if (Math.abs(ex) < Math.abs(exy) && Math.abs(ex) < Math.abs(ey)) x++;
          else if (Math.abs(ey) < Math.abs(exy) && Math.abs(ey) < Math.abs(ex)) {
            y++;
            if (Math.abs(nValue(x + 1, y + 1, r)) > Math.abs(nValue(x + 1, y, r)) && Math.abs(nValue(x + 1, y, r)) < Math.abs(nValue(x, y + 1, r))) x++;
          }
        } while (x < 0);

        // Fill only empty blocks within the circular region in the quadrant
        for (let xi = 0; xi < gridSize; xi++) {
          for (let yi = 0; yi < gridSize; yi++) {
            const blockCenterX = xi + 0.5;
            const blockCenterY = yi + 0.5;
            const dx = blockCenterX - center;
            const dy = blockCenterY - center;
            if (img.data[(yi * gridSize + xi) * 4 + 3] === 0 && (dx * dx + dy * dy) <= (r * r)) {
              drawPixel(xi, yi, img, rgba, filledBlockCountRef);
            }
          }
        }
      }

      cPix1.putImageData(img, 0, 0);
    };

    const createCanvas = (gridSize: number, selectedStyle: string) => {
      const containerSize = Math.min(500, window.innerWidth - 40); // Adjust 40 as needed for padding
      const cPix1 = document.createElement('canvas').getContext('2d')!;
      const cPix2 = document.createElement('canvas').getContext('2d')!;
      cPix1.canvas.width = cPix1.canvas.height = gridSize;
      cPix2.canvas.width = cPix2.canvas.height = containerSize;
      const div = document.createElement('div');
      div.append(cPix1.canvas, cPix2.canvas);
      div.style.width = div.style.height = `${containerSize}px`;
      cPix1.canvas.style.width = cPix1.canvas.style.height = '100%';
      cPix2.canvas.style.width = cPix2.canvas.style.height = '100%';
      cPix1.canvas.style.position = cPix2.canvas.style.position = "absolute";
      cPix1.canvas.style.imageRendering = cPix2.canvas.style.imageRendering = "pixelated";
      const d = gridSize - 1;
      const r = d / 2;
      const center = gridSize / 2;
      const scale = containerSize / gridSize;
      drawCircle(gridSize, cPix1, hexToRgb(pixelColor.value), selectedStyle, filledBlockCount);

      if (showGrid.value) {
        cPix2.beginPath();
        const step = containerSize / gridSize;
        for (let i = 0; i <= gridSize; i++) {
          const g = i * step;
          cPix2.moveTo(g, 0);
          cPix2.lineTo(g, containerSize);
          cPix2.moveTo(0, g);
          cPix2.lineTo(containerSize, g);
        }
        cPix2.strokeStyle = "#45445e";
        cPix2.stroke();
      }

      // Use the following method to preserve pixelation
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = tempCanvas.height = gridSize;
      const tempContext = tempCanvas.getContext('2d')!;
      tempContext.drawImage(cPix1.canvas, 0, 0);
      cPix2.imageSmoothingEnabled = false;
      cPix2.drawImage(tempCanvas, 0, 0, gridSize, gridSize, 0, 0, containerSize, containerSize);

      if (showCircle.value) {
        cPix2.beginPath();
        cPix2.arc(center * scale, center * scale, r * scale, 0, 2 * Math.PI);
        cPix2.stroke();
      }

      return div;
    };

    const drawGrid = () => {
      if (!gridContainer.value) return;
      gridContainer.value.innerHTML = '';
      const canvas = createCanvas(gridSize.value, selectedStyle.value);
      gridContainer.value.appendChild(canvas);
    };

    const takeSnapshot = (format: string) => {
      if (!gridContainer.value) return;
      const canvas = gridContainer.value.querySelector("canvas")!;
      const snapshotWidth = canvas.width;
      const snapshotHeight = canvas.height;
      const snapshotCanvas = document.createElement("canvas");
      snapshotCanvas.width = snapshotWidth;
      snapshotCanvas.height = snapshotHeight;
      const ctx = snapshotCanvas.getContext("2d")!;
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(canvas, 0, 0, snapshotWidth, snapshotHeight);

      if (showGrid.value) {
        ctx.strokeStyle = "#cccccc50";
        ctx.lineWidth = 1;
        const step = snapshotWidth / gridSize.value;
        for (let x = 0; x <= snapshotWidth; x += step) {
          ctx.moveTo(x, 0);
          ctx.lineTo(x, snapshotHeight);
        }
        for (let y = 0; y <= snapshotHeight; y += step) {
          ctx.moveTo(0, y);
          ctx.lineTo(snapshotWidth, y);
        }
        ctx.stroke();
      }

      const imageDataURL = snapshotCanvas.toDataURL(`image/${format}`);
      const fileName = `pixelroyals-${gridSize.value}x${gridSize.value}-downloaded.${format}`;
      const tempLink = document.createElement("a");

      if (format === "svg") {
        const svgXml = `
          <svg xmlns="http://www.w3.org/2000/svg" width="${snapshotWidth}" height="${snapshotHeight}" xmlns:xlink="http://www.w3.org/1999/xlink">
              <image x="0" y="0" width="${snapshotWidth}" height="${snapshotHeight}" xlink:href="${imageDataURL}" />
          </svg>
        `;
        const svgBlob = new Blob([svgXml], { type: "image/svg+xml" });
        tempLink.href = URL.createObjectURL(svgBlob);
      } else {
        tempLink.href = imageDataURL;
      }
      tempLink.download = fileName;
      tempLink.click();
    };

    const handleResize = () => {
      drawGrid();
    };

    onMounted(() => {
      drawGrid();
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      gridSize,
      pixelColor,
      showGrid,
      showCircle,
      selectedStyle,
      filledBlockCount,
      gridContainer,
      drawGrid,
      takeSnapshot,
    };
  },
});
</script>
  
 <style scoped>
  .custom-generator {
    max-width: 700px;
    margin: 0 auto;
  }
  .scrollable-container {
      overflow: auto;
      max-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 1rem;
  }

  #grid-container {
      position: relative;
      width: 100%;
      max-width: 500px;
      height: auto;
      aspect-ratio: 1 / 1;
  }
      .square-color-picker {
          width: 28px;
          height: 20px;
          padding: 0;
          border: none;
      }

      canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
  }

  @media (max-width: 768px) {
      .scrollable-container {
          padding: 0.5rem;
      }
  }

    .download-btn {
        margin-top: 3rem !important;
    }

    .toggleSelect {
        margin-bottom: 3rem !important;
    }

    .form-check-input[type=checkbox] {
    border-radius: 2em !important;
    }
 </style>
  