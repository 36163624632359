// src/utils/content.js
import { ref } from 'vue';
import frontMatter from 'front-matter';

export const useContent = () => {
  const posts = ref([]);

  const fetchPosts = async () => {
    const context = require.context('../content/blog', false, /\.md$/);
    const posts = await Promise.all(
      context.keys().map(async (key) => {
        const post = await import(`../content/blog/${key.slice(2)}`);
        const { attributes, body } = frontMatter(post.default);
        return {
          ...attributes,
          slug: key.slice(2, -3),
          content: body
        };
      })
    );
    return posts.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const fetchPost = async (slug) => {
    const post = await import(`../content/blog/${slug}.md`);
    const { attributes, body } = frontMatter(post.default);
    return {
      ...attributes,
      slug,
      content: body
    };
  };

  return {
    posts,
    fetchPosts,
    fetchPost
  };
};