<template>
  <BreadCrumb PageTitle="List All Adjectives In Sentence Calculator" />

  <div class="container mt-5 mb-50" style="max-width: 800px;">
    <h1 class="text-center">List All Adjectives In A Sentence</h1>
    <p class="text-center text-muted fs-5 mb-2">Analyze Your Text and Discover Its Adjectival Power</p>
    <p class="fs-6 text-center text-muted mb-4">
      Highlight Adjectives, calculate density, and uncover linguistic patterns in your writing. Enhance your prose with data-driven insights.
    </p>
    
    <div class="mb-3">
      <label for="sentenceInput" class="form-label fs-6">Enter a sentence:</label>
      <textarea
        v-model="sentence"
        class="form-control fs-6"
        id="sentenceInput"
        rows="6"
        placeholder="Type or paste your text here"
      ></textarea>
    </div>
    
    <button @click="analyzeSentence" class="btn btn-primary mb-3 w-100">Analyze</button>
    
    <div v-if="analyzed">
      <div v-if="highlightedSentence" class="mb-4">
        <h2>Highlighted Sentence:</h2>
        <p class="fs-6" v-html="highlightedSentence"></p>
      </div>
      
      <div v-if="report" class="mb-4">
        <h2>Report:</h2>
        <div class="card">
          <div class="card-body fs-5">
            <p><strong>Total words:</strong> {{ report.totalWords }}</p>
            <p><strong>Total Adjectives:</strong> {{ report.totalAdjectives }}</p>
            <p><strong>Adjective density:</strong> {{ report.adjectiveDensity.toFixed(2) }}%</p>
            <p><strong>Most common Adjective:</strong> {{ report.mostCommonAdjective || 'N/A' }}</p>
            <p><strong>Unique Adjectives:</strong> {{ report.uniqueAdjectives.join(', ') || 'N/A' }}</p>
          </div>
        </div>
      </div>
      
      <div v-if="adjectiveCounts.length > 0" class="mb-4">
        <h2>Adjectives found:</h2>
        <div class="row row-cols-2 row-cols-md-4 g-2">
          <div v-for="(adjective, index) in adjectiveCounts" :key="index" class="col">
            <div class="card">
              <div class="card-body p-2 fs-5">
                <a :href="'https://www.merriam-webster.com/dictionary/' + adjective.word" 
                   target="_blank" 
                   rel="noopener noreferrer"
                   class="text-decoration-none text-primary">
                  {{ adjective.word }}
                </a>
                {{ adjective.count > 1 ? ` (x${adjective.count})` : '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="mb-4">
      <h2>History:</h2>
      <div class="card mb-2" v-for="(item, index) in history" :key="index">
        <div class="card-body d-flex justify-content-between align-items-center">
          <span>{{ item }}</span>
          <i
            :class="['bi', copied[index] ? 'bi-clipboard-check' : 'bi-clipboard', 'cursor-pointer']"
            @click="copyToClipboard(item, index)"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

import { ref, computed, onMounted } from 'vue';
import allAdjectives from '../../components/JSON/allAdjectives.json';

export default {
 
  components: { BreadCrumb },
 name: 'App',
  setup() {
    const sentence = ref('');
    const adjectives = ref([]);
    const report = ref(null);
    const history = ref([]);
    const copied = ref({});
    const analyzed = ref(false);

    const adjectiveSet = new Set(allAdjectives.map(adjective => adjective.toLowerCase()));

    const analyzeSentence = () => {
      if (!sentence.value) return;
      
      const words = sentence.value.match(/\b(\w+)\b/g) || [];
      adjectives.value = words.filter(word => adjectiveSet.has(word.toLowerCase()));
      
      const adjectiveCounts = adjectives.value.reduce((acc, adjective) => {
        acc[adjective.toLowerCase()] = (acc[adjective.toLowerCase()] || 0) + 1;
        return acc;
      }, {});
      
      report.value = {
        totalWords: words.length,
        totalAdjectives: adjectives.value.length,
        adjectiveDensity: (adjectives.value.length / words.length) * 100,
        mostCommonAdjective: Object.entries(adjectiveCounts).sort((a, b) => b[1] - a[1])[0]?.[0],
        uniqueAdjectives: [...new Set(adjectives.value.map(v => v.toLowerCase()))]
      };
      
      history.value.unshift(sentence.value);
      if (history.value.length > 2) history.value.pop();
      
      analyzed.value = true;
    };

    const highlightedSentence = computed(() => {
      if (!sentence.value) return '';
      return sentence.value.replace(/\b(\w+)\b/g, (match) => {
        return adjectiveSet.has(match.toLowerCase())
          ? `<span style="background-color: #ff4400;"><a href="https://www.merriam-webster.com/dictionary/${match}" target="_blank" rel="noopener noreferrer" class="text-dark">${match}</a></span>`
          : match;
      });
    });

    const adjectiveCounts = computed(() => {
      const counts = {};
      adjectives.value.forEach(adjective => {
        const lowerAdjective = adjective.toLowerCase();
        counts[lowerAdjective] = (counts[lowerAdjective] || 0) + 1;
      });
      return Object.entries(counts).map(([word, count]) => ({ word, count }));
    });

    const copyToClipboard = (text, index) => {
      navigator.clipboard.writeText(text).then(() => {
        copied.value[index] = true;
        setTimeout(() => {
          copied.value[index] = false;
        }, 2000);
      });
    };

    onMounted(() => {
      // You might want to load allAdjectives.json here if it's not available at build time
    });

    return {
      sentence,
      report,
      history,
      copied,
      analyzed,
      analyzeSentence,
      highlightedSentence,
      adjectiveCounts,
      copyToClipboard
    };
  }
}
</script>

<style>
@import 'bootstrap-icons/font/bootstrap-icons.css';

.cursor-pointer {
  cursor: pointer;
}
.form-control {
  line-height: 1.3;
}
</style>