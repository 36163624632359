<template>
    <BreadCrumb PageTitle="HTML To CSV Converter" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">HTML To CSV Converter</h1>
      <p class="fs-6 text-center mb-4">Convert your HTML table into a CSV format instantly. You can beautify HTML, use live preview, and copy the output CSV effortlessly.</p>
      <div class="row mb-4">
        <div class="col-md-6 mb-4 mb-md-0">
          <div class="markdown mb-2">
            <label for="inputHTML">Input (HTML Table Data):</label>
            <div class="ms-3 form-check">
              <input type="checkbox" class="form-check-input" id="beautifyHTMLCheckbox" v-model="beautifyHTML" @change="handleInput">
              <label for="beautifyHTMLCheckbox" class="form-check-label">Beautify HTML</label>
            </div>
          </div>
          <textarea id="inputHTML" class="form-control" rows="25" v-model="inputHTML" @input="handleInput"></textarea>
        </div>
        <div class="col-md-6">
          <div class="markdown mb-2 d-flex justify-content-between align-items-center">
            <label>{{ livePreview ? 'Live Preview' : 'Output (CSV):' }}</label>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="livePreviewCheckbox" v-model="livePreview">
              <label class="form-check-label" for="livePreviewCheckbox">Live Preview</label>
            </div>
          </div>
          <div class="position-relative output-wrapper">
            <textarea v-if="!livePreview" id="outputCSV" class="form-control" rows="25" readonly :value="outputCSV"></textarea>
            <pre v-else id="csvPreview" class="form-control csv-preview">{{ outputCSV }}</pre>
            <i 
              :class="['bi', copied ? 'bi-clipboard-check' : 'bi-clipboard', 'copy-icon']" 
              @click="copyToClipboard"
              :title="copied ? 'Copied!' : 'Copy to clipboard'"
            ></i>
          </div>
        </div>
      </div>
  
      <div class="alert alert-danger mt-3" v-if="error">Error converting HTML to CSV!</div>
      <div>
        <HtmlToCSVFAQ />
      </div>
    </div>
  </template>
  
  <script>
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import HtmlToCSVFAQ from '../FAQs/htmlToCSVFAQ.vue';
  import { html as beautifyHTML } from 'js-beautify';
  
  export default {
    components: {
      BreadCrumb,
      HtmlToCSVFAQ
    },
    data() {
      return {
        inputHTML: '',
        beautifyHTML: false,
        livePreview: false,
        outputCSV: '',
        error: false,
        copied: false,
      };
    },
    methods: {
    handleInput() {
        if (this.beautifyHTML) {
        this.inputHTML = beautifyHTML(this.inputHTML, { indent_size: 2, space_in_empty_paren: true });
        }
        this.convertToCSV();
    },
    convertToCSV() {
        try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.inputHTML, 'text/html');
        const table = doc.querySelector('table');

        if (table) {
            this.outputCSV = this.htmlTableToCSV(table);
        } else {
            // If no table is found, show empty array in CSV format
            this.outputCSV = '[]';
        }
        this.error = false;
        } catch (e) {
        // In case of any parsing errors, default to empty array
        this.outputCSV = '[]';
        this.error = false; // No error message, just empty output
        }
    },
    htmlTableToCSV(table) {
        let csv = [];
        const rows = table.querySelectorAll('tr');

        rows.forEach(row => {
        let cols = Array.from(row.querySelectorAll('td, th')).map(col => col.innerText);
        csv.push(cols.join(','));
        });

        return csv.join('\n');
    },
    copyToClipboard() {
        navigator.clipboard.writeText(this.outputCSV).then(() => {
        this.copied = true;
        setTimeout(() => {
            this.copied = false;
        }, 2000);
        });
    }
    }
  };
  </script>
  
  <style scoped>
  .markdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .custom-container {
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .custom-container {
      padding: 0 0.5rem;
    }
  }
  label, .form-control {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    .row {
      flex-direction: column;
    }
  }
  .output-wrapper {
    position: relative;
  }
  .copy-icon {
    position: absolute;
    bottom: 10px;
    right: 25px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #6c757d;
    z-index: 1;
  }
  .copy-icon:hover {
    color: #495057;
  }
  .csv-preview {
    height: calc(18 * 1.5em + 0.20rem);
    overflow-y: auto;
    padding-right: 40px;
  }
  textarea {
    resize: vertical;
    padding-right: 40px;
  }
  </style>
  