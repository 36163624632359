<template>
    <div class="text-center mb-20">
      <h2>Your Secret Santa Pair 🤶</h2>
      <img src="/secret-santa.gif" alt="Secret Santa" class="img-fluid my-3">
      <p class="fs-3">Hi, {{ giver }}!</p>
      <p class="fs-5">You are Secret Santa for: {{ receiver }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        giver: '',
        receiver: ''
      };
    },
    created() {
      const pairId = this.$route.params.id;
      const [giver, receiver] = decodeURIComponent(pairId).split('-').slice(0, 2);
      this.giver = this.capitalize(giver);
      this.receiver = this.capitalize(receiver);
    },
    methods: {
      capitalize(name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
      }
    }
  };
  </script>
  
  <style scoped>
    .img-fluid {
      width: 40vh;
    }
    .my-3 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  </style>
  