<template>
  <BreadCrumb PageTitle="Exponential Growth Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Exponential Growth Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the exponential growth of an investment or population over time. Enter the initial value, growth rate, time period, and compounding frequency.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Initial Value -->
        <div class="mb-3">
          <label for="initialValue" class="form-label">Initial Value (P0):</label>
          <input
            type="text"
            id="initialValue"
            v-model="initialValue"
            class="form-control"
            placeholder="e.g., 1000"
          />
        </div>

        <!-- Input for Growth Rate -->
        <div class="mb-3">
          <label for="growthRate" class="form-label">Growth Rate (r) - in percentage:</label>
          <input
            type="text"
            id="growthRate"
            v-model="growthRate"
            class="form-control"
            placeholder="e.g., 5 for 5%"
          />
        </div>

        <!-- Input for Time Period -->
        <div class="mb-3">
          <label for="timePeriod" class="form-label">Time Period (t) - in years:</label>
          <input
            type="text"
            id="timePeriod"
            v-model="timePeriod"
            class="form-control"
            placeholder="e.g., 10"
          />
        </div>

        <!-- Input for Compounding Frequency -->
        <div class="mb-3">
          <label for="compoundingFrequency" class="form-label">Compounding Frequency (n) - per year:</label>
          <select id="compoundingFrequency" v-model="compoundingFrequency" class="form-select">
            <option value="1">Annually (1)</option>
            <option value="2">Semi-annually (2)</option>
            <option value="4">Quarterly (4)</option>
            <option value="12">Monthly (12)</option>
            <option value="365">Daily (365)</option>
            <option value="0">Continuous</option>
          </select>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateGrowth" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Initial Value: {{ result.initialValue }}</p>
          <p class="fs-5">Growth Rate: {{ result.growthRate }}%</p>
          <p class="fs-5">Time Period: {{ result.timePeriod }} years</p>
          <p class="fs-5">Compounding Frequency: {{ result.compoundingText }}</p>
          <p class="fs-5">Final Amount: {{ result.finalAmount }}</p>
          <p class="fs-5">Total Growth: {{ result.totalGrowth }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the initial value (P0) representing the starting amount or population.</li>
        <li>Enter the growth rate (r) as a percentage (e.g., 5 for 5%).</li>
        <li>Enter the time period (t) in years for which the growth is to be calculated.</li>
        <li>Select the compounding frequency (n) per year. Choose "Continuous" for continuous growth calculation.</li>
        <li>Click "Calculate" to see the final amount and total growth over the specified time period.</li>
        <li>The results will show the initial value, growth rate, time period, compounding frequency, final amount, and total growth.</li>
        <li>Click "Clear" to reset the input fields and results.</li>
      </ul>

      <h5 class="alert-heading">Formula for Exponential Growth:</h5>
      <div class="fs-6">
        The exponential growth formula depends on the compounding frequency:
      </div>
      <ul class="fs-6">
        <li><strong>Compounded n Times per Year:</strong></li>
        <div>
          \[
          A = P_0 \left(1 + \frac{r}{n}\right)^{nt}
          \]
        </div>
        <li><strong>Continuous Growth:</strong></li>
        <div>
          \[
          A = P_0 e^{rt}
          \]
        </div>
      </ul>
      <p class="fs-6">
        where:
        <ul>
          <li>\(A\) = final amount</li>
          <li>\(P_0\) = initial value</li>
          <li>\(r\) = growth rate (in decimal form, e.g., 5% = 0.05)</li>
          <li>\(n\) = number of compounding periods per year</li>
          <li>\(t\) = time in years</li>
          <li>\(e\) = Euler's number, approximately 2.71828</li>
        </ul>
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <p class="fs-6">
        If you start with an initial value of 1000, at a growth rate of 5% per year, over 10 years, with monthly compounding:
      </p>
      <ul class="fs-6">
        <li>
          The final amount will be \(A = 1000 \left(1 + \frac{0.05}{12}\right)^{12 \times 10} = 1647.01\)
        </li>
        <li>
          Total growth: \( 647.01\) or \( 64.7\% \)
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const initialValue = ref("");
    const growthRate = ref("");
    const timePeriod = ref("");
    const compoundingFrequency = ref("1"); // Default to Annually
    const result = ref(null);
    const error = ref("");

    // Function to calculate exponential growth
    const calculateGrowth = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the input values
        const initial = parseFloat(initialValue.value.trim());
        const rate = parseFloat(growthRate.value.trim()) / 100; // Convert to decimal
        const time = parseFloat(timePeriod.value.trim());
        const n = parseInt(compoundingFrequency.value.trim());

        if (isNaN(initial) || isNaN(rate) || isNaN(time)) {
          throw new Error("Please enter valid numeric values.");
        }
        if (initial <= 0 || rate < 0 || time < 0) {
          throw new Error("Initial value, growth rate, and time must be positive numbers.");
        }

        let finalAmount = 0;
        let compoundingText = "";

        // Calculate final amount based on compounding frequency
        if (n === 0) {
          // Continuous compounding
          finalAmount = initial * Math.exp(rate * time);
          compoundingText = "Continuous";
        } else {
          // Regular compounding
          finalAmount = initial * Math.pow(1 + rate / n, n * time);
          compoundingText = `${n} times per year`;
        }

        // Calculate total growth
        const totalGrowth = (finalAmount - initial).toFixed(2);
        const growthPercentage = ((totalGrowth / initial) * 100).toFixed(2);

        result.value = {
          initialValue: initial.toFixed(2),
          growthRate: (rate * 100).toFixed(2),
          timePeriod: time.toFixed(2),
          compoundingText: compoundingText,
          finalAmount: finalAmount.toFixed(2),
          totalGrowth: `${totalGrowth} (${growthPercentage}%)`,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      initialValue.value = "";
      growthRate.value = "";
      timePeriod.value = "";
      compoundingFrequency.value = "1";
      result.value = null;
      error.value = "";
    };

    return {
      initialValue,
      growthRate,
      timePeriod,
      compoundingFrequency,
      result,
      error,
      calculateGrowth,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
