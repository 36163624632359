<template>
      <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Starfield Name Generator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is Starfield Name Generator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Starfield Name Generator is an online tool designed to create unique and imaginative names for use within the Starfield universe or for any space-themed content. It generates random names suitable for characters, spaceships, planets, galaxies, and other celestial entities.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>Brief overview about Starfield</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Starfield is an upcoming space-themed role-playing game (RPG) developed by Bethesda Game Studios, known for creating the Elder Scrolls and Fallout series. Set in the 24th century, this single-player game allows players to explore space and undertake various missions across the stars. The game is expected to provide a vast open world and numerous planets to explore, filled with diverse life forms and cultures.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>How does the Starfield Name Generator work?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The generator uses a combination of linguistic patterns, syllable blending, and randomization algorithms to create names that sound appropriate for the Starfield universe. You might be able to customize the output by adjusting parameters such as name length, complexity, or theme to better suit your needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>Can I use names generated for commercial purposes?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Typically, names generated by such tools are not trademarked and can be used for personal or commercial projects. However, it's advisable to perform a trademark search to ensure the name isn't already in use or trademarked by another entity.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>Is there a limit to how many names I can generate?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Usually, there is no strict limit to the number of names you can generate with such a tool. However, excessive or automated requests may be restricted to ensure fair usage for all users.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Can I save my favorite generated names?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Some name generators include a feature to save or bookmark your favorite names. If this specific generator does not offer such a feature, you may need to manually copy and save the names elsewhere.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>How do I select the best name from the generated list?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Choosing the best name is subjective and depends on your personal preference or the context in which you plan to use the name. Consider the name's sound, meaning, and how well it fits your character, location, or concept within the Starfield universe. It may help to say the names out loud or link them with traits or backstories for your creations.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEight" aria-expanded="true" aria-controls="faqCollapseEight">
                            <h5>Is the Starfield Name Generator officially affiliated with Bethesda Game Studios?</h5>
                        </button>
                        <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Unless explicitly stated, such generators are typically created by fans or third-party developers and are not officially affiliated with the game's developers. Always ensure to use fan-made resources in compliance with intellectual property laws.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseNine" aria-expanded="true" aria-controls="faqCollapseNine">
                            <h5>Do I need to credit the Starfield Name Generator for any names I use?</h5>
                        </button>
                        <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    For most generators, crediting is not required, but you may choose to credit the tool or its creators as a courtesy if you wish. Always check the terms of service for the specific name generator you are using.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTen" aria-expanded="true" aria-controls="faqCollapseTen">
                            <h5>Can I suggest improvements or new features for the generator?</h5>
                        </button>
                        <div id="faqCollapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The creators of the generator may appreciate user feedback. Look for a contact form or email address associated with the tool where you can submit your suggestions or feedback.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'starfieldNameGeneratorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
