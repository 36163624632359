<template>
  <BreadCrumb PageTitle="Whirlpool Hash Generator Calculator" />

	<div class="custom-container mt-5">
	  <h1 class="text-center">Whirlpool Hash Generator</h1>
      <p class="fs-6 text-center mb-4">Quickly generate Whirlpool hashes for your strings using our efficient and user-friendly tool.</p>
	  <div class="mb-3">
		<label for="inputTextString" class="form-label">Enter text string:</label>
		<textarea v-model="inputText" id="inputTextString" class="form-control"></textarea>
	  </div>
	  <div class="row mb-3">
		<div class="col-md-6 mb-2">
		  <button @click="generateHash" class="btn btn-primary w-100">Generate Hash</button>
		</div>
		<div class="col-md-6 mb-2">
		  <button @click="clearText" class="btn btn-danger w-100">Clear Text</button>
		</div>
	  </div>
	  <div class="mb-3">
		<label for="generatedHash" class="form-label">Generated Whirlpool Hash:</label>
		<textarea v-model="generatedHash" id="generatedHash" class="form-control" rows="2" readonly></textarea>
	  </div>
	  <div class="mb-3">
		<button @click="copyHash" class="btn btn-secondary w-100">{{ copyButtonText }}</button>
	  </div>
	  <div class="mb-3 d-flex justify-content-between">
		<label class="form-label">Hash Size: 64 bytes (512 bits)</label>
		<label class="form-label">Hash Length: {{ hashLength }} characters</label>
	  </div>
  
	  <!-- FAQ Section -->
	  <div class="mt-50 mb-50">
		<h2 class="text-center mb-4">Frequently Asked Questions</h2>
		<div class="accordion" id="faqAccordion">
		  <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
			<h2 class="accordion-header" :id="'heading' + index">
			  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
				{{ faq.question }}
			  </button>
			</h2>
			<div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
			  <div class="accordion-body" v-html="faq.answer"></div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
</template>
  
<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

  import { ref } from 'vue';
  import cryptoApi from 'crypto-api/src/crypto-api';
  import 'crypto-api/src/hasher/whirlpool';
  
  export default {
	
  components: { BreadCrumb },
name: 'WhirlpoolHashGenerator',
	setup() {
	  const inputText = ref('');
	  const generatedHash = ref('');
	  const hashLength = ref(0);
	  const copyButtonText = ref('Copy Hash');
  
	  const generateHash = () => {
		try {
		  const hasher = cryptoApi.getHasher('whirlpool');
		  hasher.update(inputText.value);
		  const hash = hasher.finalize();
		  const hashHex = cryptoApi.encoder.toHex(hash).toUpperCase();
		  generatedHash.value = hashHex;
		  hashLength.value = hashHex.length;
		} catch (error) {
		  console.error('Hash generation failed:', error);
		  generatedHash.value = `Error generating hash: ${error.message}`;
		  hashLength.value = 0;
		}
	  };
  
	  const clearText = () => {
		inputText.value = '';
		generatedHash.value = '';
		hashLength.value = 0;
	  };
  
	  const copyHash = () => {
		navigator.clipboard.writeText(generatedHash.value)
			.then(() => {
			copyButtonText.value = 'Hash Copied';
			setTimeout(() => {
				copyButtonText.value = 'Copy Hash';
			}, 2000);
			})
			.catch(err => console.error('Failed to copy: ', err));
	  };
  
	  const faqs = [
		{
			question: "What is Whirlpool?",
			answer: `Whirlpool is a cryptographic hash function designed to provide a 512-bit hash value. It is a member of the NESSIE project and is designed to offer a high level of security and efficiency.`
		},
		{
			question: "How is the Whirlpool hash generated?",
			answer: `Whirlpool generates a hash through a series of transformations on the input data. It uses a block cipher based on the Advanced Encryption Standard (AES) and applies it in a Miyaguchi-Preneel construction to produce the final 512-bit hash value.`
		},
		{
			question: "What are the key features of Whirlpool?",
			answer: `Whirlpool offers several key features:
			<ul>
				<li><strong>High Security:</strong> Designed to resist known cryptographic attacks.</li>
				<li><strong>Fixed Output Size:</strong> Produces a consistent 512-bit hash value regardless of input size.</li>
				<li><strong>Efficiency:</strong> Efficient to compute and suitable for various applications.</li>
				<li><strong>Flexibility:</strong> Can hash messages of any length.</li>
			</ul>`
		},
		{
			question: "Why should I use Whirlpool over other hash functions?",
			answer: `Whirlpool is recommended for its high security and resistance to known vulnerabilities affecting older hash functions like SHA-1 and MD5. It offers a higher security margin than SHA-256 and is part of the NESSIE project, ensuring long-term security.`
		},
		{
			question: "Is Whirlpool suitable for security-sensitive applications?",
			answer: `Yes, Whirlpool is suitable for security-sensitive applications due to its high level of security. It is designed to provide robust protection against various cryptographic attacks and is recommended for use in digital signatures, certificates, and other critical security applications.`
		},
		{
			question: "How does Whirlpool compare to SHA-512?",
			answer: `Whirlpool provides a similar security level to SHA-512 but is based on a different design principle. While both are secure, Whirlpool offers an alternative cryptographic hash function with different performance characteristics.`
		},
		{
			question: "Can Whirlpool be used for password hashing?",
			answer: `While Whirlpool can be used for password hashing, it is generally recommended to use key derivation functions like bcrypt, scrypt, or Argon2 specifically designed for hashing passwords. These functions provide additional security features like salting and iteration counts to protect against brute-force attacks.`
		},
		{
			question: "What are the applications of Whirlpool?",
			answer: `Whirlpool is used in various applications, including:
			<ul>
				<li>Digital signatures and certificates</li>
				<li>Data integrity verification</li>
				<li>Cryptographic key generation</li>
				<li>Secure communication protocols</li>
				<li>Blockchain and cryptocurrency systems</li>
			</ul>`
		},
		{
			question: "Is Whirlpool implemented in most cryptographic libraries?",
			answer: `Yes, Whirlpool is implemented in most modern cryptographic libraries. Libraries such as OpenSSL, CryptoJS, and Bouncy Castle provide support for Whirlpool, making it accessible for developers to use in various applications.`
		},
		{
			question: "How does the Whirlpool algorithm work?",
			answer: `The Whirlpool algorithm works by applying a block cipher based on the AES to the input data in a Miyaguchi-Preneel construction. This involves a series of transformations, including substitution, permutation, and mixing of the input data, to produce the final 512-bit hash value.`
		},
		{
			question: "Is this tool secure?",
			answer: `This tool generates the Whirlpool hash entirely in your browser, ensuring that your input is not sent to any server or stored anywhere. Ensure your browser is up-to-date and you're using a secure connection (HTTPS) for added security.`
		}
	];
  
	  return {
		inputText,
		generatedHash,
		hashLength,
		generateHash,
		clearText,
		copyButtonText,
		copyHash,
		faqs
	  };
	}
  }
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  textarea.form-control {
	font-size: 16px;
  }
  
  /* Dark mode styles */
  @media (prefers-color-scheme: dark) {
	.accordion-button {
	  background-color: #343a40;
	  color: #fff;
	}
  
	.accordion-button:not(.collapsed) {
	  background-color: #2b3035;
	  color: #fff;
	}
  
	.accordion-button::after {
	  filter: invert(1);
	}
  
	.accordion-body {
	  background-color: #343a40;
	  color: #fff;
	}
  
	/* Style for code snippets in dark mode */
	.accordion-body code {
	  background-color: #2b3035;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  
  /* Light mode styles */
  @media (prefers-color-scheme: light) {
	.accordion-body {
	  background-color: #fff;
	  color: #212529;
	}
  
	/* Style for code snippets in light mode */
	.accordion-body code {
	  background-color: #f8f9fa;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  </style>
