<template>
    <BreadCrumb PageTitle="Latest Blog Posts" />
    <div class="blog-list container mx-auto px-4 py-8 mb-50">
      <h1 class="text-4xl font-bold mb-4 text-center fs-3">Latest Blog Posts</h1>
      <div class="blog-grid">
        <div v-for="post in posts" :key="post.slug" class="blog-card bg-white rounded-lg shadow-md overflow-hidden rounded-3">
          <div class="image-container custom-height">
            <img v-if="post.featuredImage" :src="require(`@/content/blog/${post.featuredImage}`)" :alt="post.title" class="featured-image w-full h-full object-cover">
          </div>
          <div class="p-6">
            <h2 class="text-xl font-semibold mb-2 text-center">
              <router-link :to="{ name: 'BlogPost', params: { slug: post.slug } }" class="text-blue-600 hover:text-blue-800 fs-5">
                {{ post.title }}
              </router-link>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, onMounted, ref } from 'vue';
  import { useContent } from '@/utils/content';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  
  export default defineComponent({
    components: {
        BreadCrumb
    },
    name: 'BlogListPage',
    setup() {
      const { fetchPosts } = useContent();
      const posts = ref([]);
  
      onMounted(async () => {
        posts.value = await fetchPosts();
      });
  
      const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate();
        const month = d.toLocaleString('default', { month: 'short' });
        const year = d.getFullYear();
        return `${day} ${month} ${year}`;
      };
  
      return {
        posts,
        formatDate
      };
    }
  });
  </script>
  
  <style scoped>
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  
  @media (max-width: 1024px) {
    .blog-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 640px) {
    .blog-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .blog-card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .image-container {
    overflow: hidden;
  }
  
  .custom-height {
    height: 200px; /* This is equivalent to h-24 in Tailwind */
  }
  
  .featured-image {
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .blog-card:hover .featured-image {
    transform: scale(1.05);
  }
  </style>