<template>
	<BreadCrumb PageTitle="Antoine Equation Calculator" />
	<div class="custom-container mt-4 mb-50">
	  <h1 class="text-center">Antoine Equation Calculator</h1>
	  <p class="fs-6 text-center mb-4">
		Calculate vapor pressure using the Antoine Equation with this simple calculator. Input coefficients A, B, C, and temperature to get results.
	  </p>
	  <form @submit.prevent="calculateVaporPressure" id="antoineForm">
		<div class="form-group">
		  <div class="input-group mb-3">
			<label class="input-group-text" for="coefficientA">Coefficient A:</label>
			<input
			  type="number"
			  class="form-control"
			  id="coefficientA"
			  v-model.number="coefficientA"
			  placeholder="Enter coefficient A"
			  required
			  min="0"
			  step="any"
			/>
		  </div>
		</div>
		<div class="form-group">
		  <div class="input-group mb-3">
			<label class="input-group-text" for="coefficientB">Coefficient B:</label>
			<input
			  type="number"
			  class="form-control"
			  id="coefficientB"
			  v-model.number="coefficientB"
			  placeholder="Enter coefficient B"
			  required
			  min="0"
			  step="any"
			/>
		  </div>
		</div>
		<div class="form-group">
		  <div class="input-group mb-3">
			<label class="input-group-text" for="coefficientC">Coefficient C:</label>
			<input
			  type="number"
			  class="form-control"
			  id="coefficientC"
			  v-model.number="coefficientC"
			  placeholder="Enter coefficient C"
			  required
			  min="0"
			  step="any"
			/>
		  </div>
		</div>
		<div class="form-group">
		  <div class="input-group mb-3">
			<label class="input-group-text" for="temperature">Temperature (°C):</label>
			<input
			  type="number"
			  class="form-control"
			  id="temperature"
			  v-model.number="temperature"
			  placeholder="Enter temperature in °C"
			  required
			  step="any"
			/>
		  </div>
		</div>
		<button type="submit" class="btn btn-primary w-100">Calculate</button>
	  </form>
	  <div class="form-group mt-3 mb-3">
		<label for="result">Vapor Pressure (mmHg):</label>
		<textarea
		  class="form-control"
		  id="result"
		  v-model="result"
		  rows="3"
		  readonly
		></textarea>
	  </div>
	  <div>
		<AntoineEquationCalculatorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AntoineEquationCalculatorFAQ from '../FAQs/antoineEquationCalculatorFAQ.vue';
  
  export default {
	components: {
	  BreadCrumb,
	  AntoineEquationCalculatorFAQ
	},
	data() {
	  return {
		coefficientA: null,
		coefficientB: null,
		coefficientC: null,
		temperature: null,
		result: '-',
	  };
	},
	methods: {
	  calculateVaporPressure() {
		const { coefficientA, coefficientB, coefficientC, temperature } = this;
  
		// Validate input: Ensure all coefficients and temperature are positive numbers
		if (
		  [coefficientA, coefficientB, coefficientC, temperature].some(
			(value) => value === null || isNaN(value) || value <= 0
		  )
		) {
		  this.result = 'Please enter valid positive numbers for all fields.';
		  return;
		}
  
		// Calculate vapor pressure using the Antoine Equation: log10(P) = A - (B / (C + T))
		const logP = coefficientA - (coefficientB / (coefficientC + temperature));
		const vaporPressure = Math.pow(10, logP);
  
		this.result = `The vapor pressure is ${vaporPressure.toFixed(2)} mmHg.`;
	  },
	},
  };
  </script>
  
  <style scoped>
  .custom-container {
	width: 90vw;              /* Takes up 90% of the viewport width */
	max-width: 800px;         /* Prevents it from getting too wide on large screens */
	margin: auto;             /* Centers the container */
	padding: 0 1rem;          /* Adds horizontal padding for better spacing */
	box-sizing: border-box;   /* Ensures padding is included in the width */
  }
  
  label {
	font-size: 16px;
  }
  
  /* Optional: Styling for input-group-text to align with label font-size */
  .input-group-text {
	font-size: 16px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 600px) {
	.custom-container {
	  width: 95vw;            /* Slightly wider on very small screens */
	  padding: 0 0.5rem;      /* Adjust padding for smaller screens */
	}
  }
  </style>
  