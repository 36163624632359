<template>
  <BreadCrumb PageTitle="Tangent Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Tangent Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the tangent of an angle. Enter the angle in degrees or radians to compute its tangent value.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Angle -->
        <div class="mb-3">
          <label for="angle" class="form-label">Enter Angle:</label>
          <input
            type="number"
            id="angle"
            v-model="angle"
            class="form-control"
            placeholder="e.g., 45"
          />
        </div>

        <!-- Select Angle Unit -->
        <div class="mb-3">
          <label class="form-label">Angle Unit:</label>
          <div>
            <input
              type="radio"
              id="degrees"
              value="degrees"
              v-model="angleUnit"
            />
            <label for="degrees" class="me-3">Degrees</label>
            <input
              type="radio"
              id="radians"
              value="radians"
              v-model="angleUnit"
            />
            <label for="radians">Radians</label>
          </div>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateTangent" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Angle: {{ result.angle }} {{ result.unit }}</p>
          <p class="fs-5">Tangent: {{ result.tangent }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the angle value in the input field.</li>
        <li>Select the unit of the angle (Degrees or Radians).</li>
        <li>Click "Calculate" to compute the tangent of the angle.</li>
        <li>The result will display the angle and its tangent value.</li>
        <li>Click "Clear" to reset the input field and result.</li>
      </ul>

      <h5 class="alert-heading">Understanding Tangent Function:</h5>
      <p class="fs-6">
        In trigonometry, the tangent of an angle in a right-angled triangle is the ratio of the length of the opposite side to the length of the adjacent side.
      </p>
      <p class="fs-6">
        The tangent function is periodic with a period of \( \pi \) radians (or 180 degrees).
      </p>

      <h5 class="alert-heading">Formula:</h5>
      <div>
        \[
        \tan(\theta) = \frac{\sin(\theta)}{\cos(\theta)}
        \]
      </div>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          Tangent of 45 degrees:
          <ul>
            <li>\( \tan(45^\circ) = 1 \)</li>
          </ul>
        </li>
        <li>
          Tangent of \( \frac{\pi}{4} \) radians:
          <ul>
            <li>\( \tan\left(\frac{\pi}{4}\right) = 1 \)</li>
          </ul>
        </li>
        <li>
          Tangent of 90 degrees:
          <ul>
            <li>Tangent is undefined as \( \cos(90^\circ) = 0 \).</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input field and result state
    const angle = ref(null);
    const angleUnit = ref("degrees");
    const result = ref(null);
    const error = ref("");

    // Function to calculate the tangent
    const calculateTangent = () => {
      error.value = "";
      result.value = null;

      try {
        // Ensure value is not null or empty
        if (angle.value === null || angle.value === "") {
          throw new Error("Please enter an angle value.");
        }

        // Parse the input value
        const angleValue = parseFloat(angle.value);

        if (isNaN(angleValue)) {
          throw new Error("Please enter a valid numeric value for the angle.");
        }

        // Convert angle to radians if necessary
        let angleInRadians;
        if (angleUnit.value === "degrees") {
          angleInRadians = (angleValue * Math.PI) / 180;
        } else {
          angleInRadians = angleValue;
        }

        // Check if tangent is undefined (cosine of angle is zero)
        const cosineValue = Math.cos(angleInRadians);
        if (Math.abs(cosineValue) < 1e-10) {
          throw new Error("Tangent is undefined for this angle.");
        }

        // Calculate tangent
        const tangentValue = Math.tan(angleInRadians);

        result.value = {
          angle: angleValue,
          unit: angleUnit.value,
          tangent: tangentValue.toFixed(6),
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input field and result
    const clearValues = () => {
      angle.value = null;
      angleUnit.value = "degrees";
      result.value = null;
      error.value = "";
    };

    return {
      angle,
      angleUnit,
      result,
      error,
      calculateTangent,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
