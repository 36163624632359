<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
    <div class="card-body p-15 p-sm-20">
        <div class="accordion" id="faqAccordion">
            <div class="row">
                <div class="text-center mb-3">
                    <h4>FAQs on Annualized Loss Expectancy (ALE) Calculator</h4>
                </div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseIntroduction" aria-expanded="true" aria-controls="faqCollapseIntroduction">
                        <h5>What Is Annualized Loss Expectancy (ALE) Calculator?</h5>
                    </button>
                    <div id="faqCollapseIntroduction" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-5 text-paragraph lh-base">
                                The Annualized Loss Expectancy (ALE) Calculator is designed to help organizations quantify the potential annual financial loss due to various risks. By understanding the ALE, businesses can make informed decisions about investments in security measures and risk management strategies.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="false" aria-controls="faqCollapseFive">
                        <h5>How to use the ALE Calculator?</h5>
                    </button>
                    <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                Using the ALE Calculator is straightforward:
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Enter the Single Loss Expectancy (SLE) in the designated field. This figure should reflect the direct and indirect financial loss per incident.</li>
                                    <li>Input the Annual Rate of Occurrence (ARO) for the risk, which is how often you expect the incident to occur annually.</li>
                                    <li>Click the "Calculate" button to compute the Annualized Loss Expectancy.</li>
                                </ol>
                                <em class="fs-md-15 fs-lg-16 text-paragraph lh-base">Example: If an SLE of $10,000 and an ARO of 0.2 (indicating the event may occur once every 5 years) are input, the ALE would be calculated as follows: $10,000 x 0.2 = $2,000. This means you can expect to lose $2,000 annually due to this risk.</em>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- FAQ Items -->
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                        <h5>Which two values are required to calculate annual loss expectancy?</h5>
                    </button>
                    <div id="faqCollapseOne" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                The two required values are the Single Loss Expectancy (SLE) and the Annual Rate of Occurrence (ARO).
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="false" aria-controls="faqCollapseTwo">
                        <h5>Annualized loss expectancy is calculated using which formula?</h5>
                    </button>
                    <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                              The formula for calculating ALE is straightforward: ALE = SLE × ARO. Here’s a breakdown of each component:
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>SLE (Single Loss Expectancy):</strong> The expected monetary loss for a single event. It represents the financial impact of each occurrence of a threat.</li>
                                    <li><strong>ARO (Annual Rate of Occurrence):</strong> The estimated number of times a threat is expected to occur in a year.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="false" aria-controls="faqCollapseThree">
                        <h5>What is the meaning of loss expectancy?</h5>
                    </button>
                    <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                Loss expectancy refers to the expected amount of financial loss from a risk or threat, either as a single event (SLE) or annually (ALE).
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="false" aria-controls="faqCollapseFour">
                        <h5>What is the difference between ARO and ALE?</h5>
                    </button>
                    <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                ARO (Annual Rate of Occurrence) is the frequency with which a specific risk is expected to occur each year, whereas ALE (Annualized Loss Expectancy) is the expected monetary loss per year, calculated by multiplying the ARO by the SLE.
                            </p>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'AnnualizedLossExpectancyCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
