<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on HTML to JSON Converter</h3>
                    </div>
                    <!-- FAQ 1 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the HTML to JSON Converter?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to JSON Converter is a tool that extracts data from HTML documents, especially from tables and other structured elements, and converts it into a JSON format. JSON (JavaScript Object Notation) is a lightweight format commonly used for data exchange between applications.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 2 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the HTML to JSON Converter?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To use the HTML to JSON Converter:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Paste the HTML code containing structured data (such as tables) into the input field.</li>
                                    <li>Click on the <strong>Convert</strong> button to transform the HTML into JSON format.</li>
                                    <li>The JSON data will be displayed in the output field.</li>
                                    <li>Click on the <strong>Download</strong> button if you want to save the JSON file to your device.</li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The tool makes it easy to convert structured data from HTML documents into JSON for use in APIs, web applications, or data analysis.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 3 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What is JSON?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    JSON (JavaScript Object Notation) is a lightweight data format used for data interchange between systems. It represents data as key-value pairs, arrays, and objects. JSON is easy for humans to read and write, and easy for machines to parse and generate.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Here's an example of a simple JSON object:
                                </p>
                                <pre>
{
    "name": "Alice",
    "age": 30,
    "city": "New York"
}
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    In this case, <code>name</code>, <code>age</code>, and <code>city</code> are the keys, and "Alice", 30, and "New York" are the values. JSON is widely used for APIs, configuration files, and data storage.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 4 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>What types of HTML can be converted to JSON?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to JSON Converter can process structured HTML elements like tables, lists, and forms. For example, a table with rows of data can be converted into a JSON array where each row is represented as an object:
                                </p>
                                <pre>
&lt;table&gt;
    &lt;tr&gt;&lt;th&gt;Name&lt;/th&gt;&lt;th&gt;Age&lt;/th&gt;&lt;/tr&gt;
    &lt;tr&gt;&lt;td&gt;Alice&lt;/td&gt;&lt;td&gt;30&lt;/td&gt;&lt;/tr&gt;
    &lt;tr&gt;&lt;td&gt;Bob&lt;/td&gt;&lt;td&gt;25&lt;/td&gt;&lt;/tr&gt;
&lt;/table&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Is converted to the following JSON:
                                </p>
                                <pre>
[
    {
        "Name": "Alice",
        "Age": 30
    },
    {
        "Name": "Bob",
        "Age": 25
    }
]
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The converter works well with standard HTML structures but may not fully support complex or nested elements.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 5 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>How does the conversion process work?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to JSON Converter reads the HTML structure, extracts the relevant data, and converts it into JSON format. For example:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>HTML tables are converted into JSON arrays, where each row becomes an object and each header tag (&lt;th&gt;) is a key.</li>
                                    <li>HTML lists (&lt;ul&gt;, &lt;ol&gt;) are converted into JSON arrays.</li>
                                    <li>Form inputs (text fields, checkboxes, etc.) are converted into key-value pairs in JSON.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This makes the data easier to manipulate programmatically or store for later use.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 6 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Are there any limitations with this converter?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to JSON Converter is designed for simple HTML structures like tables and lists. However, there are some limitations:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>It may not handle complex or deeply nested HTML elements effectively.</li>
                                    <li>Tables with <code>colspan</code> or <code>rowspan</code> attributes may not be converted correctly.</li>
                                    <li>HTML elements with custom attributes or non-standard structures may result in unexpected JSON output.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    For best results, use clean and well-structured HTML when converting to JSON.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 7 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>Why would I need to convert HTML to JSON?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Converting HTML to JSON is useful when you need to process or analyze data from a webpage in a structured format that is easily accessible for APIs, web applications, or data analysis tools. JSON is lightweight and can be consumed by various programming languages, making it ideal for transferring data between systems.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    For example, if you have a web page with a table of product data and want to use it in a JavaScript application, converting the table to JSON allows you to work with the data programmatically.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Additional FAQs as needed -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'htmlToJSONFAQ',
};
</script>

<style scoped>
  /* Add any specific styles for FAQ layout */
</style>
