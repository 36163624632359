<template>
  <BreadCrumb PageTitle="Ntlm Hash Generator Calculator" />

	<div class="custom-container mt-5">
	  <h1 class="text-center">NTLM Hash Generator</h1>
      <p class="fs-6 text-center mb-4">Quickly generate NTLM hashes for your strings using our efficient and user-friendly tool.</p>
	  <div class="mb-3">
		<label for="inputTextString" class="form-label">Enter text string:</label>
		<textarea v-model="inputText" id="inputTextString" class="form-control"></textarea>
	  </div>
	  <div class="row mb-3">
		<div class="col-md-6 mb-2">
		  <button @click="generateHash" class="btn btn-primary w-100">Generate Hash</button>
		</div>
		<div class="col-md-6 mb-2">
		  <button @click="clearText" class="btn btn-danger w-100">Clear Text</button>
		</div>
	  </div>
	  <div class="mb-3">
		<label for="generatedHash" class="form-label">Generated NTLM Hash:</label>
		<textarea v-model="generatedHash" id="generatedHash" class="form-control" rows="2" readonly></textarea>
	  </div>
	  <div class="mb-3">
		<button @click="copyHash" class="btn btn-secondary w-100">{{ copyButtonText }}</button>
	  </div>
	  <div class="mb-3 d-flex justify-content-between">
		<label class="form-label">Hash Size: 16 bytes (128 bits)</label>
		<label class="form-label">Hash Length: {{ hashLength }} characters</label>
	  </div>
  
	  <!-- FAQ Section -->
	  <div class="mt-50 mb-50">
		<h2 class="text-center mb-4">Frequently Asked Questions</h2>
		<div class="accordion" id="faqAccordion">
		  <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
			<h2 class="accordion-header" :id="'heading' + index">
			  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
				{{ faq.question }}
			  </button>
			</h2>
			<div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
			  <div class="accordion-body" v-html="faq.answer"></div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </template>
  
  <script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

  import { ref } from 'vue';
  import cryptoApi from 'crypto-api/src/crypto-api';
  import 'crypto-api/src/hasher/md4';
  
  export default {
	
  components: { BreadCrumb },
name: 'NTLMHashGenerator',
	setup() {
	  const inputText = ref('');
	  const generatedHash = ref('');
	  const hashLength = ref(0);
	  const copyButtonText = ref('Copy Hash');
  
	  const generateHash = () => {
		try {
		  const utf16le = unescape(encodeURIComponent(inputText.value)).split('').map(char => char + '\x00').join('');
		  const hasher = cryptoApi.getHasher('md4');
		  hasher.update(utf16le);
		  const hash = hasher.finalize();
		  const hashHex = cryptoApi.encoder.toHex(hash).toUpperCase();
		  generatedHash.value = hashHex;
		  hashLength.value = hashHex.length;
		} catch (error) {
		  console.error('Hash generation failed:', error);
		  generatedHash.value = `Error generating hash: ${error.message}`;
		  hashLength.value = 0;
		}
	  };
  
	  const clearText = () => {
		inputText.value = '';
		generatedHash.value = '';
		hashLength.value = 0;
	  };
  
	  const copyHash = () => {
		navigator.clipboard.writeText(generatedHash.value)
			.then(() => {
			copyButtonText.value = 'Hash Copied';
			setTimeout(() => {
				copyButtonText.value = 'Copy Hash';
			}, 2000);
			})
			.catch(err => console.error('Failed to copy: ', err));
	  };
  
      const faqs = [
        {
            question: "What is an NTLM hash?",
            answer: `An NTLM (NT LAN Manager) hash is a way of storing passwords used by Microsoft Windows. It's a one-way hash of a password, which means you can't reverse it to get the original password. NTLM hashes are used in Windows authentication processes and are stored in the Security Account Manager (SAM) database or Active Directory.<br><br>
            There are two versions of NTLM hashes:
            <ul>
            <li>LM hash: An older, less secure version (disabled by default in modern Windows systems)</li>
            <li>NT hash: The more secure version used in modern Windows systems</li>
            </ul>
            This tool generates the NT hash version.`
        },
        {
            question: "How is the NTLM hash generated?",
            answer: `The NTLM (NT) hash is generated using the following steps:
            <ol>
            <li>Convert the password to UTF-16 Little Endian format</li>
            <li>Apply the MD4 hashing algorithm to the UTF-16LE password</li>
            <li>Represent the resulting 128-bit (16-byte) hash in hexadecimal format</li>
            </ol>
            The formula can be represented as:<br>
            <code>NTLM_hash = MD4(UTF-16LE(password))</code><br><br>
            For example, if the password is "Password123", the NTLM hash would be:<br>
            <code>58A478135A93AC3BF058A5EA0E8FDB71</code>`
        },
        {
            question: "Can you provide a step-by-step example of NTLM hash generation?",
            answer: `Certainly! Let's use the password "Hello" as an example:
            <ol>
            <li>Convert "Hello" to UTF-16LE: <code>48 00 65 00 6C 00 6C 00 6F 00</code></li>
            <li>Apply MD4 hash to this UTF-16LE string</li>
            <li>The resulting hash in hexadecimal: <code>C34515438FB382E4F5CA4D926C2EAD11</code></li>
            </ol>
            So, the NTLM hash for "Hello" is <code>C34515438FB382E4F5CA4D926C2EAD11</code>.`
        },
        {
            question: "Is this tool secure?",
            answer: `Yes, this tool generates the hash entirely in your browser. Your input is not sent to any server or stored anywhere. However, please keep in mind:
            <ul>
            <li>Don't use this tool on public or shared computers</li>
            <li>Clear your browser history and cache after using if on a shared device</li>
            <li>For highly sensitive passwords, it's best to use offline tools on a secure, isolated system</li>
            </ul>`
        },
        {
            question: "Why would I need to generate an NTLM hash?",
            answer: `NTLM hashes are commonly used in Windows environments for various purposes:
            <ul>
            <li>Testing: Security professionals may generate hashes to test password strength or authentication systems</li>
            <li>Security Research: Studying password hashing algorithms and their vulnerabilities</li>
            <li>System Administration: Some Windows administration tasks may require working with NTLM hashes</li>
            <li>Forensics: Digital forensics investigators might need to generate or compare NTLM hashes</li>
            <li>Password Auditing: Organizations may audit password hashes to ensure policy compliance</li>
            </ul>`
        },
        {
            question: "Can I reverse an NTLM hash to get the original password?",
            answer: `No, NTLM hashes are one-way hashes. You can't mathematically reverse them to get the original password. However, there are methods to potentially discover the password:
            <ul>
            <li>Rainbow Tables: Precomputed tables of passwords and their corresponding hashes</li>
            <li>Brute-Force Attacks: Systematically trying all possible passwords</li>
            <li>Dictionary Attacks: Trying a list of common passwords or words</li>
            </ul>
            These methods are more effective against weak passwords. Strong, complex passwords are much more resistant to these techniques.`
        },
        {
            question: "How does NTLM hashing compare to other password hashing methods?",
            answer: `NTLM hashing, while still in use, is considered less secure compared to modern hashing algorithms:
            <ul>
            <li>It uses MD4, which is cryptographically weak by today's standards</li>
            <li>It doesn't use salting, making it vulnerable to rainbow table attacks</li>
            <li>It's relatively fast to compute, making brute-force attacks more feasible</li>
            </ul>
            Modern alternatives like bcrypt, Argon2, or PBKDF2 are generally recommended for new systems requiring password hashing.`
        },
        {
            question: "What's the difference between LM and NT hashes?",
            answer: `LM (LAN Manager) and NT hashes are two types of NTLM hashes:
            <ul>
            <li>LM Hash:
                <ul>
                <li>Older and less secure</li>
                <li>Limits passwords to 14 characters</li>
                <li>Converts all characters to uppercase</li>
                <li>Splits the password into two 7-character chunks</li>
                <li>Disabled by default in modern Windows systems</li>
                </ul>
            </li>
            <li>NT Hash:
                <ul>
                <li>More secure than LM hash</li>
                <li>Preserves case sensitivity</li>
                <li>No character limit</li>
                <li>Uses the full UTF-16LE encoded password</li>
                <li>The standard in modern Windows systems</li>
                </ul>
            </li>
            </ul>
            This tool generates the NT hash version.`
        }
        ];
  
	  return {
		inputText,
		generatedHash,
		hashLength,
		generateHash,
		clearText,
		copyButtonText,
		copyHash,
		faqs
	  };
	}
  }
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  textarea.form-control {
	font-size: 16px;
  }
  
  /* Dark mode styles */
  @media (prefers-color-scheme: dark) {
	.accordion-button {
	  background-color: #343a40;
	  color: #fff;
	}
  
	.accordion-button:not(.collapsed) {
	  background-color: #2b3035;
	  color: #fff;
	}
  
	.accordion-button::after {
	  filter: invert(1);
	}
  
	.accordion-body {
	  background-color: #343a40;
	  color: #fff;
	}
  
	/* Style for code snippets in dark mode */
	.accordion-body code {
	  background-color: #2b3035;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  
  /* Light mode styles */
  @media (prefers-color-scheme: light) {
	.accordion-body {
	  background-color: #fff;
	  color: #212529;
	}
  
	/* Style for code snippets in light mode */
	.accordion-body code {
	  background-color: #f8f9fa;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  </style>
