<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Sine Calculator</h3>
                    </div>
                    <!-- FAQ 1 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the Sine Calculator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Sine Calculator is a tool used to calculate the sine of an angle in both degrees and radians. The sine function is a fundamental trigonometric function that relates the angle of a right triangle to the ratio of the length of the opposite side to the hypotenuse.
                                </p>
                                <!-- Diagram: Right Triangle for Sine -->
                                <div class="text-center my-3">
                                    <img src="images/sc_faq_3.jpg" alt="Right Traingle Diagram" class="img-fluid">
                                    <p class="text-muted fs-md-14">Figure: Right Triangle showing sine of angle θ, where sin(θ) = Opposite/Hypotenuse.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 2 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the Sine Calculator?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To use the Sine Calculator:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Enter the angle in degrees or radians in the input field.</li>
                                    <li>Choose whether the angle is in <strong>degrees</strong> or <strong>radians</strong>.</li>
                                    <li>Click on the <strong>Calculate</strong> button to see the sine value of the angle.</li>
                                    <li>You can reset the fields by clicking the <strong>Clear</strong> button.</li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The result will display the sine value of the given angle.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 3 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What is the sine function?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The sine function, denoted as sin(θ), is one of the basic trigonometric functions. In a right triangle, it is the ratio of the length of the opposite side to the hypotenuse.
                                </p>
                                <div class="text-center my-3">
                                    <img src="images/sc_faq_1.jpg" alt="Unit Circle Diagram" class="img-fluid">
                                    <p class="text-muted fs-md-14">Figure: The Unit Circle showing the sine of an angle as the y-coordinate of the point where the angle intercepts the circle.</p>
                                </div>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The sine function can also be extended to all angles using the unit circle. The sine of an angle in the unit circle is the y-coordinate of the point on the circle where the angle intercepts the circle.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 4 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>How do I convert between degrees and radians?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To convert from degrees to radians, use the formula:
                                </p>
                                <p class="text-center">
                                    <strong>Radians = Degrees × (π/180)</strong>
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To convert from radians to degrees, use the formula:
                                </p>
                                <p class="text-center">
                                    <strong>Degrees = Radians × (180/π)</strong>
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Sine Calculator provides an option to input values in either degrees or radians, and automatically calculates the sine for both units.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 5 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>What information do I need to provide?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    You need to provide an angle, either in degrees or radians. You can also choose the unit (degrees or radians) using the option buttons. Based on the input, the calculator will return the sine value.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 6 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>What should I do if the result seems incorrect?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    If the result seems incorrect, double-check that you have entered the correct value for the angle and selected the appropriate unit (degrees or radians). Remember that incorrect unit selection can lead to unexpected results.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 7 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>Can I calculate the sine of negative angles?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, the Sine Calculator supports negative angles. Negative angles are measured clockwise from the positive x-axis. For example, the sine of -30° is equal to -0.5. The sine function is periodic, and the values repeat for every 360° (or 2π radians).
                                </p>
                                <div class="text-center my-3">
                                    <img src="images/sc_faq_2.jpg" alt="Negative Angle Diagram" class="img-fluid">
                                    <p class="text-muted fs-md-14">Figure: Sine of negative angles on the unit circle.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Additional FAQs as needed -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SineCalculatorFAQ',
};
</script>

<style scoped>
  /* Add any specific styles for FAQ layout or images */
</style>
