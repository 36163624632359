<template>
  <BreadCrumb PageTitle="Adding Fractions Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Adding Fractions Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two fractions, and this tool will calculate their sum. Use the "/" symbol to represent fractions (e.g., 1/2 + 2/3). For mixed fractions, use a space between the whole number and the fraction part (e.g., 1 1/2).
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <div class="row mb-3">
          <!-- First Fraction Input -->
          <div class="col-5">
            <input type="text" id="fraction1" v-model="fraction1" class="form-control" placeholder="e.g., 1 1/2">
          </div>

          <!-- Plus Sign -->
          <div class="col-2 text-center align-self-center">
            <h4>+</h4>
          </div>

          <!-- Second Fraction Input -->
          <div class="col-5">
            <input type="text" id="fraction2" v-model="fraction2" class="form-control" placeholder="e.g., 2 2/3">
          </div>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateFractions" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <p v-if="result !== null" class="fs-5">
          <strong>{{ fraction1 }}</strong> + <strong>{{ fraction2 }}</strong> = <br>
          <strong>{{ result }}</strong> <br>
          Fraction: <strong>{{ simplifiedResult }}</strong> <br>
          Decimal: <strong>{{ decimalResult }}</strong>
        </p>

        <!-- Show step-by-step explanation if result is calculated -->
        <div v-if="result !== null">
          <h4 class="mt-4">Step-by-Step Explanation:</h4>
          <p v-html="formattedExplanation" class="fs-6"></p> <!-- Use v-html for formatted explanation -->
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter fractions in the form of "numerator/denominator" (e.g., 1/2 + 3/4).</li>
        <li>If you're adding mixed fractions, you can enter them as decimals (e.g., 1 1/2 is equivalent to 1.5).</li>
        <li>The calculator will display the answer as a fraction, simplified fraction, and decimal.</li>
        <li>It will also explain step-by-step how the result was derived.</li>
      </ul>
      <h5 class="alert-heading">Formula for Adding Fractions:</h5>
      <p class="fs-6">There are two cases:</p>
      <ul class="fs-6">
        <li><strong>When the denominators are the same:</strong></li>
          <code> 
            a/c + b/c = (a + b) / c
            <br/>
            Example: 1/4 + 2/4 = (1 + 2) / 4 = 3/4
          </code>
        <li><strong>When the denominators are different:</strong></li>
          <code>
            a/b + c/d = (a * d) + (b * c) / (b * d)
            <br/>
            Example: 1/3 + 1/4 = (1 * 4) + (3 * 1) / (3 * 4) = (4 + 3) / 12 = 7/12
          </code>
        <li><strong>Mixed fractions:</strong></li>
          <code>
            Example: 1 1/2 + 1 1/2 = 1.5 + 1.5 = 3.0
          </code>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const fraction1 = ref("");
    const fraction2 = ref("");
    const result = ref(null);
    const simplifiedResult = ref(null);
    const decimalResult = ref(null);
    const formattedExplanation = ref("");
    const error = ref("");

    // Function to convert mixed fraction to improper fraction
    const convertMixedToImproper = (fraction) => {
      const parts = fraction.split(" ");
      if (parts.length === 2) { // Mixed fraction
        const [whole, frac] = parts;
        const [num, denom] = frac.split("/").map(Number);
        const numerator = Number(whole) * denom + num;
        return { numerator, denominator: denom };
      } else if (parts.length === 1 && parts[0].includes("/")) { // Regular fraction
        const [num, denom] = parts[0].split("/").map(Number);
        return { numerator: num, denominator: denom };
      } else { // Whole number
        return { numerator: Number(parts[0]), denominator: 1 };
      }
    };

    // Function to calculate the sum of fractions
    const calculateFractions = () => {
      error.value = "";
      result.value = null;
      formattedExplanation.value = "";

      // Check if either input is empty
      if (!fraction1.value.trim() || !fraction2.value.trim()) {
        error.value = "Please enter both fractions to perform the calculation.";
        return;
      }

      try {
        const fraction1Converted = convertMixedToImproper(fraction1.value);
        const fraction2Converted = convertMixedToImproper(fraction2.value);

        const { numerator: num1, denominator: denom1 } = fraction1Converted;
        const { numerator: num2, denominator: denom2 } = fraction2Converted;

        // Step 1: Find the least common denominator (LCD)
        const lcd = leastCommonDenominator(denom1, denom2);
        let explanation = `The least common denominator of ${denom1} and ${denom2} is <strong>${lcd}</strong>.<br>`;
        
        // Step 2: Find the multiples and adjust the numerators
        const multiplier1 = lcd / denom1;
        const multiplier2 = lcd / denom2;

        explanation += `Fraction 1 Multiple: ${lcd} ÷ ${denom1} = <strong>${multiplier1}</strong><br>`;
        explanation += `Fraction 2 Multiple: ${lcd} ÷ ${denom2} = <strong>${multiplier2}</strong><br>`;

        // Adjust numerators
        const adjustedNum1 = num1 * multiplier1;
        const adjustedNum2 = num2 * multiplier2;

        explanation += `After adjusting the fractions: (<strong>${adjustedNum1}/${lcd}</strong>) + (<strong>${adjustedNum2}/${lcd}</strong>)<br>`;

        // Step 3: Add the numerators
        const numerator = adjustedNum1 + adjustedNum2;
        explanation += `Add the numerators: <strong>${adjustedNum1} + ${adjustedNum2} = ${numerator}</strong><br>`;
        result.value = `${numerator}/${lcd}`;

        // Step 4: Simplify the fraction
        const gcdValue = gcd(numerator, lcd);
        const simplifiedNumerator = numerator / gcdValue;
        const simplifiedDenominator = lcd / gcdValue;
        simplifiedResult.value = `${simplifiedNumerator}/${simplifiedDenominator}`;

        explanation += `Simplify: GCF of ${numerator} and ${lcd} is <strong>${gcdValue}</strong>.<br>`;
        explanation += `Simplified fraction: <strong>${simplifiedNumerator}/${simplifiedDenominator}</strong><br>`;

        // Step 5: Convert to decimal
        decimalResult.value = (simplifiedNumerator / simplifiedDenominator).toFixed(2);
        explanation += `Decimal equivalent: <strong>${simplifiedNumerator} ÷ ${simplifiedDenominator} = ${decimalResult.value}</strong><br>`;

        // Update the explanation text
        formattedExplanation.value = explanation;
      } catch (e) {
        error.value = "Please enter valid fractions in the format: numerator/denominator or mixed fraction format.";
      }
    };

    // Function to find the least common denominator
    const leastCommonDenominator = (a, b) => {
      return (a * b) / gcd(a, b);
    };

    // Function to find the greatest common divisor (GCD)
    const gcd = (a, b) => {
      return b === 0 ? a : gcd(b, a % b);
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      fraction1.value = "";
      fraction2.value = "";
      result.value = null;
      simplifiedResult.value = null;
      decimalResult.value = null;
      formattedExplanation.value = "";
      error.value = "";
    };

    return {
      fraction1,
      fraction2,
      result,
      simplifiedResult,
      decimalResult,
      formattedExplanation,
      error,
      calculateFractions,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.explanation-text {
  font-size: 1.1rem;
  line-height: 1.6;
}

strong {
  font-weight: bold;
}

.dark h5 {
    color: #2b3035 !important;
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
