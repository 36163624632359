<template>
  <BreadCrumb PageTitle="Free Word Sorter" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Free Word Sorter</h1>
    <p class="fs-6 text-center mb-4">
      Effortlessly Organize Your Words in Seconds: The Free Online Tool for Students and Writers. Copy, Paste, and Sort in Seconds!
    </p>
    <div class="row">
      <div class="col mb-4">
        <label for="inputText">Input Text:</label>
        <textarea v-model="inputText" id="inputText" class="form-control" rows="5"></textarea>
      </div>
      <div class="d-flex gap-4 mb-3">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="sortwords" id="sortasc" v-model="sortOrder" value="asc" checked>
          <label class="form-check-label" for="sortasc">ASC (A-Z)</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="sortwords" id="sortdesc" v-model="sortOrder" value="desc">
          <label class="form-check-label" for="sortdesc">DESC (Z-A)</label>
        </div>
      </div>
      <div class="mb-3">
        <label for="separator">Choose a separator:</label>
        <select v-model="separator" id="separator" class="form-select">
          <option value=",">Comma</option>
          <option value=" ">Space</option>
          <option value="newline">Line Break</option>
        </select>
      </div>
      <div class="alert alert-danger mt-3" v-if="error" id="errorAlert">{{ error }}</div>
      <div>
        <button class="btn btn-primary mb-4 w-100" @click="sortWords" id="sortBtn">Sort Words</button>
      </div>
    </div>
    <div class="col mb-4">
      <label for="outputText">Output Text</label>
      <textarea v-model="outputText" id="outputText" class="form-control" rows="5" readonly ref="outputText"></textarea>
    </div>
    <div>
      <div class="btn-toolbar mb-3" role="toolbar" aria-label="Change case buttons">
        <div class="btn-group w-100" role="group" aria-label="Case group">
          <button type="button" class="btn btn-outline-secondary" @click="changeCase('upper')" id="upperCaseBtn">Upper Case</button>
          <button type="button" class="btn btn-outline-secondary" @click="changeCase('lower')" id="lowerCaseBtn">Lower case</button>
          <button type="button" class="btn btn-outline-secondary" @click="changeCase('capitalize')" id="capitalizeBtn">Capitalize</button>
        </div>
      </div>
      <button class="btn btn-danger mt-2 w-100" @click="copyToClipboard" id="copyBtn">{{ copyButtonText }}</button>
    </div>
    <div>
      <WordSorterFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import WordSorterFAQ from '../FAQs/wordSorterFAQ.vue';

export default {
  components: {
    BreadCrumb,
    WordSorterFAQ
  },
  data() {
    return {
      inputText: '',
      outputText: '',
      sortOrder: 'asc',
      separator: ' ', // Default separator is a space
      error: null,
      copyButtonText: 'Copy to Clipboard'
    }
  },
  watch: {
    outputText() {
      this.$nextTick(() => {
        const textarea = this.$refs.outputText;
        if (textarea) {
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight + 'px';
        }
      });
    }
  },
  methods: {
    sortWords() {
      if (!this.inputText.trim()) {
        this.error = 'Input text cannot be empty.';
        return;
      }
      this.error = null;
      let words = this.inputText.replace(/[^\w\s]/gi, '').split(/\s+/); // Remove punctuation and split by spaces
      words = words.map(word => word.toLowerCase()); // Convert all words to lowercase
      words = this.sortOrder === 'asc' ? words.sort() : words.sort().reverse();

      // Handle the line break separator specifically
      const separator = this.separator === 'newline' ? '\n' : this.separator;
      this.outputText = words.join(separator);
    },
    changeCase(caseType) {
      if (!this.outputText.trim()) {
        this.error = 'Output text is empty.';
        return;
      }
      this.error = null;
      switch (caseType) {
        case 'upper':
          this.outputText = this.outputText.toUpperCase();
          break;
        case 'lower':
          this.outputText = this.outputText.toLowerCase();
          break;
        case 'capitalize':
          this.outputText = this.outputText.replace(/\b\w/g, char => char.toUpperCase());
          break;
      }
    },
    copyToClipboard() {
      if (!this.outputText.trim()) {
        this.error = 'Output text is empty.';
        return;
      }
      this.error = null;
      navigator.clipboard.writeText(this.outputText).then(() => {
        this.copyButtonText = 'Copied to Clipboard';
        setTimeout(() => {
          this.copyButtonText = 'Copy to Clipboard';
        }, 2000);
      });
    }
  }
}
</script>

<style scoped>
  .custom-container {
    max-width: 85vh;
    margin: auto;
  }

  label {
    font-size: 16px;
  }

  textarea {
    overflow: hidden;
    resize: none;
  }

  textarea.form-control {
    font-size: 18px;
  }
</style>