<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Gravitational Force Calculator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is gravitational force?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Gravitational force, also known as gravity, is the natural force of attraction that exists between all objects with mass or energy. It is responsible for the phenomenon of objects being pulled towards one another and plays a fundamental role in the motion of celestial bodies, such as planets, stars, and galaxies.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>What is the gravity equation?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The gravity equation, often referred to as Newton's law of universal gravitation, is expressed as:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    F = (G * m1 * m2) / r^2
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Where:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>F is the gravitational force between two objects,</li>
                                    <li>G is the gravitational constant,</li>
                                    <li>m1 and m2 are the masses of the two objects, and</li>
                                    <li>r is the distance between the centers of the two objects.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>How do I calculate gravitational force?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To calculate gravitational force, you can use the gravity equation mentioned above. Plug in the values of the gravitational constant (G), the masses of the two objects (m1 and m2), and the distance between them (r) into the equation to find the force of attraction between the objects.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>Illustrate this with an example</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Sure! Let's say you want to calculate the gravitational force between two objects:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Mass of Object 1 (m1) = 5 kg<br>
                                    Mass of Object 2 (m2) = 8 kg<br>
                                    Distance (r) between them = 10 meters<br>
                                    Gravitational Constant (G) = 6.67430e-11 m^3 kg^-1 s^-2
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Using the gravity equation:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    F = (G * m1 * m2) / r^2
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Substitute the values:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    F = (6.67430e-11 * 5 * 8) / (10^2) = 2.13472e-10 Newtons (N)
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    So, the gravitational force between these two objects is approximately 2.13472e-10 N.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>What is the gravitational force between the Earth and the Moon?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The gravitational force between the Earth and the Moon is approximately 1.982 x 10^20 Newtons (N). This force is responsible for the Moon's orbit around the Earth and the tides on Earth.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Does the gravitational force of the planets affect humans?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, the gravitational force of planets, including Earth, affects all objects with mass, including humans. This force is what keeps us anchored to the Earth's surface and gives us weight. Without gravity, we would float in space. The strength of the gravitational force on an object depends on its mass and the mass of the planet it is on.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'GravitationalForceCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
