<template>
  <BreadCrumb PageTitle="Paint Ratio Calculator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Paint Ratio Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Discover perfect paint mixes with our Paint Ratio Calculator – your quick, easy solution for precise paint-to-thinner ratios. Mix like a pro now!
    </p>
    
    <div>
      <div class="input-group mb-4">
        <span class="input-group-text" id="partsBaseLabel">Parts Base:</span>
        <input
          type="number"
          class="form-control"
          v-model.number="partsBase"
          aria-describedby="partsBaseLabel"
          placeholder="Enter base parts"
          min="0"
        >
      </div>
      <div class="input-group mb-4">
        <span class="input-group-text" id="partsThinnerLabel">Parts Thinner:</span>
        <input
          type="number"
          class="form-control"
          v-model.number="partsThinner"
          aria-describedby="partsThinnerLabel"
          placeholder="Enter thinner parts"
          min="0"
        >
      </div>
    </div>
    
    <div v-if="alert" class="alert alert-danger" role="alert">{{ alert }}</div>
    
    <div class="row mb-4">
      <div class="col-md-6 mb-3">
        <button @click="calculateRatio" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Calculate Paint Ratio">
          Calculate
        </button>
      </div>
      <div class="col-md-6 mb-3">
        <button @click="reset" class="btn btn-danger w-100" data-bs-toggle="tooltip" title="Reset">
          Reset
        </button>
      </div>
    </div>
    
    <div class="form-group mb-3">
      <label for="paintResult" class="form-label">Paint Ratio Result</label>
      <div
        id="paintResult"
        class="form-control p-4 fs-5"
        readonly
        tabindex="0"
        aria-live="polite"
      >
        {{ paintResult }}
      </div>
    </div>
    
    <div>
      <PaintRatioCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import PaintRatioCalculatorFAQ from '../FAQs/paintRatioCalculatorFAQ.vue';

export default {
  name: 'PaintRatioCalculator',
  components: {
    BreadCrumb,
    PaintRatioCalculatorFAQ
  },
  setup() {
    const partsBase = ref('');
    const partsThinner = ref('');
    const paintResult = ref('');
    const alert = ref('');

    const calculateRatio = () => {
      alert.value = '';
      const base = parseFloat(partsBase.value);
      const thinner = parseFloat(partsThinner.value);

      if (isNaN(base) || isNaN(thinner)) {
        alert.value = 'Please enter valid numbers for both parts.';
        paintResult.value = '';
        return;
      }

      if (base <= 0 || thinner <= 0) {
        alert.value = 'Parts must be greater than zero.';
        paintResult.value = '';
        return;
      }

      const gcd = (a, b) => b === 0 ? a : gcd(b, a % b);
      const divisor = gcd(base, thinner);
      paintResult.value = `${base / divisor}:${thinner / divisor}`;
    };

    const reset = () => {
      partsBase.value = '';
      partsThinner.value = '';
      paintResult.value = '';
      alert.value = '';
    };

    return {
      partsBase,
      partsThinner,
      paintResult,
      alert,
      calculateRatio,
      reset
    };
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.form-label {
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.form-control {
  font-size: 16px;
}

.alert {
  font-size: 14px;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
