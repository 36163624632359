<template>
  <BreadCrumb PageTitle="Advanced Statistics Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Advanced Statistics Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter multiple numbers separated by commas or spaces, and this tool will calculate statistical information such as Mean, Median, Mode, Range, Minimum, Maximum, Count, and Sum.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Numbers -->
        <div class="mb-3">
          <label for="numbers" class="form-label">Numbers:</label>
          <input
            type="text"
            id="numbers"
            v-model="numberInput"
            class="form-control"
            placeholder="e.g., 1, 2, 3 or 1 2 3"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateStatistics" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Results:</h4>
        <p v-if="result !== null" class="fs-5">
          <strong>Mean:</strong> {{ result.mean }} <br>
          <strong>Median:</strong> {{ result.median }} <br>
          <strong>Mode:</strong> {{ result.mode }} <br>
          <strong>Range:</strong> {{ result.range }} <br>
          <strong>Minimum:</strong> {{ result.min }} <br>
          <strong>Maximum:</strong> {{ result.max }} <br>
          <strong>Count:</strong> {{ result.count }} <br>
          <strong>Sum:</strong> {{ result.sum }}
        </p>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter multiple numbers separated by commas or spaces (e.g., 1, 2, 3 or 1 2 3).</li>
        <li>You can include decimal values as well (e.g., 2.5, 3.75).</li>
        <li>Click "Calculate" to get the statistical information of the entered numbers.</li>
      </ul>
      <h5 class="alert-heading">Formulas for Calculating Statistics:</h5>
      <p class="fs-6">
        <strong>Mean:</strong> (Sum of all numbers) / (Total count of numbers) <br>
        <strong>Median:</strong> Middle value of the sorted list. If the count is even, it's the average of the two middle numbers. <br>
        <strong>Mode:</strong> The most frequently occurring number(s). <br>
        <strong>Range:</strong> Maximum value - Minimum value. <br>
        <strong>Minimum:</strong> The smallest number in the list. <br>
        <strong>Maximum:</strong> The largest number in the list. <br>
        <strong>Count:</strong> Total number of entries. <br>
        <strong>Sum:</strong> Total sum of all numbers.
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const numberInput = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate statistical values
    const calculateStatistics = () => {
      error.value = "";
      result.value = null;

      // Check if the input is empty
      if (!numberInput.value.trim()) {
        error.value = "Please enter some numbers to calculate the statistics.";
        return;
      }

      try {
        // Split the input into an array of numbers
        const numbers = numberInput.value
          .split(/[\s,]+/) // Split by comma or space
          .map((num) => parseFloat(num.trim())) // Convert each part to a float
          .filter((num) => !isNaN(num)); // Filter out any non-numeric values

        // Check if there are any valid numbers
        if (numbers.length === 0) {
          throw new Error("Invalid input. Please enter valid numbers.");
        }

        // Calculate statistical values
        const sum = numbers.reduce((acc, curr) => acc + curr, 0);
        const mean = (sum / numbers.length).toFixed(2);
        const sortedNumbers = numbers.slice().sort((a, b) => a - b);
        const median =
          numbers.length % 2 === 0
            ? ((sortedNumbers[numbers.length / 2 - 1] + sortedNumbers[numbers.length / 2]) / 2).toFixed(2)
            : sortedNumbers[Math.floor(numbers.length / 2)].toFixed(2);
        const mode = calculateMode(numbers);
        const range = (Math.max(...numbers) - Math.min(...numbers)).toFixed(2);
        const min = Math.min(...numbers).toFixed(2);
        const max = Math.max(...numbers).toFixed(2);
        const count = numbers.length;

        // Set result
        result.value = {
          mean,
          median,
          mode,
          range,
          min,
          max,
          count,
          sum: sum.toFixed(2),
        };
      } catch (e) {
        error.value = "Please enter valid numbers separated by commas or spaces.";
      }
    };

    // Function to calculate mode
    const calculateMode = (numbers) => {
      const frequency = {};
      let maxFrequency = 0;
      let modes = [];

      numbers.forEach((num) => {
        frequency[num] = (frequency[num] || 0) + 1;
        if (frequency[num] > maxFrequency) {
          maxFrequency = frequency[num];
        }
      });

      for (const num in frequency) {
        if (frequency[num] === maxFrequency) {
          modes.push(Number(num));
        }
      }

      return modes.length === numbers.length ? "No mode" : modes.join(", ");
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      numberInput.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      numberInput,
      result,
      error,
      calculateStatistics,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
