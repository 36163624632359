<template>
  <BreadCrumb PageTitle="JSON Escape / Unescape" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">JSON Escape / Unescape</h1>
    <p class="fs-6 text-center mb-4">Struggling with messy data? Effortlessly remove escape characters from strings and unescape with our user-centric JSON tool. Simplify, decode, and enjoy!</p>
    
    <div class="form-group mb-4">
      <label for="jsonInput">Enter Text</label>
      <textarea v-model="jsonInput" class="form-control" id="jsonInput" rows="4"></textarea>
    </div>
    
    <div class="form-group mb-4">
      <div class="input-group mb-3">
        <span class="input-group-text">JSON Type:</span>
        <select v-model="jsonType" class="form-control" id="jsonType">
          <option value="json-escaper">Escape</option>
          <option value="json-unescaper">Unescape</option>
        </select>
      </div>
    </div>
    <div id="alert" class="alert alert-danger" role="alert" v-if="alertMessage">{{ alertMessage }}</div>
    <div class="row">
      <div class="col-md-6 mb-4">
        <button @click="validateJSON" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Validate JSON">Validate</button>
      </div>
      <div class="col-md-6 mb-2">
        <button @click="reset" class="btn btn-danger w-100" data-bs-toggle="tooltip" title="Reset">Reset</button>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="validatedJSON">Validated JSON</label>
      <textarea v-model="validatedJSON" ref="validatedJSON" class="form-control" id="validatedJSON" rows="4" readonly></textarea>
    </div>
    
    <div class="mb-4">
      <button @click="copyToClipboard" class="btn btn-secondary w-100" data-bs-toggle="tooltip" title="Copy Output">Copy Text</button>
    </div>

    <div>
      <JSONEscapeUnescapeFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import JSONEscapeUnescapeFAQ from '../FAQs/jsonEscapeUnescapeFAQ.vue';

export default {
  components: {
    BreadCrumb,
    JSONEscapeUnescapeFAQ
  },
  data() {
    return {
      jsonInput: '',
      jsonType: 'json-escaper',
      validatedJSON: '',
      alertMessage: ''
    };
  },
  methods: {
    validateJSON() {
      try {
        if (this.jsonType === 'json-escaper') {
          this.validatedJSON = JSON.stringify(this.jsonInput);
        } else {
          // Unescape JSON
          let unescapedString = this.jsonInput.replace(/\\n/g, '\n').replace(/\\"/g, '"').replace(/\\t/g, '\t');
          this.validatedJSON = JSON.stringify(JSON.parse(unescapedString), null, 2);
        }
        this.alertMessage = '';
      } catch (e) {
        this.alertMessage = 'Invalid JSON input!';
      }
    },
    reset() {
      this.jsonInput = '';
      this.validatedJSON = '';
      this.alertMessage = '';
    },
    copyToClipboard() {
      // Focus and select the validated JSON text area content
      const textarea = this.$refs.validatedJSON;
      textarea.focus();
      textarea.select();

      // Use the Clipboard API to copy the content
      navigator.clipboard.writeText(this.validatedJSON).then(() => {
        // Optionally, show some visual feedback that copying was successful
        console.log('Copied to clipboard');
      }, (err) => {
        console.error('Failed to copy: ', err);
      });
    }
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    padding: 0 0.5rem;
  }
}
label, textarea {
  font-size: 16px;
}
</style>