<template>
<div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
  <div class="card-body p-15 p-sm-20">
    <div class="accordion" id="faqAccordion">
      <div class="row">
        <div class="text-center mb-3"><h3>FAQs on JSON Escape / Unescape Tool</h3></div>
        
        <div class="accordion-item rounded-0 border-0">
          <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
            <h5>What is JSON Escaping?</h5>
          </button>
          <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
            <div class="accordion-body pb-0">
              <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                JSON escaping involves replacing certain characters in a JSON string with escape sequences to ensure they are treated as data, not control characters. This process makes a JSON string safe for embedding inside other strings or for transmitting over a network. Typical characters that need to be escaped in JSON include quotes (`"`), backslashes (`\`), and control characters like newline (`\n`).
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item rounded-0 border-0">
          <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="false" aria-controls="faqCollapseTwo">
            <h5>How to Use the JSON Escape/Unescape Tool</h5>
          </button>
          <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
            <div class="accordion-body pb-0">
              <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                This tool provides a user-friendly interface to easily escape or unescape your JSON strings.
                <br><br>
                <strong>1. Enter Text:</strong> Start by typing or pasting the JSON string that you wish to escape or unescape into the "Enter Text" textarea.
                <br><br>
                <strong>2. Choose Operation:</strong> Use the "JSON Type" dropdown to select the desired operation:
                <br>
                <strong>Escape:</strong> Converts special characters in your JSON to their escape sequences.
                <br>
                <strong>Unescape:</strong> Converts escape sequences in your JSON back to their actual characters.
                <br><br>
                <strong>3. Validate the JSON:</strong> Click on the "Validate" button. The tool will process your input based on the selected operation and display the result in the "Validated JSON" textarea.
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item rounded-0 border-0">
          <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="false" aria-controls="faqCollapseThree">
            <h5>What is JSON Unescaping?</h5>
          </button>
          <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
            <div class="accordion-body pb-0">
              <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                JSON unescaping refers to the process of converting escaped sequences back to their original characters in a JSON string. During the process of JSON escaping, certain characters in a JSON string are replaced with escape sequences to ensure they're interpreted as data, not control characters. This is especially important for characters that have special meanings in JSON, such as quotes (`"`) or backslashes (`\`).
                <br><br>
                When you want to retrieve the original string, you perform the unescaping process. This means converting escape sequences back into the characters they represent. For instance, an escaped JSON string `"{\"name\":\"John\"}"` would be unescaped to `{"name":"John"}`. In the escaped version, the double quotes are preceded by backslashes (`\"`), which is the escape sequence for a double quote in JSON. The unescaping process removes these backslashes, restoring the original character.
                <br><br>
                Why might you need to unescape JSON? There are a few scenarios:
                <br>
                - <strong>Reading Data:</strong> If you've stored or received escaped JSON, you'll need to unescape it to read and use the data properly.
                <br>
                - <strong>Embedding JSON:</strong> If you're embedding JSON inside other strings (e.g., inside a JavaScript or HTML string), it might be escaped to ensure it doesn't break the surrounding code. To work with this JSON data in its original format, you'd first unescape it.
                <br>
                - <strong>Data Transmission:</strong> Sometimes, for reasons of data integrity during transmission or storage, JSON might be escaped. Before processing this data, it would need to be unescaped.
                <br><br>
                It's important to note that not all strings with escape sequences are valid JSON. The process of unescaping is mechanical, converting known escape sequences back to characters, but the resulting string must still conform to JSON syntax to be parsed as such.
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item rounded-0 border-0">
          <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="false" aria-controls="faqCollapseFour">
            <h5>Tips and Tricks</h5>
          </button>
          <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
            <div class="accordion-body pb-0">
              <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                - While most characters can be escaped and unescaped, some special characters or symbols might not have a direct escape sequence and will remain unchanged.
                <br>
                - The tool is designed to be intuitive and user-friendly, so feel free to experiment and have fun!
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
  </template>
  
  <script>
  export default {
    name: 'JSONEscapeUnescapeFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  