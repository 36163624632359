<template>
  <BreadCrumb PageTitle="Word Ladder Generator" />
  <div class="custom-container mt-5 mb-50">
    <h1 class="text-center">Word Ladder Generator</h1>
    <p class="fs-6 text-center mb-4">
      Discover and explore word connections with our Word Ladder Generator – a fun, interactive tool to bridge words through creative puzzles.
    </p>
    <div class="row">
      <div class="input-group mb-3">
        <label class="input-group-text" for="start">Start Word:</label>
        <input v-model="startWord" type="text" class="form-control" id="start" required>
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text pe-3" for="end">End Word:</label>
        <input v-model="endWord" type="text" class="form-control" id="end" required>
      </div>
      <div v-if="error" class="alert alert-danger mt-3" role="alert">
        {{ error }}
      </div>
      <div>
        <button class="btn btn-primary mb-4 w-100" @click="generateLadder">Generate Ladder</button>
      </div>
    </div>
    <div class="col mb-4 fs-6">
      <label for="outputLadder">Output Ladder</label>
      <textarea 
        v-model="outputLadder" 
        id="outputLadder" 
        class="form-control fs-4" 
        rows="5" 
        readonly
        :class="{ 'highlight': isHighlighted }"
      ></textarea>
    </div>
    <button 
      class="btn btn-danger mb-4 w-100" 
      @click="copyToClipboard"
      :disabled="isCopying"
    >
      {{ copyButtonText }}
    </button>

    <!-- FAQ Section -->
    <div class="mt-5">
      <WordLadderGeneratorFAQ />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed } from 'vue';
import { kWords } from '../../components/JS/ScrabbleWords.js';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import WordLadderGeneratorFAQ from '../FAQs/wordLadderGeneratorFAQ.vue';

// Queue implementation
class Queue<T> {
  private inStack: T[] = [];
  private outStack: T[] = [];

  enqueue(element: T): void {
    this.inStack.push(element);
  }

  dequeue(): T {
    if (this.outStack.length !== 0) return this.outStack.pop()!;
    if (this.inStack.length === 0) throw new Error("Dequeue from an empty stack.");
    while (this.inStack.length !== 0) this.outStack.push(this.inStack.pop()!);
    return this.outStack.pop()!;
  }

  length(): number {
    return this.inStack.length + this.outStack.length;
  }
}

// WordLadder type
type WordLadder = {
  word: string;
  previous: WordLadder | null;
};

// WordLadder functions
const createWordLadder = (word: string, previous: WordLadder | null = null): WordLadder => ({
  word,
  previous
});

const lastWord = (ladder: WordLadder): string => ladder.word;

const extendLadder = (ladder: WordLadder, word: string): WordLadder => 
  createWordLadder(word, ladder);

const ladderToArray = (ladder: WordLadder): string[] => {
  const result: string[] = [];
  let current: WordLadder | null = ladder;
  while (current !== null) {
    result.push(current.word);
    current = current.previous;
  }
  return result.reverse();
};

// Successors function
function findSuccessors(word: string): string[] {
  const result: string[] = [];
  for (let i = 0; i < word.length; ++i) {
    for (let ch = 'a'.charCodeAt(0); ch <= 'z'.charCodeAt(0); ++ch) {
      const candidate = word.substring(0, i) + String.fromCharCode(ch) + word.substring(i + 1);
      if (candidate in kWords) result.push(candidate);
    }
  }
  return result;
}

// findWordLadder function
function findWordLadder(startWord: string, endWord: string): string[] | undefined {
  const workList = new Queue<WordLadder>();
  workList.enqueue(createWordLadder(startWord));
  const usedWords = new Set<string>();

  while (workList.length() !== 0) {
    const ladder = workList.dequeue();
    if (usedWords.has(lastWord(ladder))) continue;
    usedWords.add(lastWord(ladder));
    if (lastWord(ladder) === endWord) return ladderToArray(ladder);
    const successors = findSuccessors(lastWord(ladder));
    for (const successor of successors) {
      workList.enqueue(extendLadder(ladder, successor));
    }
  }

  return undefined;
}

export default {
  name: 'WordLadderGenerator',
  components: {
    BreadCrumb,
    WordLadderGeneratorFAQ
  },
  setup() {
    const startWord = ref('');
    const endWord = ref('');
    const outputLadder = ref('');
    const error = ref('');
    const isCopying = ref(false);
    const isHighlighted = ref(false);

    const copyButtonText = computed(() => isCopying.value ? 'Copied!' : 'Copy');

    const generateLadder = () => {
      error.value = '';
      outputLadder.value = '';

      const start = startWord.value.trim().toLowerCase();
      const end = endWord.value.trim().toLowerCase();

      if (start.length !== end.length) {
        error.value = "Start and end words must have the same length.";
        return;
      }
      
      if (!(start in kWords) || !(end in kWords)) {
        error.value = 'One of the words is not in our dictionary.';
        return;
      }

      const ladder = findWordLadder(start, end);
      if (ladder === undefined) {
        error.value = `No ladder found from ${start} to ${end}`;
      } else {
        outputLadder.value = ladder.join(" → ");
      }
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(outputLadder.value)
        .then(() => {
          isCopying.value = true;
          isHighlighted.value = true;
          setTimeout(() => {
            isCopying.value = false;
            isHighlighted.value = false;
          }, 2000);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    };

    return {
      startWord,
      endWord,
      outputLadder,
      error,
      generateLadder,
      copyToClipboard,
      isCopying,
      isHighlighted,
      copyButtonText
    };
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}

#outputLadder {
  transition: background-color 0.3s ease;
}

button {
  transition: background-color 0.3s ease;
}

button:disabled {
  cursor: not-allowed;
}
</style>