<template>
  <BreadCrumb PageTitle="GCF Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">GCF Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two or more integers to calculate their Greatest Common Factor (GCF). The calculator will show the GCF along with the calculation steps and optionally the prime factorization of each number.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Integers -->
        <div class="mb-3">
          <label for="integers" class="form-label">Enter Integers (comma separated):</label>
          <input
            type="text"
            id="integers"
            v-model="integersInput"
            class="form-control"
            placeholder="e.g., 48, 180, 72"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateGCF" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Integers: {{ result.integers }}</p>
          <p class="fs-5">GCF: {{ result.gcf }}</p>
          <p class="fs-5">Calculation Steps: {{ result.steps }}</p>
          <p class="fs-5" v-if="result.primeFactors">
            Prime Factorization of Each Number:
            <ul>
              <li v-for="(factors, number) in result.primeFactors" :key="number">
                {{ number }}: {{ factors.join(" × ") }}
              </li>
            </ul>
          </p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter two or more integers separated by commas (e.g., 48, 180, 72).</li>
        <li>Click "Calculate" to compute the GCF of the entered integers.</li>
        <li>The result will show the entered integers, the GCF, and the calculation steps.</li>
        <li>Click "Clear" to reset the input field and result.</li>
      </ul>

      <h5 class="alert-heading">What is GCF?</h5>
      <p class="fs-6">
        The Greatest Common Factor (GCF) of two or more integers is the largest integer that divides all the given numbers without leaving a remainder.
      </p>

      <h5 class="alert-heading">Formula for GCF using Euclidean Algorithm:</h5>
      <div class="fs-6">
        For two integers \( a \) and \( b \), the GCF is calculated as follows:
      </div>
      <div>
        \[
        \text{GCF}(a, b) = \text{GCF}(b, a \% b)
        \]
      </div>
      <p class="fs-6">
        The process is repeated until \( b = 0 \). At this point, the GCF is \( a \).
      </p>

      <h5 class="alert-heading">Formula for GCF using Prime Factorization:</h5>
      <div class="fs-6">
        The GCF of two or more numbers is the product of the lowest power of all common prime factors.
      </div>
      <div>
        <p class="fs-6">
          For example, the GCF of 48 and 180 is calculated as follows:
        </p>
        <ul class="fs-6">
          <li>48 = 2 × 2 × 2 × 2 × 3</li>
          <li>180 = 2 × 2 × 3 × 3 × 5</li>
          <li>Common prime factors: 2 and 3</li>
          <li>Lowest power of 2 = 2 × 2 = 4</li>
          <li>Lowest power of 3 = 3</li>
          <li>GCF = 4 × 3 = 12</li>
        </ul>
      </div>

      <h5 class="alert-heading">Example Calculation:</h5>
      <ul class="fs-6">
        <li>
          For \( \text{GCF}(48, 180) \):
          <ul>
            <li>Using Euclidean Algorithm:</li>
            <li>180 % 48 = 36</li>
            <li>48 % 36 = 12</li>
            <li>36 % 12 = 0</li>
            <li>GCF = 12</li>
          </ul>
        </li>
        <li>
          For \( \text{GCF}(48, 180, 72) \):
          <ul>
            <li>Using Euclidean Algorithm:</li>
            <li>GCF(48, 180) = 12</li>
            <li>GCF(12, 72) = 12</li>
            <li>GCF = 12</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const integersInput = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate GCF of multiple integers
    const calculateGCF = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the integers from the input
        const integers = parseIntegers(integersInput.value);

        if (integers.length < 2) throw new Error("Please enter at least two integers.");

        // Calculate GCF of the integers
        const { gcf, steps, primeFactors } = gcfCalculation(integers);

        result.value = {
          integers: integers.join(", "),
          gcf: gcf,
          steps: steps,
          primeFactors: primeFactors,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to parse integers from a comma-separated string
    const parseIntegers = (input) => {
      return input
        .split(",")
        .map((num) => parseInt(num.trim()))
        .filter((num) => !isNaN(num));
    };

    // Function to calculate GCF using the Euclidean algorithm and prime factorization
    const gcfCalculation = (nums) => {
      let steps = "";
      let gcf = nums[0];
      let primeFactors = {};

      steps += `GCF(${nums.join(", ")})\n`;

      for (let i = 1; i < nums.length; i++) {
        gcf = findGCF(gcf, nums[i], steps);
      }

      nums.forEach((num) => {
        primeFactors[num] = primeFactorize(num);
      });

      return { gcf, steps: steps.trim(), primeFactors };
    };

    // Function to find GCF of two numbers using the Euclidean algorithm
    const findGCF = (a, b, steps) => {
      let originalA = a;
      let originalB = b;
      while (b !== 0) {
        let remainder = a % b;
        steps += `GCF(${originalA}, ${originalB}) = GCF(${b}, ${remainder})\n`;
        a = b;
        b = remainder;
      }
      return a;
    };

    // Function to perform prime factorization of a number
    const primeFactorize = (num) => {
      const factors = [];
      let divisor = 2;

      while (num >= 2) {
        if (num % divisor === 0) {
          factors.push(divisor);
          num = num / divisor;
        } else {
          divisor++;
        }
      }

      return factors;
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      integersInput.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      integersInput,
      result,
      error,
      calculateGCF,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
