<template>
  <BreadCrumb :PageTitle="pageTitle" />

  <div class="custom-container mt-5">
    <h1 class="text-center">{{ pageTitle }}</h1>
    <p class="fs-6 text-center mb-4">
      Enter the divisor and the result (quotient) to find out the number that, when divided by the divisor, equals the quotient.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Divisor -->
        <div class="mb-3">
          <label for="divisor" class="form-label">Enter the Divisor:</label>
          <input
            type="number"
            id="divisor"
            v-model.number="divisor"
            class="form-control"
            placeholder="e.g., 4"
          />
        </div>

        <!-- Input for Quotient -->
        <div class="mb-3">
          <label for="quotient" class="form-label">Enter the Quotient (Result):</label>
          <input
            type="number"
            id="quotient"
            v-model.number="quotient"
            class="form-control"
            placeholder="e.g., 6"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateDividend" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Results:</h4>
        <div v-if="result !== null">
          <p class="fs-5">The number that, when divided by {{ divisor }} equals {{ quotient }} is <strong>{{ result }}</strong>.</p>
          <p class="fs-5" v-if="explanation !== ''">{{ explanation }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the divisor (number to divide by) in the first input field.</li>
        <li>Enter the quotient (result of the division) in the second input field.</li>
        <li>Click "Calculate" to find the number that, when divided by the divisor, equals the quotient.</li>
        <li>The result and an explanation of the calculation will be displayed below.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Formula:</h5>
      <p class="fs-6">
        The formula for finding the number (dividend) that when divided by a given divisor results in the quotient is:
      </p>
      <div>
        \[
        \text{Dividend} = \text{Divisor} \times \text{Quotient}
        \]
      </div>
      <p class="fs-6">
        Where:
        <ul>
          <li><strong>Dividend:</strong> The number you are looking for.</li>
          <li><strong>Divisor:</strong> The number you are dividing by.</li>
          <li><strong>Quotient:</strong> The result of the division.</li>
        </ul>
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <ul class="fs-6">
        <li>For "What divided by 4 equals 6":</li>
        <ul class="fs-6">
          <li>The divisor is \( 4 \) and the quotient is \( 6 \).</li>
          <li>Using the formula \( 4 \times 6 = 24 \), the dividend is \( 24 \).</li>
        </ul>
        <li>For "What divided by 2 equals 5":</li>
        <ul class="fs-6">
          <li>The divisor is \( 2 \) and the quotient is \( 5 \).</li>
          <li>Using the formula \( 2 \times 5 = 10 \), the dividend is \( 10 \).</li>
        </ul>
      </ul>

      <h5 class="alert-heading">Important Notes:</h5>
      <ul class="fs-6">
        <li>Ensure both the divisor and quotient are valid numbers.</li>
        <li>If the divisor is zero, the result will be zero as multiplying by zero always gives zero.</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    
    const divisor = ref(null);
    const quotient = ref(null);
    const result = ref(null);
    const explanation = ref("");
    const error = ref("");
    const pageTitle = ref("What Divided By Calculator");

    // Function to calculate the dividend
    const calculateDividend = () => {
      error.value = "";
      result.value = null;
      explanation.value = "";

      try {
        // Check if the inputs are valid numbers
        if (divisor.value === null || quotient.value === null) {
          throw new Error("Please enter valid numbers for both divisor and quotient.");
        }

        // Calculate the result (dividend)
        result.value = (divisor.value * quotient.value).toFixed(2);

        // Generate explanation
        explanation.value = `The calculation is performed as follows: ${divisor.value} multiplied by ${quotient.value} equals ${result.value}. This means that ${result.value} divided by ${divisor.value} equals ${quotient.value}.`;

        // Update the URL dynamically with new parameters
        router.push({
          name: 'WhatDividedByCalculator',
          params: {
            divisor: divisor.value,
            equals: 'equals',
            quotient: quotient.value
          }
        });
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      divisor.value = null;
      quotient.value = null;
      result.value = null;
      explanation.value = "";
      error.value = "";
    };

    // Function to populate fields based on URL parameters
    const loadFromRouteParams = () => {
      const divisorParam = parseFloat(route.params.divisor);
      const quotientParam = parseFloat(route.params.quotient);

      if (!isNaN(divisorParam) && !isNaN(quotientParam)) {
        divisor.value = divisorParam;
        quotient.value = quotientParam;
        calculateDividend();
      } else {
        clearValues();
      }
    };

    onMounted(() => {
      loadFromRouteParams();
      if (route.params.divisor && route.params.quotient) {
        pageTitle.value = `What divided by ${route.params.divisor} equals ${route.params.quotient}`;
      } else {
        pageTitle.value = "What Divided By Calculator";
      }
    });

    // Watch for route changes to handle dynamic updates
    watch(route, () => {
      loadFromRouteParams();
      if (route.params.divisor && route.params.quotient) {
        pageTitle.value = `What divided by ${route.params.divisor} equals ${route.params.quotient}`;
      } else {
        pageTitle.value = "What Divided By Calculator";
      }
    });

    return {
      divisor,
      quotient,
      result,
      explanation,
      error,
      pageTitle,
      calculateDividend,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
