<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Five Number Summary Calculator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the Five Number Summary Calculator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Five Number Summary Calculator is a powerful tool for analyzing a dataset's central tendencies and spread. It provides a concise summary of essential statistics, which are crucial for understanding your data's distribution and variability.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>What are the key statistics provided by this calculator?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This calculator provides the following key statistics:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>Count:</strong> The total number of data points in your dataset.</li>
                                    <li><strong>Sum:</strong> The sum of all data points in your dataset.</li>
                                    <li><strong>Mean (Average):</strong> The arithmetic mean of your dataset, calculated by dividing the sum by the count.</li>
                                    <li><strong>Minimum (Min):</strong> The smallest value in your dataset.</li>
                                    <li><strong>Q1 (25th Percentile):</strong> The first quartile, which is the value separating the lowest 25% of the data from the rest. It's also known as the 25th percentile.</li>
                                    <li><strong>Median (50th Percentile):</strong> The middle value of your dataset when it's sorted in ascending order. It's also known as the 50th percentile.</li>
                                    <li><strong>Q3 (75th Percentile):</strong> The third quartile, which is the value separating the lowest 75% of the data from the rest. It's also known as the 75th percentile.</li>
                                    <li><strong>Maximum (Max):</strong> The largest value in your dataset.</li>
                                    <li><strong>Range:</strong> The difference between the maximum and minimum values, indicating the spread of your data.</li>
                                    <li><strong>Variance:</strong> A measure of how much individual data points deviate from the mean. It's calculated as the average of the squared differences between each data point and the mean.</li>
                                    <li><strong>Standard Deviation:</strong> A measure of how spread out the data is. It's the square root of the variance and provides a way to understand the average distance between each data point and the mean.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>How to Find the Five Number Summary on a TI-84 Calculator</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Calculating the Five Number Summary on a TI-84 calculator is a straightforward process that helps you summarize the key statistics of your dataset. Follow these steps:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>Enter Your Data:</strong>
                                        <ul>
                                            <li>Press the STAT button.</li>
                                            <li>Navigate to "Edit" and press ENTER.</li>
                                            <li>Enter your data into a list (e.g., L1).</li>
                                        </ul>
                                    </li>
                                    <li><strong>Calculate the Five Number Summary:</strong>
                                        <ul>
                                            <li>Press the STAT button again.</li>
                                            <li>Navigate to "CALC" (Calculate) and press ENTER.</li>
                                            <li>Scroll down and select "1-Var Stats" for the Five Number Summary.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Choose the List:</strong>
                                        <ul>
                                            <li>Select the list containing your data (e.g., L1).</li>
                                        </ul>
                                    </li>
                                    <li><strong>View the Results:</strong>
                                        <ul>
                                            <li>Press ENTER to see the statistics, including the Five Number Summary.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Interpreting the Results:</strong>
                                        <ul>
                                            <li>The Five Number Summary will be displayed, including Minimum, Q1, Median, Q3, and Maximum values.</li>
                                            <li>Use these values to gain insights into your dataset's distribution.</li>
                                        </ul>
                                    </li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    By following these steps, you can quickly and easily calculate the Five Number Summary using your TI-84 calculator.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'FiveNumberSummaryCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
