<template>
  <BreadCrumb PageTitle="Weighted Average Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Weighted Average Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the weighted average of a set of numbers with their corresponding weights. Enter your data set and weights to compute the result.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Data Set -->
        <div class="mb-3">
          <label for="dataSet" class="form-label">Enter Numbers (separated by commas):</label>
          <textarea
            id="dataSet"
            v-model="dataSet"
            class="form-control"
            rows="3"
            placeholder="e.g., 80, 90, 75"
          ></textarea>
        </div>

        <!-- Input for Weights -->
        <div class="mb-3">
          <label for="weights" class="form-label">Enter Weights (separated by commas):</label>
          <textarea
            id="weights"
            v-model="weights"
            class="form-control"
            rows="3"
            placeholder="e.g., 0.5, 0.3, 0.2"
          ></textarea>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateWeightedAverage" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Data Set: [{{ result.dataSet.join(', ') }}]</p>
          <p class="fs-5">Weights: [{{ result.weights.join(', ') }}]</p>
          <p class="fs-5">Weighted Average: {{ result.weightedAverage.toFixed(6) }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter your data set in the first input field, separating each number with a comma.</li>
        <li>Enter the corresponding weights in the second input field, separating each weight with a comma.</li>
        <li>Click "Calculate" to compute the weighted average.</li>
        <li>The result will display the data set, weights, and the weighted average.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Understanding Weighted Average:</h5>
      <p class="fs-6">
        A weighted average is an average where each data point contributes to the final average based on its weight. It's calculated by multiplying each data point by its weight, summing these products, and then dividing by the sum of the weights.
      </p>

      <h5 class="alert-heading">Formula:</h5>
      <div>
        \[
        \text{Weighted Average} = \frac{\sum_{i=1}^{n} x_i w_i}{\sum_{i=1}^{n} w_i}
        \]
      </div>

      <h5 class="alert-heading">Example Calculation:</h5>
      <p class="fs-6">
        For the data set: 80, 90, 75 with weights 0.5, 0.3, 0.2
      </p>
      <ul class="fs-6">
        <li>Weighted Sum: \( (80 \times 0.5) + (90 \times 0.3) + (75 \times 0.2) = 40 + 27 + 15 = 82 \)</li>
        <li>Sum of Weights: \( 0.5 + 0.3 + 0.2 = 1 \)</li>
        <li>Weighted Average: \( 82 / 1 = 82 \)</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const dataSet = ref("");
    const weights = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate weighted average
    const calculateWeightedAverage = () => {
      error.value = "";
      result.value = null;

      try {
        // Ensure dataSet and weights are not empty
        if (dataSet.value.trim() === "" || weights.value.trim() === "") {
          throw new Error("Please enter both the data set and weights.");
        }

        // Parse the input values into arrays of numbers
        const dataArray = dataSet.value
          .split(",")
          .map((num) => parseFloat(num.trim()))
          .filter((num) => !isNaN(num));

        const weightArray = weights.value
          .split(",")
          .map((num) => parseFloat(num.trim()))
          .filter((num) => !isNaN(num));

        if (dataArray.length === 0 || weightArray.length === 0) {
          throw new Error("Please enter valid numeric values for both data and weights.");
        }

        if (dataArray.length !== weightArray.length) {
          throw new Error("The number of data points and weights must be the same.");
        }

        // Calculate weighted sum and sum of weights
        const weightedSum = dataArray.reduce(
          (sum, val, idx) => sum + val * weightArray[idx],
          0
        );
        const sumOfWeights = weightArray.reduce((sum, val) => sum + val, 0);

        if (sumOfWeights === 0) {
          throw new Error("The sum of the weights cannot be zero.");
        }

        // Calculate weighted average
        const weightedAverage = weightedSum / sumOfWeights;

        result.value = {
          dataSet: dataArray,
          weights: weightArray,
          weightedAverage: weightedAverage,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      dataSet.value = "";
      weights.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      dataSet,
      weights,
      result,
      error,
      calculateWeightedAverage,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
