<template>
  <BreadCrumb PageTitle="Secret Santa Generator" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">🤶 Secret Santa Generator 🦌</h1>
      <p class="fs-6 text-center mb-4">
        The perfect tool for organizing a Secret Santa gift exchange. No email or
        signup!
      </p>
      <div>
        <div class="row mb-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control fs-6"
              v-model="newParticipant"
              placeholder="Enter participant's name(s) separated by commas"
            />
            <div class="input-group-append">
              <button class="btn btn-primary" @click="addParticipants">
                Add Participant(s)
              </button>
            </div>
          </div>
          <div class="input-group">
            <input type="file" class="form-control" @change="uploadParticipants" />
          </div>
          <small class="fs-6 form-text text-muted mt-2">
            Upload a text file with names separated by newlines.
          </small>
        </div>
        <div class="row mb-4">
          <ul class="list-group">
            <li
              class="fs-6 list-group-item d-flex justify-content-between align-items-center"
              v-for="(participant, index) in participants"
              :key="index"
            >
              {{ participant }}
              <button class="btn btn-danger btn-sm" @click="removeParticipant(index)">
                Remove
              </button>
            </li>
          </ul>
        </div>
        <div class="mb-4">
          <textarea
            class="form-control fs-6"
            v-model="excludedPairs"
            placeholder="Enter names that cannot be paired together, separated by commas. For example: John-Mary, Alice-Bob"
          ></textarea>
        </div>
        <div class="my-4">
            <div class="text-center">
              <button class="btn btn-primary w-100" @click="generatePairs">
                Generate Secret Santa Pairs
              </button>
          </div>
          <div class="mt-4" v-if="pairs.length">
            <ul class="list-group">
              <li class="list-group-item fs-5" v-for="(pair, index) in pairs" :key="index">
                {{ pair.giver }} 🎅🏻 is the secret santa of {{ pair.receiver }} 🎁
                <br />
                <a :href="pair.url" target="_blank">See your pair</a>
              </li>
            </ul>
          </div>
        </div>
        <div><SecretSantaGeneratorFAQ /></div>
      </div>
    </div>
  </template>
  
  <script>
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import SecretSantaGeneratorFAQ from '../FAQs/secretSantaGeneratorFAQ.vue';

  export default {
    components: {
    BreadCrumb,
    SecretSantaGeneratorFAQ
  },
    data() {
      return {
        newParticipant: '',
        participants: [],
        pairs: [],
        excludedPairs: '',
      };
    },
    methods: {
      addParticipants() {
        let names = this.newParticipant
          .split(',')
          .map((name) => name.trim().toLowerCase())
          .filter((name) => name !== '');
        let uniqueNames = [...new Set(names)]; // Remove duplicates within the new names
  
        let duplicateNames = uniqueNames.filter((name) =>
          this.participants.map((p) => p.toLowerCase()).includes(name)
        );
  
        if (duplicateNames.length > 0) {
          alert(`The following names are already in the list: ${duplicateNames.join(', ')}`);
          return;
        }
  
        this.participants.push(...uniqueNames.map((name) => this.capitalize(name)));
        this.newParticipant = '';
      },
      removeParticipant(index) {
        this.participants.splice(index, 1);
      },
      generatePairs() {
        if (this.participants.length < 2) {
          alert('At least 2 participants are required to generate pairs.');
          return;
        }
  
        let participantsCopy = [...this.participants];
        let receivers = [...this.participants];
  
        // Parse excluded pairs
        let excludedPairsArray = this.excludedPairs
          .split(',')
          .map((pair) => pair.trim().toLowerCase().split('-').map((name) => name.trim()));
  
        const isExcluded = (giver, receiver) => {
          return excludedPairsArray.some(
            (pair) => pair.includes(giver.toLowerCase()) && pair.includes(receiver.toLowerCase())
          );
        };
  
        this.pairs = [];
        let attempts = 0;
        const maxAttempts = 100;
  
        while (this.pairs.length !== participantsCopy.length && attempts < maxAttempts) {
          this.pairs = [];
          receivers = [...this.participants];
          for (const giver of participantsCopy) {
            let validReceivers = receivers.filter(
              (receiver) => receiver !== giver && !isExcluded(giver, receiver)
            );
            if (validReceivers.length === 0) {
              break;
            }
            let receiverIndex = Math.floor(Math.random() * validReceivers.length);
            let receiver = validReceivers[receiverIndex];
            receivers = receivers.filter((r) => r !== receiver);
            this.pairs.push({ giver, receiver, url: this.generatePairUrl(giver, receiver) });
          }
          attempts++;
        }
  
        if (this.pairs.length !== participantsCopy.length) {
          alert('Could not generate valid pairs. Please check the constraints and try again.');
          this.pairs = [];
        }
      },
      capitalize(name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
      },
      uploadParticipants(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const content = e.target.result;
            let names = content
              .split('\n')
              .map((name) => name.trim().toLowerCase())
              .filter((name) => name !== '');
            let uniqueNames = [...new Set(names)]; // Remove duplicates within the new names
  
            let duplicateNames = uniqueNames.filter((name) =>
              this.participants.map((p) => p.toLowerCase()).includes(name)
            );
  
            if (duplicateNames.length > 0) {
              alert(`The following names are already in the list: ${duplicateNames.join(', ')}`);
              uniqueNames = uniqueNames.filter((name) => !duplicateNames.includes(name));
            }
  
            this.participants.push(...uniqueNames.map((name) => this.capitalize(name)));
          };
          reader.readAsText(file);
        }
      },
      generatePairUrl(giver, receiver) {
        const baseUrl = `${window.location.origin}/pair`;
        const uniqueId = `${giver}-${receiver}-${Date.now()}`;
        return `${baseUrl}/${encodeURIComponent(uniqueId)}`;
      },
    },
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}

  @media (max-width: 576px) {
  h1 {
    font-size: 1.5rem; /* Adjust the font size for small screens */
    }
  }
  </style>
  