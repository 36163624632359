<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on HTML to XML Converter</h3>
                    </div>
                    <!-- FAQ 1 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the HTML to XML Converter?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to XML Converter is a tool that converts HTML markup into a well-structured XML format. XML (eXtensible Markup Language) is widely used for storing and transporting data in a format that can be easily read and parsed by machines.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 2 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the HTML to XML Converter?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Using the HTML to XML Converter is simple:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Paste the HTML code you want to convert into the input field.</li>
                                    <li>Click the <strong>Convert</strong> button to generate the XML version of the HTML structure.</li>
                                    <li>The XML output will appear in the output field, ready for download or further processing.</li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This tool helps convert HTML elements into well-formed XML, allowing you to use or store HTML data in XML format.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 3 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What is XML?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    XML (eXtensible Markup Language) is a flexible text format used for storing and transporting structured data. It is both human-readable and machine-readable, making it ideal for exchanging data between different systems and applications.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    An example of a simple XML structure is:
                                </p>
                                <pre>
&lt;person&gt;
    &lt;name&gt;John Doe&lt;/name&gt;
    &lt;age&gt;30&lt;/age&gt;
    &lt;city&gt;New York&lt;/city&gt;
&lt;/person&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    In this example, <code>person</code> is the parent element, and <code>name</code>, <code>age</code>, and <code>city</code> are child elements containing the data values.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 4 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>What is the difference between HTML and XML?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    While both HTML and XML are markup languages, they serve different purposes:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>HTML</strong> (HyperText Markup Language) is used to structure and display content on the web. It contains predefined tags like &lt;p&gt;, &lt;div&gt;, and &lt;a&gt; for displaying text, images, and links.</li>
                                    <li><strong>XML</strong> (eXtensible Markup Language) is used to store and transport data. Unlike HTML, XML allows users to define their own tags and does not have predefined tags. XML focuses on data representation rather than presentation.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    XML is stricter than HTML, requiring proper nesting of elements, closing tags, and correct case usage. For example, in XML, <code>&lt;Person&gt;</code> and <code>&lt;person&gt;</code> would be treated as different elements.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 5 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>What kind of HTML can be converted to XML?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to XML Converter works best with well-structured HTML documents, such as those containing tables, lists, and forms. HTML with proper opening and closing tags can be converted easily to XML. For example, the following HTML table:
                                </p>
                                <pre>
&lt;table&gt;
    &lt;tr&gt;&lt;th&gt;Product&lt;/th&gt;&lt;th&gt;Price&lt;/th&gt;&lt;/tr&gt;
    &lt;tr&gt;&lt;td&gt;Book&lt;/td&gt;&lt;td&gt;$10&lt;/td&gt;&lt;/tr&gt;
&lt;/table&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Will be converted to the following XML structure:
                                </p>
                                <pre>
&lt;table&gt;
    &lt;row&gt;
        &lt;Product&gt;Book&lt;/Product&gt;
        &lt;Price&gt;$10&lt;/Price&gt;
    &lt;/row&gt;
&lt;/table&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The tool restructures HTML tags into a consistent XML format.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 6 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>How does the conversion process work?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to XML Converter reads the HTML structure and converts each element into a well-formed XML element. It ensures that each tag is properly closed, and attributes are formatted correctly for XML.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The converter handles:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Transforming HTML tags into XML elements.</li>
                                    <li>Ensuring proper nesting and closure of tags.</li>
                                    <li>Reformatting attributes in HTML to XML style (for example, self-closing tags like <code>&lt;br/&gt;</code>).</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This process ensures the converted document is valid XML, making it easy to transport or store as structured data.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 7 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>What are the limitations of the HTML to XML Converter?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to XML Converter works best with clean, well-formed HTML. However, there are some limitations:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>HTML with malformed tags (unclosed tags, improper nesting) may not convert accurately.</li>
                                    <li>Attributes in HTML need to be formatted correctly for XML (for example, using double quotes around attribute values).</li>
                                    <li>Complex structures like embedded forms or JavaScript content may not translate well into XML.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    For best results, use well-structured HTML, ensuring proper closure of all tags.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 8 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEight" aria-expanded="true" aria-controls="faqCollapseEight">
                            <h5>Why would I need to convert HTML to XML?</h5>
                        </button>
                        <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Converting HTML to XML can be useful in many scenarios where structured data is needed. XML is widely used for exchanging data between different systems and applications. For example:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>XML is often used in APIs, data feeds, and databases where data integrity and structure are important.</li>
                                    <li>If you need to transform web content (HTML) into a format that can be consumed by an XML-based system, this tool can help.</li>
                                    <li>XML is platform-independent and is used in many industries for data storage and transmission.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    By converting HTML to XML, you ensure the data can be easily parsed, transported, or used in systems that rely on structured data.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Additional FAQs as needed -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HTMLToXMLFAQ',
};
</script>

<style scoped>
  /* Add any specific styles for FAQ layout */
</style>
