<template>
  <BreadCrumb PageTitle="Complex Numbers Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Complex Numbers Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two complex numbers in the form <strong>a + bi</strong> or <strong>a - bi</strong> and select an operation to calculate. The calculator supports various operations including addition, subtraction, multiplication, division, magnitude, phase, and conjugate.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for First Complex Number -->
        <div class="mb-3">
          <label for="complex1" class="form-label">First Complex Number (z<sub>1</sub>):</label>
          <input
            type="text"
            id="complex1"
            v-model="complex1"
            class="form-control"
            placeholder="e.g., 3 + 4i"
          />
        </div>

        <!-- Input for Second Complex Number -->
        <div class="mb-3">
          <label for="complex2" class="form-label">Second Complex Number (z<sub>2</sub>):</label>
          <input
            type="text"
            id="complex2"
            v-model="complex2"
            class="form-control"
            placeholder="e.g., 1 - 2i"
          />
        </div>

        <!-- Operation Selection -->
        <div class="mb-3">
          <label for="operation" class="form-label">Operation:</label>
          <select id="operation" v-model="operation" class="form-select">
            <option value="add">Addition (z<sub>1</sub> + z<sub>2</sub>)</option>
            <option value="subtract">Subtraction (z<sub>1</sub> - z<sub>2</sub>)</option>
            <option value="multiply">Multiplication (z<sub>1</sub> * z<sub>2</sub>)</option>
            <option value="divide">Division (z<sub>1</sub> / z<sub>2</sub>)</option>
            <option value="magnitude">Magnitude |z<sub>1</sub>|</option>
            <option value="phase">Phase (arg z<sub>1</sub>)</option>
            <option value="conjugate">Conjugate (z<sub>1</sub>)</option>
          </select>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateComplex" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <p v-if="result !== null" class="fs-5">
          <strong>{{ operationLabel }}:</strong> {{ result }}
        </p>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter two complex numbers in the form "a + bi" or "a - bi".</li>
        <li>Select an operation from the dropdown menu.</li>
        <li>Click "Calculate" to perform the selected operation and see the result.</li>
        <li>The calculator supports addition, subtraction, multiplication, division, magnitude, phase, and conjugate calculations.</li>
      </ul>
      <h5 class="alert-heading">Formulas for Complex Number Operations:</h5>
      <p class="fs-6">
        <strong>Addition:</strong> (a + bi) + (c + di) = (a + c) + (b + d)i <br>
        <strong>Subtraction:</strong> (a + bi) - (c + di) = (a - c) + (b - d)i <br>
        <strong>Multiplication:</strong> (a + bi) * (c + di) = (ac - bd) + (ad + bc)i <br>
        <strong>Division:</strong> (a + bi) / (c + di) = [(a + bi)(c - di)] / (c² + d²) <br>
        <strong>Magnitude:</strong> |a + bi| = √(a² + b²) <br>
        <strong>Phase (Argument):</strong> arg(a + bi) = arctan(b / a) <br>
        <strong>Conjugate:</strong> Conjugate(a + bi) = a - bi
      </p>
      <h5 class="alert-heading">Example for Division:</h5>
<p class="fs-6">
  To calculate (3 + 2i) / (5 - 3i):
</p>
<ul class="fs-6">
  <li>1. <strong>Calculate the conjugate of the denominator</strong>: The conjugate of (5 - 3i) is (5 + 3i). The conjugate is formed by changing the sign of the imaginary part.</li>
  <li>2. <strong>Multiply the numerator and denominator by the conjugate</strong>: 
    <ul>
      <li>Numerator: (3 + 2i) × (5 + 3i)</li>
      <li>Expanding the multiplication:
        <br />
        = (3 × 5) + (3 × 3i) + (2i × 5) + (2i × 3i)
        <br />
        = 15 + 9i + 10i + 6i²
        <br />
        Since i² = -1, replace 6i² with -6:
        <br />
        = 15 + 9i + 10i - 6
        <br />
        Combine like terms:
        <br />
        = 9 + 19i
      </li>
      <li>Denominator: (5 - 3i) × (5 + 3i)
        <br />
        Expanding the multiplication:
        <br />
        = (5 × 5) + (5 × 3i) + (-3i × 5) + (-3i × 3i)
        <br />
        = 25 + 15i - 15i - 9i²
        <br />
        Since i² = -1, replace -9i² with +9:
        <br />
        = 25 + 9
        <br />
        Combine like terms:
        <br />
        = 34
      </li>
    </ul>
  </li>
  <li>3. <strong>Result of multiplication</strong>: 
    <ul>
      <li>Numerator becomes: 9 + 19i</li>
      <li>Denominator becomes: 34</li>
    </ul>
  </li>
  <li>4. <strong>Final division</strong>: 
    <ul>
      <li>Real part: 9 / 34 = 0.26 (rounded to two decimal places)</li>
      <li>Imaginary part: 19 / 34 = 0.56 (rounded to two decimal places)</li>
    </ul>
  </li>
  <li>5. <strong>Final result</strong>: 
    <ul>
      <li>(3 + 2i) / (5 - 3i) = 0.26 + 0.56i</li>
    </ul>
  </li>
</ul>

    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const complex1 = ref("");
    const complex2 = ref("");
    const operation = ref("add");
    const result = ref(null);
    const error = ref("");

    // Operation labels for display
    const operationLabels = {
      add: "Addition",
      subtract: "Subtraction",
      multiply: "Multiplication",
      divide: "Division",
      magnitude: "Magnitude",
      phase: "Phase",
      conjugate: "Conjugate",
    };

    // Computed property to get operation label
    const operationLabel = computed(() => operationLabels[operation.value]);

    // Function to calculate complex number operations
    const calculateComplex = () => {
      error.value = "";
      result.value = null;

      try {
        const z1 = parseComplexNumber(complex1.value);
        const z2 = parseComplexNumber(complex2.value);

        if (!z1) throw new Error("Invalid format for the first complex number.");
        if (!z2 && operation.value !== "magnitude" && operation.value !== "phase" && operation.value !== "conjugate") {
          throw new Error("Invalid format for the second complex number.");
        }

        switch (operation.value) {
          case "add": {
            const sumReal = z1.re + z2.re;
            const sumImaginary = z1.im + z2.im;
            result.value = `${sumReal.toFixed(0)} ${sumImaginary >= 0 ? '+' : '-'} ${Math.abs(sumImaginary).toFixed(0)}i`;
            break;
          }
          case "subtract": {
            const diffReal = z1.re - z2.re;
            const diffImaginary = z1.im - z2.im;
            result.value = `${diffReal.toFixed(0)} ${diffImaginary >= 0 ? '+' : '-'} ${Math.abs(diffImaginary).toFixed(0)}i`;
            break;
          }
          case "multiply": {
            const reMul = z1.re * z2.re - z1.im * z2.im;
            const imMul = z1.re * z2.im + z1.im * z2.re;
            result.value = `${reMul.toFixed(0)} ${imMul >= 0 ? '+' : '-'} ${Math.abs(imMul).toFixed(0)}i`;
            break;
          }
          case "divide": {
            const denom = z2.re ** 2 + z2.im ** 2;
            if (denom === 0) throw new Error("Division by zero error.");
            const reDiv = (z1.re * z2.re + z1.im * z2.im) / denom;
            const imDiv = (z1.im * z2.re - z1.re * z2.im) / denom;
            result.value = `${reDiv.toFixed(2)} ${imDiv >= 0 ? '+' : '-'} ${Math.abs(imDiv).toFixed(2)}i`;
            break;
          }
          case "magnitude": {
            const magnitude = Math.sqrt(z1.re ** 2 + z1.im ** 2);
            result.value = `|${complex1.value}| = ${magnitude.toFixed(0)}`;
            break;
          }
          case "phase": {
            const phase = Math.atan2(z1.im, z1.re) * (180 / Math.PI);
            result.value = `arg(${complex1.value}) = ${phase.toFixed(0)}°`;
            break;
          }
          case "conjugate": {
            result.value = `${z1.re.toFixed(0)} ${z1.im >= 0 ? "-" : "+"} ${Math.abs(z1.im).toFixed(0)}i`;
            break;
          }
          default:
            throw new Error("Invalid operation selected.");
        }
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to parse complex number from string
    const parseComplexNumber = (str) => {
      const match = str.match(/^([-+]?\d*\.?\d*)?\s*([-+])?\s*(\d*\.?\d*)?i$/);
      if (!match) return null;

      const real = parseFloat(match[1] || 0);
      const imaginary = parseFloat((match[2] || "+") + (match[3] || 0));
      return { re: real, im: imaginary };
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      complex1.value = "";
      complex2.value = "";
      operation.value = "add";
      result.value = null;
      error.value = "";
    };

    return {
      complex1,
      complex2,
      operation,
      result,
      error,
      operationLabel,
      calculateComplex,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
