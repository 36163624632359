<template>
  <BreadCrumb PageTitle="Adler32 Hash Generator Calculator" />
	  <div class="custom-container mt-5">
		  <h1 class="text-center">Adler-32 Hash Generator</h1>
		  <p class="fs-6 text-center mb-4">
			  Quickly generate Adler-32 hashes for your strings using our efficient and user-friendly tool.
		  </p>
		  <div class="mb-3">
			  <label for="inputTextString" class="form-label">Enter text string:</label>
			  <textarea v-model="inputText" id="inputTextString" class="form-control"></textarea>
		  </div>
		  <div class="row mb-3">
			  <div class="col-md-6 mb-2">
				  <button @click="generateHash" class="btn btn-primary w-100">Generate Hash</button>
			  </div>
			  <div class="col-md-6 mb-2">
				  <button @click="clearText" class="btn btn-danger w-100">Clear Text</button>
			  </div>
		  </div>
		  <div class="mb-3">
			  <label for="generatedHash" class="form-label">Generated Adler-32 Hash:</label>
			  <textarea v-model="generatedHash" id="generatedHash" class="form-control" rows="2" readonly></textarea>
		  </div>
		  <div class="mb-3">
			  <button @click="copyHash" class="btn btn-secondary w-100">{{ copyButtonText }}</button>
		  </div>
		  <div class="mb-3 d-flex justify-content-between">
			  <label class="form-label">Hash Size: 4 bytes (32 bits)</label>
			  <label class="form-label">Hash Length: {{ hashLength }} characters</label>
		  </div>
  
		  <!-- FAQ Section -->
		  <div class="mt-50 mb-50">
			  <h2 class="text-center mb-4">Frequently Asked Questions</h2>
			  <div class="accordion" id="faqAccordion">
				  <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
					  <h2 class="accordion-header" :id="'heading' + index">
						  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
							  {{ faq.question }}
						  </button>
					  </h2>
					  <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
						  <div class="accordion-body" v-html="faq.answer"></div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
  </template>
  
  <script>
	import BreadCrumb from "../../components/Common/BreadCrumb.vue";  
	  import { ref } from 'vue';
	  import adler32 from 'adler-32';
  
	  export default {
	  
	components: { BreadCrumb },
	  name: 'Adler32HashGenerator',
		  setup() {
			  const inputText = ref('');
			  const generatedHash = ref('');
			  const hashLength = ref(0);
			  const copyButtonText = ref('Copy Hash');
  
			  const generateHash = () => {
				  try {
					  const hashHex = adler32.str(inputText.value).toString(16).toUpperCase();
					  generatedHash.value = hashHex;
					  hashLength.value = hashHex.length;
				  } catch (error) {
					  console.error('Hash generation failed:', error);
					  generatedHash.value = `Error generating hash: ${error.message}`;
					  hashLength.value = 0;
				  }
			  };
  
			  const clearText = () => {
				  inputText.value = '';
				  generatedHash.value = '';
				  hashLength.value = 0;
			  };
  
			  const copyHash = () => {
				  navigator.clipboard.writeText(generatedHash.value)
					  .then(() => {
						  copyButtonText.value = 'Hash Copied';
						  setTimeout(() => {
							  copyButtonText.value = 'Copy Hash';
						  }, 2000);
					  })
					  .catch(err => console.error('Failed to copy: ', err));
			  };
  
			  const faqs = [
				  {
					  question: "What is Adler-32?",
					  answer: `Adler-32 is a checksum algorithm that is used to detect errors in data. It produces a 32-bit hash value and is faster but less reliable than the more complex CRC32.`
				  },
				  {
					  question: "How is the Adler-32 hash generated?",
					  answer: `Adler-32 generates a hash through the following steps:
					  <ol>
						  <li>The input message is divided into two parts, each part being processed separately.</li>
						  <li>Each part is summed and then both sums are combined to produce the final hash value.</li>
					  </ol>`
				  },
				  {
					  question: "What are the key features of Adler-32?",
					  answer: `Adler-32 offers several key features:
					  <ul>
						  <li><strong>Speed:</strong> Faster than more complex checksum algorithms like CRC32.</li>
						  <li><strong>Simplicity:</strong> Easy to implement and use.</li>
						  <li><strong>Fixed Output Size:</strong> Produces a consistent 32-bit hash value regardless of input size.</li>
					  </ul>`
				  },
				  {
					  question: "Why should I use Adler-32 over other hash functions?",
					  answer: `Adler-32 is recommended for applications where speed is more critical than high reliability. It is suitable for simple checksums and error detection in less critical data.`
				  },
				  {
					  question: "Is Adler-32 suitable for security-sensitive applications?",
					  answer: `No, Adler-32 is not suitable for security-sensitive applications due to its simplicity and vulnerability to collisions. For security-sensitive applications, consider using cryptographic hash functions like SHA-256 or SHA-512.`
				  },
				  {
					  question: "How does Adler-32 compare to CRC32?",
					  answer: `Adler-32 is faster and simpler than CRC32 but also less reliable. CRC32 provides better error detection capabilities and is recommended for more critical data integrity checks.`
				  },
				  {
					  question: "Can Adler-32 be used for file integrity checks?",
					  answer: `While Adler-32 can be used for file integrity checks, it is generally better to use more reliable checksums like CRC32 or cryptographic hash functions for this purpose.`
				  },
				  {
					  question: "What are the applications of Adler-32?",
					  answer: `Adler-32 is used in various applications, including:
					  <ul>
						  <li>Simple data integrity checks</li>
						  <li>Network data transfer validation</li>
						  <li>Checksum calculations in non-critical applications</li>
					  </ul>`
				  },
				  {
					  question: "Is Adler-32 implemented in most libraries?",
					  answer: `Yes, Adler-32 is implemented in most checksum and hashing libraries, including popular ones like zlib and others.`
				  },
				  {
					  question: "How does the Adler-32 algorithm work?",
					  answer: `The Adler-32 algorithm works by dividing the input message into two parts, summing each part, and then combining the sums to produce the final hash value. This process is simpler and faster than the polynomial division used in CRC algorithms.`
				  },
				  {
					  question: "Is this tool secure?",
					  answer: `This tool generates the Adler-32 hash entirely in your browser, ensuring that your input is not sent to any server or stored anywhere. However, for sensitive data, it is recommended to use more secure hash functions.`
				  }
			  ];
  
			  return {
				  inputText,
				  generatedHash,
				  hashLength,
				  generateHash,
				  clearText,
				  copyButtonText,
				  copyHash,
				  faqs
			  };
		  }
	  }
  </script>

  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
	  textarea.form-control {
		  font-size: 16px;
	  }
  
	  /* Dark mode styles */
	  @media (prefers-color-scheme: dark) {
		  .accordion-button {
			  background-color: #343a40;
			  color: #fff;
		  }
  
		  .accordion-button:not(.collapsed) {
			  background-color: #2b3035;
			  color: #fff;
		  }
  
		  .accordion-button::after {
			  filter: invert(1);
		  }
  
		  .accordion-body {
			  background-color: #343a40;
			  color: #fff;
		  }
  
		  /* Style for code snippets in dark mode */
		  .accordion-body code {
			  background-color: #2b3035;
			  color: #e83e8c;
			  padding: 2px 4px;
			  border-radius: 4px;
		  }
	  }
  
	  /* Light mode styles */
	  @media (prefers-color-scheme: light) {
		  .accordion-body {
			  background-color: #fff;
			  color: #212529;
		  }
  
		  /* Style for code snippets in light mode */
		  .accordion-body code {
			  background-color: #f8f9fa;
			  color: #e83e8c;
			  padding: 2px 4px;
			  border-radius: 4px;
		  }
	  }
  </style>
  