<template>
  <BreadCrumb PageTitle="HTML Validator" />
  <div class="container custom-container mt-4 mb-50">
    <h1 class="text-center">HTML Validator</h1>
    <p class="fs-6 text-center">
      Easily validate your HTML with HTML Validator. Upload or paste your code,
      get instant feedback, and ensure your markup is error-free.
    </p>
    <div class="form-group mb-4">
      <span>Upload HTML:</span>
      <div class="input-group mb-3">
        <input type="file" class="form-control" ref="fileInput" @change="handleFileUpload">
      </div>
      <div>
        <span>Paste HTML Code:</span>
      </div>
      <div>
        <Codemirror
          v-model:value="code"
          :options="cmOptions"
          border
          ref="cmRef"
          height="400"
          width="100%"
          @change="onChange"
          @input="onInput"
          @ready="onReady"
        ></Codemirror>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-6">
        <button class="btn btn-primary btn-block w-100 mb-3" @click="validateHTML">
          Validate HTML
        </button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-danger btn-block w-100" @click="clearHTML">
          Clear HTML
        </button>
      </div>
    </div>
    <div id="htmlResult" v-html="validationResult" class="mt-4"></div>
    <div>
      <HTMLValidatorFAQ />
    </div>
  </div>
</template>

<script>
import { ref } from "vue"
import "codemirror/mode/htmlmixed/htmlmixed.js"
import Codemirror from "codemirror-editor-vue3"
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import HTMLValidatorFAQ from '../FAQs/htmlValidatorFAQ.vue';

export default {
  components: { 
    Codemirror,
    BreadCrumb,
    HTMLValidatorFAQ
  },
  setup() {
    const code = ref('')
    const cmRef = ref(null)
    const fileInput = ref(null)
    const cmOptions = {
      mode: "htmlmixed",
      lineNumbers: true,
      theme: "mbo",
    }
    const validationResult = ref("")

    const onChange = (val, cm) => {
      console.log(val)
      console.log(cm.getValue())
    }

    const onInput = (val) => {
      console.log(val)
    }

    const onReady = (cm) => {
      console.log(cm.focus())
    }

    const handleFileUpload = (event) => {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          code.value = e.target.result.trim()
        }
        reader.readAsText(file)
      }
    }

    const clearHTML = () => {
      code.value = ""
      validationResult.value = ""
      if (fileInput.value) {
        fileInput.value.value = "" // Clear the file input
      }
    }

    const validateHTML = () => {
      const htmlCode = code.value;
      const formData = new FormData();
      formData.append("out", "json");
      formData.append("content", htmlCode);

      fetch("https://validator.w3.org/nu/", {
        method: "POST",
        body: formData,
        headers: {
          "User-Agent": "HTMLValidatorApp",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.messages && data.messages.length > 0) {
            let output = "";
            data.messages.forEach((message, index) => {
              output += `<div class="alert alert-danger" role="alert">`;
              output += `<p class="alert-heading fs-5"><strong>Error ${index + 1}</strong></p>`;
              output += `<p>Line ${message.lastLine}: ${message.message}</p>`;
              output += `</div>`;
            });
            validationResult.value = output;
          } else {
            validationResult.value = '<div class="alert alert-success" role="alert">No errors found!</div>';
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          validationResult.value = '<div class="alert alert-danger" role="alert">An error occurred during validation.</div>';
        });
    };

    return {
      code,
      cmRef,
      cmOptions,
      validationResult,
      handleFileUpload,
      clearHTML,
      validateHTML,
      onChange,
      onInput,
      onReady,
      fileInput,
    }
  }
}
</script>

<style scoped>
.custom-container {
  max-width: 90vh;
  margin: 0 auto;
}
#codeEditor {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

span {
  font-size: 16px;
}
</style>