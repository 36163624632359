<template>
  <BreadCrumb PageTitle="Dividing Fractions Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Dividing Fractions Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two fractions to calculate their division in both fractional and decimal form.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for First Fraction -->
        <div class="mb-3">
          <label for="fraction1" class="form-label">Fraction 1 (Numerator/Denominator):</label>
          <input
            type="text"
            id="fraction1"
            v-model="fraction1"
            class="form-control"
            placeholder="e.g., 1/2"
          />
        </div>

        <!-- Input for Second Fraction -->
        <div class="mb-3">
          <label for="fraction2" class="form-label">Fraction 2 (Numerator/Denominator):</label>
          <input
            type="text"
            id="fraction2"
            v-model="fraction2"
            class="form-control"
            placeholder="e.g., 3/4"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateDivision" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <p v-if="result !== null" class="fs-5">
          Fractional Result: {{ result.fraction }}
        </p>
        <p v-if="result !== null" class="fs-5">
          Decimal Result: {{ result.decimal }}
        </p>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter two fractions in the format Numerator/Denominator (e.g., 1/2 and 3/4).</li>
        <li>Click "Calculate" to compute the division of the two fractions.</li>
        <li>The result will be displayed as a simplified fraction and a decimal.</li>
        <li>Click "Clear" to reset the input fields and results.</li>
      </ul>

      <h5 class="alert-heading">Formula for Dividing Fractions:</h5>
      <div class="fs-6">
        The division of two fractions \( \frac{a}{b} \) and \( \frac{c}{d} \) is calculated as:
      </div>
      <div>
        \[
        \frac{a}{b} \div \frac{c}{d} = \frac{a}{b} \times \frac{d}{c} = \frac{a \cdot d}{b \cdot c}
        \]
      </div>
      <p class="fs-6">
        where \( a, b, c, \) and \( d \) are integers. Multiply the numerator of the first fraction by the denominator of the second, and the denominator of the first by the numerator of the second.
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <p class="fs-6">
        Consider the fractions \( \frac{1}{2} \) and \( \frac{3}{4} \):
      </p>
      <ul class="fs-6">
        <li>
          \(\frac{1}{2} \div \frac{3}{4} = \frac{1 \times 4}{2 \times 3} = \frac{4}{6} = \frac{2}{3}\)
        </li>
        <li>
          Decimal result: \(0.6667\)
        </li>
      </ul>
      <p class="fs-6">
        So, the division of \( \frac{1}{2} \) by \( \frac{3}{4} \) is \( \frac{2}{3} \) or \( 0.6667 \).
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const fraction1 = ref("");
    const fraction2 = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate division of two fractions
    const calculateDivision = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the fractions
        const f1 = parseFraction(fraction1.value);
        const f2 = parseFraction(fraction2.value);

        if (!f1 || !f2) throw new Error("Please enter valid fractions.");

        // Perform division
        const divisionResult = performDivision(f1, f2);
        result.value = divisionResult;
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to parse fraction string into an array [numerator, denominator]
    const parseFraction = (fraction) => {
      const parts = fraction.trim().split("/");
      if (parts.length !== 2) return null;
      const numerator = parseInt(parts[0].trim());
      const denominator = parseInt(parts[1].trim());
      if (isNaN(numerator) || isNaN(denominator) || denominator === 0) return null;
      return [numerator, denominator];
    };

    // Function to perform division of two fractions
    const performDivision = (f1, f2) => {
      const [numerator1, denominator1] = f1;
      const [numerator2, denominator2] = f2;

      // Division of two fractions is equivalent to multiplication by the reciprocal of the second fraction
      const numeratorResult = numerator1 * denominator2;
      const denominatorResult = denominator1 * numerator2;

      // Simplify the resulting fraction
      const simplifiedFraction = simplifyFraction([numeratorResult, denominatorResult]);

      // Calculate decimal result
      const decimalResult = (numeratorResult / denominatorResult).toFixed(6);

      return {
        fraction: simplifiedFraction,
        decimal: decimalResult,
      };
    };

    // Function to simplify a fraction
    const simplifyFraction = (fraction) => {
      const [numerator, denominator] = fraction;
      const gcd = greatestCommonDivisor(numerator, denominator);
      return `${numerator / gcd}/${denominator / gcd}`;
    };

    // Function to find greatest common divisor (GCD) of two numbers
    const greatestCommonDivisor = (a, b) => {
      return b === 0 ? Math.abs(a) : greatestCommonDivisor(b, a % b);
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      fraction1.value = "";
      fraction2.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      fraction1,
      fraction2,
      result,
      error,
      calculateDivision,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
