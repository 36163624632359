<template>
  <BreadCrumb PageTitle="Sha3 256 Hash Generator Calculator" />

	<div class="custom-container mt-5">
	  <h1 class="text-center">SHA3-256 Hash Generator</h1>
	  <p class="fs-6 text-center mb-4">Quickly generate SHA3-256 hashes for your strings using our efficient and user-friendly tool.</p>
	  <div class="mb-3">
		<label for="inputTextString" class="form-label">Enter text string:</label>
		<textarea v-model="inputText" id="inputTextString" class="form-control"></textarea>
	  </div>
	  <div class="row mb-3">
		<div class="col-md-6 mb-2">
		  <button @click="generateHash" class="btn btn-primary w-100">Generate Hash</button>
		</div>
		<div class="col-md-6 mb-2">
		  <button @click="clearText" class="btn btn-danger w-100">Clear Text</button>
		</div>
	  </div>
	  <div class="mb-3">
		<label for="generatedHash" class="form-label">Generated SHA3-256 Hash:</label>
		<textarea v-model="generatedHash" id="generatedHash" class="form-control" rows="2" readonly></textarea>
	  </div>
	  <div class="mb-3">
		<button @click="copyHash" class="btn btn-secondary w-100">{{ copyButtonText }}</button>
	  </div>
	  <div class="mb-3 d-flex justify-content-between">
		<label class="form-label">Hash Size: 28 bytes (224 bits)</label>
		<label class="form-label">Hash Length: {{ hashLength }} characters</label>
	  </div>
  
	  <!-- FAQ Section -->
	  <div class="mt-50 mb-50">
		<h2 class="text-center mb-4">Frequently Asked Questions</h2>
		<div class="accordion" id="faqAccordion">
		  <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
			<h2 class="accordion-header" :id="'heading' + index">
			  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
				{{ faq.question }}
			  </button>
			</h2>
			<div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
			  <div class="accordion-body" v-html="faq.answer"></div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </template>
  
  <script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

  import { ref } from 'vue';
  import { sha3_256 } from 'js-sha3';
  
  export default {
	
  components: { BreadCrumb },
name: 'SHA32564HashGenerator',
	setup() {
	  const inputText = ref('');
	  const generatedHash = ref('');
	  const hashLength = ref(0);
	  const copyButtonText = ref('Copy Hash');
  
	  const generateHash = () => {
		try {
		  const hashHex = sha3_256(inputText.value).toUpperCase();
		  generatedHash.value = hashHex;
		  hashLength.value = hashHex.length;
		} catch (error) {
		  console.error('Hash generation failed:', error);
		  generatedHash.value = `Error generating hash: ${error.message}`;
		  hashLength.value = 0;
		}
	  };
  
	  const clearText = () => {
		inputText.value = '';
		generatedHash.value = '';
		hashLength.value = 0;
	  };
  
	  const copyHash = () => {
		navigator.clipboard.writeText(generatedHash.value)
			.then(() => {
			copyButtonText.value = 'Hash Copied';
			setTimeout(() => {
				copyButtonText.value = 'Copy Hash';
			}, 2000);
			})
			.catch(err => console.error('Failed to copy: ', err));
	  };
  
	  const faqs = [
		{
			question: "What is SHA3-256?",
			answer: `SHA3-256 is a cryptographic hash function that produces a 256-bit (32-byte) hash value. It is part of the SHA-3 family, designed by the National Institute of Standards and Technology (NIST) to provide improved security and as an alternative to the SHA-2 family.`
		},
		{
			question: "How is the SHA3-256 hash generated?",
			answer: `SHA3-256 generates a hash through the following steps:
			<ol>
				<li>The input message is padded and divided into blocks.</li>
				<li>These blocks are processed through a series of permutations and bitwise operations using the Keccak-p[1600, 24] permutation.</li>
				<li>The final 256-bit hash value is extracted from the resulting state.</li>
			</ol>`
		},
		{
			question: "What makes SHA3-256 unique compared to other hash functions?",
			answer: `SHA3-256 stands out for several reasons:
			<ul>
				<li>It uses the Keccak algorithm, which has a fundamentally different structure from SHA-1 and SHA-2.</li>
				<li>It's designed to be resistant to attacks that could potentially threaten SHA-2 algorithms.</li>
				<li>SHA3-256 has a fixed output size regardless of the input length, making it suitable for a wide range of applications.</li>
				<li>It's part of the newest generation of NIST-standardized hash functions.</li>
			</ul>`
		},
		{
			question: "When should I use SHA3-256 instead of other hash functions?",
			answer: `Consider using SHA3-256 in these scenarios:
			<ul>
				<li>When you need a modern, secure hash function with a 256-bit output.</li>
				<li>In cryptographic systems that require high security and collision resistance.</li>
				<li>For digital signatures and integrity checking in high-security environments.</li>
				<li>When you want to future-proof your application against potential vulnerabilities in older hash functions.</li>
				<li>In blockchain and cryptocurrency applications that specifically call for SHA3-256.</li>
			</ul>`
		},
		{
			question: "How does this tool ensure the privacy of my data?",
			answer: `This tool prioritizes your privacy in several ways:
			<ul>
				<li>All hash computations are performed entirely in your browser.</li>
				<li>No data is ever sent to a server or stored externally.</li>
				<li>The tool uses client-side JavaScript, so your input remains on your device.</li>
				<li>We recommend using an up-to-date browser and a secure (HTTPS) connection for added security.</li>
			</ul>`
		},
		{
			question: "Can SHA3-256 be reversed to obtain the original input?",
			answer: `No, SHA3-256, like other cryptographic hash functions, is designed to be one-way:
			<ul>
				<li>It's computationally infeasible to reverse the hash to obtain the original input.</li>
				<li>The same input will always produce the same hash, but you can't derive the input from the hash.</li>
				<li>This property makes hash functions useful for password storage, data integrity checks, and digital signatures.</li>
			</ul>`
		},
		{
			question: "How does SHA3-256 compare to SHA256 in terms of security and performance?",
			answer: `SHA3-256 and SHA256 have different characteristics:
			<ul>
				<li>Security: Both are considered cryptographically secure, but SHA3-256 uses a newer design that's resistant to some theoretical attacks that might affect SHA256.</li>
				<li>Output size: Both produce a 256-bit hash, making them comparable in terms of collision resistance.</li>
				<li>Performance: SHA256 is generally faster on most current hardware, but SHA3-256 may perform better on certain platforms or in hardware implementations.</li>
				<li>Adoption: SHA256 is more widely used and supported, while SHA3-256 is gaining adoption in newer systems and specialized applications.</li>
				<li>Choice between them often depends on specific security requirements, compatibility needs, and performance considerations in your use case.</li>
			</ul>`
		}
		];
  
	  return {
		inputText,
		generatedHash,
		hashLength,
		generateHash,
		clearText,
		copyButtonText,
		copyHash,
		faqs
	  };
	}
  }
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  textarea.form-control {
	font-size: 16px;
  }
  
  /* Dark mode styles */
  @media (prefers-color-scheme: dark) {
	.accordion-button {
	  background-color: #343a40;
	  color: #fff;
	}
  
	.accordion-button:not(.collapsed) {
	  background-color: #2b3035;
	  color: #fff;
	}
  
	.accordion-button::after {
	  filter: invert(1);
	}
  
	.accordion-body {
	  background-color: #343a40;
	  color: #fff;
	}
  
	/* Style for code snippets in dark mode */
	.accordion-body code {
	  background-color: #2b3035;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  
  /* Light mode styles */
  @media (prefers-color-scheme: light) {
	.accordion-body {
	  background-color: #fff;
	  color: #212529;
	}
  
	/* Style for code snippets in light mode */
	.accordion-body code {
	  background-color: #f8f9fa;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  </style>
