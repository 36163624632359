<template>
    <BreadCrumb PageTitle="HTML To BBCode" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">HTML To BBCode</h1>
      <p class="fs-6 text-center mb-4">Transform your HTML into clean, readable BBCode instantly. Enjoy features like HTML beautification and live preview to ensure seamless conversion and effortless editing.</p>
      <div class="row mb-4">
        <div class="col-md-6 mb-4 mb-md-0">
          <div class="markdown mb-2">
            <label for="inputHTML">Input (HTML):</label>
            <div class="ms-3 form-check">
              <input type="checkbox" class="form-check-input" id="beautifyHTMLCheckbox" v-model="beautifyHTML" @change="handleInput">
              <label for="beautifyHTMLCheckbox" class="form-check-label">Beautify HTML</label>
            </div>
          </div>
          <textarea id="inputHTML" class="form-control" rows="25" v-model="inputHTML" @input="handleInput"></textarea>
        </div>
        <div class="col-md-6">
          <div class="markdown mb-2 d-flex justify-content-between align-items-center">
            <label>{{ livePreview ? 'Live Preview' : 'Output (BBCode):' }}</label>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="livePreviewCheckbox" v-model="livePreview">
              <label class="form-check-label" for="livePreviewCheckbox">Live Preview</label>
            </div>
          </div>
          <div class="position-relative output-wrapper">
            <textarea v-if="!livePreview" id="outputBBCode" class="form-control" rows="25" readonly :value="outputBBCode"></textarea>
            <div v-else id="bbcodePreview" class="form-control bbcode-preview" v-html="bbcodeHTML"></div>
            <i 
              :class="['bi', copied ? 'bi-clipboard-check' : 'bi-clipboard', 'copy-icon']" 
              @click="copyToClipboard"
              :title="copied ? 'Copied!' : 'Copy to clipboard'"
            ></i>
          </div>
        </div>
      </div>
  
      <div class="alert alert-danger mt-3" v-if="error">Error converting HTML to BBCode!</div>
      <div>
        <HtmlToBBCodeFAQ />
      </div>
    </div>
  </template>
  
  <script>
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import HtmlToBBCodeFAQ from '../FAQs/htmlToBBCodeFAQ.vue';
  import { html as beautifyHTML } from 'js-beautify';
  
  export default {
    components: {
      BreadCrumb,
      HtmlToBBCodeFAQ
    },
    data() {
      return {
        inputHTML: '',
        beautifyHTML: false,
        livePreview: false,
        outputBBCode: '',
        error: false,
        copied: false,
      };
    },
    computed: {
        bbcodeHTML() {
            return this.bbcodeToHTML(this.outputBBCode);
        }
        },
    methods: {
      handleInput() {
        if (this.beautifyHTML) {
          this.inputHTML = beautifyHTML(this.inputHTML, { indent_size: 2, space_in_empty_paren: true });
        }
        this.convertToBBCode();
      },
      convertToBBCode() {
        try {
          this.outputBBCode = this.htmlToBBCode(this.inputHTML);
          this.error = false;
        } catch (e) {
          this.error = true;
        }
      },
      htmlToBBCode(html) {
        let bbcode = html;

        // Convert common tags
        bbcode = bbcode.replace(/<b>(.*?)<\/b>/gi, '[b]$1[/b]');
        bbcode = bbcode.replace(/<i>(.*?)<\/i>/gi, '[i]$1[/i]');
        bbcode = bbcode.replace(/<u>(.*?)<\/u>/gi, '[u]$1[/u]');
        bbcode = bbcode.replace(/<h1>(.*?)<\/h1>/gi, '[h1]$1[/h1]');
        bbcode = bbcode.replace(/<h2>(.*?)<\/h2>/gi, '[h2]$1[/h2]');
        bbcode = bbcode.replace(/<h3>(.*?)<\/h3>/gi, '[h3]$1[/h3]');
        bbcode = bbcode.replace(/<p>(.*?)<\/p>/gi, '$1\n\n');
        
        // Convert lists
        bbcode = bbcode.replace(/<ul>(.*?)<\/ul>/gis, '[list]$1[/list]');
        bbcode = bbcode.replace(/<ol>(.*?)<\/ol>/gis, '[list=1]$1[/list]');
        bbcode = bbcode.replace(/<li>(.*?)<\/li>/gi, '[*]$1');

        // Convert links and images
        bbcode = bbcode.replace(/<a href="(.*?)">(.*?)<\/a>/gi, '[url=$1]$2[/url]');
        bbcode = bbcode.replace(/<img src="(.*?)" ?\/?>/gi, '[img]$1[/img]');

        // Clean up doctype, head, body, and other elements we don't need
        bbcode = bbcode.replace(/<!DOCTYPE html>/gi, '');
        bbcode = bbcode.replace(/<\/?(html|head|title|body)>/gi, '');
        
        // Trim extra spaces
        bbcode = bbcode.trim();

        return bbcode;
        },
      copyToClipboard() {
        navigator.clipboard.writeText(this.outputBBCode).then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        });
      },
      bbcodeToHTML(bbcode) {
        let html = bbcode;

        // Convert BBCode tags to HTML
        html = html.replace(/\[b\](.*?)\[\/b\]/gi, '<b>$1</b>');
        html = html.replace(/\[i\](.*?)\[\/i\]/gi, '<i>$1</i>');
        html = html.replace(/\[u\](.*?)\[\/u\]/gi, '<u>$1</u>');
        html = html.replace(/\[h1\](.*?)\[\/h1\]/gi, '<h1>$1</h1>');
        html = html.replace(/\[h2\](.*?)\[\/h2\]/gi, '<h2>$1</h2>');
        html = html.replace(/\[h3\](.*?)\[\/h3\]/gi, '<h3>$1</h3>');
        html = html.replace(/\[url=(.*?)\](.*?)\[\/url\]/gi, '<a href="$1">$2</a>');
        html = html.replace(/\[img\](.*?)\[\/img\]/gi, '<img src="$1" />');

        // Convert list items and lists
        html = html.replace(/\[\*\](.*?)\n?/gi, '<li>$1</li>');
        html = html.replace(/\[list\](.*?)\[\/list\]/gis, '<ul>$1</ul>');
        html = html.replace(/\[list=1\](.*?)\[\/list\]/gis, '<ol>$1</ol>');

        return html;
    }
    }
  };
  </script>
  
  <style scoped>
  .markdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .custom-container {
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .custom-container {
      padding: 0 0.5rem;
    }
  }
  label, .form-control {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    .row {
      flex-direction: column;
    }
  }
  .output-wrapper {
    position: relative;
  }
  .copy-icon {
    position: absolute;
    bottom: 10px;
    right: 25px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #6c757d;
    z-index: 1;
  }
  .copy-icon:hover {
    color: #495057;
  }
  .bbcode-preview {
    height: calc(18 * 1.5em + 0.20rem);
    overflow-y: auto;
    padding-right: 40px;
  }
  textarea {
    resize: vertical;
    padding-right: 40px;
  }
  </style>
  