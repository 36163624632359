<template>
  <BreadCrumb PageTitle="Easter Wo️rd Scramble" />
  <div class="game-container mt-4 mb-5">
    <h1 class="text-center">Easter Word Scramble</h1>
    <p class="text-center mb-4 fs-5">Crack the egg-citing word puzzles! Hop into wordplay fun this holiday. Play now and egg-splore your skills!</p>
    <div class="text-center mb-4">
      <game-stats
        v-if="!gameActive && (gameOver || isPageRefreshed)"
        :highScore="highScore"
        :wordsSolved="wordsSolved"
        :accuracy="accuracy"
      ></game-stats>
      <div class="scrambleGif text-center mt-4" v-if="!gameActive">
        <img class="rounded img-fluid" :src="wordScrambleGif" alt="Easter Word Scramble" loading="lazy" />
      </div>
      <div class="row mt-4" v-if="!gameActive">
        <div class="col-sm-6 mb-3">
          <button class="btn btn-primary w-100" @click="startGame">Start Game</button>
        </div>
        <div class="col-sm-6 mb-3">
          <button class="btn btn-danger w-100" @click="navigateToLeaderboard">Show Leaderboard</button>
        </div>
      </div>
      <div class="card p-3" v-show="gameActive">
        <div class="gamedisplay fs-2">
          <p><i class="bi bi-star-fill" id="star-icon"></i> <span class="score">{{ score }}</span></p>
          <p><i class="bi bi-stopwatch-fill" :style="{color: timerColor}" id="stopwatch-icon"></i> <span><b>{{ remainingTime }}</b>s </span></p>
          <p><i class="bi bi-trophy-fill" id="trophy-icon"></i> <span class="high-score">{{ highScore }}</span></p>
        </div>
        <div v-if="alertMessage" class="alert" :class="`alert-${alertType}`" role="alert">
          {{ alertMessage }}
        </div>
        <p class="display-6 spaced-letters">{{ scrambledWord.toUpperCase() }}</p>
        <div>
          <p class="hint"><i class="flaticon-idea"></i> <span>{{ currentHint }}</span></p>
          <p>Word Starts With: <span>{{ startLetters }}</span></p>
        </div>
        <input class="form-control mb-3" v-model="uppercaseUserInput" type="text" placeholder="Enter a valid word" spellcheck="false" @keyup.enter="checkWord" />
        <div class="row">
          <div class="col-sm-6 mb-3">
            <button class="btn btn-primary check-word w-100" @click="checkWord">Check Word</button>
          </div>
          <div class="col-sm-6 mb-3">
            <button class="btn btn-danger refresh-word w-100" @click="nextWord">Refresh Word</button>
          </div>
        </div>
      </div>
      <FAQs v-if="!gameActive" :gameName="'Easter Word Scramble'" />
    </div>
    <div v-if="promptForName" class="name-prompt">
      <div class="card name-prompt-content">
        <h3>Save Score to Leaderboard</h3>
        <input v-model="playerName" type="text" class="form-control mb-3" placeholder="Your name">
        <div v-if="nameExists" class="alert alert-danger">
          Name already exists, please choose a different name.
        </div>
        <div class="row">
          <div class="col-6 mb-2">
            <button class="btn btn-primary w-100" @click="saveToLeaderboard">Save</button>
          </div>
          <div class="col-6 mb-2">
            <button class="btn btn-secondary w-100" @click="promptForName = false">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameStats from '@/components/VueFiles/GameStats.vue';
import { supabase } from '@/supabase';
import FAQs from '@/pages/FAQs/wordScrambleFAQ.vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  data() {
    return {
      highScore: 0,
      wordsSolved: 0,
      totalAttempts: 0,
      incorrectAttempts: 0,
      currentWord: '',
      currentHint: '',
      scrambledWord: '',
      userInput: '',
      remainingTime: 30,
      score: 0,
      gameActive: false,
      gameOver: false,
      isPageRefreshed: true,
      wordList: [],
      timer: null,
      maxTime: 30,
      currentActualWord: '',
      startLetters: '',
      wordScrambleGif: require('@/assets/word-scramble.gif'),
      timerColor: '#10ff00',
      alertMessage: '',
      alertType: '',
      showLeaderboardModal: false,
      leaderboard: [],
      playerName: '',
      promptForName: false,
      playerBeyondTop10: false,
      playerPosition: null,
      playerSaved: false,
      nameExists: false 
    };
  },
  computed: {
    accuracy() {
      return this.totalAttempts > 0 ? Math.round((this.wordsSolved / this.totalAttempts) * 100) : 0;
    },
    uppercaseUserInput: {
      get() {
        return this.userInput.toUpperCase();
      },
      set(value) {
        this.userInput = value.toUpperCase();
      }
    },
    displayedLeaderboard() {
      return this.leaderboard.slice(0, 10);
    }
  },
  methods: {
    startGame() {
      this.score = 0;
      this.gameActive = true;
      this.gameOver = false;
      this.isPageRefreshed = false;
      this.loadWordList();
      this.checkLocalStorageForPlayerName();
    },
    async loadWordList() {
      try {
        const response = await fetch('/wordlist/easter-word-scramble.json');
        this.wordList = await response.json();
        if (this.wordList.length > 0) {
          this.nextWord();
          this.startTimer();
        } else {
          this.showAlert('Word list is empty!', 'danger');
        }
      } catch (error) {
        this.showAlert('Error loading word list!', 'danger');
      }
    },
    scrambleWord(word) {
      let arr = word.split('');
      let n = arr.length;
      for (let i = n - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      let scrambled = arr.join('');
      return scrambled === word ? this.scrambleWord(word) : scrambled;
    },
    nextWord() {
      if (this.wordList.length === 0) {
        this.endGame();
        return;
      }
      const randomIndex = Math.floor(Math.random() * this.wordList.length);
      const currentWordObj = this.wordList.splice(randomIndex, 1)[0];
      this.currentActualWord = currentWordObj.word;
      this.scrambledWord = this.scrambleWord(this.currentActualWord);
      this.currentHint = currentWordObj.hint;
      this.startLetters = this.currentActualWord.split(' ').map(word => word[0].toUpperCase()).join(', ');
      this.userInput = '';
      this.startTimer();
    },
    startTimer() {
      this.remainingTime = this.maxTime;
      clearInterval(this.timer);
      this.updateTimerColor();
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
          this.updateTimerColor();
        } else {
          clearInterval(this.timer);
          this.showAlertWithCallback(`Time's up! The correct word was: ${this.currentActualWord.toUpperCase()}`, 'danger', this.endGame);
        }
      }, 1000);
    },
    updateTimerColor() {
      const thirdOfTime = this.maxTime / 3;
      if (this.remainingTime <= thirdOfTime) {
        this.timerColor = '#ff0300';
      } else if (this.remainingTime <= thirdOfTime * 2) {
        this.timerColor = '#f7f700';
      } else {
        this.timerColor = '#10ff00';
      }
    },
    checkWord() {
      this.totalAttempts++;
      if (this.userInput.toLowerCase() === this.currentActualWord.toLowerCase()) {
        this.score++;
        this.wordsSolved++;
        this.saveHighScore();
        this.updateLocalStorageStats();
        this.showAlert('Congrats! Correct word!', 'success');
        this.nextWord();
      } else {
        this.incorrectAttempts++;
        this.updateLocalStorageStats();
        this.showAlert('Oops! Incorrect word!', 'danger');
      }
    },
    async endGame() {
      this.gameActive = false;
      this.gameOver = true;
      if (this.playerSaved) {
        await this.updateLeaderboard();
      } else {
        this.promptForName = true;
      }
    },
    showAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
      setTimeout(() => {
        this.alertMessage = '';
        this.alertType = '';
      }, 3000);
    },
    showAlertWithCallback(message, type, callback) {
      this.alertMessage = message;
      this.alertType = type;
      setTimeout(() => {
        this.alertMessage = '';
        this.alertType = '';
        callback();
      }, 3000);
    },
    async saveHighScore() {
      if (this.score > this.highScore) {
        this.highScore = this.score;
        localStorage.setItem('easterhighScore', this.highScore);
      }
    },
    updateLocalStorageStats() {
      localStorage.setItem('easterWordsSolved', this.wordsSolved);
      localStorage.setItem('easterTotalAttempts', this.totalAttempts);
      localStorage.setItem('easterAccuracy', this.accuracy);
    },
    checkLocalStorageForPlayerName() {
      const savedPlayerName = localStorage.getItem('easterPlayerName');
      if (savedPlayerName) {
        this.playerName = savedPlayerName;
        this.playerSaved = true;
      }
    },
    checkLocalStorageForStats() {
      this.wordsSolved = parseInt(localStorage.getItem('easterWordsSolved')) || 0;
      this.totalAttempts = parseInt(localStorage.getItem('easterTotalAttempts')) || 0;
    },
    async saveToLeaderboard() {
      if (this.score <= 0) {
        this.showAlert('Score must be greater than zero to save to leaderboard', 'danger');
        return;
      }

      if (!this.playerName.trim()) {
        this.showAlert('Please enter your name', 'danger');
        return;
      }

      try {
        const { data, error } = await supabase
          .from('easter_leaderboard')
          .select('*');

        if (error) throw error;

        const nameExists = data.some(entry => entry.name.toLowerCase() === this.playerName.trim().toLowerCase());
        
        if (nameExists) {
          this.nameExists = true;
          return;
        }

        const { error: insertError } = await supabase
          .from('easter_leaderboard')
          .insert([{ name: this.playerName, score: this.score }]);

        if (insertError) throw insertError;

        localStorage.setItem('easterPlayerName', this.playerName.trim());
        this.promptForName = false;
        this.playerSaved = true;
        this.showAlert('Score saved successfully!', 'success');
        this.nameExists = false;
      } catch (error) {
        this.showAlert('Error saving to leaderboard', 'danger');
      }
    },
    async updateLeaderboard() {
      try {
        const { error } = await supabase
          .from('easter_leaderboard')
          .update({ score: this.highScore })
          .eq('name', this.playerName);

        if (error) throw error;
      } catch (error) {
        this.showAlert('Error updating leaderboard', 'danger');
      }
    },
    navigateToLeaderboard() {
      this.$router.push({ 
        name: 'Leaderboard', 
        params: { 
          tableName: 'easter_leaderboard', 
          title: 'Easter Word Scramble Leaderboard',
          from: '/easter-word-scramble'
        }
      });
    }
  },
  mounted() {
    this.highScore = parseInt(localStorage.getItem('easterhighScore')) || 0;
    this.checkLocalStorageForPlayerName();
    this.checkLocalStorageForStats();
  },
  watch: {
    totalAttempts(newVal) {
      localStorage.setItem('easterTotalAttempts', newVal);
    },
    wordsSolved(newVal) {
      localStorage.setItem('easterWordsSolved', newVal);
    },
    accuracy(newVal) {
      localStorage.setItem('easterAccuracy', newVal);
    }
  },
  components: {
    GameStats,
    FAQs,
    BreadCrumb
  }
};
</script>
 
<style scoped>
.game-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 15px;
}

.scrambleGif {
  margin-top: 30px;
}

.gamedisplay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

#trophy-icon, #star-icon, .flaticon-idea {
  color: #ffd454;
}

.spaced-letters {
  letter-spacing: 0.2em;
  word-break: break-all;
}

.faq-title {
  border-bottom: 1px solid #606e85;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.name-prompt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.name-prompt-content {
  width: 90%;
  max-width: 400px;
  padding: 20px;
}

@media (max-width: 576px) {
  h1 {
    font-size: 1.5rem;
  }
  
  .spaced-letters {
    font-size: 1.5rem;
  }
}
</style>