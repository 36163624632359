<template>
      <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
        <div class="accordion" id="faqAccordion">
            <div class="row">
                <div class="text-center mb-3"><h3>FAQs on Letter Frequency Counter</h3></div>
  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
      <h5>What is the Letter Frequency Counter?</h5>
    </button>
    <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          The Letter Frequency Counter is an online tool that analyzes the frequency of each letter, number, or punctuation mark in a given text. It allows users to gain insights into the distribution of characters in their input.
        </p>
      </div>
    </div>
  </div>

  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="false" aria-controls="faqCollapseTwo">
      <h5>How do I use the tool?</h5>
    </button>
    <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          Simply enter your desired text into the 'Enter Texts' textarea and click on the 'Letter Frequency' button. The frequency of each character will then be displayed in the 'Frequency Result' section.
        </p>
      </div>
    </div>
  </div>

  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="false" aria-controls="faqCollapseThree">
      <h5>Why would I need to know the frequency of letters in a text?</h5>
    </button>
    <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          Letter frequency analysis has historical importance in areas like cryptography. Besides, it can be used for linguistic analysis, educational purposes, or even for fun to gain insights into your texts.
        </p>
      </div>
    </div>
  </div>

  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="false" aria-controls="faqCollapseFour">
      <h5>Is there a limit to how much text I can input?</h5>
    </button>
    <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          There's no strict limit set in the app. However, for practical reasons and to ensure smooth performance, it's recommended not to input extremely lengthy texts.
        </p>
      </div>
    </div>
  </div>

  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="false" aria-controls="faqCollapseFive">
      <h5>Does the tool store my input?</h5>
    </button>
    <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          No, the Letter Frequency Counter processes the text on your device and does not store or transmit your input data anywhere else.
        </p>
      </div>
    </div>
  </div>

  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="false" aria-controls="faqCollapseSix">
      <h5>How does the Character Type Filtering work?</h5>
    </button>
    <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          The Character Type Filtering allows users to focus on specific character types like letters, numbers, or punctuation. By selecting a filter, the app will only display the frequency of characters that belong to the chosen category.
        </p>
      </div>
    </div>
  </div>

  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="false" aria-controls="faqCollapseSeven">
      <h5>What are the Educational Tips provided above?</h5>
    </button>
    <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          The Educational Tips section offers interesting tidbits and insights about the characters found in your input. This is to enhance the learning experience for users and provide context on certain characters.
        </p>
      </div>
    </div>
  </div>

  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEight" aria-expanded="false" aria-controls="faqCollapseEight">
      <h5>The tool provided a tip about a character I didn't use. Why?</h5>
    </button>
    <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          The tool checks for the presence of various characters, including punctuation marks. Some commonly used characters might trigger tips even if they were not the primary focus of your text.
        </p>
      </div>
    </div>
  </div>

  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseNine" aria-expanded="false" aria-controls="faqCollapseNine">
      <h5>I encountered an issue with the app. How can I report it?</h5>
    </button>
    <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          If you encounter any issues, please feel free to reach out to our support team through the 'Contact' page, and we'll do our best to assist you.
        </p>
      </div>
    </div>
  </div>

  <div class="accordion-item rounded-0 border-0">
    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTen" aria-expanded="false" aria-controls="faqCollapseTen">
      <h5>Is the tool free to use?</h5>
    </button>
    <div id="faqCollapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
      <div class="accordion-body pb-0">
        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
          Yes, the Letter Frequency Counter is entirely free to use!
        </p>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
  </template>
  
  <script>
  export default {
    name: 'LetterFrequencyCounterFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  