<template>
  <BreadCrumb PageTitle="Trigonometry Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Trigonometry Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate sine, cosine, and tangent of an angle. Enter the angle in degrees or radians to compute the trigonometric functions.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Angle -->
        <div class="mb-3">
          <label for="angle" class="form-label">Enter Angle:</label>
          <input
            type="number"
            id="angle"
            v-model="angle"
            class="form-control"
            placeholder="e.g., 30"
          />
        </div>

        <!-- Select Angle Unit -->
        <div class="mb-3">
          <label class="form-label">Angle Unit:</label>
          <div>
            <input
              type="radio"
              id="degrees"
              value="degrees"
              v-model="angleUnit"
            />
            <label for="degrees" class="me-3">Degrees</label>
            <input
              type="radio"
              id="radians"
              value="radians"
              v-model="angleUnit"
            />
            <label for="radians">Radians</label>
          </div>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateTrigonometry" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Angle: {{ result.angle }} {{ result.unit }}</p>
          <p class="fs-5">Sine (sin): {{ result.sine }}</p>
          <p class="fs-5">Cosine (cos): {{ result.cosine }}</p>
          <p class="fs-5">Tangent (tan): {{ result.tangent }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the angle value in the input field.</li>
        <li>Select the unit of the angle (Degrees or Radians).</li>
        <li>Click "Calculate" to compute the sine, cosine, and tangent of the angle.</li>
        <li>The results will display the angle and the values of the trigonometric functions.</li>
        <li>Click "Clear" to reset the input field and results.</li>
      </ul>

      <h5 class="alert-heading">Understanding Trigonometric Functions:</h5>
      <p class="fs-6">
        The primary trigonometric functions are sine (sin), cosine (cos), and tangent (tan). They relate the angles of a triangle to the lengths of its sides.
      </p>
      <p class="fs-6">
        These functions are fundamental in the study of periodic phenomena, among other applications.
      </p>

      <h5 class="alert-heading">Formulas:</h5>
      <div>
        \[
        \sin(\theta) = \frac{\text{Opposite Side}}{\text{Hypotenuse}}, \quad
        \cos(\theta) = \frac{\text{Adjacent Side}}{\text{Hypotenuse}}, \quad
        \tan(\theta) = \frac{\text{Opposite Side}}{\text{Adjacent Side}}
        \]
      </div>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For angle \( 30^\circ \):
          <ul>
            <li>\( \sin(30^\circ) = 0.5 \)</li>
            <li>\( \cos(30^\circ) \approx 0.866025 \)</li>
            <li>\( \tan(30^\circ) \approx 0.577350 \)</li>
          </ul>
        </li>
        <li>
          For angle \( \frac{\pi}{6} \) radians:
          <ul>
            <li>\( \sin\left(\frac{\pi}{6}\right) = 0.5 \)</li>
            <li>\( \cos\left(\frac{\pi}{6}\right) \approx 0.866025 \)</li>
            <li>\( \tan\left(\frac{\pi}{6}\right) \approx 0.577350 \)</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input field and result state
    const angle = ref(null);
    const angleUnit = ref("degrees");
    const result = ref(null);
    const error = ref("");

    // Function to calculate trigonometric functions
    const calculateTrigonometry = () => {
      error.value = "";
      result.value = null;

      try {
        // Ensure value is not null or empty
        if (angle.value === null || angle.value === "") {
          throw new Error("Please enter an angle value.");
        }

        // Parse the input value
        const angleValue = parseFloat(angle.value);

        if (isNaN(angleValue)) {
          throw new Error("Please enter a valid numeric value for the angle.");
        }

        // Convert angle to radians if necessary
        let angleInRadians;
        if (angleUnit.value === "degrees") {
          angleInRadians = (angleValue * Math.PI) / 180;
        } else {
          angleInRadians = angleValue;
        }

        // Calculate sine, cosine, and tangent
        const sineValue = Math.sin(angleInRadians).toFixed(6);
        const cosineValue = Math.cos(angleInRadians).toFixed(6);

        let tangentValue;
        if (Math.abs(cosineValue) < 1e-10) {
          tangentValue = "Undefined";
        } else {
          tangentValue = Math.tan(angleInRadians).toFixed(6);
        }

        result.value = {
          angle: angleValue,
          unit: angleUnit.value,
          sine: sineValue,
          cosine: cosineValue,
          tangent: tangentValue,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input field and results
    const clearValues = () => {
      angle.value = null;
      angleUnit.value = "degrees";
      result.value = null;
      error.value = "";
    };

    return {
      angle,
      angleUnit,
      result,
      error,
      calculateTrigonometry,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
