<template>
  <BreadCrumb PageTitle="Rice To Water Ratio Calculator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Rice To Water Ratio Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Find the perfect rice-to-water ratio for flawless cooking. Use our easy calculator for precise measurements and best results. Try now!
    </p>
    
    <div>
      <div class="input-group mb-4">
        <span class="input-group-text" id="riceTypeLabel">Type of Rice:</span>
        <select class="form-control" v-model="riceType" aria-describedby="riceTypeLabel">
          <option v-for="type in riceTypes" :key="type" :value="type">{{ type }}</option>
        </select>
      </div>
      <div class="input-group mb-4">
        <span class="input-group-text" id="riceAmountLabel">Amount of Rice (cups):</span>
        <input
          type="number"
          class="form-control"
          v-model.number="riceAmount"
          aria-describedby="riceAmountLabel"
          placeholder="Enter rice amount in cups"
          step="0.25"
          min="1"
        >
      </div>
    </div>
    
    <div v-if="alert" class="alert alert-danger" role="alert">{{ alert }}</div>
    
    <div class="row mb-4">
      <div class="col-md-6 mb-3">
        <button @click="calculate" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Calculate Rice to Water Ratio">
          Calculate
        </button>
      </div>
      <div class="col-md-6 mb-3">
        <button @click="reset" class="btn btn-danger w-100" data-bs-toggle="tooltip" title="Reset">
          Reset
        </button>
      </div>
    </div>
    
    <div class="form-group mb-3">
    <label for="riceResult" class="form-label">Rice to Water Ratio Result</label>
    <div
      id="riceResult"
      class="form-control p-4 fs-5"
      readonly
      tabindex="0"
      aria-live="polite"
      v-html="formattedResult"
    ></div>
  </div>
    
    <div>
      <riceToWaterRatioCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUpdated } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import riceToWaterRatioCalculatorFAQ from '../FAQs/riceToWaterRatioCalculatorFAQ.vue';

const riceRatios = {
  "White Long Grain Rice": 1.5,
  "White Medium Grain Rice": 1.5,
  "White Short Grain Rice": 1.25,
  "Brown Long Grain Rice": 2,
  "Brown Medium Grain Rice": 2,
  "Brown Short Grain Rice": 2,
  "Basmati Rice": 1.5,
  "Jasmine Rice": 1.25,
  "Converted (Parboiled) Rice": 2,
  "Arborio Rice": 2,
  "Wild Rice": 3,
  "Sushi Rice": 1.1,
  "Black Japonica Rice": 2,
  "Red Cargo Rice": 2
};

export default {
  name: 'RiceWaterRatioCalculator',
  components: {
    BreadCrumb,
    riceToWaterRatioCalculatorFAQ
  },
  setup() {
    const riceType = ref("White Long Grain Rice");
    const riceAmount = ref(1);
    const result = ref('');
    const alert = ref('');

    const riceTypes = computed(() => Object.keys(riceRatios));

    const formattedResult = computed(() => {
      if (!result.value) return '';
      return result.value.replace(/\n/g, '<br>').replace(/(\d+(\.\d+)?)\s(cup[s]?)/g, '<strong>$1</strong> $3');
    });

    const calculate = () => {
      if (riceAmount.value <= 0) {
        alert.value = "Please enter a valid amount of rice (greater than 0).";
        result.value = "";
        return;
      }

      alert.value = "";
      const waterAmount = riceAmount.value * riceRatios[riceType.value];
      result.value = `Rice to Water Ratio:
${riceAmount.value} cup${riceAmount.value !== 1 ? 's' : ''} of ${riceType.value} to ${waterAmount.toFixed(2)} cup${waterAmount !== 1 ? 's' : ''} of water

Ingredients:
• ${riceAmount.value} cup${riceAmount.value !== 1 ? 's' : ''} of ${riceType.value}
• ${waterAmount.toFixed(2)} cup${waterAmount !== 1 ? 's' : ''} of water

Cooking Instructions:
1. Rinse the rice in a fine-mesh strainer until the water runs clear.
2. In a pot, combine ${riceAmount.value} cup${riceAmount.value !== 1 ? 's' : ''} of rice with ${waterAmount.toFixed(2)} cup${waterAmount !== 1 ? 's' : ''} of water.
3. Bring the mixture to a boil over high heat.
4. Once boiling, reduce the heat to low and cover the pot with a tight-fitting lid.
5. Simmer for about 18-20 minutes, or according to the package instructions.
6. Remove from heat and let it sit, covered, for 5-10 minutes.
7. Fluff the rice with a fork before serving.

Note: Cooking times may vary slightly depending on your stove and the specific type of rice. Adjust as needed for best results.`;
    };

    const reset = () => {
      riceType.value = "White Long Grain Rice";
      riceAmount.value = 1;
      result.value = '';
      alert.value = '';
    };

    onMounted(() => {
      // Initialize result div height if needed
      const resultDiv = document.getElementById('riceResult');
      if (resultDiv) {
        resultDiv.style.height = 'auto';
        resultDiv.style.height = `${resultDiv.scrollHeight}px`;
      }
    });

    onUpdated(() => {
      // Adjust result div height after updates
      const resultDiv = document.getElementById('riceResult');
      if (resultDiv) {
        resultDiv.style.height = 'auto';
        resultDiv.style.height = `${resultDiv.scrollHeight}px`;
      }
    });

    return {
      riceType,
      riceAmount,
      result,
      alert,
      formattedResult,
      riceTypes,
      calculate,
      reset
    };
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.form-label {
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.form-control {
  font-size: 16px;
}

.alert {
  font-size: 14px;
}

#riceResult {
  min-height: 100px;
  overflow-y: hidden;
  resize: none;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>