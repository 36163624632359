<template>
  <BreadCrumb PageTitle="Pythagorean Theorem Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Pythagorean Theorem Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the length of a side of a right triangle using the Pythagorean theorem. Enter the known sides and select which side to calculate.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Select Side to Calculate -->
        <div class="mb-3">
          <label for="calcType" class="form-label">Select Side to Calculate:</label>
          <select id="calcType" v-model="calcType" class="form-select">
            <option value="hypotenuse">Calculate Hypotenuse (c)</option>
            <option value="leg">Calculate Leg (a or b)</option>
          </select>
        </div>

        <!-- Input Fields for Hypotenuse Calculation -->
        <div v-if="calcType === 'hypotenuse'" class="mb-3">
          <label for="legA" class="form-label">Leg a:</label>
          <input
            type="text"
            id="legA"
            v-model="legA"
            class="form-control"
            placeholder="e.g., 3"
          />
          <label for="legB" class="form-label mt-3">Leg b:</label>
          <input
            type="text"
            id="legB"
            v-model="legB"
            class="form-control"
            placeholder="e.g., 4"
          />
        </div>

        <!-- Input Fields for Leg Calculation -->
        <div v-if="calcType === 'leg'" class="mb-3">
          <label for="hypotenuse" class="form-label">Hypotenuse (c):</label>
          <input
            type="text"
            id="hypotenuse"
            v-model="hypotenuse"
            class="form-control"
            placeholder="e.g., 5"
          />
          <label for="legKnown" class="form-label mt-3">Known Leg (a or b):</label>
          <input
            type="text"
            id="legKnown"
            v-model="legKnown"
            class="form-control"
            placeholder="e.g., 3"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateSide" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Known Sides: {{ result.knownSides }}</p>
          <p class="fs-5">Calculated Side: {{ result.calculatedSide }}</p>
          <p class="fs-5">{{ result.explanation }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Select whether you want to calculate the hypotenuse or a leg of the right triangle.</li>
        <li>Enter the known sides in the appropriate fields.</li>
        <li>Click "Calculate" to find the missing side. The result will display the known sides and the calculated side.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Pythagorean Theorem Formula:</h5>
      <div class="fs-6">
        The formula for the Pythagorean theorem is given by:
      </div>
      <div>
        \[
        a^2 + b^2 = c^2
        \]
      </div>
      <p class="fs-6">
        Where:
        <ul>
          <li>\( a \) and \( b \) are the legs of the right triangle.</li>
          <li>\( c \) is the hypotenuse, the side opposite the right angle.</li>
        </ul>
      </p>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For legs \( a = 3 \) and \( b = 4 \), the hypotenuse is \( c = \sqrt{3^2 + 4^2} = 5 \).
        </li>
        <li>
          For hypotenuse \( c = 5 \) and leg \( a = 3 \), the other leg is \( b = \sqrt{5^2 - 3^2} = 4 \).
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const calcType = ref("hypotenuse");
    const legA = ref("");
    const legB = ref("");
    const hypotenuse = ref("");
    const legKnown = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate the missing side
    const calculateSide = () => {
      error.value = "";
      result.value = null;

      try {
        let calculatedSide, knownSides, explanation;

        if (calcType.value === "hypotenuse") {
          // Calculate hypotenuse
          const a = parseFloat(legA.value.trim());
          const b = parseFloat(legB.value.trim());

          if (isNaN(a) || isNaN(b) || a <= 0 || b <= 0) {
            throw new Error("Please enter valid positive numbers for both legs.");
          }

          const c = Math.sqrt(a * a + b * b).toFixed(6);
          calculatedSide = `Hypotenuse (c) = ${c}`;
          knownSides = `Leg a = ${a}, Leg b = ${b}`;
          explanation = `The hypotenuse is calculated as: c = √(a² + b²) = √(${a}² + ${b}²) = ${c}.`;

        } else if (calcType.value === "leg") {
          // Calculate leg
          const c = parseFloat(hypotenuse.value.trim());
          const leg = parseFloat(legKnown.value.trim());

          if (isNaN(c) || isNaN(leg) || c <= 0 || leg <= 0 || leg >= c) {
            throw new Error("Please enter valid positive numbers where the known leg is less than the hypotenuse.");
          }

          const missingLeg = Math.sqrt(c * c - leg * leg).toFixed(6);
          calculatedSide = `Leg = ${missingLeg}`;
          knownSides = `Hypotenuse (c) = ${c}, Known Leg = ${leg}`;
          explanation = `The missing leg is calculated as: leg = √(c² - leg²) = √(${c}² - ${leg}²) = ${missingLeg}.`;

        } else {
          throw new Error("Invalid calculation type.");
        }

        result.value = {
          knownSides: knownSides,
          calculatedSide: calculatedSide,
          explanation: explanation,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      legA.value = "";
      legB.value = "";
      hypotenuse.value = "";
      legKnown.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      calcType,
      legA,
      legB,
      hypotenuse,
      legKnown,
      result,
      error,
      calculateSide,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
