// LogarithmicOperations.js

import { log, log10, log2, exp } from 'mathjs';

export function calculateLog(value) {
  return log(value);
}

export function calculateLog10(value) {
  return log10(value);
}

export function calculateLog2(value) {
  return log2(value);
}

export function calculateExp(value) {
  return exp(value);
}

// Helper function to format the result
export function formatResult(value) {
  return Number(value.toFixed(10));
}