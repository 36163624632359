<template>
        <div class="card mb-25 mt-50 border-0 rounded-0 bg-white letter-spacing faq-accordion-card">
            <div class="card-body p-15 p-sm-20">
                <div class="accordion" id="faqAccordion">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center mb-3"><h3>FAQs on Midjourney Prompt Generator</h3></div>
                            <div class="accordion-item rounded-0 border-0">
                                <button
                                    class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapseOne"
                                    aria-expanded="true"
                                    aria-controls="faqCollapseOne">
                                <h5>What is the Midjourney Prompt Generator?</h5>
                                </button>
                                <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body pb-0">
                                        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                            The Midjourney Prompt Generator is a tool designed to help users generate creative prompts for Midjourney, an AI-based image generation platform. By inputting keywords or themes, the generator produces detailed prompts that guide the AI in creating unique and tailored images.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded-0 border-0">
                                <button
                                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapseTwo"
                                    aria-expanded="true"
                                    aria-controls="faqCollapseTwo">
                                <h5>How do I use the Midjourney Prompt Generator?</h5>
                                </button>
                                <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body pb-0">
                                        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                            Using our Midjourney Prompt Generator is simple. Enter a few keywords or themes into the input field, and the generator will provide a detailed prompt. This prompt can then be used in the Midjourney platform to create images based on your input.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded-0 border-0">
                                <button
                                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapseThree"
                                    aria-expanded="true"
                                    aria-controls="faqCollapseThree">
                                <h5>What are the benefits of using our Midjourney Prompt Generator?</h5>
                                </button>
                                <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body pb-0">
                                        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                            Our Midjourney Prompt Generator helps streamline the creative process by providing inspiration and structure to your prompts. This ensures that the AI generates images that are more aligned with your vision, saving you time and enhancing creativity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded-0 border-0">
                                <button
                                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapseFour"
                                    aria-expanded="true"
                                    aria-controls="faqCollapseFour">
                                <h5>Are there any costs associated with using the Midjourney Prompt Generator?</h5>
                                </button>
                                <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body pb-0">
                                        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                            Our Midjourney Prompt Generator is available for free. However, accessing and using the Midjourney platform itself may require a subscription or usage fees, depending on the plan you choose. For detailed pricing information, please visit the <a href="https://docs.midjourney.com/docs/plans" target="_blank">Midjourney pricing plans page</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded-0 border-0">
                                <button
                                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapseFive"
                                    aria-expanded="true"
                                    aria-controls="faqCollapseFive">
                                <h5>Can I customize the prompts generated by the Midjourney Prompt Generator?</h5>
                                </button>
                                <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body pb-0">
                                        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                            Yes, the prompts generated can be customized to better suit your needs. You can edit the generated text to include specific details or requirements that you want the AI to consider when creating the image.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded-0 border-0">
                                <button
                                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapseSix"
                                    aria-expanded="true"
                                    aria-controls="faqCollapseSix">
                                <h5>What kind of inputs work best for generating prompts?</h5>
                                </button>
                                <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body pb-0">
                                        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                            The best inputs are clear and descriptive keywords or themes that capture the essence of the image you are looking to create. For example, instead of a vague term like "nature," use more specific phrases like "sunset over a forest" or "tropical beach at dawn."
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded-0 border-0">
                                <button
                                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapseSeven"
                                    aria-expanded="true"
                                    aria-controls="faqCollapseSeven">
                                <h5>How can I improve the quality of the images generated?</h5>
                                </button>
                                <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body pb-0">
                                        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                            To improve the quality of the images generated, you need to add as many details as possible in the prompt. Include elements such as color, style, mood, and any particular objects or scenes you want to be depicted. The more information the AI has, the better it can match your vision.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded-0 border-0">
                                <button
                                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapseEight"
                                    aria-expanded="true"
                                    aria-controls="faqCollapseEight">
                                <h5>Is there a limit to the number of prompts I can generate?</h5>
                                </button>
                                <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body pb-0">
                                        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                            There is no limit to the number of prompts you can generate with our Midjourney Prompt Generator. Feel free to create as many prompts as you need to find the perfect inspiration for your project.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded-0 border-0">
                                <button
                                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapseNine"
                                    aria-expanded="true"
                                    aria-controls="faqCollapseNine">
                                <h5>What kind of projects can benefit from using the Midjourney Prompt Generator?</h5>
                                </button>
                                <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                    <div class="accordion-body pb-0">
                                        <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                            Our Midjourney Prompt Generator is versatile and can be used for various projects, including digital art, graphic design, marketing materials, social media content, and more. It is particularly useful for any project that requires visual creativity and inspiration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  name: 'MidjourneyPromptGeneratorFAQ',
};
</script>

<style scoped>

</style>