<template>
  <BreadCrumb PageTitle="Nursery Space Calculator" />
  <div class="custom-container mt-5 mb-50">
    <h1 class="text-center">Nursery Space Calculator</h1>
    <p class="fs-6 text-center mb-4">Calculate how many kids fit in a room by age group based on OFSTED space requirements!</p>
    <div class="row">
      <div class="col-12">
        <div>
          <div class="mb-3">
            <p>Room Dimensions (meters)</p>
            <div class="input-group">
              <span class="input-group-text" for="roomWidth">Width</span>
              <input
                id="roomWidth"
                v-model="roomWidth"
                class="form-control"
                type="number"
                placeholder="0"
                min="0"
                max="99999"
              />
              <input
                id="roomLength"
                v-model="roomLength"
                class="form-control"
                type="number"
                placeholder="0"
                min="0"
                max="99999"
              />
              <span class="input-group-text px-3" for="roomLength">Length</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="input-group">
              <span class="input-group-text" for="furnitureArea">Furniture Area (m<sup>2</sup>):</span>
              <input
                id="furnitureArea"
                v-model="furnitureArea"
                class="form-control"
                type="number"
                placeholder="0"
                min="0"
                max="99999"
              />
            </div>
          </div>
        </div>
        <div class="row mt-3 mb-3">
          <div class="input-group">
            <span class="input-group-text pe-4" for="select_year_group">Select Year Group:</span>
            <select
              v-model="selectedYearGroup"
              id="select_year_group"
              class="form-select"
            >
              <option value="">Not Selected</option>
              <option value="3.5">Under 2 year olds</option>
              <option value="2.5">2 year olds</option>
              <option value="2.3">3 to 5 year olds</option>
            </select>
          </div>
        </div>
        <div class="row mt-3 mb-4">
          <h4 class="text-center">Results</h4>
          <div class="input-group mb-3">
            <span class="input-group-text" for="totalChildren">Total Children:</span>
            <input
              id="totalChildren"
              :value="totalChildren"
              class="form-control"
              type="text"
              placeholder="0"
              readonly
            />
          </div>
          <div class="input-group">
            <span class="input-group-text" for="totalRoomArea">Total Room Area (m<sup>2</sup>):</span>
            <input
              id="totalRoomArea"
              :value="totalRoomArea"
              class="form-control"
              type="text"
              placeholder="0"
              readonly
            />
          </div>
        </div>
        <p id="dynamic_text" class="text-center mt-3 mb-3 fs-5">{{ dynamicText }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="col">
          <button
            @click="resetCalculator"
            class="btn btn-primary btn-hover w-100"
            data-bs-toggle="tooltip"
            title="Clear all"
          >
            <i class="bi bi-arrow-repeat"></i> Reset
          </button>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <NurserySpaceCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import NurserySpaceCalculatorFAQ from '../FAQs/nurserySpaceCalculatorFAQ.vue';

export default {
  name: 'NurserySpaceCalculator',
  components: {
    BreadCrumb,
    NurserySpaceCalculatorFAQ
  },
  setup() {
    const roomWidth = ref(0)
    const roomLength = ref(0)
    const furnitureArea = ref(0)
    const selectedYearGroup = ref('')

    const totalRoomArea = computed(() => {
      const area = roomWidth.value * roomLength.value - furnitureArea.value
      return area > 0 ? area.toFixed(2) : '0.00'
    })

    const totalChildren = computed(() => {
      if (!selectedYearGroup.value) return 0
      return Math.floor(totalRoomArea.value / parseFloat(selectedYearGroup.value))
    })

    const dynamicText = computed(() => {
      if (!selectedYearGroup.value) return ''
      const spacePerChild = parseFloat(selectedYearGroup.value)
      let ageGroup = ''
      if (spacePerChild === 3.5) ageGroup = 'under 2 year olds'
      else if (spacePerChild === 2.5) ageGroup = '2 year olds'
      else if (spacePerChild === 2.3) ageGroup = '3 to 5 year olds'
      return `Based on the room dimensions and furniture area provided, the room can accommodate ${totalChildren.value} "${ageGroup}" at ${spacePerChild} m² per child.`
    })

    const resetCalculator = () => {
      roomWidth.value = 0
      roomLength.value = 0
      furnitureArea.value = 0
      selectedYearGroup.value = ''
    }

    watch([roomWidth, roomLength, furnitureArea, selectedYearGroup], () => {
      if (roomWidth.value < 0) roomWidth.value = 0
      if (roomLength.value < 0) roomLength.value = 0
      if (furnitureArea.value < 0) furnitureArea.value = 0
    })

    return {
      roomWidth,
      roomLength,
      furnitureArea,
      selectedYearGroup,
      totalRoomArea,
      totalChildren,
      dynamicText,
      resetCalculator
    }
  }
}
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}
.ratio-calc {
  margin-bottom: -10px;
}
label,
.form-control {
  font-size: 16px;
}
.card {
  height: 100%;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-title {
  font-size: 1rem;
  font-weight: bold;
}
.card-text {
  font-size: 0.9rem;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
