<template>
  <BreadCrumb PageTitle="IPv6 To IPv4 Converter" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">IPv6 To IPv4 Converter</h1>
    <p class="fs-5 text-center mb-4">Quickly convert IPv6 to IPv4 for network compatibility. A handy online tool for effortless IPv6-to-IPv4 conversion.</p>
    <div class="container">
      <div class="mb-4">
        <label for="ipv6Input">Enter IPv6 Address:</label>
        <input type="text" class="form-control" id="ipv6Input" v-model="ipv6Input" placeholder="IPv6 Address">
      </div>
      <div class="alert alert-success" role="alert" v-if="ipv4Address">
        Conversion Successful: {{ ipv4Address }}
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <button class="btn btn-primary btn-block w-100 mb-3" @click="convertIPv6" data-bs-toggle="tooltip" title="Convert IPv6 to IPv4">Convert</button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-danger btn-block w-100" @click="clear" data-bs-toggle="tooltip" title="Clear screen">Clear</button>
        </div>
      </div>
      <div class="result" id="result">{{ resultMessage }}</div>
    </div>
    <div>
      <IPv6ToIPv4ConverterFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import IPv6ToIPv4ConverterFAQ from '../FAQs/ipv6ToIpv4ConverterFAQ.vue';

export default {
  components: {
    BreadCrumb,
    IPv6ToIPv4ConverterFAQ
  },
  data() {
    return {
      ipv6Input: '',
      ipv4Address: '',
      resultMessage: ''
    }
  },
  methods: {
    convertIPv6() {
      try {
        this.ipv4Address = this.IP6to4(this.ipv6Input);
        this.resultMessage = `IPv4 Address: ${this.ipv4Address}`;
      } catch (error) {
        this.resultMessage = 'Invalid IPv6 address.';
      }
    },
    clear() {
      this.ipv6Input = '';
      this.ipv4Address = '';
      this.resultMessage = '';
    },
    IP6to4(ip6) {
      function parseIp6(ip6str) {
        const str = ip6str.toString();

        // Initialize
        const ar = [];
        for (let i = 0; i < 8; i++) ar[i] = 0;

        // Check for trivial IPs
        if (str == '::') return ar;
        
        // Parse
        const sar = str.split(':');
        let slen = sar.length;
        if (slen > 8) slen = 8;
        let j = 0;
        for (let i = 0; i < slen; i++) {
          // This is a "::", switch to end-run mode
          if (i && sar[i] == '') {
            j = 9 - slen + i;
            continue;
          }
          ar[j] = parseInt(`0x0${sar[i]}`);
          j++;
        }

        return ar;
      }

      const ip6parsed = parseIp6(ip6);
      const ip4 = `${ip6parsed[6] >> 8}.${ip6parsed[6] & 0xff}.${ip6parsed[7] >> 8}.${ip6parsed[7] & 0xff}`;
      return ip4;
    }
  }
}
</script>

<style scoped>
.custom-container {
  max-width: 80vh;
  margin: auto;
}

label {
  font-size: 16px;
}
</style>