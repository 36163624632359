<template>
	<BreadCrumb PageTitle="Random PIN Generator" />
	<div class="container mt-5 mb-50">
	  <div class="row justify-content-center">
		<div class="col-12 col-md-10 col-lg-8 col-xl-6">
		  <h1 class="text-center mb-3">Advanced Random PIN Generator</h1>
		  <p class="fs-6 text-center mb-4">Generate random unique pin numbers.</p>
		  
		  <div class="mb-3">
			<label for="pinLength" class="form-label">PIN Length:</label>
			<input type="number" id="pinLength" v-model="pinLength" class="form-control" min="4" max="12">
		  </div>
		  
		  <div class="mb-3">
			<label for="pinCount" class="form-label">Number of PINs:</label>
			<input type="number" id="pinCount" v-model="pinCount" class="form-control" min="1" max="100">
		  </div>
		  
		  <button @click="generatePins" class="btn btn-primary mb-4 w-100">Generate PINs</button>
		  
		  <div v-if="pins.length" class="table-responsive mb-5">
			<table class="table">
			  <thead>
				<tr class="d-flex">
				  <th class="col-4">PIN</th>
				  <th class="col-4 text-center">Strength</th>
				  <th class="col-4 text-end">Copy</th>
				</tr>
			  </thead>
			  <tbody>
				<tr v-for="(pin, index) in pins" :key="index" class="d-flex">
				  <td class="col-4 text-break">{{ pin }}</td>
				  <td class="col-4 text-center">
					<span :class="getPinStrengthClass(pin)">{{ getPinStrength(pin) }}</span>
				  </td>
				  <td class="col-4 text-end">
					<i 
					  :class="copyStatus[index] ? 'bi-clipboard-check' : 'bi-clipboard'" 
					  @click="copyPin(pin, index)"
					  style="cursor: pointer;"
					></i>
				  </td>
				</tr>
			  </tbody>
			</table>
		  </div>
  
		  <div class="more-generators mt-4">
			<h2 class="h4 mb-3">More Pin Generators</h2>
			<ul class="list-unstyled fs-6">
			  <li v-for="digit in 9" :key="digit">
				<a :href="'/random-' + (digit + 3) + '-pin-generator'" class="text-decoration-none">
				  Random {{ digit + 3 }}-digit Pin Generator
				</a>
			  </li>
			</ul>
		  </div>
  
		  <!-- FAQ Section -->
		  <div class="mt-5">
			<RandomPINGeneratorFAQ />
		  </div>
		</div>
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import RandomPINGeneratorFAQ from '../FAQs/randomPinGeneratorFAQ.vue';
  
  export default {
	components: {
	  BreadCrumb,
	  RandomPINGeneratorFAQ
	},
	setup() {
	  const pinLength = ref(6);
	  const pinCount = ref(5);
	  const pins = ref([]);
	  const copyStatus = ref({});
	  const router = useRouter();
  
	  const generatePins = () => {
		pins.value = [];
		for (let i = 0; i < pinCount.value; i++) {
		  let pin = '';
		  for (let j = 0; j < pinLength.value; j++) {
			pin += Math.floor(Math.random() * 10);
		  }
		  pins.value.push(pin);
		}
		updateURL();
	  };
  
	  const getPinStrength = (pin) => {
		if (pin.length < 6) return 'Weak';
		if (pin.length < 8) return 'Moderate';
		return 'Strong';
	  };
  
	  const getPinStrengthClass = (pin) => {
		const strength = getPinStrength(pin);
		return {
		  'text-danger': strength === 'Weak',
		  'text-warning': strength === 'Moderate',
		  'text-success': strength === 'Strong'
		};
	  };
  
	  const copyPin = (pin, index) => {
		navigator.clipboard.writeText(pin);
		copyStatus.value[index] = true;
		setTimeout(() => {
		  copyStatus.value[index] = false;
		}, 2000);
	  };
  
	  const updateURL = () => {
		const newPath = `/random-${pinLength.value}-pin-generator`;
		router.push(newPath);
	  };
  
	  onMounted(() => {
		const path = window.location.pathname;
		const match = path.match(/\/random-(\d+)-pin-generator/);
		if (match) {
		  pinLength.value = parseInt(match[1]);
		  generatePins();
		}
	  });
  
	  return {
		pinLength,
		pinCount,
		pins,
		copyStatus,
		generatePins,
		getPinStrength,
		getPinStrengthClass,
		copyPin
	  };
	}
  }
  </script>
  
  <style scoped>
  .bi-clipboard, .bi-clipboard-check {
	font-size: 1.2rem;
  }
  
  .table th, .table td {
	vertical-align: middle;
  }
  
  .more-generators ul li {
	margin-bottom: 0.5rem;
  }
  
  @media (max-width: 576px) {
	h1 {
	  font-size: 1.8rem;
	}
	.fs-5 {
	  font-size: 1rem !important;
	}
  }
  </style>