<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
      <div class="card-body p-15 p-sm-20">
        <div class="accordion" id="faqAccordion">
          <div class="row">
            <div>
              <div class="text-center mb-3">
                <h3>FAQs on Multiple Word Replacer Tool</h3>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseOne"
                  aria-expanded="true"
                  aria-controls="faqCollapseOne"
                >
                  <h5>What is the Multiple Word Replacer Tool?</h5>
                </button>
                <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      The Multiple Word Replacer Tool is a powerful online utility crafted for editing and formatting large blocks of text. With its ability to recognize and replace several words or phrases simultaneously, this tool streamlines the tedious task of text editing. Unlike standard find-and-replace functions, it supports multiple target entries, significantly expediting the editing process and enhancing productivity.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseTwo"
                  aria-expanded="true"
                  aria-controls="faqCollapseTwo"
                >
                  <h5>What are the key features of the tool?</h5>
                </button>
                <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      <strong>Simultaneous Multiple Replacements:</strong> Perform several find-and-replace operations in one go, saving time and effort.<br>
                      <strong>Custom Replacement Sets:</strong> Define unique sets of find-and-replace pairs according to your editing needs.<br>
                      <strong>Case Sensitivity Option:</strong> Toggle case-sensitive searches for replacements that match exact letter casing.<br>
                      <strong>Whole Word Match:</strong> Avoid partial matches by ensuring that replacements are whole words.<br>
                      <strong>Live Statistics:</strong> Receive real-time stats on the total occurrences replaced, enhancing transparency and control in the editing process.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseThree"
                  aria-expanded="true"
                  aria-controls="faqCollapseThree"
                >
                  <h5>How do I use the Multiple Word Replacer Tool?</h5>
                </button>
                <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Using the Multiple Word Replacer Tool is straightforward. Follow these simple steps to get started:<br>
                      <strong>Input Text:</strong> Paste your text into the 'Input Text' field.<br>
                      <strong>Define Replacements:</strong> Enter each word separated by a comma, you wish to replace in the 'Find Text' field and their corresponding replacements in the 'Replace Text' field. Ensure both fields have the same number of entries.<br>
                      <strong>Set Preferences:</strong> Choose your desired settings (e.g., 'Match Case' or 'Whole Word Only') to refine your search criteria.<br>
                      <strong>Execute:</strong> Click the 'Replace' button and watch the tool work its magic. The replaced text will appear in the 'Output Text' field.<br>
                      <strong>Review Statistics:</strong> Check the live stats to see the number of replacements made.<br>
                      <strong>Copy or Save:</strong> Use the 'Copy' button to copy the modified text to your clipboard, or manually select and save your edited content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseFour"
                  aria-expanded="true"
                  aria-controls="faqCollapseFour"
                >
                  <h5>What are some tips for effective usage?</h5>
                </button>
                <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      <strong>Double-check your 'Find' and 'Replace' fields:</strong> Ensure spelling accuracy to avoid unintended replacements.<br>
                      <strong>Use the 'Whole Word Only' option:</strong> Prevent the tool from matching substrings of larger words.<br>
                      <strong>Review the 'Output Text':</strong> Carefully ensure all replacements are as intended, especially when working with critical or sensitive text.<br>
                      <strong>Ensure equal number of entries:</strong> The total number of separated words in the 'Find' textbox should equal the total number of corresponding words separated by commas in the 'Replace' textbox.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseFive"
                  aria-expanded="true"
                  aria-controls="faqCollapseFive"
                >
                  <h5>What should I do if I encounter an issue?</h5>
                </button>
                <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      If you encounter an issue, try the following troubleshooting steps:<br>
                      <strong>Check text fields:</strong> Ensure all fields are correctly filled.<br>
                      <strong>Verify settings:</strong> Ensure your 'Match Case' and 'Whole Word Only' settings are correct.<br>
                      <strong>Check find-and-replace pairs:</strong> Verify the accuracy of your pairs. Remember, the tool executes replacements exactly as typed.<br>
                      <strong>Refresh the page:</strong> Refreshing can reset the tool and may resolve functionality glitches.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseSix"
                  aria-expanded="true"
                  aria-controls="faqCollapseSix"
                >
                  <h5>Can I copy the modified text?</h5>
                </button>
                <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Of course! After replacing the text, you can instantly copy the updated content by clicking the 'Copy Text' button. This action copies the text to your clipboard, ready for pasting anywhere you need.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseSeven"
                  aria-expanded="true"
                  aria-controls="faqCollapseSeven"
                >
                  <h5>Is my text safe? Do you store the text that's inputted?</h5>
                </button>
                <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Your privacy is paramount. The tool operates entirely on your browser, meaning no data is sent to a server or stored online. Your text remains private and secure.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseEight"
                  aria-expanded="true"
                  aria-controls="faqCollapseEight"
                >
                  <h5>Can I undo a replacement?</h5>
                </button>
                <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Currently, the tool does not have an undo feature. It's recommended to double-check your 'Find' and 'Replace' inputs before performing the replacement. For added safety, keep a copy of your original text before making changes.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseNine"
                  aria-expanded="true"
                  aria-controls="faqCollapseNine"
                >
                  <h5>Are there any limitations on text size?</h5>
                </button>
                <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      The tool is designed to handle a wide range of text sizes, but extremely large texts might cause performance issues depending on your browser and device capabilities. It's best to work with manageable chunks of text for optimal performance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseTen"
                  aria-expanded="true"
                  aria-controls="faqCollapseTen"
                >
                  <h5>What if I encounter a problem with the tool?</h5>
                </button>
                <div id="faqCollapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      If you encounter any issues or have questions about the Multiple Word Replacer Tool, please contact our support team. We're here to help you have a smooth and enjoyable experience!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MultipleWordReplacerFAQ',
  };
  </script>
  
  <style scoped>
  .custom-container {
    max-width: 80vh;
    margin: auto;
  }
  </style>
