<template>
  <BreadCrumb PageTitle="Scientific Notation Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Scientific Notation Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Convert numbers to and from scientific notation, and perform arithmetic operations with numbers in scientific notation.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Select Calculation Type -->
        <div class="mb-3">
          <label for="calcType" class="form-label">Select Calculation Type:</label>
          <select id="calcType" v-model="calcType" class="form-select">
            <option value="toScientific">Convert to Scientific Notation</option>
            <option value="toStandard">Convert to Standard Form</option>
            <option value="operations">Perform Operations</option>
          </select>
        </div>

        <!-- Input Fields for Conversion to Scientific Notation -->
        <div v-if="calcType === 'toScientific'" class="mb-3">
          <label for="number" class="form-label">Enter a Number:</label>
          <input
            type="text"
            id="number"
            v-model="number"
            class="form-control"
            placeholder="e.g., 12345"
          />
        </div>

        <!-- Input Fields for Conversion to Standard Form -->
        <div v-if="calcType === 'toStandard'" class="mb-3">
          <label for="coefficient" class="form-label">Coefficient (a):</label>
          <input
            type="text"
            id="coefficient"
            v-model="coefficient"
            class="form-control"
            placeholder="e.g., 1.2345"
          />
          <label for="exponent" class="form-label mt-3">Exponent (n):</label>
          <input
            type="number"
            id="exponent"
            v-model="exponent"
            class="form-control"
            placeholder="e.g., 4"
          />
        </div>

        <!-- Input Fields for Operations -->
        <div v-if="calcType === 'operations'" class="mb-3">
          <label for="operation" class="form-label">Select Operation:</label>
          <select id="operation" v-model="operation" class="form-select mb-3">
            <option value="addition">Addition (+)</option>
            <option value="subtraction">Subtraction (-)</option>
            <option value="multiplication">Multiplication (×)</option>
            <option value="division">Division (÷)</option>
          </select>
          <h5>First Number</h5>
          <label for="coefficient1" class="form-label">Coefficient (a₁):</label>
          <input
            type="text"
            id="coefficient1"
            v-model="coefficient1"
            class="form-control"
            placeholder="e.g., 2.5"
          />
          <label for="exponent1" class="form-label mt-3">Exponent (n₁):</label>
          <input
            type="number"
            id="exponent1"
            v-model="exponent1"
            class="form-control"
            placeholder="e.g., 3"
          />
          <h5 class="mt-4">Second Number</h5>
          <label for="coefficient2" class="form-label">Coefficient (a₂):</label>
          <input
            type="text"
            id="coefficient2"
            v-model="coefficient2"
            class="form-control"
            placeholder="e.g., 1.2"
          />
          <label for="exponent2" class="form-label mt-3">Exponent (n₂):</label>
          <input
            type="number"
            id="exponent2"
            v-model="exponent2"
            class="form-control"
            placeholder="e.g., 4"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row mt-4">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculate" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">{{ result.calculationType }}</p>
          <p class="fs-5">{{ result.calculation }}</p>
          <p class="fs-5">Final Result: {{ result.finalResult }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Select the type of calculation you want to perform.</li>
        <li>Enter the required values based on the selected calculation type.</li>
        <li>Click "Calculate" to get the result. The result will display the calculation process and the final value.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Understanding Scientific Notation:</h5>
      <p class="fs-6">
        Scientific notation is a way of expressing numbers that are too big or too small to be conveniently written in decimal form. It is commonly used in science and engineering.
      </p>
      <p class="fs-6">
        A number in scientific notation is written as:
      </p>
      <div>
        \[
        a \times 10^n
        \]
      </div>
      <p class="fs-6">
        Where:
        <ul>
          <li>\( a \) is the coefficient (a number greater than or equal to 1 and less than 10)</li>
          <li>\( n \) is the exponent (an integer)</li>
        </ul>
      </p>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          <strong>Convert to Scientific Notation:</strong><br />
          \( 12345 = 1.2345 \times 10^4 \)
        </li>
        <li>
          <strong>Convert to Standard Form:</strong><br />
          \( 5.67 \times 10^{-3} = 0.00567 \)
        </li>
        <li>
          <strong>Addition:</strong><br />
          \( (2 \times 10^3) + (3 \times 10^3) = 5 \times 10^3 \)
        </li>
        <li>
          <strong>Multiplication:</strong><br />
          \( (2 \times 10^2) \times (3 \times 10^3) = 6 \times 10^5 \)
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const calcType = ref("toScientific");
    const number = ref("");
    const coefficient = ref("");
    const exponent = ref("");
    const coefficient1 = ref("");
    const exponent1 = ref("");
    const coefficient2 = ref("");
    const exponent2 = ref("");
    const operation = ref("addition");
    const result = ref(null);
    const error = ref("");

    // Function to perform calculations based on selected type
    const calculate = () => {
      error.value = "";
      result.value = null;

      try {
        let finalResult, calculationType, calculation;

        switch (calcType.value) {
          case "toScientific": {
            // Convert to Scientific Notation
            if (number.value === "") {
              throw new Error("Please enter a number.");
            }
            const num = parseFloat(number.value);
            if (isNaN(num)) {
              throw new Error("Please enter a valid numeric value.");
            }
            const scientific = toScientificNotation(num);
            finalResult = `${scientific.coefficient} × 10^${scientific.exponent}`;
            calculationType = "Conversion to Scientific Notation";
            calculation = `${num} = ${finalResult}`;
            break;
          }

          case "toStandard": {
            // Convert to Standard Form
            if (coefficient.value === "" || exponent.value === "") {
              throw new Error("Please enter both the coefficient and exponent.");
            }
            const coeff = parseFloat(coefficient.value);
            const exp = parseInt(exponent.value);
            if (isNaN(coeff) || isNaN(exp)) {
              throw new Error("Please enter valid numeric values.");
            }
            const standard = coeff * Math.pow(10, exp);
            finalResult = standard.toLocaleString("fullwide", { useGrouping: false });
            calculationType = "Conversion to Standard Form";
            calculation = `${coeff} × 10^${exp} = ${finalResult}`;
            break;
          }

          case "operations": {
            if (
              coefficient1.value === "" ||
              exponent1.value === "" ||
              coefficient2.value === "" ||
              exponent2.value === ""
            ) {
              throw new Error("Please enter all coefficients and exponents.");
            }
            let coeff1 = parseFloat(coefficient1.value);
            let exp1 = parseInt(exponent1.value);
            let coeff2 = parseFloat(coefficient2.value);
            let exp2 = parseInt(exponent2.value);
            if (isNaN(coeff1) || isNaN(exp1) || isNaN(coeff2) || isNaN(exp2)) {
              throw new Error("Please enter valid numeric values.");
            }

            let calcResult;
            switch (operation.value) {
              case "addition":
              case "subtraction":
                // For addition and subtraction, exponents must be the same
                if (exp1 !== exp2) {
                  // Adjust coefficients to have the same exponent
                  const expDiff = exp1 - exp2;
                  if (expDiff > 0) {
                    coeff2 = coeff2 * Math.pow(10, expDiff);
                    exp2 = exp1;
                  } else {
                    coeff1 = coeff1 * Math.pow(10, -expDiff);
                    exp1 = exp2;
                  }
                }
                calcResult =
                  operation.value === "addition"
                    ? coeff1 + coeff2
                    : coeff1 - coeff2;
                break;

              case "multiplication":
                calcResult = coeff1 * coeff2;
                exp1 = exp1 + exp2;
                break;

              case "division":
                if (coeff2 === 0) {
                  throw new Error("Cannot divide by zero.");
                }
                calcResult = coeff1 / coeff2;
                exp1 = exp1 - exp2;
                break;

              default:
                throw new Error("Invalid operation.");
            }

            // Adjust result to scientific notation
            const scientific = toScientificNotation(calcResult);
            scientific.exponent += exp1;
            finalResult = `${scientific.coefficient} × 10^${scientific.exponent}`;
            calculationType = "Operation Result";
            calculation = `Result of the operation is ${finalResult}`;
            break;
          }

          default:
            throw new Error("Invalid calculation type.");
        }

        result.value = {
          calculationType: calculationType,
          calculation: calculation,
          finalResult: finalResult,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to convert a number to scientific notation
    const toScientificNotation = (num) => {
      if (num === 0) {
        return { coefficient: 0, exponent: 0 };
      }
      const exponent = Math.floor(Math.log10(Math.abs(num)));
      const coefficient = (num / Math.pow(10, exponent)).toFixed(6);
      return { coefficient, exponent };
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      number.value = "";
      coefficient.value = "";
      exponent.value = "";
      coefficient1.value = "";
      exponent1.value = "";
      coefficient2.value = "";
      exponent2.value = "";
      operation.value = "addition";
      result.value = null;
      error.value = "";
    };

    return {
      calcType,
      number,
      coefficient,
      exponent,
      coefficient1,
      exponent1,
      coefficient2,
      exponent2,
      operation,
      result,
      error,
      calculate,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
