<template>
<div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
    <div class="card-body p-15 p-sm-20">
        <div class="accordion" id="faqAccordion">
            <div class="row">
                <div class="text-center mb-3"><h3>FAQs on Bcrypt Password Generator Tool</h3></div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                        <h5>What is Bcrypt Password Generator?</h5>
                    </button>
                    <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                The Bcrypt Password Generator is a user-friendly online tool designed to help you securely hash passwords using the bcrypt hashing algorithm. Bcrypt is renowned for its security advantages, making it a widely used method for password hashing in modern applications.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                        <h5>Why use Bcrypt?</h5>
                    </button>
                    <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                Bcrypt is not just a hashing algorithm, but it also involves salting the hash, which significantly increases security. Salting ensures that even if two users have the same password, their hashed passwords will be distinct. Furthermore, bcrypt is deliberately slow, which makes brute-force attacks much less effective.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                        <h5>How to use the Bcrypt Password Generator?</h5>
                    </button>
                    <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                Using the Bcrypt Password Generator is simple and straightforward. Here’s how:
                            </p>
                            <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                <li><strong>Enter the Password:</strong> Begin by typing the password you wish to hash into the 'Password' input field.</li>
                                <li><strong>Specify Rounds:</strong> Next, enter the number of rounds for the salting process. A higher number increases the complexity and security of the hash but also requires more processing time. We recommend using a value between 4 and 20.</li>
                                <li><strong>Generate Bcrypt Hash:</strong> Click the 'Generate Bcrypt' button. The tool will then generate and display the bcrypt hash of your password in the 'Bcrypt Output' field.</li>
                                <li><strong>Copy Hash:</strong> If you wish to copy the generated hash, click the 'Copy Text' button. The button text will change to 'Copied' momentarily to confirm the action.</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                        <h5>Tips and Recommendations</h5>
                    </button>
                    <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                - Always use a strong and unique password. Avoid using easily guessable passwords like 'password123' or 'admin'.<br>
                                - While choosing the number of rounds, remember that a higher value increases security but also the time taken to generate the hash. Typically, 10 to 12 rounds are considered safe for most applications.<br>
                                - Store the hashed passwords securely in your database. Never store plain text passwords.<br>
                                - Ensure your application also uses HTTPS, further encrypting data during transmission.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                        <h5>Can you decrypt a bcrypt password?</h5>
                    </button>
                    <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                No, bcrypt is a one-way hashing algorithm, which means once a password is hashed, it cannot be decrypted back to its original form. This is by design and is what makes bcrypt secure. Instead of decryption, bcrypt hashes the input password during the login process and compares it to the stored hash to verify user authenticity.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                        <h5>Is bcrypt good for passwords?</h5>
                    </button>
                    <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                Yes, bcrypt is considered one of the most secure password hashing algorithms available today. It's particularly useful because it incorporates salt (random data) and is deliberately slow, both of which deter brute-force and rainbow table attacks. Its adaptive nature allows the number of rounds, or the "work factor", to be increased as computers get faster, making it resilient against future advancements in computing power.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                        <h5>How long does it take to crack a bcrypt password?</h5>
                    </button>
                    <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                The time required to crack a bcrypt password depends on several factors, including the hashing rounds used, the strength of the password, and the computational power of the attacker's machine. Due to bcrypt's adaptive nature, increasing the number of rounds makes it exponentially harder to crack. For instance, with a strong password and a reasonable number of rounds, it could take decades, centuries, or even longer with current technology. However, weak passwords with minimal rounds are more susceptible. Always ensure you use a strong password and a sufficient number of rounds to maximize security.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                    <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEight" aria-expanded="true" aria-controls="faqCollapseEight">
                        <h5>Disclaimer</h5>
                    </button>
                    <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                        <div class="accordion-body pb-0">
                            <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                While bcrypt is a secure hashing algorithm, always stay updated with the latest security practices and update your systems as needed. The Bcrypt Password Generator tool is provided "as is" without any guarantees or warranty. Use at your own discretion.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  </template>
  
  <script>
  export default {
    name: 'BcryptPasswordGeneratorFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  