<template>
	<BreadCrumb PageTitle="Art Prompt Generator" />
	<div class="custom-container mt-5 mb-5">
	  <h1 class="text-center">Art Prompt Generator</h1>
	  <p class="fs-6 text-center mb-4">Over 500+ possible art prompt ideas that you can choose from.</p>
  
	  <div class="mb-4">
		<h4 class="text-center">Prompt of the Day</h4>
		<div class="card">
		  <div class="card-body">
			<p class="card-text fs-5 text-center">{{ promptOfTheDay }}</p>
			<button class="btn btn-sm btn-outline-secondary" @click="copyPrompt(promptOfTheDay, 'day')">
			  <i :class="['bi', copyIconDay ? 'bi-clipboard-check' : 'bi-clipboard']"></i>
			</button>
		  </div>
		</div>
	  </div>
  
	  <div class="mb-3">
		<p class="text-center mb-1">Select a category and the number of prompts desired. Then click "Get Prompts!"</p>
		<div class="input-group mb-3">
		  <span class="input-group-text" for="categorySelect">Category:</span>
		  <select id="categorySelect" class="form-select" v-model="selectedCategory">
			<option value="">Choose...</option>
			<option v-for="category in categories" :key="category" :value="category">
			  {{ category }}
			</option>
		  </select>
		</div>
		<div class="input-group mb-3">
		  <span class="input-group-text">No. of prompts:</span>
		  <input id="prompt_no" class="form-control" type="number" placeholder="0 - 10" min="1" max="10" v-model="promptCount" />
		</div>
	  </div>
  
	  <button id="btn_random_prompt" class="btn btn-primary d-flex justify-content-center align-items-center mb-4 w-100" 
			  data-bs-toggle="tooltip" title="Generate Random Art Prompts" @click="generatePrompts">
		Get Prompts!
	  </button>
  
	  <div>
		<h4 class="text-center">Generated Prompts</h4>
		<div id="prompt-alert" class="alert alert-warning" v-if="showAlert">{{ alertMessage }}</div>
		<div class="generated-prompts">
		  <p id="placeholder-text" class="opacity-50" v-if="generatedPrompts.length === 0">No prompts generated yet.</p>
		  <div v-else>
			<div v-for="(prompt, index) in generatedPrompts" :key="index" class="mb-3">
			  <div class="card">
				<div class="card-body">
				  <p class="card-text fs-5">{{ prompt }}</p>
				  <button class="btn btn-sm btn-outline-secondary" @click="copyPrompt(prompt, index)">
					<i :class="['bi', copyIcons[index] ? 'bi-clipboard-check' : 'bi-clipboard']"></i>
				  </button>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
  
	  <div class="mt-5">
		<ArtPromptGeneratorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, onMounted, watch } from 'vue';
  import artPromptData from '../../components/JSON/artPromptGenerator.json';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ArtPromptGeneratorFAQ from '../FAQs/artPromptGeneratorFAQ.vue';
  
  export default {
	name: 'ArtPromptGenerator',
	components: {
	  BreadCrumb,
	  ArtPromptGeneratorFAQ
	},
	setup() {
	  const categories = ref(Object.keys(artPromptData.categories));
	  const selectedCategory = ref('');
	  const promptCount = ref(1);
	  const generatedPrompts = ref([]);
	  const promptOfTheDay = ref('');
	  const showAlert = ref(false);
	  const alertMessage = ref('');
	  const copyIconDay = ref(false);
	  const copyIcons = ref({});
  
	  const generatePrompts = () => {
		if (!selectedCategory.value) {
		  showAlert.value = true;
		  alertMessage.value = 'Please select a category.';
		  return;
		}
  
		if (promptCount.value < 1 || promptCount.value > 10) {
		  showAlert.value = true;
		  alertMessage.value = 'Please enter a number between 1 and 10.';
		  return;
		}
  
		showAlert.value = false;
		const categoryPrompts = artPromptData.categories[selectedCategory.value];
		generatedPrompts.value = [];
  
		for (let i = 0; i < promptCount.value; i++) {
		  const randomIndex = Math.floor(Math.random() * categoryPrompts.length);
		  generatedPrompts.value.push(categoryPrompts[randomIndex]);
		}
	  };
  
	  const copyPrompt = (prompt, index) => {
		navigator.clipboard.writeText(prompt).then(() => {
		  if (index === 'day') {
			copyIconDay.value = true;
			setTimeout(() => { copyIconDay.value = false; }, 2000);
		  } else {
			copyIcons.value[index] = true;
			setTimeout(() => {
			  copyIcons.value[index] = false;
			}, 2000);
		  }
		});
	  };
  
	  const generatePromptOfTheDay = () => {
		const allPrompts = categories.value.flatMap(category => artPromptData.categories[category]);
		const randomIndex = Math.floor(Math.random() * allPrompts.length);
		promptOfTheDay.value = allPrompts[randomIndex];
	  };
  
	  onMounted(() => {
		generatePromptOfTheDay();
	  });
  
	  watch(generatedPrompts, () => {
		copyIcons.value = Object.fromEntries(generatedPrompts.value.map((_, index) => [index, false]));
	  });
  
	  return {
		categories,
		selectedCategory,
		promptCount,
		generatedPrompts,
		promptOfTheDay,
		showAlert,
		alertMessage,
		copyIconDay,
		copyIcons,
		generatePrompts,
		copyPrompt
	  };
	}
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  .card {
	margin-bottom: 1rem;
  }
  
  .card-body {
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  
  .card-text {
	margin-bottom: 0;
	flex-grow: 1;
  }
  
  .btn-sm {
	margin-left: 1rem;
  }
  </style>