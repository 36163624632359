<template>
  <div>
    <BreadCrumb PageTitle="Reverse Percentage Calculator" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">Reverse Percentage Calculator</h1>
      <p class="fs-6 text-center text-muted mb-4">
        Calculate the original value before a percentage change with our free reverse percentage calculator.
      </p>
      
      <div class="mb-3">
        <label for="finalValue" class="form-label">Final Value:</label>
        <input v-model.number="finalValue" type="number" class="form-control" id="finalValue" placeholder="Enter final value">
      </div>
      
      <div class="mb-3">
        <label for="percentageChange" class="form-label">Percentage Change:</label>
        <input v-model.number="percentageChange" type="number" class="form-control" id="percentageChange" placeholder="Enter percentage">
      </div>
      
      <div class="mb-3">
        <div class="form-check">
          <input v-model="isIncrease" class="form-check-input" type="radio" name="changeType" id="increase" :value="true">
          <label class="form-check-label" for="increase">Increase</label>
        </div>
        <div class="form-check">
          <input v-model="isIncrease" class="form-check-input" type="radio" name="changeType" id="decrease" :value="false">
          <label class="form-check-label" for="decrease">Decrease</label>
        </div>
      </div>
      
      <div class="row">
        <div class="col-sm-6 mb-2">
          <button @click="calculate" class="btn btn-primary w-100">Calculate</button>
        </div>
        <div class="col-sm-6 mb-2">
          <button @click="reset" class="btn btn-danger w-100">Reset</button>
        </div>
      </div>
      
      <div v-if="originalValue !== null" class="card mt-3">
        <div class="card-header bg-success text-white">
          <h4 class="mb-0">Results</h4>
        </div>
        <div class="card-body">
          <h5 class="card-title">Original Value: {{ originalValue.toFixed(2) }}</h5>
          <p class="card-text">{{ calculationExplanation }}</p>
        </div>
      </div>
      
      <div v-if="errorMessage" class="alert alert-danger mt-3">
        {{ errorMessage }}
      </div>
      <div><ReversePercentageCalculatorFAQ /></div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ReversePercentageCalculatorFAQ from '../FAQs/reversePercentageCalculatorFAQ.vue';

export default {
  components: {
    BreadCrumb,
    ReversePercentageCalculatorFAQ
  },
  data() {
    return {
      finalValue: null,
      percentageChange: null,
      isIncrease: true,
      originalValue: null,
      calculationExplanation: '',
      errorMessage: ''
    }
  },
  methods: {
    calculate() {
      this.errorMessage = '';
      this.originalValue = null;
      this.calculationExplanation = '';

      if (this.finalValue === null || this.percentageChange === null) {
        this.errorMessage = 'Please enter both final value and percentage change.';
        return;
      }

      if (this.percentageChange < 0 || this.percentageChange > 100) {
        this.errorMessage = 'Percentage must be between 0 and 100.';
        return;
      }

      if (this.isIncrease) {
        this.originalValue = this.finalValue / (1 + this.percentageChange / 100);
      } else {
        this.originalValue = this.finalValue / (1 - this.percentageChange / 100);
      }

      this.calculationExplanation = `The final value of ${this.finalValue} is a ${this.isIncrease ? 'n increase' : ' decrease'} 
        of ${this.percentageChange}% from the original value of ${this.originalValue.toFixed(2)}.`;
    },
    reset() {
      this.finalValue = null;
      this.percentageChange = null;
      this.isIncrease = true;
      this.originalValue = null;
      this.calculationExplanation = '';
      this.errorMessage = '';
    }
  }
}
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 15px;
}
</style>