<template>
  <BreadCrumb PageTitle="Remove Unwanted Characters" />
  <div class="custom-container mt-5">
    <h1 class="text-center">Remove Unwanted Characters</h1>
    <p class="fs-6 text-center mb-4">Remove unwanted characters from your text easily. Enter characters to remove, paste your text, and click a button to clean it up!</p>
    <div class="mb-3">
      <label for="characters" class="form-label">Characters to Remove</label>
      <input type="text" class="form-control" id="characters" v-model="characters" placeholder="Enter characters to remove">
    </div>
    <div class="mb-3">
      <label for="inputText" class="form-label">Text to Process</label>
      <textarea class="form-control" id="inputText" v-model="inputText" rows="5" placeholder="Enter text here"></textarea>
    </div>
    <div class="d-flex justify-content-between mb-3">
      <button class="btn btn-primary me-2 w-100" @click="removeCharacters">Remove Characters</button>
      <button class="btn btn-secondary w-100" @click="clearText">Clear</button>
    </div>
    <button class="btn btn-danger mb-3 w-100" @click="copyText">{{ copyButtonText }}</button>
    <div>
      <RemoveUnwantedCharactersFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import RemoveUnwantedCharactersFAQ from '../FAQs/removeUnwantedCharactersFAQ.vue';

export default {
  components: {
    BreadCrumb,
    RemoveUnwantedCharactersFAQ
  },
  data() {
    return {
      characters: '',
      inputText: '',
      copyButtonText: 'Copy'
    }
  },
  methods: {
    removeCharacters() {
      const charsToRemove = new RegExp(`[${this.characters}]`, 'g');
      this.inputText = this.inputText.replace(charsToRemove, '');
    },
    clearText() {
      this.inputText = '';
      this.characters = '';
    },
    copyText() {
      navigator.clipboard.writeText(this.inputText).then(() => {
        this.copyButtonText = 'Copied';
        setTimeout(() => {
          this.copyButtonText = 'Copy';
        }, 2000);
      });
    }
  }
}
</script>

<style scoped>
@media (max-width: 576px) {
  .d-flex {
    flex-direction: column;
  }

  .btn {
    margin-bottom: 10px;
  }

  .btn:last-child {
    margin-bottom: 0;
  }
}

.custom-container {
  max-width: 85vh;
  margin: auto;
}

label {
  font-size: 16px
}

.dark .accordion-item {
  color: #BCBBC7 !important;
  font-size: 16px;
}

.accordion-item {
  font-size: 16px;
}

.accordion-item text-paragraph {
  font-size: 18px;
}
</style>