<template>
    <BreadCrumb PageTitle="Algebra Solver" />
  
    <div class="custom-container mt-5">
      <h1 class="text-center">Algebra Solver</h1>
      <p class="fs-6 text-center mb-4">
        Solve algebraic equations for a specified variable. Enter your equation and the variable to solve for.
      </p>
  
      <!-- Input and Calculation Section -->
      <div class="card mb-4">
        <div class="card-body">
          <!-- Input for Equation -->
          <div class="mb-3">
            <label for="equation" class="form-label">Enter Equation:</label>
            <input
              type="text"
              id="equation"
              v-model="equation"
              class="form-control"
              placeholder="e.g., 2x + 3 = 7"
            />
          </div>
  
          <!-- Input for Variable -->
          <div class="mb-3">
            <label for="variable" class="form-label">Variable to Solve For:</label>
            <input
              type="text"
              id="variable"
              v-model="variable"
              class="form-control"
              placeholder="e.g., x"
            />
          </div>
  
          <!-- Calculation Buttons -->
          <div class="mb-3 row">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <button @click="solveEquation" class="btn btn-primary w-100">Solve</button>
            </div>
            <div class="col-12 col-md-6">
              <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
            </div>
          </div>
        </div>
  
        <!-- Display Result Section -->
        <div class="card-footer">
          <h4>Result:</h4>
          <div v-if="result !== null">
            <p class="fs-5">Equation: {{ equation }}</p>
            <p class="fs-5">Variable: {{ variable }}</p>
            <p class="fs-5">Solution:</p>
            <ul>
              <li v-for="(sol, index) in result.solutions" :key="index" class="fs-5">
                {{ variable }} = {{ sol }}
              </li>
            </ul>
            <h5>Step-by-Step Solution:</h5>
            <ul>
              <li v-for="(step, index) in result.steps" :key="index" class="fs-6">
                {{ index + 1 }}. {{ step.equation }}<br />
                <em>{{ step.description }}</em>
              </li>
            </ul>
          </div>
  
          <!-- Error message -->
          <p v-if="error" class="text-danger">{{ error }}</p>
        </div>
      </div>
  
      <!-- Instructions Section -->
      <div class="alert alert-info" role="alert">
        <h5 class="alert-heading">How to Use:</h5>
        <ul class="fs-6">
          <li>Enter the algebraic equation you wish to solve.</li>
          <li>Specify the variable you want to solve for.</li>
          <li>Click "Solve" to compute the solution(s).</li>
          <li>The result will display the equation and the solution(s) for the specified variable.</li>
          <li>Click "Clear" to reset the input fields and result.</li>
        </ul>
  
        <h5 class="alert-heading">Supported Equations:</h5>
        <p class="fs-6">
          This solver supports linear, quadratic, cubic, and some higher-degree equations, as well as equations involving basic arithmetic operations, exponents, and roots.
        </p>
  
        <h5 class="alert-heading">Example Equations:</h5>
        <ul class="fs-6">
          <li>\( 2x + 3 = 7 \)</li>
          <li>\( x^2 - 5x + 6 = 0 \)</li>
          <li>\( \sqrt{x} + 2 = 5 \)</li>
          <li>\( \frac{1}{x} = 4 \)</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import * as mathsteps from "mathsteps";
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  
  export default {
    components: {
      BreadCrumb,
    },
    setup() {
      const equation = ref("");
      const variable = ref("");
      const result = ref(null);
      const error = ref("");
  
      // Function to get a description for each step
      function getDescription(step) {
        const changeTypeDescriptions = {
          // Simplification Steps
          SIMPLIFY_LEFT_SIDE: "Simplify the left side of the equation",
          SIMPLIFY_RIGHT_SIDE: "Simplify the right side of the equation",
          // Arithmetic Operations
          ADD_TO_BOTH_SIDES: "Add to both sides",
          SUBTRACT_FROM_BOTH_SIDES: "Subtract from both sides",
          MULTIPLY_BOTH_SIDES: "Multiply both sides",
          DIVIDE_FROM_BOTH_SIDES: "Divide both sides",
          // Collecting and Combining Like Terms
          COLLECT_LIKE_TERMS: "Collect like terms",
          COMBINE_LIKE_TERMS: "Combine like terms",
          // Distribution and Factoring
          DISTRIBUTE: "Distribute multiplication over addition",
          FACTOR_SYMBOL: "Factor out common terms",
          // Isolating Variable
          ISOLATE_VARIABLE: "Isolate the variable",
          // Rearranging Equation
          REARRANGE_EQUATION: "Rearrange the equation",
          // Special Cases
          SOLVE_LINEAR_EQUATION: "Solve linear equation",
          // Default
          DEFAULT: "Perform operation",
        };
        const description = changeTypeDescriptions[step.changeType] || "Perform operation";
        return description;
      }
  
      const solveEquation = () => {
        error.value = "";
        result.value = null;
  
        try {
          if (equation.value.trim() === "") {
            throw new Error("Please enter an equation.");
          }
          if (variable.value.trim() === "") {
            throw new Error("Please specify the variable to solve for.");
          }
  
          // Check if the variable is present in the equation
          const variableRegex = new RegExp(`\\b${variable.value}\\b`);
          if (!variableRegex.test(equation.value)) {
            throw new Error(`The variable "${variable.value}" is not present in the equation.`);
          }
  
          // Use mathsteps to solve the equation and get steps
          const steps = mathsteps.solveEquation(equation.value, variable.value);
  
          if (steps.length === 0) {
            throw new Error("No solution found for the given equation.");
          }
  
          // Extract the final solution
          const lastStep = steps[steps.length - 1];
          const solutions = [lastStep.newEquation.rightNode.toString()];
  
          // Convert steps to readable format with descriptions
          const stepDescriptions = steps.map((step) => {
            return {
              equation: step.newEquation.ascii(),
              description: getDescription(step),
            };
          });
  
          result.value = {
            solutions: solutions,
            steps: stepDescriptions,
          };
        } catch (e) {
          error.value = e.message;
        }
      };
  
      const clearValues = () => {
        equation.value = "";
        variable.value = "";
        result.value = null;
        error.value = "";
      };
  
      return {
        equation,
        variable,
        result,
        error,
        solveEquation,
        clearValues,
      };
    },
  };
  </script>
  
  <style scoped>
  .custom-container {
    max-width: 70vh;
    margin: 0 auto;
    padding: 0 5px;
  }
  
  .alert {
    margin-bottom: 20px;
  }
  
  .dark h5 {
    color: #2b3035 !important;
  }
  
  strong {
    font-weight: bold;
  }
  
  .fs-5 {
    font-size: 1.25rem; /* Adjusted font size for clarity */
  }
  
  .fs-6 {
    font-size: 1rem; /* Slightly smaller font size for steps */
  }
  
  @media (max-width: 767px) {
    .col-12 {
      margin-bottom: 0.5rem;
    }
  }
  </style>
  