<template>
  <BreadCrumb PageTitle="Percent Error Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Percent Error Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the percent error between an experimental value and a theoretical value. Enter the values to compute the percent error.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Experimental Value -->
        <div class="mb-3">
          <label for="experimentalValue" class="form-label">Experimental Value:</label>
          <input
            type="text"
            id="experimentalValue"
            v-model="experimentalValue"
            class="form-control"
            placeholder="e.g., 25.6"
          />
        </div>

        <!-- Input for Theoretical Value -->
        <div class="mb-3">
          <label for="theoreticalValue" class="form-label">Theoretical Value:</label>
          <input
            type="text"
            id="theoreticalValue"
            v-model="theoreticalValue"
            class="form-control"
            placeholder="e.g., 26.0"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculatePercentError" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Experimental Value: {{ result.experimentalValue }}</p>
          <p class="fs-5">Theoretical Value: {{ result.theoreticalValue }}</p>
          <p class="fs-5">Absolute Error: {{ result.absoluteError }}</p>
          <p class="fs-5">Percent Error: {{ result.percentError }}%</p>
          <p class="fs-5" v-if="result.explanation">Explanation: {{ result.explanation }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the experimental value obtained from measurements or experiments.</li>
        <li>Enter the theoretical value, which is the expected or true value.</li>
        <li>Click "Calculate" to compute the percent error between the two values.</li>
        <li>The result will show the experimental value, theoretical value, absolute error, and percent error.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">What is Percent Error?</h5>
      <p class="fs-6">
        Percent error is a measure of the difference between an experimental value and a theoretical value, expressed as a percentage. It indicates the accuracy of the experimental results in comparison to the theoretical value.
      </p>

      <h5 class="alert-heading">Formula for Percent Error:</h5>
      <div class="fs-6">
        The formula for percent error is given by:
      </div>
      <div>
        \[
        \text{Percent Error} = \left| \frac{\text{Experimental Value} - \text{Theoretical Value}}{\text{Theoretical Value}} \right| \times 100
        \]
      </div>
      <p class="fs-6">
        This formula calculates the relative difference between the experimental and theoretical values.
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <ul class="fs-6">
        <li>
          For an experimental value of 25.6 and a theoretical value of 26.0:
          <ul>
            <li>Absolute Error = |25.6 - 26.0| = 0.4</li>
            <li>Percent Error = \( \left| \frac{25.6 - 26.0}{26.0} \right| \times 100 = 1.54\% \)</li>
          </ul>
        </li>
        <li>
          For an experimental value of 15 and a theoretical value of 20:
          <ul>
            <li>Absolute Error = |15 - 20| = 5</li>
            <li>Percent Error = \( \left| \frac{15 - 20}{20} \right| \times 100 = 25\% \)</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const experimentalValue = ref("");
    const theoreticalValue = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate percent error
    const calculatePercentError = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the input values
        const expValue = parseFloat(experimentalValue.value.trim());
        const theoValue = parseFloat(theoreticalValue.value.trim());

        if (isNaN(expValue) || isNaN(theoValue)) {
          throw new Error("Please enter valid numeric values.");
        }

        if (theoValue === 0) {
          throw new Error("Theoretical value cannot be zero.");
        }

        // Calculate the absolute error and percent error
        const absoluteError = Math.abs(expValue - theoValue);
        const percentError = (absoluteError / Math.abs(theoValue)) * 100;

        let explanation = `The percent error indicates that the experimental value differs from the theoretical value by ${percentError.toFixed(
          2
        )}%.`;

        result.value = {
          experimentalValue: expValue,
          theoreticalValue: theoValue,
          absoluteError: absoluteError.toFixed(2),
          percentError: percentError.toFixed(2),
          explanation: explanation,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      experimentalValue.value = "";
      theoreticalValue.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      experimentalValue,
      theoreticalValue,
      result,
      error,
      calculatePercentError,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
