<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Aspect Ratio Calculator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is Aspect Ratio?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Aspect ratio refers to the proportional relationship between the width and height of an image, screen, or any rectangular shape. It is usually expressed as two numbers separated by a colon (e.g., 16:9) or as a decimal (e.g., 1.78). The aspect ratio describes how wide an image is compared to its height.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How Is It Calculated?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The aspect ratio is calculated by dividing the width by the height using the formula:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Aspect Ratio = Width / Height
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    For example, if the width is 1280 pixels and the height is 720 pixels, the aspect ratio would be:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Aspect Ratio = 1280 / 720 = 1.78 (or 16:9 when simplified by their greatest common divisor (GCD))
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The pixel count is simply the width multiplied by the height:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Pixel Count = Width x Height
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    For the same example:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Pixel Count = 1280 x 720 = 921,600 pixels
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>Why Is It Important?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>Consistency:</strong> Maintaining the correct aspect ratio ensures that images or videos are displayed as intended, without distortion.</li>
                                    <li><strong>Compatibility:</strong> Knowing the aspect ratio helps in choosing compatible equipment, like screens or projectors, and ensures that content fits well within different display environments.</li>
                                    <li><strong>Aesthetics:</strong> Different aspect ratios offer different visual experiences. For example, a 4:3 aspect ratio is often used for traditional television broadcasts, while a 16:9 ratio is common for modern widescreen displays.</li>
                                    <li><strong>User Experience:</strong> Incorrect aspect ratios can lead to letterboxing (black bars on the top and bottom) or pillarboxing (black bars on the sides), which can be distracting for viewers.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>Common Aspect Ratios</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>4:3:</strong> Traditional television, iPad screens</li>
                                    <li><strong>16:9:</strong> Modern widescreen TVs, computer monitors, smartphones</li>
                                    <li><strong>1:1:</strong> Square images, often used on social media</li>
                                    <li><strong>21:9:</strong> Ultra-widescreen monitors and cinematic displays</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AspectRatioCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
