<template>
  <BreadCrumb PageTitle="Ripemd 160 Hash Generator Calculator" />

	<div class="custom-container mt-5">
	  <h1 class="text-center">RIPEMD-160 Hash Generator</h1>
	  <p class="fs-6 text-center mb-4">Quickly generate RIPEMD-160 hashes for your strings using our efficient and user-friendly tool.</p>
	  <div class="mb-3">
		<label for="inputTextString" class="form-label">Enter text string:</label>
		<textarea v-model="inputText" id="inputTextString" class="form-control"></textarea>
	  </div>
	  <div class="row mb-3">
		<div class="col-md-6 mb-2">
		  <button @click="generateHash" class="btn btn-primary w-100">Generate Hash</button>
		</div>
		<div class="col-md-6 mb-2">
		  <button @click="clearText" class="btn btn-danger w-100">Clear Text</button>
		</div>
	  </div>
	  <div class="mb-3">
		<label for="generatedHash" class="form-label">Generated RIPEMD-160 Hash:</label>
		<textarea v-model="generatedHash" id="generatedHash" class="form-control" rows="2" readonly></textarea>
	  </div>
	  <div class="mb-3">
		<button @click="copyHash" class="btn btn-secondary w-100">{{ copyButtonText }}</button>
	  </div>
	  <div class="mb-3 d-flex justify-content-between">
		<label class="form-label">Hash Size: 20 bytes (160 bits)</label>
		<label class="form-label">Hash Length: {{ hashLength }} characters</label>
	  </div>
  
	  <!-- FAQ Section -->
	  <div class="mt-50 mb-50">
		<h2 class="text-center mb-4">Frequently Asked Questions</h2>
		<div class="accordion" id="faqAccordion">
		  <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
			<h2 class="accordion-header" :id="'heading' + index">
			  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
				{{ faq.question }}
			  </button>
			</h2>
			<div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
			  <div class="accordion-body" v-html="faq.answer"></div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </template>
  
  <script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

  import { ref } from 'vue';
  import cryptoApi from 'crypto-api/src/crypto-api';
  import 'crypto-api/src/hasher/ripemd';
  
  export default {
	
  components: { BreadCrumb },
name: 'RIPEMD160HashGenerator',
	setup() {
	  const inputText = ref('');
	  const generatedHash = ref('');
	  const copyButtonText = ref('Copy Hash');
  
	  const generateHash = () => {
		try {
		  const hasher = cryptoApi.getHasher('ripemd160');
		  hasher.update(inputText.value);
		  const hash = hasher.finalize();
		  generatedHash.value = cryptoApi.encoder.toHex(hash).toUpperCase();
		} catch (error) {
		  console.error('Hash generation failed:', error);
		  generatedHash.value = `Error generating hash: ${error.message}`;
		}
	  };
  
	  const clearText = () => {
		inputText.value = '';
		generatedHash.value = '';
	  };
  
	  const copyHash = () => {
		navigator.clipboard.writeText(generatedHash.value)
			.then(() => {
			copyButtonText.value = 'Hash Copied';
			setTimeout(() => {
				copyButtonText.value = 'Copy Hash';
			}, 2000);
			})
			.catch(err => console.error('Failed to copy: ', err));
	  };
  
	  const faqs = [
		{
		  question: "What is RIPEMD-160?",
		  answer: `RIPEMD-160 (RACE Integrity Primitives Evaluation Message Digest) is a cryptographic hash function that produces a 160-bit (20-byte) hash value. It's part of the RIPEMD family of hash functions, developed in Europe by Hans Dobbertin, Antoon Bosselaers, and Bart Preneel.<br><br>
			Key characteristics of RIPEMD-160:
			<ul>
			  <li>160-bit output: Provides enhanced security compared to 128-bit hash functions.</li>
			  <li>Designed as a strengthened version of RIPEMD: Offers improved security over earlier versions.</li>
			  <li>Good performance: Generally efficient on 32-bit processors.</li>
			  <li>Open and license-free: Can be used without any licensing or royalty fees.</li>
			</ul>
			RIPEMD-160 is considered more secure than MD5 and RIPEMD-128, making it suitable for various cryptographic applications.`
		},
		{
		  question: "What are the key features of RIPEMD-160?",
		  answer: `RIPEMD-160 offers several key features:
		  <ul>
			<li><strong>Enhanced Security:</strong> 160-bit output provides better resistance against collision and preimage attacks compared to 128-bit hash functions.</li>
			<li><strong>Avalanche Effect:</strong> Small input changes result in significantly different hash values.</li>
			<li><strong>Efficiency:</strong> Balances performance and security considerations.</li>
			<li><strong>Flexible Input Length:</strong> Can hash messages of any length.</li>
			<li><strong>Two Independent Parallel Computation Chains:</strong> Enhances security through a unique design.</li>
		  </ul>`
		},
		{
		  question: "How is the RIPEMD-160 hash generated?",
		  answer: `RIPEMD-160 generates a hash through these main steps:
			<ol>
			  <li>Padding: The input message is padded to ensure its length is a multiple of 512 bits.</li>
			  <li>Parsing: The padded message is parsed into 512-bit blocks.</li>
			  <li>Initialization: An initial 160-bit state is set up.</li>
			  <li>Processing: Each 512-bit block is processed through two parallel compression functions (left and right lines).</li>
			  <li>Finalization: The results from both lines are combined to produce the final 160-bit hash value.</li>
			</ol>
			The process involves multiple rounds of bitwise operations, modular additions, and nonlinear functions to achieve strong diffusion and collision resistance.`
		},
		{
		  question: "Is this tool secure?",
		  answer: `This tool generates the RIPEMD-160 hash entirely in your browser, which provides several security benefits:
			<ul>
			  <li>No server-side processing: Your input is not sent to any server, reducing the risk of data interception.</li>
			  <li>No data storage: The tool doesn't store your input or the generated hashes.</li>
			  <li>Open-source implementation: The RIPEMD-160 algorithm used is open to scrutiny, enhancing trust.</li>
			</ul>
			However, please keep in mind:
			<ul>
			  <li>Browser security: Ensure your browser is up-to-date and you're using a secure connection (HTTPS).</li>
			  <li>Local environment: Be cautious when using this tool on public or shared computers. Clear your browser history and cache after use on such devices.</li>
			  <li>Input sensitivity: Avoid entering sensitive data (like passwords) into any online tool, including this one.</li>
			</ul>
			Always implement proper security measures in your applications and consult with security experts for critical systems.`
		},
		{
		  question: "How does RIPEMD-160 compare to other hash functions?",
		  answer: `RIPEMD-160 has several characteristics that distinguish it from other hash functions:
			<ul>
			  <li>vs MD5: 
				<ul>
				  <li>Longer output (160 bits vs 128 bits for MD5)</li>
				  <li>Considered significantly more secure than MD5</li>
				  <li>Similar performance on 32-bit processors</li>
				</ul>
			  </li>
			  <li>vs SHA-1: 
				<ul>
				  <li>Same output size (160 bits)</li>
				  <li>Comparable security level to SHA-1</li>
				  <li>Often faster on 32-bit processors</li>
				</ul>
			  </li>
			  <li>vs RIPEMD-128: 
				<ul>
				  <li>Longer output (160 bits vs 128 bits)</li>
				  <li>More secure than RIPEMD-128</li>
				  <li>Slightly slower but offers better security</li>
				</ul>
			  </li>
			  <li>vs SHA-256: 
				<ul>
				  <li>Shorter output (160 bits vs 256 bits)</li>
				  <li>Generally faster but less secure than SHA-256</li>
				  <li>SHA-256 is more widely adopted for high-security applications</li>
				</ul>
			  </li>
			</ul>
			RIPEMD-160 offers a good balance between security and performance, making it suitable for many applications. However, for the highest security requirements, longer hash functions like SHA-256 or SHA-3 are often preferred in modern systems.`
		},
		{
		  question: "Is RIPEMD-160 suitable for security-sensitive applications?",
		  answer: `RIPEMD-160 is generally considered suitable for many security-sensitive applications, offering a good balance between security and performance. Its 160-bit output provides stronger security compared to 128-bit hash functions like MD5 or RIPEMD-128.
  
			However, for applications requiring the highest levels of security, especially those with long-term security requirements, more modern hash functions with larger output sizes (such as SHA-256 or SHA-3) are often preferred.
  
			When deciding whether to use RIPEMD-160 for a security-sensitive application, consider:
			<ul>
			  <li>The specific security requirements of your application</li>
			  <li>Relevant industry standards and regulations</li>
			  <li>The expected lifespan of the security requirement</li>
			  <li>Compatibility with existing systems</li>
			</ul>
  
			Always stay informed about the latest developments in cryptography and follow best practices for secure hashing. When in doubt, consult with cybersecurity experts or cryptographers for guidance on the most appropriate hash function for your specific use case.`
		},
		{
		  question: "What is the output size of RIPEMD-160?",
		  answer: `RIPEMD-160 produces a fixed-size 160-bit (20-byte) hash value, regardless of the input message length. This longer output size, compared to 128-bit hash functions, provides enhanced security and better resistance against various cryptographic attacks.`
		}
	  ];
  
	  return {
		inputText,
		generatedHash,
		generateHash,
		clearText,
		copyButtonText,
		copyHash,
		faqs
	  };
	}
  }
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  textarea.form-control {
	font-size: 16px;
  }
  
  /* Dark mode styles */
  @media (prefers-color-scheme: dark) {
	.accordion-button {
	  background-color: #343a40;
	  color: #fff;
	}
  
	.accordion-button:not(.collapsed) {
	  background-color: #2b3035;
	  color: #fff;
	}
  
	.accordion-button::after {
	  filter: invert(1);
	}
  
	.accordion-body {
	  background-color: #343a40;
	  color: #fff;
	}
  
	/* Style for code snippets in dark mode */
	.accordion-body code {
	  background-color: #2b3035;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  
  /* Light mode styles */
  @media (prefers-color-scheme: light) {
	.accordion-body {
	  background-color: #fff;
	  color: #212529;
	}
  
	/* Style for code snippets in light mode */
	.accordion-body code {
	  background-color: #f8f9fa;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  </style>