<template>
  <BreadCrumb PageTitle="Blank Space Generator" />
  <div class="custom-container mt-5 mb-50">
    <h1 class="text-center">Blank Space Generator</h1>
    <p class="fs-6 text-center mb-4">
      Create invisible text with our easy-to-use generator. Simply copy and paste blank spaces anywhere you need. Perfect for formatting and design!
    </p>
    
    <!-- Text Input Section -->
    <section class="mb-4">
      <div class="container">
        <form id="spaceGeneratorForm" @submit.prevent="generateSpace">
          <div class="form-group mb-4 fs-6">
            <label for="spaceCount">Number of Blank Spaces:</label>
            <input
              type="number"
              class="form-control"
              id="spaceCount"
              v-model="spaceCount"
              placeholder="Enter number"
            >
          </div>
          <div
            class="alert alert-danger mb-4"
            role="alert"
            id="errorAlert"
            :style="{ display: showError ? 'block' : 'none' }"
          >
            Please enter number of blank spaces required.
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <button
                type="submit"
                class="btn btn-primary btn-block w-100 mb-3"
              >
                Generate Space
              </button>
            </div>
            <div class="col-md-6">
              <button
                type="button"
                @click="reset"
                class="btn btn-danger btn-block w-100"
                id="resetBtn"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
        <!-- Output section for generated spaces -->
        <div class="output-section mt-3 fs-6">
          <label for="generatedSpaces">Generated Blank Spaces:</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="generatedSpaces"
              v-model="generatedSpaces"
              ref="spacesOutput"
              readonly
            >
            <button
              class="btn btn-outline-secondary"
              type="button"
              id="copyButton"
              @click="copyToClipboard"
            >
              <i :class="copyIconClass"></i>
            </button>
          </div>
        </div>
        <!-- Unicode Character Table -->
        <div class="card mt-4">
          <h3 class="card-header text-center">
            Unicode Character Table
          </h3>
          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>Unicode</th>
                  <th>Description</th>
                  <th>Html</th>
                  <th>Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>U+0020</td>
                  <td>Space</td>
                  <td>&amp;#32;</td>
                </tr>
                <tr>
                <td>U+00A0</td>
                <td>No-Break Space</td>
                <td>&amp;#160;</td>
                <td class="codeClick">[&#160;]</td>
                </tr>
                <tr>
                <td>U+2000</td>
                <td>En Quad</td>
                <td>&amp;#8192;</td>
                <td class="codeClick">[&#8192;]</td>
                </tr>
                <tr>
                <td>U+2001</td>
                <td>Em Quad</td>
                <td>&amp;#8193;</td>
                <td class="codeClick">[&#8193;]</td>
                </tr>
                <tr>
                <td>U+2002</td>
                <td>En Space</td>
                <td>&amp;#8194;</td>
                <td class="codeClick">[&#8194;]</td>
                </tr>
                <tr>
                <td>U+2003</td>
                <td>Em Space</td>
                <td>&amp;#8195;</td>
                <td class="codeClick">[&#8195;]</td>
                </tr>
                <tr>
                <td>U+2004</td>
                <td>Three-Per-Em Space</td>
                <td>&amp;#8196;</td>
                <td class="codeClick">[&#8196;]</td>
                </tr>
                <tr>
                <td>U+2005</td>
                <td>Four-Per-Em Space</td>
                <td>&amp;#8197;</td>
                <td class="codeClick">[&#8197;]</td>
                </tr>
                <tr>
                <td>U+2006</td>
                <td>Six-Per-Em Space</td>
                <td>&amp;#8198;</td>
                <td class="codeClick">[&#8198;]</td>
                </tr>
                <tr>
                <td>U+2007</td>
                <td>Figure Space</td>
                <td>&amp;#8199;</td>
                <td class="codeClick">[&#8199;]</td>
                </tr>
                <tr>
                <td>U+2008</td>
                <td>Punctuation Space</td>
                <td>&amp;#8200;</td>
                <td class="codeClick">[&#8200;]</td>
                </tr>
                <tr>
                <td>U+2009</td>
                <td>Thin Space</td>
                <td>&amp;#8201;</td>
                <td class="codeClick">[&#8201;]</td>
                </tr>
                <tr>
                <td>U+200A</td>
                <td>Hair Space</td>
                <td>&amp;#8202;</td>
                <td class="codeClick">[&#8202;]</td>
                </tr>
                <tr>
                <td>U+2028</td>
                <td>Line Separator</td>
                <td>&amp;#8232;</td>
                <td class="codeClick">[&#8232;]</td>
                </tr>
                <tr>
                <td>U+205F</td>
                <td>Medium Mathematical Space</td>
                <td>&amp;#8287;</td>
                <td class="codeClick">[&#8287;]</td>
                </tr>
                <tr>
                <td>U+3000</td>
                <td>Ideographic Space</td>
                <td>&amp;#12288;</td>
                <td class="codeClick">[&#12288;]</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      <!-- FAQ Section -->
      <div class="mt-5">
        <BlankSpaceGeneratorFAQ />
      </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import BlankSpaceGeneratorFAQ from '../FAQs/blankSpaceGeneratorFAQ.vue';

export default {
  name: 'BlankSpaceGenerator',
  components: {
    BreadCrumb,
    BlankSpaceGeneratorFAQ
  },
  setup() {
    const spaceCount = ref('')
    const generatedSpaces = ref('')
    const showError = ref(false)
    const spacesOutput = ref(null)
    const isCopied = ref(false)

    const copyIconClass = computed(() => {
      return isCopied.value ? 'bi bi-clipboard-check' : 'bi bi-clipboard'
    })

    const generateSpace = () => {
      if (spaceCount.value && parseInt(spaceCount.value) > 0) {
        generatedSpaces.value = ' '.repeat(parseInt(spaceCount.value))
        showError.value = false
        
        // Automatically select and copy the blank spaces to the clipboard
        setTimeout(() => {
          spacesOutput.value.select()
          document.execCommand('copy')
        }, 0)
      } else {
        showError.value = true
      }
    }

    const reset = () => {
      spaceCount.value = ''
      generatedSpaces.value = ''
      showError.value = false
    }

    const copyToClipboard = () => {
      spacesOutput.value.select()
      document.execCommand('copy')
      isCopied.value = true
      setTimeout(() => {
        isCopied.value = false
      }, 2000)
    }

    return {
      spaceCount,
      generatedSpaces,
      showError,
      generateSpace,
      reset,
      copyToClipboard,
      spacesOutput,
      copyIconClass
    }
  }
}
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}

.table {
  font-size: 0.9rem;
}
.card-body {
    padding: 8px
}
</style>