<template>
      <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
          <div class="accordion" id="faqAccordion">
            <div class="row">
              <div class="text-center mb-3">
                <h3>FAQs on Antenna Beamwidth Calculator</h3>
              </div>

              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseOne"
                  aria-expanded="true"
                  aria-controls="faqCollapseOne"
                >
                  <h5>What is antenna beamwidth?</h5>
                </button>
                <div
                  id="faqCollapseOne"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#faqAccordion"
                >
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Antenna beamwidth is the angle at which power from the antenna drops to half its maximum value, indicating how directional the antenna is.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseTwo"
                  aria-expanded="false"
                  aria-controls="faqCollapseTwo"
                >
                  <h5>Why is beamwidth important?</h5>
                </button>
                <div
                  id="faqCollapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#faqAccordion"
                >
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Beamwidth affects how well an antenna can receive or transmit signals in a particular direction and is crucial for alignment and link quality in communication systems.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseThree"
                  aria-expanded="false"
                  aria-controls="faqCollapseThree"
                >
                  <h5>Can this calculator be used for any type of antenna?</h5>
                </button>
                <div
                  id="faqCollapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#faqAccordion"
                >
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      This calculator is specifically designed for parabolic dish antennas used in satellite and radar communications.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseFour"
                  aria-expanded="false"
                  aria-controls="faqCollapseFour"
                >
                  <h5>How to use the Antenna Beamwidth Calculator</h5>
                </button>
                <div
                  id="faqCollapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#faqAccordion"
                >
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      To use this calculator effectively, you typically need to:
                      <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        <li>Input the frequency of operation which affects the wavelength.</li>
                        <li>Enter the diameter or other dimension relevant to the shape of the antenna (e.g., diameter of a parabolic dish).</li>
                        <li>The calculator computes the beamwidth using the provided formula or a more specific formula adapted to the type of antenna.</li>
                      </ol>
                      <em class="fs-md-15 fs-lg-16 text-paragraph lh-base">Example: For a parabolic dish antenna with a diameter of 1 meter, operating at a frequency of 2.4 GHz, the calculator first determines the wavelength of the signal, then applies the formula to estimate the beamwidth.</em>
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseFive"
                  aria-expanded="false"
                  aria-controls="faqCollapseFive"
                >
                  <h5>Formula to calculate beamwidth</h5>
                </button>
                <div
                  id="faqCollapseFive"
                  class="accordion-collapse collapse"
                  data-bs-parent="#faqAccordion"
                >
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      The beamwidth (θ) of a parabolic antenna can be estimated using the formula:
                      <br>
                      θ ≈ 70 * (λ / D)
                      <br>
                      where:
                      <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        <li>λ (lambda) is the wavelength of the signal, calculated as λ = c / f, where c is the speed of light (~300,000,000 meters/second), and f is the frequency in GHz.</li>
                        <li>D is the diameter of the antenna's dish in meters.</li>
                      </ul>
                      <em class="fs-md-15 fs-lg-16 text-paragraph lh-base">This formula provides a quick estimation of the beamwidth for parabolic dish antennas. Actual beamwidth may vary depending on specific antenna designs and structures.</em>
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseSix"
                  aria-expanded="false"
                  aria-controls="faqCollapseSix"
                >
                  <h5>Beamwidth Types</h5>
                </button>
                <div
                  id="faqCollapseSix"
                  class="accordion-collapse collapse"
                  data-bs-parent="#faqAccordion"
                >
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Beamwidth is categorized into two types:
                      <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        <li><b>Horizontal Beamwidth</b>: The angle between the half-power points in the horizontal plane of the antenna pattern.</li>
                        <li><b>Vertical Beamwidth</b>: The angle between the half-power points in the vertical plane of the antenna pattern.</li>
                      </ul>
                      <em class="fs-md-15 fs-lg-16 text-paragraph lh-base">These measurements are essential for understanding how an antenna performs in different scenarios.</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'AntennaBeamwidthCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
