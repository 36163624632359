<template>
    <BreadCrumb PageTitle="Absolute Value Calculator" />
  
    <div class="custom-container mt-5">
      <h1 class="text-center">Absolute Value Calculator</h1>
      <p class="fs-6 text-center mb-4">Calculate expressions with absolute values. Use |x| for absolute value notation.</p>
  
      <div class="card mb-4">
        <div class="card-body">
          <div class="mb-3">
            <label for="expression" class="form-label">Enter an expression:</label>
            <input type="text" id="expression" v-model="expression" class="form-control" placeholder="e.g., 15(|65-85|)+23 or |15(65-85)+23|">
          </div>
          <div class="mb-3">
            <label for="decimalPlaces" class="form-label">Decimal Places:</label>
            <input type="number" id="decimalPlaces" v-model="decimalPlaces" min="0" max="10" class="form-control">
          </div>
          <div class="mb-3 row">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <button @click="calculateResult" class="btn btn-primary w-100">Calculate</button>
            </div>
            <div class="col-12 col-md-6">
              <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <h4>Result:</h4>
          <p v-if="result !== null">
            {{ expression }} = {{ formatNumber(result) }}
          </p>
          <p v-if="error" class="text-danger">{{ error }}</p>
        </div>
      </div>
  
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0">Example Calculations</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Expression</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(example, index) in examples" :key="index">
                  <td>{{ example.expression }}</td>
                  <td>{{ formatNumber(example.result) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
      <div><AbsoluteValueCalculatorFAQ /></div>
    </div>
  </template>
  
  <script>
import { ref, computed } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AbsoluteValueCalculatorFAQ from '../FAQs/absoluteValueCalculatorFAQ.vue';

export default {
  name: 'AbsoluteValueCalculator',
  components: {
    BreadCrumb,
    AbsoluteValueCalculatorFAQ
  },
  setup() {
    const expression = ref('');
    const decimalPlaces = ref(2);
    const error = ref('');
    const result = ref(null);

    const calculateResult = () => {
      if (expression.value.trim() === '') {
        result.value = null;
        error.value = '';
        return;
      }

      try {
        result.value = evaluateExpression(expression.value);
        error.value = '';
      } catch (e) {
        error.value = 'Invalid expression. Please check your input.';
        result.value = null;
      }
    };

    const evaluateExpression = (expr) => {
      // Replace absolute value notation with Math.abs()
      expr = expr.replace(/\|([^|]+)\|/g, 'Math.abs($1)');
      
      // Add multiplication operator for implicit multiplication
      expr = expr.replace(/(\d+)\(/g, '$1*(');
      expr = expr.replace(/\)(\d+)/g, ')*$1');
      expr = expr.replace(/(\d+)(?=Math\.abs)/g, '$1*');
      
      // Use Function constructor to evaluate the expression
      return Function(`'use strict'; return (${expr});`)();
    };

    const clearValues = () => {
      expression.value = '';
      result.value = null;
      error.value = '';
    };

    const formatNumber = (value) => {
      if (value === null) return '';
      return Number(value).toFixed(decimalPlaces.value);
    };

    const examples = computed(() => [
      { expression: '15(|65-85|)+23', result: evaluateExpression('15(|65-85|)+23') },
      { expression: '|15(65-85)+23|', result: evaluateExpression('|15(65-85)+23|') },
      { expression: '|-5| + |3|', result: evaluateExpression('|-5| + |3|') },
      { expression: '2 * |10 - 15|', result: evaluateExpression('2 * |10 - 15|') },
      { expression: '|30 - 40| - |20 - 25|', result: evaluateExpression('|30 - 40| - |20 - 25|') }
    ]);

    return {
      expression,
      decimalPlaces,
      result,
      error,
      calculateResult,
      clearValues,
      formatNumber,
      examples
    };
  }
};
</script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    padding: 0 0.5rem;
  }
}
  
  @media (max-width: 767px) {
    .col-12 {
      margin-bottom: 0.5rem;
    }
  }
  </style>