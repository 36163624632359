<template>
    <BreadCrumb PageTitle="Text to ASCII Art Generator" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">Text to ASCII Art Generator</h1>
      <p class="text-center fs-6">Convert any input text to ASCII art</p>
  
      <!-- Input Section -->
      <div class="form-group">
        <label for="textInput" class="fs-6">Your Text</label>
        <textarea
          id="textInput"
          class="form-control fs-6"
          rows="2"
          v-model="textInput"
          placeholder="Enter your text here..."
        ></textarea>
        <small class="text-muted">Character Count: {{ textInput.length }}</small>
      </div>
  
      <!-- Font Selection -->
      <div class="form-group mt-3">
        <label for="fontSelect" class="fs-6">Choose ASCII Art Font</label>
        <select id="fontSelect" class="form-control" v-model="selectedFont">
          <option v-for="(font, index) in availableFonts" :key="index" :value="font">
            {{ font }}
          </option>
        </select>
      </div>
  
      <!-- Convert and Reset Buttons -->
      <div class="row mt-3">
        <div class="col-md-6">
          <button class="btn btn-primary w-100" @click="convertTextToAscii">Convert to ASCII Art</button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-warning w-100" @click="resetText">Reset Text</button>
        </div>
      </div>
  
      <!-- Error Message -->
      <div v-if="error" class="alert alert-danger mt-3">
        {{ error }}
      </div>
  
      <!-- ASCII Art Output Section -->
      <div v-if="asciiArt" class="mt-4">
        <h3>ASCII Art Output ({{ selectedFont }})</h3>
        <pre class="form-control fs-6" readonly>{{ asciiArt }}</pre>
  
        <!-- Action Buttons -->
        <div class="mt-2 mb-2">
            <button class="btn btn-danger w-100" @click="copyToClipboard">
                <i :class="clipboardIcon"></i> {{ clipboardText }}
            </button>
        </div>
      </div>
  
      <!-- FAQ Section -->
      <div class="mt-50 mb-50">
        <h2>Text to ASCII Art Generator FAQ</h2>
        <div v-for="(item, index) in faqList" :key="index" class="mb-4">
          <h5>{{ item.question }}</h5>
          <p v-html="item.answer" class="fs-6"></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // Import figlet for browser
  import figlet from 'figlet';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  
  export default {
    components: {
      BreadCrumb
    },
    data() {
      return {
        textInput: '',
        asciiArt: '',
        error: '',
        selectedFont: 'Standard', // Default font
        availableFonts: [
            '1Row', '3-D', '3D Diagonal', '3D-ASCII', '3d', '3d_diagonal', '3x5', '4Max', '5 Line Oblique', '5lineoblique',
            'Acrobatic', 'Alligator', 'Alligator2', 'alligator3', 'Alpha', 'Alphabet', 'AMC 3 Line', 'AMC 3 Liv1', 'AMC AAA01',
            'AMC Neko', 'AMC Razor', 'AMC Razor2', 'AMC Slash', 'AMC Slider', 'AMC Thin', 'AMC Tubes', 'AMC Untitled',
            'amc3line', 'amc3liv1', 'amcaaa01', 'amcneko', 'amcrazo2', 'amcrazor', 'amcslash', 'amcslder', 'amcthin',
            'amctubes', 'amcun1', 'ANSI Regular', 'ANSI Shadow', 'Arrows', 'ASCII New Roman', 'ascii_new_roman', 'Avatar', 
            'B1FF', 'Banner', 'Banner3-D', 'Banner3', 'Banner4', 'Barbwire', 'Basic', 'Bear', 'Bell', 'Benjamin', 'Big Chief', 
            'Big Money-ne', 'Big Money-nw', 'Big Money-se', 'Big Money-sw', 'Big', 'bigchief', 'Bigfig', 'Binary', 'Block', 
            'Blocks', 'Bloody', 'Bolger', 'Braced', 'Bright', 'Broadway KB', 'Broadway', 'broadway_kb', 'Bubble', 'Bulbhead', 
            'calgphy2', 'Caligraphy', 'Caligraphy2', 'Calvin S', 'Cards', 'Catwalk', 'Chiseled', 'Chunky', 'Coinstak', 'Cola', 
            'Colossal', 'Computer', 'Contessa', 'Contrast', 'cosmic', 'Cosmike', 'Crawford', 'Crawford2', 'Crazy', 'Cricket', 
            'Cursive', 'Cyberlarge', 'Cybermedium', 'Cybersmall', 'Cygnet', 'DANC4', 'Dancing Font', 'dancingfont', 'Decimal', 
            'Def Leppard', 'defleppard', 'Delta Corps Priest 1', 'Diamond', 'Diet Cola', 'dietcola', 'Digital', 'Doh', 'Doom', 
            'DOS Rebel', 'dosrebel', 'Dot Matrix', 'dotmatrix', 'Double Shorts', 'Double', 'doubleshorts', 'Dr Pepper', 
            'drpepper', 'DWhistled', 'Efti Chess', 'Efti Font', 'Efti Italic', 'Efti Piti', 'Efti Robot', 'Efti Wall', 'Efti Water', 
            'eftichess', 'eftifont', 'eftipiti', 'eftirobot', 'eftitalic', 'eftiwall', 'eftiwater', 'Electronic', 'Elite', 'Epic', 
            'Fender', 'Filter', 'Fire Font-k', 'Fire Font-s', 'fire_font-k', 'fire_font-s', 'Flipped', 'Flower Power', 'flowerpower', 
            'Four Tops', 'fourtops', 'Fraktur', 'Fun Face', 'Fun Faces', 'funface', 'funfaces', 'Fuzzy', 'Georgi16', 'Georgia11', 
            'Ghost', 'Ghoulish', 'Glenyn', 'Goofy', 'Gothic', 'Graceful', 'Gradient', 'Graffiti', 'Greek', 'halfiwi', 'Heart Left', 
            'Heart Right', 'heart_left', 'heart_right', 'Henry 3D', 'henry3d', 'Hex', 'Hieroglyphs', 'Hollywood', 'Horizontal Left', 
            'Horizontal Right', 'horizontalleft', 'horizontalright', 'ICL-1900', 'Impossible', 'Invita', 'Isometric1', 'Isometric2', 
            'Isometric3', 'Isometric4', 'Italic', 'Ivrit', 'Jacky', 'Jazmine', 'Jerusalem', 'JS Block Letters', 'JS Bracket Letters', 
            'JS Capital Curves', 'JS Cursive', 'JS Stick Letters', 'Katakana', 'Kban', 'Keyboard', 'Knob', 'koholint', 'kompaktblk', 
            'Konto Slant', 'Konto', 'kontoslant', 'Larry 3D 2', 'Larry 3D', 'larry3d', 'LCD', 'Lean', 'Letters', 'Lil Devil', 'lildevil', 
            'Line Blocks', 'lineblocks', 'Linux', 'Lockergnome', 'Madrid', 'Marquee', 'Maxfour', 'maxiwi', 'Merlin1', 'Merlin2', 'Mike', 
            'Mini', 'miniwi', 'Mirror', 'Mnemonic', 'Modular', 'Morse', 'Morse2', 'Moscow', 'Mshebrew210', 'Muzzle', 'Nancyj-Fancy', 
            'Nancyj-Improved', 'Nancyj-Underlined', 'Nancyj', 'Nipples', 'NScript', 'NT Greek', 'ntgreek', 'NV Script', 'O8', 'Octal', 
            'Ogre', 'Old Banner', 'oldbanner', 'OS2', 'Patorjk\'s Cheese', 'Patorjk-HeX', 'Pawp', 'Peaks Slant', 'Peaks', 'peaksslant', 
            'Pebbles', 'Pepper', 'Poison', 'Puffy', 'Puzzle', 'Pyramid', 'Rammstein', 'Rectangles', 'Red Phoenix', 'red_phoenix', 
            'Relief', 'Relief2', 'rev', 'Reverse', 'Roman', 'Rot13', 'Rotated', 'Rounded', 'Rowan Cap', 'rowancap', 'Rozzo', 'Runic', 
            'Runyc', 'S Blood', 's-relief', 'Santa Clara', 'santaclara', 'sblood', 'Script', 'Serifcap', 'Shadow', 'Shimrod', 'Short', 
            'six-fo', 'SL Script', 'Slant Relief', 'Slant', 'Slide', 'slscript', 'Small Caps', 'Small Isometric1', 'Small Keyboard', 
            'Small Poison', 'Small Script', 'Small Shadow', 'Small Slant', 'Small Tengwar', 'Small', 'smallcaps', 'smisome1', 
            'smkeyboard', 'smpoison', 'smscript', 'smshadow', 'smslant', 'smtengwar', 'Soft', 'Speed', 'Spliff', 'Stacey', 'Stampate', 
            'Stampatello', 'Standard', 'Star Strips', 'Star Wars', 'starstrips', 'starwars', 'Stellar', 'stencil', 'Stforek', 'Stick Letters', 
            'Stop', 'Straight', 'Stronger Than All', 'Sub-Zero', 'Swamp Land', 'swampland', 'Swan', 'Sweet', 'Tanja', 'Tengwar', 'Term', 
            'Test1', 'The Edge', 'Thick', 'Thin', 'THIS', 'Thorned', 'Three Point', 'threepoint', 'Ticks Slant', 'Ticks', 'ticksslant', 
            'Tiles', 'Tinker-Toy', 'Tombstone', 'Train', 'Trek', 'Tsalagi', 'Tubular', 'Twisted', 'Two Point', 'twopoint', 'ublk', 
            'Univers', 'USA Flag', 'usaflag', 'Varsity', 'Wavy', 'Weird', 'Wet Letter', 'wetletter', 'Whimsy', 'Wow'
        ],
        clipboardIcon: 'bi bi-clipboard',
        clipboardText: 'Copy to Clipboard',
        faqList: [
          {
            question: '1. What is the Text to ASCII Art Generator?',
            answer: 'This tool converts plain text into beautiful ASCII art using the <code>figlet</code> library, which generates art from text using characters.'
          },
          {
            question: '2. How does the ASCII art conversion work?',
            answer: 'The tool uses the <code>figlet</code> library to generate ASCII art. You enter text, and it is transformed into ASCII using predefined fonts.'
          },
          {
            question: '3. What is ASCII art?',
            answer: 'ASCII art is a graphic design technique that uses characters from the ASCII set (letters, numbers, symbols) to form images, logos, or stylized text.'
          },
          {
            question: '4. How can I use the generated ASCII art?',
            answer: 'Once the art is generated, you can copy it to your clipboard and paste it anywhere—text documents, social media, or code files.'
          },
          {
            question: '5. How do I reset the text input?',
            answer: 'You can click the "Reset Text" button to clear both the input and the ASCII art output areas.'
          },
          {
            question: '6. Is there a character limit for generating ASCII art?',
            answer: 'There’s no strict limit, but generating ASCII art for very long text may result in large output. Keep your input to a reasonable length for best results.'
          },
          {
            question: '7. What does the "Copy to Clipboard" button do?',
            answer: 'It allows you to quickly copy the generated ASCII art to your system clipboard. You’ll get a visual confirmation when the text is copied.'
          },
          {
            question: '8. Can I choose different ASCII art styles?',
            answer: 'Currently, this tool uses a default ASCII font from the figlet library. In future versions, you may be able to choose different ASCII art fonts.'
          }
        ]
      };
    },
    methods: {
      convertTextToAscii() {
        this.error = '';
  
        if (!this.textInput.trim()) {
          this.error = 'Please enter some text to convert.';
          return;
        }
  
        // Dynamically load the selected font file
        const fontUrl = `/fonts/figlet/${this.selectedFont}.flf`; // Path to your local fonts
  
        fetch(fontUrl)
          .then(response => response.text())
          .then(fontData => {
            // Load the selected font into figlet
            figlet.parseFont(this.selectedFont, fontData);
  
            // Convert the text to ASCII using the selected font
            figlet.text(this.textInput, { font: this.selectedFont }, (err, data) => {
              if (err) {
                this.error = 'Error generating ASCII art. Please try again.';
              } else {
                this.asciiArt = data;
              }
            });
          })
          .catch(err => {
            this.error = 'Error loading the font file.';
          });
      },
      resetText() {
        this.textInput = '';
        this.asciiArt = '';
        this.error = '';
      },
      copyToClipboard() {
        navigator.clipboard.writeText(this.asciiArt).then(() => {
          this.clipboardIcon = 'bi bi-clipboard-check';
          this.clipboardText = 'Copied!';
          setTimeout(() => {
            this.clipboardIcon = 'bi bi-clipboard';
            this.clipboardText = 'Copy to Clipboard';
          }, 2000);
        });
      }
    }
  };
  </script>
  
  <style>
  .custom-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  </style>
  