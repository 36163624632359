<template>
  <BreadCrumb PageTitle="Quadratic Equation Solver" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Quadratic Equation Solver</h1>
    <p class="fs-6 text-center mb-4">
      Solve quadratic equations of the form \( ax^2 + bx + c = 0 \). Enter the coefficients to find the roots and other properties of the quadratic equation.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Coefficient a -->
        <div class="mb-3">
          <label for="coefficientA" class="form-label">Coefficient a:</label>
          <input
            type="text"
            id="coefficientA"
            v-model="coefficientA"
            class="form-control"
            placeholder="e.g., 1"
          />
        </div>

        <!-- Input for Coefficient b -->
        <div class="mb-3">
          <label for="coefficientB" class="form-label">Coefficient b:</label>
          <input
            type="text"
            id="coefficientB"
            v-model="coefficientB"
            class="form-control"
            placeholder="e.g., -3"
          />
        </div>

        <!-- Input for Coefficient c -->
        <div class="mb-3">
          <label for="coefficientC" class="form-label">Coefficient c:</label>
          <input
            type="text"
            id="coefficientC"
            v-model="coefficientC"
            class="form-control"
            placeholder="e.g., 2"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="solveQuadratic" class="btn btn-primary w-100">Solve</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Equation: {{ result.equation }}</p>
          <p class="fs-5">Discriminant (D): {{ result.discriminant }}</p>
          <p class="fs-5">Roots:</p>
          <ul class="fs-5">
            <li v-if="result.roots.real">{{ result.roots.x1 }}</li>
            <li v-if="result.roots.real && result.roots.x2">{{ result.roots.x2 }}</li>
            <li v-if="!result.roots.real">{{ result.roots.complex1 }}</li>
            <li v-if="!result.roots.real && result.roots.complex2">{{ result.roots.complex2 }}</li>
          </ul>
          <p class="fs-5">Vertex: ({{ result.vertex.h }}, {{ result.vertex.k }})</p>
          <p class="fs-5">Axis of Symmetry: x = {{ result.vertex.h }}</p>
          <p class="fs-5">{{ result.explanation }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the coefficients \( a \), \( b \), and \( c \) of the quadratic equation \( ax^2 + bx + c = 0 \).</li>
        <li>Click "Solve" to compute the roots, discriminant, vertex, and axis of symmetry.</li>
        <li>The result will display all the computed properties and an explanation based on the discriminant.</li>
        <li>Click "Clear" to reset the input fields and results.</li>
      </ul>

      <h5 class="alert-heading">Quadratic Formula:</h5>
      <div class="fs-6">
        The roots of the quadratic equation \( ax^2 + bx + c = 0 \) are given by:
      </div>
      <div>
        \[
        x = \frac{-b \pm \sqrt{b^2 - 4ac}}{2a}
        \]
      </div>
      <p class="fs-6">
        Where:
        <ul>
          <li>\( a \) is the coefficient of \( x^2 \)</li>
          <li>\( b \) is the coefficient of \( x \)</li>
          <li>\( c \) is the constant term</li>
          <li>\( D = b^2 - 4ac \) is the discriminant</li>
        </ul>
      </p>

      <h5 class="alert-heading">Understanding the Discriminant:</h5>
      <ul class="fs-6">
        <li>
          If \( D > 0 \): Two distinct real roots.
        </li>
        <li>
          If \( D = 0 \): One real repeated root.
        </li>
        <li>
          If \( D &lt; 0 \): Two complex conjugate roots.
        </li>
      </ul>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For \( a = 1 \), \( b = -3 \), \( c = 2 \):<br />
          Discriminant \( D = (-3)^2 - 4 \times 1 \times 2 = 1 \) (Positive)<br />
          Roots: \( x = \frac{3 \pm 1}{2} \Rightarrow x = 2 \) or \( x = 1 \)
        </li>
        <li>
          For \( a = 1 \), \( b = 2 \), \( c = 1 \):<br />
          Discriminant \( D = (2)^2 - 4 \times 1 \times 1 = 0 \) (Zero)<br />
          Root: \( x = \frac{-2}{2} \Rightarrow x = -1 \)
        </li>
        <li>
          For \( a = 1 \), \( b = 0 \), \( c = 1 \):<br />
          Discriminant \( D = 0^2 - 4 \times 1 \times 1 = -4 \) (Negative)<br />
          Roots: \( x = \frac{0 \pm i\sqrt{4}}{2} \Rightarrow x = i \) or \( x = -i \)
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const coefficientA = ref("");
    const coefficientB = ref("");
    const coefficientC = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to solve the quadratic equation
    const solveQuadratic = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the input values
        const a = parseFloat(coefficientA.value.trim());
        const b = parseFloat(coefficientB.value.trim());
        const c = parseFloat(coefficientC.value.trim());

        if (isNaN(a) || isNaN(b) || isNaN(c)) {
          throw new Error("Please enter valid numeric values for all coefficients.");
        }

        if (a === 0) {
          throw new Error("Coefficient 'a' cannot be zero in a quadratic equation.");
        }

        // Calculate the discriminant
        const discriminant = b * b - 4 * a * c;

        // Calculate roots based on discriminant
        let roots = {};
        let explanation = "";

        if (discriminant > 0) {
          // Two distinct real roots
          const sqrtD = Math.sqrt(discriminant);
          const x1 = (-b + sqrtD) / (2 * a);
          const x2 = (-b - sqrtD) / (2 * a);
          roots = {
            real: true,
            x1: `x₁ = ${x1.toFixed(6)}`,
            x2: `x₂ = ${x2.toFixed(6)}`,
          };
          explanation = "The equation has two distinct real roots because the discriminant is positive.";
        } else if (discriminant === 0) {
          // One real repeated root
          const x = -b / (2 * a);
          roots = {
            real: true,
            x1: `x = ${x.toFixed(6)}`,
          };
          explanation = "The equation has one real repeated root because the discriminant is zero.";
        } else {
          // Two complex conjugate roots
          const sqrtD = Math.sqrt(-discriminant);
          const realPart = (-b / (2 * a)).toFixed(6);
          const imaginaryPart = (sqrtD / (2 * a)).toFixed(6);
          roots = {
            real: false,
            complex1: `x₁ = ${realPart} + ${imaginaryPart}i`,
            complex2: `x₂ = ${realPart} - ${imaginaryPart}i`,
          };
          explanation = "The equation has two complex conjugate roots because the discriminant is negative.";
        }

        // Calculate vertex and axis of symmetry
        const h = -b / (2 * a);
        const k = a * h * h + b * h + c;

        result.value = {
          equation: `${a}x² ${b >= 0 ? "+" : "-"} ${Math.abs(b)}x ${c >= 0 ? "+" : "-"} ${Math.abs(c)} = 0`,
          discriminant: discriminant.toFixed(6),
          roots: roots,
          vertex: {
            h: h.toFixed(6),
            k: k.toFixed(6),
          },
          explanation: explanation,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      coefficientA.value = "";
      coefficientB.value = "";
      coefficientC.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      coefficientA,
      coefficientB,
      coefficientC,
      result,
      error,
      solveQuadratic,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
