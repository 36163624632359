<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Nursery Space Calculator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the Furniture Area?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Enter the total area occupied by furniture in square meters. This area will be subtracted from the total room area for the calculation.
                                </p>
                                <div class="floor-plan text-center">
                                    <svg height="200" width="300">
                                        <rect x="10" y="10" rx="5" width="280" height="180" style="fill: none;stroke: #d7e4ff;stroke-width: 2;"></rect>
                                        <rect x="50" y="50" rx="5" width="50" height="30" style="fill: grey;stroke: black;stroke-width: 1;"></rect>
                                        <rect x="200" y="100" rx="5" width="40" height="40" style="fill: grey;stroke: black;stroke-width: 1;"></rect>
                                        <text x="20" y="100" font-family="Verdana" font-size="10" fill="#6b9bff">50m x 30m = 1500</text>
                                        <text x="160" y="160" font-family="Verdana" font-size="10" fill="#6b9bff">40m x 40m = 1600</text>
                                        <text x="50" y="45" font-family="Verdana" font-size="10" fill="#6b9bff">50m</text>
                                        <text x="25" y="65" font-family="Verdana" font-size="10" fill="#6b9bff">40m</text>
                                        <text x="200" y="95" font-family="Verdana" font-size="10" fill="#6b9bff">30m</text>
                                        <text x="175" y="122" font-family="Verdana" font-size="10" fill="#6b9bff">40m</text>
                                    </svg>
                                </div>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The grey areas represent furniture. These areas are subtracted from the total room area to get the effective area for children.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How is the total number of children calculated?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The total number of children is calculated using the formula:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base" style="font-family: monospace; margin-left: 20px; color: #ea868f;">
                                    Total Children = floor((Room Width × Room Length - Furniture Area) / Area Per Child)
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    For example, if the room is 10m wide and 20m long, and the furniture occupies 30m<sup>2</sup>, and you select "Under 2 year olds" which requires 3.5m<sup>2</sup> per child:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base" style="font-family: monospace; margin-left: 20px; color: #ea868f;">
                                    Total Children = floor((10 × 20 - 30) / 3.5) = floor(170 / 3.5) = 48
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What do the results mean?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The "Total Children" field shows the maximum number of children that can be accommodated in the room. It is calculated as described above.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The "Total Room Area" is simply the Room Width multiplied by the Room Length:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base" style="font-family: monospace; margin-left: 20px; color: #ea868f;">
                                    Total Room Area = Room Width × Room Length
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    For example, if the room is 10m wide and 20m long:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base" style="font-family: monospace; margin-left: 20px; color: #ea868f;">
                                    Total Room Area = 10 × 20 = 200m<sup>2</sup>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>How do I select the age group?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Use the "Select Year Group" dropdown to choose the age group of the children. The calculator will use this to determine the area per child requirement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'NurserySpaceCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
