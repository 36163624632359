<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on HTML to CSV Converter</h3>
                    </div>
                    <!-- FAQ 1 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the HTML to CSV Converter?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to CSV Converter is a tool designed to extract table data from an HTML document and convert it into a CSV (Comma Separated Values) format. CSV is a widely used format for data storage and sharing, especially in spreadsheets and databases.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 2 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the HTML to CSV Converter?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Using the HTML to CSV Converter is straightforward:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Paste the HTML code containing the table into the input field.</li>
                                    <li>Click on the <strong>Convert</strong> button.</li>
                                    <li>The extracted table data will be converted into a CSV format and displayed in the output field.</li>
                                    <li>Click on the <strong>Download</strong> button to save the CSV file to your device.</li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This process allows you to easily extract and use data from HTML tables in applications that support CSV.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 3 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What is a CSV file?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    CSV (Comma Separated Values) is a simple text format used to store tabular data. Each line of a CSV file corresponds to a row in the table, and the values in each row are separated by commas. For example:
                                </p>
                                <pre>
                                    Name, Age, City
                                    Alice, 30, New York
                                    Bob, 25, San Francisco
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    In this example, the first line represents the headers, and the subsequent lines represent rows of data.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    CSV is a widely accepted format for exchanging data between different systems, including spreadsheet programs like Microsoft Excel and Google Sheets.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 4 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>What kind of HTML tables can be converted to CSV?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The converter can process most HTML tables that are correctly structured with &lt;table&gt;, &lt;tr&gt; (table row), &lt;th&gt; (table header), and &lt;td&gt; (table data) tags. For example, the following HTML table can be converted:
                                </p>
                                <pre>
&lt;table&gt;
    &lt;tr&gt;&lt;th&gt;Name&lt;/th&gt;&lt;th&gt;Age&lt;/th&gt;&lt;th&gt;City&lt;/th&gt;&lt;/tr&gt;
    &lt;tr&gt;&lt;td&gt;Alice&lt;/td&gt;&lt;td&gt;30&lt;/td&gt;&lt;td&gt;New York&lt;/td&gt;&lt;/tr&gt;
    &lt;tr&gt;&lt;td&gt;Bob&lt;/td&gt;&lt;td&gt;25&lt;/td&gt;&lt;td&gt;San Francisco&lt;/td&gt;&lt;/tr&gt;
&lt;/table&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    However, the converter may not work properly if the table contains complex elements like nested tables, colspan or rowspan attributes. It is recommended to use simple tables for the best results.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 5 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>How does the conversion work?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to CSV Converter reads the HTML table structure and extracts the data from each cell (&lt;td&gt;) and header (&lt;th&gt;). The data is then placed into a CSV format, where each row of the table becomes a line in the CSV, and each cell value is separated by a comma. For example, the HTML table:
                                </p>
                                <pre>
&lt;table&gt;
    &lt;tr&gt;&lt;th&gt;Product&lt;/th&gt;&lt;th&gt;Price&lt;/th&gt;&lt;/tr&gt;
    &lt;tr&gt;&lt;td&gt;Book&lt;/td&gt;&lt;td&gt;$10&lt;/td&gt;&lt;/tr&gt;
&lt;/table&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Is converted into the CSV format:
                                </p>
                                <pre>
Product, Price
Book, $10
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The CSV file can then be opened and manipulated in spreadsheet applications or data-processing tools.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 6 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>What are the limitations of the HTML to CSV Converter?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to CSV Converter is best suited for simple HTML tables. It may not handle:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Tables with merged cells using <code>colspan</code> or <code>rowspan</code> attributes.</li>
                                    <li>Complex table structures like nested tables.</li>
                                    <li>Non-tabular elements mixed with table data.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    In such cases, the output CSV may not be correctly formatted. It is recommended to use clean and well-structured HTML tables for accurate conversions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 7 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>Why would I need to convert HTML to CSV?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Converting HTML tables to CSV is useful when you need to work with the data in spreadsheet applications or share it in a more portable format. Many data analytics platforms, databases, and spreadsheet tools accept CSV files, making it easier to manipulate and analyze data.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    For example, you might have a web page with a list of products in an HTML table, and you want to export that data into Excel for further analysis. The HTML to CSV Converter simplifies this task by quickly transforming the table into a CSV file.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Additional FAQs as needed -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'htmlToCSVFAQ',
};
</script>

<style scoped>
  /* Add any specific styles for FAQ layout */
</style>
