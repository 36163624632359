<template>
  <BreadCrumb PageTitle="Antilog Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Antilog Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter the exponent and base to calculate the antilog. By default, the base is 10.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Exponent Input -->
        <div class="mb-3">
          <label for="exponent" class="form-label">Exponent (x):</label>
          <input
            type="text"
            id="exponent"
            v-model="exponent"
            class="form-control"
            placeholder="e.g., 2"
          />
        </div>

        <!-- Base Input -->
        <div class="mb-3">
          <label for="base" class="form-label">Base (b):</label>
          <input
            type="text"
            id="base"
            v-model="base"
            class="form-control"
            placeholder="e.g., 10 (default)"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateAntilog" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <p v-if="result !== null">
          Antilog<sub>{{ base }}</sub>({{ exponent }}) = <strong>{{ result }}</strong>
        </p>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the exponent value in the "Exponent" field.</li>
        <li>Enter the base value in the "Base" field. If left empty, the default base of 10 will be used.</li>
        <li>Click "Calculate" to get the antilog result.</li>
      </ul>
      <h5 class="alert-heading">Formula for Calculating Antilog:</h5>
      <p class="fs-6">
        The formula for calculating the antilog is:
      </p>
      <code class="fs-5">
        Antilog<sub>b</sub>(x) = b^x
      </code>
      <p class="fs-6">
        where <strong>b</strong> is the base and <strong>x</strong> is the exponent.
      </p>
      <p class="fs-6">
        Example: If <strong>x = 2</strong> and <strong>b = 10</strong>, then:
        <br />
        Antilog<sub>10</sub>(2) = 10^2 = 100
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const exponent = ref("");
    const base = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate the antilog
    const calculateAntilog = () => {
      error.value = "";
      result.value = null;

      // Validate inputs
      if (!exponent.value.trim()) {
        error.value = "Please enter the exponent value.";
        return;
      }

      let baseValue = base.value.trim() ? parseFloat(base.value) : 10;
      let exponentValue = parseFloat(exponent.value.trim());

      if (isNaN(exponentValue)) {
        error.value = "Please enter a valid exponent value.";
        return;
      }

      if (isNaN(baseValue) || baseValue <= 0) {
        error.value = "Please enter a valid base value greater than 0.";
        return;
      }

      // Calculate antilog
      result.value = Math.pow(baseValue, exponentValue).toFixed(4);
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      exponent.value = "";
      base.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      exponent,
      base,
      result,
      error,
      calculateAntilog,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

strong {
  font-weight: bold;
}

.dark h5 {
    color: #2b3035 !important;
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
