<template>
	<BreadCrumb PageTitle="Random Username Generator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Random Username Generator</h1>
	  <p class="fs-6 text-center mb-4">Generate random unique and interesting usernames using the Random User Generator.</p>
	  <div class="card">
		<div class="card-body">
		  <div class="mb-3">
			<label for="nameCount" class="form-label">Number of usernames to generate:</label>
			<input type="number" id="nameCount" v-model.number="nameCount" class="form-control" min="1" max="10">
		  </div>
		  <div class="mb-3">
			<label for="customWord" class="form-label">Custom word (optional):</label>
			<input type="text" id="customWord" v-model="customWord" class="form-control" maxlength="15">
		  </div>
		  <div class="mb-3">
			<label class="form-label">Username Style:</label>
			<div class="form-check">
			  <input type="radio" id="styleSimple" value="simple" v-model="usernameStyle" class="form-check-input">
			  <label for="styleSimple" class="form-check-label">Simple (e.g., cooluser)</label>
			</div>
			<div class="form-check">
			  <input type="radio" id="styleWithNumber" value="withNumber" v-model="usernameStyle" class="form-check-input">
			  <label for="styleWithNumber" class="form-check-label">With Number (e.g., cooluser42)</label>
			</div>
		  </div>
		  <button @click="generateUsernames" class="btn btn-primary w-100 mb-3">Generate Usernames</button>
		  <ul class="list-group">
			<li v-for="(username, index) in generatedUsernames" :key="index" class="list-group-item d-flex justify-content-between align-items-center fs-5">
			  {{ username }}
			  <button @click="copyUsername(username, index)" class="btn btn-sm p-0">
				<i class="bi" :class="copiedIndex === index ? 'bi-clipboard-check' : 'bi-clipboard'"></i>
			  </button>
			</li>
		  </ul>
		</div>
	  </div>
	  
	  <!-- FAQ Section -->
	  <div class="mt-5">
		<RandomUsernameGeneratorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { kWords } from '../../components/JS/ScrabbleWords.js';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import RandomUsernameGeneratorFAQ from '../FAQs/randomUsernameGeneratorFAQ.vue';
  
  export default {
	name: 'RandomUsernameGenerator',
	components: {
	  BreadCrumb,
	  RandomUsernameGeneratorFAQ
	},
	setup() {
	  const filteredWords = computed(() => {
		const excludedWords = ['sexual', 'witch', 'wizard', 'porn', 'demon', 'satan', 'racist'];
		return Object.keys(kWords).filter(word => 
		  !excludedWords.some(excluded => word.includes(excluded)) && word.length >= 3
		);
	  });
  
	  const nameCount = ref(3);
	  const customWord = ref('');
	  const usernameStyle = ref('simple');
	  const generatedUsernames = ref([]);
	  const copiedIndex = ref(null);
  
	  const generateNumber = () => Math.floor(Math.random() * 999) + 1; // 1 to 999
  
	  const getRandomWord = () => {
		return filteredWords.value[Math.floor(Math.random() * filteredWords.value.length)];
	  };
  
	  const generateUsername = () => {
		let username = '';
  
		if (customWord.value) {
		  username = customWord.value.toLowerCase().replace(/\s/g, '');
		  username += getRandomWord();
		} else {
		  username = getRandomWord() + getRandomWord();
		}
  
		if (usernameStyle.value === 'withNumber') {
		  const number = generateNumber().toString();
		  username += number;
		}
  
		return username;
	  };
  
	  const generateUsernames = () => {
		generatedUsernames.value = Array(nameCount.value).fill().map(() => generateUsername());
	  };
  
	  const copyUsername = (username, index) => {
		navigator.clipboard.writeText(username).then(() => {
		  copiedIndex.value = index;
		  setTimeout(() => {
			copiedIndex.value = null;
		  }, 2000);
		}).catch(err => {
		  console.error('Failed to copy username: ', err);
		});
	  };
  
	  return {
		nameCount,
		customWord,
		usernameStyle,
		generatedUsernames,
		copiedIndex,
		generateUsernames,
		copyUsername
	  };
	}
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  .bi-clipboard, .bi-clipboard-check {
	font-size: 1.2rem;
  }
  </style>