<template>
  <BreadCrumb PageTitle="Nursery Ratio Calculator" />
  <div class="custom-container mt-5 mb-50">
    <h1 class="text-center">Nursery Ratio Calculator</h1>
    <p class="fs-6 text-center mb-4">Optimize staff-to-child ratio in your nursery with our EYFS Staff/Child ratio calculator!</p>
    <div class="row">
      <div class="col-md-12">
        <div class="row mt-2 fs-6">
          <div class="mb-3" v-for="(ageGroup, index) in ageGroups" :key="index">
            <div class="d-flex justify-content-between ratio-calc">
              <p>{{ ageGroup.label }}</p>
              <p>Total staffs needed</p>
            </div>
            <div class="input-group">
              <input
                :id="ageGroup.id"
                class="form-control"
                type="number"
                placeholder="0"
                min="0"
                max="99999"
                v-model="ageGroup.children"
                @input="calculateStaff"
              />
              <input
                :id="`${ageGroup.id}_staffs`"
                class="form-control"
                type="text"
                placeholder="0"
                readonly
                :value="ageGroup.staff"
              />
            </div>
          </div>
        </div>
        <div class="form-check mb-4">
          <input
            id="preSchool"
            class="form-check-input"
            type="checkbox"
            v-model="hasQualifiedTeacher"
            @change="calculateStaff"
          />
          <label class="form-check-label" for="preSchool">Pre-school has got a qualified teacher</label>
        </div>
        <div class="col">
          <button
            id="btn_clear"
            class="btn btn-primary btn-hover w-100"
            title="Clear all"
            @click="clearAll"
          >
            <i class="bi bi-arrow-repeat"></i> Clear
          </button>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <NurseryRatioCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import NurseryRatioCalculatorFAQ from '../FAQs/nurseryRatioCalculatorFAQ.vue';

export default {
  name: 'NurseryStaffingCalculator',
  components: {
    BreadCrumb,
    NurseryRatioCalculatorFAQ
  },
  setup() {
    const ageGroups = ref([
      { id: 'under_2_years', label: 'Under 2 year olds', children: 0, staff: 0, ratio: 3 },
      { id: '2_years', label: '2 year olds', children: 0, staff: 0, ratio: 5 },
      { id: '3_5_years', label: '3 to 5 year olds', children: 0, staff: 0, ratio: 8 },
    ])

    const hasQualifiedTeacher = ref(false)

    const calculateStaff = () => {
      ageGroups.value.forEach((group) => {
        let ratio = group.ratio
        if (group.id === '3_5_years' && hasQualifiedTeacher.value) {
          ratio = 13
        }
        group.staff = Math.ceil(group.children / ratio)
      })
    }

    const clearAll = () => {
      ageGroups.value.forEach((group) => {
        group.children = 0
        group.staff = 0
      })
      hasQualifiedTeacher.value = false
    }

    return {
      ageGroups,
      hasQualifiedTeacher,
      calculateStaff,
      clearAll,
    }
  },
}
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}
.ratio-calc {
  margin-bottom: -10px;
}
label,
.form-control {
  font-size: 16px;
}
.card {
  height: 100%;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-title {
  font-size: 1rem;
  font-weight: bold;
}
.card-text {
  font-size: 0.9rem;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
