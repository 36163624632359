<template>
	<BreadCrumb PageTitle="How Many Days Calculator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">How Many Days Calculator</h1>
	  <p class="fs-6 text-center mb-4">Use our free how many days calculator to determine how many days until a particular date, event, or holiday.</p>
  
	  <div v-if="error" class="alert alert-warning mt-2" role="alert">{{ error }}</div>
  
	  <div class="row mt-4">
		<div class="col-md-6">
		  <div class="input-group mb-3">
			<span class="input-group-text pe-4" for="selectedMonth">Select Month:</span>
			<select id="selectedMonth" v-model="selectedMonth" class="form-select" @change="updateDays">
			  <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
			</select>
		  </div>
		</div>
		<div class="col-md-6">
		  <div class="input-group mb-3">
			<span class="input-group-text" for="selectedDay">Select Day:</span>
			<select id="selectedDay" v-model="selectedDay" class="form-select">
			  <option v-for="day in availableDays" :key="day" :value="day">{{ day }}</option>
			</select>
		  </div>
		</div>
	  </div>
  
	  <button @click="calculateDays" class="btn btn-primary mb-4 w-100">Calculate</button>
  
	  <div class="text-center mt-4">
		<h5 class="form-label">{{ queryText }}</h5>
		<input type="text" class="form-control fs-4 text-center mb-4" :value="result" disabled>
	  </div>
  
	  <div class="mt-5">
		<h5>Special Dates</h5>
		<ul class="list-unstyled fs-6">
		  <li v-for="date in specialDates" :key="date">
			<a href="#" @click.prevent="selectSpecialDate(date)">How many days until {{ date.replace(/-/g, ' ') }}?</a>
		  </li>
		</ul>
	  </div>
  
	  <div class="mt-5">
		<HowManyDaysUntilFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, computed, onMounted, watch } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import * as specialDateFunctions from '../../components/JS/specialDates.js';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import HowManyDaysUntilFAQ from '../FAQs/howManyDaysUntilFAQ.vue';
  
  export default {
	name: 'HowManyDaysUntil',
	components: {
	  BreadCrumb,
	  HowManyDaysUntilFAQ
	},
	setup() {
	  const router = useRouter();
	  const route = useRoute();
  
	  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	  const selectedMonth = ref(1);
	  const selectedDay = ref(1);
	  const error = ref('');
	  const result = ref('');
	  const queryText = ref('How many days until');
  
	  const specialDates = [
		'christmas', 'new-year', 'new-years-eve', 'boxing-day', 'valentines-day', 'saint-patricks-day',
		'kwanzaa', 'international-womens-day', 'bastille-day', 'canada-day', 'australia-day', 'republic-day',
		'all-saints-day', 'pioneer-day', 'europe-day', 'cinco-de-mayo', 'epiphany', 'groundhog-day',
		'juneteenth', 'independence-day', 'hanukkah', 'saint-davids-day', 'saint-georges-day', 'guy-fawkes-night',
		'mothers-day', 'fathers-day', 'martin-luther-king-day', 'presidents-day', 'spring', 'summer',
		'autumn', 'winter', 'april-fools-day', 'veterans-day', 'easter-monday', 'good-friday',
		'labor-day', 'columbus-day', 'thanksgiving', 'black-friday', 'cyber-monday', 'ash-wednesday',
		'mothering-sunday', 'palm-sunday', 'pentecost', 'remembrance-sunday', 'chinese-new-year', 'easter'
	  ];
  
	  const getSpecialHoliday = (holidayName, year) => {
		if (specialDateFunctions.specialHolidays[holidayName]) {
		  return specialDateFunctions.specialHolidays[holidayName];
		}
  
		const functionName = `get${holidayName.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')}Dates`;
		if (specialDateFunctions[functionName]) {
		  return specialDateFunctions[functionName](year);
		}
  
		return null;
	  };
  
	  const availableDays = computed(() => {
		const daysInMonth = new Date(new Date().getFullYear(), selectedMonth.value, 0).getDate();
		return Array.from({ length: daysInMonth }, (_, i) => i + 1);
	  });
  
	  const updateDays = () => {
		if (selectedDay.value > availableDays.value.length) {
		  selectedDay.value = availableDays.value.length;
		}
		updateURL();
	  };
  
	  const calculateDays = () => {
		error.value = '';
		const currentDate = new Date();
		let targetYear = currentDate.getFullYear();
		
		if (selectedMonth.value < currentDate.getMonth() + 1 || 
		   (selectedMonth.value === currentDate.getMonth() + 1 && selectedDay.value < currentDate.getDate())) {
		  targetYear++;
		}
  
		const targetDate = new Date(targetYear, selectedMonth.value - 1, selectedDay.value);
		const timeDiff = targetDate.getTime() - currentDate.getTime();
		const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  
		result.value = `${daysDiff} days`;
		updateURL();
	  };
  
	  const updateURL = () => {
		const specialDate = specialDates.find(date => {
		  const holiday = getSpecialHoliday(date, new Date().getFullYear());
		  return holiday && holiday.month === selectedMonth.value && holiday.day === selectedDay.value;
		});
  
		if (specialDate) {
		  router.push(`/how-many-days-until/how-many-days-until-${specialDate}`);
		  queryText.value = `How many days until ${specialDate.replace(/-/g, ' ')}?`;
		} else {
		  const monthName = months[selectedMonth.value - 1].toLowerCase();
		  router.push(`/how-many-days-until/how-many-days-until-${monthName}-${selectedDay.value}`);
		  queryText.value = `How many days until ${months[selectedMonth.value - 1]} ${selectedDay.value}?`;
		}
	  };
  
	  const parseURL = () => {
		const path = route.path;
		const match = path.match(/how-many-days-until-(\w+(-\w+)*)/);
		if (match) {
		  const dateString = match[1];
		  const currentYear = new Date().getFullYear();
		  const holiday = getSpecialHoliday(dateString, currentYear);
  
		  if (holiday) {
			selectedMonth.value = holiday.month;
			selectedDay.value = holiday.day;
			queryText.value = `How many days until ${dateString.replace(/-/g, ' ')}?`;
		  } else {
			const parts = dateString.split('-');
			const monthName = parts[0];
			const day = parseInt(parts[1]);
			const monthIndex = months.findIndex(m => m.toLowerCase() === monthName);
			if (monthIndex !== -1) {
			  selectedMonth.value = monthIndex + 1;
			  selectedDay.value = day;
			  queryText.value = `How many days until ${months[monthIndex]} ${day}?`;
			}
		  }
		  calculateDays();
		}
	  };
  
	  const selectSpecialDate = (date) => {
		router.push(`/how-many-days-until/how-many-days-until-${date}`);
	  };
  
	  onMounted(() => {
		if (route.path.includes('how-many-days-until')) {
		  parseURL();
		} else {
		  const currentDate = new Date();
		  selectedMonth.value = currentDate.getMonth() + 1;
		  selectedDay.value = currentDate.getDate();
		  updateURL();
		}
	  });
  
	  watch(() => route.path, parseURL);
  
	  return {
		months,
		selectedMonth,
		selectedDay,
		error,
		result,
		queryText,
		availableDays,
		updateDays,
		calculateDays,
		specialDates,
		selectSpecialDate
	  };
	}
  }
  </script>
  
  <style scoped>
  .custom-container {
	width: 90vw;
	max-width: 800px;
	margin: auto;
	padding: 0 1rem;
	box-sizing: border-box;
  }
  .dark .list-unstyled a{
	color: #a7a4ff;
  }
  .list-unstyled a{
	color: #2b2a3f;
  }
  @media (max-width: 600px) {
	.custom-container {
	  width: 95vw;
	  padding: 0 0.5rem;
	}
  }
  </style>
  