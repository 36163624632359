<template>
  <BreadCrumb PageTitle="Writing Prompt Generator" />
  <div class="custom-container mt-5 mb-50">
    <div class="mb-5">
      <h1 class="text-center">Writing Prompt Generator</h1>
      <p class="fs-6 text-center mb-4">Discover endless inspiration across various genres at the click of a button. Your muse for every writing endeavor awaits!</p>
    </div>
    <div class="col-md-12 mb-3">
      <div class="input-group">
        <span class="input-group-text" for="categorySelect">Category:</span>
        <select id="categorySelect" class="form-select fs-6" v-model="selectedCategory">
          <option v-for="category in categories" :key="category.name" :value="category.name">
            {{ formatCategoryName(category.name) }}
          </option>
        </select>
      </div>
    </div>
    <div class="col">
      <button @click="generatePrompt" class="btn btn-primary d-flex justify-content-center align-items-center mb-4 btn-hover w-100" data-bs-toggle="tooltip" title="Generate Writing Prompts">
        Get Prompts!
      </button>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <div class="flex-grow-1 text-center">
            <h4 class="mb-0">Generated Prompt</h4>
          </div>
          <button @click="copyPrompt" class="btn btn-link copy-icon p-0" :title="copied ? 'Copied!' : 'Copy to clipboard'">
            <i class="bi" :class="{'bi-clipboard': !copied, 'bi-clipboard-check': copied}"></i>
          </button>
        </div>
        <div class="card-body fs-5 text-center">
          <div v-if="error" class="alert alert-warning">{{ error }}</div>
          <div class="generated-prompts">
            <p id="promptText">{{ generatedPrompt || "No prompt generated yet." }}</p>
          </div>
        </div>
      </div>
    </div>
    
    <!-- FAQ Section -->
    <div class="mt-5">
      <WritingPromptGeneratorFAQ />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import writingPrompts from '../../components/JSON/writingPrompts.json';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import WritingPromptGeneratorFAQ from '../FAQs/writingPromptGeneratorFAQ.vue';

export default {
  name: 'WritingPromptGenerator',
  components: {
    BreadCrumb,
    WritingPromptGeneratorFAQ
  },
  setup() {
    const categories = ref([]);
    const selectedCategory = ref('fantasy');
    const generatedPrompt = ref('');
    const error = ref('');
    const copied = ref(false);

    onMounted(() => {
      categories.value = writingPrompts.categories;
    });

    const generatePrompt = () => {
      const category = categories.value.find(cat => cat.name === selectedCategory.value);
      if (category && category.prompts.length > 0) {
        const randomIndex = Math.floor(Math.random() * category.prompts.length);
        generatedPrompt.value = category.prompts[randomIndex];
        error.value = '';
        copied.value = false; // Reset copied state when generating new prompt
      } else {
        error.value = 'No prompts available for this category.';
      }
    };

    const copyPrompt = () => {
      if (generatedPrompt.value) {
        navigator.clipboard.writeText(generatedPrompt.value)
          .then(() => {
            copied.value = true;
            setTimeout(() => {
              copied.value = false;
            }, 2000);
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
      }
    };

    const formatCategoryName = (name) => {
      return name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') + ' Prompt';
    };

    return {
      categories,
      selectedCategory,
      generatedPrompt,
      error,
      copied,
      generatePrompt,
      copyPrompt,
      formatCategoryName
    };
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}

.generated-prompts {
  padding: 15px;
  min-height: 100px;
}

.btn-hover:hover {
  opacity: 0.9;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  transition: 0.3s;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
}

.card-header {
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.copy-icon {
  font-size: 1.2rem;
  color: #6c757d;
  transition: color 0.3s ease;
}

.copy-icon:hover {
  color: #007bff;
}
</style>