<template>
  <BreadCrumb PageTitle="Crc24 Hash Generator Calculator" />

	<div class="custom-container mt-5">
		<h1 class="text-center">CRC-24 Hash Generator</h1>
		<p class="fs-6 text-center mb-4">
			Quickly generate CRC-24 hashes for your strings using our efficient and user-friendly tool.
		</p>
		<div class="mb-3">
			<label for="inputTextString" class="form-label">Enter text string:</label>
			<textarea v-model="inputText" id="inputTextString" class="form-control"></textarea>
		</div>
		<div class="row mb-3">
			<div class="col-md-6 mb-2">
				<button @click="generateHash" class="btn btn-primary w-100">Generate Hash</button>
			</div>
			<div class="col-md-6 mb-2">
				<button @click="clearText" class="btn btn-danger w-100">Clear Text</button>
			</div>
		</div>
		<div class="mb-3">
			<label for="generatedHash" class="form-label">Generated CRC-24 Hash:</label>
			<textarea v-model="generatedHash" id="generatedHash" class="form-control" rows="2" readonly></textarea>
		</div>
		<div class="mb-3">
			<button @click="copyHash" class="btn btn-secondary w-100">{{ copyButtonText }}</button>
		</div>
		<div class="mb-3 d-flex justify-content-between">
			<label class="form-label">Hash Size: 3 bytes (24 bits)</label>
			<label class="form-label">Hash Length: {{ hashLength }} characters</label>
		</div>

		<!-- FAQ Section -->
		<div class="mt-50 mb-50">
			<h2 class="text-center mb-4">Frequently Asked Questions</h2>
			<div class="accordion" id="faqAccordion">
				<div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
					<h2 class="accordion-header" :id="'heading' + index">
						<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
							{{ faq.question }}
						</button>
					</h2>
					<div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
						<div class="accordion-body" v-html="faq.answer"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

	import { ref } from 'vue';
	import crc from 'crc';

	export default {
	
  components: { BreadCrumb },
	name: 'CRC24HashGenerator',
		setup() {
			const inputText = ref('');
			const generatedHash = ref('');
			const hashLength = ref(0);
			const copyButtonText = ref('Copy Hash');

			const generateHash = () => {
				try {
					const hashHex = crc.crc24(inputText.value).toString(16).toUpperCase();
					generatedHash.value = hashHex;
					hashLength.value = hashHex.length;
				} catch (error) {
					console.error('Hash generation failed:', error);
					generatedHash.value = `Error generating hash: ${error.message}`;
					hashLength.value = 0;
				}
			};

			const clearText = () => {
				inputText.value = '';
				generatedHash.value = '';
				hashLength.value = 0;
			};

			const copyHash = () => {
				navigator.clipboard.writeText(generatedHash.value)
					.then(() => {
						copyButtonText.value = 'Hash Copied';
						setTimeout(() => {
							copyButtonText.value = 'Copy Hash';
						}, 2000);
					})
					.catch(err => console.error('Failed to copy: ', err));
			};

			const faqs = [
				{
					question: "What is CRC-24?",
					answer: `CRC-24 (Cyclic Redundancy Check 24-bit) is a checksum algorithm used to detect errors in data. It produces a 24-bit hash value and is used in various applications, including telecommunications and data storage.`
				},
				{
					question: "How is CRC-24 Calculated?",
					answer: `CRC-24 is calculated through a series of bitwise operations and polynomial division. The input message is divided by a predefined polynomial, and the remainder of this division is the CRC-24 hash value.`
				},
				{
					question: "How to calculate CRC-24 in Excel?",
					answer: `To calculate CRC-24 in Excel, you can use VBA (Visual Basic for Applications) to create a custom function. Here is an example:
					<pre><code>
Function CRC24(ByVal text As String) As String
    Dim crcTable(255) As Long
    Dim crc As Long
    Dim i As Integer, j As Integer
    For i = 0 To 255
        crc = i
        For j = 8 To 1 Step -1
            If (crc And 1) Then
                crc = ((crc And &HFFFFFF) / 2) And &H7FFFFF
                crc = crc Xor &H864CFB
            Else
                crc = ((crc And &HFFFFFF) / 2) And &H7FFFFF
            End If
        Next j
        crcTable(i) = crc
    Next i
    crc = &HFFFFFF
    For i = 1 To Len(text)
        crc = ((crc And &HFFFFFF) / 256) And &H7FFFFF
        crc = crc Xor crcTable((crc Xor Asc(Mid$(text, i, 1))) And &HFF)
    Next i
    CRC24 = Hex$(crc And &HFFFFFF)
End Function
					</code></pre>`
				},
				{
					question: "How to calculate CRC-24 in Python?",
					answer: `To calculate CRC-24 in Python, you can use the 'crcmod' library. Here is an example:
					<pre><code>
import crcmod

def calculate_crc24(input_string):
    crc24 = crcmod.mkCrcFun(0x1864CFB, initCrc=0xB704CE, xorOut=0x000000)
    return hex(crc24(input_string.encode()))

input_string = 'Hello, World!'
print(calculate_crc24(input_string))
					</code></pre>`
				},
				{
					question: "How to calculate CRC-24 Modbus?",
					answer: `To calculate CRC-24 Modbus, you can use the following algorithm in Python:
					<pre><code>
def crc24_modbus(data: bytes) -> int:
    crc = 0xFFFFFF
    for pos in data:
        crc ^= pos << 16
        for _ in range(8):
            if crc & 0x800000:
                crc = (crc << 1) ^ 0x1864CFB
            else:
                crc <<= 1
            crc &= 0xFFFFFF
    return crc

data = b'Hello, World!'
print(hex(crc24_modbus(data)))
					</code></pre>`
				},
				{
					question: "What are the key features of CRC-24?",
					answer: `CRC-24 offers several key features:
					<ul>
						<li><strong>Error Detection:</strong> Provides reliable error detection capabilities for data integrity.</li>
						<li><strong>Fixed Output Size:</strong> Produces a consistent 24-bit hash value regardless of input size.</li>
						<li><strong>Efficiency:</strong> Efficient to compute and widely used in various applications.</li>
					</ul>`
				},
				{
					question: "Why should I use CRC-24 over other hash functions?",
					answer: `CRC-24 is recommended for applications where error detection is critical and where a 24-bit hash value is sufficient, such as telecommunications and data storage.`
				},
				{
					question: "Is CRC-24 suitable for security-sensitive applications?",
					answer: `No, CRC-24 is not suitable for security-sensitive applications due to its vulnerability to collisions and lack of cryptographic security. For security-sensitive applications, consider using cryptographic hash functions like SHA-256 or SHA-512.`
				},
				{
					question: "What are the applications of CRC-24?",
					answer: `CRC-24 is used in various applications, including:
					<ul>
						<li>Telecommunications for error detection</li>
						<li>Data storage devices for data integrity checks</li>
						<li>Embedded systems and IoT devices</li>
						<li>File integrity checks</li>
					</ul>`
				},
				{
					question: "Is CRC-24 implemented in most libraries?",
					answer: `Yes, CRC-24 is implemented in many checksum and hashing libraries, including popular ones like crcmod and others.`
				},
				{
					question: "How does the CRC-24 algorithm work?",
					answer: `The CRC-24 algorithm works by dividing the input message by a predefined polynomial and using the remainder as the hash value. This process involves bitwise operations and polynomial division to ensure data integrity.`
				},
				{
					question: "Is this tool secure?",
					answer: `This tool generates the CRC-24 hash entirely in your browser, ensuring that your input is not sent to any server or stored anywhere. However, for sensitive data, it is recommended to use more secure hash functions.`
				}
			];

			return {
				inputText,
				generatedHash,
				hashLength,
				generateHash,
				clearText,
				copyButtonText,
				copyHash,
				faqs
			};
		}
	}
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}

	textarea.form-control {
		font-size: 16px;
	}

	/* Dark mode styles */
	@media (prefers-color-scheme: dark) {
		.accordion-button {
			background-color: #343a40;
			color: #fff;
		}

		.accordion-button:not(.collapsed) {
			background-color: #2b3035;
			color: #fff;
		}

		.accordion-button::after {
			filter: invert(1);
		}

		.accordion-body {
			background-color: #343a40;
			color: #fff;
		}

		/* Style for code snippets in dark mode */
		.accordion-body code {
			background-color: #2b3035;
			color: #e83e8c;
			padding: 2px 4px;
			border-radius: 4px;
		}
	}

	/* Light mode styles */
	@media (prefers-color-scheme: light) {
		.accordion-body {
			background-color: #fff;
			color: #212529;
		}

		/* Style for code snippets in light mode */
		.accordion-body code {
			background-color: #f8f9fa;
			color: #e83e8c;
			padding: 2px 4px;
			border-radius: 4px;
		}
	}
</style>
