<template>
  <BreadCrumb PageTitle="Sentence Counter" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Sentence Counter</h1>
    <p class="fs-6 text-center mb-4">
      Effortlessly count sentences in your text with our user-friendly online
      Sentence Counter tool, designed for accuracy and ease of use.
    </p>

    <!-- Text Input Section -->
    <section class="mb-4">
      <div class="container">
        <form @submit.prevent="analyzeText">
          <div class="form-group mb-4">
            <label for="textInput">Enter your text here:</label>
            <textarea
              class="form-control"
              id="textInput"
              rows="6"
              placeholder="Type or paste your text here..."
              v-model="text"
            ></textarea>
          </div>
          <div
            class="alert alert-danger mb-4"
            role="alert"
            v-if="showError"
          >
            An error occurred while analyzing the text. Please try again.
          </div>
          <div class="row mb-3">
            <div class="col-md-6 mb-3">
              <button
                type="submit"
                class="btn btn-primary btn-block w-100"
                data-bs-toggle="tooltip"
                title="Analyze Text"
              >
                Analyze Text
              </button>
            </div>
            <div class="col-md-6">
              <button
                type="button"
                class="btn btn-danger btn-block w-100"
                @click="resetForm"
                data-bs-toggle="tooltip"
                title="Reset Screen"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
        <div v-if="sentenceCount !== null" class="mt-4">
          <h4>Analysis Results</h4>
          <textarea class="form-control mb-3" :style="textareaStyle" v-model="analysisResults" readonly></textarea>
        </div>
      </div>
    </section>

    <div>
      <SentenceCounterFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import SentenceCounterFAQ from '../FAQs/sentenceCounterFAQ.vue';

export default {
  components: {
    BreadCrumb,
    SentenceCounterFAQ
  },
  data() {
    return {
      text: '',
      sentenceCount: 0,
      wordCount: 0,
      characterCount: 0,
      readabilityScore: 0,
      countWithSpecialChars: 0,
      countWithoutSpecialChars: 0,
      showError: false,
    };
  },
  computed: {
    analysisResults() {
      return `Sentence Count: ${this.sentenceCount}\n\nWord Count: ${this.wordCount}\n\nCharacter Count: ${this.characterCount}\n\nReadability Score (Flesch-Kincaid): ${this.readabilityScore.toFixed(2)}\n\nCount with Special Characters: ${this.countWithSpecialChars}\n\nCount without Special Characters: ${this.countWithoutSpecialChars}`;
    },
    textareaStyle() {
      return {
        height: this.autoHeight(this.analysisResults),
        fontSize: '16px',
      };
    },
    resultsAvailable() {
      return this.sentenceCount > 0 || this.wordCount > 0 || this.characterCount > 0 || this.countWithSpecialChars > 0 || this.countWithoutSpecialChars > 0;
    }
  },
  methods: {
    analyzeText() {
      try {
        this.sentenceCount = this.text.split(/[.!?]+\s*/).filter(Boolean).length;
        this.wordCount = this.text.split(/\s+/).filter(Boolean).length;
        this.characterCount = this.text.replace(/\s+/g, '').length;
        this.readabilityScore = this.calculateReadability(this.text);
        this.countWithSpecialChars = this.text.length;
        this.countWithoutSpecialChars = this.text.replace(/[^a-zA-Z0-9]/g, '').length;
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    calculateReadability(text) {
      const sentences = text.split(/[.!?]+\s*/).filter(Boolean).length;
      const words = text.split(/\s+/).filter(Boolean).length;
      const syllables = text.split('').reduce((acc, char) => {
        if ('aeiouy'.includes(char.toLowerCase())) acc += 1;
        return acc;
      }, 0);
      return (
        206.835 -
        1.015 * (words / sentences) -
        84.6 * (syllables / words)
      );
    },
    resetForm() {
      this.text = '';
      this.sentenceCount = 0;
      this.wordCount = 0;
      this.characterCount = 0;
      this.readabilityScore = 0;
      this.countWithSpecialChars = 0;
      this.countWithoutSpecialChars = 0;
      this.showError = false;
    },
    autoHeight(text) {
      const lines = text.split('\n').length;
      const lineHeight = 1.5; // Assuming 1.5em line height
      const minHeight = 3; // Minimum height in em
      const height = lines * lineHeight;
      return `${Math.max(height, minHeight)}em`;
    },
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 80vh;
  margin: auto;
}
label, .form-control {
  font-size: 16px;
}
</style>