<template>
  <BreadCrumb PageTitle="Secret Santa Poem Generator" />
  <div class="poem-generator mt-5">
    <h1 class="text-center mb-4">🎅 Secret Santa Poem Generator 🎅</h1>
    <p class="fs-6 text-center mb-30">Generate heartwarming poems with our Secret Santa Poem generator! Perfect for holiday cards, events, or as a memorable gift surprise. 🎅✨</p>
    <div class="mb-3">
      <div class="input-group">
        <span for="type" class="input-group-text">Choose a type:</span>
        <select v-model="selectedType" id="type" class="form-select fs-6">
          <option v-for="(template, index) in templates" :key="index" :value="template">
            {{ template.type }}
          </option>
        </select>
      </div>
    </div>
    <button @click="generatePoem" class="btn btn-primary mb-3 w-100 btn-lg">Generate Poem</button>
    <div v-if="loading" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>
    <div v-if="poem" class="card mt-3 mb-4">
      <div class="card-body">
        <h3 class="card-title">Generated Secret Santa Poem</h3>
        <p class="card-text fs-5">{{ poem }}</p>
        <button @click="copyPoem" class="btn btn-outline-secondary mt-2 w-100">
          <i :class="['bi', copied ? 'bi-clipboard-check' : 'bi-clipboard']"></i>
          {{ copied ? 'Copied!' : 'Copy' }}
        </button>
      </div>
    </div>
    <div><SecretSantaPoemGeneratorFAQ /></div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import { templates } from '../../templates';
import { generatePoem as generateLocalPoem } from '../../generatePoem';
import SecretSantaPoemGeneratorFAQ from '../FAQs/secretSantaPoemGeneratorFAQ.vue';

export default {
  components: {
    BreadCrumb,
    SecretSantaPoemGeneratorFAQ
  },
  data() {
    return {
      templates,
      selectedType: templates[0],
      poem: '',
      loading: false,
      error: null,
      copied: false,
    };
  },
  methods: {
    generatePoem() {
      this.loading = true;
      this.error = null;
      this.poem = '';
      try {
        const randomTemplate = this.getRandomTemplate(this.selectedType.templates);
        this.poem = generateLocalPoem(randomTemplate);
      } catch (error) {
        console.error('Error generating poem:', error);
        this.error = 'An error occurred while generating the poem. Please try again.';
      } finally {
        this.loading = false;
      }
    },
    getRandomTemplate(templates) {
      const randomIndex = Math.floor(Math.random() * templates.length);
      return templates[randomIndex];
    },
    copyPoem() {
      if (this.poem) {
        navigator.clipboard.writeText(this.poem).then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        }).catch(err => {
          console.error('Failed to copy text: ', err);
        });
      }
    }
  },
};
</script>

<style scoped>
.poem-generator {
  max-width: 700px;
  margin: 0 auto;
}
</style>