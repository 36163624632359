<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Word Sorter Online Tool</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the Word Sorter Tool?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Word Sorter Tool is a free online application designed to help users quickly and easily sort words in alphabetical order. Whether you're a student organizing vocabulary lists or a writer streamlining your content, this tool simplifies text management by allowing you to copy and paste your words and sort them with ease.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>Key Features</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <strong>Alphabetical Sorting:</strong> Sort words from A to Z or Z to A with just one click.<br>
                                    <strong>Custom Separators:</strong> Choose from comma, space, or line break to format your sorted words.<br>
                                    <strong>Case Conversion:</strong> Convert your sorted words to upper case, lower case, or capitalize each word.<br>
                                    <strong>Copy to Clipboard:</strong> Easily copy the sorted text to your clipboard for use in other applications.<br>
                                    <strong>User-Friendly Interface:</strong> Simple and intuitive design makes it easy for anyone to use.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>How to Use the Word Sorter Tool?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Using the Word Sorter Tool is straightforward. Here’s how:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>Insert Text:</strong> Place your list of words in the 'Input Text' box.</li>
                                    <li><strong>Select Sorting Order:</strong> Choose whether to sort the words in ascending (A-Z) or descending (Z-A) order.</li>
                                    <li><strong>Choose a Separator:</strong> Select your preferred separator: comma, space, or line break.</li>
                                    <li><strong>Sort Words:</strong> Click the 'Sort Words' button to organize your words.</li>
                                    <li><strong>Review and Copy:</strong> Review the sorted words in the 'Output Text' box and click the 'Copy to Clipboard' button to copy the sorted list.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>Benefits for Students</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        <li><strong>Organize Vocabulary:</strong> Easily sort vocabulary lists for better study and retention.</li>
                                        <li><strong>Prepare Assignments:</strong> Quickly organize words and terms for essays and projects.</li>
                                        <li><strong>Enhance Learning:</strong> Use the tool to create alphabetical lists for learning and review sessions.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>Benefits for Professionals</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        <li><strong>Streamline Writing:</strong> Quickly sort lists of keywords, tags, or concepts for articles and reports.</li>
                                        <li><strong>Improve Workflow:</strong> Use the tool to organize content for presentations and documents.</li>
                                        <li><strong>Enhance Productivity:</strong> Save time by automating the sorting process, allowing you to focus on more important tasks.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Troubleshooting</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Facing an issue? Here’s what you can do:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Ensure you have entered text into the 'Input Text' field.</li>
                                    <li>If the output isn’t as expected, double-check your selected options.</li>
                                    <li>Refresh the page if you encounter any technical issues or slow responses.</li>
                                    <li>If copying fails, manually select the text in the 'Output Text' box and copy it using your device’s default copy command.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>What if I encounter a problem with the tool?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    If you encounter any issues or have questions about the Word Sorter Tool, please contact our support team. We're here to help you have a smooth and enjoyable experience!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'WordSorterFAQ',
};
</script>

<style scoped>

</style>
