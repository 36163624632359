<template>
	<BreadCrumb PageTitle="OpenAI Pricing Calculator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">OpenAI Pricing Calculator</h1>
	  <p class="fs-6 text-center mb-4">Estimate OpenAI model usage costs easily with our Free OpenAI Pricing Calculator.</p>
		
	  <div class="mb-3">
		<div class="d-flex justify-content-between">
		  <label for="prompt-length">Prompt Length (Tokens)</label>
		  <label for="answer-length">Answer Length (Tokens)</label>
		</div>
		<div class="input-group">
		  <input
			id="prompt-length"
			v-model.number="promptLength"
			class="form-control"
			type="number"
			placeholder="0"
			min="1"
			required
		  />
		  <input
			id="answer-length"
			v-model.number="answerLength"
			class="form-control"
			type="number"
			placeholder="0"
			min="1"
			required
		  />
		</div>
	  </div>
		
	  <div class="row mt-3 mb-3">
		<div class="input-group">
		  <span class="input-group-text pe-4" for="select_model">Select a Model:</span>
		  <select
			v-model="selectedModel"
			id="select_model"
			class="form-select"
			required
		  >
			<option v-for="model in models" :key="model.name" :value="model">
			  {{ model.name }}
			</option>
		  </select>
		</div>
	  </div>
		
	  <div>
		<div v-if="showError" class="alert alert-warning mt-2" role="alert">
		  Please enter valid token counts to calculate!
		</div>
		<div class="row mt-4">
		  <div class="col-md-6 mb-2">
			<button @click="calculate" class="btn btn-primary w-100">
			  <i class="bi bi-calculator"></i> Calculate
			</button>
		  </div>
		  <div class="col-md-6 mb-2">
			<button @click="reset" class="btn btn-danger w-100">
			  <i class="bi bi-repeat"></i> Reset
			</button>
		  </div>
		</div>
	  </div>
		
	  <div class="col-md-12 mt-4">
		<h3 class="card-title text-center mb-2 fs-5">OpenAI Price:</h3>
		<div class="card">
		  <div class="card-body">
			<p v-if="!result" class="card-text text-center fs-6">No pricing calculated yet.</p>
			<p v-else class="card-text fs-5">{{ result }}</p>
		  </div>
		</div>
	  </div>
		
	  <div class="mt-4">
		<h4 class="text-center">OpenAI Price Table</h4>
		<table class="table table-bordered mt-3">
		  <thead class="fs-6">
			<tr>
			  <th scope="col">Models</th>
			  <th scope="col">Input Price (1K Tokens)</th>
			  <th scope="col">Output Price (1K Tokens)</th>
			</tr>
		  </thead>
		  <tbody>
			<tr v-for="model in models" :key="model.name">
			  <td>{{ model.name }}</td>
			  <td>${{ model.inputPrice.toFixed(4) }}</td>
			  <td>${{ model.outputPrice.toFixed(4) }}</td>
			</tr>
		  </tbody>
		</table>
	  </div>
	
	  <div class="mt-5">
		<OpenAiPricingCalculatorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import OpenAiPricingCalculatorFAQ from '../FAQs/openAiPricingCalculatorFAQ.vue';
  
  export default {
	name: 'OpenAIPricingCalculator',
	components: {
	  BreadCrumb,
	  OpenAiPricingCalculatorFAQ
	},
	setup() {
	  const promptLength = ref(0)
	  const answerLength = ref(0)
	  const showError = ref(false)
	  const result = ref('')
  
	  const models = [
		{ name: 'GPT-3.5-turbo-1106', inputPrice: 0.001, outputPrice: 0.002 },
		{ name: 'GPT-3.5-turbo-0613', inputPrice: 0.0015, outputPrice: 0.002 },
		{ name: 'GPT-4-1106-preview', inputPrice: 0.01, outputPrice: 0.03 },
		{ name: 'GPT-4-vision-preview', inputPrice: 0.01, outputPrice: 0.03 },
		{ name: 'GPT-4', inputPrice: 0.03, outputPrice: 0.06 },
		{ name: 'GPT-4-32k', inputPrice: 0.06, outputPrice: 0.12 },
		{ name: 'DALL·E 3', inputPrice: 0.04, outputPrice: 0.08 },
		{ name: 'TTS', inputPrice: 0.015, outputPrice: 0.015 },
		{ name: 'Whisper', inputPrice: 0.006, outputPrice: 0.006 },
		{ name: 'GPT-4o', inputPrice: 0.0075, outputPrice: 0.015 },
	  ]
  
	  const selectedModel = ref(models[0])
  
	  const calculate = () => {
		if (promptLength.value <= 0 || answerLength.value <= 0) {
		  showError.value = true
		  return
		}
		showError.value = false
  
		const promptTokens = promptLength.value
		const answerTokens = answerLength.value
  
		const promptCost = (promptTokens / 1000) * selectedModel.value.inputPrice
		const answerCost = (answerTokens / 1000) * selectedModel.value.outputPrice
		const totalCost = promptCost + answerCost
  
		result.value = `Estimated cost for ${selectedModel.value.name}:
		  Input cost: $${promptCost.toFixed(6)}
		  Output cost: $${answerCost.toFixed(6)}
		  Total cost: $${totalCost.toFixed(6)}`
	  }
  
	  const reset = () => {
		promptLength.value = 0
		answerLength.value = 0
		showError.value = false
		result.value = ''
		selectedModel.value = models[0]
	  }
  
	  return {
		promptLength,
		answerLength,
		selectedModel,
		models,
		showError,
		result,
		calculate,
		reset
	  }
	}
  }
  </script>
  
  <style scoped>
  .custom-container {
	width: 90vw;
	max-width: 800px;
	margin: auto;
	padding: 0 1rem;
	box-sizing: border-box;
  }
  label, .form-control {
	font-size: 16px;
  }
  .card {
	height: 100%;
  }
  .card-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  }
  .card-title {
	font-size: 1rem;
	font-weight: bold;
  }
  .card-text {
	font-size: 0.9rem;
	margin-bottom: 0;
  }
  
  @media (max-width: 600px) {
	.custom-container {
	  width: 95vw;
	  padding: 0 0.5rem;
	}
  }
  </style>
  