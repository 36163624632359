<template>
  <BreadCrumb PageTitle="Subtracting Fractions Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Subtracting Fractions Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Subtract two fractions and simplify the result to its lowest terms. Enter the numerators and denominators of the fractions to perform the subtraction.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for First Fraction -->
        <h5>First Fraction (Minuend):</h5>
        <div class="mb-3 row">
          <div class="col-6">
            <label for="numerator1" class="form-label">Numerator:</label>
            <input
              type="number"
              id="numerator1"
              v-model="numerator1"
              class="form-control"
              placeholder="e.g., 3"
              step="1"
            />
          </div>
          <div class="col-6">
            <label for="denominator1" class="form-label">Denominator:</label>
            <input
              type="number"
              id="denominator1"
              v-model="denominator1"
              class="form-control"
              placeholder="e.g., 4"
              step="1"
            />
          </div>
        </div>

        <!-- Input for Second Fraction -->
        <h5>Second Fraction (Subtrahend):</h5>
        <div class="mb-3 row">
          <div class="col-6">
            <label for="numerator2" class="form-label">Numerator:</label>
            <input
              type="number"
              id="numerator2"
              v-model="numerator2"
              class="form-control"
              placeholder="e.g., 1"
              step="1"
            />
          </div>
          <div class="col-6">
            <label for="denominator2" class="form-label">Denominator:</label>
            <input
              type="number"
              id="denominator2"
              v-model="denominator2"
              class="form-control"
              placeholder="e.g., 2"
              step="1"
            />
          </div>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="subtractFractions" class="btn btn-primary w-100">Subtract</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">First Fraction: {{ result.fraction1 }}</p>
          <p class="fs-5">Second Fraction: {{ result.fraction2 }}</p>
          <p class="fs-5">Difference: {{ result.differenceFraction }}</p>
          <p class="fs-5">Simplified Result: {{ result.simplifiedFraction }}</p>
          <p class="fs-5" v-if="result.mixedNumber">
            Mixed Number: {{ result.mixedNumber }}
          </p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the numerators and denominators for both fractions.</li>
        <li>Click "Subtract" to calculate the difference between the first and second fractions.</li>
        <li>The result will display the original fractions, the difference, the simplified fraction, and, if applicable, the mixed number form.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Understanding Fraction Subtraction:</h5>
      <p class="fs-6">
        To subtract fractions, they must have a common denominator. Find the Least Common Denominator (LCD), adjust the numerators accordingly, and then subtract the numerators. Simplify the resulting fraction if possible.
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <p class="fs-6">
        Subtract \( \frac{1}{2} \) from \( \frac{3}{4} \):
      </p>
      <ul class="fs-6">
        <li>Find the LCD of 4 and 2, which is 4.</li>
        <li>Adjust fractions:
          \[
          \frac{3}{4} = \frac{3}{4}, \quad \frac{1}{2} = \frac{2}{4}
          \]
        </li>
        <li>Subtract numerators:
          \[
          \frac{3}{4} - \frac{2}{4} = \frac{1}{4}
          \]
        </li>
        <li>Simplified Result: \( \frac{1}{4} \)</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const numerator1 = ref(null);
    const denominator1 = ref(null);
    const numerator2 = ref(null);
    const denominator2 = ref(null);
    const result = ref(null);
    const error = ref("");

    // Function to subtract fractions
    const subtractFractions = () => {
      error.value = "";
      result.value = null;

      try {
        // Ensure values are not null or empty
        if (numerator1.value === null || numerator1.value === "") {
          throw new Error("Please enter a value for the numerator of the first fraction.");
        }
        if (denominator1.value === null || denominator1.value === "") {
          throw new Error("Please enter a value for the denominator of the first fraction.");
        }
        if (numerator2.value === null || numerator2.value === "") {
          throw new Error("Please enter a value for the numerator of the second fraction.");
        }
        if (denominator2.value === null || denominator2.value === "") {
          throw new Error("Please enter a value for the denominator of the second fraction.");
        }

        // Parse the input values
        const num1 = parseInt(numerator1.value);
        const den1 = parseInt(denominator1.value);
        const num2 = parseInt(numerator2.value);
        const den2 = parseInt(denominator2.value);

        if (
          isNaN(num1) ||
          isNaN(den1) ||
          isNaN(num2) ||
          isNaN(den2)
        ) {
          throw new Error("Please enter valid integer values.");
        }

        if (den1 === 0 || den2 === 0) {
          throw new Error("Denominators cannot be zero.");
        }

        // Find Least Common Denominator (LCD)
        const lcd = lcm(den1, den2);

        // Adjust numerators
        const adjustedNum1 = num1 * (lcd / den1);
        const adjustedNum2 = num2 * (lcd / den2);

        // Subtract numerators
        const differenceNum = adjustedNum1 - adjustedNum2;
        const differenceDen = lcd;

        // Simplify the result
        const gcdValue = gcd(Math.abs(differenceNum), differenceDen);
        let simplifiedNum = differenceNum / gcdValue;
        let simplifiedDen = differenceDen / gcdValue;

        // Handle negative signs
        if (simplifiedDen < 0) {
          simplifiedNum *= -1;
          simplifiedDen *= -1;
        }

        let simplifiedFraction = `${simplifiedNum}/${simplifiedDen}`;

        // Check for improper fraction to mixed number
        let mixedNumber = null;
        if (Math.abs(simplifiedNum) >= simplifiedDen) {
          const wholeNumber = Math.trunc(simplifiedNum / simplifiedDen);
          const remainder = Math.abs(simplifiedNum % simplifiedDen);
          if (remainder === 0) {
            simplifiedFraction = `${wholeNumber}`;
          } else {
            mixedNumber = `${wholeNumber} ${remainder}/${simplifiedDen}`;
          }
        }

        result.value = {
          fraction1: `${num1}/${den1}`,
          fraction2: `${num2}/${den2}`,
          differenceFraction: `${differenceNum}/${differenceDen}`,
          simplifiedFraction: simplifiedFraction,
          mixedNumber: mixedNumber,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to find GCD of two numbers
    const gcd = (a, b) => {
      return b === 0 ? a : gcd(b, a % b);
    };

    // Function to find LCM of two numbers
    const lcm = (a, b) => {
      return (a * b) / gcd(a, b);
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      numerator1.value = null;
      denominator1.value = null;
      numerator2.value = null;
      denominator2.value = null;
      result.value = null;
      error.value = "";
    };

    return {
      numerator1,
      denominator1,
      numerator2,
      denominator2,
      result,
      error,
      subtractFractions,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
