<template>
  <BreadCrumb PageTitle="Empirical Rule Calculator" />
  <div class="custom-container mt-4 mb-5">
    <h1 class="text-center">Empirical Rule Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate Data Distribution using our Empirical Rule Calculator - Your Key to Understanding Statistics!
    </p>

    <div v-if="error" class="alert alert-warning mt-2" role="alert">
      {{ error }}
    </div>

    <div class="row mt-4">
      <div class="col-md-5 mb-3">
        <div class="input-group">
          <span class="input-group-text" for="mean">Mean (μ):</span>
          <input id="mean" v-model="mean" class="form-control" type="number" placeholder="0">
        </div>
      </div>
      <div class="col-md-7">
        <div class="input-group">
          <span class="input-group-text" for="stdDev">Standard Deviation (σ):</span>
          <input id="stdDev" v-model="stdDev" class="form-control" type="number" placeholder="0">
        </div>
      </div>
      <div class="mt-4">
        <button @click="calculateEmpiricalRule" class="btn btn-primary d-flex justify-content-center align-items-center mb-4 w-100" data-bs-toggle="tooltip" title="Calculate Empirical Rule">
          Calculate Rule
        </button>
      </div>
    </div>

    <h3 class="mt-3">Results:</h3>
    <div v-if="results" class="card">
      <div class="card-body">
        <div class="results fs-6">
          <p>Approximately 68% of the data is between <span class="fw-bold">{{ results.oneStdDev }}</span>.</p>
          <p>Approximately 95% of the data is between <span class="fw-bold">{{ results.twoStdDev }}</span>.</p>
          <p>Approximately 99.7% of the data is between <span class="fw-bold">{{ results.threeStdDev }}</span>.</p>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <EmpiricalRuleCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import EmpiricalRuleCalculatorFAQ from '../FAQs/empiricalRuleCalculatorFAQ.vue';

export default {
  name: 'EmpiricalRuleCalculator',
  components: {
    BreadCrumb,
    EmpiricalRuleCalculatorFAQ
  },
  setup() {
    const mean = ref(0)
    const stdDev = ref(0)
    const error = ref('')
    const results = ref(null)

    const calculateEmpiricalRule = () => {
      if (!mean.value || !stdDev.value) {
        error.value = 'Please fill in all the fields.'
        results.value = null
        return
      }

      error.value = ''
      const μ = parseFloat(mean.value)
      const σ = parseFloat(stdDev.value)

      results.value = {
        oneStdDev: `${(μ - σ).toFixed(2)} and ${(μ + σ).toFixed(2)}`,
        twoStdDev: `${(μ - 2 * σ).toFixed(2)} and ${(μ + 2 * σ).toFixed(2)}`,
        threeStdDev: `${(μ - 3 * σ).toFixed(2)} and ${(μ + 3 * σ).toFixed(2)}`
      }
    }

    return {
      mean,
      stdDev,
      error,
      results,
      calculateEmpiricalRule
    }
  }
}
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

label,
.form-control {
  font-size: 16px;
}

textarea {
  resize: none;
  overflow: hidden;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
