// src/templates.js

export const templates = [
    {
        type: 'General Santa Poem',
        templates: [
            "🎅 Secret Santa's coming, 🛷 sledding through the snow, ❄️ To leave you a mystery gift 🎁 tied up with a bow. 🎀",
            "🔔 Jingle bells, jingle bells, 🔔 Santa's on his way: 🛷 Bringing 🎁 gifts, spreading joy, and making spirits sway! 🎉",
            "Underneath the 🎄 Christmas tree, a 🎁 gift awaits from 🤫 Secret me. Unwrap it, dear, and you will see, a special something, with ☕️ and glee!",
            "🌟 Twinkling lights and joyful hearts, 💖 as Secret Santa, I played my part. Unwrap 🎁 the fun, it's just the start, Merry Christmas, with love ❤️ and art! 🎨",
            "Roses are red, 🌹 violets are blue, a secret 🎁 gift is waiting for you. With holiday cheer, 🎅 I thought it through, Merry Christmas, and a Happy New Year! 🎉",
            "On the twelfth day of Christmas, 🎄 my Secret Santa gave to me, twelve 😂 laughs, eleven 🕯️ lights, and sweet holiday glee! 🎉",
            "🤶 Mrs. Claus is on the move, spreading cheer 🥳 and everything dear. A 🎁 gift from me, to someone so sweet, makes our Christmas 🎄 spirit complete!",
            "Guess who, guess who? 🕵️‍♂️ Your Secret Santa's giving you a clue! A 🎁 present with a 🎀, so shiny and new, for a holiday season that’s joyful and true! ❤️",
            "Ssshh... 🤫 It's a secret, but here's a peek, 🎁 a gift you'll adore, unique and chic! With cheer 🎉 and love, from a friend 🧑‍🤝‍🧑 so meek!",
            "Snowflakes ❄️ and stars 🌟, Christmas is near, a time for love, joy, and cheer. From Secret Santa, a gift 🎁 to you, my dear!",
            "Ho Ho Ho! 🎅 A surprise from your elf, placed carefully on the shelf. Open me 🎁 for joy and good health!",
            "From the North Pole 📍, with a sleigh 🛷 and a prance, your Secret Santa brings joyance! A gift 🎁, a giggle 😄, for your Yuletide dance 💃!",
            "A little bird 🐦 told me you've been good, so here's a treat 🍬 from your neighborhood! Wrapped with care, and festive flair, your Secret Santa remembered to share! 🎁",
            "🌨️ Through the snow and frosty air, your Secret Santa's gift 🎁 is fair. It comes with warmth ☀️ and festive care, to show you how much I dare!",
            "🎅 'Tis the season of giving and cheer, a present from Santa is drawing near, this gift comes with joy from me to you, happy holidays, I hope you like it too! 🤶",
            "🦌 The reindeer are ready, the sleigh has been hitched, Santa is coming, your present is stitched, with holiday magic and boxes with bows, a gift made with care, your Secret Santa knows! 🎁",
            "🧑‍🎄 Ho ho ho, Santa Clause is coming, he's making his list and checking it twice, to give you a gift that's sure to delight, enjoy this surprise from your Secret Santa tonight! 🎅",
            "🎅 Ole Saint Nick is racing his way, to deliver great gifts on this joyful day, he knows who's been naughty and nice this year, so open your gift and give a cheer! 🙌",
            "🦌 With bells jingling and carols in the air, Santa's sleigh will soon be there, your Secret Santa took time to find, a special gift to make the season bright! ✨",
            "🎅 Jolly old St. Nicholas is on his way, with a magical sleigh filled with gifts this day, he's checked his list and has something for you, a present wrapped up from your Secret Santa crew! 🎀",
            "🧝‍♀️ From the North Pole elves working hard, to Santa loading his sleigh in the yard, your gift traveled far through ice and snow, now it's under the tree, ready for you to know! 🎄",
            "🎅 Santa Claus is coming straight to you, with a gift to make your holidays shine bright, check under the tree and the presents in sight, there's one tagged from Santa, open it with delight! 😄",
            "🦌 Across the snow and icy terrains, Santa's sleigh soars as Rudolph guides and reigns, your Secret Santa wanted to send you good cheer, so enjoy this gift as the holidays draw near! 🎁",
            "🎅 Old Kris Kringle he's hip and he's cool, he knows what's happenin' inside every school, your gift is perfect he checked twice with care, now check under the tree, Santa soon will be there! 🎄",
            "🎅 With his list double checked for who's been nice, Santa's grabbing a gift that's sure to entice, wrapped with joy, a bow and your name, from your Secret Santa it came! 🤶",
            "🦌 Eight tiny reindeer are pulling the sleigh, bringing Santa to you this Christmas day, he knows when you're sleeping and knows when you're awake, your present is waiting, so much joy is at stake! 🎁",
            "🦌 With his reindeer leading the way, Santa's on his route this Christmas day, he sees you've been good so he dropped something off, a gift tagged from Santa for you to scoff! 😋🎁",
            "🧑‍🎄 Ho Ho Ho here Santa comes, riding his sleigh filled with goodies and fun, your gift is under the tree shining bright, open with cheer on this holiday night! 😄",
            "🎅 Jingle bells, jingle bells Santa's on his way, with presents and goodies on his sleigh hey!, a gift just for you he's left behind, from Santa himself you're sure to find! 🎅",
            "🦌 Eight tiny reindeer are pulling the sleigh, bringing Santa to you this Christmas day, he knows when you're sleeping and knows when you're awake, your present is waiting, so much joy is at stake! 🎁",
            "🎅 Santa's elves worked hard through the night, to make sure your gift turns out just right, with magic and cheer it was specially made, now check under the tree before Christmas Day fades! 🎄",
            "🎅 With his round little belly and nose like a cherry, Santa's grabbing gifts for all in a hurry!, one special present he's left here for you, from your Secret Santa, here's hoping you like it too! 🤞",
            "🦌 The reindeer fly pulling Santa's red sleigh, they lead the way on this Christmas day, Santa left a gift that he wants you to see, quick, go check under the tree for your present from me!🎄",
            "🧑‍🎄 Ho ho ho, Santa's coming around, with joy and cheer he'll astound!, a gift just for you he's left by the fire, from jolly old Santa is your holiday desire!🎅",
            "🎅 Santa's grabbed gifts for girls and for boys, all wrapped with care and holiday toys, he sees you've been nice so you're in for a treat, a present from Santa you're sure to love, eat or keep!😍",
            "🦌 The reindeer are ready, the sleigh has been loaded, with presents galore Santa feels devoted, a special gift just for you he's arranged, from your Secret Santa it's perfectly packaged!🎅",
            "🧑‍🎄 With Christmas cheer Santa brings holiday bliss, a magical gift he doesn't want you to miss!, check under the tree before he's back on his way, a present for you on this joyful Christmas Day!🎄",
            "🎅 Jolly old Santa he's making his way, on his route to deliver great gifts today!, one special present is just for you, he's left it here! Open and see what's new! 🤩",
            "🦌 With his sleigh filled up Santa makes a swift flight, to get all his gifts wrapped up just right!, one he's picked out is sure to excite, it's from your Secret Santa, checked twice not once, right✅✅",
            "🧑‍🎄 Ho Ho Ho here Santa comes with glee!, he's got a present under the tree wrapped with care and picked just for you, from Santa himself - it's true! 🎅",
            "🎅 Jolly old Kris Kringle what a guy!, bringing cheer and gifts that will make you cry!, a present from Santa he's left just for you, open it quick before he shouts 'Ho Ho Ho' and travels anew! 🚶‍♂️",
            "🦌 With his nose guiding the way, Rudolph leads Santa's sleigh, a gift specially picked is under the tree, take a look quick and see what it might be! 👀🎁",
            "🧑‍🎄 Santa is coming with presents to share, spreading holiday joy and flare, a gift specially wrapped he has for you, from Santa himself - it's true! 🎅",
            "🎅 Old Saint Nick he's a jolly old soul, coming down the chimney, the presents he'll dole!, check under the tree where the lights brightly glow, a gift tagged from Santa is ready to go! 🎄",
            "🦌 Santa jumps in his sled for a ride, with gifts in his bag inside, one special present under the tree, has your name on it - it's meant to be! 🎀",
            "🧑‍🎄 Ho Ho Ho, Santa's on his way!, bringing gifts and presents on his sleigh, check the tree and see a gift wrapped up tight, from Secret Santa - hope you like it tonight! 🌃",
            "🎅 Kris Kringle he's coming real soon, flying high on a Christmas full moon, a present he has picked out for you, it's under the tree waiting there to unbox something new!🎁",
            "🦌 With his reindeer, Santa makes great time, delivering presents that shimmer and shine, one special gift is now in your view, from Santa himself to fill you with glee! 🎅",
            "🧑‍🎄 Santa Claus is coming around!, with magical gifts that will astound!, check under the tree, why wait another minute, a present from Santa, go ahead and open it in! 🎁",
            "🎅 Jolly old Santa he's a merry old elf, coming from the North Pole, in spite of himself!, he's got a gift for you he picked out with care, open it up quick, enjoy what is there! 🤩",
            "🦌 With his reindeer leading the way tonight, Santa begins spreading holiday delight, a gift tagged with your name comes into sight, from your Secret Santa, savor the excitement! 🥳",
            "🧑‍🎄 Ho Ho Ho, Santa will soon be here!, Christmas Day is drawing near!, check under the tree for a gift that's for you, from Santa himself, open it before he shouts 'Ho Ho Ho' and travels anew! 🎅",
            "🎅 Saint Nick the man dressed in red, is coming round with his sleigh overhead!, a present wrapped just for you he now has in store, open with cheer and holiday glee galore! 🥳🎁",
            "🦌 With his nose guiding the way, Rudolph leads Santa's sleigh, a gift specially picked is under the tree, take a look quick and see what it might be! 👀🎁",
            "🧑‍🎄 Santa is coming, get ready for cheer!, the time has arrived, presents are near!, check under the tree for one with your name, from Secret Santa, unwrap a fun game! 🎮🎁",
            "🎅 Jolly old Santa he's making his list, of presents and toys that will spread holiday bliss!, one gift he wrapped up is just for you, open with joy and see what's new! 🎁🤩",
            "🦌 Santa hitches his reindeer for a merry flight, through the dark winter's night, a present he's wrapped and placed under the tree, take a peak quick, it's meant to be! 👀🎁",
            "🧑‍🎄 Ho Ho Ho, Santa will soon appear!, with a magical sleigh and reindeer my dear!, check the gifts 'neath the tree lights aglow, a present from Santa is ready to go! 🎁",
            "🎅 Old Saint Nick down the chimney he'll fly, with a sack filled with goodies to satisfy!, one gift he's preparing is marked just for you, open with excitement over what's new! 🤩🎁",
            "🦌 Santa hitches up Rudolph to guide his sleigh, as presents and toys are loaded away!, one special gift wrapped with a bow, is from Secret Santa - go see what's below! 🔎🎁",
            "🧑‍🎄 Santa is coming, oh what a delight!, with magical presents wrapped up overnight!, there's one 'neath the tree with a tag marked just for you, from Santa himself, open and see what's new! 🎅🎁",
            "🎅 Jolly old Saint Nick he's hip and he's cool, he'll slide down the chimney like coming home from school!, a special present under the tree he has left, open with cheer put joy to the test! 🥳",
            "🦌 Santa gears up his reindeer for flight, on his Christmas Eve ride through the night, a gift wrapped with care he's placed under the tree, take a look quick, it's meant to be! 👀🎁",
            "🧑‍🎄 Ho Ho Ho, Santa is coming this way!, with presents and cheer on his holiday sleigh!, check under the tree for a gift marked for you, from Santa himself, open it before he shouts 'Ho Ho Ho!' 🎅",
            "🎅 Saint Nick the man dressed in red, is coming around with goodies ahead!, a special present placed under the tree, is from Santa for you, go grab it and see!🤩",
            "🦌 With his reindeer, Santa takes flight tonight, spreading holiday joy and delight!, a gift specially wrapped you are sure to like, from Secret Santa, go ahead, take a strike!🎳",
            "🧑‍🎄 Santa is coming bringing holiday cheer!, the time has arrived, Christmas is here!, check under the tree for a present with your name, from Santa himself, be sure to exclaim! 😲",
            "🎅 Jolly old Santa down the chimney he dives, with a sack full of presents for good boys and girls!, one gift he's preparing is just for you, open with joy over what's new!🤩",
            "🦌 Santa hooks up his reindeer for a merry flight tonight, on his Christmas Eve ride spreading delight!, a present wrapped up special for you, is under the tree from your Secret Santa, it's true! 🎁",
            "🧑‍🎄 Ho Ho Ho, Santa will soon be near!, Christmas day is drawing near!, there's a gift under the tree wrapped with your name, from Santa himself, play his holiday game!🎅",
            "🎅 Saint Nick the man dressed in red, is coming around with a sack on his sled!, a special present tucked under the tree, is from Santa for you, take a look and see!👀",
            "🦌 With his reindeer, Santa makes great time, bringing joy and cheer that will sublime!, a gift specially wrapped you are sure to love, from your Secret Santa sent from above!⭐",
            "🧑‍🎄 Santa is coming, get ready to cheer!, the time has arrived, presents are near!, there's one under the tree wrapped with your name, from Santa himself, unwrap this fun game!🕹️",
            "🎅 Jolly old Santa what a guy!, coming from the North Pole in the sky!, he left a gift under the tree, can't wait?, hurry up quick, don't be late! ⏰",
            "🎅 Old Saint Nick down the chimney he slides, with his bag filled with presents tied up inside!, a special one for you he's placed under the tree, open it quick, and see what it might be! 🎁",
            "🦌 With his reindeer leading, Santa takes flight, on his merry trek throughout the night, a gift he's preparing is marked with your name, from Secret Santa it came! 🎅",
            "🧑‍🎄 Santa is coming, hip hip hooray!, with magical presents on his sleigh!, one special gift he's wrapped just for you, open with cheer to see what's new! 🥳",
            "🎅 Jolly old Santa, what a guy!, coming from the North Pole, flying high in the sky, he left a present under the tree, just for thee, go check it out and see! 👀",
            "🦌 With his reindeer, Santa's on his way, bringing great gifts on this Christmas Day!, one he picked out has your name on the tag, from your Secret Santa - pretty rad! 😎",
            "🧑‍🎄 Ho Ho Ho, Santa's coming with glee!, he's got a present under the tree wrapped with care and picked just for you, from Santa himself - it's true! 🎅",
            "🎅 Jolly old Kris Kringle what a guy!, he's got a twinkle in his eye, a special gift he has in his sack, check under the tree and get ready to unpack! 🎁",
            "🦌 Santa hitches his reindeer for a ride, as he sets out gifts to provide, one he wrapped with your name in sight, from Secret Santa, unwrap tonight! 🌃",
            "🧑‍🎄 Santa is coming with presents to share, spreading holiday joy and flair, a gift specially wrapped he has for you, from Santa himself - it's true! 🎅",
            "🎅 Old Saint Nick he's a jolly old soul, coming down the chimney, the presents he'll dole!, check under the tree where the lights brightly glow, a gift tagged from Santa is ready to go! 🎄",
            "🦌 Santa jumps in his sled for a ride, with gifts in his bag inside, one special present under the tree, has your name on it - it's meant to be! 🎀",
            "🧑‍🎄 Ho Ho Ho, Santa's on his way!, bringing gifts and presents on his sleigh, check the tree and see a gift wrapped up tight, from Secret Santa - hope you like it tonight! 🌃",
            "🎅 Kris Kringle he's coming real soon, flying high on a Christmas full moon, a present he has picked out for you, it's under the tree waiting there to unbox something new!🎁",
            "🦌 With his reindeer, Santa makes great time, delivering presents that shimmer and shine, one special gift is now in your view, from Santa himself to fill you with glee! 🎅",
            "🧑‍🎄 Santa Claus is coming around!, with magical gifts that will astound!, check under the tree, why wait another minute, a present from Santa, go ahead and open it in! 🎁",
            "🎅 Jolly old Santa he's a merry old elf, coming from the North Pole, in spite of himself!, he's got a gift for you he picked out with care, open it up quick, enjoy what is there! 🤩",
            "🦌 With his reindeer leading the way tonight, Santa begins spreading holiday delight, a gift tagged with your name comes into sight, from your Secret Santa, savor the excitement! 🥳",
            "🧑‍🎄 Ho Ho Ho, Santa will soon be here!, Christmas Day is drawing near!, check under the tree for a gift that's for you, from Santa himself, open it before he shouts 'Ho Ho Ho' and travels anew! 🎅",
            "🎅 Saint Nick the man dressed in red, is coming round with his sleigh overhead!, a present wrapped just for you he now has in store, open with cheer and holiday glee galore! 🥳🎁",
            "🦌 With his nose guiding the way, Rudolph leads Santa's sleigh, a gift specially picked is under the tree, take a look quick and see what it might be! 👀🎁",
            "🧑‍🎄 Santa is coming, get ready for cheer!, the time has arrived, presents are near!, check under the tree for one with your name, from Secret Santa, unwrap a fun game! 🎮🎁",
            "🎅 Jolly old Santa he's making his list, of presents and toys that will spread holiday bliss!, one gift he wrapped up is just for you, open with joy and see what's new! 🎁🤩",
            "🦌 Santa hitches his reindeer for a merry flight, through the dark winter's night, a present he's wrapped and placed under the tree, take a peak quick, it's meant to be! 👀🎁",
            "🧑‍🎄 Ho Ho Ho, Santa will soon appear!, with a magical sleigh and reindeer my dear!, check the gifts 'neath the tree lights aglow, a present from Santa is ready to go! 🎁",
            "🎅 Old Saint Nick down the chimney he'll fly, with a sack filled with goodies to satisfy!, one gift he's preparing is marked just for you, open with excitement over what's new! 🤩🎁",
            "🦌 Santa hitches up Rudolph to guide his sleigh, as presents and toys are loaded away!, one special gift wrapped with a bow, is from Secret Santa - go see what's below! 🔎🎁",
            "🧑‍🎄 Santa is coming, oh what a delight!, with magical presents wrapped up overnight!, there's one 'neath the tree with a tag marked just for you, from Santa himself, open and see what's new! 🎅🎁",
            "🎅 Jolly old Saint Nick he's hip and he's cool, he'll slide down the chimney like coming home from school!, a special present under the tree he has left, open with cheer put joy to the test! 🥳",
            "🦌 Santa gears up his reindeer for flight, on his Christmas Eve ride through the night, a gift wrapped with care he's placed under the tree, take a look quick, it's meant to be! 👀🎁",
            "🧑‍🎄 Ho Ho Ho, Santa is coming this way!, with presents and cheer on his holiday sleigh!, check under the tree for a gift marked for you, from Santa himself, open it before he shouts 'Ho Ho Ho!' 🎅",
            "🎅 Saint Nick the man dressed in red, is coming around with goodies ahead!, a special present placed under the tree, is from Santa for you, go grab it and see!🤩",
            "🦌 With his reindeer, Santa takes flight tonight, spreading holiday joy and delight!, a gift specially wrapped you are sure to like, from Secret Santa, go ahead, take a strike!🎳",
            "🧑‍🎄 Santa is coming bringing holiday cheer!, the time has arrived, Christmas is here!, check under the tree for a present with your name, from Santa himself, be sure to exclaim! 😲",
            "🎅 Jolly old Santa down the chimney he dives, with a sack full of presents for good boys and girls!, one gift he's preparing is just for you, open with joy over what's new!🤩",
            "🦌 Santa hooks up his reindeer for a merry flight tonight, on his Christmas Eve ride spreading delight!, a present wrapped up special for you, is under the tree from your Secret Santa, it's true! 🎁",
            "🧑‍🎄 Ho Ho Ho, Santa will soon be near!, Christmas day is drawing near!, there's a gift under the tree wrapped with your name, from Santa himself, play his holiday game!🎅",
            "🎅 Saint Nick the man dressed in red, is coming around with a sack on his sled!, a special present tucked under the tree, is from Santa for you, take a look and see!👀",
            "🦌 With his reindeer, Santa makes great time, bringing joy and cheer that will sublime!, a gift specially wrapped you are sure to love, from your Secret Santa sent from above!⭐",
            "🧑‍🎄 Santa is coming, get ready to cheer!, the time has arrived, presents are near!, there's one under the tree wrapped with your name, from Santa himself, unwrap this fun game!🕹️",
            "🎅 Jolly old Santa what a guy!, coming from the North Pole in the sky!, he left a gift under the tree, can't wait?, hurry up quick, don't be late! ⏰"
        ]
      },
  {
    type: 'Santa Arrival',
    templates: [
        "🎅 Secret Santa's coming, 🛷 sledding through the {weather}, ❄️ To leave you a mystery gift 🎁 tied up with a {decor}. 🎀",
        "🎅 Santa Claus is coming straight to {place}, with a gift to make your holidays shine bright, check under the tree and the presents in sight, there's one tagged from Santa, open it with delight! 😄",
        "🎅 'Tis the season of giving and cheer, a present from Santa is drawing near, this gift comes with joy from me to you, happy holidays, I hope you like it too! 🤶",
        "🧑‍🎄 Ho ho ho, Santa Clause is coming, he's making his list and checking it twice, to give you a gift that's sure to delight, enjoy this surprise from your Secret Santa tonight! 🎅",
        "🎅 Ole Saint Nick is racing his way, to deliver great gifts on this joyful day, he knows who's been naughty and nice this year, so open your gift and give a cheer! 🙌",
        "🎅 Jolly old St. Nicholas is on his way, with a magical sleigh filled with gifts this day, he's checked his list and has something for you, a present wrapped up from your Secret Santa crew! 🎀",
        "🧑‍🎄 Ho Ho Ho here Santa comes, riding his sleigh filled with goodies and fun, your gift is under the tree shining bright, open with cheer on this holiday night! 😄",
        "🎅 Santa's grabbed gifts for girls and for boys, all wrapped with care and holiday toys, he sees you've been nice so you're in for a treat, a present from Santa you're sure to love, eat or keep!😍",
        "🧑‍🎄 Santa Claus is coming around!, with magical gifts that will astound!, check under the tree, why wait another minute, a present from Santa, go ahead and open it in! 🎁",
        "🧑‍🎄 Ho Ho Ho, Santa will soon be here!, Christmas Day is drawing near!, check under the tree for a gift that's for you, from Santa himself, open it before he shouts 'Ho Ho Ho' and travels anew! 🎅"
      ]
    },
    {
      type: 'Santa Joy',
      templates: [
        "🔔 Jingle bells, jingle bells, 🔔 Santa's on his way: 🛷 Bringing 🎁 gifts, spreading {adjective} joy, and making spirits sway! 🎉",
        "🌟 Twinkling lights and joyful hearts, 💖 as Secret Santa, I played my part. Unwrap 🎁 the fun, it's just the start, Merry Christmas, with love ❤️ and {activity}! 🎨",
        "🔔 {holiday} whispers, {place} covered in white {weather}, {object} awaits gifts.",
        "🌟 Santa’s magic fills the {time_of_day}, with his presence, pure delight. In the {place}, joy is found, gifts of love all around.",
        "🎅 {adjective} Santa's here, spreading cheer, bringing {adjective} gifts, happiness near. In the {place}, warmth and light, make this {holiday} a joyous sight.",
        "🌟 With a twinkle in his eye, Santa spreads joy far and nigh. In the {place}, peace does reign, love and cheer never wane.",
        "🎅 Santa’s {adjective} reindeer fly so high, through the {weather} and starry sky. In the {place}, he brings delight, making spirits merry and bright.",
        "🔔 Jingle bells, jingle bells, all the way, Santa's {adjective} joy fills the day. In the {place}, gifts are found, spreading love all around.",
        "🌟 Santa's laugh, a merry sound, bringing joy all around. In the {place}, gifts abound, making hearts merry and sound.",
        "🎅 Santa’s sack of toys is full, bringing laughter, never dull. In the {place}, warmth and cheer, make this season bright and dear."
      ]
    },
    {
      type: 'Secret Gift',
      templates: [
        "Underneath the 🎄 Christmas tree, a 🎁 gift awaits from 🤫 Secret {name}. Unwrap it, dear, and you will see, a special something, with {activity} and glee!",
        "Roses are red, 🌹 violets are blue, a secret 🎁 gift is waiting for you. With holiday cheer, 🎅 I thought it through, Merry Christmas, and a Happy New Year! 🎉",
        "🌨️ Through the {weather} and frosty air, your Secret Santa's gift 🎁 is fair. It comes with {adjective} warmth and festive care, to show you how much I dare!",
        "Guess who, guess who? 🕵️‍♂️ Your Secret Santa's giving you a clue! A 🎁 present with a 🎀, so shiny and new, for a holiday season that’s joyful and true! ❤️",
        "Ssshh... 🤫 It's a secret, but here's a peek, 🎁 a gift you'll adore, unique and chic! With cheer 🎉 and love, from a friend 🧑‍🤝‍🧑 so meek!",
        "Snowflakes ❄️ and stars 🌟, Christmas is near, a time for love, joy, and cheer. From Secret Santa, a gift 🎁 to you, my dear!",
        "Ho Ho Ho! 🎅 A surprise from your elf, placed carefully on the shelf. Open me 🎁 for joy and good health!",
        "From the North Pole 📍, with a sleigh 🛷 and a prance, your Secret Santa brings joyance! A gift 🎁, a giggle 😄, for your Yuletide dance 💃!",
        "A little bird 🐦 told me you've been good, so here's a treat 🍬 from your neighborhood! Wrapped with care, and festive flair, your Secret Santa remembered to share! 🎁",
        "On the twelfth day of Christmas, 🎄 my Secret Santa gave to me, twelve 😂 laughs, eleven 🕯️ lights, and sweet holiday glee! 🎉"
      ]
    },
  {
    type: '12 Days of Christmas',
    templates: [
      `On the twelfth day of Christmas, 🎄 my Secret Santa gave to me, twelve 😂 laughs, eleven 🕯️ lights, and sweet holiday glee! 🎉`,
      `🎅 Santa's elves worked hard through the {time_of_day}, to make sure your gift turns out just right, with magic and cheer it was specially made, now check under the tree before Christmas Day fades! 🎄`,
      `🎅 On the first day of Christmas, my true love gave to me, a {object} in a {adjective} tree.`,
      `🎅 On the second day of Christmas, Secret Santa gave to me, two {animal} doves, and a {object} in a {adjective} tree.`,
      `🎅 On the third day of Christmas, Secret Santa gave to me, three {animal} hens, two {animal} doves, and a {object} in a {adjective} tree.`,
      `🎅 On the fourth day of Christmas, Secret Santa gave to me, four {adjective} birds, three {animal} hens, two {animal} doves, and a {object} in a {adjective} tree.`,
      `🎅 On the fifth day of Christmas, Secret Santa gave to me, five {adjective} rings, four {adjective} birds, three {animal} hens, two {animal} doves, and a {object} in a {adjective} tree.`,
      `🎅 On the sixth day of Christmas, Secret Santa gave to me, six {animal} geese a-laying, five {adjective} rings, four {adjective} birds, three {animal} hens, two {animal} doves, and a {object} in a {adjective} tree.`,
      `🎅 On the seventh day of Christmas, Secret Santa gave to me, seven {animal} swans a-swimming, six {animal} geese a-laying, five {adjective} rings, four {adjective} birds, three {animal} hens, two {animal} doves, and a {object} in a {adjective} tree.`,
      `🎅 On the eighth day of Christmas, Secret Santa gave to me, eight {profession} a-milking, seven {animal} swans a-swimming, six {animal} geese a-laying, five {adjective} rings, four {adjective} birds, three {animal} hens, two {animal} doves, and a {object} in a {adjective} tree.`
    ]
  },
  {
    type: 'Mrs. Claus',
    templates: [
      `🤶 Mrs. Claus is on the move, spreading cheer 🥳 and everything dear. A 🎁 gift from me, to someone so sweet, makes our Christmas 🎄 spirit complete!`,
      `🤶 From the North Pole elves working hard, to Santa loading his sleigh in the yard, your gift traveled far through ice and snow, now it's under the tree, ready for you to know! 🎄`,
      `🤶 Mrs. Claus and her elves, making toys for the {time_of_day}, wrapping gifts for {adjective} girls and boys.`,
      `🤶 Mrs. Claus’s magic touch, spreading joy and love so much. In the {place}, happiness clutch, Merry Christmas, and such.`,
      `🤶 Mrs. Claus’s sweet delight, bringing gifts on {holiday} night. In the {place}, spirits are bright, with {adjective} cheer, so right.`,
      `🤶 Mrs. Claus’s festive cheer, bringing gifts, spreading love so dear. In the {place}, joy is near, Merry Christmas, and good cheer.`,
      `🤶 Mrs. Claus’s special way, spreading joy this {holiday} day. In the {place}, love will stay, with gifts and cheer, hooray!`,
      `🤶 Mrs. Claus’s {adjective} heart, spreading cheer, playing her part. In the {place}, joy will start, Merry Christmas, from the heart.`,
      `🤶 Mrs. Claus’s gifts so sweet, making Christmas so complete. In the {place}, joy will meet, with love and cheer, what a treat.`,
      `🤶 Mrs. Claus’s {adjective} cheer, bringing joy this time of year. In the {place}, love is here, Merry Christmas, and so dear.`
    ]
  },
  {
    type: 'Guess Who',
    templates: [
      `Guess who, guess who? 🕵️‍♂️ Your Secret Santa's giving you a clue! A 🎁 present with a 🎀, so shiny and new, for a holiday season that’s joyful and true! ❤️`,
      `Ssshh... 🤫 It's a secret, but here's a peek, 🎁 a gift you'll adore, unique and chic! With cheer 🎉 and love, from a friend 🧑‍🤝‍🧑 so meek!`,
      `Guess who, guess who? 🕵️‍♀️ Secret Santa's giving you a clue! A {adjective} present just for you, with {activity} and love so true!`,
      `Guess who, guess who? 🕵️ Your Secret Santa's here for you! A {adjective} gift wrapped up tight, bringing joy this {holiday} night!`,
      `Guess who, guess who? 🕵️‍♂️ A gift from Santa just for you! With {adjective} cheer and love so bright, Merry Christmas and a good night!`,
      `Guess who, guess who? 🕵️‍♀️ Santa's here with a clue! A {adjective} gift for you to see, unwrap with joy and glee!`,
      `Guess who, guess who? 🕵️‍♂️ Secret Santa's gift for you! With {adjective} love and {adjective} cheer, Merry Christmas, my dear!`,
      `Guess who, guess who? 🕵️ A gift from Santa's crew! With {adjective} joy and {adjective} cheer, Merry Christmas and a Happy New Year!`,
      `Guess who, guess who? 🕵️‍♂️ Santa's got a clue for you! A {adjective} gift under the tree, unwrap with joy and glee!`,
      `Guess who, guess who? 🕵️‍♀️ Secret Santa's gift for you! With {adjective} cheer and love so true, Merry Christmas from me to you!`
    ]
  },
  {
    type: 'Snowflakes and Stars',
    templates: [
      `Snowflakes ❄️ and stars 🌟, Christmas is near, a time for love, joy, and cheer. From Secret Santa, a gift 🎁 to you, my dear!`,
      `Snowflakes and stars, Christmas delight, bringing joy on this {adjective} night. From Secret Santa, a gift in sight, with {adjective} cheer and {adjective} light.`,
      `Snowflakes ❄️ and stars 🌟, Christmas cheer, a time for love and joy so dear. From Secret Santa, a gift is near, with {adjective} cheer, Merry Christmas, my dear!`,
      `Snowflakes ❄️ and stars 🌟, Christmas time, bringing joy with every rhyme. From Secret Santa, a gift so fine, with {adjective} cheer, let it shine!`,
      `Snowflakes and stars, Christmas glee, a time for love and {adjective} cheer. From Secret Santa, a gift you see, with {adjective} joy, Merry Christmas to thee!`,
      `Snowflakes ❄️ and stars 🌟, Christmas fun, bringing joy for everyone. From Secret Santa, a gift has come, with {adjective} cheer, let it run!`,
      `Snowflakes ❄️ and stars 🌟, Christmas night, a time for love and {adjective} light. From Secret Santa, a gift so bright, with {adjective} cheer, let it take flight!`,
      `Snowflakes and stars, Christmas cheer, a time for joy and love so dear. From Secret Santa, a gift is here, with {adjective} cheer, Merry Christmas, my dear!`,
      `Snowflakes ❄️ and stars 🌟, Christmas Eve, a time for love and {adjective} belief. From Secret Santa, a gift received, with {adjective} cheer, let it achieve!`,
      `Snowflakes and stars, Christmas light, bringing joy on this {adjective} night. From Secret Santa, a gift in sight, with {adjective} cheer, let it ignite!`
    ]
  },
  {
    type: 'Elf Surprise',
    templates: [
      `Ho Ho Ho! 🎅 A surprise from your elf, placed carefully on the shelf. Open me 🎁 for joy and good health!`,
      `Ho Ho Ho! 🎅 A surprise from your elf, bringing cheer and joy itself. In the {place}, happiness dwells, open the gift and all will be well!`,
      `Ho Ho Ho! 🎅 A surprise from your elf, bringing joy and {adjective} health. In the {place}, happiness swells, open the gift and hear the bells!`,
      `Ho Ho Ho! 🎅 A surprise from your elf, bringing {adjective} cheer and good health. In the {place}, happiness tells, open the gift and all excels!`,
      `Ho Ho Ho! 🎅 A surprise from your elf, bringing {adjective} joy and {adjective} health. In the {place}, happiness wells, open the gift and hear the bells!`,
      `Ho Ho Ho! 🎅 A surprise from your elf, bringing {adjective} cheer and {adjective} wealth. In the {place}, happiness dwells, open the gift and all is well!`,
      `Ho Ho Ho! 🎅 A surprise from your elf, bringing {adjective} joy and {adjective} self. In the {place}, happiness tells, open the gift and hear the bells!`,
      `Ho Ho Ho! 🎅 A surprise from your elf, bringing {adjective} cheer and {adjective} health. In the {place}, happiness dwells, open the gift and all will tell!`,
      `Ho Ho Ho! 🎅 A surprise from your elf, bringing {adjective} joy and {adjective} wealth. In the {place}, happiness wells, open the gift and hear the bells!`,
      `Ho Ho Ho! 🎅 A surprise from your elf, bringing {adjective} cheer and {adjective} self. In the {place}, happiness tells, open the gift and all excels!`
    ]
  },
  {
    type: 'North Pole',
    templates: [
      `From the North Pole 📍, with a sleigh 🛷 and a prance, your Secret Santa brings joyance! A gift 🎁, a giggle 😄, for your Yuletide dance 💃!`,
      `From the North Pole, with a sleigh and a {adjective} prance, your Secret Santa brings joyance! A gift, a giggle, for your Yuletide dance!`,
      `From the North Pole, with a sleigh and {adjective} prance, your Secret Santa brings {adjective} joyance! A gift, a giggle, for your Yuletide dance!`,
      `From the North Pole, with a sleigh and a {adjective} prance, your Secret Santa brings joyance! A gift, a giggle, for your Yuletide dance!`,
      `From the North Pole, with a sleigh and {adjective} chance, your Secret Santa brings joyance! A gift, a giggle, for your Yuletide dance!`,
      `From the North Pole, with a sleigh and {adjective} glance, your Secret Santa brings {adjective} joyance! A gift, a giggle, for your Yuletide dance!`,
      `From the North Pole, with a sleigh and a {adjective} prance, your Secret Santa brings joyance! A gift, a giggle, for your Yuletide dance!`,
      `From the North Pole, with a sleigh and {adjective} trance, your Secret Santa brings joyance! A gift, a giggle, for your Yuletide dance!`,
      `From the North Pole, with a sleigh and {adjective} stance, your Secret Santa brings {adjective} joyance! A gift, a giggle, for your Yuletide dance!`,
      `From the North Pole, with a sleigh and a {adjective} prance, your Secret Santa brings joyance! A gift, a giggle, for your Yuletide dance!`
    ]
  },
  {
    type: 'Neighborhood Treat',
    templates: [
      `A little bird 🐦 told me you've been good, so here's a treat 🍬 from your neighborhood! Wrapped with care, and festive flair, your Secret Santa remembered to share! 🎁`,
      `A little bird 🐦 told me you've been {adjective}, so here's a treat 🍬 from your neighborhood! Wrapped with care, and festive flair, your Secret Santa remembered to share! 🎁`,
      `A little bird 🐦 told me you've been good, so here's a treat 🍬 from your neighborhood! Wrapped with {adjective} care, and festive flair, your Secret Santa remembered to share! 🎁`,
      `A little bird 🐦 told me you've been good, so here's a {adjective} treat 🍬 from your neighborhood! Wrapped with care, and festive flair, your Secret Santa remembered to share! 🎁`,
      `A little bird 🐦 told me you've been good, so here's a treat 🍬 from your {adjective} neighborhood! Wrapped with care, and festive flair, your Secret Santa remembered to share! 🎁`,
      `A little bird 🐦 told me you've been good, so here's a treat 🍬 from your neighborhood! Wrapped with care, and {adjective} flair, your Secret Santa remembered to share! 🎁`,
      `A little bird 🐦 told me you've been {adjective}, so here's a treat 🍬 from your neighborhood! Wrapped with {adjective} care, and festive flair, your Secret Santa remembered to share! 🎁`,
      `A little bird 🐦 told me you've been good, so here's a treat 🍬 from your neighborhood! Wrapped with {adjective} care, and festive flair, your Secret Santa remembered to share! 🎁`,
      `A little bird 🐦 told me you've been good, so here's a {adjective} treat 🍬 from your neighborhood! Wrapped with care, and festive flair, your Secret Santa remembered to share! 🎁`,
      `A little bird 🐦 told me you've been good, so here's a treat 🍬 from your {adjective} neighborhood! Wrapped with care, and festive flair, your Secret Santa remembered to share! 🎁`
    ]
  }
];

export const wordLists = {
  weather: ['snow', 'frost', 'chill'],
  decor: ['bow', 'ribbon', 'twine'],
  place: ['house', 'home', 'cottage', 'living room', 'bedroom'],
  holiday: ['Christmas', 'Holiday Season', 'Yuletide'],
  adjective: ['joyful', 'merry', 'bright', 'warm', 'magical'],
  time_of_day: ['night', 'evening', 'morning'],
  surface: ['roof', 'floor', 'ceiling'],
  activity: ['art', 'games', 'music'],
  animal: ['mouse', 'cat', 'dog', 'bird'],
  object: ['fireplace', 'mantel', 'tree'],
  profession: ['maids', 'dancers', 'lords'],
  name: ['Santa', 'St. Nick', 'Father Christmas']
};
