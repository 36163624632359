<template>
	<BreadCrumb PageTitle="Text Repeater" />
	<div class="custom-container mt-5 mb-50">
	  <div class="row mb-5">
		<div class="col">
		  <h1 class="text-center">Text Repeater</h1>
		  <p class="fs-6 text-center mb-4">"Supercharge your content with our Text Repeater – The Ultimate Repeat Text Generator for Instant, Effortless, and Creative Repetition!"</p>
		</div>
	  </div>
	  <div v-if="error" class="alert alert-warning mt-2" role="alert">{{ error }}</div>
	  <div class="container mt-3">
		<div class="inputTextlabel">
		  <label class="form-label" for="inputText">Enter text to repeat below</label>
		  <textarea id="inputText" v-model="inputText" class="form-control" placeholder="Enter text here" rows="5" @input="updateCounts"></textarea>
		  <div class="d-flex justify-content-between">
			<p>Word Count: <span>{{ wordCount }}</span></p>
			<p>Characters Count: <span>{{ charCount }}</span></p>
		  </div>
		</div>
		<div class="row">
		  <div class="col-6">
			<div class="input-group mb-3">
			  <span class="input-group-text" for="repeatCount">Repeat Count</span>
			  <input id="repeatCount" v-model="repeatCount" class="form-control" type="number" placeholder="Repeat count" />
			</div>
		  </div>
		  <div class="col-6">
			<div class="input-group mb-3">
			  <input id="separator" v-model="separator" class="form-control" type="text" placeholder="Enter separator" />
			  <span class="input-group-text" for="separator">Separator</span>
			</div>
		  </div>
		</div>
		<div>
		  <div class="form-check mt-2">
			<input id="newLineCheckbox" v-model="newLine" class="form-check-input" type="checkbox" />
			<label class="form-check-label mb-2" for="newLineCheckbox">On new line</label>
		  </div>
		  <div class="form-check mb-2">
			<input id="addSpaceCheckbox" v-model="addSpace" class="form-check-input" type="checkbox" />
			<label class="form-check-label" for="addSpaceCheckbox">Add space</label>
		  </div>
		  <div class="row">
			<div class="col-6">
			  <button @click="generateRepeatedText" class="btn btn-primary d-flex justify-content-center align-items-center mb-4 w-100" data-bs-toggle="tooltip" title="Generate Repeated Text">
				Repeat Text
			  </button>
			</div>
			<div class="col-6">
			  <button @click="clearText" class="btn btn-danger d-flex justify-content-center align-items-center mb-4 w-100">
				<i class="bi bi-eraser me-1"></i>Clear
			  </button>
			</div>
		  </div>
		</div>
		<div class="text-center mt-3">
		  <h5 class="form-label" for="outputText">Repeated Texts</h5>
		  <div class="position-relative">
			<textarea id="outputText" v-model="outputText" class="form-control repeated-texts fs-5" readonly></textarea>
			<button @click="copyToClipboard" class="btn btn-link position-absolute top-0 end-0" title="Copy to clipboard">
			  <i :class="['bi', copied ? 'bi-clipboard-check' : 'bi-clipboard']"></i>
			</button>
		  </div>
		</div>
		<div class="d-flex justify-content-between">
		  <p>Word Count: <span>{{ repeatWordCount }}</span></p>
		  <p>Characters Count: <span>{{ repeatCharCount }}</span></p>
		</div>
	  </div>
	  
	  <!-- FAQ Section -->
	  <div class="mt-5">
		<TextRepeaterFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import TextRepeaterFAQ from '../FAQs/onlineTextRepeaterFAQ.vue';
  
  export default {
	components: {
	  BreadCrumb,
	  TextRepeaterFAQ
	},
	setup() {
	  const inputText = ref('');
	  const repeatCount = ref(1);
	  const separator = ref('');
	  const newLine = ref(false);
	  const addSpace = ref(false);
	  const outputText = ref('');
	  const error = ref('');
	  const copied = ref(false);
  
	  const wordCount = computed(() => inputText.value.trim().split(/\s+/).filter(Boolean).length);
	  const charCount = computed(() => inputText.value.length);
	  const repeatWordCount = computed(() => outputText.value.trim().split(/\s+/).filter(Boolean).length);
	  const repeatCharCount = computed(() => outputText.value.length);
  
	  function generateRepeatedText() {
		if (!inputText.value.trim()) {
		  error.value = 'Please enter some text to repeat.';
		  return;
		}
		error.value = '';
  
		let repeatedText = '';
		for (let i = 0; i < repeatCount.value; i++) {
		  repeatedText += inputText.value;
		  if (i < repeatCount.value - 1) {
			if (newLine.value) repeatedText += '\n';
			if (addSpace.value) repeatedText += ' ';
			repeatedText += separator.value;
		  }
		}
		outputText.value = repeatedText;
	  }
  
	  function clearText() {
		inputText.value = '';
		outputText.value = '';
		error.value = '';
	  }
  
	  function updateCounts() {
		// This function is called on input, but we don't need to do anything
		// since we're using computed properties for the counts
	  }
  
	  function copyToClipboard() {
		navigator.clipboard.writeText(outputText.value).then(() => {
		  copied.value = true;
		  setTimeout(() => {
			copied.value = false;
		  }, 2000);
		});
	  }
  
	  return {
		inputText,
		repeatCount,
		separator,
		newLine,
		addSpace,
		outputText,
		error,
		copied,
		wordCount,
		charCount,
		repeatWordCount,
		repeatCharCount,
		generateRepeatedText,
		clearText,
		updateCounts,
		copyToClipboard,
	  };
	},
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  .repeated-texts {
	min-height: 200px;
  }
  .accordion-item {
	color: #d1cfff
  }
  </style>