<template>
        <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
            <div class="card-body p-15 p-sm-20">
                <div class="accordion" id="faqAccordion">
                    <div class="row">
                        <div class="text-center mb-3"><h3>FAQs on Word Flipper Generator</h3></div>
                        <div class="accordion-item rounded-0 border-0">
                            <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                                <h5>What is Word Flipper Generator?</h5>
                            </button>
                            <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        The Word Flipper Generator is a unique tool designed to transform your standard text into its flipped, or upside-down, counterpart. Whether you're looking to create fun messages or simply explore the mirrored versions of letters, this tool is both entertaining and enlightening.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item rounded-0 border-0">
                            <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="false" aria-controls="faqCollapseTwo">
                                <h5>Understanding Flip Styles</h5>
                            </button>
                            <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        The Word Flipper Generator offers various styles to transform your text. Here's what each style does:
                                        <br>
                                        <strong>1. Upside Down:</strong> This style flips each character upside down, giving the appearance that the text is viewed from the opposite direction.
                                        <br>
                                        <strong>2. Flip Vertically:</strong> Characters are flipped vertically, resembling a mirror reflection with a horizontal axis at the middle of each character.
                                        <br>
                                        <strong>3. Flip Horizontally:</strong> This mirrors each character on a vertical axis, creating a left-to-right swapped appearance.
                                        <br>
                                        <strong>4. Reverse Text:</strong> This simply reverses the order of characters in your text, making the last character first and the first character last, without altering the orientation of individual characters.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item rounded-0 border-0">
                            <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="false" aria-controls="faqCollapseThree">
                                <h5>How to Use the Word Flipper Generator</h5>
                            </button>
                            <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        <strong>1. Input your Text:</strong> Navigate to the "Enter Texts" section and type or paste the text you'd like to flip into the provided textarea.
                                        <br>
                                        <strong>2. Flip the Words:</strong> Click on the "Flip Words" button. If your input text is valid, you will immediately see the flipped version of your text in the "Flipped Words" section below.
                                        <br>
                                        <strong>3. Handling Errors:</strong> If you try to flip an empty text, an alert will appear, prompting you to enter some text before flipping.
                                        <br>
                                        <strong>4. Reset:</strong> If you wish to start over or enter new text, click the "Reset" button to clear both the input and output textareas.
                                        <br>
                                        <strong>5. Copy Flipped Text:</strong> After generating the flipped text, you can easily copy it to your clipboard by clicking the "Copy Text" button. Once clicked, the button will momentarily change to "Copied!" to confirm the action.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item rounded-0 border-0">
                            <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="false" aria-controls="faqCollapseFour">
                                <h5>Tips and Tricks</h5>
                            </button>
                            <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        - While most characters can be flipped, some special characters or symbols might not have a flipped counterpart and will remain unchanged.
                                        <br>
                                        - The tool is designed to be intuitive and user-friendly, so feel free to experiment and have fun!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
  
  <script>
  export default {
    name: 'WordFlipperFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  