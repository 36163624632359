<template>
<div class="card mb-25 mt-50 border-0 rounded-0 bg-white letter-spacing faq-accordion-card">
  <div class="card-body p-15 p-sm-20">
    <div class="accordion" id="faqAccordion">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-3">
            <h3>FAQs on Cringe Text Generator</h3>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button
              class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapseOne"
              aria-expanded="true"
              aria-controls="faqCollapseOne">
              <h5>What is the Cringe Text Generator?</h5>
            </button>
            <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  The Cringe Text Generator is a fun web tool that transforms your normal text into quirky, attention-grabbing, and often humorous "cringe" style text. It allows you to add emojis and randomly alternate between uppercase and lowercase letters for a unique and engaging look.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button
              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapseTwo"
              aria-expanded="true"
              aria-controls="faqCollapseTwo">
              <h5>How do I use the Cringe Text Generator?</h5>
            </button>
            <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  Follow these steps to use the Cringe Text Generator:
                  <ol>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Enter your text in the input box.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Enable "Include Emojis" if you want to add emojis to your text.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Click the "Generate Cringe Text" button.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Copy and share your cringe-worthy text on social media!</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button
              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapseThree"
              aria-expanded="true"
              aria-controls="faqCollapseThree">
              <h5>How do I customize the cringe text?</h5>
            </button>
            <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  You can customize your cringe text using the following options:
                  <ul>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Include Emojis:</strong> Enable this option to add random emojis to your text.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Randomize Capitalization:</strong> The text will automatically alternate between uppercase and lowercase letters.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button
              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapseFour"
              aria-expanded="true"
              aria-controls="faqCollapseFour">
              <h5>Can I copy the generated cringe text?</h5>
            </button>
            <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  Yes, you can copy the generated cringe text by clicking on it. The tooltip will change to "Copied!" for a second to confirm the action.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button
              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapseFive"
              aria-expanded="true"
              aria-controls="faqCollapseFive">
              <h5>Is the Cringe Text Generator free to use?</h5>
            </button>
            <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  Yes, the Cringe Text Generator is completely free to use. You can generate and copy as much cringe text as you like without any cost.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button
              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapseSix"
              aria-expanded="true"
              aria-controls="faqCollapseSix">
              <h5>Do I need to create an account to use the Cringe Text Generator?</h5>
            </button>
            <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  No, you do not need to create an account to use the Cringe Text Generator. The tool is available for immediate use without any registration.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button
              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapseSeven"
              aria-expanded="true"
              aria-controls="faqCollapseSeven">
              <h5>How can I share my cringe text?</h5>
            </button>
            <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  You can share your cringe text by copying it and then pasting it on social media, in emails, or in messaging apps.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button
              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapseEight"
              aria-expanded="true"
              aria-controls="faqCollapseEight">
              <h5>Can I use the Cringe Text Generator on mobile?</h5>
            </button>
            <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  Yes, the Cringe Text Generator is mobile-friendly and can be used on both smartphones and tablets.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button
              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapseNine"
              aria-expanded="true"
              aria-controls="faqCollapseNine">
              <h5>What are some creative ways to use the Cringe Text Generator?</h5>
            </button>
            <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  Here are some creative tips:
                  <ul>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Embrace Emojis: Mix and match emojis to add expressiveness. 😂😢</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Play with Phrases: Create quirky and nonsensical sentences.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Randomize Capitalization: Experiment with the uppercase frequency slider.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Rhyming and Repetition: Add rhyming words and repetitive patterns.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Punctuation Overload: Use excessive punctuation for extra chaos!!!</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Mix Languages: Combine words from different languages.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Unexpected References: Make references to obscure pop culture.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Nonsensical Characters: Invent whimsical characters like "Sir Quacks-a-Lot."</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Over-Exaggerate Expressions: Use phrases like "super duper" for extra cringe.</li>
                    <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Create a Story: Craft interesting and fantastical stories within your text.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </template>
  
  <script>
  export default {
    name: 'cringeTextGeneratorFAQ',
  };
  </script>
  
  <style scoped>
  .custom-container {
    max-width: 80vh;
    margin: auto;
  }
  </style>
  