<template>
  <BreadCrumb PageTitle="Multiple Word Replacer" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Multiple Word Replacer</h1>
    <p class="fs-6 text-center mb-20">This simple tool helps you find and replace multiple texts from the input you provide. Just paste your text and desired change text separated by comma, and see the magic happen!</p>
    <div>
      <TextAreaComponent 
        label="Input Text"
        labelFor="input-text-area"
        :inputText="inputText" 
        @updateInputText="updateInputText"
      />
      <ReplacementRuleComponent 
        :rules="rules" 
        @addRule="addRule" 
        @editRule="editRule" 
        @deleteRule="deleteRule"
        @clearAllRules="clearAllRules"
      />
      <div class="form-check mt-20">
        <input class="form-check-input" type="checkbox" id="wholeWords" v-model="matchWholeWords">
        <label class="form-check-label" for="wholeWords">
          Match Whole Words
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="caseSensitive" v-model="caseSensitive">
        <label class="form-check-label" for="caseSensitive">
          Case Sensitive
        </label>
      </div>
      <div class="row mt-20 mb-20">
          <div class="col-md-6 mb-2">
              <button @click="replaceText" class="btn btn-primary w-100">Replace</button>
          </div>
          <div class="col-md-6 mb-2">
              <button @click="resetAll" class="btn btn-danger w-100">Reset</button>
          </div>
      </div>
      <TextAreaComponent 
        label="Output Text"
        labelFor="output-text-area"
        :inputText="processedText" 
        placeholder="Your processed text will appear here..."
        readonly
      />
      <button @click="copyText" class="btn btn-secondary mt-2 w-100">Copy Text</button>
      <div class="mt-2 mb-50">
        <h5>Occurrences replaced:</h5>
        <ul class="fs-6">
          <li v-for="(count, word) in occurrencesReplaced" :key="word">{{ word }}: {{ count }}</li>
        </ul>
      </div>
    </div>
    <div>
      <MultipleWordReplacerFAQ />
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import TextAreaComponent from '../../components/VueFiles/TextAreaComponent.vue';
import ReplacementRuleComponent from '../../components/VueFiles/ReplacementRuleComponent.vue';
import MultipleWordReplacerFAQ from '../FAQs/multipleWordReplacerFAQ.vue';

export default {
  components: {
    BreadCrumb,
    TextAreaComponent,
    ReplacementRuleComponent,
    MultipleWordReplacerFAQ
  },
  data() {
    return {
      inputText: '',
      processedText: '',
      rules: [],
      matchWholeWords: false,
      caseSensitive: false,
      occurrencesReplaced: reactive({})
    };
  },
  methods: {
    updateInputText(newText) {
      this.inputText = newText;
    },
    addRule(newRule) {
      this.rules.push(newRule);
    },
    editRule(updatedRule) {
      const index = this.rules.findIndex(rule => rule.id === updatedRule.id);
      if (index !== -1) {
        this.rules.splice(index, 1, updatedRule);
      }
    },
    deleteRule(ruleId) {
      this.rules = this.rules.filter(rule => rule.id !== ruleId);
    },
    clearAllRules() {
      this.rules = [];
    },
    replaceText() {
      this.processedText = this.inputText;
      this.occurrencesReplaced = reactive({});

      this.rules.forEach(rule => {
        let regexPattern = rule.original.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
        if (this.matchWholeWords) {
          regexPattern = `\\b${regexPattern}\\b`;
        }
        const flags = this.caseSensitive ? 'g' : 'gi';
        const regex = new RegExp(regexPattern, flags);

        let matchCount = 0;
        this.processedText = this.processedText.replace(regex, (match) => {
          matchCount++;
          return rule.replacement;
        });

        if (matchCount > 0) {
          this.occurrencesReplaced[rule.original] = matchCount;
        }
      });
    },
    resetAll() {
      this.inputText = '';
      this.processedText = '';
      this.rules = [];
      this.occurrencesReplaced = reactive({});
      this.matchWholeWords = false;
      this.caseSensitive = false;
    },
    copyText() {
      navigator.clipboard.writeText(this.processedText).then(() => {
        alert('Text copied to clipboard');
      }, () => {
        alert('Failed to copy text');
      });
    }
  }
};
</script>

<style scoped>
.custom-container {
  max-width: 85vh;
  margin: auto;
}
.main-content {
  margin-top: 20px;
}
.actions {
  margin: 20px 0;
  text-align: center;
}

.form-control {
  font-size: 16px;
}
</style>