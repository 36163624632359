<template>
    <div class="card mb-25 mt-50 border-0 rounded-0 bg-white letter-spacing faq-accordion-card">
  <div class="card-body p-15 p-sm-20">
    <div class="accordion" id="faqAccordion">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-3">
            <h3>FAQs on HTML Validator</h3>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
              <h5>What is HTML Validator?</h5>
            </button>
            <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  HTML Validator is an online tool designed to help web developers and designers ensure that their HTML code is free from errors and follows the standards set by the World Wide Web Consortium (W3C). By ensuring your markup is error-free, you can create more accessible and cross-compatible web pages.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="false" aria-controls="faqCollapseTwo">
              <h5>How to Use HTML Validator?</h5>
            </button>
            <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  1. <strong>Uploading an HTML File:</strong> Click on the "Upload HTML" button. Navigate to the location of your HTML file on your device, select it, and click on "Open" or a similar option based on your device's interface.
                </p>
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  2. <strong>Pasting HTML Code:</strong> If you don't have a file but have the code ready, simply paste your HTML code into the provided textarea labeled "Paste HTML Code."
                </p>
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  3. <strong>Validating the HTML:</strong> After uploading or pasting your HTML, click on the "Validate HTML" button. The tool will process your HTML and provide feedback. If there are any errors in your HTML, they will be displayed with details on how to resolve them.
                </p>
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  4. <strong>Clearing the Input:</strong> To remove the uploaded file or the pasted code, click on the "Clear HTML" button. This will reset the validator for your next use.
                </p>
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  5. <strong>Sharing Validation Results:</strong> After validating your HTML, you can share the results with your team or friends using the provided share buttons for Facebook, Twitter, Reddit, and WhatsApp.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-0 border-0">
            <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="false" aria-controls="faqCollapseThree">
              <h5>Tips for a Better Validation Experience</h5>
            </button>
            <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  - Always ensure that the HTML code you're validating is complete. Fragmented or incomplete code can lead to unexpected errors.
                </p>
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  - While the validator provides guidance on resolving errors, it's crucial to understand the context of your HTML. Some recommendations might need careful consideration based on the specific needs of your web page.
                </p>
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  - Sharing the validation results can help in collaborative projects. However, ensure that there's no sensitive information in your HTML before sharing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </template>
  
  <script>
  export default {
    name: 'HTMLValidatorFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  