<template>
	<BreadCrumb PageTitle="Random Facts Generator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Random Facts Generator</h1>
	  <p class="fs-6 text-center mb-4">Explore intriguing facts across various subjects with our Random Facts Generator. Uncover hidden truths in history, science, and more!</p>
	  <div class="row mt-4 mb-4">
		<div class="col-md-6">
		  <div class="input-group mb-3">
			<span class="input-group-text" for="category">Category:</span>
			<select id="category" v-model="selectedCategory" class="form-select">
			  <option v-for="category in sortedCategories" :key="category" :value="category">
				{{ category.charAt(0).toUpperCase() + category.slice(1) }} Facts
			  </option>
			</select>
		  </div>
		</div>
		<div class="col-md-6">
		  <div class="input-group">
			<span class="input-group-text" for="numFacts">No. of Facts:</span>
			<input id="numFacts" v-model.number="numFacts" class="form-control" type="number" max="6" min="1" />
		  </div>
		</div>
	  </div>
	  <div class="col">
		<button @click="generateFacts" class="btn btn-primary d-flex justify-content-center align-items-center mb-4 btn-hover w-100" data-bs-toggle="tooltip" title="Generate Random Facts">
		  Generate Facts
		</button>
	  </div>
	  <div class="col-md-12">
		<div>
		  <h4 class="text-center">Generated Facts</h4>
		  <div v-if="error" class="alert alert-warning mt-2" role="alert">
			<span>{{ error }}</span>
		  </div>
		  <div class="generatedQuestions" id="factsContainer">
			<div v-if="generatedFacts.length === 0">No facts generated yet.</div>
			<div v-else class="row">
			  <div v-for="(fact, index) in generatedFacts" :key="index" class="col-md-6 mb-3">
				<div class="card">
				  <div class="card-body">
					<p class="card-text fs-5">{{ fact.fact }}</p>
					<div class="d-flex justify-content-between align-items-center">
					  <a :href="fact.source" target="_blank" class="card-link">[source]</a>
					  <button @click="copyFact(fact.fact, index)" class="btn btn-link p-0">
						<i :class="['bi', copiedIndex === index ? 'bi-clipboard-check' : 'bi-clipboard']"></i>
					  </button>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	  
	  <!-- FAQ Section -->
	  <div class="mt-5">
		<RandomFactsGeneratorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, computed, onMounted } from 'vue';
  import randomFacts from '../../components/JSON/randomFacts.json';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import RandomFactsGeneratorFAQ from '../FAQs/randomFactsGeneratorFAQ.vue';
  
  export default {
	name: 'RandomFactsGenerator',
	components: {
	  BreadCrumb,
	  RandomFactsGeneratorFAQ
	},
	setup() {
	  const categories = ref([]);
	  const selectedCategory = ref('');
	  const numFacts = ref(1);
	  const generatedFacts = ref([]);
	  const error = ref('');
	  const copiedIndex = ref(null);
  
	  const sortedCategories = computed(() => {
		return [...categories.value].sort((a, b) => a.localeCompare(b));
	  });
  
	  onMounted(() => {
		categories.value = Object.keys(randomFacts);
		if (categories.value.length > 0) {
		  selectedCategory.value = sortedCategories.value[0];
		}
	  });
  
	  const generateFacts = () => {
		error.value = '';
		if (!selectedCategory.value) {
		  error.value = 'Please select a category.';
		  return;
		}
  
		const categoryFacts = randomFacts[selectedCategory.value];
		const shuffled = [...categoryFacts].sort(() => 0.5 - Math.random());
		generatedFacts.value = shuffled.slice(0, numFacts.value);
	  };
  
	  const copyFact = (fact, index) => {
		navigator.clipboard.writeText(fact).then(() => {
		  copiedIndex.value = index;
		  setTimeout(() => {
			copiedIndex.value = null;
		  }, 2000);
		}).catch(err => {
		  console.error('Failed to copy text: ', err);
		});
	  };
  
	  return {
		sortedCategories,
		selectedCategory,
		numFacts,
		generatedFacts,
		error,
		generateFacts,
		copyFact,
		copiedIndex,
	  };
	},
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  .btn-hover:hover {
	opacity: 0.8;
  }
  
  .card {
	height: 100%;
  }
  
  .card-body {
	display: flex;
	flex-direction: column;
  }
  
  .card-text {
	flex-grow: 1;
  }
  </style>