<!-- src/App.vue -->
<template>
  <BreadCrumb PageTitle="Millisecond to Human Readable Converter" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Millisecond to Human Readable Converter</h1>
    <p class="text-center fs-6">Convert milliseconds to a human-readable format below</p>

    <!-- Millisecond Input Section -->
    <div class="form-group">
      <label for="millisecondInput" class="fs-6">Milliseconds Input</label>
      <input
        id="millisecondInput"
        type="number"
        class="form-control fs-6"
        v-model="milliseconds"
        placeholder="Enter milliseconds..."
      />
    </div>

    <!-- Language Dropdown -->
    <div class="form-group mt-3">
      <label for="languageSelect" class="fs-6">Select Language</label>
      <select id="languageSelect" class="form-control fs-6" v-model="selectedLanguage">
        <option v-for="(name, code) in supportedLanguages" :key="code" :value="code">
          {{ name }}
        </option>
      </select>
    </div>

    <!-- Convert Button -->
    <button class="btn btn-primary mt-3 w-100" @click="convertMilliseconds">Convert to Human Readable</button>

    <!-- Error Message -->
    <div v-if="error" class="alert alert-danger mt-3">
      {{ error }}
    </div>

    <!-- Human Readable Output Section -->
    <div v-if="humanReadable" class="mt-4">
      <h3>Human Readable Output</h3>
      <p class="fs-4">{{ humanReadable }}</p>

      <!-- Action Buttons -->
      <div class="row mt-2">
        <div class="mb-2">
          <button class="btn btn-secondary w-100" @click="copyToClipboard">
            <i :class="clipboardIcon"></i> {{ clipboardText }}
          </button>
        </div>
      </div>
    </div>

    <!-- FAQ Section -->
    <div class="mt-50 mb-50">
      <h2>Milliseconds to Human Readable FAQ</h2>
      <div v-for="(item, index) in faqList" :key="index" class="mb-4">
        <h4>{{ item.question }}</h4>
        <p v-html="item.answer" class="fs-6"></p>
      </div>
    </div>
  </div>
</template>

<script>
import humanizeDuration from 'humanize-duration';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  data() {
  return {
    milliseconds: '',
    humanReadable: '',
    error: '',
    clipboardIcon: 'bi bi-clipboard',
    clipboardText: 'Copy to Clipboard',
    selectedLanguage: 'en',  // Default language is English
    supportedLanguages: {
      af: 'Afrikaans',
      sq: 'Albanian',
      am: 'Amharic',
      ar: 'Arabic',
      eu: 'Basque',
      bn: 'Bengali',
      bg: 'Bulgarian',
      ca: 'Catalan',
      ckb: 'Central Kurdish',
      zh_CN: 'Chinese, Simplified',
      zh_TW: 'Chinese, Traditional',
      hr: 'Croatian',
      cs: 'Czech',
      da: 'Danish',
      nl: 'Dutch',
      en: 'English',
      eo: 'Esperanto',
      et: 'Estonian',
      fo: 'Faroese',
      fa: 'Farsi/Persian',
      fi: 'Finnish',
      fr: 'French',
      de: 'German',
      el: 'Greek',
      he: 'Hebrew',
      hi: 'Hindi',
      hu: 'Hungarian',
      is: 'Icelandic',
      id: 'Indonesian',
      it: 'Italian',
      ja: 'Japanese',
      kn: 'Kannada',
      km: 'Khmer',
      ko: 'Korean',
      ku: 'Kurdish',
      lo: 'Lao',
      lv: 'Latvian',
      lt: 'Lithuanian',
      mk: 'Macedonian',
      mn: 'Mongolian',
      ms: 'Malay',
      mr: 'Marathi',
      no: 'Norwegian',
      pl: 'Polish',
      pt: 'Portuguese',
      ro: 'Romanian',
      ru: 'Russian',
      sr: 'Serbian',
      sk: 'Slovak',
      sl: 'Slovenian',
      es: 'Spanish',
      sw: 'Swahili',
      sv: 'Swedish',
      ta: 'Tamil',
      te: 'Telugu',
      th: 'Thai',
      tr: 'Turkish',
      uk: 'Ukrainian',
      ur: 'Urdu',
      uz: 'Uzbek',
      uz_CYR: 'Uzbek (Cyrillic)',
      vi: 'Vietnamese',
      cy: 'Welsh'
    },
    faqList: [
  {
    question: '1. What does this converter do?',
    answer: `This tool converts a number representing milliseconds into a human-readable format like 
             "3 days, 1 hour, 47 minutes". It supports multiple languages, allowing users to convert 
             time durations into formats that are easy to understand in their preferred language.`
  },
  {
    question: '2. How do I use the converter?',
    answer: `Enter a number in the "Milliseconds Input" field, select the language from the dropdown, and 
             click the "Convert to Human Readable" button. The tool will convert the milliseconds to a 
             human-readable format like "2 weeks, 4 days".`
  },
  {
    question: '3. What are milliseconds?',
    answer: `Milliseconds (ms) are a unit of time representing one thousandth (1/1000) of a second. 
             For example, 1000 milliseconds equal 1 second, and 60000 milliseconds equal 1 minute.`
  },
  {
    question: '4. What languages are supported?',
    answer: `The converter supports a wide range of languages, including English, Spanish, French, German, 
             Chinese, Japanese, Arabic, and many others. You can select your preferred language from the 
             dropdown list, and the output will be updated automatically.`
  },
  {
    question: '5. Can I customize the output format?',
    answer: `Yes, the output can be customized by adjusting options such as the number of units displayed 
             or the language used. For example, you can choose to display only the largest units like weeks 
             and days, or you can show detailed units such as hours, minutes, and seconds.`
  },
  {
    question: '6. How accurate is the conversion?',
    answer: `The conversion is highly accurate, as it breaks down milliseconds into the appropriate time units 
             (weeks, days, hours, minutes, seconds). However, depending on the options chosen (like rounding), 
             some small discrepancies might occur in the smallest unit.`
  },
  {
    question: '7. What happens if I enter a very large number?',
    answer: `If you enter a very large number (for example, representing years), the converter will accurately 
             break it down into weeks, days, hours, and minutes. For example, entering 1 billion milliseconds 
             will output "11 days, 13 hours, 46 minutes".`
  },
  {
    question: '8. What if I enter a negative number?',
    answer: `The tool currently supports only positive numbers, representing time passed. Entering a negative 
             number will result in an error, as negative time isn't supported in this context.`
  },
  {
    question: '9. Can I copy the output to the clipboard?',
    answer: `Yes, after the conversion is complete, you can click the "Copy to Clipboard" button to copy the 
             human-readable output to your clipboard. This allows you to easily paste the result into another 
             application.`
  },
  {
    question: '10. Can I use this tool for very small time values (like milliseconds)?',
    answer: `Yes, the converter can handle very small time values. It will automatically adjust the output to 
             show the appropriate time units. For instance, entering 500 milliseconds will result in "0.5 seconds".`
  },
  {
    question: '11. What do the largest and round options do?',
    answer: `The 'largest' option limits the number of time units displayed. For example, if you select 
             'largest: 2', the converter will display only the two largest time units, such as "2 weeks, 
             3 days". The 'round' option rounds the smallest unit to avoid decimals. For instance, 
             1.75 hours can be rounded to "2 hours".`
  },
  {
    question: '12. How do I know if my input is valid?',
    answer: `To ensure your input is valid, enter a positive number representing milliseconds. If you input 
             a negative number or a non-numeric value, the converter will display an error message.`
  },
  {
    question: '13. Can I see the output in multiple languages simultaneously?',
    answer: `Currently, the tool allows you to select one language at a time from the dropdown. The output 
             will update in the selected language. If you want to see the result in a different language, 
             you can switch the language and run the conversion again.`
  },
  {
    question: '14. What is the largest time unit supported?',
    answer: `The converter supports multiple time units, from milliseconds up to years. For example, if 
             you enter a very large number, it will output years, months, weeks, days, hours, minutes, 
             and seconds.`
  },
  {
    question: '15. Can I use this tool offline?',
    answer: `This tool is a web-based converter, and requires an internet connection to function. If you're 
             looking for an offline solution, you could implement the humanize-duration library in a 
             local environment using Node.js or other JavaScript environments.`
  }
]

  };
},
  methods: {
    convertMilliseconds() {
      this.error = '';
      this.humanReadable = '';
      try {
        const ms = parseInt(this.milliseconds);
        if (isNaN(ms) || ms < 0) {
          this.error = 'Please enter a valid positive number of milliseconds.';
        } else {
          // Convert milliseconds to a human-readable format based on the selected language
          this.humanReadable = humanizeDuration(ms, {
            language: this.selectedLanguage,  // Use selected language
            units: ['y', 'mo', 'w', 'd', 'h', 'm', 's'], // Weeks, days, hours, minutes, seconds
            round: true
          });
        }
      } catch (err) {
        this.error = 'An error occurred during conversion.';
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.humanReadable).then(() => {
        this.clipboardIcon = 'bi bi-clipboard-check';
        this.clipboardText = 'Copied!';
        setTimeout(() => {
          this.clipboardIcon = 'bi bi-clipboard';
          this.clipboardText = 'Copy to Clipboard';
        }, 2000);
      });
    }
  }
};
</script>

<style>
  .custom-container {
    max-width: 800px;
    margin: auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .custom-container {
      padding: 0 0.5rem;
    }
  }
</style>
