<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on HTML to Markdown Converter</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the HTML to Markdown Converter?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to Markdown Converter is a tool designed to transform your HTML code into Markdown format. It simplifies the process of creating Markdown from HTML, making it easier to write and format content for various platforms that support Markdown.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>Key Features</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <strong>HTML Beautification:</strong> Automatically format your HTML code for better readability.<br>
                                    <strong>Markdown Conversion:</strong> Convert HTML code into Markdown effortlessly.<br>
                                    <strong>Live Preview:</strong> View the converted Markdown in real-time.<br>
                                    <strong>Copy to Clipboard:</strong> Easily copy the converted Markdown to your clipboard.<br>
                                    <strong>Error Handling:</strong> Get alerts for any errors during conversion.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>How to Use the HTML to Markdown Converter?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Using the HTML to Markdown Converter is simple:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>Enter HTML:</strong> Paste your HTML code into the 'Input (HTML)' box.</li>
                                    <li><strong>Beautify HTML:</strong> Optionally, check the 'Beautify HTML' box to format your HTML.</li>
                                    <li><strong>Convert:</strong> Click the 'Convert to Markdown' button to generate the Markdown output.</li>
                                    <li><strong>View Output:</strong> See the converted Markdown in the 'Output (Markdown)' box.</li>
                                    <li><strong>Live Preview:</strong> Check the 'Live Preview' box to see the Markdown rendered as HTML.</li>
                                    <li><strong>Copy Markdown:</strong> Click 'Copy to Clipboard' to copy the Markdown code.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>Tips for Optimal Usage</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        <li>Ensure your HTML is well-formed for best results.</li>
                                        <li>Use the 'Beautify HTML' option to make your HTML more readable before conversion.</li>
                                        <li>Check the live preview to ensure the Markdown looks as expected.</li>
                                        <li>Copy the output Markdown regularly to avoid losing any data.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>Troubleshooting</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    If you encounter issues, try the following:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Ensure you have entered valid HTML in the input box.</li>
                                    <li>If the conversion fails, check your HTML for errors or unsupported elements.</li>
                                    <li>Refresh the page if the tool becomes unresponsive.</li>
                                    <li>If copying fails, manually select and copy the Markdown from the output box.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>What if I encounter a problem with the tool?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    If you encounter any issues or have questions about the HTML to Markdown Converter, please contact our support team. We're here to help you have a smooth and enjoyable experience!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HtmlToMarkdownFAQ',
};
</script>

<style scoped>

</style>
