<template>
  <BreadCrumb PageTitle="Md6 Hash Generator Calculator" />

	<div class="custom-container mt-5">
	  <h1 class="text-center">MD6 Hash Generator</h1>
	  <p class="fs-6 text-center mb-4">Quickly generate MD6 hashes for your strings using our efficient and user-friendly tool.</p>
	  <div class="mb-3">
		<label for="inputTextString" class="form-label">Enter text string:</label>
		<textarea v-model="inputText" id="inputTextString" class="form-control"></textarea>
	  </div>
	  <div class="mb-3">
		<label for="hashLength" class="form-label">Select hash length:</label>
		<select v-model="hashLength" id="hashLength" class="form-select">
		  <option value="128">MD6-128</option>
		  <option value="256">MD6-256</option>
		  <option value="512">MD6-512</option>
		</select>
	  </div>
	  <div class="row mb-3">
		<div class="col-md-6 mb-2">
		  <button @click="generateHash" class="btn btn-primary w-100">Generate Hash</button>
		</div>
		<div class="col-md-6 mb-2">
		  <button @click="clearText" class="btn btn-danger w-100">Clear Text</button>
		</div>
	  </div>
	  <div class="mb-3">
		<label for="generatedHash" class="form-label">{{ generatedHashLabel }}</label>
		<textarea v-model="generatedHash" id="generatedHash" class="form-control" rows="2" readonly></textarea>
	  </div>
	  <div class="mb-3">
		<button @click="copyHash" class="btn btn-secondary w-100">{{ copyButtonText }}</button>
	  </div>
	  <div class="mb-3">
		<label class="form-label">Hash Size: {{ hashSize }} bytes</label>
	  </div>
  
	  <!-- FAQ Section -->
	  <div class="mt-5 mb-50">
		<h2 class="text-center mb-4">Frequently Asked Questions</h2>
		<div class="accordion" id="faqAccordion">
		  <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
			<h2 class="accordion-header" :id="'heading' + index">
			  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
				{{ faq.question }}
			  </button>
			</h2>
			<div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
			  <div class="accordion-body" v-html="faq.answer"></div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </template>
  
  <script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

  import { ref, computed } from 'vue';
  import md6 from 'md6-hash';
  
  export default {
	
  components: { BreadCrumb },
name: 'MD6HashGenerator',
	setup() {
	  const inputText = ref('');
	  const generatedHash = ref('');
	  const hashLength = ref('128');
	  const copyButtonText = ref('Copy Hash');
  
	  const generateHash = () => {
		const length = parseInt(hashLength.value, 10);
		const hashBytes = md6(inputText.value, { size: length }).toUpperCase();
		generatedHash.value = hashBytes;
	  };
  
	  const clearText = () => {
		inputText.value = '';
		generatedHash.value = '';
	  };
  
	  const copyHash = () => {
		navigator.clipboard.writeText(generatedHash.value)
		  .then(() => {
			copyButtonText.value = 'Hash Copied';
			setTimeout(() => {
			  copyButtonText.value = 'Copy Hash';
			}, 2000);
		  })
		  .catch(err => console.error('Failed to copy: ', err));
	  };
  
	  const hashSize = computed(() => {
		return generatedHash.value.length / 2;
	  });
  
	  const generatedHashLabel = computed(() => {
		return `Generated Hash (MD6-${hashLength.value}):`;
	  });
  
	  const faqs = [
		{
		  question: "What is an MD6 hash?",
		  answer: `MD6 (Message Digest Algorithm 6) is a cryptographic hash function designed by Ronald Rivest and his team in 2008. It produces variable-length hash values and is known for its flexibility, high security, and unique features.<br><br>
		  Key characteristics of MD6:
		  <ul>
			<li>Variable output size: Can produce hash values from 1 to 512 bits, making it adaptable to various security requirements.</li>
			<li>Tree-based parallel structure: Allows for efficient computation on multi-core processors.</li>
			<li>Provable security: Designed with formal security proofs against various types of cryptographic attacks.</li>
			<li>Tunable parameters: Offers control over the number of rounds and other internal parameters, allowing users to balance security and performance.</li>
			<li>Built-in randomized hashing: Incorporates a unique feature to defend against certain types of attacks.</li>
		  </ul>
		  MD6 was submitted as a candidate for the SHA-3 competition but was later withdrawn due to performance issues on some platforms. Despite this, it remains an interesting and secure hash function for various applications.`
		},
		{
		  question: "How is the MD6 hash generated?",
		  answer: `The MD6 algorithm generates a hash through a series of complex steps:
		  <ol>
			<li>Padding the message: The input is padded to ensure its length is a multiple of the block size.</li>
			<li>Initializing the state: A unique initial state is set up based on the desired hash size and other parameters.</li>
			<li>Creating the hash tree: 
			<ul>
			  <li>The message is divided into blocks.</li>
			  <li>These blocks form the leaves of a tree structure.</li>
			  <li>The tree is processed from bottom to top, with each parent node computed from its child nodes.</li>
			</ul>
			</li>
			<li>Applying the compression function: Each node in the tree uses a complex compression function that involves:
			<ul>
			  <li>A series of bitwise operations (XOR, AND, rotations)</li>
			  <li>Multiple rounds of transformations (default is 104 rounds for 256-bit output)</li>
			  <li>Use of constants derived from the fractional part of π for added randomness</li>
			</ul>
			</li>
			<li>Finalizing the hash: The root of the tree becomes the final hash value, which is then truncated or padded to the desired output size.</li>
		  </ol>
		  This process ensures that MD6 provides strong diffusion and confusion properties, making it resistant to various cryptographic attacks.`
		},
		{
		  question: "Can you provide an example of MD6 hash generation?",
		  answer: `Certainly! Let's look at MD6 hash generation for different input sizes and hash lengths:
		  <ol>
			<li>Short input:
			<ul>
			  <li>Input: "Hello, World!"</li>
			  <li>MD6-256: <code>1592E92266FC78C6E3F3ED65802255C632863A9EC4A9205D0C01633A1A274F22</code></li>
			</ul>
			</li>
			<li>Longer input:
			<ul>
			  <li>Input: "The quick brown fox jumps over the lazy dog"</li>
			  <li>MD6-256: <code>2D8C2F6D978CA21712B2C6C7E3F4E7F1C3A3F7CCD9E9E8AE3E3EA4D0F9E8E7E6</code></li>
			</ul>
			</li>
			<li>Same input, different hash sizes:
			<ul>
			  <li>Input: "MD6 Hash Example"</li>
			  <li>MD6-128: <code>8B3EB3E28F75C12B3E61C3C8F7C53F21</code></li>
			  <li>MD6-256: <code>B3AE739AD7A22C59C36D4CE8A63FADDF82283815893FE1E1E9B59AB1E13F2A6E</code></li>
			  <li>MD6-512: <code>6C3E93F2F8504549BA36C9B8D475E0407F83C80C4B36D26F5AFEA582D8B67E3F6677E85905C2D169B0EFEF4C4A4C5F3E3AC57E7F2D09AB640A810F09A8357C07</code></li>
			</ul>
			</li>
		  </ol>
		  Note how even a small change in input or hash size results in a completely different hash. This demonstrates the avalanche effect, a desirable property in cryptographic hash functions.`
		},
		{
		  question: "Is this tool secure?",
		  answer: `This tool generates the MD6 hash entirely in your browser, which provides several security benefits:
		  <ul>
			<li>No server-side processing: Your input is not sent to any server, reducing the risk of data interception.</li>
			<li>No data storage: The tool doesn't store your input or the generated hashes.</li>
			<li>Open-source implementation: The MD6 algorithm used is open to scrutiny, enhancing trust.</li>
		  </ul>
		  However, please keep in mind:
		  <ul>
			<li>Browser security: Ensure your browser is up-to-date and you're using a secure connection (HTTPS).</li>
			<li>Local environment: Be cautious when using this tool on public or shared computers. Clear your browser history and cache after use on such devices.</li>
			<li>Input sensitivity: Avoid entering sensitive data (like passwords) into any online tool, including this one.</li>
			<li>Cryptographic purpose: While MD6 is considered secure, always use the most appropriate and up-to-date hash function for your specific cryptographic needs. Consult with a security expert if you're unsure.</li>
		  </ul>
		  Remember, the security of a hash function doesn't make the overall system secure. Always implement proper security measures in your applications.`
		},
		{
		  question: "Why would I need to generate an MD6 hash?",
		  answer: `MD6 hashes can be useful in various scenarios:
		  <ul>
			<li>Data Integrity Verification: Ensure that data hasn't been tampered with during storage or transmission.</li>
			<li>Digital Signatures: As part of digital signature schemes to verify the authenticity of messages or documents.</li>
			<li>Password Storage: Store hashed passwords instead of plaintext (though specialized password hashing functions like bcrypt or Argon2 are generally preferred).</li>
			<li>Blockchain and Cryptocurrency: In the creation of unique identifiers for blocks or transactions.</li>
			<li>File or Data Deduplication: Generate unique identifiers for files or data chunks to identify duplicates.</li>
			<li>Cryptographic Research: Study and compare different hash functions and their properties.</li>
			<li>Random Number Generation: As part of seeding or generating pseudo-random numbers.</li>
			<li>Data Structures: In hash tables or other data structures that require fast, unique key generation.</li>
			<li>Digital Forensics: Create and verify file fingerprints in forensic investigations.</li>
			<li>Version Control Systems: Generate unique identifiers for code commits or file versions.</li>
		  </ul>
		  While MD6 offers robust security and flexibility, always ensure you're using the most appropriate hash function for your specific use case and security requirements.`
		},
		{
		  question: "Can I reverse an MD6 hash to get the original input?",
		  answer: `No, MD6 hashes, like all cryptographic hash functions, are designed to be one-way functions. This means:
		  <ul>
			<li>Irreversibility: It's computationally infeasible to reverse the hash to obtain the original input.</li>
			<li>Pre-image resistance: Given a hash value, it's extremely difficult to find any input that produces that hash.</li>
			<li>Second pre-image resistance: Given an input and its hash, it's extremely difficult to find another input that produces the same hash.</li>
			<li>Collision resistance: It's extremely difficult to find any two different inputs that produce the same hash.</li>
		  </ul>
		  These properties make MD6 suitable for security-critical applications. However, it's important to note:
		  <ul>
			<li>Brute-force attacks: For very short or predictable inputs, it might be possible to guess the input by trying many possibilities.</li>
			<li>Rainbow tables: Pre-computed tables of hashes can be used to reverse hashes of common inputs, which is why salt is used in password hashing.</li>
			<li>Quantum computing: Future quantum computers might be able to break current cryptographic hash functions, including MD6.</li>
		  </ul>
		  Always use additional security measures (like salting for passwords) and keep your systems updated to protect against evolving threats.`
		},
		{
		  question: "How does MD6 compare to other hash functions?",
		  answer: `MD6 offers several unique features compared to other hash functions:
		  <ul>
			<li>Flexibility: Variable output sizes from 1 to 512 bits, unlike fixed sizes in SHA-256 or SHA-3.</li>
			<li>Parallelism: Tree-based structure allows efficient parallel computation, beneficial for multi-core processors.</li>
			<li>Provable security: Designed with formal security proofs, providing stronger theoretical security guarantees.</li>
			<li>Customization: Allows users to adjust parameters like the number of rounds for different security/performance trade-offs.</li>
		  </ul>
		  Comparison with other common hash functions:
		  <ul>
			<li>SHA-256: 
			<ul>
			  <li>Widely used and supported</li>
			  <li>Fixed 256-bit output</li>
			  <li>Generally faster than MD6 on standard hardware</li>
			</ul>
			</li>
			<li>SHA-3 (Keccak): 
			<ul>
			  <li>Newest SHA standard with variable output sizes</li>
			  <li>Different internal structure (sponge construction)</li>
			  <li>Generally offers better performance than MD6</li>
			</ul>
			</li>
			<li>BLAKE2: 
			<ul>
			  <li>Designed for high speed on modern processors</li>
			  <li>Offers both serial and parallel versions</li>
			  <li>Generally faster than MD6 and SHA-3</li>
			</ul>
			</li>
		  </ul>
		  While MD6 offers strong security and unique features, it's not as widely adopted as SHA-256 or SHA-3. The choice of hash function depends on your specific requirements for security, performance, and compatibility with existing systems.`
		}
	  ];
  
	  return {
		inputText,
		generatedHash,
		hashLength,
		generateHash,
		clearText,
		copyHash,
		copyButtonText,
		hashSize,
		generatedHashLabel,
		faqs
	  };
	}
  }
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  textarea.form-control {
	font-size: 16px;
  }
  
  /* Dark mode styles */
  @media (prefers-color-scheme: dark) {
	.accordion-button {
	  background-color: #343a40;
	  color: #fff;
	}
  
	.accordion-button:not(.collapsed) {
	  background-color: #2b3035;
	  color: #fff;
	}
  
	.accordion-button::after {
	  filter: invert(1);
	}
  
	.accordion-body {
	  background-color: #343a40;
	  color: #fff;
	}
  
	/* Style for code snippets in dark mode */
	.accordion-body code {
	  background-color: #2b3035;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  
  /* Light mode styles */
  @media (prefers-color-scheme: light) {
	.accordion-body {
	  background-color: #fff;
	  color: #212529;
	}
  
	/* Style for code snippets in light mode */
	.accordion-body code {
	  background-color: #f8f9fa;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  </style>
  