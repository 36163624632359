<template>
  <BreadCrumb PageTitle="Percentage Change Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Percentage Change Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the percentage change between two values. Enter the initial and final values to find the percentage increase or decrease along with the calculation steps.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Initial Value -->
        <div class="mb-3">
          <label for="initialValue" class="form-label">Initial Value:</label>
          <input
            type="text"
            id="initialValue"
            v-model="initialValue"
            class="form-control"
            placeholder="e.g., 50, 200"
          />
        </div>

        <!-- Input for Final Value -->
        <div class="mb-3">
          <label for="finalValue" class="form-label">Final Value:</label>
          <input
            type="text"
            id="finalValue"
            v-model="finalValue"
            class="form-control"
            placeholder="e.g., 75, 150"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculatePercentageChange" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Initial Value: {{ result.initialValue }}</p>
          <p class="fs-5">Final Value: {{ result.finalValue }}</p>
          <p class="fs-5">Change: {{ result.changeType }} by {{ result.percentageChange }}%</p>
          <p class="fs-5">Calculation Steps: {{ result.calculation }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the initial value in the first input field (e.g., 50, 200).</li>
        <li>Enter the final value in the second input field (e.g., 75, 150).</li>
        <li>Click "Calculate" to find the percentage change between the initial and final values.</li>
        <li>The result will show the initial and final values, the change type (increase or decrease), the percentage change, and the calculation steps.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Formula for Percentage Change:</h5>
      <div class="fs-6">
        The percentage change between two values is calculated using the formula:
      </div>
      <div>
        \[
        \text{Percentage Change} = \frac{\text{Final Value} - \text{Initial Value}}{\text{Initial Value}} \times 100
        \]
      </div>
      <p class="fs-6">
        This formula calculates the relative change between two values as a percentage.
      </p>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For an initial value of 50 and a final value of 75:<br />
          Calculation: \( \frac{75 - 50}{50} \times 100 = 50\% \)<br />
          **Change**: Increase by 50%
        </li>
        <li>
          For an initial value of 200 and a final value of 150:<br />
          Calculation: \( \frac{150 - 200}{200} \times 100 = -25\% \)<br />
          **Change**: Decrease by 25%
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const initialValue = ref("");
    const finalValue = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate percentage change
    const calculatePercentageChange = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the input values
        const initial = parseFloat(initialValue.value.trim());
        const final = parseFloat(finalValue.value.trim());

        if (isNaN(initial) || isNaN(final) || initial === 0) {
          throw new Error("Please enter valid numeric values and ensure the initial value is not zero.");
        }

        // Calculate the percentage change
        const percentageChange = ((final - initial) / initial) * 100;
        const changeType = percentageChange > 0 ? "Increase" : "Decrease";
        const calculation = `((${final} - ${initial}) / ${initial}) * 100 = ${percentageChange.toFixed(2)}%`;

        result.value = {
          initialValue: initial,
          finalValue: final,
          percentageChange: percentageChange.toFixed(2),
          changeType: changeType,
          calculation: calculation,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      initialValue.value = "";
      finalValue.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      initialValue,
      finalValue,
      result,
      error,
      calculatePercentageChange,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
