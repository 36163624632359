<template>
    <BreadCrumb PageTitle="HTML To JSON Converter" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">HTML To JSON Converter</h1>
      <p class="fs-6 text-center mb-4">Convert your HTML table or list into a JSON format instantly. Use beautify HTML, live preview, and copy the JSON output effortlessly.</p>
      <div class="row mb-4">
        <div class="col-md-6 mb-4 mb-md-0">
          <div class="markdown mb-2">
            <label for="inputHTML">Input (HTML):</label>
            <div class="ms-3 form-check">
              <input type="checkbox" class="form-check-input" id="beautifyHTMLCheckbox" v-model="beautifyHTML" @change="handleInput">
              <label for="beautifyHTMLCheckbox" class="form-check-label">Beautify HTML</label>
            </div>
          </div>
          <textarea id="inputHTML" class="form-control" rows="25" v-model="inputHTML" @input="handleInput"></textarea>
        </div>
        <div class="col-md-6">
          <div class="markdown mb-2 d-flex justify-content-between align-items-center">
            <label>{{ livePreview ? 'Live Preview' : 'Output (JSON):' }}</label>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="livePreviewCheckbox" v-model="livePreview">
              <label class="form-check-label" for="livePreviewCheckbox">Live Preview</label>
            </div>
          </div>
          <div class="position-relative output-wrapper">
            <textarea v-if="!livePreview" id="outputJSON" class="form-control" rows="25" readonly :value="outputJSON"></textarea>
            <pre v-else id="jsonPreview" class="form-control json-preview">{{ outputJSON }}</pre>
            <i 
              :class="['bi', copied ? 'bi-clipboard-check' : 'bi-clipboard', 'copy-icon']" 
              @click="copyToClipboard"
              :title="copied ? 'Copied!' : 'Copy to clipboard'"
            ></i>
          </div>
        </div>
      </div>
  
      <div class="alert alert-danger mt-3" v-if="error">Error converting HTML to JSON!</div>
      <div>
        <HTMLToJSONFAQ />
      </div>
    </div>
  </template>
  
  <script>
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import HTMLToJSONFAQ from '../FAQs/htmlToJSONFAQ.vue';
  import { html as beautifyHTML } from 'js-beautify';
  
  export default {
    components: {
      BreadCrumb,
      HTMLToJSONFAQ
    },
    data() {
      return {
        inputHTML: '',
        beautifyHTML: false,
        livePreview: false,
        outputJSON: '',
        error: false,
        copied: false,
      };
    },
    methods: {
      handleInput() {
        if (this.beautifyHTML) {
          this.inputHTML = beautifyHTML(this.inputHTML, { indent_size: 2, space_in_empty_paren: true });
        }
        this.convertToJSON();
      },
      convertToJSON() {
        try {
          const parser = new DOMParser();
          const doc = parser.parseFromString(this.inputHTML, 'text/html');
          const table = doc.querySelector('table');
          const ul = doc.querySelector('ul');
          const ol = doc.querySelector('ol');
          
          if (table) {
            this.outputJSON = JSON.stringify(this.htmlTableToJSON(table), null, 2); // Beautified JSON
          } else if (ul || ol) {
            this.outputJSON = JSON.stringify(this.htmlListToJSON(ul || ol), null, 2); // Beautified JSON
          } else {
            this.outputJSON = '{}'; // Default empty object if no table or list is found
          }
          this.error = false;
        } catch (e) {
          this.outputJSON = '{}'; // Handle any errors by showing empty JSON object
          this.error = false;
        }
      },
      htmlTableToJSON(table) {
        let data = [];
        const headers = Array.from(table.querySelectorAll('th')).map(th => th.innerText.trim());
        const rows = table.querySelectorAll('tr');
  
        rows.forEach((row, rowIndex) => {
          if (rowIndex === 0 && headers.length > 0) return; // Skip header row if headers exist
          let rowData = {};
          const cols = Array.from(row.querySelectorAll('td'));
          cols.forEach((col, colIndex) => {
            let header = headers[colIndex] || `Column ${colIndex + 1}`; // Use headers if available
            rowData[header] = col.innerText.trim();
          });
          if (Object.keys(rowData).length > 0) data.push(rowData);
        });
  
        return data;
      },
      htmlListToJSON(list) {
        let data = [];
        const items = list.querySelectorAll('li');
        items.forEach(item => {
          data.push(item.innerText.trim());
        });
        return data;
      },
      copyToClipboard() {
        navigator.clipboard.writeText(this.outputJSON).then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .markdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .custom-container {
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .custom-container {
      padding: 0 0.5rem;
    }
  }
  label, .form-control {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    .row {
      flex-direction: column;
    }
  }
  .output-wrapper {
    position: relative;
  }
  .copy-icon {
    position: absolute;
    bottom: 10px;
    right: 25px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #6c757d;
    z-index: 1;
  }
  .copy-icon:hover {
    color: #495057;
  }
  .json-preview {
    height: calc(18 * 1.5em + 0.20rem);
    overflow-y: auto;
    padding-right: 40px;
  }
  textarea {
    resize: vertical;
    padding-right: 40px;
  }
  </style>
  