<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
    <div class="card-body p-15 p-sm-20">
        <div class="accordion" id="faqAccordion">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-3">
                        <h3>FAQs on Christmas Charades Generator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the Christmas Charades Generator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Christmas Charades Generator is an online tool designed to create fun and festive charades prompts for your holiday gatherings. It allows you to select from various categories such as Classic, Movies, Songs, Foods, and more to generate random charades words or phrases.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How does the Christmas Charades Generator work?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Using the Christmas Charades Generator is simple:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Select a category from the available options.</li>
                                    <li>Click on the "Generate" button to get a random charade word or phrase.</li>
                                    <li>Act out the charade without using words while others guess what it is.</li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    It's a great way to add some festive fun to your holiday celebrations!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What categories are available in the Christmas Charades Generator?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Christmas Charades Generator offers a variety of categories to choose from:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Classic</li>
                                    <li>Movies</li>
                                    <li>Songs</li>
                                    <li>Foods</li>
                                    <li>Holiday Traditions</li>
                                    <li>Decorations</li>
                                    <li>Winter Activities</li>
                                    <li>Gifts</li>
                                    <li>Characters</li>
                                    <li>Musical Instruments</li>
                                    <li>Animals</li>
                                    <li>Sports</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>Can I add my own charades to the generator?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Currently, the Christmas Charades Generator uses pre-defined lists of charades words and phrases. However, we are working on a feature that will allow users to add their own custom charades for a more personalized experience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>How can I make the game more challenging?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To make the game more challenging, you can:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Set a timer for each round to increase the urgency.</li>
                                    <li>Limit the number of gestures or movements allowed.</li>
                                    <li>Use more difficult categories or words.</li>
                                    <li>Have players act out charades in teams to add a competitive element.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Can children play Christmas Charades?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Absolutely! Christmas Charades is a family-friendly game that children can enjoy. Ensure that the categories and words chosen are appropriate for their age group, and consider simplifying the prompts for younger players.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>Is there a limit to the number of players?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    There is no strict limit to the number of players for Christmas Charades. Whether you have a small family gathering or a large holiday party, the game can be easily adapted to accommodate any group size. For larger groups, consider forming teams to keep everyone engaged.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEight" aria-expanded="true" aria-controls="faqCollapseEight">
                            <h5>How do I ensure fair play during the game?</h5>
                        </button>
                        <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To ensure fair play during Christmas Charades, follow these tips:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Clearly explain the rules before starting the game.</li>
                                    <li>Ensure everyone understands the charades prompts.</li>
                                    <li>Rotate the acting players regularly so everyone gets a turn.</li>
                                    <li>Keep the game light-hearted and fun, emphasizing participation over competition.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseNine" aria-expanded="true" aria-controls="faqCollapseNine">
                            <h5>Can I use the generator offline?</h5>
                        </button>
                        <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Christmas Charades Generator requires an internet connection to function properly. However, you can generate a list of charades prompts while online and save or print them for offline use during your holiday gathering.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTen" aria-expanded="true" aria-controls="faqCollapseTen">
                            <h5>What if I encounter a problem with the generator?</h5>
                        </button>
                        <div id="faqCollapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    If you encounter any issues or have questions about the Christmas Charades Generator, please contact our support team. We're here to help you have a smooth and enjoyable experience!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEleven" aria-expanded="true" aria-controls="faqCollapseEleven">
                            <h5>How can I make the game more fun?</h5>
                        </button>
                        <div id="faqCollapseEleven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    To make Christmas Charades even more fun:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Encourage players to use props and costumes.</li>
                                    <li>Play festive background music to set the mood.</li>
                                    <li>Offer small prizes or rewards for correct guesses.</li>
                                    <li>Mix up the categories to keep the game interesting.</li>
                                    <li>Have a themed charades night with holiday snacks and drinks.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  </template>
  
  <script>
  export default {
    name: 'ChristmasCharadesGeneratorFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  