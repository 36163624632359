<template>
	<BreadCrumb PageTitle="Aspect Ratio Calculator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Aspect Ratio Calculator</h1>
	  <p class="fs-6 text-center mb-4">Calculate the aspect ratio of an image or use the presets.</p>
	  <div class="row mt-5">
		<div class="col-lg-12 mb-4">
		  <div class="row">
			<div class="col-lg-6 mb-3">
			  <div class="input-group">
				<span class="input-group-text" for="width">Width</span>
				<input
				  id="width"
				  v-model="width"
				  class="form-control"
				  type="number"
				  placeholder="width"
				  autocomplete="off"
				  min="0"
				  max="9999999"
				  @input="calculateAspectRatio"
				  required
				/>
			  </div>
			</div>
			<div class="col-lg-6 mb-3">
			  <div class="input-group">
				<span class="input-group-text" for="height">Height</span>
				<input
				  id="height"
				  v-model="height"
				  class="form-control"
				  type="number"
				  placeholder="height"
				  autocomplete="off"
				  min="0"
				  max="9999999"
				  @input="calculateAspectRatio"
				  required
				/>
			  </div>
			</div>
		  </div>
		  <div class="input-group mb-3">
			<span class="input-group-text" for="preset">Preset Aspect Ratios:</span>
			<select
			  id="preset"
			  v-model="selectedPreset"
			  class="form-select"
			  @change="applyPreset"
			>
			  <option value="">Select Preset</option>
			  <option v-for="preset in presets" :key="preset.name" :value="preset.value">
				{{ preset.name }}
			  </option>
			</select>
		  </div>
		  <div v-if="showError" class="alert alert-danger">
			Negative values are not allowed for width and height.
		  </div>
		  <button @click="resetFields" class="btn btn-primary btn-hover w-100" data-bs-toggle="tooltip" title="Clear all">
			<i class="bi bi-arrow-repeat"></i> Clear
		  </button>
		  <div class="row mt-4">
			<div class="col-md-4">
			  <p>Aspect Ratio (Decimals)</p>
			  <input
				v-model="aspectRatioDecimals"
				class="form-control"
				type="text"
				readonly
				placeholder="1:1.0"
				style="text-align: center;"
			  />
			</div>
			<div class="col-md-4">
			  <p>Aspect Ratio (Fraction)</p>
			  <input
				v-model="aspectRatioFraction"
				class="form-control"
				type="text"
				readonly
				placeholder="1 / 1"
				style="text-align: center;"
			  />
			</div>
			<div class="col-md-4">
			  <p>Pixel Count (Pixels)</p>
			  <input
				v-model="pixelCount"
				class="form-control"
				type="text"
				readonly
				placeholder="0,000,000"
				style="text-align: center;"
			  />
			</div>
		  </div>
		  <h4 class="text-center mt-4">Outline</h4>
		  <p class="text-center mb-4 fs-6">
			The drawing below represents the calculated aspect ratio. The dimensions of the box are scaled to fit within this container while maintaining the original aspect ratio.
		  </p>
		  <div class="card">
			<div class="card-body">
			  <div class="aspect-ratio-container">
				<div ref="aspectRatioDrawing" class="aspect-ratio-drawing"></div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	  <div>
		<AspectRatioCalculatorFAQ />
	  </div>
	</div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AspectRatioCalculatorFAQ from '../FAQs/aspectRatioCalculatorFAQ.vue';

export default {
	name: 'AspectRatioCalculator',
	components: {
	  BreadCrumb,
	  AspectRatioCalculatorFAQ
	},
	setup() {
	  const width = ref('');
	  const height = ref('');
	  const selectedPreset = ref('');
	  const aspectRatioDecimals = ref('');
	  const aspectRatioFraction = ref('');
	  const pixelCount = ref('');
	  const showError = ref(false);
	  const aspectRatioDrawing = ref(null);

	  const presets = [
		{ name: '1:1 (Square)', value: '1:1' },
		{ name: '4:3 (Standard)', value: '4:3' },
		{ name: '16:9 (Widescreen)', value: '16:9' },
		{ name: '21:9 (Cinemascope)', value: '21:9' },
		{ name: '3840:2160 (4K UHD)', value: '3840:2160' },
		{ name: '2048:1080 (IMAX 2K)', value: '2048:1080' },
		{ name: '4096:2160 (IMAX 4K)', value: '4096:2160' },
		{ name: '1600:900 (HD+)', value: '1600:900' },
		{ name: '3:2 (iPhone)', value: '3:2' },
		{ name: '4:3 (Micro Four Thirds)', value: '4:3' },
		{ name: '3:2 (35mm Film)', value: '3:2' },
		{ name: '5:4 (SXGA)', value: '5:4' },
		{ name: '16:10 (WXGA+ / WSXGA+)', value: '16:10' },
		{ name: '9:16 (Vertical Video)', value: '9:16' },
		{ name: '820:312 (Facebook Cover)', value: '820:312' },
		{ name: '1500:500 (Twitter Header)', value: '1500:500' },
		{ name: '9:16 (Instagram Story)', value: '9:16' },
		{ name: '1050:600 (Business Card)', value: '1050:600' },
		{ name: '640:480 (VGA)', value: '640:480' },
		{ name: '720:576 (PAL)', value: '720:576' },
		{ name: '576:486 (NTSC)', value: '576:486' },
		{ name: '320:480 (HVGA)', value: '320:480' },
	  ];

	  const calculateAspectRatio = () => {
		const w = parseFloat(width.value);
		const h = parseFloat(height.value);

		showError.value = w < 0 || h < 0;

		if (showError.value || isNaN(w) || isNaN(h) || w === 0 || h === 0) {
		  aspectRatioDecimals.value = '';
		  aspectRatioFraction.value = '';
		  pixelCount.value = '';
		  return;
		}

		const ratio = w / h;
		aspectRatioDecimals.value = ratio.toFixed(2);
		aspectRatioFraction.value = simplifyAspectRatio(w, h);
		pixelCount.value = (w * h).toLocaleString('en-US');

		drawAspectRatio(w, h);
	  };

	  const simplifyAspectRatio = (width, height) => {
		const gcd = (a, b) => b ? gcd(b, a % b) : a;
		const divisor = gcd(width, height);
		return `${width / divisor} / ${height / divisor}`;
	  };

	  const drawAspectRatio = (w, h, opacity = 0.8) => {
		if (!aspectRatioDrawing.value) return;

		const container = aspectRatioDrawing.value;
		container.innerHTML = '';
		const containerHeight = container.clientHeight;
		const containerWidth = container.clientWidth;

		let drawingHeight = containerHeight - 20;
		let drawingWidth = (w / h) * drawingHeight;

		if (drawingWidth > containerWidth - 20) {
		  const scale = (containerWidth - 20) / drawingWidth;
		  drawingWidth = containerWidth - 20;
		  drawingHeight *= scale;
		}

		const box = document.createElement('div');
		box.className = 'aspect-ratio-box';
		box.style.cssText = `
		  width: ${drawingWidth}px;
		  height: ${drawingHeight}px;
		  border: 1px dashed #6560f0;
		  position: relative;
		  opacity: ${opacity};
		  margin: 10px auto;
		`;

		const widthLabel = document.createElement('div');
		widthLabel.innerHTML = w;
		widthLabel.style.cssText = `
		  position: absolute;
		  bottom: -20px;
		  left: 50%;
		  transform: translateX(-50%);
		  font-size: 14px;
		`;

		const heightLabel = document.createElement('div');
		heightLabel.innerHTML = h;
		heightLabel.style.cssText = `
		  position: absolute;
		  top: 50%;
		  left: -14px;
		  transform: translateY(-50%) rotate(-90deg);
		  font-size: 14px;
		`;

		box.appendChild(widthLabel);
		box.appendChild(heightLabel);
		container.appendChild(box);
	  };

	  const resetFields = () => {
		width.value = '';
		height.value = '';
		selectedPreset.value = '';
		aspectRatioDecimals.value = '';
		aspectRatioFraction.value = '';
		pixelCount.value = '';
		showError.value = false;
		drawAspectRatio(1, 1, 0.8);
	  };

	  const applyPreset = () => {
		if (selectedPreset.value) {
		  const [w, h] = selectedPreset.value.split(':').map(Number);
		  width.value = w.toString();
		  height.value = h.toString();
		  calculateAspectRatio();
		}
	  };

	  onMounted(() => {
		setTimeout(() => {
		  drawAspectRatio(16, 9, 0.8);
		}, 0);
	  });

	  watch([width, height], calculateAspectRatio);

	  return {
		width,
		height,
		selectedPreset,
		aspectRatioDecimals,
		aspectRatioFraction,
		pixelCount,
		showError,
		presets,
		aspectRatioDrawing,
		calculateAspectRatio,
		resetFields,
		applyPreset,
	  };
	},
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

label,
.form-control,
.card-body {
  font-size: 16px;
}

textarea {
  resize: none;
  overflow: hidden;
}

.aspect-ratio-container {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aspect-ratio-drawing {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
