<template>
  <BreadCrumb PageTitle="Prime Factor Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Prime Factor Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the prime factors of a positive integer. Enter the number to find its prime factors and prime factorization.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Number -->
        <div class="mb-3">
          <label for="number" class="form-label">Enter a Positive Integer:</label>
          <input
            type="number"
            id="number"
            v-model="number"
            class="form-control"
            placeholder="e.g., 60"
            min="2"
            step="1"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculatePrimeFactors" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Number: {{ result.number }}</p>
          <p class="fs-5">Prime Factors: {{ result.primeFactors.join(' × ') }}</p>
          <p class="fs-5" v-if="result.primeFactorization">
            Prime Factorization: {{ result.primeFactorization }}
          </p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter a positive integer greater than 1 in the input field.</li>
        <li>Click "Calculate" to find all the prime factors of the number.</li>
        <li>The result will display the prime factors and the prime factorization.</li>
        <li>Click "Clear" to reset the input field and result.</li>
      </ul>

      <h5 class="alert-heading">Understanding Prime Factors:</h5>
      <p class="fs-6">
        A prime factor is a factor that is a prime number. In other words, a prime factor of a number is a prime number that divides the number exactly, without leaving a remainder.
      </p>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For the number 60:
          <ul>
            <li>Prime Factors: 2, 2, 3, 5</li>
            <li>Prime Factorization: \( 2^2 \times 3 \times 5 \)</li>
          </ul>
        </li>
        <li>
          For the number 84:
          <ul>
            <li>Prime Factors: 2, 2, 3, 7</li>
            <li>Prime Factorization: \( 2^2 \times 3 \times 7 \)</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input field and result state
    const number = ref(null);
    const result = ref(null);
    const error = ref("");

    // Function to calculate prime factors
    const calculatePrimeFactors = () => {
      error.value = "";
      result.value = null;

      try {
        // Ensure value is not null or empty
        if (number.value === null || number.value === "") {
          throw new Error("Please enter a positive integer greater than 1.");
        }

        // Parse the input value
        const num = Number(number.value);

        if (!Number.isInteger(num) || num < 2) {
          throw new Error("Please enter a positive integer greater than 1.");
        }

        // Function to get prime factors
        const getPrimeFactors = (n) => {
          const factors = [];
          let divisor = 2;
          while (n >= 2) {
            if (n % divisor === 0) {
              factors.push(divisor);
              n = n / divisor;
            } else {
              divisor++;
            }
          }
          return factors;
        };

        // Get prime factors
        const factors = getPrimeFactors(num);

        // Generate prime factorization string
        const factorCounts = factors.reduce((acc, curr) => {
          acc[curr] = (acc[curr] || 0) + 1;
          return acc;
        }, {});

        const primeFactorization = Object.entries(factorCounts)
          .map(([prime, count]) =>
            count > 1 ? `${prime}^${count}` : `${prime}`
          )
          .join(" × ");

        result.value = {
          number: num,
          primeFactors: factors,
          primeFactorization: primeFactorization,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input field and result
    const clearValues = () => {
      number.value = null;
      result.value = null;
      error.value = "";
    };

    return {
      number,
      result,
      error,
      calculatePrimeFactors,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
