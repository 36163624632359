<template>
  <BreadCrumb PageTitle="Fractions Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Fractions Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two fractions and select an operation to perform calculations. The calculator supports addition, subtraction, multiplication, and division of fractions.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for First Fraction -->
        <div class="mb-3">
          <label for="fraction1" class="form-label">Fraction 1 (Numerator/Denominator):</label>
          <input
            type="text"
            id="fraction1"
            v-model="fraction1"
            class="form-control"
            placeholder="e.g., 1/2"
          />
        </div>

        <!-- Input for Second Fraction -->
        <div class="mb-3">
          <label for="fraction2" class="form-label">Fraction 2 (Numerator/Denominator):</label>
          <input
            type="text"
            id="fraction2"
            v-model="fraction2"
            class="form-control"
            placeholder="e.g., 3/4"
          />
        </div>

        <!-- Operation Selector -->
        <div class="mb-3">
          <label for="operation" class="form-label">Operation:</label>
          <select id="operation" v-model="operation" class="form-select">
            <option value="add">Addition (+)</option>
            <option value="subtract">Subtraction (-)</option>
            <option value="multiply">Multiplication (×)</option>
            <option value="divide">Division (÷)</option>
          </select>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateFractions" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Fractional Result: {{ result.fraction }}</p>
          <p class="fs-5">Decimal Result: {{ result.decimal }}</p>
          <p class="fs-5">Calculation: {{ result.calculation }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter two fractions in the format Numerator/Denominator (e.g., 1/2 and 3/4).</li>
        <li>Select the desired operation: addition, subtraction, multiplication, or division.</li>
        <li>Click "Calculate" to perform the operation on the fractions.</li>
        <li>The results will show the fractional and decimal forms of the result, along with the calculation steps.</li>
        <li>Click "Clear" to reset the input fields and results.</li>
      </ul>

      <h5 class="alert-heading">Operations on Fractions:</h5>
      <ul class="fs-6">
        <li><strong>Addition:</strong> \(\frac{a}{b} + \frac{c}{d} = \frac{a \times d + b \times c}{b \times d}\)</li>
        <li><strong>Subtraction:</strong> \(\frac{a}{b} - \frac{c}{d} = \frac{a \times d - b \times c}{b \times d}\)</li>
        <li><strong>Multiplication:</strong> \(\frac{a}{b} \times \frac{c}{d} = \frac{a \times c}{b \times d}\)</li>
        <li><strong>Division:</strong> \(\frac{a}{b} \div \frac{c}{d} = \frac{a \times d}{b \times c}\)</li>
      </ul>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For \( \frac{1}{2} + \frac{3}{4} \):<br />
          \( \frac{1 \times 4 + 2 \times 3}{2 \times 4} = \frac{10}{8} = \frac{5}{4} \) or 1.25
        </li>
        <li>
          For \( \frac{2}{3} \times \frac{3}{4} \):<br />
          \( \frac{2 \times 3}{3 \times 4} = \frac{6}{12} = \frac{1}{2} \) or 0.5
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const fraction1 = ref("");
    const fraction2 = ref("");
    const operation = ref("add");
    const result = ref(null);
    const error = ref("");

    // Function to calculate operations on fractions
    const calculateFractions = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the fractions
        const f1 = parseFraction(fraction1.value);
        const f2 = parseFraction(fraction2.value);

        if (!f1 || !f2) throw new Error("Please enter valid fractions.");

        // Perform the selected operation
        let calculationResult;
        switch (operation.value) {
          case "add":
            calculationResult = performAddition(f1, f2);
            break;
          case "subtract":
            calculationResult = performSubtraction(f1, f2);
            break;
          case "multiply":
            calculationResult = performMultiplication(f1, f2);
            break;
          case "divide":
            calculationResult = performDivision(f1, f2);
            break;
          default:
            throw new Error("Invalid operation.");
        }

        const fractionResult = simplifyFraction(calculationResult);
        const decimalResult = (fractionResult[0] / fractionResult[1]).toFixed(6);
        const calculationString = `${f1[0]}/${f1[1]} ${operationSign()} ${f2[0]}/${f2[1]} = ${fractionResult[0]}/${fractionResult[1]}`;

        result.value = {
          fraction: `${fractionResult[0]}/${fractionResult[1]}`,
          decimal: decimalResult,
          calculation: calculationString,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to parse fraction string into an array [numerator, denominator]
    const parseFraction = (fraction) => {
      const parts = fraction.trim().split("/");
      if (parts.length !== 2) return null;
      const numerator = parseInt(parts[0].trim());
      const denominator = parseInt(parts[1].trim());
      if (isNaN(numerator) || isNaN(denominator) || denominator === 0) return null;
      return [numerator, denominator];
    };

    // Function to perform addition of two fractions
    const performAddition = (f1, f2) => {
      const numerator = f1[0] * f2[1] + f1[1] * f2[0];
      const denominator = f1[1] * f2[1];
      return [numerator, denominator];
    };

    // Function to perform subtraction of two fractions
    const performSubtraction = (f1, f2) => {
      const numerator = f1[0] * f2[1] - f1[1] * f2[0];
      const denominator = f1[1] * f2[1];
      return [numerator, denominator];
    };

    // Function to perform multiplication of two fractions
    const performMultiplication = (f1, f2) => {
      const numerator = f1[0] * f2[0];
      const denominator = f1[1] * f2[1];
      return [numerator, denominator];
    };

    // Function to perform division of two fractions
    const performDivision = (f1, f2) => {
      const numerator = f1[0] * f2[1];
      const denominator = f1[1] * f2[0];
      return [numerator, denominator];
    };

    // Function to simplify a fraction
    const simplifyFraction = (fraction) => {
      const gcd = greatestCommonDivisor(fraction[0], fraction[1]);
      return [fraction[0] / gcd, fraction[1] / gcd];
    };

    // Function to find greatest common divisor (GCD) of two numbers
    const greatestCommonDivisor = (a, b) => {
      return b === 0 ? Math.abs(a) : greatestCommonDivisor(b, a % b);
    };

    // Function to return the operation sign for display
    const operationSign = () => {
      switch (operation.value) {
        case "add":
          return "+";
        case "subtract":
          return "-";
        case "multiply":
          return "×";
        case "divide":
          return "÷";
        default:
          return "";
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      fraction1.value = "";
      fraction2.value = "";
      operation.value = "add";
      result.value = null;
      error.value = "";
    };

    return {
      fraction1,
      fraction2,
      operation,
      result,
      error,
      calculateFractions,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
