<template>
  <BreadCrumb PageTitle="Logarithm Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Logarithm Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the logarithm of a number to any base. Enter the number and base to compute the logarithm.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Number -->
        <div class="mb-3">
          <label for="number" class="form-label">Enter a Positive Number:</label>
          <input
            type="text"
            id="number"
            v-model="numberInput"
            class="form-control"
            placeholder="e.g., 100, 5, 0.5"
          />
        </div>

        <!-- Input for Base -->
        <div class="mb-3">
          <label for="base" class="form-label">Enter the Base (must be > 0 and ≠ 1):</label>
          <input
            type="text"
            id="base"
            v-model="baseInput"
            class="form-control"
            placeholder="e.g., 10, 2, e"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateLog" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Number: {{ result.number }}</p>
          <p class="fs-5">Base: {{ result.base }}</p>
          <p class="fs-5">Calculation: log<sub>{{ result.base }}</sub>({{ result.number }})</p>
          <p class="fs-5">Final Result: {{ result.logResult }}</p>
          <p class="fs-5" v-if="result.explanation">Explanation: {{ result.explanation }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter a positive number in the first field for which you want to calculate the logarithm.</li>
        <li>Enter a base greater than zero and not equal to one in the second field (e.g., 2, 10, e).</li>
        <li>Click "Calculate" to compute the logarithm of the entered number to the specified base.</li>
        <li>The result will show the input number, base, calculation process, and final logarithm value.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">What is a Logarithm?</h5>
      <p class="fs-6">
        A logarithm is the power to which a base must be raised to obtain a given number. For example, \( \log_2(8) = 3 \) because \( 2^3 = 8 \).
      </p>

      <h5 class="alert-heading">Formula for Logarithm:</h5>
      <div class="fs-6">
        The logarithm of a number \( x \) to the base \( b \) is defined as:
      </div>
      <div>
        \[
        \log_b(x) = y \quad \text{if} \quad b^y = x
        \]
      </div>
      <p class="fs-6">
        This means that \( y \) is the exponent to which \( b \) must be raised to get \( x \).
      </p>

      <h5 class="alert-heading">Change of Base Formula:</h5>
      <p class="fs-6">
        If you want to calculate the logarithm of a number to any base, you can use the change of base formula:
      </p>
      <div>
        \[
        \log_b(x) = \frac{\log_k(x)}{\log_k(b)}
        \]
      </div>
      <p class="fs-6">
        where \( k \) can be any base, but common choices are 10 (common logarithm) or \( e \) (natural logarithm).
      </p>

      <h5 class="alert-heading">Properties of Logarithms:</h5>
      <ul class="fs-6">
        <li>
          \( \log_b(1) = 0 \)
        </li>
        <li>
          \( \log_b(b) = 1 \)
        </li>
        <li>
          \( \log_b(a \times c) = \log_b(a) + \log_b(c) \)
        </li>
        <li>
          \( \log_b\left(\frac{a}{c}\right) = \log_b(a) - \log_b(c) \)
        </li>
        <li>
          \( \log_b(a^c) = c \times \log_b(a) \)
        </li>
      </ul>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For \( \log_2(8) \):<br />
          Since \( 2^3 = 8 \), the logarithm of 8 to the base 2 is 3.
        </li>
        <li>
          For \( \log_{10}(100) \):<br />
          Since \( 10^2 = 100 \), the logarithm of 100 to the base 10 is 2.
        </li>
        <li>
          For \( \log_e(7.389) \):<br />
          Since \( e^2 \approx 7.389 \), the natural logarithm of 7.389 is approximately 2.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const numberInput = ref("");
    const baseInput = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate logarithm
    const calculateLog = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the input values
        const number = parseFloat(numberInput.value.trim());
        let base = baseInput.value.trim().toLowerCase();

        if (isNaN(number) || number <= 0) {
          throw new Error("Please enter a valid positive number.");
        }

        // Handle base "e" for natural logarithm
        if (base === "e") {
          base = Math.E;
        } else {
          base = parseFloat(base);
        }

        if (isNaN(base) || base <= 0 || base === 1) {
          throw new Error("Please enter a valid base greater than 0 and not equal to 1.");
        }

        // Calculate the logarithm using the change of base formula
        const logResult = Math.log(number) / Math.log(base);
        let explanation = "";

        if (number === 1) {
          explanation = `The logarithm of 1 to any base is always 0 because any number raised to the power 0 is 1.`;
        } else if (Math.abs(number - base) < 0.00001) {
          explanation = `The logarithm of a number to its own base is always 1 because ${base}^1 = ${number}.`;
        }

        result.value = {
          number: number,
          base: base === Math.E ? "e" : base,
          logResult: logResult.toFixed(6),
          explanation: explanation,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      numberInput.value = "";
      baseInput.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      numberInput,
      baseInput,
      result,
      error,
      calculateLog,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
