<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
      <div class="card-body p-15 p-sm-20">
        <div class="accordion" id="faqAccordion">
          <div class="row">
            <div>
              <div class="text-center mb-3">
                <h3>FAQs on Find and Replace Text Tool</h3>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseOne"
                  aria-expanded="true"
                  aria-controls="faqCollapseOne"
                >
                  <h5>What is the Find and Replace Text Tool?</h5>
                </button>
                <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      The Find and Replace Text Tool is an intuitive online utility that allows you to alter text quickly by replacing specific words, phrases, or patterns. It's designed with user-friendly functionalities like 'Match Case,' 'Whole Word Only,' and text highlighting to ensure precise and clear modifications.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseTwo"
                  aria-expanded="true"
                  aria-controls="faqCollapseTwo"
                >
                  <h5>How do I perform a basic find and replace?</h5>
                </button>
                <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Simply paste your text into the input textarea labeled 'Your Text.' Enter the word you want to find and the replacement word in the respective fields, then click 'Replace Text.' The tool will substitute your specified word or phrase and display the new text in the output section.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseThree"
                  aria-expanded="true"
                  aria-controls="faqCollapseThree"
                >
                  <h5>Can I choose to match case while replacing text?</h5>
                </button>
                <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Yes, the tool provides a 'Case Sensitive' option. By checking this box before hitting 'Replace Text,' the tool will consider the case of the characters in the 'Find' field, ensuring that only text matching the exact case is replaced.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseFour"
                  aria-expanded="true"
                  aria-controls="faqCollapseFour"
                >
                  <h5>How does 'Whole Word Only' work?</h5>
                </button>
                <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      When the 'Whole Word Only' option is selected, the tool will only replace entire words that match your 'Find' input. For instance, if you're looking to replace "is" with "at," "this" will remain unchanged, but "is" will become "at."
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseFive"
                  aria-expanded="true"
                  aria-controls="faqCollapseFive"
                >
                  <h5>Will the tool show how many occurrences have been replaced?</h5>
                </button>
                <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Definitely. After every replacement operation, the tool provides statistics detailing the number of occurrences that were replaced in your text.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseSix"
                  aria-expanded="true"
                  aria-controls="faqCollapseSix"
                >
                  <h5>Can I copy the modified text?</h5>
                </button>
                <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Of course! After replacing the text, you can instantly copy the updated content by clicking the 'Copy Text' button. This action copies the text to your clipboard, ready for pasting anywhere you need.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseSeven"
                  aria-expanded="true"
                  aria-controls="faqCollapseSeven"
                >
                  <h5>What should I do if I get an error message?</h5>
                </button>
                <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Ensure all fields are correctly filled and that the text does not contain unintended special characters. If issues persist, refer to the error message for guidance on resolving the input error.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseEight"
                  aria-expanded="true"
                  aria-controls="faqCollapseEight"
                >
                  <h5>Is my text safe? Do you store the text that's inputted?</h5>
                </button>
                <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Your privacy is paramount. The tool operates entirely on your browser, meaning no data is sent to a server or stored online. Your text remains private and secure.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseNine"
                  aria-expanded="true"
                  aria-controls="faqCollapseNine"
                >
                  <h5>Can I undo a replacement?</h5>
                </button>
                <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      Currently, the tool does not have an undo feature. It's recommended to double-check your 'Find' and 'Replace' inputs before performing the replacement. For added safety, keep a copy of your original text before making changes.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseTen"
                  aria-expanded="true"
                  aria-controls="faqCollapseTen"
                >
                  <h5>Are there any limitations on text size?</h5>
                </button>
                <div id="faqCollapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      The tool is designed to handle a wide range of text sizes, but extremely large texts might cause performance issues depending on your browser and device capabilities. It's best to work with manageable chunks of text for optimal performance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item rounded-0 border-0">
                <button
                  class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqCollapseEleven"
                  aria-expanded="true"
                  aria-controls="faqCollapseEleven"
                >
                  <h5>What if I encounter a problem with the tool?</h5>
                </button>
                <div id="faqCollapseEleven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                  <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                      If you encounter any issues or have questions about the Find and Replace Text Tool, please contact our support team. We're here to help you have a smooth and enjoyable experience!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'findAndReplaceTextFAQ',
  };
  </script>
  
  <style scoped>
  .custom-container {
    max-width: 80vh;
    margin: auto;
  }
  </style>
  