<template>
  <BreadCrumb :PageTitle="`Resize Image to ${targetSize}KB`" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Resize Image to {{ targetSize }}KB</h1>
    <p class="fs-6 text-center mb-4">
      Upload an image and we will resize it to a maximum file size of {{ targetSize }}KB while maintaining the best possible quality.
    </p>
    <!-- Image Upload Section -->
    <section class="mb-4">
      <h6>Upload Image</h6>
      <div
        @drop.prevent="onDrop"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        :class="{'drag-drop-box': true, 'drag-over': isDragOver}"
        @click="triggerFileInput"
      >
        <i class="bi bi-upload"></i>
        <p>Drag and drop an image here or click to upload</p>
        <p class="file-types">We accept .png, .jpg, .jpeg files only</p>
        <input
          type="file"
          @change="onFileChange"
          class="form-control"
          accept=".png, .jpg, .jpeg"
          style="display: none"
          ref="fileInput"
        />
      </div>
    </section>

    <!-- Image Preview and Resize Result Section -->
    <section class="mb-4">
      <div class="image-comparison-container">
        <div class="image-container">
          <h6>Original Image</h6>
          <div class="image-placeholder">
            <img v-if="imageData" :src="imageData" alt="Selected Image" />
          </div>
          <p v-if="imageData">Original Size: {{ originalFileSize }} KB</p>
        </div>
        <div class="image-container">
          <h6>Resized Image</h6>
          <div class="image-placeholder">
            <img v-if="resizedImageData" :src="resizedImageData" alt="Resized Image" />
          </div>
          <p v-if="resizedImageData">Resized Size: {{ resizedFileSize }} KB</p>
        </div>
      </div>
    </section>

    <!-- Resize Options Section -->
    <section class="mb-4">
      <h6>Resize Options</h6>
      <div class="input-group mb-3">
        <label class="input-group-text" for="targetSize">Target Size</label>
        <input type="number" id="targetSize" v-model="targetSize" @change="updateUrl" class="form-control" />
      </div>
      <div class="d-flex justify-content-between">
        <button @click="resizeImage" class="btn btn-primary flex-grow-1 me-2">Resize</button>
        <a
          v-if="resizedImageData"
          :href="resizedImageData"
          download="resized-image.jpg"
          class="btn btn-danger flex-grow-1 ms-2"
        >Download</a>
      </div>
      <div v-if="alertVisible" class="alert alert-info mt-3">Image resized successfully!</div>
      <div v-if="resizing" class="mt-3">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: resizeProgress + '%' }"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >{{ resizeProgress }}%</div>
        </div>
      </div>
    </section>
    <!-- Frequently Searched Links Section -->
    <section class="mb-4">
      <h5>Quick Resize Options</h5>
      <div>
        <router-link
          v-for="size in frequentSizes"
          :key="size"
          :to="`/resize-image-to-${size}-kb`"
          class="morelinks me-2 fs-6"
        >
          Resize image to {{ size }} KB
        </router-link>
      </div>
    </section>
    <div>
      <ResizeImageToFAQ />
    </div>
  </div>
</template>

<script>
import Pica from 'pica';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ResizeImageToFAQ from '../FAQs/resizeImageToFAQ.vue';

export default {
  components: {
    BreadCrumb,
    ResizeImageToFAQ
  },
  props: ['size'],
  data() {
    return {
      imageData: null,
      originalFileSize: 0,
      resizing: false,
      resizeProgress: 0,
      resizedImageData: null,
      resizedFileSize: 0,
      alertVisible: false,
      isDragOver: false,
      targetSize: this.size ? parseInt(this.size, 10) : 20,
      frequentSizes: [16, 20, 30, 40, 50, 60, 70, 75, 80, 85, 90, 95, 98, 100, 150, 180, 196, 200, 240, 250, 256, 300, 350, 400, 450, 500, 512, 600, 700, 800, 900, 1000]
    };
  },
  watch: {
    '$route.params.size'(newSize) {
      this.targetSize = newSize ? parseInt(newSize, 10) : 20;
    }
  },
  mounted() {
    const size = this.$route.params.size;
    if (size) {
      this.targetSize = parseInt(size, 10);
    }
  },
  methods: {
    updateUrl() {
      this.$router.push(`/resize-image-to-${this.targetSize}-kb`);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && this.isValidFileType(file)) {
        this.processFile(file);
      } else {
        alert("Please select a valid image file (.png, .jpg, or .jpeg)");
      }
    },
    onDrop(event) {
      this.isDragOver = false;
      const file = event.dataTransfer.files[0];
      if (file && this.isValidFileType(file)) {
        this.processFile(file);
      } else {
        alert("Please select a valid image file (.png, .jpg, or .jpeg)");
      }
    },
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    },
    isValidFileType(file) {
      const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      return acceptedTypes.includes(file.type);
    },
    processFile(file) {
      this.originalFileSize = (file.size / 1024).toFixed(2); // size in KB
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async resizeImage() {
      if (!this.imageData) {
        return;
      }
      this.resizing = true;
      this.resizeProgress = 0;
      this.alertVisible = false; // Hide alert before starting the resize process

      const img = new Image();
      img.src = this.imageData;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxFileSizeKB = this.targetSize;
        const resizePica = Pica();

        let quality = 1.0;
        let resizedBlob;

        // Function to resize image
        const resize = async (quality) => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          resizedBlob = await resizePica.toBlob(canvas, 'image/jpeg', quality);

          return resizedBlob;
        };

        resizedBlob = await resize(quality);

        while (resizedBlob.size / 1024 > maxFileSizeKB && quality > 0.1) {
          quality -= 0.1;
          resizedBlob = await resize(quality);
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.resizedImageData = e.target.result;
          this.resizedFileSize = (resizedBlob.size / 1024).toFixed(2);
          this.resizing = false;
          this.alertVisible = true; // Show alert after the image is resized
        };
        reader.readAsDataURL(resizedBlob);

        this.resizeProgress = 100;
      };
    },
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 85vh;
  margin: auto;
  padding: 20px;
}

.drag-drop-box {
  border: 1px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.drag-drop-box i {
  font-size: 2rem;
  display: block;
  margin-bottom: 10px;
}

.drag-drop-box:hover {
  background-color: #f8f9fa10;
}

.drag-over {
  border-color: #9eeaf9;
}

.file-types {
  font-size: 1em;
  color: #9eeaf9;
  margin-top: -10px;
}

.image-comparison-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.image-container {
  flex: 1;
  text-align: center;
}

.image-placeholder {
  border: 1px dashed #ccc;
  width: 100%;
  aspect-ratio: 4/3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 10px;
}

.image-placeholder img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.me-2 {
  margin-right: 0.5rem;
}

.ms-2 {
  margin-left: 0.5rem;
}

.flex-grow-1 {
  flex-grow: 1;
}

.dark .morelinks {
  color: #a5a8cc !important
}

.dark .accordion-item {
  color: #BCBBC7 !important;
  font-size: 16px;
}

.accordion-item {
  font-size: 16px;
}

.accordion-item text-paragraph {
  font-size: 18px;
}

@media (max-width: 768px) {
  .image-comparison-container {
    flex-direction: column;
  }
}
</style>