<template>
<BreadCrumb PageTitle="MidJourney Prompt Generator" />
<div class="custom-container mb-5">
    <h1 class="text-center">MidJourney Prompt Generator</h1>
    <p class="fs-5 text-center">Start generating complex midjourney prompts in seconds with our free and easy to use midjourney prompt generator.</p>
    <div class="row mt-5 mb-5">
    <div class="col-md-8">
        <div class="row">
        <div class="col container-fluid">
            <div class="row gy-4">
                <div>
                    <div class="d-flex align-items-center">
                <label class="form-label">
                Description:
            </label>
                <i class="flaticon-info ms-1" data-toggle="tooltip" title="Describe your image here (e.g a futuristic ai robot)" style="cursor: pointer;"></i></div>
                <textarea v-model="prompt" class="form-control row-cols-3" rows="4"></textarea>
            </div>
            <div>
                <div class="mt-1">
                <form @submit.prevent class="row">
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Aspect Ratio (--ar):
                    </label>
                    <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to change generated image aspect ratio. Default is 1:1."></i>
                    </div>
                    <select v-model="aspect" class="form-select form-select select" name="ar">
                        <option value="----" selected>----</option>
                        <option value="1:1">1:1</option>
                        <option value="5:4">5:4</option>
                        <option value="3:2">3:2</option>
                        <option value="7:4">7:4</option>
                        <option value="16:9">16:9</option>
                        <option value="2:1">2:1</option>
                        <option value="1:2">1:2</option>
                    </select>
                    </div>
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Quality (--q):
                    </label>
                        <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to determine how much time is spent generating an image. Default is 1 which is the highest quality."></i>
                </div>
                    <select v-model="quality" class="form-select form-select select" name="q">
                        <option value="----" selected>----</option>
                        <option value=".25">.25</option>
                        <option value=".5">.5</option>
                        <option value="1">1</option>
                    </select>
                    </div>
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Style (--style):
                    </label>
                        <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to create more photo-realistic images, cinematic scenes, or cuter characters depending on model used (v5.1, v5.2)."></i>
                </div>
                    <select v-model="style" class="form-select form-select select" name="style">
                        <option value="----" selected>----</option>
                        <option value="raw">raw</option>
                        <option value="cute">cute</option>
                        <option value="scenic">scenic</option>
                        <option value="original">original</option>
                        <option value="expressive">expressive</option>
                    </select>
                    </div>
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Do Not Include (--no):
                    </label>
                        <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use paramter to tell the Midjourney Bot what not to include in your image."></i>
                </div>
                    <input v-model="no" class="form-control form-control" type="text" name="no" />
                    </div>
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Stylize (--s):
                    </label>
                        <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to generate less artistic images that closely match the prompt (low --s value) or high artistic images but less connected to the prompt (high --s value)."></i>
                </div>
                    <input v-model="stylize" class="form-control form-control" type="number" max="1000" min="0" name="s" placeholder="0 to 1000" />
                    </div>
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Chaos (--chaos):
                    </label>
                        <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter with high values to produce more unusual and unexpected results. Lower values have more reliable, repeatable results."></i>
                </div>
                    <input v-model="chaos" class="form-control form-control" type="number" max="100" min="0" name="chaos" placeholder="0 to 100" />
                    </div>
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Stop (--stop):
                    </label>
                        <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to help finish a Job partway through the process. Stopping a Job at an earlier percentage can create blurrier, less detailed results."></i>
                </div>
                    <input v-model="stop" class="form-control form-control" type="number" max="100" min="10" name="stop" placeholder="10 to 100" />
                    </div>
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Repeat (--r):
                    </label>
                        <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to run a Job multiple times."></i>
                </div>
                    <input v-model="repeat" class="form-control form-control" type="number" max="40" min="1" name="r" placeholder="1 to 40" />
                    </div>
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Weird (--weird):
                    </label>
                        <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to add quirky and offbeat features to generated images, with unexpected unique results."></i>
                </div>
                    <input v-model="weird" class="form-control form-control" type="number" max="3000" min="0" name="weird" placeholder="0 to 3000" />
                    </div>
                    <div class="col-sm-6 mb-3"><div class="d-flex align-items-center">
                    <label class="form-label">
                        Seed (--seed):
                    </label>
                        <i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to generate images with similar final outcome. Default is random seeds which generates different image outcome for each prompt run."></i>
                </div>
                    <input v-model="seed" class="form-control form-control" type="number" max="4294967295" min="0" name="seed" placeholder="0 to 4294967295" />
                    </div>
                </form>
                </div>
            </div>
            <div>
                <h3 class="text-center">More Options</h3>
                <div class="mt-3 form-control">
                    <div class="mt-1">
                        <form id="more_options" class="row">
                            <div class="col-md-4 mb-3"><div class="d-flex align-items-center"><label class="form-label"> Uplight (--uplight): </label><i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to create a simpler, smoother enlarged image with fewer details."></i></div><select v-model="uplight" class="form-select form-select form-select select" name="uplight">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select></div>
                            <div class="col-md-4 mb-3"><div class="d-flex align-items-center"><label class="form-label"> Tile (--tile): </label><i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to create repeating image patterns for fabrics, wallpapers and textures."></i></div><select v-model="tile" class="form-select form-select form-select select" name="tile">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select></div>
                            <div class="col-md-4 mb-3"><div class="d-flex align-items-center"><label class="form-label"> Upbeta (--upbeta): </label><i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter for an alternative beta upscaler when selecting the U buttons for results more closer to the original grid image, with fewer added details."></i></div><select v-model="upbeta" class="form-select form-select form-select select" name="upbeta">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select></div>
                            <div class="col-md-4 mb-3"><div class="d-flex align-items-center"><label class="form-label"> Niji (--niji): </label><i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to generate anime-styled images."></i></div><select v-model="niji" class="form-select form-select form-select select" name="niji">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select></div>
                            <div class="col-md-4 mb-3"><div class="d-flex align-items-center"><label class="form-label"> Test (--test): </label><i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to use Midjourney special test model."></i></div><select v-model="test" class="form-select form-select form-select select" name="test">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select></div>
                            <div class="col-md-4 mb-3"><div class="d-flex align-items-center"><label class="form-label"> Testp (--testp): </label><i class="flaticon-info ms-1" data-toggle="tooltip" title="Use the Midjourney test model specifically designed for photography."></i></div><select v-model="testp" class="form-select form-select form-select select" name="testp">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select></div>
                            <div class="col-md-4 mb-3"><div class="d-flex align-items-center"><label class="form-label"> HD (--hd): </label><i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter with earlier models to produce larger, less consistent images. Best suitable for abstract and landscape images."></i></div><select v-model="hd" class="form-select form-select form-select select" name="hd">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select></div>
                            <div class="col-md-4 mb-3"><div class="d-flex align-items-center"><label class="form-label"> Sameseed (--sameseed): </label><i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to ensure that all images in the initial grid share the same starting noise, resulting in highly similar generated images."></i></div><select v-model="sameseed" class="form-select form-select form-select select" name="sameseed">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select></div>
                            <div class="col-md-4 mb-3"><div class="d-flex align-items-center"><label class="form-label"> Version (--v): </label><i class="flaticon-info ms-1" data-toggle="tooltip" title="Use parameter to choose what model version to use to generate desired images."></i></div><select v-model="version" class="form-select form-select form-select select" name="version">
                                    <option value="----" selected>----</option>
                                    <option value="5.2">5.2</option>
                                    <option value="5.1">5.1</option>
                                    <option value="5">5</option>
                                    <option value="4">4</option>
                                    <option value="3">3</option>
                                    <option value="2">2</option>
                                    <option value="1">1</option>
                                </select></div>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <h3 class="text-center mb-3 mt-5">Advanced Options</h3>
                <ul id="myTabs" class="nav nav-tabs nav-justified" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active d-flex align-items-center" aria-controls="id_styles" aria-selected="true" data-bs-toggle="tab" href="#id_styles" role="tab">
                        <i class="flaticon-image me-2"></i> Styles
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link d-flex align-items-center" aria-controls="id_lighting" aria-selected="false" data-bs-toggle="tab" href="#id_lighting" role="tab">
                        <i class="flaticon-sun me-2"></i> Lighting
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link d-flex align-items-center" aria-controls="id_engines" aria-selected="false" data-bs-toggle="tab" href="#id_engines" role="tab">
                    <i class="flaticon-settings-1 me-2"></i> Engines
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link d-flex align-items-center" aria-controls="id_colors" aria-selected="false" data-bs-toggle="tab" href="#id_colors" role="tab">
                    <i class="flaticon-pie-chart-1 me-2"></i> Colors
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link d-flex align-items-center" aria-controls="id_cameras" aria-selected="false" data-bs-toggle="tab" href="#id_cameras" role="tab">
                    <i class="flaticon-photo-camera me-2"></i> Cameras
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link d-flex align-items-center" aria-controls="id_artists" aria-selected="false" data-bs-toggle="tab" href="#id_artists" role="tab">
                    <i class="flaticon-user-3 me-2"></i> Artists
                    </a>
                </li>
                </ul>
                <div id="myTabContent" class="tab-content form-control">
                    <!-- Styles Tab -->
                    <div id="id_styles" class="tab-pane fade show active scrollable-tab-content" role="tabpanel" aria-labelledby="id_styles">
                        <div class="btn-group flex-wrap gap-2 p-1" role="group">
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('1800s')}" @click="toggleStyle('1800s')" type="button">1800s</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('1980s')}" @click="toggleStyle('1980s')" type="button">1980s</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('4-bit')}" @click="toggleStyle('4-bit')" type="button">4-bit</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('8-bit')}" @click="toggleStyle('8-bit')" type="button">8-bit</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Amber')}" @click="toggleStyle('Amber')" type="button">Amber</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Anatomical Drawing')}" @click="toggleStyle('Anatomical Drawing')" type="button">Anatomical Drawing</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ancient')}" @click="toggleStyle('Ancient')" type="button">Ancient</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Anime')}" @click="toggleStyle('Anime')" type="button">Anime</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Antimatter')}" @click="toggleStyle('Antimatter')" type="button">Antimatter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Arabic')}" @click="toggleStyle('Arabic')" type="button">Arabic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Black Hole')}" @click="toggleStyle('Black Hole')" type="button">Black Hole</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Blocky')}" @click="toggleStyle('Blocky')" type="button">Blocky</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Blueprint Drawing')}" @click="toggleStyle('Blueprint Drawing')" type="button">Blueprint Drawing</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Carbon Fiber')}" @click="toggleStyle('Carbon Fiber')" type="button">Carbon Fiber</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Caribbean')}" @click="toggleStyle('Caribbean')" type="button">Caribbean</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cartoon')}" @click="toggleStyle('Cartoon')" type="button">Cartoon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Carved Lacquer')}" @click="toggleStyle('Carved Lacquer')" type="button">Carved Lacquer</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Celestial')}" @click="toggleStyle('Celestial')" type="button">Celestial</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cellulose')}" @click="toggleStyle('Cellulose')" type="button">Cellulose</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Charcoal Style')}" @click="toggleStyle('Charcoal Style')" type="button">Charcoal Style</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Chromatic')}" @click="toggleStyle('Chromatic')" type="button">Chromatic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Comicbook')}" @click="toggleStyle('Comicbook')" type="button">Comicbook</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Comicbook Drawing')}" @click="toggleStyle('Comicbook Drawing')" type="button">Comicbook Drawing</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Computer Chip')}" @click="toggleStyle('Computer Chip')" type="button">Computer Chip</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Concept Art')}" @click="toggleStyle('Concept Art')" type="button">Concept Art</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Coral')}" @click="toggleStyle('Coral')" type="button">Coral</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cyberpunk')}" @click="toggleStyle('Cyberpunk')" type="button">Cyberpunk</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dangerous')}" @click="toggleStyle('Dangerous')" type="button">Dangerous</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark Matter')}" @click="toggleStyle('Dark Matter')" type="button">Dark Matter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Da Vinci')}" @click="toggleStyle('Da Vinci')" type="button">Da Vinci</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Da Vinci Drawing')}" @click="toggleStyle('Da Vinci Drawing')" type="button">Da Vinci Drawing</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Deep Sea')}" @click="toggleStyle('Deep Sea')" type="button">Deep Sea</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Diabolic')}" @click="toggleStyle('Diabolic')" type="button">Diabolic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Diffraction Grading')}" @click="toggleStyle('Diffraction Grading')" type="button">Diffraction Grading</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('DNA')}" @click="toggleStyle('DNA')" type="button">DNA</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dots')}" @click="toggleStyle('Dots')" type="button">Dots</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dripping Paint')}" @click="toggleStyle('Dripping Paint')" type="button">Dripping Paint</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dune')}" @click="toggleStyle('Dune')" type="button">Dune</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Electrical')}" @click="toggleStyle('Electrical')" type="button">Electrical</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Electronic Circuitry')}" @click="toggleStyle('Electronic Circuitry')" type="button">Electronic Circuitry</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Etching')}" @click="toggleStyle('Etching')" type="button">Etching</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Extraterrestrial')}" @click="toggleStyle('Extraterrestrial')" type="button">Extraterrestrial</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fiber Optic')}" @click="toggleStyle('Fiber Optic')" type="button">Fiber Optic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fibonacci')}" @click="toggleStyle('Fibonacci')" type="button">Fibonacci</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Floral')}" @click="toggleStyle('Floral')" type="button">Floral</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Flower Of Life')}" @click="toggleStyle('Flower Of Life')" type="button">Flower Of Life</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fossil')}" @click="toggleStyle('Fossil')" type="button">Fossil</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fractal')}" @click="toggleStyle('Fractal')" type="button">Fractal</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Futuristic')}" @click="toggleStyle('Futuristic')" type="button">Futuristic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Galactic')}" @click="toggleStyle('Galactic')" type="button">Galactic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gasoline')}" @click="toggleStyle('Gasoline')" type="button">Gasoline</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Glass')}" @click="toggleStyle('Glass')" type="button">Glass</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Glass Blowing')}" @click="toggleStyle('Glass Blowing')" type="button">Glass Blowing</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Glitchart')}" @click="toggleStyle('Glitchart')" type="button">Glitchart</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gouache')}" @click="toggleStyle('Gouache')" type="button">Gouache</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Graffitti')}" @click="toggleStyle('Graffitti')" type="button">Graffitti</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Graphic Novel')}" @click="toggleStyle('Graphic Novel')" type="button">Graphic Novel</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gummies')}" @click="toggleStyle('Gummies')" type="button">Gummies</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Helix')}" @click="toggleStyle('Helix')" type="button">Helix</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Higgs Boson')}" @click="toggleStyle('Higgs Boson')" type="button">Higgs Boson</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ice Age')}" @click="toggleStyle('Ice Age')" type="button">Ice Age</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Icy')}" @click="toggleStyle('Icy')" type="button">Icy</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Jurassic')}" @click="toggleStyle('Jurassic')" type="button">Jurassic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kaleidoscope')}" @click="toggleStyle('Kaleidoscope')" type="button">Kaleidoscope</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Knitted')}" @click="toggleStyle('Knitted')" type="button">Knitted</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Latex')}" @click="toggleStyle('Latex')" type="button">Latex</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lightspeed')}" @click="toggleStyle('Lightspeed')" type="button">Lightspeed</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Liquid')}" @click="toggleStyle('Liquid')" type="button">Liquid</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Logo')}" @click="toggleStyle('Logo')" type="button">Logo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Love')}" @click="toggleStyle('Love')" type="button">Love</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('LSD')}" @click="toggleStyle('LSD')" type="button">LSD</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Magma')}" @click="toggleStyle('Magma')" type="button">Magma</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Mandala')}" @click="toggleStyle('Mandala')" type="button">Mandala</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Marble Statue')}" @click="toggleStyle('Marble Statue')" type="button">Marble Statue</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Matter')}" @click="toggleStyle('Matter')" type="button">Matter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Merkaba')}" @click="toggleStyle('Merkaba')" type="button">Merkaba</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Metallic')}" @click="toggleStyle('Metallic')" type="button">Metallic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Mitochondria')}" @click="toggleStyle('Mitochondria')" type="button">Mitochondria</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Molecular')}" @click="toggleStyle('Molecular')" type="button">Molecular</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Multidimensional')}" @click="toggleStyle('Multidimensional')" type="button">Multidimensional</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('NASA')}" @click="toggleStyle('NASA')" type="button">NASA</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Nebula')}" @click="toggleStyle('Nebula')" type="button">Nebula</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Neon')}" @click="toggleStyle('Neon')" type="button">Neon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Nuclear')}" @click="toggleStyle('Nuclear')" type="button">Nuclear</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Oil Painting')}" @click="toggleStyle('Oil Painting')" type="button">Oil Painting</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Old Photograph')}" @click="toggleStyle('Old Photograph')" type="button">Old Photograph</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Orbital')}" @click="toggleStyle('Orbital')" type="button">Orbital</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Origami')}" @click="toggleStyle('Origami')" type="button">Origami</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ornamental')}" @click="toggleStyle('Ornamental')" type="button">Ornamental</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pastel')}" @click="toggleStyle('Pastel')" type="button">Pastel</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Photorealistic')}" @click="toggleStyle('Photorealistic')" type="button">Photorealistic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pixelart')}" @click="toggleStyle('Pixelart')" type="button">Pixelart</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Polka')}" @click="toggleStyle('Polka')" type="button">Polka</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pre Historic')}" @click="toggleStyle('Pre Historic')" type="button">Pre Historic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Prokaryotic')}" @click="toggleStyle('Prokaryotic')" type="button">Prokaryotic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Quasar')}" @click="toggleStyle('Quasar')" type="button">Quasar</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Radioactive')}" @click="toggleStyle('Radioactive')" type="button">Radioactive</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ray Tracing')}" @click="toggleStyle('Ray Tracing')" type="button">Ray Tracing</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Realistic')}" @click="toggleStyle('Realistic')" type="button">Realistic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Renaissance')}" @click="toggleStyle('Renaissance')" type="button">Renaissance</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Retro')}" @click="toggleStyle('Retro')" type="button">Retro</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Risograph')}" @click="toggleStyle('Risograph')" type="button">Risograph</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sacred Geometry')}" @click="toggleStyle('Sacred Geometry')" type="button">Sacred Geometry</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sketch Drawing')}" @click="toggleStyle('Sketch Drawing')" type="button">Sketch Drawing</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Slime')}" @click="toggleStyle('Slime')" type="button">Slime</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Space')}" @click="toggleStyle('Space')" type="button">Space</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Splatter Paint')}" @click="toggleStyle('Splatter Paint')" type="button">Splatter Paint</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Spray Paint')}" @click="toggleStyle('Spray Paint')" type="button">Spray Paint</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Squiggles')}" @click="toggleStyle('Squiggles')" type="button">Squiggles</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Stitching')}" @click="toggleStyle('Stitching')" type="button">Stitching</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Stranger Things')}" @click="toggleStyle('Stranger Things')" type="button">Stranger Things</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Street Art')}" @click="toggleStyle('Street Art')" type="button">Street Art</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Surreal')}" @click="toggleStyle('Surreal')" type="button">Surreal</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Symmetric')}" @click="toggleStyle('Symmetric')" type="button">Symmetric</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Synthwave')}" @click="toggleStyle('Synthwave')" type="button">Synthwave</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Technological')}" @click="toggleStyle('Technological')" type="button">Technological</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tron')}" @click="toggleStyle('Tron')" type="button">Tron</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tropical')}" @click="toggleStyle('Tropical')" type="button">Tropical</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ultra Modern')}" @click="toggleStyle('Ultra Modern')" type="button">Ultra Modern</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ultrasonic')}" @click="toggleStyle('Ultrasonic')" type="button">Ultrasonic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Veins')}" @click="toggleStyle('Veins')" type="button">Veins</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Volcanic')}" @click="toggleStyle('Volcanic')" type="button">Volcanic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wet Paint')}" @click="toggleStyle('Wet Paint')" type="button">Wet Paint</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wild West')}" @click="toggleStyle('Wild West')" type="button">Wild West</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wind')}" @click="toggleStyle('Wind')" type="button">Wind</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wormhole')}" @click="toggleStyle('Wormhole')" type="button">Wormhole</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wrinkled')}" @click="toggleStyle('Wrinkled')" type="button">Wrinkled</button>
                        </div>
                    </div>
                    <div id="id_lighting" class="tab-pane fade scrollable-tab-content" role="tabpanel" aria-labelledby="id_lighting">
                        <div class="btn-group flex-wrap gap-2 p-1" role="group">
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Spotlight')}" @click="toggleStyle('Spotlight')" type="button">Spotlight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Floodlight')}" @click="toggleStyle('Floodlight')" type="button">Floodlight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Frontlight')}" @click="toggleStyle('Frontlight')" type="button">Frontlight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Halfrear Lighting')}" @click="toggleStyle('Halfrear Lighting')" type="button">Halfrear Lighting</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Backlight')}" @click="toggleStyle('Backlight')" type="button">Backlight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rim Lights')}" @click="toggleStyle('Rim Lights')" type="button">Rim Lights</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rim Lighting')}" @click="toggleStyle('Rim Lighting')" type="button">Rim Lighting</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Marquee')}" @click="toggleStyle('Marquee')" type="button">Marquee</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Strobe')}" @click="toggleStyle('Strobe')" type="button">Strobe</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Strobe Light')}" @click="toggleStyle('Strobe Light')" type="button">Strobe Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Stroboscope')}" @click="toggleStyle('Stroboscope')" type="button">Stroboscope</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Flickering Light')}" @click="toggleStyle('Flickering Light')" type="button">Flickering Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Bubble Light')}" @click="toggleStyle('Bubble Light')" type="button">Bubble Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Flash-Lamp')}" @click="toggleStyle('Flash-Lamp')" type="button">Flash-Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Flashtube')}" @click="toggleStyle('Flashtube')" type="button">Flashtube</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dim Lighting')}" @click="toggleStyle('Dim Lighting')" type="button">Dim Lighting</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark Lighting')}" @click="toggleStyle('Dark Lighting')" type="button">Dark Lighting</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Bright')}" @click="toggleStyle('Bright')" type="button">Bright</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ultrabright')}" @click="toggleStyle('Ultrabright')" type="button">Ultrabright</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Blinding Light')}" @click="toggleStyle('Blinding Light')" type="button">Blinding Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Crepuscular Rays')}" @click="toggleStyle('Crepuscular Rays')" type="button">Crepuscular Rays</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rays of Shimmering Light')}" @click="toggleStyle('Rays of Shimmering Light')" type="button">Rays of Shimmering Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Artificial Lighting')}" @click="toggleStyle('Artificial Lighting')" type="button">Artificial Lighting</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Natural Lighting')}" @click="toggleStyle('Natural Lighting')" type="button">Natural Lighting</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sunlight')}" @click="toggleStyle('Sunlight')" type="button">Sunlight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Direct Sunlight')}" @click="toggleStyle('Direct Sunlight')" type="button">Direct Sunlight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sunshine Ray')}" @click="toggleStyle('Sunshine Ray')" type="button">Sunshine Ray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sunbeams')}" @click="toggleStyle('Sunbeams')" type="button">Sunbeams</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Moonbeams')}" @click="toggleStyle('Moonbeams')" type="button">Moonbeams</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Starlight')}" @click="toggleStyle('Starlight')" type="button">Starlight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Incandescent')}" @click="toggleStyle('Incandescent')" type="button">Incandescent</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Incandescent Lamp')}" @click="toggleStyle('Incandescent Lamp')" type="button">Incandescent Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fluorescent')}" @click="toggleStyle('Fluorescent')" type="button">Fluorescent</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fluorescent Lamp')}" @click="toggleStyle('Fluorescent Lamp')" type="button">Fluorescent Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('CFL')}" @click="toggleStyle('CFL')" type="button">CFL</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('CFL Light')}" @click="toggleStyle('CFL Light')" type="button">CFL Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Candlelight')}" @click="toggleStyle('Candlelight')" type="button">Candlelight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Torch')}" @click="toggleStyle('Torch')" type="button">Torch</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Torch Light')}" @click="toggleStyle('Torch Light')" type="button">Torch Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Northern Lights')}" @click="toggleStyle('Northern Lights')" type="button">Northern Lights</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Plasma Globe')}" @click="toggleStyle('Plasma Globe')" type="button">Plasma Globe</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Plasma Lamp')}" @click="toggleStyle('Plasma Lamp')" type="button">Plasma Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lava Lamp')}" @click="toggleStyle('Lava Lamp')" type="button">Lava Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tesla Coil')}" @click="toggleStyle('Tesla Coil')" type="button">Tesla Coil</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Electric Arc')}" @click="toggleStyle('Electric Arc')" type="button">Electric Arc</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Crackle Tube')}" @click="toggleStyle('Crackle Tube')" type="button">Crackle Tube</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Glow Stick')}" @click="toggleStyle('Glow Stick')" type="button">Glow Stick</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Blacklight')}" @click="toggleStyle('Blacklight')" type="button">Blacklight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Laser')}" @click="toggleStyle('Laser')" type="button">Laser</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Laser Light Show')}" @click="toggleStyle('Laser Light Show')" type="button">Laser Light Show</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dye-Laser')}" @click="toggleStyle('Dye-Laser')" type="button">Dye-Laser</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ion-Laser')}" @click="toggleStyle('Ion-Laser')" type="button">Ion-Laser</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gas-Laser')}" @click="toggleStyle('Gas-Laser')" type="button">Gas-Laser</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gobo')}" @click="toggleStyle('Gobo')" type="button">Gobo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gobo Light')}" @click="toggleStyle('Gobo Light')" type="button">Gobo Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Halogen')}" @click="toggleStyle('Halogen')" type="button">Halogen</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Halogen Lamp')}" @click="toggleStyle('Halogen Lamp')" type="button">Halogen Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Neon Lamp')}" @click="toggleStyle('Neon Lamp')" type="button">Neon Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Xenon Lamp')}" @click="toggleStyle('Xenon Lamp')" type="button">Xenon Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Krypton Lamp')}" @click="toggleStyle('Krypton Lamp')" type="button">Krypton Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Argon Lamp')}" @click="toggleStyle('Argon Lamp')" type="button">Argon Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Argon Flash')}" @click="toggleStyle('Argon Flash')" type="button">Argon Flash</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Helium Lamp')}" @click="toggleStyle('Helium Lamp')" type="button">Helium Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Carbide Lamp')}" @click="toggleStyle('Carbide Lamp')" type="button">Carbide Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Argand Lamp')}" @click="toggleStyle('Argand Lamp')" type="button">Argand Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Diya Lamp')}" @click="toggleStyle('Diya Lamp')" type="button">Diya Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Arc Lamp')}" @click="toggleStyle('Arc Lamp')" type="button">Arc Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gas Lamp')}" @click="toggleStyle('Gas Lamp')" type="button">Gas Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gas Mantle')}" @click="toggleStyle('Gas Mantle')" type="button">Gas Mantle</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kerosene Lamp')}" @click="toggleStyle('Kerosene Lamp')" type="button">Kerosene Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tilley Lamp')}" @click="toggleStyle('Tilley Lamp')" type="button">Tilley Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Oil Lamp')}" @click="toggleStyle('Oil Lamp')" type="button">Oil Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Mercury-Vapor Lamp')}" @click="toggleStyle('Mercury-Vapor Lamp')" type="button">Mercury-Vapor Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Metal-Halide Lamp')}" @click="toggleStyle('Metal-Halide Lamp')" type="button">Metal-Halide Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sodium-Vapor Lamp')}" @click="toggleStyle('Sodium-Vapor Lamp')" type="button">Sodium-Vapor Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sulfur Lamp')}" @click="toggleStyle('Sulfur Lamp')" type="button">Sulfur Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Hollow-Cathode Lamp')}" @click="toggleStyle('Hollow-Cathode Lamp')" type="button">Hollow-Cathode Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Electrodeless Lamp')}" @click="toggleStyle('Electrodeless Lamp')" type="button">Electrodeless Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lantern')}" @click="toggleStyle('Lantern')" type="button">Lantern</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Schwarz Lantern')}" @click="toggleStyle('Schwarz Lantern')" type="button">Schwarz Lantern</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Coleman Lantern')}" @click="toggleStyle('Coleman Lantern')" type="button">Coleman Lantern</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Flare')}" @click="toggleStyle('Flare')" type="button">Flare</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ember Light')}" @click="toggleStyle('Ember Light')" type="button">Ember Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Edison Bulb')}" @click="toggleStyle('Edison Bulb')" type="button">Edison Bulb</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Nixie Tube')}" @click="toggleStyle('Nixie Tube')" type="button">Nixie Tube</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rubens-Tube')}" @click="toggleStyle('Rubens-Tube')" type="button">Rubens-Tube</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vacuum Tube Lamp')}" @click="toggleStyle('Vacuum Tube Lamp')" type="button">Vacuum Tube Lamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Geissler Tube')}" @click="toggleStyle('Geissler Tube')" type="button">Geissler Tube</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dekatron')}" @click="toggleStyle('Dekatron')" type="button">Dekatron</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Nightlight')}" @click="toggleStyle('Nightlight')" type="button">Nightlight</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Christmas Lights')}" @click="toggleStyle('Christmas Lights')" type="button">Christmas Lights</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Optical Fiber')}" @click="toggleStyle('Optical Fiber')" type="button">Optical Fiber</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Electroluminescent Wire')}" @click="toggleStyle('Electroluminescent Wire')" type="button">Electroluminescent Wire</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Electromagnetic Spectrum')}" @click="toggleStyle('Electromagnetic Spectrum')" type="button">Electromagnetic Spectrum</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Infrared')}" @click="toggleStyle('Infrared')" type="button">Infrared</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ultraviolet')}" @click="toggleStyle('Ultraviolet')" type="button">Ultraviolet</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('UV')}" @click="toggleStyle('UV')" type="button">UV</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('X-Ray')}" @click="toggleStyle('X-Ray')" type="button">X-Ray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lightspeed')}" @click="toggleStyle('Lightspeed')" type="button">Lightspeed</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Nightclub')}" @click="toggleStyle('Nightclub')" type="button">Nightclub</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Glowing Radioactivity')}" @click="toggleStyle('Glowing Radioactivity')" type="button">Glowing Radioactivity</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Nuclear Waste')}" @click="toggleStyle('Nuclear Waste')" type="button">Nuclear Waste</button>
                        </div>
                    </div>
                    <div id="id_engines" class="tab-pane fade scrollable-tab-content" role="tabpanel" aria-labelledby="id_engines">
                        <div class="btn-group flex-wrap gap-2 p-1" role="group">
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Octane')}" @click="toggleStyle('Octane')" type="button">Octane</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cinema4D')}" @click="toggleStyle('Cinema4D')" type="button">Cinema4D</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('C4D')}" @click="toggleStyle('C4D')" type="button">C4D</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Unreal Engine')}" @click="toggleStyle('Unreal Engine')" type="button">Unreal Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Unity Engine')}" @click="toggleStyle('Unity Engine')" type="button">Unity Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rendered in Houdini')}" @click="toggleStyle('Rendered in Houdini')" type="button">Rendered in Houdini</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Houdini-Render')}" @click="toggleStyle('Houdini-Render')" type="button">Houdini-Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Redshift Render')}" @click="toggleStyle('Redshift Render')" type="button">Redshift Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Blender Render')}" @click="toggleStyle('Blender Render')" type="button">Blender Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cycles Render')}" @click="toggleStyle('Cycles Render')" type="button">Cycles Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('OptiX-Render')}" @click="toggleStyle('OptiX-Render')" type="button">OptiX-Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Povray')}" @click="toggleStyle('Povray')" type="button">Povray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vray')}" @click="toggleStyle('Vray')" type="button">Vray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('CryEngine')}" @click="toggleStyle('CryEngine')" type="button">CryEngine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('LuxCoreRender')}" @click="toggleStyle('LuxCoreRender')" type="button">LuxCoreRender</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Silicon Render')}" @click="toggleStyle('Silicon Render')" type="button">Silicon Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('MentalRay-Render')}" @click="toggleStyle('MentalRay-Render')" type="button">MentalRay-Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Raylectron')}" @click="toggleStyle('Raylectron')" type="button">Raylectron</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Infini-D-Render')}" @click="toggleStyle('Infini-D-Render')" type="button">Infini-D-Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Zbrush')}" @click="toggleStyle('Zbrush')" type="button">Zbrush</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sketchfab')}" @click="toggleStyle('Sketchfab')" type="button">Sketchfab</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('OpenGL')}" @click="toggleStyle('OpenGL')" type="button">OpenGL</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('DirectX')}" @click="toggleStyle('DirectX')" type="button">DirectX</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Autodesk 3ds Max')}" @click="toggleStyle('Autodesk 3ds Max')" type="button">Autodesk 3ds Max</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('SketchUp')}" @click="toggleStyle('SketchUp')" type="button">SketchUp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Terragen')}" @click="toggleStyle('Terragen')" type="button">Terragen</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Arnold Render')}" @click="toggleStyle('Arnold Render')" type="button">Arnold Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Corona Renderer')}" @click="toggleStyle('Corona Renderer')" type="button">Corona Renderer</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Indigo Renderer')}" @click="toggleStyle('Indigo Renderer')" type="button">Indigo Renderer</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Maxwell Render')}" @click="toggleStyle('Maxwell Render')" type="button">Maxwell Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('KeyShot')}" @click="toggleStyle('KeyShot')" type="button">KeyShot</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Appleseed')}" @click="toggleStyle('Appleseed')" type="button">Appleseed</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Mantra')}" @click="toggleStyle('Mantra')" type="button">Mantra</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Radiance')}" @click="toggleStyle('Radiance')" type="button">Radiance</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Thea Render')}" @click="toggleStyle('Thea Render')" type="button">Thea Render</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Caustic Visualizer')}" @click="toggleStyle('Caustic Visualizer')" type="button">Caustic Visualizer</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Yafaray')}" @click="toggleStyle('Yafaray')" type="button">Yafaray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Mitsuba')}" @click="toggleStyle('Mitsuba')" type="button">Mitsuba</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('RenderMan')}" @click="toggleStyle('RenderMan')" type="button">RenderMan</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('FurryBall')}" @click="toggleStyle('FurryBall')" type="button">FurryBall</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Iray')}" @click="toggleStyle('Iray')" type="button">Iray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Clarisse iFX')}" @click="toggleStyle('Clarisse iFX')" type="button">Clarisse iFX</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Marmoset Toolbag')}" @click="toggleStyle('Marmoset Toolbag')" type="button">Marmoset Toolbag</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ogre3D')}" @click="toggleStyle('Ogre3D')" type="button">Ogre3D</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Godot Engine')}" @click="toggleStyle('Godot Engine')" type="button">Godot Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lumberyard')}" @click="toggleStyle('Lumberyard')" type="button">Lumberyard</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Flamingo nXt')}" @click="toggleStyle('Flamingo nXt')" type="button">Flamingo nXt</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Combustion Engine')}" @click="toggleStyle('Combustion Engine')" type="button">Combustion Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('V6 Engine')}" @click="toggleStyle('V6 Engine')" type="button">V6 Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('V4 Engine')}" @click="toggleStyle('V4 Engine')" type="button">V4 Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Diesel Engine')}" @click="toggleStyle('Diesel Engine')" type="button">Diesel Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Inline Engine')}" @click="toggleStyle('Inline Engine')" type="button">Inline Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Four-stroke Engine')}" @click="toggleStyle('Four-stroke Engine')" type="button">Four-stroke Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Six Cylinder')}" @click="toggleStyle('Six Cylinder')" type="button">Six Cylinder</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('W Engine')}" @click="toggleStyle('W Engine')" type="button">W Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Flat Engine')}" @click="toggleStyle('Flat Engine')" type="button">Flat Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rotary Engine')}" @click="toggleStyle('Rotary Engine')" type="button">Rotary Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Radial Engine')}" @click="toggleStyle('Radial Engine')" type="button">Radial Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('V12 Engine')}" @click="toggleStyle('V12 Engine')" type="button">V12 Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('V8 Engine')}" @click="toggleStyle('V8 Engine')" type="button">V8 Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Spark-ignition Engine')}" @click="toggleStyle('Spark-ignition Engine')" type="button">Spark-ignition Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Straight-twin Engine')}" @click="toggleStyle('Straight-twin Engine')" type="button">Straight-twin Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wankel Engine')}" @click="toggleStyle('Wankel Engine')" type="button">Wankel Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Diesel')}" @click="toggleStyle('Diesel')" type="button">Diesel</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Petrol')}" @click="toggleStyle('Petrol')" type="button">Petrol</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Turbojet')}" @click="toggleStyle('Turbojet')" type="button">Turbojet</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Steam Engine')}" @click="toggleStyle('Steam Engine')" type="button">Steam Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('V10 Engine')}" @click="toggleStyle('V10 Engine')" type="button">V10 Engine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rocket Propulsion')}" @click="toggleStyle('Rocket Propulsion')" type="button">Rocket Propulsion</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Bing')}" @click="toggleStyle('Bing')" type="button">Bing</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Yahoo')}" @click="toggleStyle('Yahoo')" type="button">Yahoo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Duckduckgo')}" @click="toggleStyle('Duckduckgo')" type="button">Duckduckgo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Google Search')}" @click="toggleStyle('Google Search')" type="button">Google Search</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wolfram Alpha')}" @click="toggleStyle('Wolfram Alpha')" type="button">Wolfram Alpha</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Yandex Search')}" @click="toggleStyle('Yandex Search')" type="button">Yandex Search</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ecosia')}" @click="toggleStyle('Ecosia')" type="button">Ecosia</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dogpile')}" @click="toggleStyle('Dogpile')" type="button">Dogpile</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ask.com')}" @click="toggleStyle('Ask.com')" type="button">Ask.com</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Youtube')}" @click="toggleStyle('Youtube')" type="button">Youtube</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Qwant')}" @click="toggleStyle('Qwant')" type="button">Qwant</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Searx')}" @click="toggleStyle('Searx')" type="button">Searx</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gigablast')}" @click="toggleStyle('Gigablast')" type="button">Gigablast</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Yippy')}" @click="toggleStyle('Yippy')" type="button">Yippy</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Facebook')}" @click="toggleStyle('Facebook')" type="button">Facebook</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Metager')}" @click="toggleStyle('Metager')" type="button">Metager</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Webcrawler')}" @click="toggleStyle('Webcrawler')" type="button">Webcrawler</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Metacrawler')}" @click="toggleStyle('Metacrawler')" type="button">Metacrawler</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Amazon')}" @click="toggleStyle('Amazon')" type="button">Amazon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kiddle')}" @click="toggleStyle('Kiddle')" type="button">Kiddle</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alltheweb')}" @click="toggleStyle('Alltheweb')" type="button">Alltheweb</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Info.com')}" @click="toggleStyle('Info.com')" type="button">Info.com</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Google Images')}" @click="toggleStyle('Google Images')" type="button">Google Images</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sterndrive')}" @click="toggleStyle('Sterndrive')" type="button">Sterndrive</button>
                        </div>
                    </div>
                    <div id="id_colors" class="tab-pane fade scrollable-tab-content" role="tabpanel" aria-labelledby="id_colors">
                        <div class="btn-group flex-wrap gap-2 p-1" role="group">
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('White')}" @click="toggleStyle('White')" type="button">White</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Black')}" @click="toggleStyle('Black')" type="button">Black</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Brown')}" @click="toggleStyle('Brown')" type="button">Brown</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Gray')}" @click="toggleStyle('Light-Gray')" type="button">Light-Gray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gray')}" @click="toggleStyle('Gray')" type="button">Gray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Gray')}" @click="toggleStyle('Dark-Gray')" type="button">Dark-Gray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Maroon')}" @click="toggleStyle('Maroon')" type="button">Maroon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Red')}" @click="toggleStyle('Red')" type="button">Red</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Orange')}" @click="toggleStyle('Orange')" type="button">Orange</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Yellow')}" @click="toggleStyle('Yellow')" type="button">Yellow</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lime')}" @click="toggleStyle('Lime')" type="button">Lime</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Green')}" @click="toggleStyle('Green')" type="button">Green</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cyan')}" @click="toggleStyle('Cyan')" type="button">Cyan</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Teal')}" @click="toggleStyle('Teal')" type="button">Teal</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Blue')}" @click="toggleStyle('Blue')" type="button">Blue</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Indigo')}" @click="toggleStyle('Indigo')" type="button">Indigo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Purple')}" @click="toggleStyle('Purple')" type="button">Purple</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Violet')}" @click="toggleStyle('Violet')" type="button">Violet</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fuchsia')}" @click="toggleStyle('Fuchsia')" type="button">Fuchsia</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Magenta')}" @click="toggleStyle('Magenta')" type="button">Magenta</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pink')}" @click="toggleStyle('Pink')" type="button">Pink</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Palette')}" @click="toggleStyle('Palette')" type="button">Palette</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Color Palette')}" @click="toggleStyle('Color Palette')" type="button">Color Palette</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Warm Color Palette')}" @click="toggleStyle('Warm Color Palette')" type="button">Warm Color Palette</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cool Color Palette')}" @click="toggleStyle('Cool Color Palette')" type="button">Cool Color Palette</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Inverted Colors')}" @click="toggleStyle('Inverted Colors')" type="button">Inverted Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Colorful')}" @click="toggleStyle('Colorful')" type="button">Colorful</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Multicolored')}" @click="toggleStyle('Multicolored')" type="button">Multicolored</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rainbow')}" @click="toggleStyle('Rainbow')" type="button">Rainbow</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Spectral Color')}" @click="toggleStyle('Spectral Color')" type="button">Spectral Color</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vibrant')}" @click="toggleStyle('Vibrant')" type="button">Vibrant</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Chroma')}" @click="toggleStyle('Chroma')" type="button">Chroma</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dichromatism')}" @click="toggleStyle('Dichromatism')" type="button">Dichromatism</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tetrachromacy')}" @click="toggleStyle('Tetrachromacy')" type="button">Tetrachromacy</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Saturated')}" @click="toggleStyle('Saturated')" type="button">Saturated</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('High Saturation')}" @click="toggleStyle('High Saturation')" type="button">High Saturation</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Low Saturation')}" @click="toggleStyle('Low Saturation')" type="button">Low Saturation</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Neon')}" @click="toggleStyle('Neon')" type="button">Neon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Electric Colors')}" @click="toggleStyle('Electric Colors')" type="button">Electric Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Complimentary-Colors')}" @click="toggleStyle('Complimentary-Colors')" type="button">Complimentary-Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Split-Complementary-Colors')}" @click="toggleStyle('Split-Complementary-Colors')" type="button">Split-Complementary-Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Supplementary-Colors')}" @click="toggleStyle('Supplementary-Colors')" type="button">Supplementary-Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Analogous-Colors')}" @click="toggleStyle('Analogous-Colors')" type="button">Analogous-Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Triadic-Colors')}" @click="toggleStyle('Triadic-Colors')" type="button">Triadic-Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tetradic-Colors')}" @click="toggleStyle('Tetradic-Colors')" type="button">Tetradic-Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Polychromatic-Colors')}" @click="toggleStyle('Polychromatic-Colors')" type="button">Polychromatic-Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tonal Colors')}" @click="toggleStyle('Tonal Colors')" type="button">Tonal Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light')}" @click="toggleStyle('Light')" type="button">Light</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light Mode')}" @click="toggleStyle('Light Mode')" type="button">Light Mode</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark')}" @click="toggleStyle('Dark')" type="button">Dark</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark Mode')}" @click="toggleStyle('Dark Mode')" type="button">Dark Mode</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tones of Black')}" @click="toggleStyle('Tones of Black')" type="button">Tones of Black</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tones of Black in Background')}" @click="toggleStyle('Tones of Black in Background')" type="button">Tones of Black in Background</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light Blue Background')}" @click="toggleStyle('Light Blue Background')" type="button">Light Blue Background</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light Blue Foreground')}" @click="toggleStyle('Light Blue Foreground')" type="button">Light Blue Foreground</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tan')}" @click="toggleStyle('Tan')" type="button">Tan</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Beige')}" @click="toggleStyle('Beige')" type="button">Beige</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Blush')}" @click="toggleStyle('Blush')" type="button">Blush</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Scarlet')}" @click="toggleStyle('Scarlet')" type="button">Scarlet</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Olive-Green')}" @click="toggleStyle('Olive-Green')" type="button">Olive-Green</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Chartreuse')}" @click="toggleStyle('Chartreuse')" type="button">Chartreuse</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Turquoise')}" @click="toggleStyle('Turquoise')" type="button">Turquoise</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Aqua')}" @click="toggleStyle('Aqua')" type="button">Aqua</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Azure')}" @click="toggleStyle('Azure')" type="button">Azure</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Monochromatic')}" @click="toggleStyle('Monochromatic')" type="button">Monochromatic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Monochrome')}" @click="toggleStyle('Monochrome')" type="button">Monochrome</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Black and White')}" @click="toggleStyle('Black and White')" type="button">Black and White</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Desaturated')}" @click="toggleStyle('Desaturated')" type="button">Desaturated</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sepia')}" @click="toggleStyle('Sepia')" type="button">Sepia</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cyanopsia')}" @click="toggleStyle('Cyanopsia')" type="button">Cyanopsia</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-White')}" @click="toggleStyle('Dark-White')" type="button">Dark-White</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Brown')}" @click="toggleStyle('Dark-Brown')" type="button">Dark-Brown</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Maroon')}" @click="toggleStyle('Dark-Maroon')" type="button">Dark-Maroon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Red')}" @click="toggleStyle('Dark-Red')" type="button">Dark-Red</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Orange')}" @click="toggleStyle('Dark-Orange')" type="button">Dark-Orange</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Yellow')}" @click="toggleStyle('Dark-Yellow')" type="button">Dark-Yellow</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Lime')}" @click="toggleStyle('Dark-Lime')" type="button">Dark-Lime</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Green')}" @click="toggleStyle('Dark-Green')" type="button">Dark-Green</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Cyan')}" @click="toggleStyle('Dark-Cyan')" type="button">Dark-Cyan</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Blue')}" @click="toggleStyle('Dark-Blue')" type="button">Dark-Blue</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Purple')}" @click="toggleStyle('Dark-Purple')" type="button">Dark-Purple</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Magenta')}" @click="toggleStyle('Dark-Magenta')" type="button">Dark-Magenta</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dark-Pink')}" @click="toggleStyle('Dark-Pink')" type="button">Dark-Pink</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Black')}" @click="toggleStyle('Light-Black')" type="button">Light-Black</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Brown')}" @click="toggleStyle('Light-Brown')" type="button">Light-Brown</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Maroon')}" @click="toggleStyle('Light-Maroon')" type="button">Light-Maroon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Red')}" @click="toggleStyle('Light-Red')" type="button">Light-Red</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Orange')}" @click="toggleStyle('Light-Orange')" type="button">Light-Orange</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Yellow')}" @click="toggleStyle('Light-Yellow')" type="button">Light-Yellow</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Lime')}" @click="toggleStyle('Light-Lime')" type="button">Light-Lime</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Green')}" @click="toggleStyle('Light-Green')" type="button">Light-Green</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Cyan')}" @click="toggleStyle('Light-Cyan')" type="button">Light-Cyan</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Blue')}" @click="toggleStyle('Light-Blue')" type="button">Light-Blue</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Purple')}" @click="toggleStyle('Light-Purple')" type="button">Light-Purple</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Magenta')}" @click="toggleStyle('Light-Magenta')" type="button">Light-Magenta</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Light-Pink')}" @click="toggleStyle('Light-Pink')" type="button">Light-Pink</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Contrast')}" @click="toggleStyle('Contrast')" type="button">Contrast</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('High Contrast')}" @click="toggleStyle('High Contrast')" type="button">High Contrast</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Low Contrast')}" @click="toggleStyle('Low Contrast')" type="button">Low Contrast</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Color Model')}" @click="toggleStyle('Color Model')" type="button">Color Model</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('RGB')}" @click="toggleStyle('RGB')" type="button">RGB</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('scRGB')}" @click="toggleStyle('scRGB')" type="button">scRGB</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('CMYK')}" @click="toggleStyle('CMYK')" type="button">CMYK</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('HSV')}" @click="toggleStyle('HSV')" type="button">HSV</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('HSL')}" @click="toggleStyle('HSL')" type="button">HSL</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('HCL')}" @click="toggleStyle('HCL')" type="button">HCL</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('VGA')}" @click="toggleStyle('VGA')" type="button">VGA</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('EGA')}" @click="toggleStyle('EGA')" type="button">EGA</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('CGA')}" @click="toggleStyle('CGA')" type="button">CGA</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('HDR')}" @click="toggleStyle('HDR')" type="button">HDR</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('sRGB')}" @click="toggleStyle('sRGB')" type="button">sRGB</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('DCI-P3')}" @click="toggleStyle('DCI-P3')" type="button">DCI-P3</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Adobe RGB')}" @click="toggleStyle('Adobe RGB')" type="button">Adobe RGB</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('ProPhoto RGB')}" @click="toggleStyle('ProPhoto RGB')" type="button">ProPhoto RGB</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pantone')}" @click="toggleStyle('Pantone')" type="button">Pantone</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('YCbCr')}" @click="toggleStyle('YCbCr')" type="button">YCbCr</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('YPbPr')}" @click="toggleStyle('YPbPr')" type="button">YPbPr</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Coloroid')}" @click="toggleStyle('Coloroid')" type="button">Coloroid</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Brown')}" @click="toggleStyle('Vivid-Brown')" type="button">Vivid-Brown</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Maroon')}" @click="toggleStyle('Vivid-Maroon')" type="button">Vivid-Maroon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Red')}" @click="toggleStyle('Vivid-Red')" type="button">Vivid-Red</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Orange')}" @click="toggleStyle('Vivid-Orange')" type="button">Vivid-Orange</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Yellow')}" @click="toggleStyle('Vivid-Yellow')" type="button">Vivid-Yellow</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Lime')}" @click="toggleStyle('Vivid-Lime')" type="button">Vivid-Lime</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Green')}" @click="toggleStyle('Vivid-Green')" type="button">Vivid-Green</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Cyan')}" @click="toggleStyle('Vivid-Cyan')" type="button">Vivid-Cyan</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Blue')}" @click="toggleStyle('Vivid-Blue')" type="button">Vivid-Blue</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Purple')}" @click="toggleStyle('Vivid-Purple')" type="button">Vivid-Purple</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Magenta')}" @click="toggleStyle('Vivid-Magenta')" type="button">Vivid-Magenta</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid-Pink')}" @click="toggleStyle('Vivid-Pink')" type="button">Vivid-Pink</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Technicolor')}" @click="toggleStyle('Technicolor')" type="button">Technicolor</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kinemacolor')}" @click="toggleStyle('Kinemacolor')" type="button">Kinemacolor</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kodachrome')}" @click="toggleStyle('Kodachrome')" type="button">Kodachrome</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cinecolor')}" @click="toggleStyle('Cinecolor')" type="button">Cinecolor</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Agfacolor')}" @click="toggleStyle('Agfacolor')" type="button">Agfacolor</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Colorized')}" @click="toggleStyle('Colorized')" type="button">Colorized</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Color Wheel')}" @click="toggleStyle('Color Wheel')" type="button">Color Wheel</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Hue')}" @click="toggleStyle('Hue')" type="button">Hue</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tone')}" @click="toggleStyle('Tone')" type="button">Tone</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Value')}" @click="toggleStyle('Value')" type="button">Value</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gradient')}" @click="toggleStyle('Gradient')" type="button">Gradient</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vibrance')}" @click="toggleStyle('Vibrance')" type="button">Vibrance</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vivid')}" @click="toggleStyle('Vivid')" type="button">Vivid</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Spectrum')}" @click="toggleStyle('Spectrum')" type="button">Spectrum</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pigment')}" @click="toggleStyle('Pigment')" type="button">Pigment</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Variegated')}" @click="toggleStyle('Variegated')" type="button">Variegated</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pure')}" @click="toggleStyle('Pure')" type="button">Pure</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Purity')}" @click="toggleStyle('Purity')" type="button">Purity</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Faded Colors')}" @click="toggleStyle('Faded Colors')" type="button">Faded Colors</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Faded')}" @click="toggleStyle('Faded')" type="button">Faded</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Autochrome')}" @click="toggleStyle('Autochrome')" type="button">Autochrome</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('EnChroma')}" @click="toggleStyle('EnChroma')" type="button">EnChroma</button>
                        </div>
                    </div>
                    <div id="id_cameras" class="tab-pane fade scrollable-tab-content" role="tabpanel" aria-labelledby="id_cameras">
                        <div class="btn-group flex-wrap gap-2 p-1" role="group">
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Scene')}" @click="toggleStyle('Scene')" type="button">Scene</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Photography')}" @click="toggleStyle('Photography')" type="button">Photography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Filmic')}" @click="toggleStyle('Filmic')" type="button">Filmic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cinematic')}" @click="toggleStyle('Cinematic')" type="button">Cinematic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dramatic')}" @click="toggleStyle('Dramatic')" type="button">Dramatic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Glamor Shot')}" @click="toggleStyle('Glamor Shot')" type="button">Glamor Shot</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Golden Hour')}" @click="toggleStyle('Golden Hour')" type="button">Golden Hour</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Blue Hour')}" @click="toggleStyle('Blue Hour')" type="button">Blue Hour</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Award Winning Photography')}" @click="toggleStyle('Award Winning Photography')" type="button">Award Winning Photography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Establishing Shot')}" @click="toggleStyle('Establishing Shot')" type="button">Establishing Shot</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Nightography')}" @click="toggleStyle('Nightography')" type="button">Nightography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Photoshoot')}" @click="toggleStyle('Photoshoot')" type="button">Photoshoot</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Portrait')}" @click="toggleStyle('Portrait')" type="button">Portrait</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cinematic Haze')}" @click="toggleStyle('Cinematic Haze')" type="button">Cinematic Haze</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Subject')}" @click="toggleStyle('Subject')" type="button">Subject</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pose')}" @click="toggleStyle('Pose')" type="button">Pose</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gesture')}" @click="toggleStyle('Gesture')" type="button">Gesture</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Profile')}" @click="toggleStyle('Profile')" type="button">Profile</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('High-speed Photograph')}" @click="toggleStyle('High-speed Photograph')" type="button">High-speed Photograph</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Time-lapse')}" @click="toggleStyle('Time-lapse')" type="button">Time-lapse</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Motion Capture')}" @click="toggleStyle('Motion Capture')" type="button">Motion Capture</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Claymation')}" @click="toggleStyle('Claymation')" type="button">Claymation</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Video Frame Capture')}" @click="toggleStyle('Video Frame Capture')" type="button">Video Frame Capture</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Stop Motion')}" @click="toggleStyle('Stop Motion')" type="button">Stop Motion</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Stop-motion Animation Frame')}" @click="toggleStyle('Stop-motion Animation Frame')" type="button">Stop-motion Animation Frame</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Color Grading')}" @click="toggleStyle('Color Grading')" type="button">Color Grading</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Bokeh')}" @click="toggleStyle('Bokeh')" type="button">Bokeh</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Film Grain')}" @click="toggleStyle('Film Grain')" type="button">Film Grain</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Surveillance')}" @click="toggleStyle('Surveillance')" type="button">Surveillance</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Surveillance Footage')}" @click="toggleStyle('Surveillance Footage')" type="button">Surveillance Footage</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Security Footage')}" @click="toggleStyle('Security Footage')" type="button">Security Footage</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cctv')}" @click="toggleStyle('Cctv')" type="button">Cctv</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dashcam-footage')}" @click="toggleStyle('Dashcam-footage')" type="button">Dashcam-footage</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Satellite Imagery')}" @click="toggleStyle('Satellite Imagery')" type="button">Satellite Imagery</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Paparazzi Photography')}" @click="toggleStyle('Paparazzi Photography')" type="button">Paparazzi Photography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Underwater Photography')}" @click="toggleStyle('Underwater Photography')" type="button">Underwater Photography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wildlife Photography')}" @click="toggleStyle('Wildlife Photography')" type="button">Wildlife Photography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('National Geographic Photo')}" @click="toggleStyle('National Geographic Photo')" type="button">National Geographic Photo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Editorial Photography')}" @click="toggleStyle('Editorial Photography')" type="button">Editorial Photography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Associated Press Photo')}" @click="toggleStyle('Associated Press Photo')" type="button">Associated Press Photo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Photojournalism')}" @click="toggleStyle('Photojournalism')" type="button">Photojournalism</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Action Scene')}" @click="toggleStyle('Action Scene')" type="button">Action Scene</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('War Photography')}" @click="toggleStyle('War Photography')" type="button">War Photography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Color-gel')}" @click="toggleStyle('Color-gel')" type="button">Color-gel</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Filter')}" @click="toggleStyle('Filter')" type="button">Filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Photographic-filter')}" @click="toggleStyle('Photographic-filter')" type="button">Photographic-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Diffusion-filter')}" @click="toggleStyle('Diffusion-filter')" type="button">Diffusion-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dichroic-filter')}" @click="toggleStyle('Dichroic-filter')" type="button">Dichroic-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Uv-filter')}" @click="toggleStyle('Uv-filter')" type="button">Uv-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Polarization-filter')}" @click="toggleStyle('Polarization-filter')" type="button">Polarization-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Polarizer')}" @click="toggleStyle('Polarizer')" type="button">Polarizer</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Infrared-filter')}" @click="toggleStyle('Infrared-filter')" type="button">Infrared-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Infrared-cut-off-filter')}" @click="toggleStyle('Infrared-cut-off-filter')" type="button">Infrared-cut-off-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Neutral-density-filter')}" @click="toggleStyle('Neutral-density-filter')" type="button">Neutral-density-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Nd-filter')}" @click="toggleStyle('Nd-filter')" type="button">Nd-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Graduated-neutral-density-filter')}" @click="toggleStyle('Graduated-neutral-density-filter')" type="button">Graduated-neutral-density-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gnd-filter')}" @click="toggleStyle('Gnd-filter')" type="button">Gnd-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Astronomical-filter')}" @click="toggleStyle('Astronomical-filter')" type="button">Astronomical-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cokin-filter')}" @click="toggleStyle('Cokin-filter')" type="button">Cokin-filter</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Camcorder Effect')}" @click="toggleStyle('Camcorder Effect')" type="button">Camcorder Effect</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dslr')}" @click="toggleStyle('Dslr')" type="button">Dslr</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Night Vision')}" @click="toggleStyle('Night Vision')" type="button">Night Vision</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Drone Photography')}" @click="toggleStyle('Drone Photography')" type="button">Drone Photography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gopro Video')}" @click="toggleStyle('Gopro Video')" type="button">Gopro Video</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Unregistered Hypercam 2')}" @click="toggleStyle('Unregistered Hypercam 2')" type="button">Unregistered Hypercam 2</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Hyperspectral Imaging')}" @click="toggleStyle('Hyperspectral Imaging')" type="button">Hyperspectral Imaging</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Multispectral Imaging')}" @click="toggleStyle('Multispectral Imaging')" type="button">Multispectral Imaging</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Schlieren')}" @click="toggleStyle('Schlieren')" type="button">Schlieren</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Disposable Camera')}" @click="toggleStyle('Disposable Camera')" type="button">Disposable Camera</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Disposable Camera Photo')}" @click="toggleStyle('Disposable Camera Photo')" type="button">Disposable Camera Photo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Polaroid')}" @click="toggleStyle('Polaroid')" type="button">Polaroid</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ektachrome')}" @click="toggleStyle('Ektachrome')" type="button">Ektachrome</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fujifilm Superia')}" @click="toggleStyle('Fujifilm Superia')" type="button">Fujifilm Superia</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Instax')}" @click="toggleStyle('Instax')" type="button">Instax</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kodak Ektar')}" @click="toggleStyle('Kodak Ektar')" type="button">Kodak Ektar</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kodak Gold 200')}" @click="toggleStyle('Kodak Gold 200')" type="button">Kodak Gold 200</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kodak Portra')}" @click="toggleStyle('Kodak Portra')" type="button">Kodak Portra</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Nikon D750')}" @click="toggleStyle('Nikon D750')" type="button">Nikon D750</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Provia')}" @click="toggleStyle('Provia')" type="button">Provia</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Velvia')}" @click="toggleStyle('Velvia')" type="button">Velvia</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lomo')}" @click="toggleStyle('Lomo')" type="button">Lomo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cinemascope')}" @click="toggleStyle('Cinemascope')" type="button">Cinemascope</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tri-x 400 Tx')}" @click="toggleStyle('Tri-x 400 Tx')" type="button">Tri-x 400 Tx</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ilford Hp5')}" @click="toggleStyle('Ilford Hp5')" type="button">Ilford Hp5</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Photogram')}" @click="toggleStyle('Photogram')" type="button">Photogram</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vistavision')}" @click="toggleStyle('Vistavision')" type="button">Vistavision</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Technirama')}" @click="toggleStyle('Technirama')" type="button">Technirama</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Techniscope')}" @click="toggleStyle('Techniscope')" type="button">Techniscope</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Super-35')}" @click="toggleStyle('Super-35')" type="button">Super-35</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Panavision')}" @click="toggleStyle('Panavision')" type="button">Panavision</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Super-panavision-70')}" @click="toggleStyle('Super-panavision-70')" type="button">Super-panavision-70</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cinerama')}" @click="toggleStyle('Cinerama')" type="button">Cinerama</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kinopanorama')}" @click="toggleStyle('Kinopanorama')" type="button">Kinopanorama</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Cinemiracle')}" @click="toggleStyle('Cinemiracle')" type="button">Cinemiracle</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Daguerrotype')}" @click="toggleStyle('Daguerrotype')" type="button">Daguerrotype</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ambrotype')}" @click="toggleStyle('Ambrotype')" type="button">Ambrotype</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Calotype')}" @click="toggleStyle('Calotype')" type="button">Calotype</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tintype')}" @click="toggleStyle('Tintype')" type="button">Tintype</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Film-negative')}" @click="toggleStyle('Film-negative')" type="button">Film-negative</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Full Frame')}" @click="toggleStyle('Full Frame')" type="button">Full Frame</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Exposure')}" @click="toggleStyle('Exposure')" type="button">Exposure</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Short Exposure')}" @click="toggleStyle('Short Exposure')" type="button">Short Exposure</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Long Exposure')}" @click="toggleStyle('Long Exposure')" type="button">Long Exposure</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Double-exposure')}" @click="toggleStyle('Double-exposure')" type="button">Double-exposure</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shutter Speed 1/1000')}" @click="toggleStyle('Shutter Speed 1/1000')" type="button">Shutter Speed 1/1000</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shutter Speed 1/2')}" @click="toggleStyle('Shutter Speed 1/2')" type="button">Shutter Speed 1/2</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Aperture')}" @click="toggleStyle('Aperture')" type="button">Aperture</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gamma')}" @click="toggleStyle('Gamma')" type="button">Gamma</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('White Balance')}" @click="toggleStyle('White Balance')" type="button">White Balance</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shot On 8mm')}" @click="toggleStyle('Shot On 8mm')" type="button">Shot On 8mm</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shot On 9.5mm')}" @click="toggleStyle('Shot On 9.5mm')" type="button">Shot On 9.5mm</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shot On 16mm')}" @click="toggleStyle('Shot On 16mm')" type="button">Shot On 16mm</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shot On 17.5mm')}" @click="toggleStyle('Shot On 17.5mm')" type="button">Shot On 17.5mm</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shot On 28mm')}" @click="toggleStyle('Shot On 28mm')" type="button">Shot On 28mm</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shot On 35mm')}" @click="toggleStyle('Shot On 35mm')" type="button">Shot On 35mm</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Expired 35mm Film')}" @click="toggleStyle('Expired 35mm Film')" type="button">Expired 35mm Film</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shot On 65mm')}" @click="toggleStyle('Shot On 65mm')" type="button">Shot On 65mm</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Expired 65mm Film')}" @click="toggleStyle('Expired 65mm Film')" type="button">Expired 65mm Film</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shot On 70mm')}" @click="toggleStyle('Shot On 70mm')" type="button">Shot On 70mm</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Shot On Imax 70mm')}" @click="toggleStyle('Shot On Imax 70mm')" type="button">Shot On Imax 70mm</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Depth')}" @click="toggleStyle('Depth')" type="button">Depth</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Depth Of Field')}" @click="toggleStyle('Depth Of Field')" type="button">Depth Of Field</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dof')}" @click="toggleStyle('Dof')" type="button">Dof</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Zoom')}" @click="toggleStyle('Zoom')" type="button">Zoom</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dolly Zoom')}" @click="toggleStyle('Dolly Zoom')" type="button">Dolly Zoom</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Horizon Line')}" @click="toggleStyle('Horizon Line')" type="button">Horizon Line</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vantage Point')}" @click="toggleStyle('Vantage Point')" type="button">Vantage Point</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vanishing Point')}" @click="toggleStyle('Vanishing Point')" type="button">Vanishing Point</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pan')}" @click="toggleStyle('Pan')" type="button">Pan</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Tilt')}" @click="toggleStyle('Tilt')" type="button">Tilt</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Focal Point')}" @click="toggleStyle('Focal Point')" type="button">Focal Point</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Soft-focus')}" @click="toggleStyle('Soft-focus')" type="button">Soft-focus</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('15mm Lens')}" @click="toggleStyle('15mm Lens')" type="button">15mm Lens</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('35mm Lens')}" @click="toggleStyle('35mm Lens')" type="button">35mm Lens</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('85mm Lens')}" @click="toggleStyle('85mm Lens')" type="button">85mm Lens</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('100mm Lens')}" @click="toggleStyle('100mm Lens')" type="button">100mm Lens</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('200mm Lens')}" @click="toggleStyle('200mm Lens')" type="button">200mm Lens</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Megapixel')}" @click="toggleStyle('Megapixel')" type="button">Megapixel</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('2 Megapixels')}" @click="toggleStyle('2 Megapixels')" type="button">2 Megapixels</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('10 Megapixels')}" @click="toggleStyle('10 Megapixels')" type="button">10 Megapixels</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('12 Megapixels')}" @click="toggleStyle('12 Megapixels')" type="button">12 Megapixels</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('16 Megapixels')}" @click="toggleStyle('16 Megapixels')" type="button">16 Megapixels</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('20 Megapixels')}" @click="toggleStyle('20 Megapixels')" type="button">20 Megapixels</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('22 Megapixels')}" @click="toggleStyle('22 Megapixels')" type="button">22 Megapixels</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Macro')}" @click="toggleStyle('Macro')" type="button">Macro</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Macro View')}" @click="toggleStyle('Macro View')" type="button">Macro View</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Magnification')}" @click="toggleStyle('Magnification')" type="button">Magnification</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('100x Magnification')}" @click="toggleStyle('100x Magnification')" type="button">100x Magnification</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('200x Magnification')}" @click="toggleStyle('200x Magnification')" type="button">200x Magnification</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('500x Magnification')}" @click="toggleStyle('500x Magnification')" type="button">500x Magnification</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('1000x Magnification')}" @click="toggleStyle('1000x Magnification')" type="button">1000x Magnification</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Microscopic')}" @click="toggleStyle('Microscopic')" type="button">Microscopic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Electron Microscope')}" @click="toggleStyle('Electron Microscope')" type="button">Electron Microscope</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Super-resolution Microscopy')}" @click="toggleStyle('Super-resolution Microscopy')" type="button">Super-resolution Microscopy</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Telescope')}" @click="toggleStyle('Telescope')" type="button">Telescope</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Telescopic')}" @click="toggleStyle('Telescopic')" type="button">Telescopic</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Telescope Photography')}" @click="toggleStyle('Telescope Photography')" type="button">Telescope Photography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Panorama')}" @click="toggleStyle('Panorama')" type="button">Panorama</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('360 Panorama')}" @click="toggleStyle('360 Panorama')" type="button">360 Panorama</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wide Angle')}" @click="toggleStyle('Wide Angle')" type="button">Wide Angle</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ultra-wide Angle')}" @click="toggleStyle('Ultra-wide Angle')" type="button">Ultra-wide Angle</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('360 Angle')}" @click="toggleStyle('360 Angle')" type="button">360 Angle</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fisheye Lens')}" @click="toggleStyle('Fisheye Lens')" type="button">Fisheye Lens</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Fisheye Lens Effect')}" @click="toggleStyle('Fisheye Lens Effect')" type="button">Fisheye Lens Effect</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lens Distortion')}" @click="toggleStyle('Lens Distortion')" type="button">Lens Distortion</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lens Flare')}" @click="toggleStyle('Lens Flare')" type="button">Lens Flare</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vignette')}" @click="toggleStyle('Vignette')" type="button">Vignette</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Split Toning')}" @click="toggleStyle('Split Toning')" type="button">Split Toning</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rephotography')}" @click="toggleStyle('Rephotography')" type="button">Rephotography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Scanography')}" @click="toggleStyle('Scanography')" type="button">Scanography</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Miniature Faking')}" @click="toggleStyle('Miniature Faking')" type="button">Miniature Faking</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pinhole Lens')}" @click="toggleStyle('Pinhole Lens')" type="button">Pinhole Lens</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Telephoto Lens')}" @click="toggleStyle('Telephoto Lens')" type="button">Telephoto Lens</button>
                        </div>
                    </div>
                    <div id="id_artists" class="tab-pane fade scrollable-tab-content" role="tabpanel" aria-labelledby="id_artists">
                        <div class="btn-group flex-wrap gap-2 p-1" role="group">
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alphonse Mucha')}" @click="toggleStyle('Alphonse Mucha')" type="button">Alphonse Mucha</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Andy Warhol')}" @click="toggleStyle('Andy Warhol')" type="button">Andy Warhol</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Yoko Ono')}" @click="toggleStyle('Yoko Ono')" type="button">Yoko Ono</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Banksy')}" @click="toggleStyle('Banksy')" type="button">Banksy</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Francisco De Goya')}" @click="toggleStyle('Francisco De Goya')" type="button">Francisco De Goya</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Caravaggio')}" @click="toggleStyle('Caravaggio')" type="button">Caravaggio</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('David Hockney')}" @click="toggleStyle('David Hockney')" type="button">David Hockney</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Diego Rivera')}" @click="toggleStyle('Diego Rivera')" type="button">Diego Rivera</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Edgar Degas')}" @click="toggleStyle('Edgar Degas')" type="button">Edgar Degas</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Eugene Delacroix')}" @click="toggleStyle('Eugene Delacroix')" type="button">Eugene Delacroix</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Francis Bacon')}" @click="toggleStyle('Francis Bacon')" type="button">Francis Bacon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Frida Kahlo')}" @click="toggleStyle('Frida Kahlo')" type="button">Frida Kahlo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Garald Brom')}" @click="toggleStyle('Garald Brom')" type="button">Garald Brom</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gustav Klimt')}" @click="toggleStyle('Gustav Klimt')" type="button">Gustav Klimt</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Henri Matisse')}" @click="toggleStyle('Henri Matisse')" type="button">Henri Matisse</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Jack Kirby')}" @click="toggleStyle('Jack Kirby')" type="button">Jack Kirby</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Jackson Pollock')}" @click="toggleStyle('Jackson Pollock')" type="button">Jackson Pollock</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Jean Michel Basquiat')}" @click="toggleStyle('Jean Michel Basquiat')" type="button">Jean Michel Basquiat</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('JMW Turner')}" @click="toggleStyle('JMW Turner')" type="button">JMW Turner</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Johannes Vermeer')}" @click="toggleStyle('Johannes Vermeer')" type="button">Johannes Vermeer</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Leonardo Da Vinci')}" @click="toggleStyle('Leonardo Da Vinci')" type="button">Leonardo Da Vinci</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Marc Chagall')}" @click="toggleStyle('Marc Chagall')" type="button">Marc Chagall</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Marcel Duchamp')}" @click="toggleStyle('Marcel Duchamp')" type="button">Marcel Duchamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Mark Rothko')}" @click="toggleStyle('Mark Rothko')" type="button">Mark Rothko</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Michelangelo')}" @click="toggleStyle('Michelangelo')" type="button">Michelangelo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Monet')}" @click="toggleStyle('Monet')" type="button">Monet</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Paul Cezanne')}" @click="toggleStyle('Paul Cezanne')" type="button">Paul Cezanne</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Paul Gauguin')}" @click="toggleStyle('Paul Gauguin')" type="button">Paul Gauguin</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Paul Klee')}" @click="toggleStyle('Paul Klee')" type="button">Paul Klee</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Picasso')}" @click="toggleStyle('Picasso')" type="button">Picasso</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pierre Auguste Renoir')}" @click="toggleStyle('Pierre Auguste Renoir')" type="button">Pierre Auguste Renoir</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Piet Mondrian')}" @click="toggleStyle('Piet Mondrian')" type="button">Piet Mondrian</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rembrandt')}" @click="toggleStyle('Rembrandt')" type="button">Rembrandt</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Rene Magritte')}" @click="toggleStyle('Rene Magritte')" type="button">Rene Magritte</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Roy Lichtenstein')}" @click="toggleStyle('Roy Lichtenstein')" type="button">Roy Lichtenstein</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Salvador Dali')}" @click="toggleStyle('Salvador Dali')" type="button">Salvador Dali</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sandro Botticelli')}" @click="toggleStyle('Sandro Botticelli')" type="button">Sandro Botticelli</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Takashi Murakami')}" @click="toggleStyle('Takashi Murakami')" type="button">Takashi Murakami</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Van Gogh')}" @click="toggleStyle('Van Gogh')" type="button">Van Gogh</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Willem De Koonig')}" @click="toggleStyle('Willem De Koonig')" type="button">Willem De Koonig</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Yayoi Kusama')}" @click="toggleStyle('Yayoi Kusama')" type="button">Yayoi Kusama</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Yoji Shinkawa')}" @click="toggleStyle('Yoji Shinkawa')" type="button">Yoji Shinkawa</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ivan Shishkin')}" @click="toggleStyle('Ivan Shishkin')" type="button">Ivan Shishkin</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Zdzislaw Beksinski')}" @click="toggleStyle('Zdzislaw Beksinski')" type="button">Zdzislaw Beksinski</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('James Gurney')}" @click="toggleStyle('James Gurney')" type="button">James Gurney</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Claude Lorrain')}" @click="toggleStyle('Claude Lorrain')" type="button">Claude Lorrain</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Edward Hopper')}" @click="toggleStyle('Edward Hopper')" type="button">Edward Hopper</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Adolph Menzel')}" @click="toggleStyle('Adolph Menzel')" type="button">Adolph Menzel</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alexei Savrasov')}" @click="toggleStyle('Alexei Savrasov')" type="button">Alexei Savrasov</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Andrew Wyeth')}" @click="toggleStyle('Andrew Wyeth')" type="button">Andrew Wyeth</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vilhelm Hammershoi')}" @click="toggleStyle('Vilhelm Hammershoi')" type="button">Vilhelm Hammershoi</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('John Constable')}" @click="toggleStyle('John Constable')" type="button">John Constable</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Pablo Picasso')}" @click="toggleStyle('Pablo Picasso')" type="button">Pablo Picasso</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Andre Masson')}" @click="toggleStyle('Andre Masson')" type="button">Andre Masson</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Max Ernst')}" @click="toggleStyle('Max Ernst')" type="button">Max Ernst</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Jim Burns')}" @click="toggleStyle('Jim Burns')" type="button">Jim Burns</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Vincent Di Fate')}" @click="toggleStyle('Vincent Di Fate')" type="button">Vincent Di Fate</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Henry Moore')}" @click="toggleStyle('Henry Moore')" type="button">Henry Moore</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Da Vinci')}" @click="toggleStyle('Da Vinci')" type="button">Da Vinci</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Hieronymus Bosch')}" @click="toggleStyle('Hieronymus Bosch')" type="button">Hieronymus Bosch</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Jean Delville')}" @click="toggleStyle('Jean Delville')" type="button">Jean Delville</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Canaletto')}" @click="toggleStyle('Canaletto')" type="button">Canaletto</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wassily Kandinsky')}" @click="toggleStyle('Wassily Kandinsky')" type="button">Wassily Kandinsky</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Marcia Santore')}" @click="toggleStyle('Marcia Santore')" type="button">Marcia Santore</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Annibale Carracci')}" @click="toggleStyle('Annibale Carracci')" type="button">Annibale Carracci</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Anthony Van Dyck')}" @click="toggleStyle('Anthony Van Dyck')" type="button">Anthony Van Dyck</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kandinksey')}" @click="toggleStyle('Kandinksey')" type="button">Kandinksey</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Paul Cezane')}" @click="toggleStyle('Paul Cezane')" type="button">Paul Cezane</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Lawrence Pelton')}" @click="toggleStyle('Lawrence Pelton')" type="button">Lawrence Pelton</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Amanda Sage')}" @click="toggleStyle('Amanda Sage')" type="button">Amanda Sage</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Amedeo Modigliani')}" @click="toggleStyle('Amedeo Modigliani')" type="button">Amedeo Modigliani</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Robert Rauschenberg')}" @click="toggleStyle('Robert Rauschenberg')" type="button">Robert Rauschenberg</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Man Ray')}" @click="toggleStyle('Man Ray')" type="button">Man Ray</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Morton Livingston Schamberg')}" @click="toggleStyle('Morton Livingston Schamberg')" type="button">Morton Livingston Schamberg</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Suzanne Duchamp')}" @click="toggleStyle('Suzanne Duchamp')" type="button">Suzanne Duchamp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Francis Picabia')}" @click="toggleStyle('Francis Picabia')" type="button">Francis Picabia</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Georges Ribemont-Dessaignes')}" @click="toggleStyle('Georges Ribemont-Dessaignes')" type="button">Georges Ribemont-Dessaignes</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Juliette Roche')}" @click="toggleStyle('Juliette Roche')" type="button">Juliette Roche</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wilhelm Fick')}" @click="toggleStyle('Wilhelm Fick')" type="button">Wilhelm Fick</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('George Grosz')}" @click="toggleStyle('George Grosz')" type="button">George Grosz</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Hannah Hoch')}" @click="toggleStyle('Hannah Hoch')" type="button">Hannah Hoch</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Kurt Schwitters')}" @click="toggleStyle('Kurt Schwitters')" type="button">Kurt Schwitters</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Julius Evola')}" @click="toggleStyle('Julius Evola')" type="button">Julius Evola</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Serge Charchoune')}" @click="toggleStyle('Serge Charchoune')" type="button">Serge Charchoune</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ilia Zdanevich')}" @click="toggleStyle('Ilia Zdanevich')" type="button">Ilia Zdanevich</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Jean Crotti')}" @click="toggleStyle('Jean Crotti')" type="button">Jean Crotti</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Sophie Taeuber-Arp')}" @click="toggleStyle('Sophie Taeuber-Arp')" type="button">Sophie Taeuber-Arp</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Genpei Akasegawa')}" @click="toggleStyle('Genpei Akasegawa')" type="button">Genpei Akasegawa</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Josip Demirovic Devj')}" @click="toggleStyle('Josip Demirovic Devj')" type="button">Josip Demirovic Devj</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Jim Dine')}" @click="toggleStyle('Jim Dine')" type="button">Jim Dine</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Arthur Kopcke')}" @click="toggleStyle('Arthur Kopcke')" type="button">Arthur Kopcke</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('George Maciunas')}" @click="toggleStyle('George Maciunas')" type="button">George Maciunas</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Valery Oisteanu')}" @click="toggleStyle('Valery Oisteanu')" type="button">Valery Oisteanu</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ushio Shinohara')}" @click="toggleStyle('Ushio Shinohara')" type="button">Ushio Shinohara</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Jean Tinguely')}" @click="toggleStyle('Jean Tinguely')" type="button">Jean Tinguely</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Masunobu Yoshimura')}" @click="toggleStyle('Masunobu Yoshimura')" type="button">Masunobu Yoshimura</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Wes Anderson')}" @click="toggleStyle('Wes Anderson')" type="button">Wes Anderson</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alphonso Mucha')}" @click="toggleStyle('Alphonso Mucha')" type="button">Alphonso Mucha</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Uon.visuals')}" @click="toggleStyle('Uon.visuals')" type="button">Uon.visuals</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Seth McMahon')}" @click="toggleStyle('Seth McMahon')" type="button">Seth McMahon</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Artofethan')}" @click="toggleStyle('Artofethan')" type="button">Artofethan</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Peter Mohrbacher')}" @click="toggleStyle('Peter Mohrbacher')" type="button">Peter Mohrbacher</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Boris Groh')}" @click="toggleStyle('Boris Groh')" type="button">Boris Groh</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Albert Bierstadt')}" @click="toggleStyle('Albert Bierstadt')" type="button">Albert Bierstadt</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Thomas Kinkade')}" @click="toggleStyle('Thomas Kinkade')" type="button">Thomas Kinkade</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Annton Fadeev')}" @click="toggleStyle('Annton Fadeev')" type="button">Annton Fadeev</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alena Aenami')}" @click="toggleStyle('Alena Aenami')" type="button">Alena Aenami</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Andreas Rocha')}" @click="toggleStyle('Andreas Rocha')" type="button">Andreas Rocha</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Aleksi Briclot')}" @click="toggleStyle('Aleksi Briclot')" type="button">Aleksi Briclot</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ivan Stan')}" @click="toggleStyle('Ivan Stan')" type="button">Ivan Stan</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Junji Ito')}" @click="toggleStyle('Junji Ito')" type="button">Junji Ito</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Akihiko Yoshida')}" @click="toggleStyle('Akihiko Yoshida')" type="button">Akihiko Yoshida</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Anton Pieck')}" @click="toggleStyle('Anton Pieck')" type="button">Anton Pieck</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Angus McKie')}" @click="toggleStyle('Angus McKie')" type="button">Angus McKie</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Al Williamson')}" @click="toggleStyle('Al Williamson')" type="button">Al Williamson</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ilya Kuvshinov')}" @click="toggleStyle('Ilya Kuvshinov')" type="button">Ilya Kuvshinov</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('David Alabo')}" @click="toggleStyle('David Alabo')" type="button">David Alabo</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alberto Giacometti')}" @click="toggleStyle('Alberto Giacometti')" type="button">Alberto Giacometti</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alexander Milne Calder')}" @click="toggleStyle('Alexander Milne Calder')" type="button">Alexander Milne Calder</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Anne Stokes')}" @click="toggleStyle('Anne Stokes')" type="button">Anne Stokes</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Gerald Brom')}" @click="toggleStyle('Gerald Brom')" type="button">Gerald Brom</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Grant Wood')}" @click="toggleStyle('Grant Wood')" type="button">Grant Wood</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Albrecht Durer')}" @click="toggleStyle('Albrecht Durer')" type="button">Albrecht Durer</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Anne Geddes')}" @click="toggleStyle('Anne Geddes')" type="button">Anne Geddes</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Joel-Peter Witkin')}" @click="toggleStyle('Joel-Peter Witkin')" type="button">Joel-Peter Witkin</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alex Grey')}" @click="toggleStyle('Alex Grey')" type="button">Alex Grey</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Dan Mumford')}" @click="toggleStyle('Dan Mumford')" type="button">Dan Mumford</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Anne McCaffrey')}" @click="toggleStyle('Anne McCaffrey')" type="button">Anne McCaffrey</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Andy Worhol')}" @click="toggleStyle('Andy Worhol')" type="button">Andy Worhol</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Bob Ross')}" @click="toggleStyle('Bob Ross')" type="button">Bob Ross</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('M.C. Escher')}" @click="toggleStyle('M.C. Escher')" type="button">M.C. Escher</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Boris Smirnoff')}" @click="toggleStyle('Boris Smirnoff')" type="button">Boris Smirnoff</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Anton Otto')}" @click="toggleStyle('Anton Otto')" type="button">Anton Otto</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alexander Jansson')}" @click="toggleStyle('Alexander Jansson')" type="button">Alexander Jansson</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ansel Adams')}" @click="toggleStyle('Ansel Adams')" type="button">Ansel Adams</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Ray Harryhausen')}" @click="toggleStyle('Ray Harryhausen')" type="button">Ray Harryhausen</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('H.R. Giger')}" @click="toggleStyle('H.R. Giger')" type="button">H.R. Giger</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Raja Ravi Varma')}" @click="toggleStyle('Raja Ravi Varma')" type="button">Raja Ravi Varma</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Anna Dittmann')}" @click="toggleStyle('Anna Dittmann')" type="button">Anna Dittmann</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Hugh Ferriss')}" @click="toggleStyle('Hugh Ferriss')" type="button">Hugh Ferriss</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alexandre Cabanel')}" @click="toggleStyle('Alexandre Cabanel')" type="button">Alexandre Cabanel</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('John Howe')}" @click="toggleStyle('John Howe')" type="button">John Howe</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Squidward Tentacles')}" @click="toggleStyle('Squidward Tentacles')" type="button">Squidward Tentacles</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Marc Simonetti')}" @click="toggleStyle('Marc Simonetti')" type="button">Marc Simonetti</button>
                            <button :class="{'btn btn-outline-primary rounded-pill flex-grow-0': true, 'active': styles.includes('Alan Lee')}" @click="toggleStyle('Alan Lee')" type="button">Alan Lee</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
            </div>
            <div class="col-md-4">
                <div class="sticky-parent">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <label class="form-label">
                                Final Prompt:
                            </label>
                            <i class="flaticon-info ms-1" data-toggle="tooltip" title="This is the final midjourney prompt command. Simply click the copy button to copy the whole command to clipboard."></i>
                        </div>
                        <span id="copy_button" class="copy_button" title="Copy to clipboard" @click="copyToClipboard">
                            <i class="flaticon-copy fs-20 cursor-pointer"></i>
                        </span>
                    </div>
                    <div class="textarea-wrapper" style="position: relative;">
                        <textarea v-model="readonlyText" class="card w-100 p-3" name="text_readonly" rows="8" readonly></textarea>
                    </div>
                    <div class="mt-4">
                        <button id="reset_button" class="btn btn-primary w-100" type="button" title="Reset Prompt" @click="resetForm">
                            <span class="button-text">Reset Prompt</span>
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <MidjourneyPromptGeneratorFAQ />
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import { ref, onMounted, watch } from 'vue';
import { Tooltip } from 'bootstrap';
import MidjourneyPromptGeneratorFAQ from '../FAQs/midjourneyPromptGeneratorFAQ.vue';

export default {
    components: {
    BreadCrumb,
    MidjourneyPromptGeneratorFAQ
  },
  setup() {
    const prompt = ref('');
    const aspect = ref('----');
    const quality = ref('----');
    const style = ref('----');
    const no = ref('');
    const stylize = ref(null);
    const chaos = ref(null);
    const stop = ref(null);
    const repeat = ref(null);
    const weird = ref(null);
    const seed = ref(null);
    const uplight = ref('No');
    const tile = ref('No');
    const upbeta = ref('No');
    const niji = ref('No');
    const test = ref('No');
    const testp = ref('No');
    const hd = ref('No');
    const sameseed = ref('No');
    const version = ref('----');
    const styles = ref([]);
    const readonlyText = ref('');

    const toggleStyle = (style) => {
      const index = styles.value.indexOf(style);
      if (index === -1) {
        styles.value.push(style);
      } else {
        styles.value.splice(index, 1);
      }
      calculatePrompt();
    };

    const calculatePrompt = () => {
      let combinedText = '/imagine prompt:';
      if (prompt.value) {
        combinedText += ` ${prompt.value}`;
      }
      if (aspect.value !== '----') {
        combinedText += ` --ar ${aspect.value}`;
      }
      if (quality.value !== '----') {
        combinedText += ` --q ${quality.value}`;
      }
      if (style.value !== '----') {
        combinedText += ` --style ${style.value}`;
      }
      if (no.value) {
        combinedText += ` --no ${no.value}`;
      }
      if (stylize.value !== null && stylize.value !== 0) {
        combinedText += ` --s ${stylize.value}`;
      }
      if (chaos.value !== null && chaos.value !== 0) {
        combinedText += ` --chaos ${chaos.value}`;
      }
      if (stop.value !== null && stop.value !== 0) {
        combinedText += ` --stop ${stop.value}`;
      }
      if (repeat.value !== null && repeat.value !== 0) {
        combinedText += ` --r ${repeat.value}`;
      }
      if (weird.value !== null && weird.value !== 0) {
        combinedText += ` --weird ${weird.value}`;
      }
      if (seed.value !== null && seed.value !== 0) {
        combinedText += ` --seed ${seed.value}`;
      }
      if (uplight.value === 'Yes') {
        combinedText += ` --uplight`;
      }
      if (tile.value === 'Yes') {
        combinedText += ` --tile`;
      }
      if (upbeta.value === 'Yes') {
        combinedText += ` --upbeta`;
      }
      if (niji.value === 'Yes') {
        combinedText += ` --niji`;
      }
      if (test.value === 'Yes') {
        combinedText += ` --test`;
      }
      if (testp.value === 'Yes') {
        combinedText += ` --testp`;
      }
      if (hd.value === 'Yes') {
        combinedText += ` --hd`;
      }
      if (sameseed.value === 'Yes') {
        combinedText += ` --sameseed`;
      }
      if (version.value !== '----') {
        combinedText += ` --v ${version.value}`;
      }
      if (styles.value.length > 0) {
        combinedText += ` ${styles.value.join(', ')}`;
      }
      readonlyText.value = combinedText;
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(readonlyText.value).then(() => {
            const copyButton = document.getElementById('copy_button');
            if (copyButton) {
                // Store the current innerHTML
                const originalHTML = copyButton.innerHTML;
                // Change the innerHTML to the check mark
                copyButton.innerHTML = '<i class="flaticon-check-1 fs-20"></i>';
                setTimeout(() => {
                    // Revert back to the original innerHTML
                    copyButton.innerHTML = originalHTML;
                }, 1000);
            }
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    const resetForm = () => {
    prompt.value = '';
    aspect.value = '----';
    quality.value = '----';
    style.value = '----';
    no.value = '';
    stylize.value = null;
    chaos.value = null;
    stop.value = null;
    repeat.value = null;
    weird.value = null;
    seed.value = null;
    uplight.value = 'No';
    tile.value = 'No';
    upbeta.value = 'No';
    niji.value = 'No';
    test.value = 'No';
    testp.value = 'No';
    hd.value = 'No';
    sameseed.value = 'No';
    version.value = '----';
    styles.value = [];
    calculatePrompt();
};

    onMounted(() => {
      calculatePrompt();
      document.querySelectorAll('[data-toggle="tooltip"]').forEach((element) => {
        new Tooltip(element);  // Initialize tooltip using Tooltip from Bootstrap
      });
    });

    watch(
      [
        prompt,
        aspect,
        quality,
        style,
        no,
        stylize,
        chaos,
        stop,
        repeat,
        weird,
        seed,
        uplight,
        tile,
        upbeta,
        niji,
        test,
        testp,
        hd,
        sameseed,
        version,
        styles
      ],
      calculatePrompt,
      { deep: true, immediate: true }
    );

    return {
    prompt,
    aspect,
    quality,
    style,
    no,
    stylize,
    chaos,
    stop,
    repeat,
    weird,
    seed,
    uplight,
    tile,
    upbeta,
    niji,
    test,
    testp,
    hd,
    sameseed,
    version,
    styles,
    readonlyText,
    toggleStyle,
    resetForm,
    copyToClipboard,
    };
  },
};

</script>

<style scoped>
    /* Ensure the sticky parent is only fixed on larger screens */
    .sticky-parent {
        position: -webkit-sticky;
        position: sticky;
        margin-top: 30px;
    }

    @media (min-width: 992px) {
        .sticky-parent {
            position: fixed;
            width: 25%;
            margin-top: 0;
        }
    }

    .scrollable-tab-content {
        max-height: 300px;
        overflow-y: auto;
    }

    .flaticon-image {
        color: #ff4500;
    }

    .flaticon-sun {
        color: #ffc107;
    }

    .flaticon-settings-1 {
        color: #35dcb8;
    }

    .flaticon-pie-chart-1 {
        color: #7fff00;
    }
    
    .flaticon-photo-camera {
        color: #f0f;
    }

    .flaticon-user-3 {
        color: #0d8af0;
    }
</style>