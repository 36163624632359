<template>
      <div class="mt-5 mb-50">
        <div class="row">
          <h3 class="text-center mt-2">Recent Posts</h3>
          <div class="blog-sidebar">
            <div class="card border-0 mb-4">
              <div class="card-body">
                <ul class="list-unstyled">
                  <li v-for="(post, index) in recentPosts" :key="post.slug">
                    <div class="d-flex align-items-center">
                      <img :src="resolveImagePath(post.featuredImage)" :alt="post.title" class="featured-image me-3">
                      <div class="post-info">
                        <router-link :to="{ name: 'BlogPost', params: { slug: post.slug } }" class="text-decoration-none">
                          {{ post.title }}
                        </router-link>
                        <span class="post-date text-muted">{{ formatDate(post.date) }}</span>
                      </div>
                    </div>
                    <!-- Divider -->
                    <hr v-if="index < recentPosts.length - 1" class="my-3"/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>


<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useContent } from '@/utils/content';

interface BlogPost {
  slug: string;
  title: string;
  date: string;
  featuredImage: string;
  categories?: string[];
}

export default defineComponent({
  name: 'BlogSidebar',
  setup() {
    const { fetchPosts } = useContent();
    const recentPosts = ref<BlogPost[]>([]);
    const categories = ref<string[]>([]);

    onMounted(async () => {
      const posts: BlogPost[] = await fetchPosts();
      recentPosts.value = posts.slice(0, 5); // Get the 5 most recent posts
      categories.value = [...new Set(posts.flatMap(post => post.categories || []))];
    });

    const resolveImagePath = (imagePath: string) => {
      try {
        return require(`@/content/blog/${imagePath}`);
      } catch (e) {
        console.error("Image not found:", imagePath);
        return ''; // return an empty string or a placeholder image path
      }
    };

    const formatDate = (date: string) => {
      return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    };

    const filterByCategory = (category: string) => {
      // Implement category filtering logic here
      console.log(`Filter by category: ${category}`);
    };

    return {
      recentPosts,
      categories,
      resolveImagePath,
      formatDate,
      filterByCategory
    };
  }
});
</script>

<style scoped>
.blog-sidebar {
  padding: 1rem;
}

.featured-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.post-info {
  display: flex;
  flex-direction: column;
}

.post-date {
  font-size: 0.8rem;
}

a {
  font-size: 1rem;
  color: inherit;
  transition: color 0.2s;
}

a:hover {
  color: #76a1ff;
}

.dark a:hover {
  color: #76a1ff;
}

hr {
  border: none;
  border-top: 1px dashed #45445e;
  margin: 1rem 0;
}

.dark hr {
  border-top-color: #d9e9ef !important;
}

</style>