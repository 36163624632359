<template>
	<BreadCrumb PageTitle="Pace Running Calculator" />
	<div class="custom-container mt-4 mb-50">
	  <h1 class="text-center">Pace Running Calculator</h1>
	  <p class="fs-6 text-center mb-4">
		Discover your running potential with the Pace Running Calculator. Plan workouts, set achievable goals, and track performance improvements with ease.
	  </p>
  
	  <div class="mb-4">
		<label for="eventDistance" class="form-label">Event Distance</label>
		<select v-model="eventDistance" class="form-select" id="eventDistance">
		  <option v-for="distance in distances" :key="distance.value" :value="distance.value">
			{{ distance.label }}
		  </option>
		</select>
	  </div>
	  <div v-if="eventDistance === 0" class="mb-4">
		<label for="customDistance" class="form-label">Custom Distance</label>
		<div class="input-group">
		  <input v-model.number="customDistance" type="number" class="form-control" id="customDistance" min="0.8" placeholder="Enter custom distance">
		  <select v-model="customDistanceUnit" class="form-select" id="customDistanceUnit">
			<option value="km">km</option>
			<option value="mi">mi</option>
			<option value="m">m</option>
		  </select>
		</div>
	  </div>
	  <div class="mb-4">
		<label for="time" class="form-label">Time (hh:mm:ss)</label>
		<div class="input-group">
		  <input
			v-model.number="timeHours"
			type="number"
			class="form-control"
			placeholder="hh"
			@keydown="jumpToNext($event, 'minutesInput')"
			ref="hoursInput"
			min="0"
		  />
		  <span class="input-group-text">:</span>
		  <input
			v-model.number="timeMinutes"
			type="number"
			class="form-control"
			placeholder="mm"
			@keydown="jumpToNext($event, 'secondsInput')"
			ref="minutesInput"
			min="0"
			max="59"
		  />
		  <span class="input-group-text">:</span>
		  <input
			v-model.number="timeSeconds"
			type="number"
			class="form-control"
			placeholder="ss"
			ref="secondsInput"
			min="0"
			max="59"
		  />
		</div>
	  </div>
	  <div class="mb-4">
		<label for="pace" class="form-label">Pace (mm:ss)</label>
		<div class="input-group">
		  <input
			v-model.number="paceMinutes"
			type="number"
			class="form-control"
			placeholder="mm"
			@keydown="jumpToNext($event, 'paceSecondsInput')"
			ref="paceMinutesInput"
			min="0"
		  />
		  <span class="input-group-text">:</span>
		  <input
			v-model.number="paceSeconds"
			type="number"
			class="form-control"
			placeholder="ss"
			ref="paceSecondsInput"
			min="0"
			max="59"
		  />
		  <select v-model="paceUnit" class="form-select" id="paceUnit">
			<option value="mi">per mi</option>
			<option value="km">per km</option>
		  </select>
		</div>
	  </div>
  
	  <div class="mb-4">
		<button @click="toggleAdvanced" class="btn btn-outline-secondary w-100">
		  {{ showAdvanced ? 'Hide' : 'Show' }} Advanced Features
		</button>
	  </div>
  
	  <div v-if="showAdvanced" class="row mb-4">
		<div class="col-md-6 mb-3">
		  <label class="form-label">Adjustment Type</label>
		  <div>
			<div class="form-check form-check-inline">
			  <input
				v-model="adjustmentType"
				class="form-check-input"
				type="radio"
				id="temperature"
				value="temperature"
			  />
			  <label class="form-check-label" for="temperature">Temperature</label>
			</div>
			<div class="form-check form-check-inline">
			  <input
				v-model="adjustmentType"
				class="form-check-input"
				type="radio"
				id="altitude"
				value="altitude"
			  />
			  <label class="form-check-label" for="altitude">Altitude</label>
			</div>
			<div class="form-check form-check-inline">
			  <input
				v-model="adjustmentType"
				class="form-check-input"
				type="radio"
				id="none"
				value="none"
			  />
			  <label class="form-check-label" for="none">None</label>
			</div>
		  </div>
		</div>
		<div v-if="adjustmentType === 'temperature'" class="col-md-6 mb-3">
		  <label for="temperature" class="form-label">Temperature</label>
		  <div class="input-group">
			<input
			  v-model.number="temperature"
			  type="number"
			  class="form-control"
			  id="temperature"
			  placeholder="0"
			/>
			<select v-model="temperatureUnit" class="form-select" id="temperatureUnit">
			  <option value="F">°F</option>
			  <option value="C">°C</option>
			</select>
		  </div>
		</div>
		<div v-if="adjustmentType === 'altitude'" class="col-md-6 mb-3">
		  <label for="altitude" class="form-label">Altitude</label>
		  <div class="input-group">
			<input
			  v-model.number="altitude"
			  type="number"
			  class="form-control"
			  id="altitude"
			  placeholder="0"
			/>
			<select v-model="altitudeUnit" class="form-select" id="altitudeUnit">
			  <option value="ft">ft</option>
			  <option value="m">m</option>
			</select>
		  </div>
		</div>
	  </div>
  
	  <div class="row mb-3">
		<div class="col-md-6">
		  <button class="btn btn-primary btn-block w-100 mb-3" @click="calculate">
			Calculate
		  </button>
		</div>
		<div class="col-md-6">
		  <button class="btn btn-danger btn-block w-100" @click="reset">
			Reset
		  </button>
		</div>
	  </div>
  
	  <div v-if="result" class="mb-4">
		<label for="resultTextarea" class="form-label">Result:</label>
		<textarea 
		  id="resultTextarea" 
		  class="form-control fs-5 text-center" 
		  rows="1" 
		  readonly 
		  v-model="resultText"
		  ref="resultTextarea"
		  @input="adjustTextareaHeight"
		></textarea>
	  </div>
	  <div>
		<PaceRunningCalculatorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, computed, watch, onMounted, nextTick } from 'vue';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import PaceRunningCalculatorFAQ from '../FAQs/paceRunningCalculatorFAQ.vue';
  
  export default {
	name: 'PaceRunningCalculator',
	components: {
	  BreadCrumb,
	  PaceRunningCalculatorFAQ
	},
	setup() {
	  const distances = [
		{ value: 42195, label: 'Marathon' },
		{ value: 21097.5, label: 'Half-Marathon' },
		{ value: 15000, label: '15K' },
		{ value: 10000, label: '10K' },
		{ value: 5000, label: '5K' },
		{ value: 3218.688, label: '2Mi' },
		{ value: 3200, label: '3200m' },
		{ value: 3000, label: '3K' },
		{ value: 1609.344, label: '1Mi' },
		{ value: 1600, label: '1600m' },
		{ value: 1500, label: '1500m' },
		{ value: 0, label: 'Other' }
	  ];
  
	  const eventDistance = ref(42195);
	  const customDistance = ref(0);
	  const customDistanceUnit = ref('km');
	  const timeHours = ref(0);
	  const timeMinutes = ref(0);
	  const timeSeconds = ref(0);
	  const paceMinutes = ref(0);
	  const paceSeconds = ref(0);
	  const paceUnit = ref('mi');
	  const showAdvanced = ref(false);
	  const adjustmentType = ref('none');
	  const temperature = ref(0);
	  const temperatureUnit = ref('F');
	  const altitude = ref(0);
	  const altitudeUnit = ref('ft');
	  const result = ref(null);
	  const resultTextarea = ref(null);
  
	  const resultText = computed(() => {
		if (!result.value) return '';
		let text = `Distance: ${formatDistance(getEffectiveDistance())}\n\n`;
		text += `Time: ${result.value.time}\n\n`;
		text += `Total Pace Needed: ${result.value.pace} ${paceUnit.value === 'mi' ? 'per mile' : 'per km'}\n\n`;
		if (adjustmentType.value !== 'none') {
		  text += `Adjusted for ${adjustmentType.value}:\n\n`;
		  text += `Time: ${result.value.adjustedTime}\n\n`;
		  text += `Total Pace Needed: ${result.value.adjustedPace} ${paceUnit.value === 'mi' ? 'per mile' : 'per km'}\n\n`;
		  text += `Adjustment Effect: ${result.value.adjustmentEffect}`;
		}
		return text;
	  });
  
	  const adjustTextareaHeight = () => {
		if (resultTextarea.value) {
		  resultTextarea.value.style.height = 'auto';
		  resultTextarea.value.style.height = resultTextarea.value.scrollHeight + 'px';
		}
	  };
  
	  watch(result, () => {
		nextTick(adjustTextareaHeight);
	  }, { deep: true });
  
	  // Helper functions
	  const timeToSeconds = (hours, minutes, seconds) => {
		return hours * 3600 + minutes * 60 + seconds;
	  };
  
	  const secondsToTime = (totalSeconds) => {
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = Math.round(totalSeconds % 60);
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	  };
  
	  const getEffectiveDistance = () => {
		if (eventDistance.value !== 0) return eventDistance.value;
		switch (customDistanceUnit.value) {
		  case 'km': return customDistance.value * 1000;
		  case 'mi': return customDistance.value * 1609.344;
		  default: return customDistance.value;
		}
	  };
  
	  const formatDistance = (meters) => {
		if (meters >= 1000) {
		  return `${(meters / 1000).toFixed(2)} km`;
		} else {
		  return `${meters.toFixed(2)} m`;
		}
	  };
  
	  const calculatePace = (timeSeconds, distanceMeters) => {
		const paceInSeconds = timeSeconds / (distanceMeters / (paceUnit.value === 'mi' ? 1609.344 : 1000));
		const paceMinutes = Math.floor(paceInSeconds / 60);
		const paceSeconds = Math.round(paceInSeconds % 60);
		return `${paceMinutes.toString().padStart(2, '0')}:${paceSeconds.toString().padStart(2, '0')}`;
	  };
  
	  const convertTemperature = (value, from, to) => {
		if (from === to) return value;
		return from === 'F' ? (value - 32) * 5/9 : value * 9/5 + 32;
	  };
  
	  const convertAltitude = (value, from, to) => {
		if (from === to) return value;
		return from === 'ft' ? value * 0.3048 : value / 0.3048;
	  };
  
	  watch(temperatureUnit, (newUnit, oldUnit) => {
		temperature.value = convertTemperature(temperature.value, oldUnit, newUnit);
	  });
  
	  watch(altitudeUnit, (newUnit, oldUnit) => {
		altitude.value = convertAltitude(altitude.value, oldUnit, newUnit);
	  });
  
	  const adjustForTemperature = (timeSeconds) => {
		const tempInC = temperatureUnit.value === 'F' ? convertTemperature(temperature.value, 'F', 'C') : temperature.value;
		const adjustmentFactor = 1 + (tempInC - 20) * 0.003; // 0.3% slowdown per degree C above 20°C
		return timeSeconds * adjustmentFactor;
	  };
  
	  const adjustForAltitude = (timeSeconds) => {
		const altitudeInMeters = altitudeUnit.value === 'ft' ? convertAltitude(altitude.value, 'ft', 'm') : altitude.value;
		const adjustmentFactor = 1 + (altitudeInMeters / 1000) * 0.02; // 2% slowdown per 1000m of elevation
		return timeSeconds * adjustmentFactor;
	  };
  
	  const calculate = () => {
		const distanceInMeters = getEffectiveDistance();
		let timeInSeconds = timeToSeconds(timeHours.value, timeMinutes.value, timeSeconds.value);
		let paceInSeconds = timeToSeconds(0, paceMinutes.value, paceSeconds.value);
  
		if (timeInSeconds && !paceInSeconds) {
		  paceInSeconds = timeInSeconds / (distanceInMeters / (paceUnit.value === 'mi' ? 1609.344 : 1000));
		  paceMinutes.value = Math.floor(paceInSeconds / 60);
		  paceSeconds.value = Math.round(paceInSeconds % 60);
		} else if (paceInSeconds && !timeInSeconds) {
		  timeInSeconds = paceInSeconds * (distanceInMeters / (paceUnit.value === 'mi' ? 1609.344 : 1000));
		  timeHours.value = Math.floor(timeInSeconds / 3600);
		  timeMinutes.value = Math.floor((timeInSeconds % 3600) / 60);
		  timeSeconds.value = Math.round(timeInSeconds % 60);
		}
  
		if (!timeInSeconds || !paceInSeconds) {
		  alert("Please enter either time or pace.");
		  return;
		}
  
		let adjustedTimeInSeconds = timeInSeconds;
		if (adjustmentType.value === 'temperature') {
		  adjustedTimeInSeconds = adjustForTemperature(timeInSeconds);
		} else if (adjustmentType.value === 'altitude') {
		  adjustedTimeInSeconds = adjustForAltitude(timeInSeconds);
		}
  
		result.value = {
		  time: secondsToTime(timeInSeconds),
		  pace: calculatePace(timeInSeconds, distanceInMeters),
		  adjustedTime: secondsToTime(adjustedTimeInSeconds),
		  adjustedPace: calculatePace(adjustedTimeInSeconds, distanceInMeters),
		  adjustmentEffect: secondsToTime(adjustedTimeInSeconds - timeInSeconds)
		};
	  };
  
	  const toggleAdvanced = () => {
		showAdvanced.value = !showAdvanced.value;
	  };
  
	  const reset = () => {
		eventDistance.value = 42195;
		customDistance.value = 0;
		customDistanceUnit.value = 'km';
		timeHours.value = 0;
		timeMinutes.value = 0;
		timeSeconds.value = 0;
		paceMinutes.value = 0;
		paceSeconds.value = 0;
		paceUnit.value = 'mi';
		adjustmentType.value = 'none';
		temperature.value = 0;
		temperatureUnit.value = 'F';
		altitude.value = 0;
		altitudeUnit.value = 'ft';
		result.value = null;
		adjustTextareaHeight();
	  };
  
	  const jumpToNext = (event, nextField) => {
		if (event.key === 'Tab' || event.key === ':') {
		  event.preventDefault();
		  if (refs[nextField].value) {
			refs[nextField].value.focus();
		  }
		}
	  };
  
	  const refs = {
		hoursInput: ref(null),
		minutesInput: ref(null),
		secondsInput: ref(null),
		paceMinutesInput: ref(null),
		paceSecondsInput: ref(null),
		resultTextarea: ref(null)
	  };
  
	  onMounted(() => {
		adjustTextareaHeight();
	  });
  
	  return {
		distances,
		eventDistance,
		customDistance,
		customDistanceUnit,
		timeHours,
		timeMinutes,
		timeSeconds,
		paceMinutes,
		paceSeconds,
		paceUnit,
		showAdvanced,
		adjustmentType,
		temperature,
		temperatureUnit,
		altitude,
		altitudeUnit,
		result,
		resultText,
		resultTextarea,
		toggleAdvanced,
		calculate,
		reset,
		jumpToNext,
		...refs
	  };
	}
  };
  </script>
  
  <style scoped>
  .custom-container {
	width: 90vw;
	max-width: 800px;
	margin: auto;
	padding: 0 1rem;
	box-sizing: border-box;
  }
  label,
  .form-control {
	font-size: 16px;
  }
  #resultTextarea {
	min-height: 100px;
	overflow-y: hidden;
	resize: none;
  }
  @media (max-width: 600px) {
	.custom-container {
	  width: 95vw;
	  padding: 0 0.5rem;
	}
  }
  </style>
  