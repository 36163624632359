<template>
  <BreadCrumb PageTitle="Word Flipper Generator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Word Flipper Generator</h1>
    <p class="fs-6 text-center mb-4">
      Transform your text with the Word Flipper Generator! Easily flip words
      upside-down, vertical, horizontal, or reverse and impress your friends
      with every flip!
    </p>

    <div class="form-group mb-4">
      <label for="textInput">Enter Texts</label>
      <textarea
        v-model="textInput"
        class="form-control"
        id="textInput"
        rows="4"
      ></textarea>
    </div>

    <div class="form-group mb-4">
      <div class="input-group mb-3">
        <span class="input-group-text" for="flipStyle">Flip Style:</span>
        <select v-model="flipStyle" class="form-control" id="flipStyle">
          <option value="upside-down">Upside Down</option>
          <option value="flip-vertically">Flip Vertically</option>
          <option value="flip-horizontally">Flip Horizontally</option>
          <option value="reverse-text">Reverse Text</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-3">
        <button
          @click="flipWords"
          class="btn btn-primary w-100"
          data-bs-toggle="tooltip"
          title="Flip Words"
        >
          Flip Words
        </button>
      </div>
      <div class="col-md-6 mb-4">
        <button
          @click="reset"
          class="btn btn-danger w-100"
          data-bs-toggle="tooltip"
          title="Reset"
        >
          Reset
        </button>
      </div>
    </div>

    <div v-if="alertMessage" class="alert alert-danger" role="alert">
      {{ alertMessage }}
    </div>

    <div class="form-group mb-4">
      <label for="flippedWords">Flipped Words</label>
      <textarea
        v-model="flippedWords"
        class="form-control"
        id="flippedWords"
        rows="4"
        readonly
      ></textarea>
    </div>

    <div class="mb-4">
      <button
        ref="copyBtn"
        @click="copyText"
        class="btn btn-secondary w-100"
        data-bs-toggle="tooltip"
        title="Copy Output"
      >
        Copy Text
      </button>
    </div>

    <div>
      <WordFlipperGeneratorFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import WordFlipperGeneratorFAQ from '../FAQs/wordFlipperGeneratorFAQ.vue';

export default {
  components: {
    BreadCrumb,
    WordFlipperGeneratorFAQ
  },
  data() {
    return {
      textInput: "",
      flipStyle: "upside-down",
      flippedWords: "",
      alertMessage: "",
    };
  },
  mounted() {
    this.setStyleFromHash();
    window.addEventListener("hashchange", this.setStyleFromHash);
  },
  beforeUnmount() {
    window.removeEventListener("hashchange", this.setStyleFromHash);
  },
  methods: {
    flipWords() {
      if (!this.textInput.trim()) {
        this.alertMessage = "Please enter some text.";
        return;
      }
      this.alertMessage = "";

      const transformations = this.starter(this.textInput);
      this.flippedWords = transformations[this.flipStyle];

      window.location.hash = this.flipStyle;
    },
    reset() {
      this.textInput = "";
      this.flippedWords = "";
      this.alertMessage = "";
    },
    copyText() {
      navigator.clipboard.writeText(this.flippedWords).then(() => {
        this.$refs.copyBtn.innerText = "Copied!";
        setTimeout(() => {
          this.$refs.copyBtn.innerText = "Copy Text";
        }, 2000);
      });
    },
    setStyleFromHash() {
      const hashValue = window.location.hash.substring(1);
      if (["upside-down", "flip-vertically", "flip-horizontally", "reverse-text"].includes(hashValue)) {
        this.flipStyle = hashValue;
      }
    },
    starter(textin) {
      textin = this.stripAccents(textin);
      const arrayFlip = {",":"\u02cb","!":"\u00a1","?":"\u01be",".":"\u0387","'":"\u02cc","\"":"\u02cc\u02cc","*":"\u2093","&":"\u214b","1":"\u0196","2":"\u1615","4":"\u0aa4","5":"\u0ae8","6":"\u0b67","7":"\u230b","9":"\u10db","A":"\u2c6f","a":"\u0251","b":"p","d":"q","e":"\u050d","F":"\u15b6","f":"\u027b","G":"\u1613","g":"\u10db","h":"\u03bc","i":"\u1d09","J":"\u1489","j":"\u1489","k":"\u0138","L":"\u0393","l":"\u027c","M":"W","m":"w","N":"\u0418","n":"u","P":"b","p":"b","Q":"\u2940","q":"d","R":"\u1589","r":"\u0281","S":"\u01a7","s":"\u01a8","T":"\ua4d5","t":"\u03dd","U":"\ua4f5","u":"n","V":"\u039b","v":"\u028c","W":"M","w":"\u028d","Y":"\u2144","y":"\u03bb"};
      const arrayMirror = {",":"\u02ce","?":"\u2e2e","(":")",")":"(","1":"\u16da","2":"\ud801\udc55","3":"\u0510","4":"\ud800\ude80","5":"\u091f","6":"\u10db","7":"\u0662","9":"\u0b67","a":"\u0252","B":"\ua4ed","b":"d","C":"\u0186","c":"\u0254","D":"\ua4f7","d":"b","E":"\u018e","e":"\u0258","F":"\u15b7","f":"\u0287","G":"\u04d8","g":"\u03f1","h":"\u029c","J":"\u10b1","j":"\u012f","K":"\ua4d8","k":"\u029e","L":"\u2143","N":"\u0418","P":"\u154b","p":"q","Q":"\u03d8","q":"p","R":"\u042f","r":"\u044f","S":"\u01a7","s":"\u01a8","t":"\u0248","u":"\u03c5","y":"\u10f8"};
      const arrayMirrorFlip = {",":"\u02bb","!":"\u00a1","?":"\u00bf",".":"\u0387","'":"\u02cc","\"":"\u02cc\u02cc","*":"\u2093","&":"\u214b","1":"\u0196","2":"\u0547","3":"\u0190","4":"h","5":"\ud801\udc55","6":"9","7":"L","9":"6","A":"\u2c6f","a":"\u0250","B":"\ua4ed","b":"q","C":"\ua4db","c":"\u0254","D":"\ua4f7","d":"p","E":"\u018e","e":"\u01dd","F":"\ua4de","f":"\u025f","G":"\ua4e8","g":"\u0253","h":"\u0265","i":"\u1d09","J":"\u017f","j":"\ua4e9","K":"\ua4d8","k":"\u029e","L":"\ua4f6","l":"\u0285","M":"W","m":"\u026f","n":"u","P":"\ua4d2","p":"d","Q":"\u1ff8","q":"b","R":"\ua4e4","r":"\u0279","T":"\ua4d5","t":"\u0287","U":"\ua4f5","u":"n","V":"\ua4e5","v":"\u028c","W":"M","w":"\u028d","Y":"\u2144","y":"\u028e"};

      const results = {
        'upside-down': this.reverseN(this.strtr(textin, arrayFlip).trim()),
        'flip-horizontally': this.reverseN(this.reverse(this.strtr(textin, arrayMirror).trim())),
        'flip-vertically': this.reverse(this.strtr(textin, arrayMirrorFlip).trim()),
        'reverse-text': this.reverseN(this.reverse(textin.trim())),
      };

      return results;
    },
    stripAccents(str) {
      const accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
      ];
      const noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];

      for (let i = 0; i < accent.length; i++) {
        str = str.replace(accent[i], noaccent[i]);
      }

      return str;
    },
    strtr(s, p) {
      let n = '';
      for (let i = 0; i < s.length; i++) {
        const c = s.charAt(i);
        n += c in p ? p[c] : c;
      }
      return n;
    },
    reverseN(str) {
      return str.split("\n").reverse().join("\n");
    },
    reverse(str) {
      return str.split("").reverse().join("");
    },
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 85vh;
  margin: 0 auto;
}
label, textarea {
  font-size: 16px;
}
</style>