<template>
    <BreadCrumb PageTitle="Square Root Calculator" />
  
    <div class="custom-container mt-5">
      <h1 class="text-center">Square Root Calculator</h1>
      <p class="fs-6 text-center mb-4">
        Calculate the square root of a number. Enter the number to find its square root, including complex results for negative numbers.
      </p>
  
      <!-- Input and Calculation Section -->
      <div class="card mb-4">
        <div class="card-body">
          <!-- Input for Number -->
          <div class="mb-3">
            <label for="number" class="form-label">Enter a Number:</label>
            <input
              type="text"
              id="number"
              v-model="number"
              class="form-control"
              placeholder="e.g., 25 or -16"
            />
          </div>
  
          <!-- Calculation Buttons -->
          <div class="mb-3 row">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <button @click="calculateSquareRoot" class="btn btn-primary w-100">Calculate</button>
            </div>
            <div class="col-12 col-md-6">
              <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
            </div>
          </div>
        </div>
  
        <!-- Display Result Section -->
        <div class="card-footer">
          <h4>Result:</h4>
          <div v-if="result !== null">
            <p class="fs-5">Number: {{ result.number }}</p>
            <p class="fs-5">Square Root: {{ result.squareRoot }}</p>
            <p class="fs-5">{{ result.explanation }}</p>
          </div>
  
          <!-- Error message -->
          <p v-if="error" class="text-danger">{{ error }}</p>
        </div>
      </div>
  
      <!-- Instructions Section -->
      <div class="alert alert-info" role="alert">
        <h5 class="alert-heading">How to Use:</h5>
        <ul class="fs-6">
          <li>Enter the number you wish to find the square root of.</li>
          <li>Click "Calculate" to compute the square root.</li>
          <li>The result will display the number, its square root, and an explanation.</li>
          <li>Click "Clear" to reset the input field and result.</li>
        </ul>
  
        <h5 class="alert-heading">Understanding Square Roots:</h5>
        <p class="fs-6">
          The square root of a number \( n \) is a number \( x \) such that \( x^2 = n \). For positive real numbers, there are two square roots: one positive and one negative. For negative numbers, the square root is a complex number involving the imaginary unit \( i \), where \( i^2 = -1 \).
        </p>
  
        <h5 class="alert-heading">Formula:</h5>
        <div>
          \[
          \sqrt{n} =
          \begin{cases}
            \text{Positive and Negative Real Roots} \\
            \text{Complex Roots}
          \end{cases}
          \]

          \[
          \sqrt{n} =
          \begin{cases}
          \text{if } n \geq 0 \\
          \text{if } n &lt; 0
          \end{cases}
          \]
        </div>
  
        <h5 class="alert-heading">Example Calculations:</h5>
        <ul class="fs-6">
          <li>
            Square root of 25:
            <ul>
              <li>\( \sqrt{25} = \pm5 \)</li>
            </ul>
          </li>
          <li>
            Square root of 2:
            <ul>
              <li>\( \sqrt{2} \approx \pm1.4142135 \)</li>
            </ul>
          </li>
          <li>
            Square root of -16:
            <ul>
              <li>\( \sqrt{-16} = \pm4i \)</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  
  export default {
    components: {
      BreadCrumb,
    },
    setup() {
      // Input field and result state
      const number = ref("");
      const result = ref(null);
      const error = ref("");
  
      // Function to calculate the square root
      const calculateSquareRoot = () => {
        error.value = "";
        result.value = null;
  
        try {
          // Ensure value is not empty
          if (number.value === "") {
            throw new Error("Please enter a number.");
          }
  
          // Parse the input value
          const num = parseFloat(number.value);
  
          if (isNaN(num)) {
            throw new Error("Please enter a valid numeric value.");
          }
  
          let squareRoot, explanation;
  
          if (num > 0) {
            // Positive number: two real roots
            const sqrtNum = Math.sqrt(num);
            squareRoot = `±${sqrtNum.toFixed(6)}`;
            explanation = `The number ${num} is positive, so it has two real square roots: ±${sqrtNum.toFixed(6)}.`;
          } else if (num === 0) {
            // Zero: one root
            squareRoot = "0";
            explanation = "The square root of 0 is 0.";
          } else {
            // Negative number: complex roots
            const sqrtNum = Math.sqrt(-num);
            squareRoot = `±${sqrtNum.toFixed(6)}i`;
            explanation = `The number ${num} is negative, so it has two complex square roots: ±${sqrtNum.toFixed(6)}i.`;
          }
  
          result.value = {
            number: num,
            squareRoot: squareRoot,
            explanation: explanation,
          };
        } catch (e) {
          error.value = e.message;
        }
      };
  
      // Function to clear the input field and result
      const clearValues = () => {
        number.value = "";
        result.value = null;
        error.value = "";
      };
  
      return {
        number,
        result,
        error,
        calculateSquareRoot,
        clearValues,
      };
    },
  };
  </script>
  
  <style scoped>
  .custom-container {
    max-width: 70vh;
    margin: 0 auto;
    padding: 0 5px;
  }
  
  .alert {
    margin-bottom: 20px;
  }
  
  .dark h5 {
    color: #2b3035 !important;
  }
  
  strong {
    font-weight: bold;
  }
  
  .fs-5 {
    font-size: 1.25rem; /* Adjusted font size for clarity */
  }
  
  @media (max-width: 767px) {
    .col-12 {
      margin-bottom: 0.5rem;
    }
  }
  </style>
  