<template>
  <BreadCrumb PageTitle="Exponents Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Exponents Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter a base number and an exponent to calculate the power. The calculator will handle positive, negative, and fractional exponents.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Base Number -->
        <div class="mb-3">
          <label for="baseNumber" class="form-label">Base Number:</label>
          <input
            type="text"
            id="baseNumber"
            v-model="baseNumber"
            class="form-control"
            placeholder="e.g., 2, -3, 0.5"
          />
        </div>

        <!-- Input for Exponent -->
        <div class="mb-3">
          <label for="exponentValue" class="form-label">Exponent:</label>
          <input
            type="text"
            id="exponentValue"
            v-model="exponentValue"
            class="form-control"
            placeholder="e.g., 3, -2, 0.5"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateExponent" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Base: {{ result.base }}</p>
          <p class="fs-5">Exponent: {{ result.exponent }}</p>
          <p class="fs-5">Calculation: {{ result.calculation }}</p>
          <p class="fs-5">Final Result: {{ result.finalResult }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the base number in the first field (e.g., 2, -3, 0.5).</li>
        <li>Enter the exponent in the second field (e.g., 3, -2, 0.5).</li>
        <li>Click "Calculate" to compute the power.</li>
        <li>The results will show the base number, exponent, calculation, and final result.</li>
        <li>Click "Clear" to reset the input fields and results.</li>
      </ul>

      <h5 class="alert-heading">Formula for Exponents:</h5>
      <div class="fs-6">
        The general formula for exponents is:
      </div>
      <div>
        \[
        b^e = \underbrace{b \times b \times \cdots \times b}_{\text{e times}}
        \]
      </div>
      <p class="fs-6">
        where \(b\) is the base and \(e\) is the exponent. If the exponent is negative, the formula becomes:
      </p>
      <div>
        \[
        b^{-e} = \frac{1}{b^e}
        \]
      </div>
      <p class="fs-6">
        If the exponent is fractional, it represents a root:
      </p>
      <div>
        \[
        b^{\frac{1}{e}} = \sqrt[e]{b}
        \]
      </div>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For base 2 and exponent 3:<br />
          \( 2^3 = 2 \times 2 \times 2 = 8 \)
        </li>
        <li>
          For base 5 and exponent -2:<br />
          \( 5^{-2} = \frac{1}{5^2} = \frac{1}{25} = 0.04 \)
        </li>
        <li>
          For base 9 and exponent 0.5:<br />
          \( 9^{0.5} = \sqrt{9} = 3 \)
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const baseNumber = ref("");
    const exponentValue = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate exponents
    const calculateExponent = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the input values
        const base = parseFloat(baseNumber.value.trim());
        const exponent = parseFloat(exponentValue.value.trim());

        if (isNaN(base) || isNaN(exponent)) {
          throw new Error("Please enter valid numeric values.");
        }

        // Calculate the result and explanation
        let finalResult;
        let calculation;

        if (exponent === 0) {
          // Special case for exponent 0
          finalResult = 1;
          calculation = `${base}^0 = 1 (Any non-zero number raised to the power of 0 is 1)`;
        } else if (exponent > 0) {
          finalResult = Math.pow(base, exponent);
          calculation = `${base} raised to the power of ${exponent} = ${finalResult}`;
        } else if (exponent < 0) {
          // Negative exponent case
          finalResult = Math.pow(base, exponent);
          calculation = `1 / (${base}^${Math.abs(exponent)}) = ${finalResult}`;
        }

        result.value = {
          base: base,
          exponent: exponent,
          calculation: calculation,
          finalResult: finalResult.toFixed(6),
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      baseNumber.value = "";
      exponentValue.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      baseNumber,
      exponentValue,
      result,
      error,
      calculateExponent,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
