<template>
  <BreadCrumb PageTitle="Convolution Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Convolution Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two sequences of numbers, separated by commas or spaces, to calculate their convolution.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for First Sequence -->
        <div class="mb-3">
          <label for="sequence1" class="form-label">Sequence 1 (f[n]):</label>
          <input
            type="text"
            id="sequence1"
            v-model="sequence1"
            class="form-control"
            placeholder="e.g., 1, 2, 3"
          />
        </div>

        <!-- Input for Second Sequence -->
        <div class="mb-3">
          <label for="sequence2" class="form-label">Sequence 2 (g[n]):</label>
          <input
            type="text"
            id="sequence2"
            v-model="sequence2"
            class="form-control"
            placeholder="e.g., 4, 5, 6"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateConvolution" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <p v-if="result !== null" class="fs-5">
          Convolution: [ {{ result.join(', ') }} ]
        </p>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

<!-- Instructions Section -->
<div class="alert alert-info" role="alert">
  <h5 class="alert-heading">How to Use:</h5>
  <ul class="fs-6">
    <li>Enter two sequences of numbers separated by commas or spaces (e.g., 1, 2, 3 or 4 5 6).</li>
    <li>Click "Calculate" to compute the convolution of the two sequences.</li>
    <li>The result will be displayed as a sequence representing the convolution.</li>
    <li>Click "Clear" to reset the input fields and results.</li>
  </ul>

  <h5 class="alert-heading">Formula for Convolution:</h5>
  <div class="fs-6">
    The convolution of two discrete sequences \( f[n] \) and \( g[n] \) is defined as:
  </div>
  <div>
    \[
    (f * g)[n] = \sum_{m=-\infty}^{\infty} f[m] \cdot g[n - m]
    \]
  </div>
  <p class="fs-6">
    where the summation is over all integers \( m \). This operation combines two sequences to produce a third sequence that represents the overlap and interaction between \( f \) and \( g \).
  </p>

  <h5 class="alert-heading">Example Calculation:</h5>
  <p class="fs-6">
    Consider the two sequences:
  </p>
  <ul class="fs-6">
    <li>
      \( f[n] = \{1, 2, 3\} \)
    </li>
    <li>
      \( g[n] = \{4, 5, 6\} \)
    </li>
  </ul>
  <p class="fs-6">
    The convolution of \( f[n] \) and \( g[n] \) is calculated as follows:
  </p>
  <ul class="fs-6">
    <li>At \( n = 0 \): \((1 \cdot 4) = 4\)</li>
    <li>At \( n = 1 \): \((1 \cdot 5) + (2 \cdot 4) = 5 + 8 = 13\)</li>
    <li>At \( n = 2 \): \((1 \cdot 6) + (2 \cdot 5) + (3 \cdot 4) = 6 + 10 + 12 = 28\)</li>
    <li>At \( n = 3 \): \((2 \cdot 6) + (3 \cdot 5) = 12 + 15 = 27\)</li>
    <li>At \( n = 4 \): \((3 \cdot 6) = 18\)</li>
  </ul>
  <p class="fs-6">
    So, the result of the convolution is:
  </p>
  <p class="fs-6">
    \( y[n] = \{4, 13, 28, 27, 18\} \)
  </p>

  <h5 class="alert-heading">Additional Information:</h5>
  <ul class="fs-6">
    <li>
      <strong>Properties of Convolution:</strong>
      <ul>
        <li><strong>Commutativity:</strong> \( f * g = g * f \)</li>
        <li><strong>Associativity:</strong> \( (f * g) * h = f * (g * h) \)</li>
        <li><strong>Distributivity:</strong> \( f * (g + h) = f * g + f * h \)</li>
      </ul>
    </li>
    <li>
      <strong>Applications of Convolution:</strong>
      Convolution is used in various fields, such as signal processing, image processing, and control systems, to analyze and manipulate signals and images.
    </li>
    <li>
      <strong>Handling Sequences of Different Lengths:</strong>
      If the input sequences have different lengths, the shorter sequence will be zero-padded to match the length of the longer sequence during convolution.
    </li>
  </ul>
</div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb
  },
  data(){
    return {
      latexStr:""
    }
  },
  setup() {
    // Input fields and result state
    const sequence1 = ref("");
    const sequence2 = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate convolution of two sequences
    const calculateConvolution = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the sequences into arrays of numbers
        const f = parseSequence(sequence1.value);
        const g = parseSequence(sequence2.value);

        if (!f.length || !g.length) throw new Error("Please enter valid sequences.");

        // Perform convolution
        const convolutionResult = performConvolution(f, g);
        result.value = convolutionResult;
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to parse sequence string into an array of numbers
    const parseSequence = (sequence) => {
      return sequence
        .split(/[\s,]+/) // Split by spaces or commas
        .map((num) => parseFloat(num.trim())) // Convert to float
        .filter((num) => !isNaN(num)); // Filter out non-numeric values
    };

    // Function to perform convolution of two sequences
    const performConvolution = (f, g) => {
      const n = f.length + g.length - 1;
      const result = Array(n).fill(0);

      for (let i = 0; i < f.length; i++) {
        for (let j = 0; j < g.length; j++) {
          result[i + j] += f[i] * g[j];
        }
      }
      return result;
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      sequence1.value = "";
      sequence2.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      sequence1,
      sequence2,
      result,
      error,
      calculateConvolution,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
