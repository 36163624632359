<template>
    <div class="card mb-25 mt-50 border-0 rounded-0 bg-white letter-spacing faq-accordion-card col-lg-8">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mb-3"><h3>FAQs on Color Picker Generator</h3></div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseOne"
                                aria-expanded="true"
                                aria-controls="faqCollapseOne">
                                <h5>What is the Color Picker Generator?</h5>
                            </button>
                            <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        The Color Picker Generator is a web tool that allows you to pick colors from an uploaded image or a loaded image URL. You can save the picked colors and view detailed information like RGB, HEX, HSL, and CMYK values.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseTwo"
                                aria-expanded="true"
                                aria-controls="faqCollapseTwo">
                                <h5>How do I upload an image?</h5>
                            </button>
                            <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        Click on the "Upload Image" button and select an image file from your device. The image will be displayed on the canvas.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseThree"
                                aria-expanded="true"
                                aria-controls="faqCollapseThree">
                                <h5>How can I load an image from a URL?</h5>
                            </button>
                            <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        Enter the URL of the image in the "Image URL" field and click the "Load Image from URL" button. The image will be displayed on the canvas.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseFour"
                                aria-expanded="true"
                                aria-controls="faqCollapseFour">
                                <h5>How do I pick a color from the image?</h5>
                            </button>
                            <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        Move your mouse pointer over the image on the canvas. A zoom popup will appear, showing the color under the pointer. Click to select the color.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseFive"
                                aria-expanded="true"
                                aria-controls="faqCollapseFive">
                                <h5>How can I view detailed information about a selected color?</h5>
                            </button>
                            <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        Once you've selected a color, detailed information such as RGB, HEX, HSL, and CMYK values, as well as the color name, will be displayed in the "Color Info" section.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseSix"
                                aria-expanded="true"
                                aria-controls="faqCollapseSix">
                                <h5>How do I copy color codes to the clipboard?</h5>
                            </button>
                            <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        Next to each color code (RGB, HEX, HSL, CMYK), there is a clipboard icon. Click on it to copy the respective color code to your clipboard.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseSeven"
                                aria-expanded="true"
                                aria-controls="faqCollapseSeven">
                                <h5>How can I view my color history?</h5>
                            </button>
                            <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        Every color you click on is saved in the "Color History" section. You can revisit any of these colors by clicking on them in this section.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseEight"
                                aria-expanded="true"
                                aria-controls="faqCollapseEight">
                                <h5>What is the Zoom Popup and how do I use it?</h5>
                            </button>
                            <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        The Zoom Popup appears when you hover over the canvas. It shows a magnified view of the area around your pointer, helping you pick colors more accurately.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseNine"
                                aria-expanded="true"
                                aria-controls="faqCollapseNine">
                                <h5>How can I generate random colors?</h5>
                            </button>
                            <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        Click the "Generate New Random Color Scheme" button to populate the "Related Colors" section with random colors.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseTen"
                                aria-expanded="true"
                                aria-controls="faqCollapseTen">
                                <h5>How does the color picker work on mobile devices?</h5>
                            </button>
                            <div id="faqCollapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        The color picker is optimized for touch events. You can tap on the canvas to pick a color, and the Zoom Popup will appear to help you select colors more accurately.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item rounded-0 border-0">
                            <button
                                class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#faqCollapseEleven"
                                aria-expanded="true"
                                aria-controls="faqCollapseEleven">
                                <h5>How can I save a color for later use?</h5>
                            </button>
                            <div id="faqCollapseEleven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body pb-0">
                                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                        Whenever you click on the canvas to pick a color, that color is automatically saved in the "Color History" section. You can click on any of the saved colors to view its details. To remove a saved color, click the delete button (X) next to it.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ColorPickerGeneratorFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  