<template>
  <BreadCrumb PageTitle="Letter Frequency Counter" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Letter Frequency Counter</h1>
    <p class="fs-6 text-center mb-4">Analyze text for character frequencies, including letters, numbers, and punctuation. Gain insights and learn fun facts with the Letter Frequency Counter.</p>

    <!-- Alert for validation message -->
    <div v-if="showAlert" id="alert" class="alert alert-danger" role="alert">{{ alertMessage }}</div>
    
    <div class="form-group mb-4">
        <label for="textInput">Enter Texts</label>
        <textarea class="form-control" id="textInput" v-model="textInput" rows="4"></textarea>
    </div>

    <div class="form-group mb-4">
        <label>Character Types:</label>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="checkLetters" v-model="checkLetters" checked>
            <label class="form-check-label" for="checkLetters">Letters</label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="checkNumbers" v-model="checkNumbers">
            <label class="form-check-label" for="checkNumbers">Numbers</label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="checkPunctuation" v-model="checkPunctuation">
            <label class="form-check-label" for="checkPunctuation">Punctuation</label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="checkWhitespaces" v-model="checkWhitespaces">
            <label class="form-check-label" for="checkWhitespaces">Whitespaces</label>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-6 mb-4"><button @click="countFrequency" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Count Letter Frequency">Letter Frequency</button></div>
        <div class="col-md-6 mb-2"><button @click="reset" class="btn btn-danger w-100" data-bs-toggle="tooltip" title="Reset">Reset</button></div>
    </div>

    <div class="form-group mb-3">
        <label for="frequencyOutput">Frequency Result</label>
        <textarea class="form-control" id="frequencyOutput" v-model="frequencyOutput" :rows="outputRows" readonly></textarea>
    </div>

    <div>
      <LetterFrequencyCounterFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import LetterFrequencyCounterFAQ from '../FAQs/letterFrequencyCounterFAQ.vue';

export default {
  components: {
    BreadCrumb,
    LetterFrequencyCounterFAQ
  },
  data() {
    return {
      textInput: '',
      frequencyOutput: '',
      checkLetters: true,
      checkNumbers: false,
      checkPunctuation: false,
      checkWhitespaces: false,
      showAlert: false,
      alertMessage: ''
    };
  },
  computed: {
    outputRows() {
      return this.frequencyOutput.split('\n').length || 4;
    }
  },
  methods: {
    countFrequency() {
      if (this.textInput === '') {
        this.alertMessage = 'Please enter some text!';
        this.showAlert = true;
        return;
      }
      this.showAlert = false;
      const text = this.textInput;
      const frequency = {};

      for (const char of text) {
        if (this.checkLetters && /[a-zA-Z]/.test(char)) {
          frequency[char] = (frequency[char] || 0) + 1;
        } else if (this.checkNumbers && /[0-9]/.test(char)) {
          frequency[char] = (frequency[char] || 0) + 1;
        } else if (this.checkPunctuation && /[^\w\s]/.test(char)) {
          frequency[char] = (frequency[char] || 0) + 1;
        } else if (this.checkWhitespaces && /\s/.test(char)) {
          frequency[char] = (frequency[char] || 0) + 1;
        }
      }

      const sortedFrequency = Object.entries(frequency).sort((a, b) => b[1] - a[1]);
      this.frequencyOutput = sortedFrequency.map(([char, count]) => `${char}: ${count}`).join('\n');
    },
    reset() {
      this.textInput = '';
      this.frequencyOutput = '';
      this.checkLetters = true;
      this.checkNumbers = false;
      this.checkPunctuation = false;
      this.checkWhitespaces = false;
      this.showAlert = false;
      this.alertMessage = '';
    }
  }
};
</script>

<style scoped>
.custom-container {
  max-width: 85vh;
  margin: auto;
}
label, textarea {
  font-size: 16px;
}
</style>