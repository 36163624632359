<template>
  <BreadCrumb PageTitle="YAML Validator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">YAML Validator</h1>
    <p class="fs-6 text-center mb-4">
      Easily validate YAML code for syntax errors. Ensure your configurations are correct with our user-friendly tool for seamless integration and error-free deployment.
    </p>
    <div class="form-group mb-4">
      <label for="yamlInput">Enter Your YAML Texts</label>
      <textarea class="form-control" id="yamlInput" v-model="yamlText" rows="8"></textarea>
    </div>
    <div v-if="error" class="form-group mb-4">
      <label for="errorOutput">Validation Error</label>
      <textarea class="form-control" id="errorOutput" rows="3" readonly v-bind:value="error"></textarea>
    </div>
    <div v-else-if="validationMessage" class="form-group mb-4">
      <label for="successOutput">Validation Result</label>
      <textarea class="form-control" id="successOutput" rows="3" readonly v-bind:value="validationMessage"></textarea>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4">
        <button @click="validateYAML" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Validate YAML">Validate</button>
      </div>
      <div class="col-md-6 mb-2">
        <button @click="reset" class="btn btn-danger w-100" data-bs-toggle="tooltip" title="Reset">Reset</button>
      </div>
    </div>
    <div>
      <YAMLValidatorFAQ />
    </div>
  </div>
</template>

<script>
import yaml from 'js-yaml';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import YAMLValidatorFAQ from '../FAQs/yamlValidatorFAQ.vue';

export default {
  components: {
    BreadCrumb,
    YAMLValidatorFAQ
  },
  data() {
    return {
      yamlText: '',
      error: '',
      validationMessage: ''
    };
  },
  methods: {
    validateYAML() {
      try {
        yaml.load(this.yamlText);
        this.error = '';
        this.validationMessage = 'YAML is valid';
      } catch (e) {
        this.error = e.message;
        this.validationMessage = '';
      }
    },
    reset() {
      this.yamlText = '';
      this.error = '';
      this.validationMessage = '';
    }
  }
};
</script>

<style scoped>
.custom-container {
  max-width: 85vh;
  margin: auto;
}
label, textarea {
  font-size: 16px;
}
</style>