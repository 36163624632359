<template>
  <BreadCrumb PageTitle="Cosine Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Cosine Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter an angle and select the unit (Degrees or Radians) to calculate its cosine value.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Angle -->
        <div class="mb-3">
          <label for="angle" class="form-label">Angle:</label>
          <input
            type="text"
            id="angle"
            v-model="angle"
            class="form-control"
            placeholder="e.g., 45"
          />
        </div>

        <!-- Dropdown for Unit Selection -->
        <div class="mb-3">
          <label for="unit" class="form-label">Unit:</label>
          <select id="unit" v-model="unit" class="form-select">
            <option value="deg">Degrees</option>
            <option value="rad">Radians</option>
          </select>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateCosine" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <p v-if="result !== null" class="fs-5">
          Cosine: {{ result }}
        </p>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Cosine Table Section -->
    <div class="card">
      <div class="card-body">
        <h5 class="card-title text-center">Cosine Table</h5>
        <div class="table-responsive">
          <table class="table table-bordered table-striped text-center">
            <thead>
              <tr>
                <th>Angle (Degrees)</th>
                <th>Angle (Radians)</th>
                <th>Cosine</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in cosineTable" :key="item.degrees">
                <td>{{ item.degrees }}°</td>
                <td>{{ item.radians.toFixed(4) }}</td>
                <td>{{ item.cosine.toFixed(6) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info mt-4" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter an angle in the input field (e.g., 45).</li>
        <li>Select the unit (Degrees or Radians).</li>
        <li>Click "Calculate" to compute the cosine of the angle.</li>
        <li>The result will be displayed below the input field.</li>
        <li>Click "Clear" to reset the input field and result.</li>
      </ul>

      <h5 class="alert-heading">Formula for Cosine:</h5>
      <div class="fs-6">
        The cosine of an angle \( \theta \) is calculated as:
      </div>
      <div>
        \[
        \cos(\theta) = \frac{\text{Adjacent}}{\text{Hypotenuse}}
        \]
      </div>
      <p class="fs-6">
        where \( \theta \) is the angle in degrees or radians. In trigonometric terms, it represents the ratio of the adjacent side to the hypotenuse in a right-angled triangle.
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <p class="fs-6">
        Consider the angle \( \theta = 60^\circ \):
      </p>
      <ul class="fs-6">
        <li>
          \(\cos(60^\circ) = 0.5\)
        </li>
      </ul>
      <p class="fs-6">
        So, the cosine of \( 60^\circ \) is \( 0.5 \).
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const angle = ref("");
    const unit = ref("deg");
    const result = ref(null);
    const error = ref("");

    // Cosine table data
    const cosineTable = ref([
      { degrees: 0, radians: 0, cosine: Math.cos(0) },
      { degrees: 30, radians: Math.PI / 6, cosine: Math.cos(Math.PI / 6) },
      { degrees: 45, radians: Math.PI / 4, cosine: Math.cos(Math.PI / 4) },
      { degrees: 60, radians: Math.PI / 3, cosine: Math.cos(Math.PI / 3) },
      { degrees: 90, radians: Math.PI / 2, cosine: Math.cos(Math.PI / 2) },
      { degrees: 120, radians: (2 * Math.PI) / 3, cosine: Math.cos((2 * Math.PI) / 3) },
      { degrees: 135, radians: (3 * Math.PI) / 4, cosine: Math.cos((3 * Math.PI) / 4) },
      { degrees: 150, radians: (5 * Math.PI) / 6, cosine: Math.cos((5 * Math.PI) / 6) },
      { degrees: 180, radians: Math.PI, cosine: Math.cos(Math.PI) },
    ]);

    // Function to calculate cosine of an angle
    const calculateCosine = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the angle into a number
        const angleValue = parseFloat(angle.value.trim());
        if (isNaN(angleValue)) throw new Error("Please enter a valid angle.");

        // Calculate cosine based on the selected unit
        const radians = unit.value === "deg" ? (angleValue * Math.PI) / 180 : angleValue;
        result.value = Math.cos(radians).toFixed(6); // Rounded to 6 decimal places
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      angle.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      angle,
      unit,
      result,
      error,
      calculateCosine,
      clearValues,
      cosineTable,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
