<template>
  <BreadCrumb PageTitle="Subtraction Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Subtraction Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Subtract two numbers and get the result instantly. Enter the minuend (the number to subtract from) and the subtrahend (the number to subtract) to perform the subtraction.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Minuend -->
        <div class="mb-3">
          <label for="minuend" class="form-label">Minuend (Number to subtract from):</label>
          <input
            type="number"
            id="minuend"
            v-model="minuend"
            class="form-control"
            placeholder="e.g., 15"
          />
        </div>

        <!-- Input for Subtrahend -->
        <div class="mb-3">
          <label for="subtrahend" class="form-label">Subtrahend (Number to subtract):</label>
          <input
            type="number"
            id="subtrahend"
            v-model="subtrahend"
            class="form-control"
            placeholder="e.g., 8"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateSubtraction" class="btn btn-primary w-100">Subtract</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">{{ minuend }} - {{ subtrahend }} = {{ result }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the minuend (the number you want to subtract from) in the first input field.</li>
        <li>Enter the subtrahend (the number you want to subtract) in the second input field.</li>
        <li>Click "Subtract" to calculate the difference between the two numbers.</li>
        <li>The result will display the subtraction operation and the final result.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Understanding Subtraction:</h5>
      <p class="fs-6">
        Subtraction is one of the basic arithmetic operations. It represents the operation of removing objects from a collection. The result of subtracting one number from another is called the difference.
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <ul class="fs-6">
        <li>
          Subtract 8 from 15:
          <ul>
            <li>Calculation: \( 15 - 8 = 7 \)</li>
            <li>Result: 7</li>
          </ul>
        </li>
        <li>
          Subtract -5 from 10:
          <ul>
            <li>Calculation: \( 10 - (-5) = 15 \)</li>
            <li>Result: 15</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const minuend = ref(null);
    const subtrahend = ref(null);
    const result = ref(null);
    const error = ref("");

    // Function to calculate the subtraction
    const calculateSubtraction = () => {
      error.value = "";
      result.value = null;

      try {
        // Ensure values are not null or empty
        if (minuend.value === null || minuend.value === "") {
          throw new Error("Please enter a value for the minuend.");
        }
        if (subtrahend.value === null || subtrahend.value === "") {
          throw new Error("Please enter a value for the subtrahend.");
        }

        // Parse the input values
        const a = parseFloat(minuend.value);
        const b = parseFloat(subtrahend.value);

        if (isNaN(a) || isNaN(b)) {
          throw new Error("Please enter valid numeric values.");
        }

        // Calculate the difference
        result.value = a - b;
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      minuend.value = null;
      subtrahend.value = null;
      result.value = null;
      error.value = "";
    };

    return {
      minuend,
      subtrahend,
      result,
      error,
      calculateSubtraction,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
