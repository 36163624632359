<template>
  <BreadCrumb PageTitle="Md5 Hash Generator Calculator" />

	<div class="custom-container mt-5">
	  <h1 class="text-center">MD5 Hash Generator</h1>
      <p class="fs-6 text-center mb-4">Quickly generate MD5 hashes for your strings using our efficient and user-friendly tool.</p>
	  <div class="mb-3">
		<label for="inputTextString" class="form-label">Enter text string:</label>
		<textarea v-model="inputText" id="inputTextString" class="form-control"></textarea>
	  </div>
	  <div class="row mb-3">
		<div class="col-md-6 mb-2">
		  <button @click="generateHash" class="btn btn-primary w-100">Generate Hash</button>
		</div>
		<div class="col-md-6 mb-2">
		  <button @click="clearText" class="btn btn-danger w-100">Clear Text</button>
		</div>
	  </div>
	  <div class="mb-3">
		<label for="generatedHash" class="form-label">Generated MD5 Hash:</label>
		<textarea v-model="generatedHash" id="generatedHash" class="form-control" rows="2" readonly></textarea>
	  </div>
	  <div class="mb-3">
		<button @click="copyHash" class="btn btn-secondary w-100">{{ copyButtonText }}</button>
	  </div>
	  <div class="mb-3 d-flex justify-content-between">
		<label class="form-label">Hash Size: 40 bytes (320 bits)</label>
		<label class="form-label">Hash Length: {{ hashLength }} characters</label>
	  </div>
  
	  <!-- FAQ Section -->
	  <div class="mt-50 mb-50">
		<h2 class="text-center mb-4">Frequently Asked Questions</h2>
		<div class="accordion" id="faqAccordion">
		  <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
			<h2 class="accordion-header" :id="'heading' + index">
			  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
				{{ faq.question }}
			  </button>
			</h2>
			<div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#faqAccordion">
			  <div class="accordion-body" v-html="faq.answer"></div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </template>
  
  <script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

  import { ref } from 'vue';
  import cryptoApi from 'crypto-api/src/crypto-api';
  import 'crypto-api/src/hasher/md4';
  
  export default {
	
  components: { BreadCrumb },
name: 'MD5HashGenerator',
	setup() {
	  const inputText = ref('');
	  const generatedHash = ref('');
	  const hashLength = ref(0);
	  const copyButtonText = ref('Copy Hash');
  
	  const generateHash = () => {
		try {
		  const hasher = cryptoApi.getHasher('md5');
		  hasher.update(inputText.value);
		  const hash = hasher.finalize();
		  const hashHex = cryptoApi.encoder.toHex(hash).toUpperCase();
		  generatedHash.value = hashHex;
		  hashLength.value = hashHex.length;
		} catch (error) {
		  console.error('Hash generation failed:', error);
		  generatedHash.value = `Error generating hash: ${error.message}`;
		  hashLength.value = 0;
		}
	  };
  
	  const clearText = () => {
		inputText.value = '';
		generatedHash.value = '';
		hashLength.value = 0;
	  };
  
	  const copyHash = () => {
		navigator.clipboard.writeText(generatedHash.value)
			.then(() => {
			copyButtonText.value = 'Hash Copied';
			setTimeout(() => {
				copyButtonText.value = 'Copy Hash';
			}, 2000);
			})
			.catch(err => console.error('Failed to copy: ', err));
	  };
  
			const faqs = [
				{
					question: "What is an MD5 hash?",
					answer: `MD5 (Message Digest Algorithm 5) is a cryptographic hash function designed by Ronald Rivest in 1991. It produces a 128-bit (16-byte) hash value. MD5 is widely used for data integrity verification but is no longer considered secure for cryptographic purposes.<br><br>
					Key characteristics of MD5:
					<ul>
					<li>Fixed output size: 128 bits (16 bytes)</li>
					<li>Fast computation</li>
					<li>No longer considered cryptographically secure</li>
					</ul>`
				},
				{
					question: "How is the MD5 hash generated?",
					answer: `The MD5 algorithm generates a hash through the following steps:
					<ol>
					<li>Padding the message</li>
					<li>Appending a length field</li>
					<li>Initializing a 128-bit state</li>
					<li>Processing the message in 512-bit blocks</li>
					<li>Outputting the final 128-bit state as the hash</li>
					</ol>
					The exact process involves multiple rounds of bitwise operations and transformations.`
				},
				{
					question: "Can you provide an example of MD5 hash generation?",
					answer: `Certainly! Let's use the string "Hello, World!" as an example:
					<ol>
					<li>Input: "Hello, World!"</li>
					<li>MD5 Hash: <code>fc3ff98e8c6a0d3087d515c0473f8677</code></li>
					</ol>
					Note that even a small change in the input will result in a completely different hash.`
				},
				{
					question: "Is this tool secure?",
					answer: `This tool generates the MD5 hash entirely in your browser. Your input is not sent to any server or stored anywhere. However, please note:
					<ul>
					<li>MD5 is no longer considered cryptographically secure for most applications</li>
					<li>Don't use this tool on public or shared computers for sensitive data</li>
					<li>Clear your browser history and cache after using if on a shared device</li>
					<li>For modern cryptographic needs, consider using more secure hash functions like SHA-256 or SHA-3</li>
					</ul>`
				},
				{
					question: "Why would I need to generate an MD5 hash?",
					answer: `While MD5 is outdated for cryptographic purposes, it might still be used in some specific scenarios:
					<ul>
					<li>Legacy System Compatibility: Some older systems might still use MD5</li>
					<li>Educational Purposes: Understanding the evolution of hash functions</li>
					<li>Cryptography Research: Studying vulnerabilities in older hash functions</li>
					<li>Data Integrity Checks: In non-security-critical applications where MD5 is already in use</li>
					</ul>
					For most modern applications, more secure hash functions are recommended.`
				},
				{
					question: "Can I reverse an MD5 hash to get the original input?",
					answer: `No, MD5 hashes, like all cryptographic hash functions, are designed to be one-way functions. This means:
					<ul>
					<li>You cannot mathematically reverse the hash to obtain the original input</li>
					<li>However, due to known vulnerabilities, it's possible to find collisions (different inputs with the same hash) for MD5</li>
					<li>For small input spaces, brute-force or dictionary attacks might be feasible to find an input that produces a given hash</li>
					</ul>
					Because of these vulnerabilities, MD5 is not recommended for security-critical applications.`
				},
				{
					question: "How does MD5 compare to other hash functions?",
					answer: `MD5 is an older hash function with several drawbacks compared to modern alternatives:
					<ul>
					<li>Security: MD5 is vulnerable to collision attacks and is not considered cryptographically secure</li>
					<li>Speed: MD5 is faster than MD2 and MD5 but slower than modern hash functions</li>
					<li>Output Size: MD5's 128-bit output is smaller than many modern hash functions, which often use 256 bits or more</li>
					</ul>
					Modern alternatives like SHA-256, SHA-3, or BLAKE2 offer better security and performance for most applications.`
				}
			];
  
	  return {
		inputText,
		generatedHash,
		hashLength,
		generateHash,
		clearText,
		copyButtonText,
		copyHash,
		faqs
	  };
	}
  }
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  textarea.form-control {
	font-size: 16px;
  }
  
  /* Dark mode styles */
  @media (prefers-color-scheme: dark) {
	.accordion-button {
	  background-color: #343a40;
	  color: #fff;
	}
  
	.accordion-button:not(.collapsed) {
	  background-color: #2b3035;
	  color: #fff;
	}
  
	.accordion-button::after {
	  filter: invert(1);
	}
  
	.accordion-body {
	  background-color: #343a40;
	  color: #fff;
	}
  
	/* Style for code snippets in dark mode */
	.accordion-body code {
	  background-color: #2b3035;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  
  /* Light mode styles */
  @media (prefers-color-scheme: light) {
	.accordion-body {
	  background-color: #fff;
	  color: #212529;
	}
  
	/* Style for code snippets in light mode */
	.accordion-body code {
	  background-color: #f8f9fa;
	  color: #e83e8c;
	  padding: 2px 4px;
	  border-radius: 4px;
	}
  }
  </style>
