import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-51a2b641"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid mt-5 mb-50" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-9" }
const _hoisted_4 = { class: "text-center mt-4 mb-3" }
const _hoisted_5 = { class: "cards-container" }
const _hoisted_6 = { class: "card-body d-flex flex-column" }
const _hoisted_7 = { class: "text-center mb-3" }
const _hoisted_8 = ["src", "onError"]
const _hoisted_9 = { class: "card-title text-center" }
const _hoisted_10 = { class: "card-text text-center text-muted mt-2" }
const _hoisted_11 = { class: "col-lg-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BlogSidebar = _resolveComponent("BlogSidebar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allTools, (category, categoryIndex) => {
          return (_openBlock(), _createElementBlock("div", { key: categoryIndex }, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(category.categoryName), 1),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.tools, (tool) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: tool.name,
                  class: "card h-100"
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      (!_ctx.imageErrors[tool.name])
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: `/images/${tool.route.slice(1)}.png`,
                            alt: "Icon",
                            class: "img-fluid",
                            onError: ($event: any) => (_ctx.onImageError(tool.name)),
                            style: {"width":"2rem","height":"2rem"}
                          }, null, 40, _hoisted_8))
                        : (_openBlock(), _createElementBlock("i", {
                            key: 1,
                            class: _normalizeClass(['bi', tool.icon, 'fs-1'])
                          }, null, 2))
                    ]),
                    _createElementVNode("h5", _hoisted_9, _toDisplayString(tool.name), 1),
                    _createElementVNode("p", _hoisted_10, _toDisplayString(tool.subtitle), 1),
                    _createVNode(_component_router_link, {
                      to: tool.route,
                      class: "btn btn-outline-primary mt-auto"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Use Tool ")
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ])
                ]))
              }), 128))
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_BlogSidebar)
      ])
    ])
  ]))
}