<!-- src/App.vue -->
<template>
  <BreadCrumb PageTitle="JSON to CSV Converter" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">JSON to CSV Converter</h1>
    <p class="text-center fs-6">Convert your JSON file to CSV (Comma Separated Values) below</p>

    <!-- JSON Input Section -->
    <div class="form-group">
      <label for="jsonInput" class="fs-6">JSON Input</label>
      <textarea
        id="jsonInput"
        class="form-control fs-6"
        rows="16"
        v-model="jsonInput"
        placeholder='Enter JSON data here...'
      ></textarea>
    </div>

    <!-- Load JSON from File -->
    <div class="form-group mt-3 fs-6">
      <label for="jsonFile">Or Load JSON from File</label>
      <input type="file" class="form-control-file" id="jsonFile" @change="loadJsonFromFile" accept=".json" />
    </div>

    <!-- Convert Button -->
    <button class="btn btn-primary mt-3 w-100" @click="convertJsonToCsv">Convert to CSV</button>

    <!-- Error Message -->
    <div v-if="error" class="alert alert-danger mt-3">
      {{ error }}
    </div>

    <!-- CSV Output Section -->
    <div v-if="csvOutput" class="mt-4">
      <h3>CSV Output</h3>
      <textarea class="form-control fs-6" rows="16" readonly :value="csvOutput"></textarea>

      <!-- Action Buttons -->
      <div class="row mt-2">
        <div class="col-md-6 mb-2">
          <button class="btn btn-danger w-100" @click="downloadCsv">
            <i class="bi bi-download"></i> Download CSV
          </button>
        </div>
        <div class="col-md-6 mb-2">
          <button class="btn btn-secondary  w-100" @click="copyToClipboard">
            <i :class="clipboardIcon"></i> {{ clipboardText }}
          </button>
        </div>
      </div>
    </div>

    <!-- FAQ Section -->
    <div class="mt-50 mb-50">
      <h2>JSON to CSV FAQ</h2>
      <div v-for="(item, index) in faqList" :key="index" class="mb-4">
        <h5>{{ item.question }}</h5>
        <p v-html="item.answer" class="fs-6"></p>
      </div>
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
      BreadCrumb
    },
  data() {
    return {
      jsonInput: '',
      csvOutput: '',
      error: '',
      clipboardIcon: 'bi bi-clipboard',
      clipboardText: 'Copy to Clipboard',
      faqList: [
        {
          question: '1. How does the JSON to CSV conversion work?',
          answer: 'We use the <code>PapaParse</code> library\'s <code>unparse</code> method to convert JSON data to CSV format.'
        },
        {
          question: '2. How is error handling implemented?',
          answer: 'We wrap the JSON parsing and CSV conversion logic in a <code>try...catch</code> block. If <code>JSON.parse</code> throws an error due to invalid JSON, we catch it and display an error message.'
        },
        {
          question: '3. How does the download function work?',
          answer: 'We create a <code>Blob</code> from the CSV data and generate a temporary URL. We then programmatically create an <code>&lt;a&gt;</code> element, trigger a click event, and remove the element to prompt the user to download the CSV file.'
        },
        {
          question: '4. How can I ensure my JSON input is valid?',
          answer: 'Make sure your JSON is properly formatted:<ul><li>Use double quotes for strings.</li><li>Ensure all keys are enclosed in double quotes.</li><li>Validate using an online JSON validator if unsure.</li></ul>'
        },
        {
          question: '5. Can I customize the CSV output?',
          answer: 'Yes, PapaParse allows you to customize the CSV output through options like <code>quotes</code>, <code>delimiter</code>, <code>newline</code>, etc.'
        },
        {
          question: '6. How does data binding work with <code>v-model</code>?',
          answer: 'The <code>v-model</code> directive creates a two-way binding between form inputs and component data.'
        },
        {
          question: '7. What is the purpose of <code>v-if</code> in the template?',
          answer: 'The <code>v-if</code> directive conditionally renders elements based on the expression\'s truthiness.'
        },
        {
          question: '8. How are Bootstrap styles applied?',
          answer: 'We use Bootstrap classes directly in our HTML elements. Bootstrap CSS is imported globally in <code>main.js</code>, so all components have access to it.'
        },
        {
          question: '9. How does the <code>Papa.unparse</code> method work?',
          answer: 'The <code>Papa.unparse</code> method converts JSON data to CSV format. It takes JSON data and an options object.'
        },
        {
          question: '10. How can I handle nested JSON objects?',
          answer: 'Nested objects need to be flattened or transformed before converting to CSV. PapaParse doesn\'t handle nested objects out of the box.'
        },
        {
          question: '11. How do I modify the delimiter in the CSV output?',
          answer: 'Specify the <code>delimiter</code> option in the <code>unparse</code> method.'
        },
        {
          question: '12. Can you provide a LaTeX representation of the conversion process?',
          answer: 'Yes. The conversion maps a set of JSON objects to a matrix where rows represent records and columns represent fields.<br><br>Given JSON data ( \\{D_i\\} ) where ( i = 1, 2, \\dots, n ):<br>$$<br>\\begin{array}{c|ccc}<br>\\text{Fields} & f_1 & f_2 & \\dots \\\\ <br>\\hline<br>\\text{Record 1} & D_1(f_1) & D_1(f_2) & \\dots \\\\ <br>\\text{Record 2} & D_2(f_1) & D_2(f_2) & \\dots \\\\ <br>\\vdots & \\vdots & \\vdots & \\ddots \\\\ <br>\\end{array}<br>$$'
        },
        {
          question: '13. How do I handle arrays within JSON objects?',
          answer: 'You\'ll need to process arrays separately or flatten them. For example, you can join array elements into a string.'
        },
      ],
    };
  },
  methods: {
    convertJsonToCsv() {
      this.error = '';
      this.csvOutput = '';
      try {
        const jsonData = JSON.parse(this.jsonInput);

        // Ensure the input is an array
        const dataArray = Array.isArray(jsonData) ? jsonData : [jsonData];

        this.csvOutput = Papa.unparse(dataArray, {
          quotes: true,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: true,
          newline: "\r\n",
        });
      } catch (err) {
        this.error = 'Invalid JSON data. Please correct and try again.';
      }
    },
    downloadCsv() {
      const blob = new Blob([this.csvOutput], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.setAttribute('href', url);
      link.setAttribute('download', 'data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.csvOutput).then(() => {
        this.clipboardIcon = 'bi bi-clipboard-check';
        this.clipboardText = 'Copied!';
        setTimeout(() => {
          this.clipboardIcon = 'bi bi-clipboard';
          this.clipboardText = 'Copy to Clipboard';
        }, 2000);
      });
    },
    loadJsonFromFile(event) {
      const file = event.target.files[0];
      if (file && file.type === 'application/json') {
        const reader = new FileReader();
        reader.onload = e => {
          this.jsonInput = e.target.result;
        };
        reader.readAsText(file);
      } else {
        this.error = 'Please select a valid JSON file.';
      }
    },
  },
};
</script>

<style>
  .custom-container {
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .custom-container {
      padding: 0 0.5rem;
    }
  }
</style>
