<template>
  <BreadCrumb PageTitle="Antenna Beamwidth Calculator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Antenna Beamwidth Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the horizontal and vertical beamwidth of antennas for optimal telecommunications and radar performance.
    </p>
    <form id="beamwidthForm" @submit.prevent="calculateBeamwidth">
      <div class="form-group mb-4">
        <label for="frequency">Frequency (GHz):</label>
        <input
          type="number"
          class="form-control"
          id="frequency"
          v-model.number="frequency"
          placeholder="Enter frequency in GHz"
          required
        />
      </div>
      <div class="form-group mb-4">
        <label for="diameter">Dish Diameter (meters):</label>
        <input
          type="number"
          class="form-control"
          id="diameter"
          v-model.number="diameter"
          placeholder="Enter diameter in meters"
          required
        />
      </div>
      <button
        type="submit"
        class="btn btn-primary w-100 mb-4"
      >
        Calculate Beamwidth
      </button>
    </form>
    <div class="form-group mt-3 mb-3">
      <label for="result">Beamwidth (degrees):</label>
      <textarea 
        class="form-control" 
        id="result" 
        v-model="result" 
        rows="3" 
        disabled
      ></textarea>
    </div>
    <div>
      <AntennaBeamwidthCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AntennaBeamwidthCalculatorFAQ from '../FAQs/antennaBeamwidthCalculatorFAQ.vue';

export default {
  components: {
    BreadCrumb,
    AntennaBeamwidthCalculatorFAQ
  },
  data() {
    return {
      frequency: null,
      diameter: null,
      result: '-'
    };
  },
  methods: {
    calculateBeamwidth() {
      const c = 299792458; // Speed of light in meters/second

      // Validate input
      if (
        this.frequency === null ||
        this.diameter === null ||
        isNaN(this.frequency) ||
        isNaN(this.diameter) ||
        this.frequency <= 0 ||
        this.diameter <= 0
      ) {
        this.result = "Please enter valid positive numbers for both frequency and diameter.";
        return;
      }

      const frequencyHz = parseFloat(this.frequency) * 1e9; // Convert GHz to Hz
      const diameterMeters = parseFloat(this.diameter);

      // Calculate the wavelength λ
      const lambda = c / frequencyHz;

      // Calculate beamwidth θ using the formula θ = 70 * λ / D
      const theta = (70 * lambda) / diameterMeters;

      // Convert theta to degrees and display it
      this.result = theta.toFixed(2) + " degrees";
    }
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;              /* Takes up 90% of the viewport width */
  max-width: 800px;         /* Prevents it from getting too wide on large screens */
  margin: auto;             /* Centers the container */
  padding: 0 1rem;          /* Adds horizontal padding for better spacing */
  box-sizing: border-box;   /* Ensures padding is included in the width */
}

label {
  font-size: 16px;
}

.dark .accordion-item {
  color: #BCBBC7 !important;
  font-size: 16px;
}

.accordion-item {
  font-size: 16px;
}

.accordion-item text-paragraph {
  font-size: 18px;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .custom-container {
    width: 95vw;            /* Slightly wider on very small screens */
    padding: 0 0.5rem;      /* Adjust padding for smaller screens */
  }
}
</style>
