import { sin, cos, tan, pi, evaluate } from 'mathjs';

// Convert degrees to radians
function toRadians(degrees) {
  return degrees * (pi / 180);
}

// Helper function to replace 'π' with the mathematical constant pi
function replacePi(value) {
  return value.toString().replace(/π/g, pi);
}

export function calculateSin(angle, isRadianMode) {
  const evaluatedAngle = evaluate(replacePi(angle));
  return isRadianMode ? sin(evaluatedAngle) : sin(toRadians(evaluatedAngle));
}

export function calculateCos(angle, isRadianMode) {
  const evaluatedAngle = evaluate(replacePi(angle));
  return isRadianMode ? cos(evaluatedAngle) : cos(toRadians(evaluatedAngle));
}

export function calculateTan(angle, isRadianMode) {
  const evaluatedAngle = evaluate(replacePi(angle));
  return isRadianMode ? tan(evaluatedAngle) : tan(toRadians(evaluatedAngle));
}

// Helper function to format the result
export function formatResult(value) {
  return Number(value.toFixed(10));
}
