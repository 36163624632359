<template>
    <div class="card mb-25 mt-50 border-0 rounded-0 bg-white letter-spacing faq-accordion-card">
      <div class="card-body p-15 p-sm-20">
          <div class="accordion" id="faqAccordion">
              <div class="row">
                  <div class="col-lg-12">
                      <div class="text-center mb-3"><h3>FAQs on Pixel Circle Generator</h3></div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseOne"
                              aria-expanded="true"
                              aria-controls="faqCollapseOne">
                              <h5>What is the Pixel Circle Generator?</h5>
                          </button>
                          <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      The Pixel Circle Generator is a web tool that allows you to create pixelated circle designs. It's particularly useful for generating circular pixel art or patterns that can be used in various applications, including Minecraft builds.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseTwo"
                              aria-expanded="true"
                              aria-controls="faqCollapseTwo">
                              <h5>How do I use the Pixel Circle Generator?</h5>
                          </button>
                          <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      Follow these steps to use the Pixel Circle Generator:
                                      <ol>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Adjust the "Grid Size" and "Pixel Color" settings to customize your pixel circle.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Select a "Style" from the dropdown menu. You can choose between "Thin" and "Fill" styles. "Thin" will display only the outline, while "Fill" will fill the interior of the circle.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative">You can toggle gridlines and the circle outline using the "Toggle Grid" and "Toggle Outline" options.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative">The "Block Count" field shows the number of filled blocks in your circle.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative">Click on the "Download" buttons to save your pixel circle as PNG, JPG, or SVG.</li>
                                      </ol>
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseThree"
                              aria-expanded="true"
                              aria-controls="faqCollapseThree">
                            <h5>How do I customize the pixel circle?</h5>
                          </button>
                          <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      You can customize your pixel circle using the following settings:
                                      <ul>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Grid Size:</strong> Adjust the size of the grid to control the overall size of your pixel circle.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Pixel Color:</strong> Choose the color you want to use for your pixel circle.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Style:</strong> Select either "Thin" for an outline or "Fill" to fill the interior of the circle.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Toggle Grid:</strong> Turn gridlines on or off for your pixel circle.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Toggle Outline:</strong> Enable or disable the circle outline.</li>
                                      </ul>
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseFour"
                              aria-expanded="true"
                              aria-controls="faqCollapseFour">
                            <h5>How can I download my pixel circle?</h5>
                          </button>
                          <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      To download your pixel circle, use the following buttons:
                                      <ul>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Download as PNG:</strong> Saves your pixel circle as a PNG image file.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Download as JPG:</strong> Saves your pixel circle as a JPG image file.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><strong>Download as SVG:</strong> Saves your pixel circle as an SVG vector image file.</li>
                                      </ul>
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseFive"
                              aria-expanded="true"
                              aria-controls="faqCollapseFive">
                            <h5>Can I use the generated pixel circle in Minecraft?</h5>
                          </button>
                          <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      Yes, you can use the pixel circle generated by this tool in Minecraft. Simply import the image into your Minecraft world using a resource pack or by manually building it block by block.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseSix"
                              aria-expanded="true"
                              aria-controls="faqCollapseSix">
                            <h5>Is the Pixel Circle Generator free to use?</h5>
                          </button>
                          <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      Yes, the Pixel Circle Generator is completely free to use. You can generate and download as many pixel circles as you need without any cost.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseSeven"
                              aria-expanded="true"
                              aria-controls="faqCollapseSeven">
                            <h5>Do I need to create an account to use the Pixel Circle Generator?</h5>
                          </button>
                          <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      No, you do not need to create an account to use the Pixel Circle Generator. The tool is available for immediate use without any registration.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseEight"
                              aria-expanded="true"
                              aria-controls="faqCollapseEight">
                            <h5>Can I customize the colors of my pixel circle?</h5>
                          </button>
                          <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      Yes, you can customize the colors of your pixel circle. Use the "Pixel Color" option to select the color you want for your design.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseNine"
                              aria-expanded="true"
                              aria-controls="faqCollapseNine">
                            <h5>How do I share my pixel circle design with others?</h5>
                          </button>
                          <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      You can share your pixel circle design by downloading it as PNG, JPG, or SVG and then sharing the file through email, social media, or any other platform.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item rounded-0 border-0">
                          <button
                              class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#faqCollapseTen"
                              aria-expanded="true"
                              aria-controls="faqCollapseTen">
                            <h5>Where can I find more resources on pixel art and design?</h5>
                          </button>
                          <div id="faqCollapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                              <div class="accordion-body pb-0">
                                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                      For more resources on pixel art and design, you can visit websites such as:
                                      <ul>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><a href="https://www.piskelapp.com/" target="_blank">Piskel</a> - a free online editor for animated sprites & pixel art.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><a href="https://www.pixilart.com/" target="_blank">Pixilart</a> - a social platform for sharing and creating pixel art.</li>
                                          <li class="text-black fs-md-15 fs-xxxl-16 position-relative"><a href="https://www.deviantart.com/" target="_blank">DeviantArt</a> - a community for artists and art enthusiasts.</li>
                                      </ul>
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'PixelCircleGeneratorFAQ',
};
</script>

<style scoped>

</style>