<template>
  <BreadCrumb PageTitle="Syllable Counter Calculator" />

  <div class="container mt-5">
    <h1 class="text-center">Syllable Counter</h1>
    <p class="text-center text-muted fs-5 mt-2">Analyze the rhythm of your words</p>
    
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="text-input" class="form-label fs-6">Enter text:</label>
          <textarea
            id="text-input"
            v-model="input"
            @input="onInput"
            class="form-control auto-expand fs-6"
            placeholder="Type here..."
            rows="2"
            ref="textarea"
          ></textarea>
        </div>
        
        <div v-if="syllableCount !== null" class="card">
          <div class="card-body">
            <h3 class="card-title">Results:</h3>
            <div class="fs-6">
                <p><strong>Syllable Count:</strong> {{ syllableCount }}</p>
                <p><strong>Vowel Groups:</strong> {{ vowelGroups.join(', ') }}</p>
                <p><strong>Consonant Groups:</strong> {{ consonantGroups.join(', ') }}</p>
                <p><strong>Character Count:</strong> {{ input.length }}</p>
                <p><strong>Word Count:</strong> {{ wordCount }}</p>
            </div>
          </div>
        </div>
        <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Syllable Counter</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the Syllable Counter?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Syllable Counter is a tool designed to count the number of syllables in a given word or sentence. It is particularly useful for poets, writers, and anyone interested in analyzing the rhythm and flow of text.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the Syllable Counter?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Using the Syllable Counter is easy:
                                    <ul>
                                        <li>Enter the text you want to analyze in the input textarea.</li>
                                        <li>Click the "Count Syllables" button.</li>
                                        <li>The tool will display the number of syllables in the entered text.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>Why is syllable counting important?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Counting syllables is important in poetry, songwriting, and linguistic studies. It helps in understanding the rhythm, meter, and phonetic structure of words and sentences, which is crucial for creating engaging and structured content.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>Can the Syllable Counter handle multiple sentences?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, the Syllable Counter can analyze multiple sentences or even entire paragraphs. Simply enter the text you want to analyze, and the tool will count the syllables for the entire input.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>Is the Syllable Counter accurate?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Syllable Counter uses advanced algorithms to accurately count syllables in most cases. However, due to the complexities of the English language and variations in pronunciation, there may be occasional inaccuracies.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Can I use the Syllable Counter for other languages?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Syllable Counter is primarily designed for English text. Its accuracy may vary when used with other languages, depending on the linguistic rules and phonetic structures of those languages.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>Are there any limits to the text I can analyze?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    While there is no strict limit on the length of the text you can analyze, very large inputs may affect the performance of the tool. For best results, consider analyzing text in smaller sections if you encounter any issues.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

import { ref, computed, onMounted } from 'vue';

export default {
 
  components: { BreadCrumb },
 name: 'SyllableCounter',
  setup() {
    const input = ref('');
    const syllableCount = ref(null);
    const vowelGroups = ref([]);
    const consonantGroups = ref([]);
    const textarea = ref(null);

    const wordCount = computed(() => {
      return input.value.trim().split(/\s+/).filter(word => word.length > 0).length;
    });

    const countSyllables = () => {
      const text = input.value.toLowerCase();
      const words = text.split(/\s+/).filter(word => word.length > 0);
      const vowels = ['a', 'e', 'i', 'o', 'u', 'y'];
      let totalCount = 0;
      vowelGroups.value = [];
      consonantGroups.value = [];

      words.forEach(word => {
        let count = 0;
        let lastWasVowel = false;
        let currentVowelGroup = '';
        let currentConsonantGroup = '';

        for (let i = 0; i < word.length; i++) {
          const char = word[i];
          const isVowel = vowels.includes(char);

          if (isVowel) {
            if (!lastWasVowel) {
              count++;
              if (currentConsonantGroup) {
                consonantGroups.value.push(currentConsonantGroup);
                currentConsonantGroup = '';
              }
            }
            currentVowelGroup += char;
            lastWasVowel = true;
          } else {
            if (lastWasVowel) {
              vowelGroups.value.push(currentVowelGroup);
              currentVowelGroup = '';
            }
            currentConsonantGroup += char;
            lastWasVowel = false;
          }
        }

        if (currentVowelGroup) {
          vowelGroups.value.push(currentVowelGroup);
        }
        if (currentConsonantGroup) {
          consonantGroups.value.push(currentConsonantGroup);
        }

        // Adjust for special cases
        if (word.endsWith('e') && !word.endsWith('le') && count > 1) count--;
        if (word.endsWith('le') && word.length > 2 && !vowels.includes(word[word.length - 3])) count++;
        if (word.endsWith('es') || word.endsWith('ed')) count--;
        if (count === 0) count = 1;

        totalCount += count;
      });

      syllableCount.value = totalCount;
    };

    const adjustTextareaHeight = () => {
      const el = textarea.value;
      el.style.height = 'auto';
      el.style.height = el.scrollHeight + 'px';
    };

    const onInput = () => {
      countSyllables();
      adjustTextareaHeight();
    };

    onMounted(() => {
      adjustTextareaHeight();
    });

    return {
      input,
      syllableCount,
      vowelGroups,
      consonantGroups,
      wordCount,
      textarea,
      onInput,
    };
  },
};
</script>

<style scoped>
.auto-expand {
  min-height: 38px;
  overflow-y: hidden;
  resize: none;
}
</style>