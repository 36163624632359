<template>
  <BreadCrumb PageTitle="Division Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Division Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two numbers to calculate their division in multiple formats: integer, decimal, and fractional results.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Numerator -->
        <div class="mb-3">
          <label for="numerator" class="form-label">Numerator:</label>
          <input
            type="text"
            id="numerator"
            v-model="numerator"
            class="form-control"
            placeholder="e.g., 12, 4.5, or 3/2"
          />
        </div>

        <!-- Input for Denominator -->
        <div class="mb-3">
          <label for="denominator" class="form-label">Denominator:</label>
          <input
            type="text"
            id="denominator"
            v-model="denominator"
            class="form-control"
            placeholder="e.g., 3, 2.5, or 1/4"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateDivision" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p v-if="result.decimal !== null" class="fs-5">
            Decimal Result: {{ result.decimal }}
          </p>
          <p v-if="result.integer !== null" class="fs-5">
            Integer Division Result: Quotient = {{ result.integer.quotient }}, Remainder = {{ result.integer.remainder }}
          </p>
          <p v-if="result.fraction !== null" class="fs-5">
            Fractional Result: {{ result.fraction }}
          </p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the numerator and denominator in any format (integer, decimal, or fraction).</li>
        <li>Click "Calculate" to compute the division of the two numbers.</li>
        <li>The results will be displayed as an integer division, decimal, and fractional form (if possible).</li>
        <li>Click "Clear" to reset the input fields and results.</li>
      </ul>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For input \( \frac{3}{2} \) divided by \( \frac{1}{4} \):<br />
          Decimal Result: 6<br />
          Fractional Result: \( \frac{6}{1} \)<br />
          Integer Division: Quotient = 6, Remainder = 0
        </li>
        <li>
          For input 5 divided by 2:<br />
          Decimal Result: 2.5<br />
          Fractional Result: \( \frac{5}{2} \)<br />
          Integer Division: Quotient = 2, Remainder = 1
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const numerator = ref("");
    const denominator = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate division
    const calculateDivision = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the numerator and denominator
        const numValue = parseInput(numerator.value);
        const denomValue = parseInput(denominator.value);

        if (denomValue === 0) throw new Error("Denominator cannot be zero.");

        // Perform division and compute all possible results
        const decimalResult = (numValue / denomValue).toFixed(6);
        const integerResult = {
          quotient: Math.floor(numValue / denomValue),
          remainder: numValue % denomValue,
        };
        const fractionResult = performFractionDivision(numValue, denomValue);

        result.value = {
          decimal: decimalResult,
          integer: integerResult,
          fraction: fractionResult,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to parse input as number or fraction
    const parseInput = (input) => {
      // Check if input is a fraction
      if (input.includes("/")) {
        const parts = input.trim().split("/");
        if (parts.length !== 2) throw new Error("Invalid fraction format.");
        const numerator = parseFloat(parts[0].trim());
        const denominator = parseFloat(parts[1].trim());
        if (isNaN(numerator) || isNaN(denominator)) throw new Error("Invalid fraction values.");
        return numerator / denominator;
      }

      // Parse as float
      const value = parseFloat(input.trim());
      if (isNaN(value)) throw new Error("Invalid numeric value.");
      return value;
    };

    // Function to perform division and return as fraction
    const performFractionDivision = (num, denom) => {
      const gcdValue = greatestCommonDivisor(num, denom);
      const numeratorResult = num / gcdValue;
      const denominatorResult = denom / gcdValue;
      return `${numeratorResult}/${denominatorResult}`;
    };

    // Function to find greatest common divisor (GCD) of two numbers
    const greatestCommonDivisor = (a, b) => {
      return b === 0 ? Math.abs(a) : greatestCommonDivisor(b, a % b);
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      numerator.value = "";
      denominator.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      numerator,
      denominator,
      result,
      error,
      calculateDivision,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
