<template>
  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
    <div class="card-body p-15 p-sm-20">
      <div class="accordion" id="faqAccordion">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mb-3">
              <h3>FAQs on Secret Santa Poem Generator</h3>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                <h5>What is the Secret Santa Poem Generator?</h5>
              </button>
              <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    The Secret Santa Poem Generator is an online tool designed to create heartwarming and festive poems for your Secret Santa exchanges. It offers a variety of poem templates tailored to different themes and allows you to generate unique poems for holiday cards, events, or memorable gift surprises.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                <h5>How does the Secret Santa Poem Generator work?</h5>
              </button>
              <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    Using the Secret Santa Poem Generator is simple:
                  </p>
                  <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    <li>Select a poem type from the available options.</li>
                    <li>Click on the "Generate Poem" button to get a unique poem based on the selected type.</li>
                    <li>The generator uses dynamic placeholders to ensure each poem is personalized and festive.</li>
                  </ol>
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    It's a great way to add a personal touch to your Secret Santa gifts and holiday greetings!
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                <h5>What poem types are available in the Secret Santa Poem Generator?</h5>
              </button>
              <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    The Secret Santa Poem Generator offers a variety of poem types to choose from:
                  </p>
                  <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    <li>Santa Arrival</li>
                    <li>Santa Joy</li>
                    <li>Secret Gift</li>
                    <li>Mrs. Claus</li>
                    <li>Guess Who</li>
                    <li>Snowflakes and Stars</li>
                    <li>Elf Surprise</li>
                    <li>North Pole</li>
                    <li>Neighborhood Treat</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                <h5>Can I customize the poems generated?</h5>
              </button>
              <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    The Secret Santa Poem Generator uses dynamic placeholders to personalize each poem. While you cannot directly customize the templates, the generator ensures a unique output each time by randomly selecting words and phrases from predefined lists.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                <h5>How can I make the generated poems more personal?</h5>
              </button>
              <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    While the generator produces personalized poems, you can add a personal touch by editing the generated text to include specific names, events, or inside jokes that are meaningful to the recipient.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                <h5>Can children use the Secret Santa Poem Generator?</h5>
              </button>
              <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    Yes, the Secret Santa Poem Generator is family-friendly and suitable for children. However, we recommend that an adult supervise younger children to help them understand and use the tool effectively.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                <h5>Do I need an internet connection to use the generator?</h5>
              </button>
              <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    Yes, the Secret Santa Poem Generator requires an internet connection to function. You can generate poems while online and save or print them for offline use.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEight" aria-expanded="true" aria-controls="faqCollapseEight">
                <h5>What if I encounter a problem with the generator?</h5>
              </button>
              <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    If you encounter any issues or have questions about the Secret Santa Poem Generator, please contact our support team. We're here to help you have a smooth and enjoyable experience!
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseNine" aria-expanded="true" aria-controls="faqCollapseNine">
                <h5>How can I share the generated poems?</h5>
              </button>
              <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    Once you generate a poem, you can copy the text and share it via email, social media, or print it out to include in your holiday cards or gifts.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTen" aria-expanded="true" aria-controls="faqCollapseTen">
                <h5>How do I ensure the poems are unique?</h5>
              </button>
              <div id="faqCollapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    The Secret Santa Poem Generator uses random selection from a wide range of templates and word lists to ensure each poem is unique. However, you can further personalize the poems by adding specific details or tweaking the text after generation.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item rounded-0 border-0">
              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEleven" aria-expanded="true" aria-controls="faqCollapseEleven">
                <h5>How can I make the generated poems even more special?</h5>
              </button>
              <div id="faqCollapseEleven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                <div class="accordion-body pb-0">
                  <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    To make the poems even more special, consider:
                  </p>
                  <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    <li>Adding the recipient's name and specific details.</li>
                    <li>Including a personal message or inside joke.</li>
                    <li>Printing the poem on decorative paper or a holiday card.</li>
                    <li>Accompanying the poem with a small gift or festive item.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: 'SecretSantaPoemGeneratorFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  