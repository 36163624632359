<template>
    <BreadCrumb PageTitle="Cron Expression Converter" />
  
    <div class="custom-container mt-5">
      <h1 class="text-center">Cron Expression Converter</h1>
      <p class="fs-6 text-center mb-4">Generate and validate cron expressions with ease.</p>
  
      <div class="card mb-4">
        <div class="card-body">
          <div class="mb-3">
            <label for="cronExpression" class="form-label">Enter a cron expression:</label>
            <input type="text" id="cronExpression" v-model="cronExpression" class="form-control" placeholder="e.g., * * * * *">
          </div>
          <div class="mb-3 row">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <button @click="convertCron" class="btn btn-primary w-100">Convert</button>
            </div>
            <div class="col-12 col-md-6">
              <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
            </div>
          </div>
        </div>
        <div class="card-footer position-relative">
          <h4>Converted Output:</h4>
          <div class="d-flex justify-content-center align-items-center mb-3">
            <input v-if="output" type="text" class="form-control fs-3 text-center" :value="output" readonly />
          </div>
          <button v-if="output" @click="copyOutput" class="position-absolute top-0 end-0 mt-2 me-2 border-0 bg-transparent">
            <i :class="[copied ? 'bi bi-clipboard-check' : 'bi bi-clipboard']" style="font-size: 1.5rem;"></i>
          </button>
          <p v-if="error" class="text-danger">{{ error }}</p>
        </div>
      </div>
  
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0">Example Cron Expressions</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Cron Expression</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(example, index) in examples" :key="index">
                  <td>{{ example.expression }}</td>
                  <td>{{ example.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
      <div class="card mt-4">
      <div class="card-header">
        <h4 class="mb-0">FAQ For CRON Converter</h4>
      </div>
      <div class="card-body">
        <ul>
          <li v-for="(faq, index) in faqList" :key="index">
            <h5>{{ faq.question }}</h5>
            <p v-html="faq.answer" class="fs-6"></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import cronstrue from 'cronstrue';

export default {
  name: 'CronExpressionConverter',
  components: {
    BreadCrumb
  },
  setup() {
    const cronExpression = ref('');
    const output = ref('');
    const error = ref('');
    const copied = ref(false);

    const convertCron = () => {
      if (cronExpression.value.trim() === '') {
        output.value = '';
        error.value = 'Please enter a cron expression.';
        return;
      }

      try {
        output.value = cronstrue.toString(cronExpression.value, { use24HourTimeFormat: true });
        error.value = '';
      } catch (e) {
        error.value = 'Invalid cron expression. Please check your input.';
        output.value = '';
      }
    };

    const copyOutput = () => {
      if (output.value) {
        navigator.clipboard.writeText(output.value);
        copied.value = true;
        setTimeout(() => {
          copied.value = false;
        }, 2000);
      }
    };

    const clearValues = () => {
      cronExpression.value = '';
      output.value = '';
      error.value = '';
      copied.value = false;
    };

    const examples = [
      { expression: '* * * * *', description: cronstrue.toString('* * * * *', { use24HourTimeFormat: true }) },
      { expression: '0 12 * * *', description: cronstrue.toString('0 12 * * *', { use24HourTimeFormat: true }) },
      { expression: '0 0 * * 1', description: cronstrue.toString('0 0 * * 1', { use24HourTimeFormat: true }) },
      { expression: '*/5 * * * *', description: cronstrue.toString('*/5 * * * *', { use24HourTimeFormat: true }) },
      { expression: '0 0 1 * *', description: cronstrue.toString('0 0 1 * *', { use24HourTimeFormat: true }) }
    ];

    const faqList = [
        {
            question: 'What is a Cron Expression Generator?',
            answer: 'A Cron Expression Generator is a tool used to create, visualize, and validate cron expressions, which are used to schedule automated tasks. These expressions dictate when a task should run, for example, every day at 2:30 PM or the first Monday of every month. A generator helps ensure that the cron syntax is correct and the desired schedule is achieved.'
        },
        {
            question: 'What is a Cron Schedule?',
            answer: 'A Cron Schedule defines the specific times when a task or job will run, using a sequence of fields that represent time units like minutes, hours, days, months, and weekdays. For example, a cron schedule of <code>0 0 * * 1</code> will execute a job every Monday at midnight.'
        },
        {
            question: 'What is a Cron Job?',
            answer: 'A Cron Job is a scheduled command or script in a Unix-based system that runs automatically at specified intervals, as defined by a cron expression. It is commonly used for system maintenance, backups, and other repetitive tasks like running database queries or sending email reports.'
        },
        {
            question: 'What is Cron Syntax?',
            answer: `Cron Syntax is the format used to specify schedules for cron jobs. It consists of five or six fields representing different units of time:<br/>
                    <ul>
                        <li><strong>Minute</strong> (0-59)</li>
                        <li><strong>Hour</strong> (0-23)</li>
                        <li><strong>Day of the Month</strong> (1-31)</li>
                        <li><strong>Month</strong> (1-12 or JAN-DEC)</li>
                        <li><strong>Day of the Week</strong> (0-6, where 0 is Sunday or SUN-SAT)</li>
                        <li><strong>Optional Year</strong> (Optional field to specify a particular year)</li>
                    </ul>
                    Special characters such as "*", ",", "-", and "/" are used to define complex schedules.`
        },
        {
            question: 'What do the special characters in Cron mean?',
            answer: `<p>In cron syntax, special characters help define more specific schedules:</p>
                    <ul>
                        <li><strong>Asterisk (*)</strong>: Represents all possible values for a field. For example, "*" in the hour field means every hour.</li>
                        <li><strong>Comma (,)</strong>: Separates multiple values. For example, "1,15" in the day field means the job will run on the 1st and 15th of the month.</li>
                        <li><strong>Dash (-)</strong>: Specifies a range. For example, "1-5" in the weekday field means Monday to Friday.</li>
                        <li><strong>Slash (/)</strong>: Specifies step values. For example, "*/5" in the minute field means every 5 minutes.</li>
                        <li><strong>Question mark (?)</strong>: Used in the day of the month or day of the week fields to represent no specific value, useful when you want to specify only one of these fields.</li>
                    </ul>`
        },
        {
            question: 'What is the "*/5 * * * *" Cron Expression?',
            answer: `The cron expression <code>*/5 * * * *</code> means "every 5 minutes." It is interpreted as follows:
                    <ul>
                        <li><strong>Minute</strong>: <code>*/5</code> (every 5 minutes)</li>
                        <li><strong>Hour</strong>: <code>*</code> (every hour)</li>
                        <li><strong>Day of the Month</strong>: <code>*</code> (every day of the month)</li>
                        <li><strong>Month</strong>: <code>*</code> (every month)</li>
                        <li><strong>Day of the Week</strong>: <code>*</code> (every day of the week)</li>
                    </ul>
                    This expression is commonly used for tasks that need to run frequently, like checking for new data or sending regular updates.`
        },
        {
            question: 'What is the use of "* * * * *" in Cron?',
            answer: `The expression <code>* * * * *</code> means "every minute." It will execute the defined job every minute, all day, every day. This is useful for tasks that need to run continuously, such as monitoring scripts or real-time data processing jobs.`
        },
        {
            question: 'Why is Cron used?',
            answer: 'Cron is used for automating repetitive tasks on Unix-based systems. It is widely used for scheduling scripts or commands to perform system maintenance, data backups, report generation, and other tasks that need to be executed at regular intervals without manual intervention.'
        },
        {
            question: 'What is a 7-field Cron Expression?',
            answer: `A 7-field cron expression includes an additional field for the year, making it even more specific. The fields are:
                    <ul>
                        <li><strong>Minute</strong> (0-59)</li>
                        <li><strong>Hour</strong> (0-23)</li>
                        <li><strong>Day of the Month</strong> (1-31)</li>
                        <li><strong>Month</strong> (1-12 or JAN-DEC)</li>
                        <li><strong>Day of the Week</strong> (0-6 or SUN-SAT)</li>
                        <li><strong>Year</strong> (e.g., 2023)</li>
                    </ul>
                    This allows for more granular control over scheduling, such as running a job on a specific date every year.`
        },
        {
            question: 'What is Cron in SQL?',
            answer: 'In the context of databases, cron jobs can be used to schedule SQL queries or scripts. For example, a cron job can be set to back up a database or refresh a materialized view at regular intervals. Some SQL database systems have built-in schedulers that allow cron-like expressions to schedule these operations.'
        },
        {
            question: 'Is Cron 24 hours?',
            answer: 'Yes, cron operates on a 24-hour schedule. The hour field in a cron expression ranges from 0 to 23, where 0 represents midnight and 23 represents 11 PM. This allows tasks to be scheduled for any hour of the day or night.'
        },
        {
            question: 'How do you create a cron expression?',
            answer: 'Creating a cron expression involves filling out each of the fields according to your schedule requirements. For example, to run a job every day at 5 AM, you would use <code>0 5 * * *</code>. Use online cron generators to visualize and validate your expression, ensuring it represents the desired schedule.'
        },
        {
            question: 'How to use a cron script?',
            answer: 'To use a cron script, add your cron expression and command to your user’s crontab file using the <code>crontab -e</code> command. For example:<br/><br/>' +
                    '<code>0 2 * * * /path/to/script.sh</code><br/><br/>' +
                    'This will run your script every day at 2 AM. Ensure your script has the appropriate permissions and paths are correctly set.'
        }
        ];

    return {
      cronExpression,
      output,
      error,
      copied,
      convertCron,
      clearValues,
      copyOutput,
      examples,
      faqList
    };
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    padding: 0 0.5rem;
  }
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}

.bi-clipboard,
.bi-clipboard-check {
  font-size: 1.5rem;
  cursor: pointer;
}

.position-absolute {
  position: absolute !important;
}

.border-0 {
  border: 0 !important;
}

.bg-transparent {
  background-color: transparent !important;
}
</style>
