<template>
  <BreadCrumb PageTitle="Natural Logarithm (ln) Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Natural Logarithm (ln) Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the natural logarithm (ln) of a positive number. Enter a value to compute its natural logarithm, base \( e \approx 2.718 \).
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Number -->
        <div class="mb-3">
          <label for="number" class="form-label">Enter a Positive Number:</label>
          <input
            type="text"
            id="number"
            v-model="numberInput"
            class="form-control"
            placeholder="e.g., 1, 2.718, 10"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateLn" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Number: {{ result.number }}</p>
          <p class="fs-5">Calculation: ln({{ result.number }})</p>
          <p class="fs-5">Final Result: {{ result.lnResult }}</p>
          <p class="fs-5" v-if="result.explanation">Explanation: {{ result.explanation }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter a positive number for which you want to calculate the natural logarithm.</li>
        <li>Click "Calculate" to compute the natural logarithm of the entered number.</li>
        <li>The result will show the input number, the calculation process, and the final natural logarithm value.</li>
        <li>Click "Clear" to reset the input field and result.</li>
      </ul>

      <h5 class="alert-heading">What is Natural Logarithm (ln)?</h5>
      <p class="fs-6">
        The natural logarithm, denoted as \( \ln \), is the logarithm to the base \( e \), where \( e \approx 2.718 \). It represents the power to which the base \( e \) must be raised to obtain a given number.
      </p>

      <h5 class="alert-heading">Formula for Natural Logarithm:</h5>
      <div class="fs-6">
        For a positive number \( x \), the natural logarithm is defined as:
      </div>
      <div>
        \[
        \ln(x) = \log_e(x)
        \]
      </div>
      <p class="fs-6">
        This means that \( \ln(x) \) is the exponent to which \( e \) must be raised to get \( x \):
      </p>
      <div>
        \[
        e^{\ln(x)} = x
        \]
      </div>

      <h5 class="alert-heading">Properties of Natural Logarithm:</h5>
      <ul class="fs-6">
        <li>
          \( \ln(1) = 0 \)
        </li>
        <li>
          \( \ln(e) = 1 \)
        </li>
        <li>
          \( \ln(a \times b) = \ln(a) + \ln(b) \)
        </li>
        <li>
          \( \ln\left(\frac{a}{b}\right) = \ln(a) - \ln(b) \)
        </li>
        <li>
          \( \ln(a^b) = b \times \ln(a) \)
        </li>
      </ul>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For \( \ln(1) \):<br />
          Since \( e^0 = 1 \), the natural logarithm of 1 is 0.
        </li>
        <li>
          For \( \ln(2.718) \):<br />
          The natural logarithm of \( e \approx 2.718 \) is 1.
        </li>
        <li>
          For \( \ln(10) \):<br />
          The natural logarithm of 10 is approximately 2.3026.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const numberInput = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate natural logarithm (ln)
    const calculateLn = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the input value
        const number = parseFloat(numberInput.value.trim());

        if (isNaN(number) || number <= 0) {
          throw new Error("Please enter a valid positive number.");
        }

        // Calculate the natural logarithm
        const lnResult = Math.log(number);
        let explanation = "";

        if (number === 1) {
          explanation = "The natural logarithm of 1 is always 0 because e^0 = 1.";
        } else if (Math.abs(number - Math.E) < 0.00001) {
          explanation = "The natural logarithm of e is always 1 because e^1 = e.";
        }

        result.value = {
          number: number,
          lnResult: lnResult.toFixed(6),
          explanation: explanation,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      numberInput.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      numberInput,
      result,
      error,
      calculateLn,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
