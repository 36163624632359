import { createWebHistory, createRouter } from "vue-router";

import HomePage from '@/pages/Dashboard/HomePage.vue'
import BlogListPage from '@/pages/Dashboard/BlogListPage.vue'
import BlogPost from '@/pages/Dashboard/BlogPost.vue'

import PixelCircleGenerator from "../pages/EditorsChoice/pixel-circle-generator.vue";
import MidJourneyPromptGenerator from "../pages/EditorsChoice/midjourney-prompt-generator.vue";
import ColorPickerGenerator from "../pages/EditorsChoice/color-picker-generator.vue";
import CringeTextGenerator from "../pages/EditorsChoice/cringe-text-generator.vue";
import AdvancedImageCropper from "../pages/EditorsChoice/advanced-image-cropper.vue";
import SecretSantaGenerator from "../pages/EditorsChoice/secret-santa-generator.vue";
import PairDetails from "../pages/EditorsChoice/PairDetails.vue";
import ChristmasCharadesGenerator from "../pages/EditorsChoice/christmas-charades-generator.vue";
import SecretSantaPoemGenerator from "../pages/EditorsChoice/secret-santa-poem-generator.vue";

import ChristmasWordScramble from "../pages/WordGames/christmas-word-scramble.vue";
import AnimalWordScramble from "../pages/WordGames/animal-word-scramble.vue";
import BabyWordScramble from "../pages/WordGames/baby-word-scramble.vue";
import BibleWordScramble from "../pages/WordGames/bible-word-scramble.vue";
import EasterWordScramble from "../pages/WordGames/easter-word-scramble.vue";
import HolidayWordScramble from "../pages/WordGames/holiday-word-scramble.vue";
import ThanksgivingWordScramble from "../pages/WordGames/thanksgiving-word-scramble.vue";
import WeddingWordScramble from "../pages/WordGames/wedding-word-scramble.vue";
import GuessWord from "@/pages/WordGames/guess-word.vue";

import HTMLValidator from "../pages/TechTools/html-validator.vue";
import RandomIPGenerator from "../pages/TechTools/random-ip-generator.vue";
import FindReplace from "../pages/TechTools/find-and-replace-text.vue";
import MultipleWordReplacer from "../pages/TechTools/multiple-word-replacer.vue";
import WhitespaceRemover from "../pages/TechTools/whitespace-remover.vue";
import HTMLtoMarkdown from "../pages/TechTools/html-to-markdown.vue";
import WordSorter from "../pages/TechTools/word-sorter.vue";
import IPv6toIPv4 from "../pages/TechTools/ipv6-to-ipv4-converter.vue";
import IPv4toIPv6 from "../pages/TechTools/ipv4-to-ipv6-converter.vue";
import BcryptPasswordGenerator from "../pages/TechTools/bcrypt-password-generator.vue";
import LetterFrequencyCounter from "../pages/TechTools/letter-frequency-counter.vue";
import WordFlipperGenerator from "../pages/TechTools/word-flipper.vue";
import YAMLValidator from "../pages/TechTools/yaml-validator.vue";
import ResizeImageTo from "../pages/TechTools/resize-image-to.vue";
import RemoveUnwantedCharacters from "../pages/TechTools/remove-unwanted-characters.vue";
import JSONEscapeUnescape from "../pages/TechTools/json-escape-unescape.vue";
import SentenceCounter from "../pages/TechTools/sentence-counter.vue";
import SVGToCSSconverter from "../pages/TechTools/svg-to-css-converter.vue"
import CronConverter from "@/pages/TechTools/cron-converter.vue";
import ColorConverter from "@/pages/TechTools/color-converter.vue";
import HTMLToBbcode from "@/pages/TechTools/html-to-bbcode.vue";
import HTMLToCSV from "@/pages/TechTools/html-to-csv.vue";
import HTMLToJSON from "@/pages/TechTools/html-to-json.vue";
import HTMLToText from "@/pages/TechTools/html-to-text.vue";
import HTMLToXML from "@/pages/TechTools/html-to-xml.vue";
import HTMLToYAML from "@/pages/TechTools/html-to-yaml.vue";
import JSONToCSV from "@/pages/TechTools/json-to-csv.vue";
import MillisecondToHumanReadable from "@/pages/TechTools/millisecond-to-human-readable.vue";
import NumbersToWordsConverter from "@/pages/TechTools/numbers-to-words-converter.vue";

import NTLMHashGenerator from "../pages/EncodersDecoders/ntlm-hash-generator.vue";
import MD2HashGenerator from "../pages/EncodersDecoders/md2-hash-generator.vue";
import MD4HashGenerator from "../pages/EncodersDecoders/md4-hash-generator.vue";
import MD5HashGenerator from "../pages/EncodersDecoders/md5-hash-generator.vue";
import MD6HashGenerator from "../pages/EncodersDecoders/md6-hash-generator.vue";
import RIPEMD128HashGenerator from "../pages/EncodersDecoders/ripemd-128-hash-generator.vue";
import RIPEMD160HashGenerator from "../pages/EncodersDecoders/ripemd-160-hash-generator.vue";
import RIPEMD256HashGenerator from "../pages/EncodersDecoders/ripemd-256-hash-generator.vue";
import RIPEMD320HashGenerator from "../pages/EncodersDecoders/ripemd-320-hash-generator.vue";
import SHA1HashGenerator from "../pages/EncodersDecoders/sha1-hash-generator.vue";
import SHA3224HashGenerator from "../pages/EncodersDecoders/sha3-224-hash-generator.vue";
import SHA3256HashGenerator from "../pages/EncodersDecoders/sha3-256-hash-generator.vue";
import SHA3384HashGenerator from "../pages/EncodersDecoders/sha3-384-hash-generator.vue";
import SHA3512HashGenerator from "../pages/EncodersDecoders/sha3-512-hash-generator.vue";
import SHA224HashGenerator1 from "../pages/EncodersDecoders/sha-224-hash-generator.vue";
import SHA256HashGenerator from "../pages/EncodersDecoders/sha-256-hash-generator.vue";
import SHA384HashGenerator from "../pages/EncodersDecoders/sha-384-hash-generator.vue";
import SHA512HashGenerator from "../pages/EncodersDecoders/sha-512-hash-generator.vue";
import CRC8HashGenerator from "../pages/EncodersDecoders/crc8-hash-generator.vue";
import CRC16HashGenerator from "../pages/EncodersDecoders/crc16-hash-generator.vue";
import CRC24HashGenerator from "../pages/EncodersDecoders/crc24-hash-generator.vue";
import CRC32HashGenerator from "../pages/EncodersDecoders/crc32-hash-generator.vue";
import Adler32HashGenerator from "../pages/EncodersDecoders/adler32-hash-generator.vue";
import WhirlpoolHashGenerator from "../pages/EncodersDecoders/whirlpool-hash-generator.vue";

import AntennaBeamwidthCalculator from "../pages/Calculator/antenna-beamwidth-calculator.vue";
import AnnularVelocityCalculator from "../pages/Calculator/annular-velocity-calculator.vue";
import AntoineEquationCalculator from "../pages/Calculator/antoine-equation-calculator.vue";
import AnnualizedLossExpectancyCalculator from "../pages/Calculator/annualized-loss-expectancy-calculator.vue";
import PaceRunningCalculator from "../pages/Calculator/pace-running-calculator.vue";
import PaintRatioCalculator from "../pages/Calculator/paint-ratio-calculator.vue";
import CollatzConjectureCalculator from "../pages/Calculator/collatz-conjecture-calculator.vue";
import RiceToWaterRatioCalculator from "../pages/Calculator/rice-to-water-ratio-calculator.vue";
import WallArtSizeCalculator from "../pages/Calculator/wall-art-size-calculator.vue";
import EmpiricalRuleCalculator from "../pages/Calculator/empirical-rule-calculator.vue";
import WhatDividedByCalculator from "../pages/Calculator/what-divided-by-calculator.vue";
import GravitationalForceCalculator from "../pages/Calculator/gravitational-force-calculator.vue";
import OpenAIPricingCalculator from "../pages/Calculator/openai-pricing-calculator.vue";
import FiveNumberSummaryCalculator from "../pages/Calculator/five-number-summary-calculator.vue";
import AspectRatioCalculator from "../pages/Calculator/aspect-ratio-calculator.vue";
import NurserySpaceCalculator from "../pages/Calculator/nursery-space-calculator.vue";
import NurseryRatioCalculator from "../pages/Calculator/nursery-ratio-calculator.vue";
import HowManyDaysUntil from "../pages/Calculator/how-many-days-until.vue";
import WhatDividedByWhatCalculator from "../pages/Calculator/what-divided-by-what-calculator.vue";

import SquareRootCalculator from "../pages/Maths/square-root-calculator.vue";
import NthRootCalculator from "../pages/Maths/nth-root-calculator.vue";
import ReversePercentageCalculator from "../pages/Maths/reverse-percentage-calculator.vue";
import SluggingPercentageCalculator from "../pages/Maths/slugging-percentage-calculator.vue";
import PercentageChangeCalculator from "../pages/Maths/percentage-change-calculator.vue";
import GCDCalculator from "../pages/Maths/gcd-calculator.vue";
import SineCalculator from "../pages/Maths/sine-calculator.vue";
import AbsoluteValueCalculator from "../pages/Maths/absolute-value-calculator.vue";
import OnlineMathCalculator from "../pages/Maths/online-math-calculator.vue";
import AddingFractionsCalculator from "../pages/Maths/adding-fractions-calculator.vue";
import AdditionCalculator from "../pages/Maths/addition-calculator.vue";
import AntilogCalculator from "../pages/Maths/antilog-calculator.vue";
import ArccosCalculator from "../pages/Maths/arccos-calculator.vue";
import ArcsinCalculator from "../pages/Maths/arcsin-calculator.vue";
import ArctanCalculator from "../pages/Maths/arctan-calculator.vue";
import AverageCalculator from "../pages/Maths/average-calculator.vue";
import ComplexNumbersCalculator from "../pages/Maths/complex-numbers-calculator.vue";
import ConvolutionCalculator from "../pages/Maths/convolution-calculator.vue";
import CosineCalculator from "../pages/Maths/cosine-calculator.vue";
import DividingFractionsCalculator from "../pages/Maths/dividing-fractions-calculator.vue";
import DivisionCalculator from "../pages/Maths/division-calculator.vue";
import ExponentialGrowthCalculator from "../pages/Maths/exponential-growth-calculator.vue";
import ExponentsCalculator from "../pages/Maths/exponents-calculator.vue";
import FactorialCalculator from "../pages/Maths/factorial-calculator.vue";
import FractionsCalculator from "../pages/Maths/fractions-calculator.vue";
import GCFCalculator from "../pages/Maths/gcf-calculator.vue";
import LCMCalculator from "../pages/Maths/lcm-calculator.vue";
import LnCalculator from "../pages/Maths/ln-calculator.vue";
import LogCalculator from "../pages/Maths/log-calculator.vue";
import MultiplicationCalculator from "../pages/Maths/multiplication-calculator.vue";
import MultiplyingFractionsCalculator from "../pages/Maths/multiplying-fractions-calculator.vue";
import PercentageCalculator from "../pages/Maths/percentage-calculator.vue";
import PercentErrorCalculator from "../pages/Maths/percent-error-calculator.vue";
import PercentageIncreaseCalculator from "../pages/Maths/percentage-increase-calculator.vue";
import PythagoreanTheoremCalculator from "../pages/Maths/pythagorean-theorem-calculator.vue";
import QuadraticEquationSolver from "../pages/Maths/quadratic-equation-solver.vue";
import RandomNumberGenerator from "../pages/Maths/random-number-generator.vue";
import RatioCalculator from "../pages/Maths/ratio-calculator.vue";
import RemainderCalculator from "../pages/Maths/remainder-calculator.vue";
import ScientificNotationCalculator from "../pages/Maths/scientific-notation-calculator.vue";
import PrimeFactorCalculator from "../pages/Maths/prime-factor-calculator.vue";
import SimplifyingFractionsCalculator from "../pages/Maths/simplifying-fractions-calculator.vue";
import StandardDeviationCalculator from "../pages/Maths/standard-deviation-calculator.vue";
import SubtractingFractionsCalculator from "../pages/Maths/subtracting-fractions-calculator.vue";
import SubtractionCalculator from "../pages/Maths/subtraction-calculator.vue";
import TangentCalculator from "../pages/Maths/tangent-calculator.vue";
import TrigonometryCalculator from "../pages/Maths/trigonometry-calculator.vue";
import WeightedAverageCalculator from "../pages/Maths/weighted-average-calculator.vue";
import VarianceCalculator from "../pages/Maths/variance-calculator.vue";
import AlgebraSolver from "../pages/Maths/algebra-solver.vue";

import StarfieldNameGenerator from "../pages/Generators/starfield-name-generator.vue"
import BlankSpaceGenerator from "../pages/Generators/blank-space-generator.vue";
import SuperscriptGenerator from "../pages/Generators/superscript-generator.vue";
import BubbleTextGenerator from "../pages/Generators/bubble-text-generator.vue";
import ChristmasElfNameGenerator from "../pages/Generators/christmas-elf-name-generator.vue";
import FarmNameGenerator from "../pages/Generators/farm-name-generator.vue";
import WritingPromptGenerator from "../pages/Generators/writing-prompt-generator.vue";
import RandomFactsGenerator from "../pages/Generators/random-facts-generator.vue";
import RandomQuestionGenerator from "../pages/Generators/random-question-generator.vue";
import RandomBibleVerseGenerator from "../pages/Generators/random-bible-verse-generator.vue";
import RandomUserNameGenerator from "../pages/Generators/random-username-generator.vue";
import OnlineTextRepeater from "../pages/Generators/online-text-repeater.vue";
import ArtPromptGenerator from "../pages/Generators/art-prompt-generator.vue";
import RandomWordGenerator from "../pages/Generators/random-word-generator.vue";
import RandomPinGenerator from "../pages/Generators/random-pin-generator.vue";
import RandomNBATeamGenerator from "../pages/Generators/random-nba-team-generator.vue";
import RandomParagraphGenerator from "../pages/Generators/random-paragraph-generator.vue";
import RomanNameGenerator from "../pages/Generators/roman-name-generator.vue";
import SubscriptGenerator from "../pages/Generators/subscript-generator.vue";
import WordLadderGenerator from "../pages/Generators/word-ladder-generator.vue";
import SpongebobMockingTextGenerator from "../pages/Generators/spongebob-mocking-text-generator.vue"
import TextToAsciiArtGenerator from "@/pages/Generators/text-to-ascii-art-generator.vue";

import SyllableCounter from "../pages/English/syllable-counter.vue";
import ListAllVerbsInSentence from "../pages/English/list-all-verbs-in-sentence.vue";
import ListAllNounsInSentence from "../pages/English/list-all-nouns-in-sentence.vue";
import ListAllPronounsInSentence from "../pages/English/list-all-pronouns-in-sentence.vue";
import ListAllAdjectivesInSentence from "../pages/English/list-all-adjectives-in-sentence.vue";
import ListAllAdverbsInSentence from "../pages/English/list-all-adverbs-in-sentence.vue";
import ListAllPrepositionsInSentence from "../pages/English/list-all-prepositions-in-sentence.vue";
import ReadabilityCalculator from "@/pages/English/readability-calculator.vue";

import PrivacyPolicy from "../pages/Miscellaneous/PrivacyPolicyPage.vue";
import TermsConditions from "../pages/Miscellaneous/TermsConditionsPage.vue";
import AboutPage from "../pages/Miscellaneous/AboutPage.vue";

import LeaderboardTemplate from '@/components/VueFiles/LeaderboardTemplate.vue';
import WhatsTheWord from "@/pages/WordGames/whats-the-word.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage
  },
  {
    path: "/blog",
    name: "BlogList",
    component: BlogListPage
  },
  {
    path: '/blog/:slug',
    name: 'BlogPost',
    component: BlogPost,
    props: true
  },
  {
    path: "/pixel-circle-generator",
    name: "PixelCircleGenerator",
    component: PixelCircleGenerator,
  },  
  {
    path: "/midjourney-prompt-generator",
    name: "MidJourneyPromptGenerator",
    component: MidJourneyPromptGenerator,
  },
  {
    path: "/color-picker-generator",
    name: "ColorPickerGenerator",
    component: ColorPickerGenerator,
  },
  {
    path: "/cringe-text-generator",
    name: "CringeTextGenerator",
    component: CringeTextGenerator,
  },
  {
    path: "/advanced-image-cropper",
    name: "AdvancedImageCropper",
    component: AdvancedImageCropper,
  },
  {
    path: "/secret-santa-generator",
    name: "SecretSantaGenerator",
    component: SecretSantaGenerator,
  },
  {
    path: "/pair/:id",
    name: "PairDetails",
    component: PairDetails,
  },
  {
    path: "/christmas-charades-generator",
    name: "ChristmasCharadesGenerator",
    component: ChristmasCharadesGenerator,
  },
  {
    path: "/secret-santa-poem-generator",
    name: "SecretSantaPoemGenerator",
    component: SecretSantaPoemGenerator,
  },
  {
    path: "/christmas-word-scramble",
    name: "ChristmasWordScramble",
    component: ChristmasWordScramble,
  },
  {
    path: "/animal-word-scramble",
    name: "AnimalWordScramble",
    component: AnimalWordScramble,
  },
  {
    path: "/baby-word-scramble",
    name: "BabyWordScramble",
    component: BabyWordScramble,
  },
  {
    path: "/bible-word-scramble",
    name: "BibleWordScramble",
    component: BibleWordScramble,
  },
  {
    path: "/easter-word-scramble",
    name: "EasterWordScramble",
    component: EasterWordScramble,
  },
  {
    path: "/holiday-word-scramble",
    name: "HolidayWordScramble",
    component: HolidayWordScramble,
  },
  {
    path: "/thanksgiving-word-scramble",
    name: "ThanksgivingWordScramble",
    component: ThanksgivingWordScramble,
  },
  {
    path: "/wedding-word-scramble",
    name: "WeddingWordScramble",
    component: WeddingWordScramble,
  },
  {
    path: '/leaderboard/:tableName/:title/:from',
    name: 'Leaderboard',
    component: LeaderboardTemplate,
    props: true
  },
  {
    path: "/html-validator",
    name: "HTMLValidator",
    component: HTMLValidator,
  },
  {
    path: "/random-ip-generator",
    name: "RandomIPGenerator",
    component: RandomIPGenerator,
  },
  {
    path: "/find-and-replace-text",
    name: "FindReplace",
    component: FindReplace,
  },
  {
    path: "/multiple-word-replacer",
    name: "MultipleWordReplacer",
    component: MultipleWordReplacer,
  },
  {
    path: "/whitespace-remover",
    name: "WhitespaceRemover",
    component: WhitespaceRemover,
  },
  {
    path: "/html-to-markdown",
    name: "HTMLtoMarkdown",
    component: HTMLtoMarkdown,
  },
  {
    path: "/word-sorter",
    name: "WordSorter",
    component: WordSorter,
  },
  {
    path: "/ipv6-to-ipv4-converter",
    name: "IPv6toIPv4",
    component: IPv6toIPv4,
  },
  {
    path: "/ipv4-to-ipv6-converter",
    name: "IPv4toIPv6",
    component: IPv4toIPv6,
  },
  {
    path: "/bcrypt-password-generator",
    name: "BcryptPasswordGenerator",
    component: BcryptPasswordGenerator,
  },
  {
    path: "/letter-frequency-counter",
    name: "LetterFrequencyCounter",
    component: LetterFrequencyCounter,
  },
  {
    path: "/word-flipper",
    name: "WordFlipperGenerator",
    component: WordFlipperGenerator,
  },
  {
    path: "/yaml-validator",
    name: "YAMLValidator",
    component: YAMLValidator,
  },
  {
    path: "/json-escape-unescape",
    name: "JSONEscapeUnescape",
    component: JSONEscapeUnescape,
  },
  {
    path: "/sentence-counter",
    name: "SentenceCounter",
    component: SentenceCounter,
  },
  {
    path: "/ntlm-hash-generator",
    name: "NTLMHashGenerator",
    component: NTLMHashGenerator,
  },
  {
    path: "/md2-hash-generator",
    name: "MD2HashGenerator",
    component: MD2HashGenerator,
  },
  {
    path: "/md4-hash-generator",
    name: "MD4HashGenerator",
    component: MD4HashGenerator,
  },
  {
    path: "/md5-hash-generator",
    name: "MD5HashGenerator",
    component: MD5HashGenerator,
  },
  {
    path: "/md6-hash-generator",
    name: "MD6HashGenerator",
    component: MD6HashGenerator,
  },
  {
    path: "/ripemd-128-hash-generator",
    name: "RIPEMD128HashGenerator",
    component: RIPEMD128HashGenerator,
  },
  {
    path: "/ripemd-160-hash-generator",
    name: "RIPEMD160HashGenerator",
    component: RIPEMD160HashGenerator,
  },
  {
    path: "/ripemd-256-hash-generator",
    name: "RIPEMD256HashGenerator",
    component: RIPEMD256HashGenerator,
  },
  {
    path: "/ripemd-320-hash-generator",
    name: "RIPEMD320HashGenerator",
    component: RIPEMD320HashGenerator,
  },
  {
    path: "/sha1-hash-generator",
    name: "SHA1HashGenerator",
    component: SHA1HashGenerator,
  },
  {
    path: "/sha3-224-hash-generator",
    name: "SHA3224HashGenerator",
    component: SHA3224HashGenerator,
  },
  {
    path: "/sha3-256-hash-generator",
    name: "SHA3256HashGenerator",
    component: SHA3256HashGenerator,
  },
  {
    path: "/sha3-384-hash-generator",
    name: "SHA3384HashGenerator",
    component: SHA3384HashGenerator,
  },
  {
    path: "/sha3-512-hash-generator",
    name: "SHA3512HashGenerator",
    component: SHA3512HashGenerator,
  },
  {
    path: "/sha-224-hash-generator",
    name: "SHA224HashGenerator1",
    component: SHA224HashGenerator1,
  },
  {
    path: "/sha-256-hash-generator",
    name: "SHA256HashGenerator",
    component: SHA256HashGenerator,
  },
  {
    path: "/sha-384-hash-generator",
    name: "SHA384HashGenerator",
    component: SHA384HashGenerator,
  },
  {
    path: "/sha-512-hash-generator",
    name: "SHA512HashGenerator",
    component: SHA512HashGenerator,
  },
  {
    path: "/crc8-hash-generator",
    name: "CRC8HashGenerator",
    component: CRC8HashGenerator,
  },
  {
    path: "/crc16-hash-generator",
    name: "CRC16HashGenerator",
    component: CRC16HashGenerator,
  },
  {
    path: "/crc24-hash-generator",
    name: "CRC24HashGenerator",
    component: CRC24HashGenerator,
  },
  {
    path: "/crc32-hash-generator",
    name: "CRC32HashGenerator",
    component: CRC32HashGenerator,
  },  
  {
    path: "/adler32-hash-generator",
    name: "Adler32HashGenerator",
    component: Adler32HashGenerator,
  },
  {
    path: "/whirlpool-hash-generator",
    name: "WhirlpoolHashGenerator",
    component: WhirlpoolHashGenerator,
  },
  {
    path: "/remove-unwanted-characters",
    name: "RemoveUnwantedCharacters",
    component: RemoveUnwantedCharacters,
  },
  {
    path: "/resize-image-to-:size-kb",
    name: "ResizeImageTo",
    component: ResizeImageTo,
    props: true,
  },
  {
    path: '/resize-image-to',
    redirect: '/resize-image-to-20-kb'
  },
  {
    path: "/antenna-beamwidth-calculator",
    name: "AntennaBeamwidthCalculator",
    component: AntennaBeamwidthCalculator,
  },
  {
    path: "/annular-velocity-calculator",
    name: "AnnularVelocityCalculator",
    component: AnnularVelocityCalculator,
  },
  {
    path: "/antoine-equation-calculator",
    name: "AntoineEquationCalculator",
    component: AntoineEquationCalculator,
  },
  {
    path: "/annualized-loss-expectancy-calculator",
    name: "AnnualizedLossExpectancyCalculator",
    component: AnnualizedLossExpectancyCalculator,
  },
  {
    path: "/pace-running-calculator",
    name: "PaceRunningCalculator",
    component: PaceRunningCalculator,
  },
  {
    path: "/paint-ratio-calculator",
    name: "PaintRatioCalculator",
    component: PaintRatioCalculator,
  },
  {
    path: "/collatz-conjecture-calculator",
    name: "CollatzConjectureCalculator",
    component: CollatzConjectureCalculator,
  },
  {
    path: "/rice-to-water-ratio-calculator",
    name: "RiceToWaterRatioCalculator",
    component: RiceToWaterRatioCalculator,
  },
  {
    path: "/wall-art-size-calculator",
    name: "WallArtSizeCalculator",
    component: WallArtSizeCalculator,
  },
  {
    path: "/empirical-rule-calculator",
    name: "EmpiricalRuleCalculator",
    component: EmpiricalRuleCalculator,
  },
  {
    path: '/what-divided-by-calculator',
    name: 'WhatDividedByCalculatorBase',
    component: WhatDividedByCalculator,
  },
  {
    path: '/what-divided-by-calculator/what-divided-by-:divisor-equals-:quotient',
    name: 'WhatDividedByCalculator',
    component: WhatDividedByCalculator,
    props: true
  },
  {
    path: '/what-divided-by-what-calculator',
    redirect: '/what-divided-by-what-equals-1'
  },
  {
    path: '/what-divided-by-what-equals-:number',
    name: 'WhatDividedByWhatCalculator',
    component: WhatDividedByWhatCalculator,
    props: route => ({ number: parseInt(route.params.number) || 1 }),
  },
  {
    path: "/square-root-calculator/:result?",
    name: "SquareRootCalculator",
    component: SquareRootCalculator,
  },
  {
    path: "/gravitational-force-calculator",
    name: "GravitationalForceCalculator",
    component: GravitationalForceCalculator,
  },
  {
    path: "/openai-pricing-calculator",
    name: "OpenAIPricingCalculator",
    component: OpenAIPricingCalculator,
  },
  {
    path: "/five-number-summary-calculator",
    name: "FiveNumberSummaryCalculator",
    component: FiveNumberSummaryCalculator,
  },
  {
    path: "/aspect-ratio-calculator",
    name: "AspectRatioCalculator",
    component: AspectRatioCalculator,
  },
  {
    path: "/nursery-space-calculator",
    name: "NurserySpaceCalculator",
    component: NurserySpaceCalculator,
  },
  {
    path: "/nursery-ratio-calculator",
    name: "NurseryRatioCalculator",
    component: NurseryRatioCalculator,
  },
  {
    path: "/how-many-days-until/:date?",
    name: "HowManyDaysUntil",
    component: HowManyDaysUntil,
    props: true,
  },
  {
    path: "/starfield-name-generator",
    name: "StarfieldNameGenerator",
    component: StarfieldNameGenerator,
  },
  {
    path: "/blank-space-generator",
    name: "BlankSpaceGenerator",
    component: BlankSpaceGenerator,
  },
  {
    path: "/superscript-generator",
    name: "SuperscriptGenerator",
    component: SuperscriptGenerator,
  },
  {
    path: "/bubble-text-generator",
    name: "BubbleTextGenerator",
    component: BubbleTextGenerator,
  },
  {
    path: "/christmas-elf-name-generator",
    name: "ChristmasElfNameGenerator",
    component: ChristmasElfNameGenerator,
  },
  {
    path: "/farm-name-generator",
    name: "FarmNameGenerator",
    component: FarmNameGenerator,
  },
  {
    path: "/writing-prompt-generator",
    name: "WritingPromptGenerator",
    component: WritingPromptGenerator,
  },
  {
    path: "/random-facts-generator",
    name: "RandomFactsGenerator",
    component: RandomFactsGenerator,
  },
  {
    path: "/random-question-generator",
    name: "RandomQuestionGenerator",
    component: RandomQuestionGenerator,
  },
  {
    path: "/random-bible-verse-generator",
    name: "RandomBibleVerseGenerator",
    component: RandomBibleVerseGenerator,
  },
  {
    path: "/random-username-generator",
    name: "RandomUserNameGenerator",
    component: RandomUserNameGenerator,
  },
  {
    path: "/online-text-repeater",
    name: "OnlineTextRepeater",
    component: OnlineTextRepeater,
  },
  {
    path: "/art-prompt-generator",
    name: "ArtPromptGenerator",
    component: ArtPromptGenerator,
  },
  {
    path: "/random-word-generator",
    name: "RandomWordGenerator",
    component: RandomWordGenerator,
  },
  {
    path: '/random-pin-generator',
    name: 'RandomPinGenerator',
    component: RandomPinGenerator,
    props: route => ({ pinLength: 6 }),
  },
  {
    path: '/random-:length-pin-generator',
    name: 'RandomPinGeneratorWithLength',
    component: RandomPinGenerator,
    props: route => ({ pinLength: parseInt(route.params.length as string) || 6 }),
  },
  {
    path: "/random-nba-team-generator",
    name: "RandomNBATeamGenerator",
    component: RandomNBATeamGenerator,
  },
  {
    path: "/random-paragraph-generator",
    name: "RandomParagraphGenerator",
    component: RandomParagraphGenerator,
  },
  {
    path: "/roman-name-generator",
    name: "RomanNameGenerator",
    component: RomanNameGenerator,
  },
  {
    path: "/subscript-generator",
    name: "SubscriptGenerator",
    component: SubscriptGenerator,
  },
  {
    path: "/word-ladder-generator",
    name: "WordLadderGenerator",
    component: WordLadderGenerator,
  },
  {
    path: "/nth-root-calculator/:result?",
    name: "NthRootCalculator",
    component: NthRootCalculator,
  },
  {
    path: "/reverse-percentage-calculator",
    name: "ReversePercentageCalculator",
    component: ReversePercentageCalculator,
  },
  {
    path: "/slugging-percentage-calculator",
    name: "SluggingPercentageCalculator",
    component: SluggingPercentageCalculator,
  },
  {
    path: "/percentage-change-calculator",
    name: "PercentageChangeCalculator",
    component: PercentageChangeCalculator,
  },
  {
    path: "/gcd-calculator",
    name: "GCDCalculator",
    component: GCDCalculator,
  },
  {
    path: "/syllable-counter",
    name: "SyllableCounter",
    component: SyllableCounter,
  },
  {
    path: "/list-all-verbs-in-sentence",
    name: "ListAllVerbsInSentence",
    component: ListAllVerbsInSentence,
  },
  {
    path: "/list-all-nouns-in-sentence",
    name: "ListAllNounsInSentence",
    component: ListAllNounsInSentence,
  },
  {
    path: "/list-all-pronouns-in-sentence",
    name: "ListAllPronounsInSentence",
    component: ListAllPronounsInSentence,
  },
  {
    path: "/list-all-adjectives-in-sentence",
    name: "ListAllAdjectivesInSentence",
    component: ListAllAdjectivesInSentence,
  },
  {
    path: "/list-all-adverbs-in-sentence",
    name: "ListAllAdverbsInSentence",
    component: ListAllAdverbsInSentence,
  },
  {
    path: "/list-all-prepositions-in-sentence",
    name: "ListAllPrepositionsInSentence",
    component: ListAllPrepositionsInSentence,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-conditions",
    name: "TermsConditions",
    component: TermsConditions,
  },
  {
    path: "/about-us",
    name: "AboutPage",
    component: AboutPage,
  },
  {
    path: "/sine-calculator",
    name: "SineCalculator",
    component: SineCalculator,
  },
  {
    path: "/absolute-value-calculator",
    name: "AbsoluteValueCalculator",
    component: AbsoluteValueCalculator,
  },
  {
    path: "/online-math-calculator",
    name: "OnlineMathCalculator",
    component: OnlineMathCalculator,
  },
  {
    path: "/adding-fractions-calculator",
    name: "AddingFractionsCalculator",
    component: AddingFractionsCalculator,
  },
  {
    path: "/addition-calculator",
    name: "AdditionCalculator",
    component: AdditionCalculator,
  },
  {
    path: "/antilog-calculator",
    name: "AntilogCalculator",
    component: AntilogCalculator,
  },
  {
    path: "/arccos-calculator",
    name: "ArccosCalculator",
    component: ArccosCalculator,
  },
  {
    path: "/arcsin-calculator",
    name: "ArcsinCalculator",
    component: ArcsinCalculator,
  },
  {
    path: "/arctan-calculator",
    name: "ArctanCalculator",
    component: ArctanCalculator,
  },
  {
    path: "/average-calculator",
    name: "AverageCalculator",
    component: AverageCalculator,
  },
  {
    path: "/complex-numbers-calculator",
    name: "ComplexNumbersCalculator",
    component: ComplexNumbersCalculator,
  },
  {
    path: "/convolution-calculator",
    name: "ConvolutionCalculator",
    component: ConvolutionCalculator,
  },
  {
    path: "/cosine-calculator",
    name: "CosineCalculator",
    component: CosineCalculator,
  },
  {
    path: "/dividing-fractions-calculator",
    name: "DividingFractionsCalculator",
    component: DividingFractionsCalculator,
  },
  {
    path: "/division-calculator",
    name: "DivisionCalculator",
    component: DivisionCalculator,
  },
  {
    path: "/exponential-growth-calculator",
    name: "ExponentialGrowthCalculator",
    component: ExponentialGrowthCalculator,
  },
  {
    path: "/exponents-calculator",
    name: "ExponentsCalculator",
    component: ExponentsCalculator,
  },
  {
    path: "/factorial-calculator",
    name: "FactorialCalculator",
    component: FactorialCalculator,
  },
  {
    path: "/fractions-calculator",
    name: "FractionsCalculator",
    component: FractionsCalculator,
  },
  {
    path: "/gcf-calculator",
    name: "GCFCalculator",
    component: GCFCalculator,
  },
  {
    path: "/lcm-calculator",
    name: "LCMCalculator",
    component: LCMCalculator,
  },
  {
    path: "/ln-calculator",
    name: "LnCalculator",
    component: LnCalculator,
  },
  {
    path: "/log-calculator",
    name: "LogCalculator",
    component: LogCalculator,
  },
  {
    path: "/multiplication-calculator",
    name: "MultiplicationCalculator",
    component: MultiplicationCalculator,
  },
  {
    path: "/multiplying-fractions-calculator",
    name: "MultiplyingFractionsCalculator",
    component: MultiplyingFractionsCalculator,
  },
  {
    path: "/percentage-calculator",
    name: "PercentageCalculator",
    component: PercentageCalculator,
  },
  {
    path: "/percent-error-calculator",
    name: "PercentErrorCalculator",
    component: PercentErrorCalculator,
  },
  {
    path: "/percentage-increase-calculator",
    name: "PercentageIncreaseCalculator",
    component: PercentageIncreaseCalculator,
  },
  {
    path: "/pythagorean-theorem-calculator",
    name: "PythagoreanTheoremCalculator",
    component: PythagoreanTheoremCalculator,
  },
  {
    path: "/quadratic-equation-solver",
    name: "QuadraticEquationSolver",
    component: QuadraticEquationSolver,
  },
  {
    path: "/random-number-generator",
    name: "RandomNumberGenerator",
    component: RandomNumberGenerator,
  },
  {
    path: '/ratio-calculator/:calculationType?',
    name: 'RatioCalculator',
    component: RatioCalculator,
    props: true, // Pass route params as props to the component
  },
  {
    path: "/remainder-calculator",
    name: "RemainderCalculator",
    component: RemainderCalculator,
  },
  {
    path: "/scientific-notation-calculator",
    name: "ScientificNotationCalculator",
    component: ScientificNotationCalculator,
  },
  {
    path: "/prime-factor-calculator",
    name: "PrimeFactorCalculator",
    component: PrimeFactorCalculator,
  },
  {
    path: "/simplifying-fractions-calculator",
    name: "SimplifyingFractionsCalculator",
    component: SimplifyingFractionsCalculator,
  },
  {
    path: "/standard-deviation-calculator",
    name: "StandardDeviationCalculator",
    component: StandardDeviationCalculator,
  },
  {
    path: "/subtracting-fractions-calculator",
    name: "SubtractingFractionsCalculator",
    component: SubtractingFractionsCalculator,
  },
  {
    path: "/subtraction-calculator",
    name: "SubtractionCalculator",
    component: SubtractionCalculator,
  },
  {
    path: "/tangent-calculator",
    name: "TangentCalculator",
    component: TangentCalculator,
  },
  {
    path: "/trigonometry-calculator",
    name: "TrigonometryCalculator",
    component: TrigonometryCalculator,
  },
  {
    path: "/weighted-average-calculator",
    name: "WeightedAverageCalculator",
    component: WeightedAverageCalculator,
  },
  {
    path: "/variance-calculator",
    name: "VarianceCalculator",
    component: VarianceCalculator,
  },
  {
    path: "/algebra-solver",
    name: "AlgebraSolver",
    component: AlgebraSolver,
  },
  {
    path: "/readability-calculator",
    name: "ReadabilityCalculator",
    component: ReadabilityCalculator,
  },
  {
    path: "/svg-to-css-converter",
    name: "SVGToCSSconverter",
    component: SVGToCSSconverter,
  },
  {
    path: "/cron-converter",
    name: "CronConverter",
    component: CronConverter,
  },
  {
    path: '/color-converter/:slug?',
    component: ColorConverter,
  },
  {
    path: '/html-to-bbcode',
    component: HTMLToBbcode,
  },
  {
    path: '/html-to-csv',
    component: HTMLToCSV,
  },
  {
    path: '/html-to-json',
    component: HTMLToJSON,
  },
  {
    path: '/html-to-text',
    component: HTMLToText,
  },
  {
    path: '/html-to-xml',
    component: HTMLToXML,
  },
  {
    path: '/html-to-yaml',
    component: HTMLToYAML,
  },
  {
    path: '/json-to-csv',
    component: JSONToCSV,
  },
  {
    path: '/millisecond-to-human-readable',
    component: MillisecondToHumanReadable,
  },
  {
    path: '/spongebob-mocking-text-generator',
    component: SpongebobMockingTextGenerator,
  },
  {
    path: '/text-to-ascii-art-generator',
    component: TextToAsciiArtGenerator,
  },
  {
    path: '/numbers-to-words-converter',
    component: NumbersToWordsConverter,
  },
  {
    path: '/guess-word',
    component: GuessWord,
  },
  {
    path: '/whats-the-word',
    component: WhatsTheWord,
  }
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
