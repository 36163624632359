<template>
  <BreadCrumb :PageTitle="pageTitle" />
  <div class="blog-layout col-lg-9">
    <div v-if="post" class="blog-post">
      <img v-if="post.featuredImage" :src="require(`@/content/blog/${post.featuredImage}`)" :alt="post.title" class="featured-image">
      <!--<h1>{{ post.title }}</h1>-->
      <p class="post-meta">
        <span class="author">
          <i class="bi bi-person-fill"></i>
          {{ post.author }}
        </span>
        <span class="separator mx-2">|</span>
        <span class="date">
          <i class="bi bi-calendar3"></i>
          {{ formatDate(post.date) }}
        </span>
      </p>
      <div v-html="renderedContent" class="blog-content"></div>
    </div>
    <div class="col-lg-3">
      <BlogSidebar class="sidebar" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, computed } from 'vue';
import { useContent } from '@/utils/content';
import { marked } from 'marked';
import BlogSidebar from './BlogSidebar.vue';
import BreadCrumb from '@/components/Common/BreadCrumb.vue';

interface BlogPost {
  title: string;
  author: string;
  date: string;
  content: string;
  featuredImage?: string;
}

export default defineComponent({
  name: 'BlogPost',
  components: {
    BlogSidebar,
    BreadCrumb
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { fetchPost } = useContent();
    const post = ref<BlogPost | null>(null);
    const pageTitle = ref('Blog Post');

    // Function to load post data
    const loadPost = async () => {
      post.value = await fetchPost(props.slug);
      if (post.value) {
        pageTitle.value = post.value.title;
      }
    };

    // Initial load
    onMounted(loadPost);

    // Watch for changes in the `slug` prop to reload the post
    watch(() => props.slug, () => {
      loadPost(); // Refetch post when the slug changes
    });

    const renderedContent = computed(() => {
      return post.value ? marked(post.value.content) : '';
    });

    const formatDate = (date: string) => {
      const d = new Date(date);
      const day = d.getDate();
      const month = d.toLocaleString('default', { month: 'short' });
      const year = d.getFullYear();
      return `${day} ${month} ${year}`;
    };

    return {
      post,
      pageTitle,
      renderedContent,
      formatDate
    };
  }
});
</script>

<style scoped>
.blog-layout {
  display: flex;
  gap: 2rem;
  
  margin: 0 auto;
  padding: 2rem;
}

.blog-post {
  flex: 1;
  font-size: 15px;
}

::v-deep(.blog-content h2) {
  font-size: 1.6rem;
}

::v-deep(.blog-content h3) {
  font-size: 1.3rem;
}

.featured-image {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.post-meta {
  display: flex;
  align-items: center;
  color: #6c757d;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.post-meta i {
  margin-right: 0.5rem;
}

.separator {
  margin: 0 0.5rem;
}

h1 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .blog-layout {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
  }
}
</style>