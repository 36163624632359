<template>
  <BreadCrumb PageTitle="SVG to CSS Converter" />

  <div class="custom-container mt-5">
    <h1 class="text-center mb-4">SVG to CSS Converter</h1>
    <p class="fs-6 text-center mb-4">Paste your SVG code or upload a file to generate CSS code for easy use in your projects.</p>

    <div class="row">
      <!-- SVG Input Section -->
      <div class="col-12 col-lg-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">SVG Input</h5>
            <div class="mb-3">
              <label for="svgInput" class="form-label">Paste SVG Code:</label>
              <textarea id="svgInput" v-model="svgContent" class="form-control" rows="6" placeholder="<svg>...</svg>"></textarea>
            </div>
            <div class="mb-3">
              <label for="svgFile" class="form-label">Or Upload SVG File:</label>
              <input type="file" id="svgFile" ref="fileInput" @change="handleFileUpload" accept=".svg" class="form-control">
            </div>
          </div>
        </div>
      </div>

      <!-- CSS Settings Section -->
      <div class="col-12 col-lg-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">CSS Settings</h5>
            <div class="mb-3">
              <label for="cssClassName" class="form-label">CSS Class Name:</label>
              <input type="text" id="cssClassName" v-model="cssClassName" class="form-control" placeholder="e.g., my-svg-icon">
            </div>
            <div class="mb-3">
              <button @click="convertToCSS" class="btn btn-primary w-100" :disabled="!svgContent || !cssClassName">Convert</button>
            </div>
            <div class="mb-3">
              <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- CSS Output Section -->
      <div class="col-12 col-lg-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title mb-0">CSS Background Code</h5>
              <button @click="copyToClipboard" class="btn btn-outline-secondary btn-sm">
                <i :class="clipboardIcon" class="bi me-1"></i> Copy
              </button>
            </div>
            <pre v-if="cssCode" class="bg-light p-3 border mt-2">{{ cssCode }}</pre>
            <p v-if="error" class="text-danger">{{ error }}</p>
          </div>
        </div>
      </div>

      <!-- SVG Preview Section -->
      <div class="col-12 col-lg-6 mb-4">
        <div class="card h-100">
          <div class="card-body text-center">
            <h5 class="card-title">SVG Preview</h5>
            <div v-if="svgContent" class="svg-preview" v-html="sanitizedSvgContent"></div>
            <p v-if="!svgContent">SVG preview will appear here after you paste the code or upload a file.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-4">
      <div class="card-header">
        <h4 class="mb-0">Example CSS Output</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>SVG Content</th>
                <th>CSS Output</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(example, index) in examples" :key="index">
                <td><code>{{ example.svg }}</code></td>
                <td><code>{{ example.css }}</code></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  name: 'SVGToCSSConverter',
  components: {
    BreadCrumb
  },
  setup() {
    const svgContent = ref('');
    const cssClassName = ref('');
    const cssCode = ref('');
    const error = ref('');
    const svgFile = ref('');
    const clipboardIcon = ref('bi-clipboard');
    const fileInput = ref(null);

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file && file.type === 'image/svg+xml') {
        const reader = new FileReader();
        reader.onload = (e) => {
          svgContent.value = e.target.result;
          error.value = '';
        };
        reader.readAsText(file);
      } else {
        error.value = 'Please upload a valid SVG file.';
      }
    };

    const convertToCSS = () => {
      if (!svgContent.value || !cssClassName.value) {
        error.value = 'SVG content and CSS class name are required.';
        return;
      }

      try {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgContent.value, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');

        if (!svgElement) {
          throw new Error('Invalid SVG content.');
        }

        let cssOutput = `.${cssClassName.value} {\n`;
        cssOutput += `  width: ${svgElement.getAttribute('width') || 'auto'};\n`;
        cssOutput += `  height: ${svgElement.getAttribute('height') || 'auto'};\n`;
        cssOutput += `  background-image: url('data:image/svg+xml;utf8,${encodeURIComponent(svgElement.outerHTML)}');\n`;
        cssOutput += `  background-repeat: no-repeat;\n`;
        cssOutput += `  display: inline-block;\n`;
        cssOutput += '}';

        cssCode.value = cssOutput;
        error.value = '';
      } catch (e) {
        error.value = 'Failed to convert SVG to CSS. Please check your SVG content.';
        cssCode.value = '';
      }
    };

    const clearValues = () => {
      svgContent.value = '';
      cssClassName.value = '';
      cssCode.value = '';
      error.value = '';
      svgFile.value = '';
      clipboardIcon.value = 'bi-clipboard';
      if (fileInput.value) {
        fileInput.value.value = '';
      }
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(cssCode.value).then(() => {
        clipboardIcon.value = 'bi-clipboard-check';
        setTimeout(() => {
          clipboardIcon.value = 'bi-clipboard';
        }, 2000);
      });
    };

    const sanitizedSvgContent = computed(() => {
      return svgContent.value.replace(/<\?xml.*?\?>/g, '');
    });

    const examples = computed(() => [
      {
        svg: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" /></svg>',
        css: `.example-circle {\n  width: 100px;\n  height: 100px;\n  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" /></svg>');\n  background-repeat: no-repeat;\n  display: inline-block;\n}`
      },
      {
        svg: '<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" style="fill:blue;stroke-width:3;stroke:rgb(0,0,0)" /></svg>',
        css: `.example-rect {\n  width: 50px;\n  height: 50px;\n  background-image: url('data:image/svg+xml;utf8,<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" style="fill:blue;stroke-width:3;stroke:rgb(0,0,0)" /></svg>');\n  background-repeat: no-repeat;\n  display: inline-block;\n}`
      }
    ]);

    return {
      svgContent,
      cssClassName,
      cssCode,
      error,
      fileInput,
      clipboardIcon,
      handleFileUpload,
      convertToCSS,
      clearValues,
      copyToClipboard,
      sanitizedSvgContent,
      examples
    };
  }
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 1200px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.svg-preview {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 1rem;
  background: #f9f9f9;
  width: 50%;
  height: auto;
  overflow: hidden;
}

@media (max-width: 600px) {
  .custom-container {
    padding: 0 0.5rem;
  }
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
