<template>
	<BreadCrumb PageTitle="Random NBA Team Generator" />
	<div class="custom-container mt-5 mb-50">
	  <div class="mb-5">
		<h1 class="text-center">Random NBA Team Generator</h1>
		<p class="fs-6 text-center mb-4">Generate random unique NBA teams.</p>
	  </div>
	  <div class="col-md-12">
		<div class="mb-3">
		  <div class="d-flex justify-content-between">
			<label class="form-label" for="numNBA">Number of NBA Teams:</label>
			<label class="form-label" for="nbaDivision">Divisions:</label>
		  </div>
		  <div class="input-group">
			<input 
			  id="numNBA" 
			  class="form-control" 
			  type="number" 
			  min="1" 
			  :max="selectedDivision === 'all' ? 30 : 5" 
			  v-model.number="numTeams"
			/>
			<select id="nbaDivision" class="form-select" v-model="selectedDivision">
			  <option value="">Select...</option>
			  <option value="all">All</option>
			  <option v-for="division in divisions" :key="division" :value="division">
				{{ division.charAt(0).toUpperCase() + division.slice(1) }}
			  </option>
			</select>
		  </div>
		</div>
	  </div>
	  <div>
		<div id="prompt-alert" class="alert alert-warning mt-3" v-if="showPrompt">{{ promptMessage }}</div>
		<div class="col">
		  <button id="btn_generatePin" class="btn btn-primary mb-4 w-100" @click="generateRandomTeams">
			Generate Team
		  </button>
		</div>
	  </div>
	  <div class="col-md-12">
		<div>
		  <div>
			<h4 class="text-center">Random NBA Team List</h4>
		  </div>
		  <div class="team-group text-center">
			<div id="team-list" v-if="generatedTeams.length > 0" class="row">
			  <div v-for="team in generatedTeams" :key="team.name" class="col-md-4 mb-4">
				<div class="card">
				  <img :src="team.logo" class="card-img-top" :alt="team.name + ' logo'">
				  <div class="card-body">
					<h5 class="card-title">
					  <a :href="team.wikipedia" target="_blank">{{ team.name }}</a>
					</h5>
					<p class="card-text fs-6">{{ team.city }}, {{ team.state }}</p>
					<p class="card-text"><small class="text-muted">Venue: {{ team.venue }}</small></p>
				  </div>
				</div>
			  </div>
			</div>
			<div v-else>No teams generated yet</div>
		  </div>
		</div>
	  </div>
  
	  <!-- FAQ Section -->
	  <div class="mt-5">
		<RandomNBATeamGeneratorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import RandomNBATeamGeneratorFAQ from '../FAQs/randomNBATeamGeneratorFAQ.vue';
  
  export default {
	name: 'NBATeamGenerator',
	components: {
	  BreadCrumb,
	  RandomNBATeamGeneratorFAQ
	},
	setup() {
	  const nbaList = ref({});
	  const selectedDivision = ref('');
	  const numTeams = ref(1);
	  const generatedTeams = ref([]);
	  const showPrompt = ref(false);
	  const promptMessage = ref('');
  
	  const divisions = ref(['atlantic', 'central', 'southeast', 'northwest', 'pacific', 'southwest']);
  
	  const fetchNBATeams = async () => {
		try {
		  const response = await fetch('/nba_list.json');
		  if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		  }
		  const data = await response.json();
		  nbaList.value = data;
		  console.log('NBA teams data loaded:', nbaList.value);
		} catch (error) {
		  console.error('Error fetching NBA teams:', error);
		  showPrompt.value = true;
		  promptMessage.value = 'Error loading NBA teams data. Please try refreshing the page.';
		}
	  };
  
	  onMounted(fetchNBATeams);
  
	  const getRandomInt = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	  };
  
	  const generateRandomTeams = () => {
		console.log('Generate button clicked');
		console.log('Selected division:', selectedDivision.value);
		console.log('Number of teams:', numTeams.value);
  
		if (!selectedDivision.value || selectedDivision.value === 'Select...') {
		  showPrompt.value = true;
		  promptMessage.value = 'Please select a division.';
		  console.log('No division selected');
		  return;
		}
  
		showPrompt.value = false;
  
		let teams = selectedDivision.value === 'all'
		  ? Object.values(nbaList.value).flat()
		  : nbaList.value[selectedDivision.value] || [];
  
		console.log('Available teams:', teams);
  
		const randomTeams = [];
		const tempTeams = [...teams];
		while (randomTeams.length < numTeams.value && tempTeams.length > 0) {
		  const randomIndex = getRandomInt(0, tempTeams.length - 1);
		  randomTeams.push(tempTeams[randomIndex]);
		  tempTeams.splice(randomIndex, 1);
		}
  
		console.log('Generated teams:', randomTeams);
  
		generatedTeams.value = randomTeams;
	  };
  
	  return {
		selectedDivision,
		numTeams,
		generatedTeams,
		showPrompt,
		promptMessage,
		divisions,
		generateRandomTeams
	  };
	}
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  .card-img-top {
	height: 200px;
	object-fit: contain;
	padding: 1rem;
  }
  #team-list {
	justify-content: space-evenly;
  }
  </style>