<template>
    <BreadCrumb PageTitle="HTML To YAML Converter" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">HTML To YAML Converter</h1>
      <p class="text-center fs-6">Convert your HTML code into a structured YAML format.</p>
  
      <div class="row mb-4 fs-6">
        <div class="col-md-6 mb-4">
          <div class="markdown mb-2">
            <label for="inputHTML">Input (HTML):</label>
            <textarea id="inputHTML" class="form-control" rows="15" v-model="inputHTML"></textarea>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="markdown mb-2">
            <label>Output (YAML):</label>
            <textarea id="outputYAML" class="form-control" rows="15" readonly :value="outputYAML"></textarea>
          </div>
        </div>
      </div>
  
      <div class="text-center">
        <button class="btn btn-primary w-100" @click="handleConversion">Convert</button>
      </div>
  
      <div v-if="error" class="alert alert-danger mt-3 text-center">
        Error converting HTML to YAML!
      </div>

      <div>
        <HTMLToYAMLFAQ />
      </div>
    </div>
  </template>
  
  <script>
  import { parseDocument } from 'htmlparser2';
  import YAML from 'js-yaml';  // Import js-yaml for conversion to YAML
  import HTMLToYAMLFAQ from "../FAQs/htmlToYAMLFAQ.vue";
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  
  export default {
    components: {
      BreadCrumb,
      HTMLToYAMLFAQ
    },
    data() {
      return {
        inputHTML: '',  // Input HTML from the user
        outputYAML: '',  // Output YAML generated
        error: false,   // Flag to show error if any occurs
      };
    },
    methods: {
      handleConversion() {
        try {
          const structuredHTML = this.convertHTMLToJSON(this.inputHTML);
          this.outputYAML = YAML.dump(structuredHTML);  // Convert JSON to YAML
          this.error = false;
        } catch (e) {
          this.error = true;  // Show error message if conversion fails
          this.outputYAML = '';
        }
      },
      convertHTMLToJSON(html) {
        // Parse the HTML into a structured format using htmlparser2
        const document = parseDocument(html);
  
        // Recursively convert the document into a JSON-like structure
        const rootNode = document.children.filter(node => node.type === 'tag')[0]; // Get the main HTML node
  
        return this.traverseNodesToJSON(rootNode);
      },
      traverseNodesToJSON(node) {
        const obj = {};
  
        // Handle element (tag) nodes
        if (node.type === 'tag') {
          obj.tag = node.name;
  
          // Add attributes if they exist
          if (node.attribs && Object.keys(node.attribs).length > 0) {
            obj.attributes = node.attribs;
          }
  
          // Recursively process children if they exist
          if (node.children && node.children.length > 0) {
            obj.children = node.children
              .map(child => this.traverseNodesToJSON(child))
              .filter(child => Object.keys(child).length > 0);  // Remove empty objects
          }
        }
  
        // Handle text nodes
        if (node.type === 'text' && node.data.trim()) {
          obj.text = node.data.trim();
        }
  
        return obj;
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-container {
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .custom-container {
      padding: 0 0.5rem;
    }
  }
  .text-center {
    text-align: center;
  }
  textarea {
    resize: vertical;
    width: 100%;
    font-family: monospace;
    font-size: 14px;
  }
  .btn {
    padding: 10px 20px;
    font-size: 16px;
  }
  .alert {
    max-width: 600px;
    margin: 20px auto;
  }
  </style>
  