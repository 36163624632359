<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on OpenAI Pricing Calculator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the OpenAI Pricing Calculator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The OpenAI Pricing Calculator is a tool that helps you estimate the cost of using various OpenAI models for natural language processing tasks. It allows you to calculate the pricing for different models based on the length of your input prompts and the length of the output responses.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>What is the "Prompt Length (Words)"?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The "Prompt Length (Words)" refers to the number of words in the input text or prompt that you provide to the OpenAI model. This parameter affects the cost of using the model, as you are charged based on the number of tokens used in your input.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What is the "Answer Length (Words)"?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The "Answer Length (Words)" is the number of words you expect in the response generated by the OpenAI model. This parameter also impacts the cost, as you are billed for the tokens used in the model's output.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>How does the pricing calculation work?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The pricing calculation is based on the total number of tokens used, including both input and output tokens. Each OpenAI model has specific prices per 1,000 tokens for input and output. The calculator multiplies the number of input tokens by the input price and the number of output tokens by the output price to estimate the total cost.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>What OpenAI models are available?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The OpenAI models available in this calculator include:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>GPT-3.5-turbo 4K: This model is optimized for various natural language processing tasks and is suitable for tasks with a smaller number of tokens.</li>
                                    <li>GPT-3.5-turbo 16K: Similar to the 4K version, but with an extended token limit, making it suitable for longer text generation tasks.</li>
                                    <li>GPT-4 8K: GPT-4 offers advanced natural language processing capabilities and is designed for tasks requiring more extensive language understanding.</li>
                                    <li>GPT-4 32K: The 32K version of GPT-4 provides an even higher token limit, making it suitable for very long text generation tasks.</li>
                                    <li>Ada: Ada is known for its efficiency in natural language understanding tasks and is a versatile model for various applications.</li>
                                    <li>Babbage: Babbage is designed for straightforward text generation tasks and is suitable for simpler language generation needs.</li>
                                    <li>Curie: Curie is designed for specific language generation tasks and is a good choice for focused text generation applications.</li>
                                    <li>Davinci: Davinci is a powerful model capable of handling complex language tasks and is suitable for a wide range of applications, including creative writing and code generation.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Can I estimate the cost without an OpenAI API key?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, you can use this calculator to estimate the cost of using OpenAI models without requiring an OpenAI API key. The calculator uses predefined pricing data for each model, so you can get pricing estimates without making actual API requests.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>Is the pricing in the calculator up-to-date?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The pricing data in the calculator is based on the provided information and may not reflect the most current pricing from OpenAI. It's recommended to check OpenAI's official pricing page for the most accurate and up-to-date pricing information.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'OpenAiPricingCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
