<template>
	<BreadCrumb PageTitle="Subscript Generator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Subscript Generator</h1>
	  <p class="fs-6 text-center mb-4">Create subscript text easily with our free Subscript Generator. Copy formatted results in one click for instant use anywhere!</p>
	  <div class="row">
		<div class="col">
		  <form @submit.prevent="generateSubscript">
			<div class="form-group">
			  <div class="input-group mb-3">
				<span class="input-group-text" for="textInput">Enter Text:</span>
				<input type="text" class="form-control" id="textInput" v-model="inputText" placeholder="Input text here">
			  </div>
			  <div v-if="alert" class="alert alert-danger mb-3" role="alert">{{ alert }}</div>
			  <button type="submit" class="btn btn-primary w-100">Generate</button>
			</div>
		  </form>
		</div>
	  </div>
	  <!-- Options -->
	  <div class="row my-4">
		<div class="col">
		  <h4>Options:</h4>
		  <div class="form-check form-check-inline">
			<input class="form-check-input" type="checkbox" id="optionBold" v-model="options.bold" @change="reapplyFormatting">
			<label class="form-check-label" for="optionBold">Bold</label>
		  </div>
		  <div class="form-check form-check-inline">
			<input class="form-check-input" type="checkbox" id="optionItalic" v-model="options.italic" @change="reapplyFormatting">
			<label class="form-check-label" for="optionItalic">Italic</label>
		  </div>
		</div>
	  </div>
	  <!-- Output section for generated subscript -->
	  <div class="output-section mt-3 fs-5">
		<label for="outputSubscript">Generated Subscript:</label>
		<div class="input-group mb-3">
		  <input type="text" class="form-control fs-2" id="outputSubscript" :value="outputText" readonly>
		  <button class="btn btn-outline-secondary" type="button" @click="copyToClipboard" :title="copyButtonTitle">
			<i :class="copyIconClass"></i>
		  </button>
		</div>
	  </div>
	  
	  <!-- FAQ Section -->
	  <div class="mt-5">
		<SubscriptGeneratorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import SubscriptGeneratorFAQ from '../FAQs/subscriptGeneratorFAQ.vue';
  
  export default {
	name: 'SubscriptGenerator',
	components: {
	  BreadCrumb,
	  SubscriptGeneratorFAQ
	},
	setup() {
	  const inputText = ref('');
	  const outputText = ref('');
	  const alert = ref('');
	  const options = ref({
		bold: false,
		italic: false
	  });
	  const isCopied = ref(false);
	  const lastGenerated = ref('');
  
	  const subscriptMap = {
		'0': '₀', '1': '₁', '2': '₂', '3': '₃', '4': '₄', '5': '₅', '6': '₆', '7': '₇', '8': '₈', '9': '₉',
		'a': 'ₐ', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'ₑ', 'f': 'f', 'g': 'g', 'h': 'ₕ', 'i': 'ᵢ', 'j': 'ⱼ',
		'k': 'ₖ', 'l': 'ₗ', 'm': 'ₘ', 'n': 'ₙ', 'o': 'ₒ', 'p': 'ₚ', 'q': 'q', 'r': 'ᵣ', 's': 'ₛ', 't': 'ₜ',
		'u': 'ᵤ', 'v': 'ᵥ', 'w': 'w', 'x': 'ₓ', 'y': 'y', 'z': 'z',
		'A': 'ₐ', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'ₑ', 'F': 'F', 'G': 'G', 'H': 'ₕ', 'I': 'ᵢ', 'J': 'ⱼ',
		'K': 'ₖ', 'L': 'ₗ', 'M': 'ₘ', 'N': 'ₙ', 'O': 'ₒ', 'P': 'ₚ', 'Q': 'Q', 'R': 'ᵣ', 'S': 'ₛ', 'T': 'ₜ',
		'U': 'ᵤ', 'V': 'ᵥ', 'W': 'W', 'X': 'ₓ', 'Y': 'Y', 'Z': 'Z',
		'+': '₊', '-': '₋', '=': '₌', '(': '₍', ')': '₎'
	  };
  
	  const copyIconClass = computed(() => {
		return isCopied.value ? 'bi bi-clipboard-check' : 'bi bi-clipboard';
	  });
  
	  const copyButtonTitle = computed(() => {
		return isCopied.value ? 'Copied!' : 'Copy to Clipboard';
	  });
  
	  const generateSubscript = () => {
		if (!inputText.value.trim()) {
		  alert.value = 'Please enter some text to generate subscript.';
		  return;
		}
		alert.value = '';
		
		lastGenerated.value = inputText.value
		  .split('')
		  .map(char => subscriptMap[char] || char)
		  .join('');
  
		reapplyFormatting();
	  };
  
	  const reapplyFormatting = () => {
		let formattedText = lastGenerated.value;
		if (options.value.bold) {
		  formattedText = `<strong>${formattedText}</strong>`;
		}
		if (options.value.italic) {
		  formattedText = `<i>${formattedText}</i>`;
		}
		outputText.value = formattedText;
	  };
  
	  const copyToClipboard = () => {
		navigator.clipboard.writeText(outputText.value).then(() => {
		  isCopied.value = true;
		  setTimeout(() => {
			isCopied.value = false;
		  }, 2000);
		}).catch(err => {
		  console.error('Failed to copy text: ', err);
		});
	  };
  
	  return {
		inputText,
		outputText,
		alert,
		options,
		copyIconClass,
		copyButtonTitle,
		generateSubscript,
		reapplyFormatting,
		copyToClipboard
	  };
	}
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  </style>