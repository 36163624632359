<template>
  <BreadCrumb PageTitle="Simplifying Fractions Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Simplifying Fractions Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Simplify fractions to their lowest terms. Enter the numerator and denominator to simplify the fraction.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Numerator -->
        <div class="mb-3">
          <label for="numerator" class="form-label">Numerator:</label>
          <input
            type="number"
            id="numerator"
            v-model="numerator"
            class="form-control"
            placeholder="e.g., 8"
            step="1"
          />
        </div>

        <!-- Input for Denominator -->
        <div class="mb-3">
          <label for="denominator" class="form-label">Denominator:</label>
          <input
            type="number"
            id="denominator"
            v-model="denominator"
            class="form-control"
            placeholder="e.g., 12"
            step="1"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="simplifyFraction" class="btn btn-primary w-100">Simplify</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Original Fraction: {{ result.originalFraction }}</p>
          <p class="fs-5">Simplified Fraction: {{ result.simplifiedFraction }}</p>
          <p class="fs-5" v-if="result.mixedNumber">
            Mixed Number: {{ result.mixedNumber }}
          </p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the numerator (top number) of the fraction.</li>
        <li>Enter the denominator (bottom number) of the fraction.</li>
        <li>Click "Simplify" to reduce the fraction to its lowest terms.</li>
        <li>The result will display the original fraction, the simplified fraction, and, if applicable, the mixed number form.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Understanding Fraction Simplification:</h5>
      <p class="fs-6">
        Simplifying a fraction means reducing it to its lowest terms by dividing both the numerator and the denominator by their Greatest Common Divisor (GCD).
      </p>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          Simplify \( \frac{8}{12} \):
          <ul>
            <li>GCD of 8 and 12 is 4.</li>
            <li>Simplified Fraction: \( \frac{8 \div 4}{12 \div 4} = \frac{2}{3} \).</li>
          </ul>
        </li>
        <li>
          Simplify \( \frac{15}{10} \):
          <ul>
            <li>GCD of 15 and 10 is 5.</li>
            <li>Simplified Fraction: \( \frac{15 \div 5}{10 \div 5} = \frac{3}{2} \).</li>
            <li>Mixed Number: \( 1 \frac{1}{2} \).</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const numerator = ref(null);
    const denominator = ref(null);
    const result = ref(null);
    const error = ref("");

    // Function to simplify the fraction
    const simplifyFraction = () => {
      error.value = "";
      result.value = null;

      try {
        // Ensure values are not null or empty
        if (numerator.value === null || numerator.value === "") {
          throw new Error("Please enter a value for the numerator.");
        }
        if (denominator.value === null || denominator.value === "") {
          throw new Error("Please enter a value for the denominator.");
        }

        // Parse the input values
        const num = parseInt(numerator.value);
        const den = parseInt(denominator.value);

        if (isNaN(num) || isNaN(den)) {
          throw new Error("Please enter valid integer values.");
        }

        if (den === 0) {
          throw new Error("Denominator cannot be zero.");
        }

        // Find GCD
        const gcdValue = gcd(Math.abs(num), Math.abs(den));

        // Simplify fraction
        let simplifiedNum = num / gcdValue;
        let simplifiedDen = den / gcdValue;

        // Handle negative signs
        if (simplifiedDen < 0) {
          simplifiedNum *= -1;
          simplifiedDen *= -1;
        }

        let simplifiedFraction = `${simplifiedNum}/${simplifiedDen}`;

        // Check for improper fraction to mixed number
        let mixedNumber = null;
        if (Math.abs(simplifiedNum) >= Math.abs(simplifiedDen)) {
          const wholeNumber = Math.trunc(simplifiedNum / simplifiedDen);
          const remainder = Math.abs(simplifiedNum % simplifiedDen);
          if (remainder === 0) {
            simplifiedFraction = `${wholeNumber}`;
          } else {
            mixedNumber = `${wholeNumber} ${remainder}/${Math.abs(simplifiedDen)}`;
          }
        }

        result.value = {
          originalFraction: `${num}/${den}`,
          simplifiedFraction: simplifiedFraction,
          mixedNumber: mixedNumber,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to find GCD of two numbers
    const gcd = (a, b) => {
      return b === 0 ? a : gcd(b, a % b);
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      numerator.value = null;
      denominator.value = null;
      result.value = null;
      error.value = "";
    };

    return {
      numerator,
      denominator,
      result,
      error,
      simplifyFraction,
      clearValues,
    };
  },
};
</script>


<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
