<template>
    <BreadCrumb PageTitle="Slugging Percentage Calculator" />
  
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">Slugging Percentage Calculator</h1>
      <p class="fs-6 text-center mb-4">Calculate a player's slugging percentage based on their hits and at-bats.</p>
  
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="form-group mb-3">
                <label for="singles">Singles:</label>
                <input type="number" class="form-control" id="singles" v-model.number="singles" min="0" required>
              </div>
  
              <div class="form-group mb-3">
                <label for="doubles">Doubles:</label>
                <input type="number" class="form-control" id="doubles" v-model.number="doubles" min="0" required>
              </div>
  
              <div class="form-group mb-3">
                <label for="triples">Triples:</label>
                <input type="number" class="form-control" id="triples" v-model.number="triples" min="0" required>
              </div>
  
              <div class="form-group mb-3">
                <label for="home-runs">Home Runs:</label>
                <input type="number" class="form-control" id="home-runs" v-model.number="homeRuns" min="0" required>
              </div>
  
              <div class="form-group mb-3">
                <label for="at-bats">At Bats:</label>
                <input type="number" class="form-control" id="at-bats" v-model.number="atBats" min="0" required>
              </div>
  
              <button class="btn btn-primary d-block mx-auto w-100" @click="calculateSluggingPercentage">Calculate</button>
  
              <div class="card mt-4" v-if="sluggingPercentage !== null">
                <div class="card-body">
                  <h2 class="card-title text-center">Slugging Percentage</h2>
                  <p class="card-text text-center fs-1">{{ sluggingPercentage.toFixed(3) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div><SluggingPercentageCalculatorFAQ /></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import SluggingPercentageCalculatorFAQ from '../FAQs/sluggingPercentageCalculatorFAQ.vue';
  
  export default {
    name: 'SluggingPercentageCalculator',
    components: {
      BreadCrumb,
      SluggingPercentageCalculatorFAQ
    },
    data() {
      return {
        singles: 0,
        doubles: 0,
        triples: 0,
        homeRuns: 0,
        atBats: 0,
        sluggingPercentage: null
      };
    },
    methods: {
      calculateSluggingPercentage() {
        if (this.atBats <= 0) {
          return;
        }
  
        const totalBases = this.singles + (2 * this.doubles) + (3 * this.triples) + (4 * this.homeRuns);
        this.sluggingPercentage = totalBases / this.atBats;
      }
    }
  };
  </script>

<style scoped>
.custom-container {
  max-width: 80vh;
  margin: 0 auto;
  padding: 0 15px;
}
</style>