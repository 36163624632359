<template>
        <div class="card mb-25 mt-50 border-0 rounded-0 bg-white letter-spacing faq-accordion-card">
          <div class="card-body p-15 p-sm-20">
              <div class="accordion" id="faqAccordion">
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="text-center mb-3">
                              <h3>FAQs on Advanced Image Cropper</h3>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                                  <h5>What is the Advanced Image Cropper?</h5>
                              </button>
                              <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          The Advanced Image Cropper is a web tool that allows you to crop images to specific sizes and aspect ratios. It supports uploading images from your device or loading them from a URL, and provides predefined dimensions for various social media platforms.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                                  <h5>How do I upload an image?</h5>
                              </button>
                              <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          You can upload an image by clicking on the "Upload" button and selecting an image from your device. Alternatively, you can also paste a direct image URL into the provided input field and click "URL Import".
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                                  <h5>How do I crop the image to a specific aspect ratio?</h5>
                              </button>
                              <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          You can select one of the predefined aspect ratios by clicking on the respective button. If you want a custom aspect ratio, click on the "Custom" button and adjust the crop area as desired.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                                  <h5>How do I rotate the image?</h5>
                              </button>
                              <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          You can rotate the image to the left or right by 45 degrees using the respective rotate buttons.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                                  <h5>How do I download the cropped image?</h5>
                              </button>
                              <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          After cropping the image to your desired aspect ratio and making any other adjustments, click on the "Download" button. The cropped image will be downloaded to your device with the original image extension.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                                  <h5>What are the supported social media image dimensions?</h5>
                              </button>
                              <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          We support various social media image dimensions, including Facebook Story, Vertical post, Shared image, Page cover, Event cover, Group cover, Profile image, Ad (Carousel), Ad (Link), and News feed. Simply click on the respective button to crop your image to the desired dimension.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                                  <h5>How do I reset the cropper?</h5>
                              </button>
                              <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          Click on the "Reset" button to reset the cropper to its initial state. This will clear any existing cropping settings and allow you to start fresh.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEight" aria-expanded="true" aria-controls="faqCollapseEight">
                                  <h5>Can I flip the image?</h5>
                              </button>
                              <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          Yes, you can flip the image horizontally or vertically using the flip buttons.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseNine" aria-expanded="true" aria-controls="faqCollapseNine">
                                  <h5>How do I set a custom crop area?</h5>
                              </button>
                              <div id="faqCollapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          To set a custom crop area, click on the "Custom" button in the aspect ratio options. You can then adjust the crop area manually to fit your specific needs.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTen" aria-expanded="true" aria-controls="faqCollapseTen">
                                  <h5>What image formats are supported?</h5>
                              </button>
                              <div id="faqCollapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          The Advanced Image Cropper supports JPEG, PNG, and GIF image formats.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEleven" aria-expanded="true" aria-controls="faqCollapseEleven">
                                  <h5>How do I adjust the cropping area?</h5>
                              </button>
                              <div id="faqCollapseEleven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          You can adjust the cropping area by dragging the corners or edges of the crop box. You can also use the input fields to specify exact dimensions for the cropping area.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div class="accordion-item rounded-0 border-0">
                              <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwelve" aria-expanded="true" aria-controls="faqCollapseTwelve">
                                  <h5>Can I preview the cropped image before downloading?</h5>
                              </button>
                              <div id="faqCollapseTwelve" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                  <div class="accordion-body pb-0">
                                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                          Yes, the cropped area is displayed on the canvas, allowing you to preview the cropped image before downloading it.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </template>
  
  <script>
  export default {
    name: 'AdvancedImageCropperFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  