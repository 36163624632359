<template>
  <BreadCrumb PageTitle="Annular Velocity Calculator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Annular Velocity Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate annular velocity in ft/min for drilling operations using hole and pipe diameters and flow rate.
    </p>
    <form id="annularVelocityForm" @submit.prevent="calculateAnnularVelocity">
      <div class="form-group mb-4">
        <label for="holeDiameter">Hole Diameter (in inches):</label>
        <input type="number" class="form-control" id="holeDiameter" v-model.number="holeDiameter" required />
      </div>
      <div class="form-group mb-4">
        <label for="pipeDiameter">Pipe Diameter (in inches):</label>
        <input type="number" class="form-control" id="pipeDiameter" v-model.number="pipeDiameter" required />
      </div>
      <div class="form-group mb-4">
        <label for="flowRate">Flow Rate (in gallons per minute):</label>
        <input type="number" class="form-control" id="flowRate" v-model.number="flowRate" required />
      </div>
      <button type="submit" class="btn btn-primary w-100 mb-4">Calculate AV</button>
    </form>
    <div class="form-group mt-3 mb-3">
      <label for="annularVelocity">Annular Velocity (ft/min):</label>
      <textarea class="form-control" id="annularVelocity" v-model="annularVelocity" disabled></textarea>
    </div>
    <div>
      <AnnularVelocityCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AnnularVelocityCalculatorFAQ from '../FAQs/annularVelocityCalculatorFAQ.vue';

export default {
  components: {
    BreadCrumb,
    AnnularVelocityCalculatorFAQ
  },
  data() {
    return {
      holeDiameter: null,
      pipeDiameter: null,
      flowRate: null,
      annularVelocity: '-',
    };
  },
  methods: {
    calculateAnnularVelocity() {
      const holeDiameter = parseFloat(this.holeDiameter);
      const pipeDiameter = parseFloat(this.pipeDiameter);
      const flowRate = parseFloat(this.flowRate);

      if (isNaN(holeDiameter) || isNaN(pipeDiameter) || isNaN(flowRate)) {
        this.annularVelocity = "Please enter valid numbers for all fields.";
        return;
      }

      const area = Math.PI * (Math.pow(holeDiameter / 2, 2) - Math.pow(pipeDiameter / 2, 2)) / 144; // Area in square feet
      if (area === 0) {
        this.annularVelocity = "Invalid diameters: Area cannot be zero.";
        return;
      }

      const annularVelocity = (flowRate / area) / 7.48; // Velocity in ft/min (1 gallon = 7.48 cubic feet)

      this.annularVelocity = annularVelocity.toFixed(2) + " ft/min";
    },
  },
};
</script>

<style scoped>
.custom-container {
  width: 90vw;              /* Takes up 90% of the viewport width */
  max-width: 800px;         /* Prevents it from getting too wide on large screens */
  margin: auto;             /* Centers the container */
  padding: 0 1rem;          /* Adds horizontal padding for better spacing */
  box-sizing: border-box;   /* Ensures padding is included in the width */
}

label {
  font-size: 16px;
}

.dark .accordion-item {
  color: #BCBBC7 !important;
  font-size: 16px;
}

.accordion-item {
  font-size: 16px;
}

.accordion-item text-paragraph {
  font-size: 18px;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .custom-container {
    width: 95vw;            /* Slightly wider on very small screens */
    padding: 0 0.5rem;      /* Adjust padding for smaller screens */
  }
}
</style>
