<template>
    <BreadCrumb PageTitle="Color Converter" />
    <div class="color-converter-container">
      <h1 class="text-center">Color Converter</h1>
      <p class="text-center fs-6">Convert between RGB, HEX, HSL, and Named colors</p>
  
      <div class="converter-form">
        <div class="form-group fs-6">
          <label for="colorInput">Enter a color:</label>
          <input
            type="text"
            id="colorInput"
            v-model="colorInput"
            class="form-control"
            :placeholder="getPlaceholder"
          />
        </div>
  
        <div class="form-group mt-2">
          <label for="conversionType" class="fs-6">Select Conversion Type:</label>
          <select v-model="conversionType" id="conversionType" class="form-control" @change="handleConversionChange">
            <option value="rgbToHex">RGB to HEX</option>
            <option value="hexToRgb">HEX to RGB</option>
            <option value="rgbToHsl">RGB to HSL</option>
            <option value="hexToHsl">HEX to HSL</option>
            <option value="hslToRgb">HSL to RGB</option>
            <option value="hslToHex">HSL to HEX</option>
            <option value="colorToRgb">Named Color to RGB</option>
            <option value="colorToHex">Named Color to HEX</option>
            <option value="colorToHsl">Named Color to HSL</option>
          </select>
        </div>
  
        <button class="btn btn-primary mt-3 w-100" @click="convertColor">Convert</button>
  
        <div v-if="result" class="result-container mt-4 text-center">
            <h4>Conversion Result:</h4>
            <div class="color-preview-box" :style="{ backgroundColor: resultColor }"></div>
            <p class="fs-4">{{ result }}</p>
            <button class="btn btn-secondary mt-2" @click="copyToClipboard">
                <i :class="clipboardIcon"></i> <!-- Icon class binding -->
            </button>
        </div>  
        <div v-if="error" class="error-message text-danger mt-3 text-center">
          <p>{{ error }}</p>
        </div>
      </div>

      <!-- Conversion Links -->
      <div class="conversion-links mt-4 mb-4 fs-6">
        <h5>Quick Links to Other Conversions</h5>
        <ul class="list-unstyled">
          <li><router-link to="/color-converter/rgb-to-hex">RGB to HEX</router-link></li>
          <li><router-link to="/color-converter/hex-to-rgb">HEX to RGB</router-link></li>
          <li><router-link to="/color-converter/rgb-to-hsl">RGB to HSL</router-link></li>
          <li><router-link to="/color-converter/hex-to-hsl">HEX to HSL</router-link></li>
          <li><router-link to="/color-converter/hsl-to-rgb">HSL to RGB</router-link></li>
          <li><router-link to="/color-converter/hsl-to-hex">HSL to HEX</router-link></li>
          <li><router-link to="/color-converter/color-to-rgb">Named Color to RGB</router-link></li>
          <li><router-link to="/color-converter/color-to-hex">Named Color to HEX</router-link></li>
          <li><router-link to="/color-converter/color-to-hsl">Named Color to HSL</router-link></li>
        </ul>
      </div>
  
      <div class="faq-section mt-5">
        <h2 class="text-center">FAQ - Color Formats and Conversion Formulas</h2>
        <div v-for="(faq, index) in faqList" :key="index" class="faq-item">
          <h4>{{ faq.question }}</h4>
          <p v-html="faq.answer" class="fs-6"></p>
          <p class="latex-formula fs-6" v-html="faq.formula"></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { onMounted, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";

  import {
    rgbToHex,
    hexToRgb,
    rgbToHsl,
    hexToHsl,
    hslToRgb,
    hslToHex,
    colorToRgb,
    colorToHex,
    colorToHsl,
  } from '@nawael/color-converter.js';
  
  export default {
  name: 'ColorConverter',
  components: {
    BreadCrumb
  },
  setup() {
    const colorInput = ref('');
    const conversionType = ref('rgbToHex');
    const result = ref(null);
    const resultColor = ref('#fff');
    const error = ref(null);
    const router = useRouter();
    const route = useRoute();
    const copied = ref(false); // State to track whether copied or not

    // Define valid conversion types and slugs
    const conversionMapping = {
      'rgb-to-hex': 'rgbToHex',
      'hex-to-rgb': 'hexToRgb',
      'rgb-to-hsl': 'rgbToHsl',
      'hex-to-hsl': 'hexToHsl',
      'hsl-to-rgb': 'hslToRgb',
      'hsl-to-hex': 'hslToHex',
      'color-to-rgb': 'colorToRgb',
      'color-to-hex': 'colorToHex',
      'color-to-hsl': 'colorToHsl',
    };

    // Watch the route for changes to update conversion type
    watch(route, (newRoute) => {
      const slug = newRoute.params.slug;
      if (slug && conversionMapping[slug]) {
        conversionType.value = conversionMapping[slug]; // Update conversion type based on URL slug
      } else {
        conversionType.value = 'rgbToHex'; // Default to 'RGB to HEX' if the slug doesn't exist
      }
    });

    // Initialize conversion type based on the URL when the component is mounted
    onMounted(() => {
      const slug = route.params.slug;
      if (slug && conversionMapping[slug]) {
        conversionType.value = conversionMapping[slug]; // Set conversion type if slug is valid
      } else {
        conversionType.value = 'rgbToHex'; // Default to 'RGB to HEX'
      }
    });

    // Handle conversion type change and update the URL
    const handleConversionChange = () => {
      result.value = null;
      error.value = null;
      const slug = Object.keys(conversionMapping).find(key => conversionMapping[key] === conversionType.value);
      if (slug) {
        router.push(`/color-converter/${slug}`);
      }
    };
  
      // Dynamic placeholders based on the selected conversion type
      const getPlaceholder = computed(() => {
        switch (conversionType.value) {
          case 'rgbToHex':
          case 'rgbToHsl':
            return 'e.g., rgb(255, 87, 51)';
          case 'hexToRgb':
          case 'hexToHsl':
            return 'e.g., #ff5733';
          case 'hslToRgb':
          case 'hslToHex':
            return 'e.g., hsl(340, 100%, 60%)';
          case 'colorToRgb':
          case 'colorToHex':
          case 'colorToHsl':
            return 'e.g., CornflowerBlue';
          default:
            return 'Enter a color value';
        }
      });
  
      // FAQ list with JSON input and LaTeX formulae
      const faqList = ref([
        {
          question: 'What is RGB?',
          answer:
            'RGB stands for Red, Green, and Blue. It is a color model used to represent colors in digital systems.',
          formula: '$$ RGB(255, 0, 0) = \\text{Red} $$',
        },
        {
          question: 'What is HEX?',
          answer:
            'HEX is a hexadecimal representation of RGB values. It is commonly used in web design to define colors.',
          formula: '$$ \\text{HEX} = \\#RRGGBB $$',
        },
        {
          question: 'How do you convert RGB to HEX?',
          answer:
            'To convert RGB to HEX, each value in the RGB triplet is converted to a two-digit hexadecimal number.',
          formula: '$$ \\text{HEX} = \\#(R_{hex})(G_{hex})(B_{hex}) $$',
        },
        {
          question: 'What is HSL?',
          answer:
            'HSL stands for Hue, Saturation, and Lightness. It is a cylindrical-coordinate representation of colors.',
          formula: '$$ HSL(h, s, l) $$',
        },
        {
          question: 'How do you convert RGB to HSL?',
          answer:
            'To convert RGB to HSL, you calculate the hue, saturation, and lightness based on the RGB values.',
          formula:
            '$$ H = \\frac{\\Delta}{360}, S = \\frac{\\Delta}{1 - |2L - 1|}, L = \\frac{max(R,G,B) + min(R,G,B)}{2} $$',
        },
        {
          question: 'What is a named color?',
          answer:
            'A named color is a predefined color name recognized in CSS, like "CornflowerBlue".',
          formula: 'Named color formulae are predefined in CSS standards.',
        },
      ]);
  
      // Validate input based on selected conversion type
      const validateInput = () => {
        const patterns = {
          rgb: /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/,
          hex: /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/,
          hsl: /^hsl\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$/,
        };
        const value = colorInput.value;
        switch (conversionType.value) {
          case 'rgbToHex':
          case 'rgbToHsl':
            return patterns.rgb.test(value);
          case 'hexToRgb':
          case 'hexToHsl':
            return patterns.hex.test(value);
          case 'hslToRgb':
          case 'hslToHex':
            return patterns.hsl.test(value);
          case 'colorToRgb':
          case 'colorToHex':
          case 'colorToHsl':
            return typeof value === 'string'; // Assume valid CSS color name
          default:
            return false;
        }
      };
  
      const getErrorMessage = () => {
        switch (conversionType.value) {
          case 'rgbToHex':
          case 'rgbToHsl':
            return 'Input a valid RGB value. Example: rgb(255, 87, 51).';
          case 'hexToRgb':
          case 'hexToHsl':
            return 'Input a valid HEX value. Example: #ff5733.';
          case 'hslToRgb':
          case 'hslToHex':
            return 'Input a valid HSL value. Example: hsl(340, 100%, 60%).';
          case 'colorToRgb':
          case 'colorToHex':
          case 'colorToHsl':
            return 'Input a valid named color. Example: CornflowerBlue.';
          default:
            return 'Invalid input.';
        }
      };
  
      // Convert the color based on the selected conversion type
      const convertColor = () => {
        error.value = null;
        result.value = null;
  
        if (!validateInput()) {
          error.value = getErrorMessage();
          return;
        }
  
        try {
        switch (conversionType.value) {
          case 'rgbToHex':
            result.value = rgbToHex(colorInput.value);
            resultColor.value = result.value;
            break;
          case 'hexToRgb':
            result.value = hexToRgb(colorInput.value);
            resultColor.value = colorInput.value;
            break;
          case 'rgbToHsl':
            result.value = rgbToHsl(colorInput.value);
            resultColor.value = colorInput.value;
            break;
          case 'hexToHsl':
            result.value = hexToHsl(colorInput.value);
            resultColor.value = colorInput.value;
            break;
          case 'hslToRgb':
            result.value = hslToRgb(colorInput.value);
            resultColor.value = result.value;
            break;
          case 'hslToHex':
            result.value = hslToHex(colorInput.value);
            resultColor.value = result.value;
            break;
          case 'colorToRgb':
            result.value = colorToRgb(colorInput.value);
            resultColor.value = result.value;
            break;
          case 'colorToHex':
            result.value = colorToHex(colorInput.value);
            resultColor.value = result.value;
            break;
          case 'colorToHsl':
            result.value = colorToHsl(colorInput.value);
            resultColor.value = colorInput.value;
            break;
          default:
            throw new Error('Invalid conversion type');
        }
      } catch (e) {
        error.value = `Error: ${e.message}. Please check your input format.`;
      }
    };

    const clipboardIcon = computed(() => (copied.value ? 'bi-clipboard-check' : 'bi-clipboard'));

    const copyToClipboard = () => {
      if (result.value) {
        navigator.clipboard.writeText(result.value).then(() => {
          copied.value = true; // Set copied state to true
          setTimeout(() => {
            copied.value = false; // Revert back to original icon after 2 seconds
          }, 2000);
        });
      }
    };

    return {
      colorInput,
      conversionType,
      result,
      resultColor,
      error,
      faqList,
      getPlaceholder,
      convertColor,
      clipboardIcon,
      copyToClipboard,
      handleConversionChange,
    };
  },
};
</script>

<style scoped>
.color-converter-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.text-center {
  text-align: center;
}

.converter-form {
  margin: 20px 0;
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-preview-box {
  width: 100px;
  height: 100px;
  margin: 10px 0;
  border: 1px solid #ccc;
}

.faq-section {
  margin-top: 50px;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h5 {
  font-weight: bold;
}

.latex-formula {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}

.bi-clipboard,
.bi-clipboard-check {
  font-size: 1.2rem;
}

.conversion-links h5 {
  margin-bottom: 15px;
}

.conversion-links ul {
  padding-left: 0;
}

.conversion-links li {
  margin-bottom: 10px;
}

.conversion-links a {
  color: #76a1ff;
  text-decoration: none;
}

.conversion-links a:hover {
  text-decoration: underline;
}

</style>
