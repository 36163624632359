<template>
      <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
            <div class="row">
                <div class="text-center mb-3">
                <h3>FAQs on Remove Unwanted Characters Tool</h3>
                </div>
                
                <div class="accordion-item rounded-0 border-0">
                <button
                    class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseOne"
                    aria-expanded="true"
                    aria-controls="faqCollapseOne"
                >
                    <h5>What is the "Remove Unwanted Characters" Tool?</h5>
                </button>
                <div
                    id="faqCollapseOne"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#faqAccordion"
                >
                    <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        The "Remove Unwanted Characters" tool allows users to quickly and easily remove specific characters from a block of text. This can be useful for cleaning up text, preparing data for analysis, or formatting content for various purposes.
                    </p>
                    </div>
                </div>
                </div>

                <div class="accordion-item rounded-0 border-0">
                <button
                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseTwo"
                    aria-expanded="false"
                    aria-controls="faqCollapseTwo"
                >
                    <h5>How to Use the Tool</h5>
                </button>
                <div
                    id="faqCollapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                >
                    <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        To use the "Remove Unwanted Characters" tool, follow these steps:
                        <ol class="list-group">
                        <li>Enter the characters you want to remove in the "Characters to Remove" input field.</li>
                        <li>Paste or type the text you want to process in the "Text to Process" textarea.</li>
                        <li>Click the "Remove Characters" button to clean up the text.</li>
                        <li>If you need to start over, click the "Clear" button to reset both fields.</li>
                        <li>Use the "Copy" button to copy the cleaned text to your clipboard for further use.</li>
                        </ol>
                    </p>
                    </div>
                </div>
                </div>

                <div class="accordion-item rounded-0 border-0">
                <button
                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseThree"
                    aria-expanded="false"
                    aria-controls="faqCollapseThree"
                >
                    <h5>Key Features of the Tool</h5>
                </button>
                <div
                    id="faqCollapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                >
                    <div class="accordion-body pb-0">
                    <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        The "Remove Unwanted Characters" tool offers several key features:
                        <ul>
                        <li><b>Character Specific Removal</b>: Easily remove any characters you specify.</li>
                        <li><b>Real-time Processing</b>: Immediate removal of characters upon clicking the button.</li>
                        <li><b>Clipboard Support</b>: Quickly copy the processed text to your clipboard.</li>
                        <li><b>User-friendly Interface</b>: Simple and intuitive design for ease of use.</li>
                        <li><b>Clear Function</b>: Reset the fields with a single click to start fresh.</li>
                        </ul>
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
</template>
  
  <script>
  export default {
    name: 'RemoveUnwantedCharactersFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  