<template>
    <BreadCrumb PageTitle="HTML To XML Converter" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">HTML To XML Converter</h1>
      <p class="text-center fs-6 mb-4">Convert your HTML code into a well-structured XML format.</p>
  
      <div class="row mb-4 fs-6">
        <div class="col-md-6 mb-4">
          <div class="markdown mb-2">
            <label for="inputHTML">Input (HTML):</label>
            <textarea id="inputHTML" class="form-control" rows="15" v-model="inputHTML"></textarea>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="markdown mb-2">
            <label>Output (XML):</label>
            <div class="position-relative output-wrapper">
              <textarea id="outputXML" class="form-control" rows="15" readonly :value="outputXML"></textarea>
              <i 
                :class="['bi', copied ? 'bi-clipboard-check' : 'bi-clipboard', 'copy-icon']" 
                @click="copyToClipboard"
                :title="copied ? 'Copied!' : 'Copy to clipboard'"
              ></i>
            </div>
          </div>
        </div>
      </div>
  
      <div class="text-center">
        <button class="btn btn-primary w-100" @click="handleConversion">Convert</button>
      </div>
  
      <div v-if="error" class="alert alert-danger mt-3 text-center">
        Error converting HTML to XML!
      </div>
  
      <div>
        <HTMLToXMLFAQ />
      </div>
    </div>
  </template>
  
  <script>
  import { parseDocument } from 'htmlparser2';
  import { create } from 'xmlbuilder2';
  import HTMLToXMLFAQ from "../FAQs/htmlToXMLFAQ.vue"
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  
  export default {
    components: {
      BreadCrumb,
      HTMLToXMLFAQ
    },
    data() {
      return {
        inputHTML: '',  // Input HTML from the user
        outputXML: '',  // Output XML generated
        error: false,   // Flag to show error if any occurs
        copied: false,  // Flag to track if the text was copied to clipboard
      };
    },
    methods: {
      handleConversion() {
        try {
          this.outputXML = this.convertHTMLToXML(this.inputHTML);
          this.error = false;  // Clear error state on successful conversion
        } catch (e) {
          this.error = true;  // Show error message if conversion fails
        }
      },
      convertHTMLToXML(html) {
        // Parse the HTML string using htmlparser2
        const document = parseDocument(html);
  
        // Create the root XML document using xmlbuilder2
        const xmlRoot = create().ele('root');
  
        // Recursively process the HTML nodes into XML
        this.traverseNodes(document, xmlRoot);
  
        // Return the formatted XML output with prettyPrint
        return xmlRoot.end({ prettyPrint: true });
      },
      traverseNodes(node, xmlParent) {
        // Iterate over each child node in the HTML
        node.childNodes.forEach((child) => {
          if (child.type === 'text') {
            // Append text content for text nodes
            if (child.data.trim()) {
              xmlParent.txt(child.data.trim());
            }
          } else if (child.type === 'tag') {
            // Create new XML elements for each HTML element
            const xmlChild = xmlParent.ele(child.name);
  
            // Add attributes to the XML elements
            if (child.attribs) {
              Object.entries(child.attribs).forEach(([name, value]) => {
                xmlChild.att(name, value);
              });
            }
  
            // Recursively process the child nodes
            this.traverseNodes(child, xmlChild);
          }
        });
      },
      copyToClipboard() {
        navigator.clipboard.writeText(this.outputXML).then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .custom-container {
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .custom-container {
      padding: 0 0.5rem;
    }
  }
  .text-center {
    text-align: center;
  }
  textarea {
    resize: vertical;
    width: 100%;
    font-family: monospace;
    font-size: 14px;
  }
  .output-wrapper {
    position: relative;
  }
  .copy-icon {
    position: absolute;
    bottom: 10px;
    right: 25px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #6c757d;
    z-index: 1;
  }
  .copy-icon:hover {
    color: #495057;
  }
  .btn {
    padding: 10px 20px;
    font-size: 16px;
  }
  .alert {
    max-width: 600px;
    margin: 20px auto;
  }
  </style>
  