<template>
  <BreadCrumb PageTitle="Annualized Loss Expectancy (ALE) Calculator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Annualized Loss Expectancy (ALE) Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Use this ALE Calculator to estimate your annual financial risk from potential threats, helping you make informed security investments.
    </p>
    <form @submit.prevent="calculateALE" id="aleForm">
      <div class="form-group">
        <div class="input-group mb-3">
          <span class="input-group-text" for="sle">Single Loss Expectancy (SLE)</span>
          <input type="number" class="form-control" id="sle" v-model.number="sle" min="0" required />
        </div>
      </div>
      <div class="form-group">
        <div class="input-group mb-3">
          <span class="input-group-text" for="aro">Annual Rate of Occurrence (ARO)</span>
          <input type="number" class="form-control" id="aro" v-model.number="aro" min="0" required />
        </div>
      </div>
      <button type="submit" class="btn btn-primary w-100">Calculate ALE</button>
    </form>
    <div id="result" class="mt-3 mb-4">
      <textarea class="form-control fs-6" rows="3" readonly v-model="aleMessage"></textarea>
    </div>
    <div>
      <AnnualizedLossExpectancyCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AnnualizedLossExpectancyCalculatorFAQ from '../FAQs/annualizedLossExpectancyCalculatorFAQ.vue';

export default {
  components: {
    BreadCrumb,
    AnnualizedLossExpectancyCalculatorFAQ
  },
  data() {
    return {
      sle: 3,
      aro: 4,
      ale: 12,
    };
  },
  computed: {
    aleMessage() {
      return `The Annualized Loss Expectancy (ALE) is $ ${this.ale}`;
    },
  },
  methods: {
    calculateALE() {
      if (this.sle !== null && this.aro !== null) {
        this.ale = this.sle * this.aro;
      } else {
        this.ale = null;
      }
    },
  },
};
</script>

<style scoped>
.custom-container {
  width: 90vw;              /* Takes up 90% of the viewport width */
  max-width: 800px;         /* Prevents it from getting too wide on large screens */
  margin: auto;             /* Centers the container */
  padding: 0 1rem;          /* Optional: Adds horizontal padding for better spacing */
  box-sizing: border-box;   /* Ensures padding is included in the width */
}

label {
  font-size: 16px;
}

/* Optional: Further responsiveness */
@media (max-width: 600px) {
  .custom-container {
    width: 95vw;            /* Slightly wider on very small screens */
    padding: 0 0.5rem;      /* Adjust padding for smaller screens */
  }
}
</style>
