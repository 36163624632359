<template>
  <div class="faq-section mx-auto mb-50">
    <h2 class="faq-title">How to Play {{ `${gameName}` }}</h2>
    <div class="faq-inst">
      <h3>Step 1: Start the Game</h3>
      <p>Click "Start Game" button and you will be taken to the game screen. On the game screen, the timer will start immediately and you will be presented with the first letters to unscramble.</p>
      <h3>Step 2: Unscramble the Word</h3>
      <p>Look at the scrambled letters and try to unscramble them. See the "Hint" which describes what the word is and also see the "Word Starts With" which shows you the first letter of the word you are looking for. Enter your answer into the text box provided. Remember, you're against the clock!</p>
      <h3>Step 3: Submit Your Answer</h3>
      <p>Once you think you've figured out the word, click the "Check Word" button. If you're correct, you'll move on to the next word. However, if you are wrong, you will still have some time to try and unjumble the letters. If you struggle to find the word, you have an option to reload a new set of letters by clicking on the "Refresh Word" button. When you do this, the timer will reset to 30 seconds.</p>
      <h3>Step 4: Keep Going!</h3>
      <p>Continue unscrambling every letter until the timer runs out. The more words you solve, the higher your score!</p>
      <h3>Step 5: End of the Game</h3>
      <p>When the timer runs out, the game will end. Check your final score and see if you've beaten your previous best!</p>
      <h3>Step 6: Save Score to Leaderboard</h3>
      <p>If this is your first time of playing the game, you will be prompted to save your score to the global leaderboard. Enter a unique name to save score. You will be prompted to use a different name if your name already exists in leaderboard!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQs',
  props: {
    gameName: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.faq-title {
  border-bottom: 1px solid #606e85;
  padding-bottom: 10px; 
  margin-bottom: 20px;
}

.faq-inst p {
  font-size: 1rem;
  text-align: left;
}

.faq-inst h3 {
  text-align: left;
  font-size: 1.6rem;;
}
</style>
