<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Random Question Generator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the Random Question Generator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Random Question Generator is a web application designed to help you spice up conversations and discussions. It provides you with a collection of random questions in various categories, perfect for icebreakers, deep discussions, or simply adding fun to your interactions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How Does It Work?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Random Question Generator allows you to choose from different categories of questions, such as icebreakers, relationships, fun and games, creative topics, and personal growth. You can also specify the number of questions you'd like to generate, up to a maximum of five at a time.
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Once you've made your selections, click the "Get Questions" button. The generator will randomly select questions from your chosen category and display them for you to use in your conversations or activities.
                                </p>
                            </div>
                        </div>
                    </div>
					<div class="accordion-item rounded-0 border-0">
						<button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
							<h5>How to Use the Random Question Generator</h5>
						</button>
						<div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
							<div class="accordion-body pb-0">
								<div class="fs-md-15 fs-lg-16 text-paragraph lh-base">
									<p>1. Select a category from the dropdown menu.</p>
									<p>2. Choose the number of questions you want (up to 5).</p>
									<p>3. Click the "Get Questions" button.</p>
									<p>4. Copy the generated questions to your clipboard using the copy icon.</p>
									<p>5. Paste the questions into your conversation, presentation, or discussion.</p>
									<p>6. Enjoy engaging and thought-provoking discussions with the help of the Random Question Generator!</p>
								</div>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'randomQuestionGeneratorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
