<template>
	<BreadCrumb PageTitle="Collatz Conjecture Calculator" />
	<div class="custom-container mt-4 mb-50">
	  <h1 class="text-center">Collatz Conjecture Calculator</h1>
	  <p class="fs-6 text-center mb-4">Discover patterns in numbers with the Collatz Conjecture Calculator - a simple tool for exploring a famous mathematical sequence.</p>
	  <div class="input-group mb-3">
		<input 
		  type="number" 
		  class="form-control me-2" 
		  placeholder="Enter a positive integer" 
		  v-model="inputNumber" 
		  aria-label="Positive integer input"
		>
		<button 
		  class="btn btn-primary" 
		  type="button" 
		  @click="calculateCollatz"
		>
		  Calculate
		</button>
	  </div>
	  <div 
		class="alert alert-danger mb-3" 
		role="alert" 
		v-if="errorMessage"
	  >
		{{ errorMessage }}
	  </div>
	  <div v-if="sequence.length > 0">
		<h2 class="mt-4">Results:</h2>
		<p class="fs-5">Number of steps: {{ sequence.length - 1 }}</p>
		<p class="fs-5">Highest number reached: {{ Math.max(...sequence) }}</p>
		<h3 class="mt-4">Sequence:</h3>
		<div class="table-responsive">
		  <table class="table">
			<thead class="fs-5">
			  <tr>
				<th>#Step</th>
				<th>Value</th>
			  </tr>
			</thead>
			<tbody>
			  <tr v-for="(number, index) in sequence" :key="index">
				<td>{{ index }}</td>
				<td>{{ number }}</td>
			  </tr>
			</tbody>
		  </table>
		</div>
		<h3 class="mt-4">Chart:</h3>
		<div class="card">
		  <div class="card-body p-2">
			<div ref="chartContainer" style="width: 100%; height: 400px;">
			  <canvas ref="chartRef"></canvas>
			</div>
		  </div>
		</div>
	  </div>
	  <div>
		<CollatzConjectureCalculatorFAQ />
	  </div>
	</div>
</template>

<script>
import { ref, onMounted, watch, nextTick } from 'vue';
import Chart from 'chart.js/auto';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import CollatzConjectureCalculatorFAQ from '../FAQs/collatzConjectureCalculatorFAQ.vue';

export default {
	name: 'CollatzConjectureCalculator',
	components: {
	  BreadCrumb,
	  CollatzConjectureCalculatorFAQ
	},
	setup() {
	  const inputNumber = ref('');
	  const errorMessage = ref('');
	  const sequence = ref([]);
	  const chartRef = ref(null);
	  const chartInstance = ref(null);
	  const chartContainer = ref(null);

	  const calculateCollatz = () => {
		errorMessage.value = '';
		sequence.value = [];
		
		const startNumber = parseInt(inputNumber.value);

		if (isNaN(startNumber) || startNumber <= 0 || !Number.isInteger(startNumber)) {
		  errorMessage.value = 'Please enter a positive integer.';
		  return;
		}

		let currentNumber = startNumber;
		sequence.value.push(currentNumber);

		while (currentNumber !== 1) {
		  if (currentNumber % 2 === 0) {
			currentNumber = currentNumber / 2;
		  } else {
			currentNumber = 3 * currentNumber + 1;
		  }
		  sequence.value.push(currentNumber);

		  if (sequence.value.length > 1000) {
			errorMessage.value = 'Calculation stopped: Sequence exceeds 1000 steps.';
			return;
		  }
		}

		console.log('Sequence calculated:', sequence.value);
	  };

	  const createChart = () => {
		console.log('Creating chart...');
		if (!chartRef.value) {
		  console.error('Chart canvas element not found');
		  return;
		}

		if (chartInstance.value) {
		  console.log('Destroying old chart instance');
		  chartInstance.value.destroy();
		}

		try {
		  const ctx = chartRef.value.getContext('2d');
		  chartInstance.value = new Chart(ctx, {
			type: 'line',
			data: {
			  labels: sequence.value.map((_, index) => index),
			  datasets: [{
				label: 'Collatz Sequence',
				data: sequence.value,
				borderColor: 'rgb(75, 192, 192)',
				tension: 0.1
			  }]
			},
			options: {
			  responsive: true,
			  maintainAspectRatio: false,
			  scales: {
				y: {
				  beginAtZero: true
				}
			  }
			}
		  });
		  console.log('Chart created successfully');
		} catch (error) {
		  console.error('Error creating chart:', error);
		}
	  };

	  watch(sequence, () => {
		if (sequence.value.length > 0) {
		  nextTick(() => {
			console.log('Sequence updated, creating chart on next tick');
			createChart();
		  });
		}
	  });

	  onMounted(() => {
		console.log('Component mounted');
		if (sequence.value.length > 0) {
		  console.log('Initial sequence exists, creating chart');
		  createChart();
		}
	  });

	  return {
		inputNumber,
		errorMessage,
		sequence,
		calculateCollatz,
		chartRef,
		chartContainer
	  };
	}
};
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

label {
  font-size: 16px;
}

textarea {
  resize: none;
  overflow: hidden;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
