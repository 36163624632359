<template>
  <BreadCrumb PageTitle="Multiplying Fractions Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Multiplying Fractions Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two or more fractions to calculate their product. The calculator will show the result in both fraction and decimal formats along with the calculation steps.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Fractions -->
        <div class="mb-3" v-for="(fraction, index) in fractions" :key="index">
          <label :for="'fraction' + index" class="form-label">Fraction {{ index + 1 }} (Numerator/Denominator):</label>
          <input
            type="text"
            :id="'fraction' + index"
            v-model="fraction.value"
            class="form-control"
            placeholder="e.g., 1/2"
          />
        </div>

        <!-- Button to Add More Fractions -->
        <div class="mb-3">
          <button @click="addFraction" class="btn btn-success">Add Another Fraction</button>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateProduct" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Fractional Result: {{ result.fraction }}</p>
          <p class="fs-5">Decimal Result: {{ result.decimal }}</p>
          <p class="fs-5">Calculation Steps: {{ result.steps }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter two or more fractions in the format Numerator/Denominator (e.g., 1/2 and 3/4).</li>
        <li>Click "Calculate" to find the product of the fractions.</li>
        <li>The results will show the fractional and decimal forms of the product, along with the calculation steps.</li>
        <li>Click "Add Another Fraction" to include more fractions in the calculation.</li>
        <li>Click "Clear" to reset the input fields and results.</li>
      </ul>

      <h5 class="alert-heading">Formula for Multiplying Fractions:</h5>
      <div class="fs-6">
        The product of two fractions \( \frac{a}{b} \) and \( \frac{c}{d} \) is calculated as:
      </div>
      <div>
        \[
        \frac{a}{b} \times \frac{c}{d} = \frac{a \times c}{b \times d}
        \]
      </div>
      <p class="fs-6">
        This means that you multiply the numerators together and the denominators together.
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <ul class="fs-6">
        <li>
          For \( \frac{1}{2} \times \frac{3}{4} \):<br />
          Multiply the numerators: \( 1 \times 3 = 3 \)<br />
          Multiply the denominators: \( 2 \times 4 = 8 \)<br />
          Product: \( \frac{3}{8} \)
        </li>
        <li>
          For \( \frac{2}{3} \times \frac{3}{5} \times \frac{4}{7} \):<br />
          Multiply the numerators: \( 2 \times 3 \times 4 = 24 \)<br />
          Multiply the denominators: \( 3 \times 5 \times 7 = 105 \)<br />
          Product: \( \frac{24}{105} \)<br />
          Simplified: \( \frac{8}{35} \)
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const fractions = ref([{ value: "" }, { value: "" }]);
    const result = ref(null);
    const error = ref("");

    // Function to add more fractions
    const addFraction = () => {
      fractions.value.push({ value: "" });
    };

    // Function to calculate the product of multiple fractions
    const calculateProduct = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse and validate each fraction
        const parsedFractions = fractions.value.map((fraction) => parseFraction(fraction.value));

        if (parsedFractions.some((fraction) => !fraction)) {
          throw new Error("Please enter valid fractions.");
        }

        // Calculate the product of all fractions
        let numeratorProduct = 1;
        let denominatorProduct = 1;
        let steps = "Multiplying the fractions:\n";

        parsedFractions.forEach(([numerator, denominator], index) => {
          numeratorProduct *= numerator;
          denominatorProduct *= denominator;
          steps += `${index > 0 ? "× " : ""}${numerator}/${denominator} `;
        });

        steps += `= ${numeratorProduct}/${denominatorProduct}`;

        // Simplify the result
        const simplifiedFraction = simplifyFraction([numeratorProduct, denominatorProduct]);
        const decimalResult = (simplifiedFraction[0] / simplifiedFraction[1]).toFixed(6);
        steps += `\nSimplified: ${simplifiedFraction[0]}/${simplifiedFraction[1]}`;

        result.value = {
          fraction: `${simplifiedFraction[0]}/${simplifiedFraction[1]}`,
          decimal: decimalResult,
          steps: steps,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to parse fraction string into an array [numerator, denominator]
    const parseFraction = (fraction) => {
      const parts = fraction.trim().split("/");
      if (parts.length !== 2) return null;
      const numerator = parseInt(parts[0].trim());
      const denominator = parseInt(parts[1].trim());
      if (isNaN(numerator) || isNaN(denominator) || denominator === 0) return null;
      return [numerator, denominator];
    };

    // Function to simplify a fraction
    const simplifyFraction = (fraction) => {
      const gcd = greatestCommonDivisor(fraction[0], fraction[1]);
      return [fraction[0] / gcd, fraction[1] / gcd];
    };

    // Function to find greatest common divisor (GCD) of two numbers
    const greatestCommonDivisor = (a, b) => {
      return b === 0 ? Math.abs(a) : greatestCommonDivisor(b, a % b);
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      fractions.value = [{ value: "" }, { value: "" }];
      result.value = null;
      error.value = "";
    };

    return {
      fractions,
      result,
      error,
      addFraction,
      calculateProduct,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
