<template>
  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
    <div class="card-body p-15 p-sm-20">
      <div class="accordion" id="faqAccordion">
        <div class="row">
          <div class="text-center mb-3">
            <h3>FAQs on YAML Validator</h3>
          </div>

          <div class="accordion-item rounded-0 border-0">
            <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
              <h5>What is YAML?</h5>
            </button>
            <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  YAML, standing for "YAML Ain't Markup Language," is a human-readable data serialization standard. It's commonly employed for configuration files, data exchange between languages with different data structures, and as a language-independent data format. Its simplicity and ease of understanding have made it a popular choice for developers and system administrators alike. YAML uses a combination of maps (key-value pairs), sequences (arrays/lists), and scalars (string, boolean, integer) to represent data.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item rounded-0 border-0">
            <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="false" aria-controls="faqCollapseTwo">
              <h5>What is YAML Validator?</h5>
            </button>
            <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  A YAML Validator is a digital tool designed to analyze YAML code to identify any syntax or structural errors. Given that a small mistake, such as a missing colon or incorrect indentation, can lead to YAML not functioning as intended, this validator ensures that your YAML files are both correct and reliable. By using our YAML Validator, you can:
                  <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    <li><strong>Check for Errors:</strong> Instantly locate syntax issues or misconfigurations in your YAML code.</li>
                    <li><strong>Save Time:</strong> Instead of manually sifting through lines of code, get immediate feedback on any issues present.</li>
                    <li><strong>Ensure Reliability:</strong> Before deploying configurations or using YAML data, make sure it's error-free to avoid potential hiccups in your processes.</li>
                  </ul>
                  <em class="fs-md-15 fs-lg-16 text-paragraph lh-base">To use the YAML Validator, simply paste your YAML code into the provided textarea and click the "Validate" button. If any errors are detected, they will be prominently displayed, guiding you to the exact location of the issue for easy correction.</em>
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item rounded-0 border-0">
            <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="false" aria-controls="faqCollapseThree">
              <h5>How to Use the YAML Validator</h5>
            </button>
            <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    <li><strong>1. Input your YAML:</strong> Navigate to the "Enter Your YAML Texts" section and type or paste the YAML code you'd like to validate into the provided textarea.</li>
                    <li><strong>2. Validate the YAML:</strong> Click on the "Validate" button. If your input YAML is valid, a success message will appear. If there are errors, they will be displayed in the "Validation Error" section.</li>
                    <li><strong>3. Handling Errors:</strong> If you encounter errors in your YAML code, the error message will guide you to the exact location of the issue for easy correction.</li>
                    <li><strong>4. Reset:</strong> If you wish to start over or enter new YAML code, click the "Reset" button to clear both the input and error messages.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item rounded-0 border-0">
            <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="false" aria-controls="faqCollapseFour">
              <h5>Tips and Tricks</h5>
            </button>
            <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
              <div class="accordion-body pb-0">
                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                  <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                    <li>Pay close attention to indentation, as YAML is indentation-sensitive. Improper indentation can cause errors.</li>
                    <li>Use a text editor or IDE that supports YAML syntax highlighting to make it easier to spot errors.</li>
                    <li>While most YAML parsers are lenient, it's good practice to adhere to YAML standards to ensure compatibility across different tools and platforms.</li>
                    <li>The tool is designed to be intuitive and user-friendly, so feel free to experiment and have fun!</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YAMLValidatorFAQ',
};
</script>

<style scoped>
</style>