<template>
  <BreadCrumb PageTitle="Percentage Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Percentage Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate percentages in various scenarios. Select a calculation type, enter the values, and get the result.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Select Calculation Type -->
        <div class="mb-3">
          <label for="calcType" class="form-label">Select Calculation Type:</label>
          <select id="calcType" v-model="calcType" class="form-select">
            <option value="percentOfNumber">Find Percentage of a Number</option>
            <option value="percentageOfTotal">Find What Percentage One Number is of Another</option>
            <option value="percentageIncrease">Calculate Percentage Increase</option>
            <option value="percentageDecrease">Calculate Percentage Decrease</option>
          </select>
        </div>

        <!-- Input Fields for Different Calculations -->
        <div v-if="calcType === 'percentOfNumber'" class="mb-3">
          <label for="percentage" class="form-label">Percentage (%):</label>
          <input
            type="text"
            id="percentage"
            v-model="percentage"
            class="form-control"
            placeholder="e.g., 25"
          />
          <label for="number" class="form-label mt-3">Number:</label>
          <input
            type="text"
            id="number"
            v-model="number"
            class="form-control"
            placeholder="e.g., 200"
          />
        </div>

        <div v-if="calcType === 'percentageOfTotal'" class="mb-3">
          <label for="part" class="form-label">Part:</label>
          <input
            type="text"
            id="part"
            v-model="part"
            class="form-control"
            placeholder="e.g., 50"
          />
          <label for="total" class="form-label mt-3">Total:</label>
          <input
            type="text"
            id="total"
            v-model="total"
            class="form-control"
            placeholder="e.g., 200"
          />
        </div>

        <div v-if="calcType === 'percentageIncrease'" class="mb-3">
          <label for="initialValue" class="form-label">Initial Value:</label>
          <input
            type="text"
            id="initialValue"
            v-model="initialValue"
            class="form-control"
            placeholder="e.g., 100"
          />
          <label for="finalValue" class="form-label mt-3">Final Value:</label>
          <input
            type="text"
            id="finalValue"
            v-model="finalValue"
            class="form-control"
            placeholder="e.g., 150"
          />
        </div>

        <div v-if="calcType === 'percentageDecrease'" class="mb-3">
          <label for="initialValue" class="form-label">Initial Value:</label>
          <input
            type="text"
            id="initialValue"
            v-model="initialValue"
            class="form-control"
            placeholder="e.g., 200"
          />
          <label for="finalValue" class="form-label mt-3">Final Value:</label>
          <input
            type="text"
            id="finalValue"
            v-model="finalValue"
            class="form-control"
            placeholder="e.g., 150"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculatePercentage" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">{{ result.calculationType }}</p>
          <p class="fs-5">{{ result.calculation }}</p>
          <p class="fs-5">Final Result: {{ result.finalResult }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Select the type of percentage calculation you want to perform.</li>
        <li>Enter the required values based on the selected calculation type.</li>
        <li>Click "Calculate" to get the result. The result will display the calculation process and the final value.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Percentage Calculation Formulas:</h5>
      <ul class="fs-6">
        <li>
          <strong>Finding a Percentage of a Number:</strong><br />
          \[
          \text{Result} = \frac{\text{Percentage}}{100} \times \text{Number}
          \]
        </li>
        <li>
          <strong>Finding What Percentage One Number is of Another:</strong><br />
          \[
          \text{Percentage} = \frac{\text{Part}}{\text{Total}} \times 100
          \]
        </li>
        <li>
          <strong>Calculating Percentage Increase:</strong><br />
          \[
          \text{Percentage Increase} = \frac{\text{Final Value} - \text{Initial Value}}{\text{Initial Value}} \times 100
          \]
        </li>
        <li>
          <strong>Calculating Percentage Decrease:</strong><br />
          \[
          \text{Percentage Decrease} = \frac{\text{Initial Value} - \text{Final Value}}{\text{Initial Value}} \times 100
          \]
        </li>
      </ul>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          <strong>Finding a Percentage of a Number:</strong><br />
          25% of 200 is \( \frac{25}{100} \times 200 = 50 \).
        </li>
        <li>
          <strong>Finding What Percentage One Number is of Another:</strong><br />
          50 is what percent of 200? \( \frac{50}{200} \times 100 = 25\% \).
        </li>
        <li>
          <strong>Calculating Percentage Increase:</strong><br />
          The increase from 100 to 150 is \( \frac{150 - 100}{100} \times 100 = 50\% \).
        </li>
        <li>
          <strong>Calculating Percentage Decrease:</strong><br />
          The decrease from 200 to 150 is \( \frac{200 - 150}{200} \times 100 = 25\% \).
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const calcType = ref("percentOfNumber");
    const percentage = ref("");
    const number = ref("");
    const part = ref("");
    const total = ref("");
    const initialValue = ref("");
    const finalValue = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate percentages based on selected type
    const calculatePercentage = () => {
      error.value = "";
      result.value = null;

      try {
        let finalResult, calculationType, calculation;

        switch (calcType.value) {
  case "percentOfNumber": {
    // Calculate percentage of a number
    if (!percentage.value || !number.value) {
      throw new Error("Please enter both percentage and number.");
    }
    const percentOfNumber = parseFloat(percentage.value);
    const numValue = parseFloat(number.value);
    if (isNaN(percentOfNumber) || isNaN(numValue)) {
      throw new Error("Please enter valid numeric values.");
    }
    finalResult = ((percentOfNumber / 100) * numValue).toFixed(2);
    calculationType = `Percentage of a Number`;
    calculation = `${percentOfNumber}% of ${numValue} is ${finalResult}`;
    break;
  }

  case "percentageOfTotal": {
    // Calculate what percentage one number is of another
    if (!part.value || !total.value) {
      throw new Error("Please enter both part and total values.");
    }
    const partValue = parseFloat(part.value);
    const totalValue = parseFloat(total.value);
    if (isNaN(partValue) || isNaN(totalValue) || totalValue === 0) {
      throw new Error("Please enter valid numeric values and total should not be zero.");
    }
    finalResult = ((partValue / totalValue) * 100).toFixed(2);
    calculationType = `Percentage of Total`;
    calculation = `${partValue} is ${finalResult}% of ${totalValue}`;
    break;
  }

  case "percentageIncrease": {
    // Calculate percentage increase
    if (!initialValue.value || !finalValue.value) {
      throw new Error("Please enter both initial and final values.");
    }
    const initialInc = parseFloat(initialValue.value);
    const finalInc = parseFloat(finalValue.value);
    if (isNaN(initialInc) || isNaN(finalInc)) {
      throw new Error("Please enter valid numeric values.");
    }
    finalResult = (((finalInc - initialInc) / initialInc) * 100).toFixed(2);
    calculationType = `Percentage Increase`;
    calculation = `The increase from ${initialInc} to ${finalInc} is ${finalResult}%`;
    break;
  }

  case "percentageDecrease": {
    // Calculate percentage decrease
    if (!initialValue.value || !finalValue.value) {
      throw new Error("Please enter both initial and final values.");
    }
    const initialDec = parseFloat(initialValue.value);
    const finalDec = parseFloat(finalValue.value);
    if (isNaN(initialDec) || isNaN(finalDec)) {
      throw new Error("Please enter valid numeric values.");
    }
    finalResult = (((initialDec - finalDec) / initialDec) * 100).toFixed(2);
    calculationType = `Percentage Decrease`;
    calculation = `The decrease from ${initialDec} to ${finalDec} is ${finalResult}%`;
    break;
  }

  default:
    throw new Error("Invalid calculation type.");
}

        result.value = {
          calculationType: calculationType,
          calculation: calculation,
          finalResult: finalResult,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      percentage.value = "";
      number.value = "";
      part.value = "";
      total.value = "";
      initialValue.value = "";
      finalValue.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      calcType,
      percentage,
      number,
      part,
      total,
      initialValue,
      finalValue,
      result,
      error,
      calculatePercentage,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
