<template>
	<BreadCrumb PageTitle="Random Word Generator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Random Word Generator</h1>
	  <p class="fs-6 text-center mb-4">Your advanced random word generator.</p>
  
	  <div class="col-md-12">
		<div class="mb-3">
		  <div class="mb-4">
			<h4 class="text-center">Word of the Day</h4>
			<div class="card">
			  <div class="card-body word-of-the-day-wrapper text-center">
				<h4 class="card-title" id="word-of-the-day">{{ wordOfTheDay.word }}</h4>
				<p class="card-text fs-6" id="word-meaning"><strong>Meaning:</strong> {{ wordOfTheDay.meaning }}</p>
				<p class="card-text fs-6" id="word-sentence"><strong>Sentence:</strong> {{ wordOfTheDay.sentence }}</p>
				<span id="copy-icon-day" class="copy-icon-day" @click="copyWordOfTheDay">
				  <i :class="copyIconDay"></i>
				</span>
			  </div>
			</div>
		  </div>
		  <p class="text-center">Select word type, and number of words to generate. You can add a start letter or end letter (optional). Then click "Get Words!"</p>
		  <div class="row mt-4">
			<div class="col-md-6">
			  <div class="input-group mb-3">
				<span class="input-group-text pe-4" for="categorySelect">Word Type:</span>
				<select id="word_type_select" class="form-select" v-model="selectedWordType">
				  <option value="" selected>Choose...</option>
				  <option value="All Words">All Words</option>
				  <option v-for="type in wordTypes" :key="type" :value="type">{{ type }}</option>
				</select>
			  </div>
			</div>
			<div class="col-md-6">
			  <div class="input-group">
				<span class="input-group-text">No. of words:</span>
				<input id="word_no" class="form-control" type="number" max="50" min="1" placeholder="1" v-model.number="numberOfWords" />
			  </div>
			</div>
		  </div>
		  <div class="row mt-4">
			<div class="col-12">
			  <div class="input-group mb-2">
				<span class="input-group-text">Starts</span>
				<input id="starts_with" class="form-control" type="text" placeholder="Starts with" v-model="startsWith" />
				<input id="ends_with" class="form-control" type="text" placeholder="Ends with" v-model="endsWith" />
				<span class="input-group-text">Ends</span>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	  <div class="col">
		<button id="btn_random_word" class="btn btn-primary d-flex justify-content-center align-items-center mb-4 btn-hover w-100" @click="generateWords">
		  Get Words!
		</button>
	  </div>
	  <div class="col-md-12">
		<div>
		  <div>
			<h4 class="text-center">Generated Words</h4>
			<div id="error-alert" class="alert alert-warning mt-2" role="alert" v-if="showError">
			  Please select a word type and set the number of words.
			</div>
			<div class="card generated-words">
			  <div class="card-body">
				<span id="copy-icon-generated" class="copy-icon" @click="copyGeneratedWords">
				  <i :class="copyIconGenerated"></i>
				</span>
				<p id="words-container" class="card-text">
				  <template v-if="generatedWords.length">
					<a v-for="(word, index) in generatedWords" :key="index" 
					   :href="'https://www.dictionary.com/browse/' + word.toLowerCase()" 
					   target="_blank" rel="noopener noreferrer" class="me-2 fs-5">
					  {{ word }}
					</a>
				  </template>
				  <template v-else>
					<div class="fs-6">No words generated yet.</div>
				  </template>
				</p>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	  
	  <!-- FAQ Section -->
	  <div class="mt-5">
		<RandomWordGeneratorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import wordOfTheDayData from '../../components/JSON/wordOfTheDay.json';
  import randomWordTypeData from '../../components/JSON/randomWordType.json';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import RandomWordGeneratorFAQ from '../FAQs/randomWordGeneratorFAQ.vue';
  
  export default {
	name: 'RandomWordGenerator',
	components: {
	  BreadCrumb,
	  RandomWordGeneratorFAQ
	},
	setup() {
	  const wordOfTheDay = ref({});
	  const wordTypes = ref([]);
	  const selectedWordType = ref('');
	  const numberOfWords = ref(1);
	  const startsWith = ref('');
	  const endsWith = ref('');
	  const generatedWords = ref([]);
	  const showError = ref(false);
	  const copyIconDay = ref('bi bi-clipboard');
	  const copyIconGenerated = ref('bi bi-clipboard');
  
	  onMounted(() => {
		// Set Word of the Day
		const randomIndex = Math.floor(Math.random() * wordOfTheDayData.wordoftheday.length);
		wordOfTheDay.value = wordOfTheDayData.wordoftheday[randomIndex];
  
		// Set Word Types
		wordTypes.value = Object.keys(randomWordTypeData);
	  });
  
	  const generateWords = () => {
		if (!selectedWordType.value || numberOfWords.value < 1) {
		  showError.value = true;
		  return;
		}
		showError.value = false;
  
		let words = [];
		if (selectedWordType.value === 'All Words') {
		  words = Object.values(randomWordTypeData).flat();
		} else {
		  words = randomWordTypeData[selectedWordType.value];
		}
  
		const filteredWords = words.filter(word => 
		  (!startsWith.value || word.toLowerCase().startsWith(startsWith.value.toLowerCase())) &&
		  (!endsWith.value || word.toLowerCase().endsWith(endsWith.value.toLowerCase()))
		);
  
		generatedWords.value = [];
		const usedIndices = new Set();
		for (let i = 0; i < numberOfWords.value && usedIndices.size < filteredWords.length; i++) {
		  let randomIndex;
		  do {
			randomIndex = Math.floor(Math.random() * filteredWords.length);
		  } while (usedIndices.has(randomIndex));
		  
		  usedIndices.add(randomIndex);
		  generatedWords.value.push(filteredWords[randomIndex]);
		}
	  };
  
	  const copyWordOfTheDay = () => {
		const text = `${wordOfTheDay.value.word}\nMeaning: ${wordOfTheDay.value.meaning}\nSentence: ${wordOfTheDay.value.sentence}`;
		navigator.clipboard.writeText(text).then(() => {
		  copyIconDay.value = 'bi bi-clipboard-check';
		  setTimeout(() => copyIconDay.value = 'bi bi-clipboard', 2000);
		});
	  };
  
	  const copyGeneratedWords = () => {
		navigator.clipboard.writeText(generatedWords.value.join(', ')).then(() => {
		  copyIconGenerated.value = 'bi bi-clipboard-check';
		  setTimeout(() => copyIconGenerated.value = 'bi bi-clipboard', 2000);
		});
	  };
  
	  return {
		wordOfTheDay,
		wordTypes,
		selectedWordType,
		numberOfWords,
		startsWith,
		endsWith,
		generatedWords,
		showError,
		copyIconDay,
		copyIconGenerated,
		generateWords,
		copyWordOfTheDay,
		copyGeneratedWords
	  };
	}
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  .copy-icon, .copy-icon-day {
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 10px;
  }
  
  .generated-words, .word-of-the-day-wrapper {
	position: relative;
  }
  
  .card {
	margin-top: 10px;
  }
  
  a {
	text-decoration: none;
	color: #007bff;
  }
  
  a:hover {
	text-decoration: underline;
  }
  </style>