<template>
  <BreadCrumb PageTitle="WHATS THE WORD" />
  <div id="app" class="custom-container mt-5 text-center">
    <h1 class="text-center mb-3">WHATS THE WORD</h1>
    <p class="fs-6">You have 4 attempts to guess the word based on the definition provided. Good luck!</p>
    <h3 class="text-primary">DEFINITION</h3>
    <p class="fs-5">
      {{ currentDefinition }}
    </p>

    <!-- Input boxes for each character -->
    <div class="input-boxes my-4">
      <h3 class="text-primary">WHATS THE WORD</h3>
      <div class="d-flex justify-content-center flex-wrap">
        <input
          v-for="(letter, index) in currentWord"
          :key="index"
          :value="userGuess[index]"
          :readonly="index === 0"
          v-model="userGuess[index]"
          @input="handleInput(index, $event)"
          @keydown.backspace="handleBackspace(index, $event)"
          maxlength="1"
          class="letter-input mx-1"
          :autofocus="index === 1"
        />
      </div>
    </div>

    <!-- Attempts left -->
    <div class="mt-3 fs-6">
      <p>Attempts left: {{ attemptsLeft }}</p>
    </div>

    <!-- Submit Button -->
    <div>
      <button
        class="btn btn-primary mt-3 w-100"
        @click="checkGuess"
        :disabled="isSubmitDisabled"
      >
        Submit
      </button>
    </div>

    <!-- Message for Correct/Incorrect Word -->
    <div v-if="message" class="mt-4">
      <h4 :class="messageClass">{{ message }}</h4>
    </div>

    <!-- FAQ Section -->
    <div class="mt-50 mb-50">
      <h2>Whats The Word FAQ</h2>
      <div v-for="(item, index) in faqList" :key="index" class="mb-4 text-start">
        <h4>{{ item.question }}</h4>
        <p v-html="item.answer" class="fs-6"></p>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      wordData: [], // Array to hold the data from the JSON file
      currentWord: '', // Current word to guess
      currentDefinition: '', // The current definition/sentence
      userGuess: [], // Array to store the user's guess for each character
      attemptsLeft: 4, // Attempts the user has left
      message: '', // Feedback message
      isSubmitDisabled: false, // Flag to disable the submit button
      faqList: [
        {
          "question": "What is the objective of the game?",
          "answer": "The objective of the game is to guess the word based on the definition provided. You have 4 attempts to guess the correct word."
        },
        {
          "question": "How many attempts do I get?",
          "answer": "You have 4 attempts to guess the correct word."
        },
        {
          "question": "Is the first letter of the word given?",
          "answer": "Yes, the first letter of the word is provided in the first input box, and it cannot be changed."
        },
        {
          "question": "What happens if I guess the word correctly?",
          "answer": "If you guess the word correctly, a congratulatory message will appear and a new word will be loaded automatically."
        },
        {
          "question": "What happens if I run out of attempts?",
          "answer": "If you use all 4 attempts without guessing the word correctly, the game will display the correct word and start a new round."
        }
      ],
    };
  },
  computed: {
    messageClass() {
      return this.message.includes('Congratulations')
        ? 'text-success'
        : 'text-danger';
    },
  },
  methods: {
    // Handle input and move to the next box if applicable
    handleInput(index, event) {
      if (event.target.value && index < this.currentWord.length - 1) {
        this.$nextTick(() => {
          const nextInput = document.querySelectorAll('.letter-input')[index + 1];
          if (nextInput) {
            nextInput.focus(); // Move focus to the next input field
          }
        });
      }
    },
    handleBackspace(index, event) {
      if (event.key === 'Backspace' && index > 0) {
        if (!this.userGuess[index]) {
          this.$nextTick(() => {
            const prevInput = document.querySelectorAll('.letter-input')[index - 1];
            if (prevInput) {
              prevInput.focus(); // Move focus to the previous input field
              if (index > 1) { // Skip the first box, do not clear it
                this.userGuess[index - 1] = ''; // Clear previous input box
              }
            }
          });
        }
      }
    },
    // Method to check if the guess is correct
    checkGuess() {
      if (this.attemptsLeft <= 0 || this.isSubmitDisabled) {
        // Prevent further actions if no attempts are left or if submit is disabled
        return;
      }

      const guessedWord = this.userGuess.join('').toLowerCase();
      if (guessedWord === this.currentWord.toLowerCase()) {
        this.showMessage('Congratulations! You guessed the word!', true);
      } else {
        this.attemptsLeft--;
        if (this.attemptsLeft === 0) {
          this.showMessage(`Incorrect! The correct word was "${this.currentWord}".`);
          setTimeout(() => {
            this.resetGame(); // Start a new round
          }, 2000); // Wait 2 seconds before starting the next round
        } else {
          this.message = `Incorrect! Try again. Attempts left: ${this.attemptsLeft}`;
        }
      }
    },
    // Show feedback message
    showMessage(message, isCorrect) {
      this.message = message;
      this.isSubmitDisabled = true; // Disable the submit button
      setTimeout(() => {
        this.message = ''; // Clear the message after 2 seconds
        if (isCorrect) {
          this.resetGame(); // Start a new round immediately after a correct answer
        }
        this.isSubmitDisabled = false; // Re-enable the submit button when new round starts
      }, 2000);
    },
    // Reset the game state and load a new random word
    resetGame() {
      const randomIndex = Math.floor(Math.random() * this.wordData.length);
      const { definition, word } = this.wordData[randomIndex];
      this.currentDefinition = definition;
      this.currentWord = word;
      this.userGuess = Array(this.currentWord.length).fill('');
      this.userGuess[0] = this.currentWord[0]; // First letter is fixed
      this.attemptsLeft = 4; // Reset attempts
      this.message = ''; // Clear previous messages
      this.isSubmitDisabled = false; // Ensure the button is re-enabled
    },
    // Fetch the word data from the JSON file
    async fetchWordData() {
      const response = await fetch('/wordlist/whats-the-word.json');
      const data = await response.json();
      this.wordData = data;
      this.resetGame(); // Start the game with the first random word
    },
  },
  mounted() {
    this.fetchWordData(); // Fetch the word data when the component is mounted
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
.letter-input {
  width: 40px;
  height: 40px;
  font-size: 1.5em;
  text-align: center;
  text-transform: uppercase;
  margin: 5px;
}

@media (max-width: 768px) {
  .letter-input {
    width: 35px;
    height: 35px;
    font-size: 1.2em;
  }
}

@media (max-width: 576px) {
  .letter-input {
    width: 30px;
    height: 30px;
    font-size: 1em;
  }
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}
</style>
