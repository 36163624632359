<template>
      <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
          <div class="accordion" id="faqAccordion">
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center mb-3">
                  <h3>FAQs on Secret Santa Generator</h3>
                </div>
                <div class="accordion-item rounded-0 border-0">
                  <button
                    class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseOne"
                    aria-expanded="true"
                    aria-controls="faqCollapseOne"
                  >
                    <h5>What is the Secret Santa Generator?</h5>
                  </button>
                  <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                    <div class="accordion-body pb-0">
                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        The Secret Santa Generator is a fun, easy-to-use online tool that facilitates the traditional
                        gift-exchanging game known as 'Secret Santa'. By entering participants' names and specific
                        exclusions (if any), you can randomly assign each person a secret giftee. This tool helps to add an
                        element of surprise to your holiday celebrations, ensuring that no participant knows who their
                        'Santa' is!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                  <button
                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseTwo"
                    aria-expanded="true"
                    aria-controls="faqCollapseTwo"
                  >
                    <h5>How does the Secret Santa Generator work?</h5>
                  </button>
                  <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                    <div class="accordion-body pb-0">
                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        It's simple and easy-to-use! Simply enter the names of everyone participating in the Secret Santa
                        gift exchange, and add any exclusions (these could be based on people's preferences, relationships,
                        or any other reason). The generator will then shuffle the names and assign each participant a
                        person to whom they’ll secretly give a gift. The results can either be sent via email or generated
                        as unique links for each participant, maintaining the secrecy of the entire game.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                  <button
                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseThree"
                    aria-expanded="true"
                    aria-controls="faqCollapseThree"
                  >
                    <h5>Who can participate in Secret Santa?</h5>
                  </button>
                  <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                    <div class="accordion-body pb-0">
                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        Anyone and everyone! Secret Santa is a universal game that's perfect for families, friends,
                        coworkers, and even communities. As long as you have a group of people willing to give and receive
                        gifts, you're set for a fun, mystery-filled gift exchange.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                  <button
                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseFour"
                    aria-expanded="true"
                    aria-controls="faqCollapseFour"
                  >
                    <h5>Can I exclude certain pairings from the draw?</h5>
                  </button>
                  <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                    <div class="accordion-body pb-0">
                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        Absolutely. We understand that there might be reasons to exclude certain pairings, perhaps due to
                        family relationships, or because some friends know each other too well. The Secret Santa Generator
                        allows you to exclude specific pairs, ensuring that certain participants do not get assigned to
                        one another.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                  <button
                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseFive"
                    aria-expanded="true"
                    aria-controls="faqCollapseFive"
                  >
                    <h5>How is the secrecy maintained in the draw?</h5>
                  </button>
                  <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                    <div class="accordion-body pb-0">
                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        The magic of Secret Santa lies in its anonymity. When using the Secret Santa Generator, the system
                        first draws all participants. Then a unique link is generated for each individual draw. This secure
                        link can then be sent via email or chat to each giver. No other participant can see who's been
                        assigned to whom, keeping the Santa's identity a secret until the gift exchange.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                  <button
                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseSix"
                    aria-expanded="true"
                    aria-controls="faqCollapseSix"
                  >
                    <h5>Is there a limit to the number of participants in the Secret Santa draw?</h5>
                  </button>
                  <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                    <div class="accordion-body pb-0">
                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        While there's no strict limit set by the Secret Santa Generator, we believe it works best for
                        groups ranging from small gatherings to large parties. However, it's essential to ensure that each
                        participant's name is entered correctly to maintain a smooth and fair drawing process.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                  <button
                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseSeven"
                    aria-expanded="true"
                    aria-controls="faqCollapseSeven"
                  >
                    <h5>What if I encounter a problem or error with the generator?</h5>
                  </button>
                  <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                    <div class="accordion-body pb-0">
                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        We strive to provide a seamless experience with the Secret Santa Generator. However, if you
                        encounter any issues or require assistance, please don't hesitate to contact our support team.
                        We're here to help make your Secret Santa experience joyful and hassle-free!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item rounded-0 border-0">
                  <button
                    class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCollapseEight"
                    aria-expanded="true"
                    aria-controls="faqCollapseEight"
                  >
                    <h5>How do I upload names from a text file?</h5>
                  </button>
                  <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                    <div class="accordion-body pb-0">
                      <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                        You can upload a text file with names separated by newlines. Click on the "Upload" button and
                        select your text file. The names will be extracted and added to the participants list
                        automatically.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  export default {
    name: 'SecretSantaGeneratorFAQ',
  };
  </script>
  
  <style scoped>

  </style>
  