<template>
  <BreadCrumb PageTitle="Standard Deviation Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Standard Deviation Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the mean, variance, and standard deviation of a set of numbers. Enter your data set to compute the results.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Data Set -->
        <div class="mb-3">
          <label for="dataSet" class="form-label">Enter Numbers (separated by commas):</label>
          <textarea
            id="dataSet"
            v-model="dataSet"
            class="form-control"
            rows="3"
            placeholder="e.g., 5, 10, 15, 20, 25"
          ></textarea>
        </div>

        <!-- Select Standard Deviation Type -->
        <div class="mb-3">
          <label class="form-label">Standard Deviation Type:</label>
          <div>
            <input
              type="radio"
              id="population"
              value="population"
              v-model="stdDevType"
            />
            <label for="population" class="me-3">Population</label>
            <input
              type="radio"
              id="sample"
              value="sample"
              v-model="stdDevType"
            />
            <label for="sample">Sample</label>
          </div>
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateStatistics" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Data Set: [{{ result.dataSet.join(', ') }}]</p>
          <p class="fs-5">Mean (Average): {{ result.mean.toFixed(6) }}</p>
          <p class="fs-5">Variance: {{ result.variance.toFixed(6) }}</p>
          <p class="fs-5">Standard Deviation: {{ result.stdDeviation.toFixed(6) }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter your data set in the input field, separating each number with a comma.</li>
        <li>Select whether you want to calculate the population or sample standard deviation.</li>
        <li>Click "Calculate" to compute the mean, variance, and standard deviation.</li>
        <li>The results will display the data set, mean, variance, and standard deviation.</li>
        <li>Click "Clear" to reset the input field and results.</li>
      </ul>

      <h5 class="alert-heading">Understanding Standard Deviation:</h5>
      <p class="fs-6">
        Standard deviation is a measure of the amount of variation or dispersion in a set of values. A low standard deviation indicates that the values tend to be close to the mean, while a high standard deviation indicates that the values are spread out over a wider range.
      </p>

      <h5 class="alert-heading">Formulas:</h5>
      <p class="fs-6">
        For a data set with \( n \) values \( x_1, x_2, ..., x_n \), the mean \( \mu \) is calculated as:
      </p>
      <div>
        \[
        \mu = \frac{1}{n} \sum_{i=1}^{n} x_i
        \]
      </div>
      <p class="fs-6">
        The variance \( \sigma^2 \) and standard deviation \( \sigma \) are calculated as:
      </p>
      <div>
        \[
        \sigma^2 = \frac{1}{N} \sum_{i=1}^{n} (x_i - \mu)^2
        \]
        \[
        \sigma = \sqrt{\sigma^2}
        \]
      </div>
      <p class="fs-6">
        For a sample standard deviation, use \( N = n - 1 \) instead of \( N = n \).
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <p class="fs-6">
        For the data set: 5, 10, 15, 20, 25
      </p>
      <ul class="fs-6">
        <li>Mean \( \mu = (5 + 10 + 15 + 20 + 25) / 5 = 15 \)</li>
        <li>Variance (Population) \( \sigma^2 = [(5-15)^2 + (10-15)^2 + (15-15)^2 + (20-15)^2 + (25-15)^2] / 5 = 50 \)</li>
        <li>Standard Deviation (Population) \( \sigma = \sqrt{50} \approx 7.071068 \)</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input field and result state
    const dataSet = ref("");
    const stdDevType = ref("population");
    const result = ref(null);
    const error = ref("");

    // Function to calculate mean, variance, and standard deviation
    const calculateStatistics = () => {
      error.value = "";
      result.value = null;

      try {
        // Ensure dataSet is not empty
        if (dataSet.value.trim() === "") {
          throw new Error("Please enter at least one number.");
        }

        // Parse the input values into an array of numbers
        const dataArray = dataSet.value
          .split(",")
          .map((num) => parseFloat(num.trim()))
          .filter((num) => !isNaN(num));

        if (dataArray.length === 0) {
          throw new Error("Please enter valid numeric values.");
        }

        const n = dataArray.length;

        if (stdDevType.value === "sample" && n < 2) {
          throw new Error("At least two numbers are required for sample standard deviation.");
        }

        // Calculate mean
        const mean = dataArray.reduce((sum, val) => sum + val, 0) / n;

        // Calculate variance
        const variance =
          dataArray.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) /
          (stdDevType.value === "population" ? n : n - 1);

        // Calculate standard deviation
        const stdDeviation = Math.sqrt(variance);

        result.value = {
          dataSet: dataArray,
          mean: mean,
          variance: variance,
          stdDeviation: stdDeviation,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input field and result
    const clearValues = () => {
      dataSet.value = "";
      stdDevType.value = "population";
      result.value = null;
      error.value = "";
    };

    return {
      dataSet,
      stdDevType,
      result,
      error,
      calculateStatistics,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
