<template>
  <BreadCrumb PageTitle="What Divided By What Equals Calculator" />
  <div class="custom-container mt-5 mb-50">
    <h1 class="text-center">What Divided By What Equals Calculator</h1>
    <p class="fs-6 text-center mb-4">Use our free what divided by what calculator to find what number can be divided by another number to get the given answer.</p>
    
    <div v-if="error" class="alert alert-warning mt-2" role="alert">
      {{ error }}
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text pe-4" for="number">Enter Number:</span>
      <input id="number" class="form-control" type="number" min="1" max="100000" placeholder="Enter a number" v-model="inputNumber" />
    </div>

    <div class="text-center mt-4">
      <h5 class="form-label" for="outputValue">
        <span>What divided by what equals {{ inputNumber }}</span>
      </h5>
      <div>
        <pre id="outputValue" class="form-control fs-5" style="white-space: pre-line;" readonly>{{ result }}</pre>
      </div>
    </div>

    <div class="mt-4">
      <WhatDividedByWhatCalculatorFAQ />
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import WhatDividedByWhatCalculatorFAQ from '../FAQs/whatDividedByWhatCalculatorFAQ.vue';

export default {
  name: 'WhatDividedByWhatCalculator',
  components: {
    BreadCrumb,
    WhatDividedByWhatCalculatorFAQ
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const inputNumber = ref(1)
    const result = ref('')
    const error = ref('')

    const calculate = () => {
      error.value = ''
      if (inputNumber.value < 1) {
        error.value = 'Please enter a positive number greater than 0.'
        result.value = ''
        return
      }

      let combinations = []
      for (let i = 1; i <= 100; i++) {  // Limit to 100 results
        const product = i * inputNumber.value
        combinations.push(`${product} / ${i} = ${inputNumber.value}`)
      }

      result.value = combinations.join('\n')
    }

    const updateURL = (number) => {
      if (number >= 1) {
        router.push(`/what-divided-by-what-equals-${number}`)
      } else {
        router.push('/what-divided-by-what-equals-1')
      }
    }

    watch(inputNumber, (newValue) => {
      updateURL(newValue)
      calculate()
    })

    onMounted(() => {
      const urlNumber = parseInt(route.params.number)
      if (!isNaN(urlNumber) && urlNumber >= 1) {
        inputNumber.value = urlNumber
      } else {
        inputNumber.value = 1
        updateURL(1)
      }
      calculate()
    })

    return {
      inputNumber,
      result,
      error
    }
  }
}
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>