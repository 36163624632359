<template>
  <BreadCrumb PageTitle="Bcrypt Password Generator" />
  <div class="custom-container mt-4 mb-50">
    <h1 class="text-center">Bcrypt Password Generator</h1>
    <p class="fs-6 text-center mb-4">Discover our Bcrypt Password Generator – your personal tool for optimal password security. Hash with confidence, every time.</p>

    <!-- Alert for validation message -->
    <div v-if="alertMessage" class="alert alert-danger" role="alert">{{ alertMessage }}</div>

    <div class="row">
      <div class="col-md-7">
        <div class="input-group mb-4">
          <span class="input-group-text">Password:</span>
          <input type="text" class="form-control" v-model="password" placeholder="Enter password string">
        </div>
      </div>
      <div class="col-md-5">
        <div class="input-group mb-4">
          <span class="input-group-text">Rounds:</span>
          <input type="number" class="form-control" v-model.number="rounds" min="4" max="20" placeholder="Enter rounds">
        </div>
      </div>
    </div>
    
    <div class="form-group mb-3">
      <label for="bcryptOutput">Bcrypt Output:</label>
      <textarea class="form-control" id="bcryptOutput" rows="4" v-model="bcryptOutput" readonly></textarea>
    </div>

    <div class="row">
      <div class="col-md-6 mb-2">
        <button @click="generateBcrypt" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Generate Bcrypt">Generate Bcrypt</button>
      </div>
      <div class="col-md-6 mb-2">
        <button @click="copyToClipboard" class="btn btn-danger w-100" data-bs-toggle="tooltip" title="Copy Text">Copy Text</button>
      </div>
    </div>
    <div><BcryptPasswordGeneratorFAQ /></div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import bcrypt from 'bcryptjs';
import BcryptPasswordGeneratorFAQ from '../FAQs/bcryptPasswordGeneratorFAQ.vue';

export default {
  components: {
    BreadCrumb,
    BcryptPasswordGeneratorFAQ
  },
  data() {
    return {
      password: '',
      rounds: '',
      bcryptOutput: '',
      alertMessage: '',
    };
  },
  methods: {
    async generateBcrypt() {
      this.alertMessage = '';
      if (!this.password) {
        this.alertMessage = 'Password is required.';
        return;
      }
      if (!this.rounds || this.rounds < 4 || this.rounds > 20) {
        this.alertMessage = 'Rounds must be between 4 and 20.';
        return;
      }
      try {
        const salt = await bcrypt.genSalt(this.rounds);
        this.bcryptOutput = await bcrypt.hash(this.password, salt);
      } catch (error) {
        this.alertMessage = 'An error occurred while generating the bcrypt hash.';
      }
    },
    copyToClipboard() {
      if (this.bcryptOutput) {
        navigator.clipboard.writeText(this.bcryptOutput);
        alert('Copied to clipboard!');
      } else {
        this.alertMessage = 'Nothing to copy.';
      }
    },
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 80vh;
  margin: 0 auto;
}
label {
  font-size: 16px;
}
</style>