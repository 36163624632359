<template>
  <BreadCrumb PageTitle="List All Adverbs In Sentence Calculator" />

  <div class="container mt-5 mb-50" style="max-width: 800px;">
    <h1 class="text-center">List All Adverbs In A Sentence</h1>
    <p class="text-center text-muted fs-5 mb-2">Analyze Your Text and Discover Its Adverbal Power</p>
    <p class="fs-6 text-center text-muted mb-4">
      Highlight Adverbs, calculate density, and uncover linguistic patterns in your writing. Enhance your prose with data-driven insights.
    </p>
    
    <div class="mb-3">
      <label for="sentenceInput" class="form-label fs-6">Enter a sentence:</label>
      <textarea
        v-model="sentence"
        class="form-control fs-6"
        id="sentenceInput"
        rows="6"
        placeholder="Type or paste your text here"
      ></textarea>
    </div>
    
    <button @click="analyzeSentence" class="btn btn-primary mb-3 w-100">Analyze</button>
    
    <div v-if="analyzed">
      <div v-if="highlightedSentence" class="mb-4">
        <h2>Highlighted Sentence:</h2>
        <p class="fs-6" v-html="highlightedSentence"></p>
      </div>
      
      <div v-if="report" class="mb-4">
        <h2>Report:</h2>
        <div class="card">
          <div class="card-body fs-5">
            <p><strong>Total words:</strong> {{ report.totalWords }}</p>
            <p><strong>Total Adverbs:</strong> {{ report.totalAdverbs }}</p>
            <p><strong>Adverb density:</strong> {{ report.adverbDensity.toFixed(2) }}%</p>
            <p><strong>Most common Adverb:</strong> {{ report.mostCommonAdverb || 'N/A' }}</p>
            <p><strong>Unique Adverbs:</strong> {{ report.uniqueAdverbs.join(', ') || 'N/A' }}</p>
          </div>
        </div>
      </div>
      
      <div v-if="adverbCounts.length > 0" class="mb-4">
        <h2>Adverbs found:</h2>
        <div class="row row-cols-2 row-cols-md-4 g-2">
          <div v-for="(adverb, index) in adverbCounts" :key="index" class="col">
            <div class="card">
              <div class="card-body p-2 fs-5">
                <a :href="'https://www.merriam-webster.com/dictionary/' + adverb.word" 
                   target="_blank" 
                   rel="noopener noreferrer"
                   class="text-decoration-none text-primary">
                  {{ adverb.word }}
                </a>
                {{ adverb.count > 1 ? ` (x${adverb.count})` : '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="mb-4">
      <h2>History:</h2>
      <div class="card mb-2" v-for="(item, index) in history" :key="index">
        <div class="card-body d-flex justify-content-between align-items-center">
          <span>{{ item }}</span>
          <i
            :class="['bi', copied[index] ? 'bi-clipboard-check' : 'bi-clipboard', 'cursor-pointer']"
            @click="copyToClipboard(item, index)"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

import { ref, computed, onMounted } from 'vue';
import allAdverbs from '../../components/JSON/allAdverbs.json';

export default {
 
  components: { BreadCrumb },
 name: 'App',
  setup() {
    const sentence = ref('');
    const adverbs = ref([]);
    const report = ref(null);
    const history = ref([]);
    const copied = ref({});
    const analyzed = ref(false);

    const adverbSet = new Set(allAdverbs.map(adverb => adverb.toLowerCase()));

    const analyzeSentence = () => {
      if (!sentence.value) return;
      
      const words = sentence.value.match(/\b(\w+)\b/g) || [];
      adverbs.value = words.filter(word => adverbSet.has(word.toLowerCase()));
      
      const adverbCounts = adverbs.value.reduce((acc, adverb) => {
        acc[adverb.toLowerCase()] = (acc[adverb.toLowerCase()] || 0) + 1;
        return acc;
      }, {});
      
      report.value = {
        totalWords: words.length,
        totalAdverbs: adverbs.value.length,
        adverbDensity: (adverbs.value.length / words.length) * 100,
        mostCommonAdverb: Object.entries(adverbCounts).sort((a, b) => b[1] - a[1])[0]?.[0],
        uniqueAdverbs: [...new Set(adverbs.value.map(v => v.toLowerCase()))]
      };
      
      history.value.unshift(sentence.value);
      if (history.value.length > 2) history.value.pop();
      
      analyzed.value = true;
    };

    const highlightedSentence = computed(() => {
      if (!sentence.value) return '';
      return sentence.value.replace(/\b(\w+)\b/g, (match) => {
        return adverbSet.has(match.toLowerCase())
          ? `<span style="background-color: #ff4400;"><a href="https://www.merriam-webster.com/dictionary/${match}" target="_blank" rel="noopener noreferrer" class="text-dark">${match}</a></span>`
          : match;
      });
    });

    const adverbCounts = computed(() => {
      const counts = {};
      adverbs.value.forEach(adverb => {
        const lowerAdverb = adverb.toLowerCase();
        counts[lowerAdverb] = (counts[lowerAdverb] || 0) + 1;
      });
      return Object.entries(counts).map(([word, count]) => ({ word, count }));
    });

    const copyToClipboard = (text, index) => {
      navigator.clipboard.writeText(text).then(() => {
        copied.value[index] = true;
        setTimeout(() => {
          copied.value[index] = false;
        }, 2000);
      });
    };

    onMounted(() => {
      // You might want to load allAdverbs.json here if it's not available at build time
    });

    return {
      sentence,
      report,
      history,
      copied,
      analyzed,
      analyzeSentence,
      highlightedSentence,
      adverbCounts,
      copyToClipboard
    };
  }
}
</script>

<style>
@import 'bootstrap-icons/font/bootstrap-icons.css';

.cursor-pointer {
  cursor: pointer;
}
.form-control {
  line-height: 1.3;
}
</style>