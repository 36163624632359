<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Wall Art Size Calculator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is Wall Art Size Calculator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Wall Art Size Calculator is an intuitive tool designed to assist users in determining the appropriate size of artwork(s) that can fit on their wall. Whether you're an interior designer, art enthusiast, or someone looking to beautify a space, this calculator makes it simple to plan out your wall aesthetics.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How to Use the Wall Art Size Calculator</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>Enter Wall Dimensions:</strong> Begin by inputting the width and height of your wall in centimeters. These values provide the calculator with the total space available for artworks.</li>
                                    <li><strong>Add Artwork Dimensions:</strong> For each piece of artwork you'd like to place on the wall, input its width and height in centimeters. If you have more than one artwork, click the 'Add Another Artwork' button to add fields for additional pieces.</li>
                                    <li><strong>Specify Gaps Between Artworks:</strong> If you want space between multiple artworks, enter the desired gap in centimeters. This ensures that artworks aren't placed edge-to-edge, giving them breathing room and making the display more aesthetically pleasing.</li>
                                    <li><strong>Calculate:</strong> Once all values are entered, click the 'Calculate' button. The tool will then determine whether the total dimensions of the artworks, including the gaps, fit within the dimensions of the wall.</li>
                                    <li><strong>Share Your Results:</strong> Excited about your wall art arrangement? Use the share buttons below the calculator to share your results on social media platforms like Facebook, Twitter, Reddit, and WhatsApp.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>Useful Tips To Keep In Mind</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Ensure the measurements for the wall and artworks are accurate for best results.</li>
                                    <li>Play around with different gaps and artwork dimensions to see various possible configurations.</li>
                                    <li>Remember that the calculator assumes artworks are arranged in a straight line, either horizontally or vertically.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>How big should my art be for my wall?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Art size largely depends on your wall size and the aesthetic you're aiming for. Generally, larger walls can accommodate larger pieces or multiple smaller pieces grouped together. A common guideline is that artwork should occupy 60-70% of a wall segment.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>How do you calculate art for a wall?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Start by measuring the width and height of your wall. Next, decide on the size of the gap between artworks if you're hanging multiple pieces. The total width and height of the artworks, including gaps, shouldn't exceed the wall's dimensions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Can a piece of art be too big for a wall?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, if an artwork overwhelms the wall or the room, it can be considered too big. The key is balance; the art should complement the space, not dominate it. Always ensure there's ample space around the artwork, allowing it to "breathe".
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>How do I choose wall art for my space?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    When choosing wall art, consider the room's color palette, style, and purpose. Opt for pieces that resonate with you personally, match the room's mood, and fit within the available space. The Wall Art Size Calculator can be a valuable tool in ensuring your chosen pieces fit perfectly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'WallArtSizeCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
