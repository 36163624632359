<template>
  <div class="textarea-container">
    <label :for="labelFor">{{ label }}</label>
    <textarea 
      :id="labelFor"
      :placeholder="placeholder"
      :value="inputText"
      @input="updateText"
      :readonly="readonly"
      class="form-control"
      rows="10"
    ></textarea>
    <p class="text-muted">
      {{ charCount }} characters, {{ wordCount }} words
    </p>
  </div>
</template>

<script>
export default {
  props: {
    inputText: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Enter your text here...'
    },
    label: {
      type: String,
      default: 'Text'
    },
    labelFor: {
      type: String,
      default: 'text-area'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    charCount() {
      return this.inputText.length;
    },
    wordCount() {
      if (!this.inputText.trim()) {
        return 0;
      }
      return this.inputText.trim().split(/\s+/).length;
    }
  },
  methods: {
    updateText(event) {
      this.$emit('updateInputText', event.target.value);
    }
  }
};
</script>

<style scoped>
.textarea-container {
  margin-bottom: 20px;
}
.textarea-container label {
  font-weight: bold;
  font-size: 16px;
}
</style>
