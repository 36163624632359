<template>
  <BreadCrumb PageTitle="Whitespace Remover Online Tool" />
  <div class="container mt-5">
    <h1 class="text-center">Whitespace Remover Online Tool</h1>
    <p class="fs-6 text-center mb-20">Easily declutter your content with our Whitespace Removal tool! Say goodbye to excessive spaces, pesky tabs, and unwanted gaps.</p>
    <div class="mb-3">
      <label for="inputText" class="form-label">Enter text:</label>
      <textarea v-model="inputText" id="inputText" class="form-control" rows="5"></textarea>
    </div>
    <div class="row mt-20 mb-20">
      <div class="col-md-6">
        <div class="mb-3 form-check">
          <input v-model="options.removeExtraSpace" type="checkbox" id="removeExtraSpace" class="form-check-input">
          <label for="removeExtraSpace" class="form-check-label">Remove extra space</label>
        </div>
        <div class="mb-3 form-check">
          <input v-model="options.removeTabs" type="checkbox" id="removeTabs" class="form-check-input">
          <label for="removeTabs" class="form-check-label">Remove Tabs</label>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="mb-3 form-check">
          <input v-model="options.removeAllWhitespaces" type="checkbox" id="removeAllWhitespaces" class="form-check-input">
          <label for="removeAllWhitespaces" class="form-check-label">Remove all whitespaces</label>
        </div>
        <div class="mb-3 form-check">
          <input v-model="options.removeNewlines" type="checkbox" id="removeNewlines" class="form-check-input">
          <label for="removeNewlines" class="form-check-label">Remove all newlines</label>
        </div>
      </div>
    </div>
    <div class="row mt-20 mb-20">
      <div class="col-12 col-md-6 mb-3">
        <button @click="removeWhitespace" class="btn btn-primary w-100">Remove Whitespace</button>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <button @click="copyText" class="btn btn-danger w-100">Copy Text</button>
      </div>
    </div>
    <div class="mb-3">
      <label for="resultText" class="form-label">Result:</label>
      <textarea v-model="resultText" id="resultText" class="form-control" rows="5" readonly></textarea>
    </div>
    <div class="mb-3">
      <label class="form-label">Spaces affected: {{ spacesAffected }}</label>
    </div>
    <div>
      <WhitespaceRemoverFAQ />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import WhitespaceRemoverFAQ from '../FAQs/whiteSpaceRemoverFAQ.vue';

export default {
  components: {
    BreadCrumb,
    WhitespaceRemoverFAQ
  },
  data() {
    return {
      inputText: '',
      resultText: '',
      options: {
        removeExtraSpace: false,
        removeTabs: false,
        removeAllWhitespaces: false,
        removeNewlines: false,
      },
      spacesAffected: 0,
    };
  },
  methods: {
    removeWhitespace() {
      let text = this.inputText;
      let initialLength = text.length;

      if (this.options.removeAllWhitespaces) {
        text = text.replace(/\s+/g, '');
      } else {
        if (this.options.removeExtraSpace) {
          text = text.replace(/\s+/g, ' ').trim();
        }
        if (this.options.removeTabs) {
          text = text.replace(/\t/g, '');
        }
        if (this.options.removeNewlines) {
          text = text.replace(/\n/g, '');
        }
      }

      this.spacesAffected = initialLength - text.length;
      this.resultText = text;
    },
    copyText() {
      navigator.clipboard.writeText(this.resultText)
        .then(() => {
          alert('Text copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 85vh;
  margin: auto;
}
</style>