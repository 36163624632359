export const getEasterDates = (year) => {
    const dates = {
        2023: { month: 4, day: 9 },
        2024: { month: 3, day: 31 },
        2025: { month: 4, day: 20 },
        2026: { month: 4, day: 5 },
        2027: { month: 3, day: 28 },
        2028: { month: 4, day: 16 },
        2029: { month: 4, day: 1 },
        2030: { month: 4, day: 21 },
        2031: { month: 4, day: 13 },
        2032: { month: 3, day: 28 },
        2033: { month: 4, day: 17 },
        2034: { month: 4, day: 9 },
        // Add more years as needed
    };
    return dates[year] || { month: 4, day: 1 }; // Default to April 1 if year not found
};

export const getMothersDayDates = (year) => {
    const dates = {
        2023: { month: 5, day: 14 },
        2024: { month: 5, day: 12 },
        2025: { month: 5, day: 11 },
        2026: { month: 5, day: 10 },
        2027: { month: 5, day: 9 },
        2028: { month: 5, day: 14 },
        2029: { month: 5, day: 13 },
        2030: { month: 5, day: 12 },
        2031: { month: 5, day: 11 },
        2032: { month: 5, day: 9 },
        2033: { month: 5, day: 8 },
        2034: { month: 5, day: 14 },
        // Add more years as needed
    };
    return dates[year] || { month: 5, day: 1 }; // Default to May 1 if year not found
};

export const getFathersDayDates = (year) => {
    const dates = {
        2023: { month: 6, day: 18 },
        2024: { month: 6, day: 16 },
        2025: { month: 6, day: 15 },
        2026: { month: 6, day: 21 },
        2027: { month: 6, day: 20 },
        2028: { month: 6, day: 18 },
        2029: { month: 6, day: 17 },
        2030: { month: 6, day: 16 },
        2031: { month: 6, day: 15 },
        2032: { month: 6, day: 20 },
        2033: { month: 6, day: 19 },
        2034: { month: 6, day: 18 },
        // Add more years as needed
    };
    return dates[year] || { month: 6, day: 1 }; // Default to June 1 if year not found
};

export const getChineseNewYearDates = (year) => {
    const dates = {
        2023: { month: 1, day: 22 },
        2024: { month: 2, day: 10 },
        2025: { month: 1, day: 29 },
        2026: { month: 2, day: 17 },
        2027: { month: 2, day: 6 },
        2028: { month: 1, day: 26 },
        2029: { month: 2, day: 13 },
        2030: { month: 2, day: 3 },
        2031: { month: 1, day: 23 },
        2032: { month: 2, day: 11 },
        2033: { month: 1, day: 31 },
        2034: { month: 2, day: 19 },
        // Add more years as needed
    };
    return dates[year] || { month: 1, day: 1 }; // Default to January 1 if year not found
};

export const getMartinLutherKingDayDates = (year) => {
    const dates = {
        2023: { month: 1, day: 16 },
        2024: { month: 1, day: 15 },
        2025: { month: 1, day: 20 },
        2026: { month: 1, day: 19 },
        2027: { month: 1, day: 18 },
        2028: { month: 1, day: 17 },
        2029: { month: 1, day: 15 },
        2030: { month: 1, day: 21 },
        2031: { month: 1, day: 20 },
        2032: { month: 1, day: 19 },
        2033: { month: 1, day: 17 },
        2034: { month: 1, day: 16 },
        // Add more years as needed
    };
    return dates[year] || { month: 1, day: 1 }; // Default to January 1 if year not found
};

export const getPresidentsDayDates = (year) => {
    const dates = {
        2023: { month: 2, day: 20 },
        2024: { month: 2, day: 19 },
        2025: { month: 2, day: 17 },
        2026: { month: 2, day: 16 },
        2027: { month: 2, day: 15 },
        2028: { month: 2, day: 21 },
        2029: { month: 2, day: 19 },
        2030: { month: 2, day: 18 },
        2031: { month: 2, day: 17 },
        2032: { month: 2, day: 16 },
        2033: { month: 2, day: 21 },
        2034: { month: 2, day: 20 },
        // Add more years as needed
    };
    return dates[year] || { month: 2, day: 1 }; // Default to February 1 if year not found
};

export const getSpringDayDates = (year) => {
    const dates = {
        2023: { month: 3, day: 20 },
        2024: { month: 3, day: 19 },
        2025: { month: 3, day: 20 },
        2026: { month: 3, day: 20 },
        2027: { month: 3, day: 20 },
        2028: { month: 3, day: 19 },
        2029: { month: 3, day: 20 },
        2030: { month: 3, day: 20 },
        2031: { month: 3, day: 20 },
        2032: { month: 3, day: 19 },
        2033: { month: 3, day: 20 },
        2034: { month: 3, day: 20 },
        // Add more years as needed
    };
    return dates[year] || { month: 3, day: 1 }; // Default to March 1 if year not found
};

export const getSummerDayDates = (year) => {
    const dates = {
        2023: { month: 6, day: 21 },
        2024: { month: 6, day: 20 },
        2025: { month: 6, day: 21 },
        2026: { month: 6, day: 21 },
        2027: { month: 6, day: 21 },
        2028: { month: 6, day: 20 },
        2029: { month: 6, day: 21 },
        2030: { month: 6, day: 21 },
        2031: { month: 6, day: 21 },
        2032: { month: 6, day: 20 },
        2033: { month: 6, day: 21 },
        2034: { month: 6, day: 21 },
        // Add more years as needed
    };
    return dates[year] || { month: 6, day: 1 }; // Default to June 1 if year not found
};

export const getAutumnDates = (year) => {
    const dates = {
        2023: { month: 9, day: 22 },
        2024: { month: 9, day: 22 },
        2025: { month: 9, day: 23 },
        2026: { month: 9, day: 23 },
        2027: { month: 9, day: 23 },
        2028: { month: 9, day: 22 },
        2029: { month: 9, day: 22 },
        2030: { month: 9, day: 23 },
        2031: { month: 9, day: 23 },
        2032: { month: 9, day: 22 },
        2033: { month: 9, day: 22 },
        2034: { month: 9, day: 22 },
        // Add more years as needed
    };
    return dates[year] || { month: 9, day: 1 }; // Default to September 1 if year not found
};

export const getEasterMondayDates = (year) => {
    const dates = {
        2023: { month: 4, day: 10 },
        2024: { month: 4, day: 1 },
        2025: { month: 4, day: 21 },
        2026: { month: 4, day: 6 },
        2027: { month: 3, day: 29 },
        2028: { month: 4, day: 17 },
        2029: { month: 4, day: 2 },
        2030: { month: 4, day: 22 },
        2031: { month: 4, day: 14 },
        2032: { month: 3, day: 29 },
        2033: { month: 4, day: 18 },
        2034: { month: 4, day: 10 },
        // Add more years as needed
    };
    return dates[year] || { month: 4, day: 1 }; // Default to April 1 if year not found
};

export const getGoodFridayDates = (year) => {
    const dates = {
        2023: { month: 4, day: 7 },
        2024: { month: 3, day: 29 },
        2025: { month: 4, day: 18 },
        2026: { month: 4, day: 3 },
        2027: { month: 3, day: 26 },
        2028: { month: 4, day: 14 },
        2029: { month: 3, day: 30 },
        2030: { month: 4, day: 19 },
        2031: { month: 4, day: 11 },
        2032: { month: 3, day: 26 },
        2033: { month: 4, day: 15 },
        2034: { month: 4, day: 7 },
        // Add more years as needed
    };
    return dates[year] || { month: 4, day: 1 }; // Default to April 1 if year not found
};

export const getLaborDayDates = (year) => {
    const dates = {
        2023: { month: 9, day: 4 },
        2024: { month: 9, day: 2 },
        2025: { month: 9, day: 1 },
        2026: { month: 9, day: 7 },
        2027: { month: 9, day: 6 },
        2028: { month: 9, day: 4 },
        2029: { month: 9, day: 3 },
        2030: { month: 9, day: 2 },
        2031: { month: 9, day: 1 },
        2032: { month: 9, day: 6 },
        2033: { month: 9, day: 5 },
        2034: { month: 9, day: 4 },
        // Add more years as needed
    };
    return dates[year] || { month: 9, day: 1 }; // Default to September 1 if year not found
};

export const getColumbusDayDates = (year) => {
    const dates = {
        2023: { month: 10, day: 9 },
        2024: { month: 10, day: 14 },
        2025: { month: 10, day: 13 },
        2026: { month: 10, day: 12 },
        2027: { month: 10, day: 11 },
        2028: { month: 10, day: 9 },
        2029: { month: 10, day: 8 },
        2030: { month: 10, day: 14 },
        2031: { month: 10, day: 13 },
        2032: { month: 10, day: 11 },
        2033: { month: 10, day: 10 },
        2034: { month: 10, day: 9 },
        // Add more years as needed
    };
    return dates[year] || { month: 10, day: 1 }; // Default to October 1 if year not found
};

export const getThanksgivingDayDates = (year) => {
    const dates = {
        2023: { month: 11, day: 23 },
        2024: { month: 11, day: 28 },
        2025: { month: 11, day: 27 },
        2026: { month: 11, day: 26 },
        2027: { month: 11, day: 25 },
        2028: { month: 11, day: 23 },
        2029: { month: 11, day: 22 },
        2030: { month: 11, day: 28 },
        2031: { month: 11, day: 27 },
        2032: { month: 11, day: 25 },
        2033: { month: 11, day: 24 },
        2034: { month: 11, day: 23 },
        // Add more years as needed
    };
    return dates[year] || { month: 11, day: 1 }; // Default to November 1 if year not found
};

export const getBlackFridayDates = (year) => {
    const dates = {
        2023: { month: 11, day: 24 },
        2024: { month: 11, day: 29 },
        2025: { month: 11, day: 28 },
        2026: { month: 11, day: 27 },
        2027: { month: 11, day: 26 },
        2028: { month: 11, day: 24 },
        2029: { month: 11, day: 23 },
        2030: { month: 11, day: 29 },
        2031: { month: 11, day: 28 },
        2032: { month: 11, day: 26 },
        2033: { month: 11, day: 25 },
        2034: { month: 11, day: 24 },
        // Add more years as needed
    };
    return dates[year] || { month: 11, day: 1 }; // Default to November 1 if year not found
};

export const getCyberMondayDates = (year) => {
    const dates = {
        2023: { month: 11, day: 27 },
        2024: { month: 12, day: 2 },
        2025: { month: 12, day: 1 },
        2026: { month: 11, day: 30 },
        2027: { month: 11, day: 29 },
        2028: { month: 11, day: 27 },
        2029: { month: 11, day: 26 },
        2030: { month: 12, day: 2 },
        2031: { month: 12, day: 1 },
        2032: { month: 11, day: 29 },
        2033: { month: 11, day: 28 },
        2034: { month: 11, day: 27 },
        // Add more years as needed
    };
    return dates[year] || { month: 11, day: 1 }; // Default to November 1 if year not found
};

export const getAshWednesdayDates = (year) => {
    const dates = {
        2023: { month: 2, day: 22 },
        2024: { month: 2, day: 14 },
        2025: { month: 3, day: 5 },
        2026: { month: 2, day: 18 },
         2027: { month: 2, day: 10 },
        2028: { month: 2, day: 29 },
        2029: { month: 2, day: 14 },
        2030: { month: 3, day: 6 },
        2031: { month: 2, day: 26 },
        2032: { month: 2, day: 13 },
        2033: { month: 3, day: 2 },
        2034: { month: 2, day: 22 },
        // Add more years as needed
    };
    return dates[year] || { month: 2, day: 1 }; // Default to February 1 if year not found
};

export const getMotheringSundayDates = (year) => {
    const dates = {
        2023: { month: 3, day: 19 },
        2024: { month: 3, day: 10 },
        2025: { month: 3, day: 30 },
        2026: { month: 3, day: 15 },
        2027: { month: 3, day: 7 },
        2028: { month: 3, day: 26 },
        2029: { month: 3, day: 11 },
        2030: { month: 3, day: 31 },
        2031: { month: 3, day: 20 },
        2032: { month: 3, day: 7 },
        2033: { month: 3, day: 27 },
        2034: { month: 3, day: 16 },
        // Add more years as needed
    };
    return dates[year] || { month: 3, day: 1 }; // Default to March 1 if year not found
};

export const getPalmSundayDates = (year) => {
    const dates = {
        2023: { month: 4, day: 2 },
        2024: { month: 3, day: 24 },
        2025: { month: 4, day: 13 },
        2026: { month: 3, day: 29 },
        2027: { month: 3, day: 21 },
        2028: { month: 4, day: 9 },
        2029: { month: 3, day: 25 },
        2030: { month: 4, day: 14 },
        2031: { month: 4, day: 6 },
        2032: { month: 3, day: 21 },
        2033: { month: 4, day: 10 },
        2034: { month: 4, day: 2 },
        // Add more years as needed
    };
    return dates[year] || { month: 4, day: 1 }; // Default to April 1 if year not found
};

export const getPentecostDates = (year) => {
    const dates = {
        2023: { month: 5, day: 28 },
        2024: { month: 5, day: 20 },
        2025: { month: 6, day: 8 },
        2026: { month: 5, day: 24 },
        2027: { month: 5, day: 16 },
        2028: { month: 6, day: 4 },
        2029: { month: 5, day: 20 },
        2030: { month: 6, day: 9 },
        2031: { month: 5, day: 30 },
        2032: { month: 5, day: 15 },
        2033: { month: 6, day: 5 },
        2034: { month: 5, day: 26 },
        // Add more years as needed
    };
    return dates[year] || { month: 5, day: 1 }; // Default to May 1 if year not found
};

export const getRemembranceSundayDates = (year) => {
    const dates = {
        2023: { month: 11, day: 12 },
        2024: { month: 11, day: 10 },
        2025: { month: 11, day: 9 },
        2026: { month: 11, day: 8 },
        2027: { month: 11, day: 14 },
        2028: { month: 11, day: 12 },
        2029: { month: 11, day: 11 },
        2030: { month: 11, day: 10 },
        2031: { month: 11, day: 9 },
        2032: { month: 11, day: 14 },
        2033: { month: 11, day: 13 },
        2034: { month: 11, day: 12 },
        // Add more years as needed
    };
    return dates[year] || { month: 11, day: 1 }; // Default to November 1 if year not found
};

// You can also export the specialHolidays object if needed
export const specialHolidays = {
    'christmas': { month: 12, day: 25 },
    'new-year': { month: 1, day: 1 },
    'new-years-eve': { month: 12, day: 31 },
    'boxing-day': { month: 12, day: 26 },
    'valentines-day': { month: 2, day: 14 },
    'saint-patricks-day': { month: 3, day: 17 },
    'kwanzaa': { month: 12, day: 26 },
    'international-womens-day': { month: 3, day: 8 },
    'bastille-day': { month: 7, day: 14 },
    'canada-day': { month: 7, day: 1 },
    'australia-day': { month: 1, day: 26 },
    'republic-day': { month: 1, day: 26 },
    'all-saints-day': { month: 11, day: 1 },
    'pioneer-day': { month: 7, day: 24 },
    'europe-day': { month: 5, day: 9 },
    'cinco-de-mayo': { month: 5, day: 5 },
    'epiphany': { month: 1, day: 6 },
    'groundhog-day': { month: 2, day: 2 },
    'juneteenth': { month: 6, day: 19 },
    'independence-day': { month: 7, day: 4 },
    'chinese-new-year': getChineseNewYearDates(new Date().getFullYear()),
    'easter': getEasterDates(new Date().getFullYear()),
    'hanukkah': { month: 12, day: 25 },
    'saint-davids-day': { month: 3, day: 1 },
    'saint-georges-day': { month: 4, day: 23 },
    'guy-fawkes-night': { month: 11, day: 5 },
    'mothers-day': getMothersDayDates(new Date().getFullYear()),
    'fathers-day': getFathersDayDates(new Date().getFullYear()),
    'martin-luther-king-day': getMartinLutherKingDayDates(new Date().getFullYear()),
    'presidents-day': getPresidentsDayDates(new Date().getFullYear()),
    'spring': getSpringDayDates(new Date().getFullYear()),
    'summer': getSummerDayDates(new Date().getFullYear()),
    'autumn': getAutumnDates(new Date().getFullYear()),
    'winter': { month: 12, day: 21 },
    'april-fools-day': { month: 4, day: 1 },
    'veterans-day': { month: 11, day: 11 },
    'easter-monday': getEasterMondayDates(new Date().getFullYear()),
    'good-friday': getGoodFridayDates(new Date().getFullYear()),
    'labor-day': getLaborDayDates(new Date().getFullYear()),
    'columbus-day': getColumbusDayDates(new Date().getFullYear()),
    'thanksgiving': getThanksgivingDayDates(new Date().getFullYear()),
    'black-friday': getBlackFridayDates(new Date().getFullYear()),
    'cyber-monday': getCyberMondayDates(new Date().getFullYear()),
    'ash-wednesday': getAshWednesdayDates(new Date().getFullYear()),
    'mothering-sunday': getMotheringSundayDates(new Date().getFullYear()),
    'palm-sunday': getPalmSundayDates(new Date().getFullYear()),
    'pentecost': getPentecostDates(new Date().getFullYear()),
    'remembrance-sunday': getRemembranceSundayDates(new Date().getFullYear())
};
