<template>
	<BreadCrumb PageTitle="Superscript Generator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Superscript Generator</h1>
	  <p class="fs-6 text-center mb-4">Create superscript text easily with our free Superscript Generator. Copy formatted results in one click for instant use anywhere!</p>
	  <div class="row">
		<div class="col">
		  <form @submit.prevent="generateSuperscript">
			<div class="form-group">
			  <div class="input-group mb-3">
				<span class="input-group-text" for="textInput">Enter Text:</span>
				<input type="text" class="form-control" id="textInput" v-model="inputText" placeholder="Input text here">
			  </div>
			  <div v-if="alert" class="alert alert-danger mb-3" role="alert">{{ alert }}</div>
			  <button type="submit" class="btn btn-primary w-100">Generate</button>
			</div>
		  </form>
		</div>
	  </div>
	  <!-- Options -->
	  <div class="row my-4">
		<div class="col">
		  <h4>Options:</h4>
		  <div class="form-check form-check-inline">
			<input class="form-check-input" type="checkbox" id="optionBold" v-model="options.bold" @change="reapplyFormatting">
			<label class="form-check-label" for="optionBold">Bold</label>
		  </div>
		  <div class="form-check form-check-inline">
			<input class="form-check-input" type="checkbox" id="optionItalic" v-model="options.italic" @change="reapplyFormatting">
			<label class="form-check-label" for="optionItalic">Italic</label>
		  </div>
		</div>
	  </div>
	  <!-- Output section for generated superscript -->
	  <div class="output-section mt-3 fs-5">
		<label for="outputSuperscript">Generated Superscript:</label>
		<div class="input-group mb-3">
		  <input type="text" class="form-control fs-2" id="outputSuperscript" :value="outputText" readonly>
		  <button class="btn btn-outline-secondary" type="button" @click="copyToClipboard" :title="copyButtonTitle">
			<i :class="copyIconClass"></i>
		  </button>
		</div>
	  </div>
	  
	  <!-- FAQ Section -->
	  <div class="mt-5">
		<SuperscriptGeneratorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import SuperscriptGeneratorFAQ from '../FAQs/superscriptGeneratorFAQ.vue';
  
  export default {
	name: 'SuperscriptGenerator',
	components: {
	  BreadCrumb,
	  SuperscriptGeneratorFAQ
	},
	setup() {
	  const inputText = ref('');
	  const outputText = ref('');
	  const alert = ref('');
	  const options = ref({
		bold: false,
		italic: false
	  });
	  const isCopied = ref(false);
	  const lastGenerated = ref('');
  
	  const superscriptMap = {
		'0': '⁰', '1': '¹', '2': '²', '3': '³', '4': '⁴', '5': '⁵', '6': '⁶', '7': '⁷', '8': '⁸', '9': '⁹',
		'a': 'ᵃ', 'b': 'ᵇ', 'c': 'ᶜ', 'd': 'ᵈ', 'e': 'ᵉ', 'f': 'ᶠ', 'g': 'ᵍ', 'h': 'ʰ', 'i': 'ᶦ', 'j': 'ʲ',
		'k': 'ᵏ', 'l': 'ˡ', 'm': 'ᵐ', 'n': 'ⁿ', 'o': 'ᵒ', 'p': 'ᵖ', 'q': 'ᑫ', 'r': 'ʳ', 's': 'ˢ', 't': 'ᵗ',
		'u': 'ᵘ', 'v': 'ᵛ', 'w': 'ʷ', 'x': 'ˣ', 'y': 'ʸ', 'z': 'ᶻ',
		'A': 'ᴬ', 'B': 'ᴮ', 'C': 'ᶜ', 'D': 'ᴰ', 'E': 'ᴱ', 'F': 'ᶠ', 'G': 'ᴳ', 'H': 'ᴴ', 'I': 'ᴵ', 'J': 'ᴶ',
		'K': 'ᴷ', 'L': 'ᴸ', 'M': 'ᴹ', 'N': 'ᴺ', 'O': 'ᴼ', 'P': 'ᴾ', 'Q': 'Q', 'R': 'ᴿ', 'S': 'ˢ', 'T': 'ᵀ',
		'U': 'ᵁ', 'V': 'ⱽ', 'W': 'ᵂ', 'X': 'ˣ', 'Y': 'ʸ', 'Z': 'ᶻ',
		'+': '⁺', '-': '⁻', '=': '⁼', '(': '⁽', ')': '⁾'
	  };
  
	  const copyIconClass = computed(() => {
		return isCopied.value ? 'bi bi-clipboard-check' : 'bi bi-clipboard';
	  });
  
	  const copyButtonTitle = computed(() => {
		return isCopied.value ? 'Copied!' : 'Copy to Clipboard';
	  });
  
	  const generateSuperscript = () => {
		if (!inputText.value.trim()) {
		  alert.value = 'Please enter some text to generate superscript.';
		  return;
		}
		alert.value = '';
		
		lastGenerated.value = inputText.value
		  .split('')
		  .map(char => superscriptMap[char] || char)
		  .join('');
  
		reapplyFormatting();
	  };
  
	  const reapplyFormatting = () => {
		let formattedText = lastGenerated.value;
		if (options.value.bold) {
		  formattedText = `<strong>${formattedText}</strong>`;
		}
		if (options.value.italic) {
		  formattedText = `<i>${formattedText}</i>`;
		}
		outputText.value = formattedText;
	  };
  
	  const copyToClipboard = () => {
		navigator.clipboard.writeText(outputText.value).then(() => {
		  isCopied.value = true;
		  setTimeout(() => {
			isCopied.value = false;
		  }, 2000);
		}).catch(err => {
		  console.error('Failed to copy text: ', err);
		});
	  };
  
	  return {
		inputText,
		outputText,
		alert,
		options,
		copyIconClass,
		copyButtonTitle,
		generateSuperscript,
		reapplyFormatting,
		copyToClipboard
	  };
	}
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  </style>