<template>
  <BreadCrumb PageTitle="Factorial Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Factorial Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the factorial of a non-negative integer. Enter a number to see its factorial and the calculation steps.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Number -->
        <div class="mb-3">
          <label for="factorialNumber" class="form-label">Number (n):</label>
          <input
            type="text"
            id="factorialNumber"
            v-model="factorialNumber"
            class="form-control"
            placeholder="e.g., 5, 10"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateFactorial" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Number: {{ result.number }}</p>
          <p class="fs-5">Calculation Steps: {{ result.calculation }}</p>
          <p class="fs-5">Factorial Result: {{ result.factorialResult }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter a non-negative integer in the input field (e.g., 5, 10).</li>
        <li>Click "Calculate" to compute the factorial of the number.</li>
        <li>The results will show the number, calculation steps, and final factorial result.</li>
        <li>Click "Clear" to reset the input field and result.</li>
      </ul>

      <h5 class="alert-heading">Formula for Factorial:</h5>
      <div class="fs-6">
        The factorial of a non-negative integer \( n \) is defined as:
      </div>
      <div>
        \[
        n! = n \times (n-1) \times (n-2) \times \ldots \times 2 \times 1
        \]
      </div>
      <p class="fs-6">
        Special case: \( 0! = 1 \).
      </p>

      <h5 class="alert-heading">Example Calculations:</h5>
      <ul class="fs-6">
        <li>
          For \( 5! \):<br />
          \( 5 \times 4 \times 3 \times 2 \times 1 = 120 \)
        </li>
        <li>
          For \( 10! \):<br />
          \( 10 \times 9 \times 8 \times 7 \times 6 \times 5 \times 4 \times 3 \times 2 \times 1 = 3628800 \)
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const factorialNumber = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate factorial
    const calculateFactorial = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the input value
        const number = parseInt(factorialNumber.value.trim());
        if (isNaN(number) || number < 0) {
          throw new Error("Please enter a valid non-negative integer.");
        }

        // Calculate the factorial and the steps
        const { factorialResult, calculation } = factorialCalculation(number);

        result.value = {
          number: number,
          calculation: calculation,
          factorialResult: factorialResult,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to calculate factorial iteratively and return the steps
    const factorialCalculation = (n) => {
      if (n === 0) return { factorialResult: 1, calculation: "0! = 1" };

      let factorialResult = 1;
      let calculation = `${n}! = `;

      for (let i = n; i > 1; i--) {
        factorialResult *= i;
        calculation += `${i} × `;
      }
      calculation += "1";

      return {
        factorialResult: factorialResult,
        calculation: calculation,
      };
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      factorialNumber.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      factorialNumber,
      result,
      error,
      calculateFactorial,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
