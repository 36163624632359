<template>
    <BreadCrumb PageTitle="Numbers to Words Converter" />
    <div class="custom-container mt-4 mb-50">
      <h1 class="text-center">Numbers to Words Converter</h1>
      <p class="text-center fs-6">Convert your numbers to words below</p>
  
      <!-- Number Input Section -->
      <div class="form-group">
        <label for="numberInput" class="fs-6">Number Input</label>
        <input
          id="numberInput"
          class="form-control fs-6"
          v-model="numberInput"
          placeholder='Enter a number...'
          type="number"
        />
      </div>
  
      <!-- Language Dropdown -->
      <div class="form-group mt-3">
        <label for="languageSelect" class="fs-6">Select Language</label>
        <select class="form-control" v-model="selectedLang">
          <option v-for="(lang, code) in languages" :value="code" :key="code">
            {{ lang }}
          </option>
        </select>
      </div>
  
      <!-- Output Format Dropdown -->
      <div class="form-group mt-3">
        <label for="formatSelect" class="fs-6">Select Output Format</label>
        <select class="form-control" v-model="outputFormat">
          <option value="normal">Normal</option>
          <option value="uppercase">Uppercase</option>
          <option value="lowercase">Lowercase</option>
          <option value="capitalize">Capitalize</option>
        </select>
      </div>
  
      <!-- Convert Button -->
      <button class="btn btn-primary mt-3 w-100" @click="convertNumberToWords">Convert to Words</button>
  
      <!-- Error Message -->
      <div v-if="error" class="alert alert-danger mt-3">
        {{ error }}
      </div>
  
      <!-- Words Output Section -->
      <div v-if="wordsOutput" class="mt-4">
        <h3>Words Output</h3>
        <textarea class="form-control fs-4" rows="4" readonly v-model="formattedWordsOutput"></textarea>
  
        <!-- Copy Button -->
        <div class="mt-2">
          <button class="btn btn-danger w-100" @click="copyToClipboard">
            <i :class="clipboardIcon"></i> {{ clipboardText }}
          </button>
        </div>
      </div>
  
      <!-- FAQ Section -->
      <div class="mt-50 mb-50">
        <h2>Numbers to Words FAQ</h2>
        <div v-for="(item, index) in faqList" :key="index" class="mb-4">
          <h4>{{ item.question }}</h4>
          <p v-html="item.answer" class="fs-6"></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import n2words from 'n2words';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  
  export default {
    components: {
      BreadCrumb,
    },
    data() {
      return {
        numberInput: '',
        wordsOutput: '',
        formattedWordsOutput: '',
        selectedLang: 'en',
        outputFormat: 'normal',
        clipboardIcon: 'bi bi-clipboard',
        clipboardText: 'Copy to Clipboard',
        error: '',
        languages: {
            en: 'English',
            ar: 'Arabic',
            az: 'Azerbaijani',
            cz: 'Czech',
            dk: 'Danish',
            de: 'German',
            es: 'Spanish',
            fr: 'French',
            'fr-BE': 'French (Belgium)',
            fa: 'Farsi',
            he: 'Hebrew',
            hr: 'Croatian',
            hu: 'Hungarian',
            id: 'Indonesian',
            it: 'Italian',
            ko: 'Korean',
            lt: 'Lithuanian',
            lv: 'Latvian',
            nl: 'Dutch',
            no: 'Norwegian',
            pl: 'Polish',
            pt: 'Portuguese',
            ru: 'Russian',
            sr: 'Serbian',
            tr: 'Turkish',
            uk: 'Ukrainian',
            vi: 'Vietnamese',
            zh: 'Chinese',
            },
        faqList: [
          {
            question: '1. How does the Numbers to Words conversion work?',
            answer: 'We use the <code>n2words</code> library to convert numbers into words for various languages.'
          },
          {
            question: '2. Can I convert numbers in other languages?',
            answer: 'Yes, <code>n2words</code> supports multiple languages. You can specify the language during conversion.'
          },
          {
            question: '3. How does error handling work?',
            answer: 'We check if the input is valid. If the input is not a number or exceeds the supported range, an error message is displayed.'
          },
          {
            question: '4. What number ranges are supported?',
            answer: 'The <code>n2words</code> library supports both small and large numbers, but the exact limits depend on the language being used.'
          },
          {
            question: '5. How is two-way data binding achieved?',
            answer: 'The <code>v-model</code> directive binds the input field to the <code>numberInput</code> variable, allowing real-time updates.'
          },
          {
            question: '6. How can I copy the output?',
            answer: 'You can highlight the output manually and copy it. An additional button for copying can be added if needed.'
          },
          {
            question: '7. Can I customize the output format?',
            answer: 'The <code>n2words</code> library provides customization options like choosing the language and formatting style.'
          }
        ],
      };
    },
    methods: {
      convertNumberToWords() {
        this.error = '';
        this.wordsOutput = '';
        try {
          // Check if the input is valid
          if (this.numberInput === '') {
            this.error = 'Please enter a valid number.';
            return;
          }
  
          // Convert the number to words using selected language
          this.wordsOutput = n2words(this.numberInput, { lang: this.selectedLang });
  
          // Apply selected formatting to the output
          this.formatWordsOutput();
        } catch (err) {
          this.error = 'An error occurred while converting the number. Please try again.';
        }
      },
      formatWordsOutput() {
        if (this.outputFormat === 'uppercase') {
          this.formattedWordsOutput = this.wordsOutput.toUpperCase();
        } else if (this.outputFormat === 'lowercase') {
          this.formattedWordsOutput = this.wordsOutput.toLowerCase();
        } else if (this.outputFormat === 'capitalize') {
          this.formattedWordsOutput = this.wordsOutput.replace(/\b\w/g, char => char.toUpperCase());
        } else {
          this.formattedWordsOutput = this.wordsOutput; // Normal case
        }
      },
      copyToClipboard() {
        navigator.clipboard.writeText(this.formattedWordsOutput).then(() => {
          this.clipboardIcon = 'bi bi-clipboard-check';
          this.clipboardText = 'Copied!';
          setTimeout(() => {
            this.clipboardIcon = 'bi bi-clipboard';
            this.clipboardText = 'Copy to Clipboard';
          }, 2000);
        });
      },
    },
  };
  </script>
  
  <style>
  .custom-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  </style>
  