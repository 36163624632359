<template>
    <BreadCrumb PageTitle="Readability Calculator" />
  
    <div class="custom-container mt-5">
      <h1 class="text-center">Readability Calculator</h1>
      <p class="fs-6 text-center mb-4">
        Calculate the readability score of a block of text using various readability algorithms.
      </p>
  
      <!-- Input and Calculation Section -->
      <div class="card mb-4">
        <div class="card-body">
          <!-- Input for Text -->
          <div class="mb-3">
            <label for="inputText" class="form-label fs-6">Enter Text:</label>
            <textarea
              id="inputText"
              v-model="inputText"
              class="form-control fs-6"
              rows="6"
              placeholder="Paste your text here..."
            ></textarea>
          </div>
  
          <!-- Calculation Buttons -->
          <div class="mb-3 row">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <button @click="calculateReadability" class="btn btn-primary w-100">Calculate</button>
            </div>
            <div class="col-12 col-md-6">
              <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
            </div>
          </div>
        </div>
  
        <!-- Display Result Section -->
        <div class="card-footer">
          <h4>Results:</h4>
          <div v-if="result !== null">
            <p class="fs-5">Flesch Reading Ease: {{ result.fleschReadingEase.toFixed(2) }}</p>
            <p class="fs-5">Flesch-Kincaid Grade Level: {{ result.fleschKincaidGradeLevel.toFixed(2) }}</p>
            <p class="fs-5">Gunning Fog Index: {{ result.gunningFog.toFixed(2) }}</p>
            <p class="fs-5">Coleman-Liau Index: {{ result.colemanLiau.toFixed(2) }}</p>
            <p class="fs-5">SMOG Index: {{ result.smog.toFixed(2) }}</p>
            <p class="fs-5">Automated Readability Index (ARI): {{ result.ari.toFixed(2) }}</p>
          </div>
  
          <!-- Error message -->
          <p v-if="error" class="text-danger">{{ error }}</p>
        </div>
      </div>
  
      <!-- Instructions Section -->
      <div class="alert alert-info" role="alert">
        <h5 class="alert-heading">How to Use:</h5>
        <ul class="fs-6">
          <li>Enter a block of text in the input field.</li>
          <li>Click "Calculate" to compute the readability scores using various algorithms.</li>
          <li>The results will display the scores for each readability index.</li>
          <li>Click "Clear" to reset the input field and result.</li>
        </ul>
  
        <h5 class="alert-heading">Understanding Readability Scores:</h5>
        <p class="fs-6">
          Readability scores measure how easy a piece of text is to read. Various formulas take into account factors such as sentence length, word complexity, syllable count, and character count.
        </p>
  
        <h5 class="alert-heading">Formulas:</h5>
        <div>
          <h6>Flesch Reading Ease</h6>
          <p class="fs-6">
            \[
            \text{Flesch Reading Ease} = 206.835 - 1.015 \left( \frac{\text{Total Words}}{\text{Total Sentences}} \right) - 84.6 \left( \frac{\text{Total Syllables}}{\text{Total Words}} \right)
            \]
          </p>
  
          <h6>Flesch-Kincaid Grade Level</h6>
          <p class="fs-6">
            \[
            \text{Flesch-Kincaid Grade Level} = 0.39 \left( \frac{\text{Total Words}}{\text{Total Sentences}} \right) + 11.8 \left( \frac{\text{Total Syllables}}{\text{Total Words}} \right) - 15.59
            \]
          </p>
  
          <h6>Gunning Fog Index</h6>
          <p class="fs-6">
            \[
            \text{Gunning Fog Index} = 0.4 \left( \left( \frac{\text{Total Words}}{\text{Total Sentences}} \right) + 100 \left( \frac{\text{Complex Words}}{\text{Total Words}} \right) \right)
            \]
            <br/>
            *Complex Words* are words with three or more syllables.
          </p>
  
          <h6>Coleman-Liau Index</h6>
          <p class="fs-6">
            \[
            \text{Coleman-Liau Index} = 0.0588L - 0.296S - 15.8
            \]
            <br/>
            Where:
            <ul>
              <li>\( L \) = average number of letters per 100 words.</li>
              <li>\( S \) = average number of sentences per 100 words.</li>
            </ul>
          </p>
  
          <h6>SMOG Index</h6>
          <p class="fs-6">
            \[
            \text{SMOG Index} = 1.0430 \sqrt{\text{Polysyllables} \times \left( \frac{30}{\text{Sentences}} \right)} + 3.1291
            \]
            <br/>
            *Polysyllables* are words with three or more syllables.
          </p>
  
          <h6>Automated Readability Index (ARI)</h6>
          <p class="fs-6">
            \[
            \text{Automated Readability Index (ARI)} = 4.71 \left( \frac{\text{Characters}}{\text{Words}} \right) + 0.5 \left( \frac{\text{Words}}{\text{Sentences}} \right) - 21.43
            \]
          </p>
        </div>
  
        <h5 class="alert-heading">Example Scores:</h5>
        <ul class="fs-6">
          <li><strong>Flesch Reading Ease:</strong> Higher values indicate easier reading (e.g., 90+ is very easy, 60-70 is standard, 30 or below is very difficult).</li>
          <li><strong>Flesch-Kincaid Grade Level:</strong> Indicates the U.S. school grade level needed to understand the text (e.g., 8.5 means eighth grade).</li>
          <li><strong>Gunning Fog Index:</strong> Estimates the years of formal education required (e.g., 12 means twelfth grade).</li>
          <li><strong>Coleman-Liau Index:</strong> Based on letters and sentences, it returns a grade level (e.g., 10 indicates tenth grade).</li>
          <li><strong>SMOG Index:</strong> Estimates the grade level required based on polysyllabic words and sentence count.</li>
          <li><strong>Automated Readability Index (ARI):</strong> Returns a grade level based on characters and sentence length.</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  
  export default {
    components: {
      BreadCrumb,
    },
    setup() {
      const inputText = ref("");
      const result = ref(null);
      const error = ref("");
  
      // Helper functions to count syllables, words, sentences, letters, etc.
      const countSyllables = (word) => {
        word = word.toLowerCase();
        if (word.length <= 3) { return 1; }
        word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '');
        word = word.replace(/^y/, '');
        const syllableMatch = word.match(/[aeiouy]{1,2}/g);
        return syllableMatch ? syllableMatch.length : 1;
      };
  
      const calculateReadability = () => {
        error.value = "";
        result.value = null;
  
        try {
          if (inputText.value.trim() === "") {
            throw new Error("Please enter some text.");
          }
  
          const text = inputText.value.trim();
  
          // Count sentences
          const sentenceEndings = text.match(/[.!?]/g);
          const sentenceCount = sentenceEndings ? sentenceEndings.length : 1;
  
          // Count words
          const words = text.match(/\b\w+\b/g);
          const wordCount = words ? words.length : 0;
  
          if (wordCount === 0) {
            throw new Error("No words found in the text.");
          }
  
          // Count syllables and complex words
          let syllableCount = 0;
          let polysyllableCount = 0;
          let complexWordCount = 0;
          let letterCount = 0;
          words.forEach(word => {
            syllableCount += countSyllables(word);
            if (countSyllables(word) >= 3) {
              complexWordCount += 1;
              polysyllableCount +=1;
            }
            letterCount += word.replace(/[^a-zA-Z]/g, "").length;
          });
  
          // Flesch Reading Ease
          const fleschReadingEase = 206.835 - (1.015 * (wordCount / sentenceCount)) - (84.6 * (syllableCount / wordCount));
  
          // Flesch-Kincaid Grade Level
          const fleschKincaidGradeLevel = (0.39 * (wordCount / sentenceCount)) + (11.8 * (syllableCount / wordCount)) - 15.59;
  
          // Gunning Fog Index
          const gunningFog = 0.4 * ((wordCount / sentenceCount) + 100 * (complexWordCount / wordCount));
  
          // Coleman-Liau Index
          const L = (letterCount / wordCount) * 100; // average number of letters per 100 words
          const S = (sentenceCount / wordCount) * 100; // average number of sentences per 100 words
          const colemanLiau = (0.0588 * L) - (0.296 * S) - 15.8;
  
          // SMOG Index
          const smog = 1.0430 * Math.sqrt(polysyllableCount * (30 / sentenceCount)) + 3.1291;
  
          // Automated Readability Index (ARI)
          const characterCount = letterCount; // considering letters as characters
          const ari = 4.71 * (characterCount / wordCount) + 0.5 * (wordCount / sentenceCount) - 21.43;
  
          result.value = {
            fleschReadingEase,
            fleschKincaidGradeLevel,
            gunningFog,
            colemanLiau,
            smog,
            ari,
          };
        } catch (e) {
          error.value = e.message;
        }
      };
  
      // Function to clear the input and results
      const clearValues = () => {
        inputText.value = "";
        result.value = null;
        error.value = "";
      };
  
      return {
        inputText,
        result,
        error,
        calculateReadability,
        clearValues,
      };
    },
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  .alert {
    margin-bottom: 20px;
  }
  
  .dark h5 {
    color: #2b3035 !important;
  }
  
  strong {
    font-weight: bold;
  }
  
  .fs-5 {
    font-size: 1.25rem; /* Adjusted font size for clarity */
  }
  
  @media (max-width: 767px) {
    .col-12 {
      margin-bottom: 0.5rem;
    }
  }
  </style>
  