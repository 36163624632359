<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Superscript Generator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is Superscript Generator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    A Superscript Generator is a digital tool that converts standard alphanumeric characters into their superscript equivalents, typically used for mathematical exponents, ordinal indicators, and other scientific notations.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>What is Superscript?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Superscript refers to text formatted to appear above the typical baseline of text. It is smaller than the standard text size and is commonly used for citations, mathematical expressions, and chemical compounds.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>How do I use Superscript Generator?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Simply input your text into the generator, and click 'Generate'. The tool will convert your text into superscript, which you can copy and paste into documents, emails, or web pages.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>What characters can be converted to Superscript?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Most numerical digits and some letters have direct superscript counterparts in Unicode. However, the range of alphabetic superscript characters is limited to a subset commonly used in notations.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>Is Superscript Generator free to use?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, the Superscript Generator is typically a free online service, designed to quickly convert your text into superscript format without any charges.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Can I use the generated Superscript text in software like Microsoft Word or Google Docs?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Absolutely. The generated superscript is Unicode text, which is compatible with popular word processors like Microsoft Word, Google Docs, and others.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>Does Superscript Generator require any installation?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    No installation is required. The Superscript Generator is an online tool that operates within your web browser, making it accessible from any device with internet access.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'superscriptGeneratorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
