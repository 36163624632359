<template>
	<BreadCrumb PageTitle="Gravitational Force Calculator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Gravitational Force Calculator</h1>
	  <p class="fs-6 text-center mb-4">Your online gravitational force between two objects calculator</p>

	  <div class="col-12">
		<div class="input-group mb-3">
		  <span class="input-group-text" for="mass1">Mass 1 (kg)</span>
		  <input id="mass1" v-model="mass1" class="form-control" type="number" placeholder="Enter mass 1" />
		  <input id="mass2" v-model="mass2" class="form-control" type="number" placeholder="Enter mass 2" />
		  <span class="input-group-text" for="mass2">Mass 2 (kg)</span>
		</div>
		<div class="input-group mb-3">
		  <span class="input-group-text" for="distance">Distance</span>
		  <input type="number" id="distance" v-model="distance" placeholder="Enter Distance" class="form-control" aria-label="Distance in meters">
		  <span class="input-group-text">m</span>
		</div>
		<div v-if="showError" class="alert alert-warning mt-2" role="alert">
		  Please enter numbers to calculate!
		</div>
	  </div>
	  <div class="row mt-4">
		<div class="col-md-6 mb-2">
		  <button @click="calculate" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Calculate Gravitational Force">
			Calculate
		  </button>
		</div>
		<div class="col-md-6 mb-2">
		  <button @click="reset" class="btn btn-danger w-100" data-bs-toggle="tooltip" title="Reset Screen">
			Reset
		  </button>
		</div>
	  </div>
	  <div class="col-md-12 mt-4">
		<h4 class="text-center">Gravitational Force:</h4>
		<div v-if="forceResults" class="row mt-4">
		  <div v-for="(force, unit) in forceResults" :key="unit" class="col-md-6 col-lg-4 mb-3">
			<div class="card">
			  <div class="card-body">
				<h5 class="card-title">{{ unitLabels[unit] }}</h5>
				<p class="card-text">{{ force.toExponential(5) }}</p>
			  </div>
			</div>
		  </div>
		</div>
		<p v-else class="text-center">No calculations generated yet.</p>
	  </div>
	  <div class="mt-4">
		<GravitationalForceCalculatorFAQ />
	  </div>
	</div>
</template>

<script>
import { ref } from 'vue'
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import GravitationalForceCalculatorFAQ from '../FAQs/gravitationalForceCalculatorFAQ.vue';

export default {
  name: 'GravitationalForceCalculator',
  components: {
    BreadCrumb,
    GravitationalForceCalculatorFAQ
  },
  setup() {
    const mass1 = ref('')
    const mass2 = ref('')
    const distance = ref('')
    const forceResults = ref(null)
    const showError = ref(false)

    const unitLabels = {
      N: 'Newtons (N)',
      dyn: 'Dynes (dyn)',
      gf: 'Gram-force (gf)',
      kgms2: 'Kilogram-meter/second² (kg·m/s²)',
      kgf: 'Kilogram-force (kgf)',
      kip: 'Kip (kip)',
      lbf: 'Pound-force (lbf)',
      pdl: 'Poundal (pdl)'
    }

    const calculateForce = (m1, m2, d) => {
      const G = 6.674e-11 // Gravitational constant
      const forceN = (G * m1 * m2) / (d * d)

      return {
        N: forceN,
        dyn: forceN * 1e5,
        gf: forceN * 101.971621298,
        kgms2: forceN,
        kgf: forceN / 9.80665,
        kip: forceN * 0.000224808943,
        lbf: forceN * 0.224808943,
        pdl: forceN * 7.23301,
      }
    }

    const calculate = () => {
      if (mass1.value && mass2.value && distance.value) {
        forceResults.value = calculateForce(parseFloat(mass1.value), parseFloat(mass2.value), parseFloat(distance.value))
        showError.value = false
      } else {
        showError.value = true
      }
    }

    const reset = () => {
      mass1.value = ''
      mass2.value = ''
      distance.value = ''
      forceResults.value = null
      showError.value = false
    }

    return {
      mass1,
      mass2,
      distance,
      forceResults,
      showError,
      unitLabels,
      calculate,
      reset
    }
  }
}
</script>

<style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

label,
.form-control {
  font-size: 16px;
}

.card {
  height: 100%;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
}

.card-text {
  font-size: 0.9rem;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
</style>
