<template>
    <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on HTML to Text Converter</h3>
                    </div>
                    <!-- FAQ 1 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the HTML to Text Converter?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to Text Converter is a tool that strips away all HTML tags and converts the content into plain text. This is useful for extracting readable text from HTML documents while ignoring any markup or formatting.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 2 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>How do I use the HTML to Text Converter?</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Using the HTML to Text Converter is straightforward:
                                </p>
                                <ol class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Paste the HTML content into the input field.</li>
                                    <li>Click the <strong>Convert</strong> button to remove the HTML tags.</li>
                                    <li>The output will be displayed as plain text in the output field.</li>
                                    <li>You can copy the plain text or download it for further use.</li>
                                </ol>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This allows you to extract human-readable content from HTML code quickly.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 3 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>What is plain text?</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Plain text is any text that is not formatted or encoded with any special markup language such as HTML, XML, or Markdown. It consists of characters without any special styling or tags. For example, in HTML:
                                </p>
                                <pre>
&lt;p&gt;This is a paragraph.&lt;/p&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Will be converted to plain text as:
                                </p>
                                <pre>
This is a paragraph.
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Plain text is often used when formatting or layout is unnecessary, such as for emails or simple documents.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 4 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFour" aria-expanded="true" aria-controls="faqCollapseFour">
                            <h5>What type of HTML can be converted to text?</h5>
                        </button>
                        <div id="faqCollapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to Text Converter can handle most HTML content, including paragraphs, headings, links, tables, and lists. For example, this HTML structure:
                                </p>
                                <pre>
&lt;h1&gt;Welcome&lt;/h1&gt;
&lt;p&gt;This is an example of text inside a paragraph.&lt;/p&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Will be converted to:
                                </p>
                                <pre>
Welcome
This is an example of text inside a paragraph.
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The tool removes all tags and leaves only the text, making it ideal for extracting plain content from HTML documents.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 5 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseFive" aria-expanded="true" aria-controls="faqCollapseFive">
                            <h5>What are the benefits of converting HTML to text?</h5>
                        </button>
                        <div id="faqCollapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Converting HTML to plain text can be useful in many scenarios:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>Content extraction:</strong> It helps you retrieve readable content from an HTML document, removing all unnecessary code.</li>
                                    <li><strong>Data processing:</strong> You can easily process or analyze content without worrying about HTML tags or structure.</li>
                                    <li><strong>Email or plain-text usage:</strong> Plain text is often preferred for email bodies or environments where HTML formatting is unsupported.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    By converting HTML to text, you can work with content in its simplest form without distraction from formatting elements.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 6 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSix" aria-expanded="true" aria-controls="faqCollapseSix">
                            <h5>Does the converter handle special characters?</h5>
                        </button>
                        <div id="faqCollapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Yes, the HTML to Text Converter handles special characters like HTML entities (e.g., &amp;amp;, &lt;, &gt;). It converts them into their corresponding characters in plain text. For example:
                                </p>
                                <pre>
&lt;p&gt;5 &amp; 3 are numbers.&lt;/p&gt;
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Will be converted to:
                                </p>
                                <pre>
5 & 3 are numbers.
                                </pre>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This ensures that the content is displayed as expected in the plain text output.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 7 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseSeven" aria-expanded="true" aria-controls="faqCollapseSeven">
                            <h5>Are there any limitations to the converter?</h5>
                        </button>
                        <div id="faqCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The HTML to Text Converter is designed to handle most HTML content, but there are some limitations:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>It may not fully preserve complex formatting, such as tables or forms, since they are removed in the plain text conversion.</li>
                                    <li>Images, videos, and other media will not be converted, as they require more than simple text to represent.</li>
                                    <li>Any inline CSS or JavaScript will be stripped, as they are not part of the text content.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Despite these limitations, the tool is ideal for extracting readable text from HTML documents, especially for simpler structures like paragraphs, headers, and lists.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- FAQ 8 -->
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseEight" aria-expanded="true" aria-controls="faqCollapseEight">
                            <h5>Why would I need to convert HTML to text?</h5>
                        </button>
                        <div id="faqCollapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Converting HTML to text can be useful in many cases:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li><strong>Email campaigns:</strong> Some email clients only support plain text emails, so HTML to text conversion ensures compatibility.</li>
                                    <li><strong>Content analysis:</strong> You might want to analyze or process the text content without dealing with the formatting or HTML structure.</li>
                                    <li><strong>Accessibility:</strong> Plain text is often easier to read and can be used in applications where minimal formatting is necessary.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    By converting HTML to plain text, you gain flexibility in how you use or process the content, making it easier to work with across different platforms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Additional FAQs as needed -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HTMLToTextFAQ',
};
</script>

<style scoped>
  /* Add any specific styles for FAQ layout */
</style>
