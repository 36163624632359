<template>
  <BreadCrumb PageTitle="Remainder Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">Remainder Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Calculate the remainder of a division operation. Enter the dividend and divisor to find the remainder.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Dividend -->
        <div class="mb-3">
          <label for="dividend" class="form-label">Dividend (Number to be divided):</label>
          <input
            type="number"
            id="dividend"
            v-model="dividend"
            class="form-control"
            placeholder="e.g., 10"
          />
        </div>

        <!-- Input for Divisor -->
        <div class="mb-3">
          <label for="divisor" class="form-label">Divisor (Number to divide by):</label>
          <input
            type="number"
            id="divisor"
            v-model="divisor"
            class="form-control"
            placeholder="e.g., 3"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateRemainder" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Dividend: {{ result.dividend }}</p>
          <p class="fs-5">Divisor: {{ result.divisor }}</p>
          <p class="fs-5">Quotient: {{ result.quotient }}</p>
          <p class="fs-5">Remainder: {{ result.remainder }}</p>
          <p class="fs-5">{{ result.explanation }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter the dividend (the number to be divided) in the first field.</li>
        <li>Enter the divisor (the number to divide by) in the second field.</li>
        <li>Click "Calculate" to compute the quotient and remainder of the division operation.</li>
        <li>The result will display the dividend, divisor, quotient, and remainder along with an explanation.</li>
        <li>Click "Clear" to reset the input fields and result.</li>
      </ul>

      <h5 class="alert-heading">Understanding Remainder and Modulus:</h5>
      <p class="fs-6">
        The remainder is the amount left over after division when one integer cannot be exactly divided by another. The modulus operation finds the remainder after division of one number by another.
      </p>

      <h5 class="alert-heading">Formula for Remainder:</h5>
      <div class="fs-6">
        For two integers \( a \) (dividend) and \( b \) (divisor), the remainder \( r \) is calculated as:
      </div>
      <div>
        \[
        r = a - b \times \left\lfloor \frac{a}{b} \right\rfloor
        \]
      </div>
      <p class="fs-6">
        Alternatively, using the modulus operator:
      </p>
      <div>
        \[
        r = a \mod b
        \]
      </div>

      <h5 class="alert-heading">Example Calculation:</h5>
      <ul class="fs-6">
        <li>
          For \( a = 10 \) and \( b = 3 \):
          <ul>
            <li>Quotient \( q = \left\lfloor \frac{10}{3} \right\rfloor = 3 \)</li>
            <li>Remainder \( r = 10 - 3 \times 3 = 1 \)</li>
            <li>So, \( 10 \div 3 = 3 \) with a remainder of \( 1 \).</li>
          </ul>
        </li>
        <li>
          For \( a = 20 \) and \( b = 4 \):
          <ul>
            <li>Quotient \( q = \left\lfloor \frac{20}{4} \right\rfloor = 5 \)</li>
            <li>Remainder \( r = 20 - 4 \times 5 = 0 \)</li>
            <li>So, \( 20 \div 4 = 5 \) with no remainder.</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const dividend = ref(null);
    const divisor = ref(null);
    const result = ref(null);
    const error = ref("");

    // Function to calculate the remainder
    const calculateRemainder = () => {
      error.value = "";
      result.value = null;

      try {
        // Ensure values are not null or empty
        if (dividend.value === null || dividend.value === "") {
          throw new Error("Please enter a value for the dividend.");
        }
        if (divisor.value === null || divisor.value === "") {
          throw new Error("Please enter a value for the divisor.");
        }

        // Parse the input values
        const a = Number(dividend.value);
        const b = Number(divisor.value);

        if (isNaN(a) || isNaN(b)) {
          throw new Error("Please enter valid numeric values for both dividend and divisor.");
        }

        if (b === 0) {
          throw new Error("Divisor cannot be zero.");
        }

        // Calculate quotient and remainder
        const quotient = Math.floor(a / b);
        const remainder = a - b * quotient;

        const explanation = `When ${a} is divided by ${b}, the quotient is ${quotient} and the remainder is ${remainder}.`;

        result.value = {
          dividend: a,
          divisor: b,
          quotient: quotient,
          remainder: remainder,
          explanation: explanation,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      dividend.value = null;
      divisor.value = null;
      result.value = null;
      error.value = "";
    };

    return {
      dividend,
      divisor,
      result,
      error,
      calculateRemainder,
      clearValues,
    };
  },
};
</script>


<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
