<template>
  <div class="row">
    <!-- Main Content Area -->
    <div class="col-xxl-8 col-xl-9">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing privacy-policy-card">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            At <strong>Pixel Royals</strong>, we value your privacy and are committed to protecting the personal information we collect from our users. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website, Pixel Royals.com.
          </p>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Last updated on September 4, 2023.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">Information We Collect</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            We may collect the following types of information when you use our website:
          </p>
          <ul class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            <li>Personal information: Information you provide to us, such as your name and email address when signing up for our newsletter.</li>
            <li>Usage data: Information about how you use our website, including pages visited, time spent, and actions taken.</li>
            <li>Cookies: Small data files stored on your device that help us track your preferences and improve your experience on our website.</li>
          </ul>
          <h5 class="text-black fw-bold mb-10 mb-12">How We Use Your Information</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            We use the information we collect for the following purposes:
          </p>
          <ul class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            <li>Providing and improving our services</li>
            <li>Communicating with you (e.g., sending newsletters and responding to inquiries)</li>
            <li>Analyzing website usage and trends to optimize user experience</li>
            <li>Enforcing our terms of service and protecting our rights</li>
          </ul>
          <h5 class="text-black fw-bold mb-10 mb-12">Information Sharing and Disclosure</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            We do not sell or share your personal information with third parties, except when necessary to provide our services, comply with legal obligations, or protect our rights.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">Data Security</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            We take reasonable precautions to protect your personal information from unauthorized access, disclosure, or loss. However, no data transmission over the internet can be guaranteed to be 100% secure. While we strive to protect your data, we cannot guarantee its absolute security.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">Third-Party Links</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Our website may contain links to third-party websites. We are not responsible for the content or privacy practices of these sites. We encourage you to review the privacy policies of any third-party websites you visit.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">Children's Privacy</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Our services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to remove such information and terminate the child's account, if applicable.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">Updates to This Privacy Policy</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on this page, and we encourage you to review this Privacy Policy periodically to stay informed about how we protect your personal information.
          </p>
          <h5 class="text-black fw-bold mb-10 mb-12">How you can contact us</h5>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            If you have any questions or concerns about our Privacy Policy, please contact us at:
            <a href="mailto:admin@PixelRoyals.com" class="text-decoration-none">
              admin@PixelRoyals.com
            </a>.
          </p>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base">
            Both personal information and personal data have the same meaning in the context of this Privacy Policy.
          </p>
        </div>
      </div>
    </div>

    <!-- Sidebar Area -->
    <div class="col-xxl-4 col-xl-3">
      <BlogSidebar />
    </div>
  </div>
</template>

<script>
import BlogSidebar from "../../../pages/Dashboard/BlogSidebar.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    BlogSidebar,
  },
};
</script>
