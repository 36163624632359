<template>
  <BreadCrumb PageTitle="GCD Calculator" />

  <div class="custom-container mt-5">
    <h1 class="text-center">GCD Calculator</h1>
    <p class="fs-6 text-center mb-4">
      Enter two or more integers to calculate their Greatest Common Divisor (GCD). The calculator will show the GCD along with the calculation steps.
    </p>

    <!-- Input and Calculation Section -->
    <div class="card mb-4">
      <div class="card-body">
        <!-- Input for Integers -->
        <div class="mb-3">
          <label for="integers" class="form-label">Enter Integers (comma separated):</label>
          <input
            type="text"
            id="integers"
            v-model="integersInput"
            class="form-control"
            placeholder="e.g., 48, 180, 72"
          />
        </div>

        <!-- Calculation Buttons -->
        <div class="mb-3 row">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <button @click="calculateGCD" class="btn btn-primary w-100">Calculate</button>
          </div>
          <div class="col-12 col-md-6">
            <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
          </div>
        </div>
      </div>

      <!-- Display Result Section -->
      <div class="card-footer">
        <h4>Result:</h4>
        <div v-if="result !== null">
          <p class="fs-5">Integers: {{ result.integers }}</p>
          <p class="fs-5">GCD: {{ result.gcd }}</p>
          <p class="fs-5">Calculation Steps: {{ result.steps }}</p>
        </div>

        <!-- Error message -->
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

    <!-- Instructions Section -->
    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">How to Use:</h5>
      <ul class="fs-6">
        <li>Enter two or more integers separated by commas (e.g., 48, 180, 72).</li>
        <li>Click "Calculate" to compute the GCD of the entered integers.</li>
        <li>The result will show the entered integers, the GCD, and the calculation steps.</li>
        <li>Click "Clear" to reset the input field and result.</li>
      </ul>

      <h5 class="alert-heading">What is GCD?</h5>
      <p class="fs-6">
        The Greatest Common Divisor (GCD) of two or more integers is the largest integer that divides all the given numbers without leaving a remainder.
      </p>

      <h5 class="alert-heading">Formula for GCD using Euclidean Algorithm:</h5>
      <div class="fs-6">
        For two integers \( a \) and \( b \), the GCD is calculated as follows:
      </div>
      <div>
        \[
        \text{GCD}(a, b) = \text{GCD}(b, a \% b)
        \]
      </div>
      <p class="fs-6">
        The process is repeated until \( b = 0 \). At this point, the GCD is \( a \).
      </p>

      <h5 class="alert-heading">Example Calculation:</h5>
      <ul class="fs-6">
        <li>
          For \( \text{GCD}(48, 180) \):
          <ul>
            <li>180 % 48 = 36</li>
            <li>48 % 36 = 12</li>
            <li>36 % 12 = 0</li>
            <li>GCD = 12</li>
          </ul>
        </li>
        <li>
          For \( \text{GCD}(48, 180, 72) \):
          <ul>
            <li>GCD(48, 180) = 12</li>
            <li>GCD(12, 72) = 12</li>
            <li>GCD = 12</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
  },
  setup() {
    // Input fields and result state
    const integersInput = ref("");
    const result = ref(null);
    const error = ref("");

    // Function to calculate GCD of multiple integers
    const calculateGCD = () => {
      error.value = "";
      result.value = null;

      try {
        // Parse the integers from the input
        const integers = parseIntegers(integersInput.value);

        if (integers.length < 2) throw new Error("Please enter at least two integers.");

        // Calculate GCD of the integers
        const { gcd, steps } = gcdCalculation(integers);

        result.value = {
          integers: integers.join(", "),
          gcd: gcd,
          steps: steps,
        };
      } catch (e) {
        error.value = e.message;
      }
    };

    // Function to parse integers from a comma-separated string
    const parseIntegers = (input) => {
      return input
        .split(",")
        .map((num) => parseInt(num.trim()))
        .filter((num) => !isNaN(num));
    };

    // Function to calculate GCD using the Euclidean algorithm
    const gcdCalculation = (nums) => {
      let steps = "";
      let gcd = nums[0];
      steps += `GCD(${nums.join(", ")})\n`;

      for (let i = 1; i < nums.length; i++) {
        gcd = findGCD(gcd, nums[i], steps);
      }

      return { gcd, steps: steps.trim() };
    };

    // Function to find GCD of two numbers using the Euclidean algorithm
    const findGCD = (a, b, steps) => {
      let originalA = a;
      let originalB = b;
      while (b !== 0) {
        let remainder = a % b;
        steps += `GCD(${originalA}, ${originalB}) = GCD(${b}, ${remainder})\n`;
        a = b;
        b = remainder;
      }
      return a;
    };

    // Function to clear the input fields and result
    const clearValues = () => {
      integersInput.value = "";
      result.value = null;
      error.value = "";
    };

    return {
      integersInput,
      result,
      error,
      calculateGCD,
      clearValues,
    };
  },
};
</script>

<style scoped>
.custom-container {
  max-width: 70vh;
  margin: 0 auto;
  padding: 0 5px;
}

.alert {
  margin-bottom: 20px;
}

.dark h5 {
  color: #2b3035 !important;
}

strong {
  font-weight: bold;
}

.fs-5 {
  font-size: 1.25rem; /* Adjusted font size for clarity */
}

@media (max-width: 767px) {
  .col-12 {
    margin-bottom: 0.5rem;
  }
}
</style>
