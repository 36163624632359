<template>
    <div class="game-stats">
      <h3>Game Stats</h3>
      <p>High Score: {{ highScore }} | Words Solved: {{ wordsSolved }} | Accuracy: {{ accuracy }}%</p>
    </div>
  </template>
  
  <script>
  export default {
    props: ['highScore', 'wordsSolved', 'accuracy']
  };
  </script>

<style scoped>
  .game-stats {
    font-size: 1.2rem;
  }
</style>