<template>
	<BreadCrumb PageTitle="Bubble Text Generator" />
	<div class="custom-container mt-5 mb-50">
	  <h1 class="text-center">Bubble Text Generator</h1>
	  <p class="fs-6 text-center mb-4">Generate fun bubble-style text for social media with our easy-to-use Bubble Text Generator. Just type, copy, and paste anywhere!</p>
	  <div class="row">
		<div class="col">
		  <div class="form-group mb-4 fs-6">
			<label for="inputText">Enter Text Here:</label>
			<input type="text" class="form-control" id="inputText" v-model="inputText">
		  </div>
		  <div class="form-group mb-4 fs-6">
			<label id="labelLightBubbleText">Light Bubble Text:</label>
			<div class="input-group">
			  <div class="fancy form-control" id="lightBubbleText" aria-labelledby="labelLightBubbleText" readonly>{{ lightBubbleText }}</div>
			  <button class="btn btn-outline-secondary" type="button" @click="copyText('lightBubbleText')">
				<i :class="['bi', lightCopyIcon]"></i>
			  </button>
			</div>
		  </div>
		  <div class="form-group mb-4 fs-6">
			<label id="labelDarkBubbleText">Dark Bubble Text:</label>
			<div class="input-group">
			  <div class="fancy form-control" id="darkBubbleText" aria-labelledby="labelDarkBubbleText" readonly>{{ darkBubbleText }}</div>
			  <button class="btn btn-outline-secondary" type="button" @click="copyText('darkBubbleText')">
				<i :class="['bi', darkCopyIcon]"></i>
			  </button>
			</div>
		  </div>
		</div>
	  </div>
	  <div id="alert" class="alert alert-danger" v-if="showAlert" role="alert">{{ alertMessage }}</div>
	  <div class="mb-2">
		<button id="resetBtn" class="btn btn-danger w-100" @click="reset" data-bs-toggle="tooltip" title="Reset Screen">Reset</button>
	  </div>
	  
	  <!-- FAQ Section -->
	  <div class="mt-5">
		<BubbleTextGeneratorFAQ />
	  </div>
	</div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import BubbleTextGeneratorFAQ from '../FAQs/bubbleTextGeneratorFAQ.vue';
  
  export default {
	name: 'BubbleTextGenerator',
	components: {
	  BreadCrumb,
	  BubbleTextGeneratorFAQ
	},
	setup() {
	  const inputText = ref('');
	  const showAlert = ref(false);
	  const alertMessage = ref('');
	  const lightCopyIcon = ref('bi-clipboard');
	  const darkCopyIcon = ref('bi-clipboard');
  
	  const fonts = [
		[
		  // Light bubble text characters
		  ["ⓐ", "ⓑ", "ⓒ", "ⓓ", "ⓔ", "ⓕ", "ⓖ", "ⓗ", "ⓘ", "ⓙ", "ⓚ", "ⓛ", "ⓜ", "ⓝ", "ⓞ", "ⓟ", "ⓠ", "ⓡ", "ⓢ", "ⓣ", "ⓤ", "ⓥ", "ⓦ", "ⓧ", "ⓨ", "ⓩ"],
		  // Capital light bubble text characters
		  ["Ⓐ", "Ⓑ", "Ⓒ", "Ⓓ", "Ⓔ", "Ⓕ", "Ⓖ", "Ⓗ", "Ⓘ", "Ⓙ", "Ⓚ", "Ⓛ", "Ⓜ", "Ⓝ", "Ⓞ", "Ⓟ", "Ⓠ", "Ⓡ", "Ⓢ", "Ⓣ", "Ⓤ", "Ⓥ", "Ⓦ", "Ⓧ", "Ⓨ", "Ⓩ"],
		  // Numbers for light bubble text
		  ["Ⓞ", "①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨"]
		],
		[
		  // Dark bubble text characters
		  ["🅐", "🅑", "🅒", "🅓", "🅔", "🅕", "🅖", "🅗", "🅘", "🅙", "🅚", "🅛", "🅜", "🅝", "🅞", "🅟", "🅠", "🅡", "🅢", "🅣", "🅤", "🅥", "🅦", "🅧", "🅨", "🅩"],
		  // Capital dark bubble text characters
		  ["🅐", "🅑", "🅒", "🅓", "🅔", "🅕", "🅖", "🅗", "🅘", "🅙", "🅚", "🅛", "🅜", "🅝", "🅞", "🅟", "🅠", "🅡", "🅢", "🅣", "🅤", "🅥", "🅦", "🅧", "🅨", "🅩"],
		  // Numbers for dark bubble text
		  ["🅞", "➊", "➋", "➌", "➍", "➎", "➏", "➐", "➑", "➒"]
		]
	  ];
  
	  const convertBubbleText = (input, fontType) => {
		let convertedText = "";
		for (let i = 0; i < input.length; i++) {
		  const charCode = input.charCodeAt(i);
		  if (charCode >= 97 && charCode <= 122) {
			convertedText += fonts[fontType][0][charCode - 97];
		  } else if (charCode >= 65 && charCode <= 90) {
			convertedText += fonts[fontType][1][charCode - 65];
		  } else if (charCode >= 48 && charCode <= 57) {
			convertedText += fonts[fontType][2][charCode - 48];
		  } else {
			convertedText += input[i];
		  }
		}
		return convertedText;
	  };
  
	  const lightBubbleText = computed(() => {
		return inputText.value ? convertBubbleText(inputText.value, 0) : 'ⓈⒶⓂⓅⓁⒺ ⓉⒺⓍⓉ ①②③';
	  });
  
	  const darkBubbleText = computed(() => {
		return inputText.value ? convertBubbleText(inputText.value, 1) : '🅢🅐🅜🅟🅛🅔 🅣🅔🅧🅣 ➊➋➌';
	  });
  
	  const convertText = () => {
		if (!inputText.value.trim()) {
		  showAlert.value = true;
		  alertMessage.value = 'Please enter some text to generate bubble text.';
		} else {
		  showAlert.value = false;
		}
	  };
  
	  const reset = () => {
		inputText.value = '';
		showAlert.value = false;
		lightCopyIcon.value = 'bi-clipboard';
		darkCopyIcon.value = 'bi-clipboard';
	  };
  
	  const copyText = (elementId) => {
		const el = document.getElementById(elementId);
		navigator.clipboard.writeText(el.textContent).then(() => {
		  const iconRef = elementId === 'lightBubbleText' ? lightCopyIcon : darkCopyIcon;
		  iconRef.value = 'bi-clipboard-check';
		  setTimeout(() => {
			iconRef.value = 'bi-clipboard';
		  }, 2000);
		});
	  };
  
	  return {
		inputText,
		lightBubbleText,
		darkBubbleText,
		showAlert,
		alertMessage,
		lightCopyIcon,
		darkCopyIcon,
		convertText,
		reset,
		copyText
	  };
	}
  };
  </script>
  
  <style scoped>
.custom-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .custom-container {
    width: 95vw;
    padding: 0 0.5rem;
  }
}
  
  .fancy {
	font-size: 1.8em;
  }
  </style>