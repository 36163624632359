<template>
	  <div class="card mb-40 border-0 rounded-0 bg-white letter-spacing faq-accordion-card mt-30 mx-auto">
        <div class="card-body p-15 p-sm-20">
            <div class="accordion" id="faqAccordion">
                <div class="row">
                    <div class="text-center mb-3">
                        <h3>FAQs on Empirical Rule Calculator</h3>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseOne" aria-expanded="true" aria-controls="faqCollapseOne">
                            <h5>What is the Empirical Rule Calculator?</h5>
                        </button>
                        <div id="faqCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Empirical Rule Calculator is a tool for understanding the distribution of data in a normal distribution. It helps you calculate the percentages of data within one, two, and three standard deviations from the mean.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseTwo" aria-expanded="true" aria-controls="faqCollapseTwo">
                            <h5>Understanding the Empirical Rule</h5>
                        </button>
                        <div id="faqCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    The Empirical Rule, also known as the 68-95-99.7 Rule, is a statistical concept that describes the distribution of data in a normal or bell-shaped curve. It states that:
                                </p>
                                <ul class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <li>Approximately 68% of the data falls within one standard deviation of the mean.</li>
                                    <li>Approximately 95% falls within two standard deviations.</li>
                                    <li>Approximately 99.7% falls within three standard deviations.</li>
                                </ul>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    This rule is valuable for understanding how data is spread out in a normal distribution.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item rounded-0 border-0">
                        <button class="accordion-button collapsed shadow-none border-0 rounded-0 fs-15 fs-md-17 fs-lg-18 text-black fw-medium d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapseThree" aria-expanded="true" aria-controls="faqCollapseThree">
                            <h5>Examples of Applying the Empirical Rule</h5>
                        </button>
                        <div id="faqCollapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body pb-0">
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    Determine the data distribution when the mean (μ) = 50, standard deviation (σ) = 15
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <strong>Solution</strong>
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <strong>Step 1:</strong><br>
                                    First, we calculate the data that falls within 68%:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    μ - σ, μ + σ<br>
                                    Putting Values in Formula:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    50 - 15 = 35<br>
                                    50 + 15 = 65
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <strong>Step 2:</strong><br>
                                    Now, we calculate the data that falls within 95%:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    μ - 2σ, μ + 2σ<br>
                                    Putting Values in Formula:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    50 - 2(15) = 20<br>
                                    50 + 2(15) = 80
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <strong>Step 3:</strong><br>
                                    Finally, we calculate the data that falls within 99.7%:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    μ - 3σ, μ + 3σ<br>
                                    Putting Values in Formula:
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    50 - 3(15) = 5<br>
                                    50 + 3(15) = 95
                                </p>
                                <p class="fs-md-15 fs-lg-16 text-paragraph lh-base">
                                    <strong>Summary</strong><br>
                                    Approximately 68% of the data falls between 35 and 65.<br>
                                    Approximately 95% of the data falls between 20 and 80.<br>
                                    Approximately 99.7% of the data falls between 5 and 95.<br>
                                    This information can help you understand how well students are performing and identify outliers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'EmpiricalRuleCalculatorFAQ',
};
</script>

<style scoped>
/* Your styles here */
</style>
