<template>
  <BreadCrumb PageTitle="Advanced Image Cropper" />
    <div class="mt-5 mb-50">
      <h1 class="text-center">Advanced Image Cropper</h1>
      <p class="fs-5 text-center">Crop your image to any size. Download image using our pre-defined sizes for all social media.</p>
      <div class="row mt-50">
        <div class="col-lg-8">
        <div class="row">
          <div class="col-md-6">
            <div class="input-group mb-3">
              <input
                ref="fileInput"
                class="form-control"
                type="file"
                accept="image/jpeg, image/png, image/gif"
                aria-describedby="file-addon"
                aria-label="Upload"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <input
                ref="imageURL"
                class="form-control form-control-sm"
                type="text"
                aria-label="Image URL"
                placeholder="Enter Image URL"
              />
              <button ref="loadImageFromURLBtn" class="btn btn-primary btn-sm">
                URL Import
              </button>
            </div>
          </div>
        
      </div>
      <div>
        <div class="col-12 mb-4">
          <div
            class="d-xl-flex justify-content-xl-center align-items-xl-center image-input"
          >
            <img ref="placeholderImage" class="placeholder-image" alt="Placeholder Image" src="/place-holder.png" />
          </div>

            <div class="row mt-20 mb-3">
              <div class="col-6">
                <button ref="btnDownload" class="btn btn-primary w-100" data-bs-toggle="tooltip" title="Download Image">Download</button>
              </div>
              <div class="col-6">
                <button ref="btnReset" class="btn btn-danger w-100" data-bs-toggle="tooltip" title="Reset Image">Reset</button>
              </div>
            </div>
            <div class="d-flex justify-content-between flex-wrap gap-1">
              <div class="col-3 col-md-3">
                <button ref="btnRotateLeft" class="btn btn-primary w-100 btn-hover" data-bs-toggle="tooltip" title="Rotate Image Left 45°">
                  <i class="ph ph-arrow-counter-clockwise"></i>
                </button>
              </div>
              <div class="col-3 col-md-3">
                <button ref="btnRotateRight" class="btn btn-primary w-100 btn-hover" data-bs-toggle="tooltip" title="Rotate Image Right 45°">
                  <i class="ph ph-arrow-clockwise"></i>
                </button>
              </div>
              <div class="col-3 col-md-3">
                <button ref="btnFlipHorizontal" class="btn btn-primary w-100 btn-hover" data-bs-toggle="tooltip" title="Flip Image Horizontally">
                  <i class="ph ph-arrows-in-line-horizontal"></i>
                </button>
              </div>
              <div class="col-3 col-md-3">
                <button ref="btnFlipVertical" class="btn btn-primary w-100 btn-hover" data-bs-toggle="tooltip" title="Flip Image Vertically">
                  <i class="ph ph-arrows-in-line-vertical"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col mt-50 mb-5">
          <div class="social-media-ratios mx-2">
            <h3 class="text-center mb-10">Social Media Ratios</h3>
            <div class="col">
              <div>
                <ul class="nav nav-tabs nav-justified mb-2" role="tablist">
                  <li class="nav-item" role="presentation"><a class="nav-link active" role="tab" data-bs-toggle="tab" href="#tab-1">Facebook</a></li>
                  <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" href="#tab-2">Twitter (X)</a></li>
                  <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" href="#tab-3">Instagram</a></li>
                  <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" href="#tab-4">Pinterest</a></li>
                  <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" href="#tab-5">YouTube</a></li>
                  <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" href="#tab-6">LinkedIn</a></li>
                </ul>
              <div class="tab-content">
                <div id="tab-1" class="tab-pane active" role="tabpanel">
                  <p>Download your desired image size.</p>
                  <div class="d-flex justify-content-evenly flex-wrap ratio-container gap-2 mt-3" ref="ratioBoxesContainer">
                    <div v-for="(ratio, index) in tab1Ratios" :key="index">
                      <div class="header"><span>{{ ratio.label }}</span><p>{{ ratio.size }}</p></div><div :id="'id_' + ratio.size.replace('/', 'by')" class="ratio-box" ref="ratioBoxes" data-type="secondary" :style="{ 'aspect-ratio': ratio.aspectRatio }" :image-size="ratio.size"><button class="download-btn"><i class="flaticon-download"></i></button></div>
                    </div>
                  </div>
                </div>

                <div id="tab-2" class="tab-pane" role="tabpanel">
                  <p>Download your desired image size.</p>
                  <div class="d-flex justify-content-evenly flex-wrap ratio-container gap-2 mt-3" ref="ratioBoxesContainer">
                    <div v-for="(ratio, index) in tab2Ratios" :key="index">
                      <div class="header"><span>{{ ratio.label }}</span><p>{{ ratio.size }}</p></div><div :id="'id_' + ratio.size.replace('/', 'by')" class="ratio-box" ref="ratioBoxes" data-type="secondary" :style="{ 'aspect-ratio': ratio.aspectRatio }" :image-size="ratio.size"><button class="download-btn"><i class="flaticon-download"></i></button></div>
                    </div>
                  </div>
                </div>

                <div id="tab-3" class="tab-pane" role="tabpanel">
                  <p>Download your desired image size.</p>
                  <div class="d-flex justify-content-evenly flex-wrap ratio-container gap-2 mt-3" ref="ratioBoxesContainer">
                    <div v-for="(ratio, index) in tab3Ratios" :key="index">
                      <div class="header"><span>{{ ratio.label }}</span><p>{{ ratio.size }}</p></div><div :id="'id_' + ratio.size.replace('/', 'by')" class="ratio-box" ref="ratioBoxes" data-type="secondary" :style="{ 'aspect-ratio': ratio.aspectRatio }" :image-size="ratio.size"><button class="download-btn"><i class="flaticon-download"></i></button></div>
                    </div>
                  </div>
                </div>

                <div id="tab-4" class="tab-pane" role="tabpanel">
                  <p>Download your desired image size.</p>
                  <div class="d-flex justify-content-evenly flex-wrap ratio-container gap-2 mt-3" ref="ratioBoxesContainer">
                    <div v-for="(ratio, index) in tab4Ratios" :key="index">
                      <div class="header"><span>{{ ratio.label }}</span><p>{{ ratio.size }}</p></div><div :id="'id_' + ratio.size.replace('/', 'by')" class="ratio-box" ref="ratioBoxes" data-type="secondary" :style="{ 'aspect-ratio': ratio.aspectRatio }" :image-size="ratio.size"><button class="download-btn"><i class="flaticon-download"></i></button></div>
                    </div>
                  </div>
                </div>

                <div id="tab-5" class="tab-pane" role="tabpanel">
                  <p>Download your desired image size.</p>
                  <div class="d-flex justify-content-evenly flex-wrap ratio-container gap-2 mt-3" ref="ratioBoxesContainer">
                    <div v-for="(ratio, index) in tab5Ratios" :key="index">
                      <div class="header"><span>{{ ratio.label }}</span><p>{{ ratio.size }}</p></div><div :id="'id_' + ratio.size.replace('/', 'by')" class="ratio-box" ref="ratioBoxes" data-type="secondary" :style="{ 'aspect-ratio': ratio.aspectRatio }" :image-size="ratio.size"><button class="download-btn"><i class="flaticon-download"></i></button></div>
                    </div>
                  </div>
                </div>

                <div id="tab-6" class="tab-pane" role="tabpanel">
                  <p>Download your desired image size.</p>
                  <div class="d-flex justify-content-evenly flex-wrap ratio-container gap-2 mt-3" ref="ratioBoxesContainer">
                    <div v-for="(ratio, index) in tab6Ratios" :key="index">
                      <div class="header"><span>{{ ratio.label }}</span><p>{{ ratio.size }}</p></div><div :id="'id_' + ratio.size.replace('/', 'by')" class="ratio-box" ref="ratioBoxes" data-type="secondary" :style="{ 'aspect-ratio': ratio.aspectRatio }" :image-size="ratio.size"><button class="download-btn"><i class="flaticon-download"></i></button></div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div><AdvancedImageCropperFAQ /></div>
    </div>

    <div class="col-lg-4">
        <div class="sticky-parent">
          <div class="col-12">
          <div>
            <div class="row">
              <div class="col">
                <div class="input-group">
                  <span class="input-group-text">X</span>
                  <input ref="idXAxis" class="form-control" type="text" placeholder="x-axis" disabled/>
                  <input ref="idYAxis" class="form-control" type="text" placeholder="y-axis" disabled/>
                  <span class="input-group-text">Y</span>
                </div>
                <div class="input-group">
                  <span class="input-group-text">W</span>
                  <input ref="idWidth" class="form-control" type="text" placeholder="width" disabled/>
                  <input ref="idHeight" class="form-control" type="text" placeholder="height" disabled/>
                  <span class="input-group-text">H</span>
                </div>
                <div class="input-group">
                  <span class="input-group-text">Rotate</span>
                  <input ref="idRotate" class="form-control" type="text" placeholder="rotate" disabled/>
                </div>
              </div>
            </div>
        </div>
      </div>
          <div class="card mt-3 mb-3">
            <div class="card-body p-26">
              <h5 class="text-center mb-20">Select Ratio</h5>
                <div class="aspect_ratio_select_grid">
                  <div
                    v-for="(ratio, index) in aspectRatios"
                    :key="index"
                    :data-aspect-ratio="ratio.value"
                    :data-aspect-ratio-index="index"
                    :ref="'aspectRatioItem' + index"
                    @click="selectAspectRatio"
                    @mouseover="onMouseOver"
                    @mouseleave="onMouseLeave"
                    class="card aspect_ratio_item"
                    :class="{ 'selected-box': selectedAspectRatioIndex === index }"
                  >
                    <div class="aspect_ratio_item_box">
                      <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                        <rect
                          v-if="ratio.value !== 'NaN'"
                          x="8"
                          y="8"
                          :width="getRectWidth(ratio.value)"
                          :height="getRectHeight(ratio.value)"
                          fill="none"
                          stroke="#726eed"
                          stroke-width="1"
                        ></rect>
                        <path
                          v-else
                          fill="none"
                          stroke="#726eed"
                          stroke-width="1"
                          d="M8 8h16v16H8z M6 6l4 0 M26 6l-4 0 M6 26l4 0 M26 26l-4 0 M6 6l0 4 M6 26l0-4 M26 6l0 4 M26 26l0-4"
                        ></path>
                      </svg>
                      <span>{{ ratio.label }}</span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
  </template>
  
  <script>
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import Cropper from 'cropperjs';
  import 'cropperjs/dist/cropper.css';
  import AdvancedImageCropperFAQ from '../FAQs/advancedImageCropperFAQ.vue';
  
  export default {
    components: {
    BreadCrumb,
    AdvancedImageCropperFAQ
  },
    data() {
      return {
        img: null,
        originalFilename: 'oFilename',
        imageType: 'image/png',
        cropper: null,
        aspectRatio: 1,
        timer: null,
        scaleXValue: 1,
        scaleYValue: 1,
        selectedAspectRatioIndex: null,
        aspectRatios: [
          { label: '1 x 1', value: '1/1' },
          { label: '4 x 3', value: '4/3' },
          { label: '5 x 4', value: '5/4' },
          { label: '3 x 2', value: '3/2' },
          { label: '5 x 3', value: '5/3' },
          { label: '16 x 9', value: '16/9' },
          { label: '3 x 1', value: '3/1' },
          { label: '1 x 3', value: '1/3' },
          { label: '3 x 4', value: '3/4' },
          { label: '3 x 5', value: '3/5' },
          { label: '4 x 5', value: '4/5' },
          { label: '9 x 16', value: '9/16' },
          { label: '2 x 3', value: '2/3' },
          { label: 'Custom', value: 'NaN' }
        ],
        tab1Ratios: [
          { label: 'Profile Picture', size: '180/180', aspectRatio: '1 / 1' },
          { label: 'Stories', size: '1080/1920', aspectRatio: '9 / 16' },
          { label: 'Cover Photo', size: '820/312', aspectRatio: '205 / 78' },
          { label: 'Shared Image', size: '1200/630', aspectRatio: '40 / 21' },
          { label: 'Event Image', size: '1920/1080', aspectRatio: '16 / 9' },
          { label: 'Group Cover', size: '1640/856', aspectRatio: '205 / 107' },
          { label: 'Video Thumbnails', size: '1280/720', aspectRatio: '16 / 9' }
        ],
        tab2Ratios: [
          { label: 'Header', size: '1500/500', aspectRatio: '3 / 1' },
          { label: 'Profile Image', size: '400/400', aspectRatio: '1 / 1' },
          { label: 'In-stream Photo', size: '440/220', aspectRatio: '2 / 1' },
          { label: 'Website Card', size: '1024/512', aspectRatio: '2 / 1' },
          { label: 'Shared Image', size: '1200/675', aspectRatio: '16 / 9' },
          { label: 'Link with Image', size: '800/418', aspectRatio: '400 / 209' }
        ],
        tab3Ratios: [
          { label: 'Profile Image', size: '110/110', aspectRatio: '1 / 1' },
          { label: 'Square Post', size: '1080/1080', aspectRatio: '1 / 1' },
          { label: 'Story', size: '1080/1920', aspectRatio: '9 / 16' },
          { label: 'Thumbnail', size: '161/161', aspectRatio: '1 / 1' },
          { label: 'Vertical Post', size: '1080/1350', aspectRatio: '4 / 5' },
          { label: 'Horizontal Post', size: '1080/566', aspectRatio: '540 / 283' }
        ],
        tab4Ratios: [
          { label: 'Story Pin', size: '1080/1920', aspectRatio: '9 / 16' },
          { label: 'Standard Pin', size: '1000/1500', aspectRatio: '2 / 3' },
          { label: 'Square Pin', size: '1000/1000', aspectRatio: '1 / 1' },
          { label: 'Long Pin', size: '1000/2100', aspectRatio: '10 / 21' },
          { label: 'Infographic Pins', size: '1000/3000', aspectRatio: '1 / 3' },
          { label: 'Board Cover', size: '600/600', aspectRatio: '1 / 1' },
          { label: 'Profile Picture', size: '165/165', aspectRatio: '1 / 1' }
        ],
        tab5Ratios: [
          { label: 'Thumbnail', size: '1280/720', aspectRatio: '16 / 9' },
          { label: 'Channel Banner', size: '2560/1440', aspectRatio: '16 / 9' },
          { label: 'Channel Art', size: '2560/1440', aspectRatio: '16 / 9' },
          { label: 'Channel Icon', size: '800/800', aspectRatio: '1 / 1' },
          { label: 'Watermark', size: '150/150', aspectRatio: '1 / 1' }
        ],
        tab6Ratios: [
          { label: 'Square Image', size: '1140/736', aspectRatio: '285 / 184' },
          { label: 'LinkedIn Image', size: '1200/628', aspectRatio: '300 / 157' },
          { label: 'Company Banner', size: '646/220', aspectRatio: '323 / 110' },
          { label: 'Personal Background', size: '1584/396', aspectRatio: '396 / 99' },
          { label: 'Company Background', size: '1536/768', aspectRatio: '2 / 1' },
          { label: 'Company Hero', size: '1128/376', aspectRatio: '564 / 188' },
          { label: 'Profile Image', size: '400/400', aspectRatio: '1 / 1' },
          { label: 'Company Logo', size: '300/300', aspectRatio: '1 / 1' },
          { label: 'Square Logo', size: '60/60', aspectRatio: '1 / 1' }
        ]
      };
    },
    mounted() {
    this.img = this.$refs.placeholderImage;
    this.initializeCropper();

    if (this.$refs.fileInput) {
      this.$refs.fileInput.addEventListener('change', this.handleFileUpload);
    }
    if (this.$refs.loadImageFromURLBtn) {
      this.$refs.loadImageFromURLBtn.addEventListener('click', this.loadImageFromURL);
    }
    if (this.$refs.btnFlipHorizontal) {
      this.$refs.btnFlipHorizontal.addEventListener('click', this.flipHorizontal);
    }
    if (this.$refs.btnFlipVertical) {
      this.$refs.btnFlipVertical.addEventListener('click', this.flipVertical);
    }
    if (this.$refs.btnRotateLeft) {
      this.$refs.btnRotateLeft.addEventListener('click', this.rotateLeft);
    }
    if (this.$refs.btnRotateRight) {
      this.$refs.btnRotateRight.addEventListener('click', this.rotateRight);
    }
    if (this.$refs.btnDownload) {
      this.$refs.btnDownload.addEventListener('click', this.downloadImage);
    }
    if (this.$refs.btnReset) {
      this.$refs.btnReset.addEventListener('click', this.resetCropper);
    }

    // Update this section
    this.aspectRatios.forEach((ratio, index) => {
      const element = this.$refs[`aspectRatioItem${index}`];
      if (element) {
        element.forEach(item => item.addEventListener('click', this.selectAspectRatio));
      }
    });

    if (this.$refs.downloadBtns && this.$refs.downloadBtns.length > 0) {
      Array.from(this.$refs.downloadBtns).forEach(btn => {
        btn.addEventListener('click', this.downloadCustomSize);
      });
    }

    const customButton = this.$refs.customButton;
    if (customButton) {
      const height = customButton.offsetHeight;
      customButton.style.width = `${height}px`;
    }
  },
  methods: {
    initializeCropper() {
      if (this.cropper) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(this.img, {
        aspectRatio: this.aspectRatio,
        viewMode: 1,
        crop: event => {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.updateCropData(event.detail);
            const dataURL = this.cropper.getCroppedCanvas().toDataURL(this.imageType);
            this.updateRatioBoxes(dataURL);
          }, 100);
        }
      });
    },
    updateCropData(detail) {
      if (this.$refs.idXAxis) this.$refs.idXAxis.value = `${Math.round(detail.x)} px`;
      if (this.$refs.idYAxis) this.$refs.idYAxis.value = `${Math.round(detail.y)} px`;
      if (this.$refs.idWidth) this.$refs.idWidth.value = `${Math.round(detail.width)} px`;
      if (this.$refs.idHeight) this.$refs.idHeight.value = `${Math.round(detail.height)} px`;
      if (this.$refs.idRotate) this.$refs.idRotate.value = `${Math.round(detail.rotate)} deg`;
    },
    updateRatioBoxes(dataURL) {
      if (this.$refs.ratioBoxes) {
        Array.from(this.$refs.ratioBoxes).forEach(box => {
          box.style.backgroundImage = `url(${dataURL})`;
        });
      }
    },
    selectAspectRatio(event) {
      if (this.selectedAspectRatioIndex !== null) {
        this.$refs[`aspectRatioItem${this.selectedAspectRatioIndex}`][0].classList.remove('selected-box');
      }
      const index = event.currentTarget.getAttribute('data-aspect-ratio-index');
      this.selectedAspectRatioIndex = index;
      event.currentTarget.classList.add('selected-box');

      const rawAspectRatio = event.currentTarget.getAttribute('data-aspect-ratio');
      this.aspectRatio = rawAspectRatio === 'NaN' ? NaN : eval(rawAspectRatio);
      if (this.cropper) {
        this.cropper.setAspectRatio(this.aspectRatio);
        this.cropper.crop();
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.originalFilename = file.name.split('.').slice(0, -1).join('.');
      this.imageType = file.type;
      const reader = new FileReader();
      reader.onload = e => {
        this.img.src = e.target.result;
        this.img.onload = () => {
          this.initializeCropper();
          this.updateRatioBoxes(this.img.src);
        };
      };
      reader.readAsDataURL(file);
      this.$refs.imageURL.value = '';
    },
    loadImageFromURL() {
      const url = this.$refs.imageURL.value;
      if (url) {
        this.originalFilename = url.split('/').pop().split('.').slice(0, -1).join('.');
        this.imageType = 'image/jpg';
        this.img.src = url;
        this.img.onload = () => {
          this.initializeCropper();
          this.updateRatioBoxes(this.img.src);
        };
      }
      this.$refs.fileInput.value = '';
    },
    flipHorizontal() {
      if (this.cropper) {
        this.scaleXValue = -this.scaleXValue;
        this.cropper.scaleX(this.scaleXValue);
      }
    },
    flipVertical() {
      if (this.cropper) {
        this.scaleYValue = -this.scaleYValue;
        this.cropper.scaleY(this.scaleYValue);
      }
    },
    rotateLeft() {
      if (this.cropper) {
        this.cropper.rotate(-45);
      }
    },
    rotateRight() {
      if (this.cropper) {
        this.cropper.rotate(45);
      }
    },
    downloadImage() {
      if (this.cropper) {
        const canvasData = this.cropper.getCroppedCanvas();
        const dataURL = canvasData.toDataURL(this.imageType);
        const a = document.createElement('a');
        a.href = dataURL;
        a.download = `${this.originalFilename}_cropped_image.${this.imageType.split('/')[1]}`;
        a.click();
      }
    },
    resetCropper() {
      if (this.cropper) {
        this.cropper.reset();
      }
      this.$refs.fileInput.value = '';
      this.$refs.imageURL.value = '';
    },
    downloadCustomSize(event) {
      const parentBox = event.currentTarget.closest('.ratio-box');
      const [width, height] = parentBox.getAttribute('image-size').split('/').map(Number);
      let canvasData = this.cropper.getCroppedCanvas();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(canvasData, 0, 0, width, height);
      const dataURL = canvas.toDataURL(this.imageType);
      const a = document.createElement('a');
      a.href = dataURL;
      a.download = `${this.originalFilename}_cropped_${width}x${height}.${this.imageType.split('/')[1]}`;
      a.click();
    },
    onMouseOver(event) {
      event.currentTarget.style.cursor = 'pointer';
    },
    onMouseLeave(event) {
      event.currentTarget.style.cursor = 'default';
    },
    getRectX(value) {
      const aspectRatio = eval(value);
      return aspectRatio >= 1 ? 8 : 32 / (2 * aspectRatio) - 8;
    },
    getRectWidth(value) {
      const aspectRatio = eval(value);
      return aspectRatio >= 1 ? 16 : 16 * aspectRatio;
    },
    getRectHeight(value) {
      const aspectRatio = eval(value);
      return aspectRatio >= 1 ? 16 / aspectRatio : 16;
    }
  }
};
  </script>
  
  
  <style scoped>
  .image-input {
    border: 1px dashed;
    border-radius: 4px;
    border-color: #626976 !important;
    width: 100%;
  }
  
  .input-group {
    margin-bottom: 10px;
  }
  
  .input-group-text {
    min-width: 5em;
  }
  
  #id_xaxis,
  #id_yaxis,
  #id_width,
  #id_height,
  #id_rotate {
    text-align: center;
  }
  
  .select_shape_ratio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .ratio-box {
    border-radius: 4px;
    border: 1px dashed;
    border-color: #626976 !important;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 10px;
  }
  
  .col-3 {
    flex: 0 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
  }
  
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
    padding: 5px;
    text-align: -webkit-center;
  }
  
  .aspect_ratio_select_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-gap: 10px;
    transition: display 0.3s ease;
  }
  
  .select-ratio.active + .aspect_ratio_select_grid {
    display: grid;
  }
  
  .select-ratio {
    display: flex;
    align-items: center;
  }
  
  .select-ratio-text {
    margin: 0 auto;
    color: #212529;
  }
  
  .rotate-icon {
    transition: transform 0.3s ease;
  }
  
  .rotate-down {
    transform: rotate(90deg);
  }
  
  .aspect_ratio_item {
    display: inline-block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
  }
  
  .selected-box {
    border: 2px solid #007bff;
    border-radius: 4px;
  }
  
  .selected-box span {
    color: #ffffff !important;
  }
  
  .aspect_ratio_item_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
  }
  
  .aspect_ratio_item_box svg {
    margin-bottom: 0;
  }
  
  .aspect_ratio_item_box span {
    text-align: center;
    font-size: 14px;
  }
  
  .download-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    border: 1px solid #00000060;
    opacity: 0.8;
    background-color: #ea868f;
  }
  
  .download-btn:hover {
    opacity: 1;
  }
  </style>