<template>
    <BreadCrumb PageTitle="Sine Calculator" />
  
    <div class="custom-container mt-5">
      <h1 class="text-center">Sine Calculator</h1>
      <p class="fs-6 text-center mb-4">Calculate the sine of an angle in degrees or radians.</p>
  
      <div class="card mb-4">
        <div class="card-body">
          <div class="mb-3">
            <label for="angle" class="form-label">Angle:</label>
            <input type="number" id="angle" v-model="angle" class="form-control" @input="calculateSine">
          </div>
          <div class="mb-3">
            <label for="angleUnit" class="form-label">Angle Unit:</label>
            <select id="angleUnit" v-model="angleUnit" class="form-select" @change="calculateSine">
              <option value="degrees">Degrees</option>
              <option value="radians">Radians</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="decimalPlaces" class="form-label">Decimal Places:</label>
            <input type="number" id="decimalPlaces" v-model="decimalPlaces" min="0" max="10" class="form-control" @input="calculateSine">
          </div>
          <div class="mb-3 row">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <button @click="calculateSine" class="btn btn-primary w-100">Calculate</button>
            </div>
            <div class="col-12 col-md-6">
              <button @click="clearValues" class="btn btn-secondary w-100">Clear</button>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <h4>Result:</h4>
          <p v-if="sineValue !== null" class="fs-4">
            sin({{ angle }}{{ angleUnit === 'degrees' ? '°' : ' rad' }}) = {{ formatNumber(sineValue) }}
          </p>
          <p v-if="error" class="text-danger">{{ error }}</p>
        </div>
      </div>
  
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0">Sine Table for Common Angles</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Angle (degrees)</th>
                  <th>Angle (radians)</th>
                  <th>Sine Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="entry in sineTable" :key="entry.degrees">
                  <td>{{ entry.degrees }}°</td>
                  <td>{{ formatNumber(entry.radians) }}</td>
                  <td>{{ formatNumber(entry.sine) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
      <div><SineCalculatorFAQ /></div>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import SineCalculatorFAQ from '../FAQs/sineCalculatorFAQ.vue';
  
  export default {
    name: 'SineCalculator',
    components: {
      BreadCrumb,
      SineCalculatorFAQ
    },
    setup() {
      const angle = ref('');
      const angleUnit = ref('degrees');
      const decimalPlaces = ref(4);
      const error = ref('');
      const sineValue = ref(null);
  
      const calculateSine = () => {
        if (angle.value === '') {
          sineValue.value = null;
          error.value = '';
          return;
        }
  
        const angleNum = parseFloat(angle.value);
  
        if (isNaN(angleNum)) {
          error.value = 'Please enter a valid number for the angle.';
          return;
        }
  
        error.value = '';
        const angleInRadians = angleUnit.value === 'degrees' ? angleNum * Math.PI / 180 : angleNum;
        sineValue.value = Math.sin(angleInRadians);
      };
  
      const clearValues = () => {
        angle.value = '';
        sineValue.value = null;
        error.value = '';
      };
  
      const formatNumber = (value) => {
        if (value === null) return '';
        return value.toFixed(decimalPlaces.value);
      };
  
      const sineTable = computed(() => {
        const angles = [0, 30, 45, 60, 90, 120, 135, 150, 180, 270, 360];
        return angles.map(degrees => {
          const radians = degrees * Math.PI / 180;
          return {
            degrees,
            radians,
            sine: Math.sin(radians)
          };
        });
      });
  
      return {
        angle,
        angleUnit,
        decimalPlaces,
        sineValue,
        error,
        calculateSine,
        clearValues,
        formatNumber,
        sineTable
      };
    }
  };
  </script>
  
  <style scoped>
  .custom-container {
    max-width: 80vh;
    margin: 0 auto;
    padding: 0 5px;
  }
  
  @media (max-width: 767px) {
    .col-12 {
      margin-bottom: 0.5rem;
    }
  }
  </style>